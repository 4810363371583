import gql from "graphql-tag"

const CREATE_OR_UPDATE_BUNDLE_GROUP = gql`
  mutation createOrUpdateBundleGroup(
    $id: ID!
    $bundleGroup: InputCreateOrUpdateBundleGroup!
  ) {
    createOrUpdateBundleGroup(
      bundleGroup: $bundleGroup
      id: $id
    ) {
      id
    }
  }
`

export default CREATE_OR_UPDATE_BUNDLE_GROUP
