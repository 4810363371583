import gql from "graphql-tag";

const DELETE_NSHIFT_CONFIG = gql`
    mutation deleteNShiftConfig($id: ID!) {
        deleteNShiftConfig(id: $id) {
            status
        }
    }
`;

export default DELETE_NSHIFT_CONFIG;
