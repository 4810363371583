import gql from "graphql-tag"

const DELETE_STORE_MARKET = gql`
  mutation deleteStoreMarket($storeGroupId: String!, $countryCode: String!) {
    deleteStoreMarket(storeGroupId: $storeGroupId, countryCode: $countryCode) {
      status
    }
  }
`

export default DELETE_STORE_MARKET
