import React from "react"
import { Control, Controller } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import { SidebarDivider } from "../../Dashboard/Shared.styled"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { BonusConfig, InputBonusConfig } from "@lib/types/generated/graphql-types"

interface ExtendedInputBonusConfig extends InputBonusConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputBonusConfig>
  errors: FieldErrors
  showId?: boolean
  defaultValues?: BonusConfig
}

const BonusConfigInput = ({ control, errors, showId, defaultValues }: Props) => {
  const isValidHttpUrl = (string: string) => {
    try {
      const newUrl = new URL(string)
      return newUrl.protocol === "https:"
    } catch (err) {
      return false
    }
  }

  return (
    <>
      {showId && (
        <Controller
          name="id"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="Identifier *"
              description="For internal use in Brink"
              placeholder="Aa - Zz, 0 - 9, -_."
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field",
            pattern: {
              value: /^[A-Za-z0-9_.-]+$/,
              message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
            }
          }}
          defaultValue=""
        />
      )}
      <SidebarDivider />
      <Controller
        name="baseUrl"
        render={({ field }) => <Input {...field} type="text" label="Base URL *" errors={errors} />}
        control={control}
        rules={{
          validate: {
            url: (value) => {
              if (!isValidHttpUrl(value)) return "Must be https format"
            }
          },
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.baseUrl ?? ""}
      />
      <Controller
        name="authUrl"
        render={({ field }) => <Input {...field} type="text" errors={errors} label="Auth URL *" />}
        control={control}
        rules={{
          validate: {
            url: (value) => {
              if (!isValidHttpUrl(value)) return "Must be https format"
            }
          },
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.authUrl ?? ""}
      />
      <Controller
        name="clientId"
        render={({ field }) => <Input {...field} type="text" errors={errors} label="Client ID *" />}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="clientSecret"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Client secret *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
    </>
  )
}

export default BonusConfigInput
