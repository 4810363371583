import gql from "graphql-tag"

const VALIDATE_SVEA = gql`
    query validateSvea($baseUrl: String!, $checkoutSecret: String!, $merchantId: String!) {
        Configuration {
            validate {
                sveaCheckout(baseUrl: $baseUrl, checkoutSecret: $checkoutSecret, merchantId: $merchantId) {
                    errorMessage
                    valid
                }
            }
        }
    }
`

export default VALIDATE_SVEA
