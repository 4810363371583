import React from "react"
import { Order, ShippingFee } from "@lib/types/generated/graphql-types"
import { Name, RefundButtonWrapper, Row } from "../Shared.styled"

import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import CREATE_REFUND from "../../../../../graphql/mutations/order/refunds/CreateRefund"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import UPDATE_REFUND from "../../../../../graphql/mutations/order/refunds/UpdateRefund"
import { ReactComponent as RefundIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"
import { ReactComponent as ShippingIcon } from "../../../../../images/icons/cart-flatbed-boxes.svg"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import Status from "../../../../Ui/Status"
import { getRefundInput } from "../Helpers/helpers"

type Props = {
  shippingFee: ShippingFee | undefined
  order: Order
  refetch: () => void
}

export const ShippingRow = ({ shippingFee, order, refetch }: Props) => {
  const dispatch = useDispatch()
  const refunds = order.Refunds
  const currencyCode = order.currencyCode

  const notCompletedRefund = refunds.find(
    (refund) => !refund.started || (refund.started && !refund.completed)
  )
  const shippingFeeRefunded = order.Refunds.filter((refund) => !!refund.completed).find((refund) =>
    refund?.shippingFees?.find((fee) => fee.shippingFeeId == shippingFee?.id)
  )

  const shippingFeeExistsInRefund = notCompletedRefund?.shippingFees.find(
    (fee) => fee.shippingFeeId === shippingFee?.id
  )

  const [createRefund, { loading: createLoading }] = useMutation(CREATE_REFUND, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const [updateRefund, { loading: updateLoading }] = useMutation(UPDATE_REFUND, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const createOrUpdateOrderRefund = () => {
    if (!notCompletedRefund) {
      return createRefund({
        variables: {
          orderId: order.id,
          input: {
            shippingFees: [shippingFee?.id]
          }
        }
      })
    }
    return updateRefund({
      variables: {
        refundId: notCompletedRefund.id,
        input: {
          ...getRefundInput(notCompletedRefund),
          shippingFees: [
            ...notCompletedRefund.shippingFees.map((fee) => fee.shippingFeeId),
            shippingFee?.id
          ]
        }
      }
    })
  }

  return (
    <Row key={shippingFee?.id}>
      <ShippingIcon />
      <div>
        <Name>{shippingFee?.name}</Name>
        <Money
          amount={shippingFee?.salePriceAmount ?? 0}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>
      </div>
      <RefundButtonWrapper>
        {shippingFeeRefunded ? (
          <Status status="Refunded" />
        ) : (
          <SecondaryButton
            handleClick={createOrUpdateOrderRefund}
            disabled={!!shippingFeeExistsInRefund}
            loading={createLoading || updateLoading}
          >
            <RefundIcon />
            Refund
          </SecondaryButton>
        )}
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={shippingFee?.salePriceAmount ?? 0}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </RefundButtonWrapper>
    </Row>
  )
}
