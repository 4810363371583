import gql from "graphql-tag"

const GET_PRODUCT_VARIANT_STOCK = gql`
  query GetProduct($id: ID!) {
    Product {
      variant(id: $id) {
        Stock {
          stockQuantity
        }
      }
    }
  }
`

export default GET_PRODUCT_VARIANT_STOCK
