import gql from "graphql-tag";

const DELETE_INGRID_CONFIG = gql`
    mutation deleteIngridConfig($id: ID!) {
        deleteIngridConfig(id: $id) {
            status
        }
    }
`;

export default DELETE_INGRID_CONFIG;
