import gql from "graphql-tag"

const UPDATE_INVENTORY_STORE_MARKET_INVENTORIES = gql`
  mutation updateInventoryStoreMarketInventories(
    $storeGroupId: ID!
    $countryCode: String!
    $inventoryIds: [String!]!
  ) {
    updateInventoryStoreMarketInventories(
      storeGroupId: $storeGroupId
      countryCode: $countryCode
      inventoryIds: $inventoryIds
    ) {
      storeGroupId
      countryCode
      inventoryIds
    }
  }
`

export default UPDATE_INVENTORY_STORE_MARKET_INVENTORIES
