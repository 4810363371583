import styled from "styled-components/macro"
import { ActionDropdownButton } from "../../../../Ui/Buttons/ActionDropdownButton"

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.6rem;
  margin-bottom: 0.5rem;
  background: ${p => p.theme.colors.white};
  align-items: center;

  border: 0.1rem solid ${p => p.theme.colors.borderLight};
 button {
   height: 3rem;
 }

  svg {
    height: 2rem;
    margin-right: 1rem;
  }
`

export const LabelWrapper = styled.div`
display: flex;
  align-items: center;
`

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  p {
    display: inline-block;
    word-break: break-word;
    font-size: 1.1rem;
  }
`

export const GiftCardInputWrapper = styled.div`
display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 0.5rem;
  p {
    margin: 0;
    font-size: 1rem;
  }
`

export const StyledActionDropdownButton = styled(ActionDropdownButton)`
background: aliceblue;
`
