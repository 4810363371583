import React from "react"
import OrderBlock from "../OrderBlock"
import { List } from "../../Ui/List/List"
import { BundleOutcome, OrderLine } from "@lib/types/generated/graphql-types"
import { Link } from "react-router-dom"
import placeholder from "../../../images/placeholder.svg"
import {
  Id,
  ImageAndDetails,
  ImageContainer,
  OrderlineName,
  ParentId,
  ProductImage,
  Quantity,
  StyledTableHeader
} from "./Bundle.styled"
import Bundle from "./Bundle"

type BundleOrderLine = {
  orderLine: OrderLine
  bundles: BundleOutcome[]
}

type Props = {
  bundleOrderLine: BundleOrderLine | undefined
  currencyCode: string
}

export const BundleDiscount = ({ bundleOrderLine, currencyCode }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  if (!bundleOrderLine) return null

  const { orderLine } = bundleOrderLine

  return (
    <OrderBlock key={orderLine.id}>
      <List>
        <>
          <ImageAndDetails>
            <ImageContainer>
              <ProductImage src={orderLine.imageUrl as string} onError={handleImageError} />
            </ImageContainer>
            <div>
              <OrderlineName>{orderLine.displayName}</OrderlineName>
              <Id>
                <b>Id: </b>
                {orderLine.productVariantId} |{" "}
                <ParentId>
                  <b>Parent Id:</b>{" "}
                  <Link to={`/products/${orderLine.productParentId}`}>
                    {orderLine.productParentId}
                  </Link>
                </ParentId>
              </Id>
              <Quantity>
                <b>Quantity:</b> {orderLine.quantity}
              </Quantity>
            </div>
          </ImageAndDetails>
          <StyledTableHeader>
            <div>Bundle group</div>
            <div style={{ textAlign: "right" }}>Discount value</div>
          </StyledTableHeader>
          {bundleOrderLine?.bundles?.map((bundle) => (
            <Bundle key={bundle.bundleId} bundle={bundle} currencyCode={currencyCode} />
          ))}
        </>
      </List>
    </OrderBlock>
  )
}

export default BundleDiscount
