import styled from "styled-components/macro"
import PrimaryButton from "./PrimaryButton"

export const Container = styled.div`
  position: relative;
`

export const DropDownButton = styled(PrimaryButton)`
  padding-right: 1.5rem;

  span {
    svg {
      margin-left: 1.5rem;
      margin-right: 0;
    }
  }
`

export const Flex = styled.div`
  display: flex;
`

export const SaveButton = styled(PrimaryButton)`
  border-radius: 0.5rem 0 0 0.5rem;
  margin-left: 0;
`

export const ArrowDownButton = styled(PrimaryButton)`
  border-radius: 0 0.5rem 0.5rem 0;
  padding: 0 1.5rem;
  margin-left: 0.1rem !important;

  svg {
    margin-right: 0;
  }
`

export const Dropdown = styled.ul`
  position: absolute;
  right: 0;
  background: ${(p) => p.theme.colors.white};
  width: 25rem;
  padding: 1rem;
  box-shadow: 0 2px 12px 3px #1f274c15;
  border-radius: 0.3rem;
  z-index: 10;

  li {
    height: 4rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0 1.5rem;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 0.3rem;

    &:hover {
      background: ${(p) => p.theme.colors.background};
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`
