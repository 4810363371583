import React, { useEffect } from "react"
import { useRoutes } from "react-router-dom"

import { Layout } from "layouts/Layout"
import {
  protectedCampaignViews,
  protectedSuperUserViews,
  protectedUserAdminViews,
  views
} from "views"
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth"
import {
  setAuthenticationUserAttributes,
  setAuthenticationUserGroups
} from "lib/store/services/auth/slice"
import { useDispatch, useSelector } from "react-redux"
import { selectUserGroups } from "lib/store/services/auth/selectors"
import { useConfig } from "./configuration/useConfig"

export default function SecureRoutes() {
  const dispatch = useDispatch()
  const userGroups = useSelector(selectUserGroups)
  const userAdminAccess = userGroups?.includes("brinkcommerce-useradmin")
  const superUserAccess = userGroups?.includes("brinkcommerce-superuser")
  const campaignUserAccess = userGroups?.includes("brinkcommerce-campaignuser")
  const readOnlyUserAccess = userGroups?.includes("brinkcommerce-readonly")
  const { campaignEnabled } = useConfig().config

  useEffect(() => {
    fetchAuthSession &&
      fetchAuthSession().then((res) =>
        dispatch(
          setAuthenticationUserGroups({
            userGroups: res.tokens?.accessToken.payload["cognito:groups"]
          })
        )
      )
    fetchUserAttributes &&
      fetchUserAttributes().then((res) =>
        dispatch(setAuthenticationUserAttributes({ userAttributes: res }))
      )
  }, [])

  const routes = [
    ...views,
    ...(superUserAccess ? protectedSuperUserViews : []),
    ...(userAdminAccess ? protectedUserAdminViews : []),
    ...(campaignEnabled && (superUserAccess || campaignUserAccess || readOnlyUserAccess)
      ? protectedCampaignViews
      : [])
  ]

  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: routes
    }
  ])
}
