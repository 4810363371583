import React, { useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import { User } from "lib/types/generated/graphql-types"
import styled from "styled-components/macro"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import USERS from "graphql/queries/user/Users"

import PageHeader from "components/Ui/Page/PageHeader"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { UserTable } from "components/User/UserTable"
import { UpdateUser } from "./UpdateUser"
import { CreateUser } from "./CreateUser"
import { GroupUsers } from "components/User/GroupUsers"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

const ShowMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  button {
    padding: 1rem 3rem;
  }
`

export const ShowUsers = () => {
  const [editUser, setEditUser] = useState<string>("")
  const [users, setUsers] = useState<User[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)

  const [getUsers, { loading: fetchMoreLoading, error: fetchMoreError }] = useLazyQuery(USERS)
  const dispatch = useDispatch()

  const { refetch, error, loading } = useQuery(USERS, {
    variables: { limit: 10 },
    onCompleted: (data) => {
      setUsers(data?.users?.users)
      setNextToken(data?.users?.nextToken)
    }
  })

  const fetchMoreUsers = () => {
    getUsers({ variables: { limit: 10, token: nextToken } }).then((result) => {
      setUsers([...users, ...result.data.users.users])
      setNextToken(result?.data?.users?.nextToken)
    })
  }

  return (
    <>
      {!editUser && <CreateUser refetch={refetch} />}
      <PageHeader
        title="Users"
        description="Manage users from Brink Commerce for Merchant Portal."
        hasTabs={true}
      >
        <PrimaryButton type="submit" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon />
          Create new user
        </PrimaryButton>
      </PageHeader>
      <Tabs>
        <TabList>
          <Tab>All users</Tab>
          <Tab>Read-only</Tab>
          <Tab>Superuser</Tab>
          <Tab>User admin</Tab>
        </TabList>
        <TabPanel>
          {editUser && (
            <UpdateUser editUser={editUser} setEditUser={setEditUser} refetch={refetch} />
          )}
          {loading && !nextToken ? (
            <BrinkLoader />
          ) : (
            <UserTable
              users={users}
              error={error || fetchMoreError}
              editId={editUser}
              setEditId={setEditUser}
            />
          )}

          {nextToken && (
            <ShowMoreWrapper>
              <PrimaryButton loading={fetchMoreLoading} handleClick={() => fetchMoreUsers()}>
                Show more users
              </PrimaryButton>
            </ShowMoreWrapper>
          )}
        </TabPanel>
        <TabPanel>
          <GroupUsers
            groupname="brinkcommerce-readonly"
            setEditUser={setEditUser}
            editUser={editUser}
          />
        </TabPanel>
        <TabPanel>
          <GroupUsers
            groupname="brinkcommerce-superuser"
            setEditUser={setEditUser}
            editUser={editUser}
          />
        </TabPanel>
        <TabPanel>
          <GroupUsers
            groupname="brinkcommerce-useradmin"
            setEditUser={setEditUser}
            editUser={editUser}
          />
        </TabPanel>
      </Tabs>
    </>
  )
}
