import React, { Dispatch, SetStateAction } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { useAppDispatch } from "lib/store"

import GET_INVENTORY from "../../graphql/queries/inventory/GetInventory"
import CREATE_OR_UPDATE_INVENTORY from "../../graphql/mutations/inventory/CreateOrUpdateInventory"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import alertActions from "lib/store/services/Alert/AlertSlice"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import DeleteButton from "../../components/Ui/EditSidebar/DeleteButton"
import DELETE_INVENTORY from "../../graphql/mutations/inventory/DeleteInventory"
import { InputInventory } from "@lib/types/generated/graphql-types"

type Props = {
  id: string
  setEditId: Dispatch<SetStateAction<string>>
  refetch: () => void
}

const UpdateInventories = ({ id, setEditId, refetch }: Props) => {
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<InputInventory>()

  const { loading, error, data } = useQuery(GET_INVENTORY, {
    variables: { id: id }
  })

  const [updateInventory, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_INVENTORY,
    {
      onCompleted: () => {
        refetch()
        dispatch(
          alertActions.actions.createAlert({
            message: "Inventory successfully updated",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputInventory> = (data) => {
    updateInventory({
      variables: {
        ...data,
        id
      }
    })
  }

  if (loading) return <EditSidebar loading={true} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading Inventory. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const { name, description, address } = data.getInventory

  return (
    <EditSidebar setEditId={setEditId}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader title={name} id={id} setEditId={setEditId}>
          <DeleteButton
            id={id}
            name={name}
            entity={"Inventory"}
            redirectUrl="/stores/inventories"
            mutation={DELETE_INVENTORY}
            refetch={refetch}
            postDeleteFunction={() => setEditId("")}
            secureDelete={true}
          />
          <PrimaryButton
            type="submit"
            handleClick={handleSubmit(onSubmit)}
            loading={createLoading}
          >
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <div>
          <Controller
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Name *"
                placeholder="Name"
                errors={errors}
              />
            )}
            defaultValue={name}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="description"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Description *"
                placeholder="Description"
                errors={errors}
              />
            )}
            defaultValue={description}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="address.city"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="City *"
                placeholder="City"
                errors={errors}
              />
            )}
            defaultValue={address?.city}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="address.country"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Country *"
                placeholder="Country"
                errors={errors}
              />
            )}
            defaultValue={address?.country}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="address.streetAddress"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Street address *"
                placeholder="Street Address"
                errors={errors}
              />
            )}
            defaultValue={address?.streetAddress}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="address.postalCode"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Postal code *"
                placeholder="Postal code"
                errors={errors}
              />
            )}
            defaultValue={address?.postalCode}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="address.region"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Region *"
                placeholder="Region"
                errors={errors}
              />
            )}
            defaultValue={address?.region}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
        </div>

        <hr />
      </form>
    </EditSidebar>
  )
}

export default UpdateInventories
