import React from "react"
import { Flex, Link, useAuthenticator } from "@aws-amplify/ui-react"

const SignInFooter = () => {
  const { toForgotPassword } = useAuthenticator()

  return (
    <Flex>
      <Link onClick={toForgotPassword}>Forgot your password?</Link>
    </Flex>
  )
}

export default SignInFooter
