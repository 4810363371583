import React, { useState } from "react"
import DineroFactory from "dinero.js"
import { Link } from "react-router-dom"
import * as graphql from "@lib/types/generated/graphql-types"

import {
  BasePrice,
  Container,
  Content,
  Delivered,
  ExpandIcon,
  FieldTitle,
  Footer,
  Id,
  ImageAndDetails,
  ImageContainer,
  Name,
  ParentId,
  Price,
  PriceInfo,
  ProductImage,
  Qty,
  Refunded,
  Row,
  SalePrice,
  TotalTitle
} from "./OrderLine.styled"
import Money from "components/Money"
import placeholder from "images/placeholder.svg"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"

type Props = {
  orderLine: graphql.OrderLine
  orderHistory: graphql.OrderHistory
}

export const Orderline = ({ orderLine, orderHistory }: Props) => {
  const [showFullSummary, setShowFullSummary] = useState<boolean>(false)

  const getOrderRefund = () =>
    orderHistory.operations?.refunded.orderLines.find((ol) => ol?.orderLineId === orderLine.id)

  const getOrderDelivery = () =>
    orderHistory.operations?.delivered.orderLines.find((ol) => ol?.orderLineId === orderLine.id)

  const hasDiscountPrice = orderLine.salePriceAmount < orderLine.basePriceAmount

  if (!orderLine) return null

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const getPercentage = (discountAmount: number, salePriceAmount: number) => {
    return ((discountAmount / salePriceAmount) * 100).toFixed(2)
  }

  return (
    <Container onClick={() => setShowFullSummary(!showFullSummary)}>
      <Content>
        <div>
          <ImageAndDetails>
            <ImageContainer $showFullSummary={showFullSummary}>
              <ProductImage
                $showFullSummary={showFullSummary}
                src={orderLine.imageUrl as string}
                onError={handleImageError}
              />
            </ImageContainer>
            <div>
              <Name>{orderLine.displayName}</Name>
              <Id>
                <b>Id: </b>
                {orderLine.productVariantId} |{" "}
                <ParentId>
                  <b>Parent Id:</b>{" "}
                  <Link to={`/products/${orderLine.productParentId}`}>
                    {orderLine.productParentId}
                  </Link>
                </ParentId>
              </Id>
              <Price>
                <BasePrice $hasDiscountPrice={hasDiscountPrice}>
                  <Money
                    amount={parseInt(`${orderLine.basePriceAmount}`)}
                    currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                  />
                </BasePrice>
                {hasDiscountPrice && (
                  <SalePrice>
                    <Money
                      amount={parseInt(`${orderLine.salePriceAmount}`)}
                      currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                    />
                  </SalePrice>
                )}
              </Price>
            </div>
          </ImageAndDetails>
        </div>
        <Qty>
          <div>
            <b>Quantity:</b> {orderLine.quantity}
          </div>
        </Qty>
        <div>
          <Row>
            <FieldTitle>Tax rate:</FieldTitle>
            <span>
              {(orderLine.taxPercentage || 0) / Math.pow(10, orderLine.taxPercentageDecimals || 0)}%
            </span>
          </Row>
          <Row>
            <FieldTitle>Total tax:</FieldTitle>
            <span>
              <Money
                amount={parseInt(`${orderLine.totalTaxAmount}`)}
                currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
              />
            </span>
          </Row>
          <PriceInfo $showFullSummary={showFullSummary}>
            <Row>
              <FieldTitle>Base price:</FieldTitle>
              <span>
                <Money
                  amount={parseInt(`${orderLine.basePriceAmount}`)}
                  currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                />
              </span>
            </Row>
            {hasDiscountPrice && (
              <Row>
                <FieldTitle>Sale price:</FieldTitle>
                <span>
                  <Money
                    amount={parseInt(`${orderLine.salePriceAmount}`)}
                    currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Row>
            )}
            {orderLine.discountAmount > 0 && (
              <Row>
                <FieldTitle>Product discount:</FieldTitle>
                <span>
                  -
                  <Money
                    amount={parseInt(`${orderLine.discountAmount}`)}
                    currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Row>
            )}
            {orderLine.discountAmount > 0 && orderLine.quantity > 1 && (
              <Row>
                <FieldTitle>Total product discount:</FieldTitle>
                <span>
                  -
                  <Money
                    amount={parseInt(`${orderLine.discountAmount * orderLine.quantity}`)}
                    currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Row>
            )}
            {orderLine.discountOutcome.totalDiscountAmount !== 0 && (
              <Row>
                <FieldTitle>Discount from rule(s):</FieldTitle>
                <span>
                  -
                  <Money
                    amount={parseInt(`${orderLine.discountOutcome.totalDiscountAmount}`)}
                    currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
                  />
                </span>
              </Row>
            )}
            {orderLine?.discountOutcome?.codeRules &&
              orderLine?.discountOutcome?.codeRules.length !== 0 && (
                <>
                  {orderLine?.discountOutcome?.codeRules.map((codeRule) => (
                    <Row key={codeRule.code}>
                      <FieldTitle>Code {codeRule.code}:</FieldTitle>
                      <span>
                        {getPercentage(
                          codeRule?.discountAmount ?? 0,
                          orderLine.salePriceAmount * orderLine.quantity
                        )}
                        %
                      </span>
                    </Row>
                  ))}
                </>
              )}
            {orderLine.basePriceAmount - orderLine.totalPriceAmount / orderLine.quantity > 0 && (
              <Row>
                <FieldTitle>Total discount %:</FieldTitle>
                <span>
                  {getPercentage(
                    orderLine.basePriceAmount - orderLine.totalPriceAmount / orderLine.quantity,
                    orderLine.basePriceAmount
                  )}
                  %
                </span>
              </Row>
            )}
          </PriceInfo>
        </div>
      </Content>
      <Footer>
        <div>
          {getOrderDelivery() && (
            <Delivered>
              <b>Delivered quantity: {getOrderDelivery()?.quantity}</b>
            </Delivered>
          )}
          {getOrderRefund() && (
            <Refunded>
              <b>Refunded quantity: {getOrderRefund()?.quantity}</b>
            </Refunded>
          )}
        </div>
        <div>
          <TotalTitle>Total:</TotalTitle>
          <TotalTitle>
            <Money
              amount={parseInt(`${orderLine.totalPriceAmount}`)}
              currencyUnit={orderLine.currencyCode as DineroFactory.Currency}
            />
          </TotalTitle>
        </div>
      </Footer>
      <ExpandIcon $showFullSummary={showFullSummary}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}
