import React, { useState } from "react"
import { AdyenEnvironment, InputAdyenConfig } from "lib/types/generated/graphql-types"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLazyQuery, useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import {
  CREATE_OR_UPDATE_ADYEN_CONFIG,
  GENERATE_WEBHOOKS
} from "graphql/mutations/config/CreateOrUpdateAdyenConfig"
import VALIDATE_ADYEN from "graphql/queries/config/ValidateAdyenConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"
import AdyenConfigInputs from "../../../../components/Config/Adyen/AdyenConfigInputs"


type Props = {
  refetch: () => void
}

interface ExtendedInputAdyenConfig extends InputAdyenConfig {
  id: string
}

function CreateAdyenConfig({ refetch }: Props) {
  const dispatch = useAppDispatch()
  const [validationError, setValidationError] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputAdyenConfig>({ mode: "onBlur" })

  const [generateWebhooks, { loading: webhooksLoading }] = useMutation(GENERATE_WEBHOOKS, {
    onCompleted: () => {
      reset({
        id: "",
        apiKey: "",
        clientKey: "",
        hmac: "",
        liveEndpointUrlPrefix: "",
        merchantAccount: ""
      })
      dispatch(hideEditSidebar())
      dispatch(
        alertActions.actions.createAlert({
          message: "Adyen configuration successfully created",
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [addAdyenConfig, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_ADYEN_CONFIG, {
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [validateAdyen, { loading: validateLoading }] = useLazyQuery(VALIDATE_ADYEN)

  const getBaseUrl = (type: AdyenEnvironment, liveUrlPrefix: string) =>
    type === AdyenEnvironment.Test
      ? "https://checkout-test.adyen.com"
      : `https://${liveUrlPrefix}-checkout-live.adyenpayments.com/checkout`

  const onSubmit: SubmitHandler<ExtendedInputAdyenConfig> = (data) => {
    validateAdyen({
      variables: {
        baseUrl: getBaseUrl(data.environment, data.liveEndpointUrlPrefix ?? ""),
        merchantAccount: data.merchantAccount,
        xApiKey: data.apiKey,
        environment: data.environment
      }
    }).then((response) => {
      if (!response.data.Configuration.validate.adyen.valid) {
        setValidationError(true)
        return
      }

      return addAdyenConfig({ variables: { ...data } }).then(() =>
        generateWebhooks({ variables: { ...data } })
      )
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Adyen configuration">
          {validationError ? (
            <Popup
              title="Validation error"
              subtitle="Couldn't validate provided credentials with Adyen."
              buttonText="Ok"
              handleOkClick={() => setValidationError(false)}
              handleCloseClick={() => setValidationError(false)}
            />
          ) : (
            <></>
          )}
          <PrimaryButton
            type="submit"
            loading={validateLoading || createLoading || webhooksLoading}
          >
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <AdyenConfigInputs control={control} errors={errors} setValue={setValue} showId={true}/>
      </EditSidebar>
    </form>
  )
}

export default CreateAdyenConfig
