import gql from "graphql-tag"

const ALL_STORE_GROUPS = gql`
  query getStoreGroups {
    getStoreGroups {
      id
      name
      description
      channelType
      storeMarkets {
        name
        countryCode
        currencyCode
        isTaxIncludedInPrice
        capabilities {
          shippingProviders {
            id
            type
          }
          paymentProviders {
            id
            type
          }
        }
        cartCapabilities {
          voyado {
            id
          }
          bonus {
            id
          }
        }
      }
    }
  }
`

export default ALL_STORE_GROUPS
