import gql from "graphql-tag";

const DELETE_INVENTORY = gql`
  mutation deleteInventory($id: ID!) {
    deleteInventory(id: $id) {
      status
    }
  }
`;

export default DELETE_INVENTORY;
