import gql from "graphql-tag"

const GENERATE_DISCOUNT_CODES = gql`
  mutation createGenerateCodes(
    $discountCodeRuleId: String!
    $usageLimit: Int!
    $numberOfCodes: Int!
    $prefix: String!
  ) {
    createGenerateCodes(
      input: {
        discountCodeRuleId: $discountCodeRuleId
        usageLimit: $usageLimit
        numberOfCodes: $numberOfCodes
        prefix: $prefix
      }
    ) {
      jobId
    }
  }
`

export default GENERATE_DISCOUNT_CODES
