import React from "react"

import { Blur, Container, ButtonWrapper } from "./EditPopup.styled"
import PrimaryButton from "../Buttons/PrimaryButton"
import SecondaryButton from "../Buttons/SecondaryButton"
import { isCampaignUser } from "helpers/user"

type Props = {
  children?: React.ReactNode
  title: string
  buttonText?: string
  disableButton?: boolean
  loading?: boolean
  handleOkClick?: () => void
  handleCloseClick?: React.MouseEventHandler
  hideOkButton?: boolean
}

export const EditPopup = ({
  children,
  title,
  buttonText,
  disableButton,
  loading,
  handleOkClick,
  handleCloseClick,
  hideOkButton
}: Props) => (
  <>
    <Blur onClick={handleCloseClick} />
    <Container>
      <h2>{title}</h2>
      {children}
      <ButtonWrapper>
        <SecondaryButton handleClick={handleCloseClick} overrideDisabled={isCampaignUser()}>
          Cancel
        </SecondaryButton>
        {!hideOkButton && (
          <PrimaryButton disabled={disableButton} handleClick={handleOkClick} loading={loading}>
            {buttonText ?? "Ok"}
          </PrimaryButton>
        )}
      </ButtonWrapper>
    </Container>
  </>
)
