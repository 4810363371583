import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"

import { Name } from "components/Ui/Table/Shared.styled"

import { BlurRow } from "components/Ui/Table/TableBlurLoading"
import TableRow from "components/Ui/Table/TableRow"

import { ReactComponent as Logo } from "images/providers/icons/avarda.svg"
import GET_AVARDA_CONFIG from "../../../graphql/queries/config/Providers/Avarda/GetAvardaConfig"

export const getLoadingRow = () => (
  <>
    <Logo />
    <div>
      <Name>Identifier</Name>
    </div>
    <div>
      <p>TEST</p>
    </div>
    <div>
      <p>https://url.com</p>
    </div>
    <div>
      <p>https://url.com</p>
    </div>
    <div className="hideOnSmallScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setEditId?: Dispatch<SetStateAction<string>>
}

export const AvardaConfigTableRow = ({ id, setEditId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_AVARDA_CONFIG, {
    variables: { id: id }
  })

  const avardaConfig = data?.getAvardaConfig
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>

  if (!avardaConfig) return null

  const { created, updated, baseUrl, authUrl } = avardaConfig

  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setEditId) setEditId(avardaConfig.id)
        dispatch(toggleEditSidebar())
      }}
    >
      <Logo />
      <div>
        <Name>{id}</Name>
      </div>
      <div>
        <p>{baseUrl}</p>
      </div>
      <div>
        <p>{authUrl}</p>
      </div>
      <div className="hideOnSmallScreens">
        <p>{dayjs(created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens">
        <p>{dayjs(updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
