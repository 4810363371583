import gql from "graphql-tag"

const ALL_INVENTORIES = gql`
  query getInventories {
    getInventories {
      id
      name
      description
      address {
        country
        region
        postalCode
        city
        streetAddress
      }
      created
      updated
    }
  }
`

export default ALL_INVENTORIES
