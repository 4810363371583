import React, { useState } from "react"
import DineroFactory from "dinero.js"
import * as graphql from "@lib/types/generated/graphql-types"
import { ReactComponent as CardIcon } from "images/icons/gift-card.svg"

import {
  BasePrice,
  Container,
  Content, Delivered,
  ExpandIcon,
  FieldTitle,
  Footer,
  IconWrapper,
  Id,
  ImageAndDetails,
  Name,
  Price,
  PriceInfo,
  Row,
  TotalTitle
} from "./OrderLine.styled"
import Money from "../../../../components/Money"

import { ReactComponent as DownIcon } from "../../../../images/icons/chevron-down.svg"

type Props = {
  giftCardProductLine: graphql.OrderGiftCardProductItemRetain24
  order: graphql.Order
  orderHistory: graphql.OrderHistory
}

export const GiftCardProductLine = ({ giftCardProductLine, orderHistory }: Props) => {
  const [showFullSummary, setShowFullSummary] = useState<boolean>(false)
  const getOrderDelivery = () =>
    orderHistory.operations?.delivered?.giftCardProducts?.find(
      (giftCardProduct) => giftCardProduct?.giftCardProductId === giftCardProductLine.id
    )

  if (!giftCardProductLine) return null

  return (
    <Container onClick={() => setShowFullSummary(!showFullSummary)}>
      <Content>
        <div>
          <ImageAndDetails>
            <IconWrapper $showFullSummary={showFullSummary}>
              <CardIcon />
            </IconWrapper>
            <div>
              <Name>{giftCardProductLine.name}</Name>
              <Id>
                <b>Id: </b>
                {giftCardProductLine.id}
              </Id>
              <Price>
                <BasePrice $hasDiscountPrice={false}>
                  <Money
                    amount={parseInt(`${giftCardProductLine.amount}`)}
                    currencyUnit={giftCardProductLine.currencyCode as DineroFactory.Currency}
                  />
                </BasePrice>
              </Price>
            </div>
          </ImageAndDetails>
        </div>
        <div>
          <PriceInfo $showFullSummary={showFullSummary}>
            <Row>
              <FieldTitle>Amount:</FieldTitle>
              <span>
                <Money
                  amount={parseInt(`${giftCardProductLine.amount}`)}
                  currencyUnit={giftCardProductLine.currencyCode as DineroFactory.Currency}
                />
              </span>
            </Row>
            {giftCardProductLine.emailText && (
              <Row>
                <FieldTitle>Email text:</FieldTitle>
                {giftCardProductLine.emailText}
              </Row>
            )}
            {giftCardProductLine.smsText && (
              <Row>
                <FieldTitle>SMS text:</FieldTitle>
                {giftCardProductLine.smsText}
              </Row>
            )}
            {giftCardProductLine.recipient && (
              <Row>
                <FieldTitle>Recipient:</FieldTitle>
                {giftCardProductLine.recipient}
              </Row>
            )}
            {giftCardProductLine.referenceNumber && (
              <Row>
                <FieldTitle>Reference:</FieldTitle>
                {giftCardProductLine.referenceNumber}
              </Row>
            )}
            {giftCardProductLine.templateId && (
              <Row>
                <FieldTitle>Template Id:</FieldTitle>
                {giftCardProductLine.templateId}
              </Row>
            )}
            {giftCardProductLine.merchantReference && (
              <Row>
                <FieldTitle>Merchant Reference:</FieldTitle>
                {giftCardProductLine.merchantReference}
              </Row>
            )}
          </PriceInfo>
        </div>
      </Content>

      <Footer>
        {getOrderDelivery() && <Delivered><b>Delivered</b></Delivered>}
        <div>
          <TotalTitle>Total:</TotalTitle>
          <TotalTitle>
            <Money
              amount={parseInt(`${giftCardProductLine.amount}`)}
              currencyUnit={giftCardProductLine.currencyCode as DineroFactory.Currency}
            />
          </TotalTitle>
        </div>
      </Footer>
      <ExpandIcon $showFullSummary={showFullSummary}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}
