import gql from "graphql-tag"

const UPDATE_API_CLIENT = gql`
    mutation updateApiClient($clientId: ID!, $name: String!, $scopes: [String!]!) {
        updateApiClient(clientId: $clientId ,input: { name: $name, scopes: $scopes }) {
            clientId
            clientSecret
            name
            scopes
        }
    }
`
export default UPDATE_API_CLIENT
