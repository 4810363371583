import React, { useState, MutableRefObject } from "react"
import styled from "styled-components/macro"
import { useForm, SubmitHandler } from "react-hook-form"
import { Common } from "@lib/types"
import { FrontendOutcome, Outcomes } from "@lib/types/discount"
import {
  InputOutcome,
  InputSubOutcome,
  TagCondition,
  InputDiscountValueType
} from "lib/types/generated/graphql-types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import { TagConditions } from "components/Discount/Ui/TagConditions/TagConditions"
import { Suboutcome } from "components/Discount/Suboutcome"

const Divider = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin-top: 3rem;
`

const Label = styled.h3`
  margin-bottom: 0;
`

const Description = styled.span`
  color: ${(p) => p.theme.colors.greyDarker};
`

const SuboutcomeTitle = styled.span`
  text-align: left;
  font-weight: 700;
`

const Suboutcomes = styled.div`
  margin: 2rem 0;

  p {
    text-align: center;
  }
`

type Props = {
  outcome?: FrontendOutcome
  addOutcome: (data: Outcomes) => void
  currencyOptions: Common.Option[]
  submitRef: MutableRefObject<HTMLButtonElement>
}

export const OptimizeBuyGets = ({ outcome, addOutcome, currencyOptions, submitRef }: Props) => {
  const dispatch = useAppDispatch()

  const valueTypes = (
    outcome?.data?.subOutcomes?.map((subOutcome) => subOutcome?.discountValue?.type) || []
  ).filter((type): type is string => type !== undefined)

  const [tagConditions, setTagConditions] = useState<TagCondition[]>(
    outcome?.data?.discountItemRule?.tagConditions ?? []
  )
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(
    outcome?.data?.discountItemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPrices, setExcludeFullPrices] = useState(
    outcome?.data?.discountItemRule?.excludeFullPrices ?? false
  )

  const {
    handleSubmit,
    setValue,
    control,
    register,
    formState: { errors }
  } = useForm<InputOutcome>()

  const onSubmit: SubmitHandler<InputOutcome> = (data) => {
    if (data?.subOutcomes?.some((subOutcome) => !subOutcome?.discountValue?.type)) {
      dispatch(
        alertActions.actions.createAlert({
          message: "Please select a discount value type",
          type: "error"
        })
      )
      return
    }

    if (
      valueTypes.includes("FIXED") &&
      data.subOutcomes?.some(
        (subOutcome) =>
          subOutcome?.discountValue?.type === InputDiscountValueType.Fixed &&
          subOutcome?.discountValue?.fixedDiscounts?.length === 0
      )
    ) {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one threshold is required",
          type: "error"
        })
      )
      return
    }

    addOutcome({
      subOutcomes: data.subOutcomes as InputSubOutcome[],
      discountItemRule: {
        tagConditions: tagConditions,
        excludeDiscountedPrices: excludeDiscountedPrices,
        excludeFullPrices: excludeFullPrices
      }
    })
  }

  return (
    <>
      <Divider />
      <form>
        <Label>{"Suboutcomes"}</Label>
        {<Description>{"At least two suboutcomes are required."}</Description>}

        <Suboutcomes>
          {[{}, {}]?.map((subOutcome, index) => {
            return (
              <div key={index}>
                <SuboutcomeTitle>Suboutcome {index + 1}</SuboutcomeTitle>
                <Suboutcome
                  index={index}
                  outcome={outcome}
                  currencyOptions={currencyOptions}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  register={register}
                />
              </div>
            )
          })}
        </Suboutcomes>
        <button
          onClick={handleSubmit(onSubmit)}
          ref={submitRef}
          type="submit"
          style={{ display: "none" }}
        />
      </form>
      <Divider />
      <TagConditions
        tagConditions={tagConditions}
        setTagConditions={setTagConditions}
        excludeDiscountedPrices={excludeDiscountedPrices}
        setExcludeDiscountedPrices={setExcludeDiscountedPrices}
        excludeFullPrices={excludeFullPrices}
        setExcludeFullPrices={setExcludeFullPrices}
      />
    </>
  )
}
