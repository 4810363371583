import gql from "graphql-tag"

const ADD_USER_TO_GROUP = gql`
    mutation addUserToGroup(
        $username: AWSEmail!
        $groupName: String!
    ) {
        addUserToGroup(
            input: {username: $username groupname: $groupName}
        ) {
            status
        }
    }
`

export default ADD_USER_TO_GROUP