import React from "react"
import { QueryPlatformConfig } from "@lib/types/generated/graphql-types"

import { Header, Title, TableRow } from "./RateLimits.styled"

import { TooltipIcon } from "components/Ui/TooltipIcon"
import { RateLimitTable } from "components/Settings/PlatformConfig/RateLimitTable"

type Props = {
  PlatformConfig: QueryPlatformConfig
  loading: boolean
  error: object | undefined
}

export const RateLimits = ({ PlatformConfig, loading, error }: Props) => (
  <>
    <Header>
      <Title>
        Rate limits
        <a
          href="https://docs.brinkcommerce.com/retail-api/general/authentication/#rate-limits"
          target="_blank"
          rel="noreferrer"
        >
          <TooltipIcon content="Read more about Rate limits in our Docs" />
        </a>
      </Title>
      <p>Rate limits are applied to ensure fair usage and stability of the service.</p>
    </Header>
    <h2>Shopper API</h2>
    <TableRow>
      <h3>No limit</h3>
      <p>We don&apos;t have any rate limits for our Shopper API.</p>
    </TableRow>
    <h2>Management API</h2>
    <RateLimitTable services={PlatformConfig} loading={loading} error={error} />
  </>
)
