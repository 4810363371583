import styled from "styled-components/macro"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  border-radius: 0.6rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1.5rem;
  cursor: pointer;
  flex: 1;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: 1rem 0 0;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  padding-bottom: 0.2rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
  }
`

export const Number = styled.span`
  font-size: 2rem;
  cursor: pointer;
  ${(p) => p.theme.bold}

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 2.4rem;
  }
`

export const IconWrapper = styled.div`
  width: 4.4rem;
  height: 4.4rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 5rem;
    height: 5rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${(p) => p.theme.colors.black};

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`

export const SidebarDivider = styled.hr`
  margin-top: 0 !important;
`
