import gql from "graphql-tag"

const CREATE_OR_UPDATE_KLARNA_CHECKOUT_CONFIG = gql`
    mutation createOrUpdateKlarnaCheckoutConfig(
        $id: ID!
        $baseUrl: String!
        $merchantId: String!
        $password: String!
        $username: String!
        $klarnaShippingAssistantEnabled: Boolean!
    ) {
        createOrUpdateKlarnaCheckoutConfig(
            id: $id
            klarnaCheckoutConfig: {
                baseUrl: $baseUrl
                merchantId: $merchantId
                klarnaShippingAssistantEnabled: $klarnaShippingAssistantEnabled
                password: $password
                username: $username
            }
        ) {
            id
        }
    }
`

export default CREATE_OR_UPDATE_KLARNA_CHECKOUT_CONFIG
