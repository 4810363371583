import React, { useEffect } from "react"
import esb, { RequestBodySearch } from "elastic-builder"
import { Common } from "lib/types"
import Search from "./Search"

type Props = {
  setSearchQuery: (input: string) => void
  loading: boolean
  searchQuery: string
  setEsbQuery: (query: RequestBodySearch) => void
  productFilter: Common.ProductFilter
  setProductFilter: (filter: Common.ProductFilter) => void
  numberOfHitsOption: string[]
  defaultProductFilter: Common.ProductFilter
}

const VariantSearch = ({
  setSearchQuery,
  loading,
  searchQuery,
  setEsbQuery,
  productFilter
}: Props) => {
  useEffect(() => {
    const boolQuery = esb.boolQuery()
    boolQuery.must(esb.termsQuery("isActive", true))
    boolQuery.must(esb.termsQuery("isArchived", false))
    boolQuery.must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)])
    setEsbQuery(esb.requestBodySearch().query(boolQuery))
  }, [productFilter, searchQuery])

  return (
    <Search
      searchOptions={{
        handleChange: (input: string) => {
          setSearchQuery(input)
        },
        loading: loading,
        defaultValue: searchQuery
      }}
    />
  )
}
export default VariantSearch
