import React from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import { DeliveryShippingFee, Order } from "lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"

import GET_STORE_GROUP from "../../../../../graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import { Blur, Summary } from "./OrderSummary.styled"

import Money from "../../../../Money"
import { Item, Label, Value } from "../../../../Ui/List/List"
import FlagIcon from "../../../../Ui/FlagIcon"
import DotLoader from "../../../../Ui/DotLoader"
import CopyButton from "../../../../Ui/CopyButton"
import { VariantRow } from "./VariantRow"
import { GiftRow } from "./GiftRow"
import { ShippingRow } from "./ShippingRow"
import { NoticeBanner } from "../../../../Ui/Messages/NoticeBanner"

type Props = {
  order: Order
  refetch: () => void
  orderLoading: boolean
}

export const OrderSummary = ({ order, refetch }: Props) => {
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  const orderLinesInDeliveries = order?.Deliveries?.filter(
    (delivery) => !!delivery.completed
  ).flatMap((delivery) => delivery.orderLines)

  const giftsInDeliveries = order?.Deliveries?.filter((delivery) => !!delivery.completed).flatMap(
    (delivery) => delivery.gifts
  )

  const shippingFeesInDeliveries = order?.Deliveries?.filter(
    (delivery) => !!delivery.completed
  ).flatMap((delivery) => delivery.shippingFees)

  const completedCompensationsExists =
    order?.Compensations.map((compensation) => compensation.completed).length > 0

  const getOrderShippingFee = (deliveryFee: DeliveryShippingFee) =>
    order?.shippingFees?.find((shippingFee) => shippingFee?.id === deliveryFee?.shippingFeeId)

  if (!order)
    return (
      <Blur>
        <Header>
          <h2>Order: #1234567</h2>
        </Header>
        <Summary>
          <Item flexibleHeight>
            <Label>E-mail:</Label>
            <Value>
              <>
                john.doe@email.com
                <CopyButton string={""} />
              </>
            </Value>
          </Item>
          <Item>
            <Label>Store group:</Label>
            <Value>store group</Value>
          </Item>
          <Item>
            <Label>Store market:</Label>
            <Value flex>
              <>Sweden</>
            </Value>
          </Item>
          <Item>
            <Label>Order date:</Label>
            <Value>2000-00-00 00:00</Value>
          </Item>
          <Item>
            <Label>Total order value:</Label>
            <Value>SEK 200.00</Value>
          </Item>
        </Summary>
      </Blur>
    )
  if (!order) return null
  return (
    <>
      <Header>
        <h2>Order: #{order?.reference}</h2>
      </Header>
      <Summary>
        <Item flexibleHeight>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order?.billingAddress?.email}
              <CopyButton string={order?.billingAddress?.email} />
            </>
          </Value>
        </Item>
        <Item>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Item>
        <Item>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order?.countryCode} />
              {getCountryName(order?.countryCode)}
            </>
          </Value>
        </Item>
        <Item>
          <Label>Order date:</Label>
          <Value>{dayjs(order?.date).format("YYYY-MM-DD HH:mm")}</Value>
        </Item>
        <Item>
          <Label>Total order value:</Label>
          <Value>
            <Money
              amount={parseInt(`${order?.totals?.grandTotal ?? 0}`)}
              currencyUnit={order?.currencyCode as DineroFactory.Currency}
            />
          </Value>
        </Item>
      </Summary>
      {completedCompensationsExists ? (
        <NoticeBanner type="important">{`Can't create refund when compensation exists`}</NoticeBanner>
      ) : (
        <>
          <h2>Order lines ({orderLinesInDeliveries?.length ?? 0})</h2>
          {orderLinesInDeliveries?.map((orderLine) => (
            <VariantRow
              key={orderLine?.orderLineId}
              orderLine={orderLine}
              order={order}
              refetch={refetch}
              currencyCode={order?.currencyCode}
              refunds={order?.Refunds}
            />
          ))}
          {orderLinesInDeliveries?.length === 0 && (
            <NoticeBanner type="important">
              Missing completed deliveries for orderlines
            </NoticeBanner>
          )}

          {giftsInDeliveries?.length > 0 && (
            <>
              <h2>Order gifts ({giftsInDeliveries?.length ?? 0})</h2>
              {giftsInDeliveries?.map((gift) => (
                <GiftRow
                  key={gift?.giftId}
                  deliveryGift={gift}
                  order={order}
                  refetch={refetch}
                  currencyCode={order?.currencyCode}
                  refunds={order?.Refunds}
                />
              ))}
            </>
          )}
          <h2>Shipping ({shippingFeesInDeliveries?.length ?? 0})</h2>
          {shippingFeesInDeliveries?.map((shippingFee) => (
            <ShippingRow
              key={shippingFee?.shippingFeeId}
              shippingFee={getOrderShippingFee(shippingFee)}
              order={order}
              refetch={refetch}
            />
          ))}
          {shippingFeesInDeliveries?.length === 0 && (
            <NoticeBanner type="important">
              Missing completed deliveries for shipping fees
            </NoticeBanner>
          )}
        </>
      )}
    </>
  )
}
