import gql from "graphql-tag"

const GET_NSHIFT_CONFIG = gql`
    query getNShiftConfig($id: ID!) {
        getNShiftConfig(id: $id) {
            id
            created
            updated
            baseUrl
            deliveryCheckoutId
            credentials {
                clientId
                clientSecret
            }
        }
    }
`

export default GET_NSHIFT_CONFIG
