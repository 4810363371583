import React from "react"
import styled, { css } from "styled-components/macro"

type StyledProps = {
  collapse?: boolean
}

const Container = styled.div<StyledProps>`
  flex: 1;
  margin-bottom: 3rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-bottom: 0;
  }

  > ul {
    ${(p) =>
      !p.collapse &&
      css`
        background: ${(p) => p.theme.colors.white};
        border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
        border-radius: 0.6rem;
        padding: 1rem 2rem;
        position: relative;
        height: calc(100% - 6.4rem);

        ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
          padding: 3rem 4rem;
        }
      `}
  }

  h2 {
    display: flex;
    align-items: center;
    margin-top: 0;

    svg {
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1rem;
    }
  }
`

type Props = {
  children: JSX.Element | JSX.Element[] | undefined
  collapse?: boolean
}

const OrderBlock = ({ children, collapse }: Props) => {
  return <Container collapse={collapse}>{children}</Container>
}

export default OrderBlock
