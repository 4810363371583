import gql from "graphql-tag"

const SEARCH_EXTERNAL_RULES = gql`
  query searchDiscountExternalRules(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
  ) {
    searchDiscountExternalRules(
      from: $from
      size: $size
      sort: $sort
      query: $query
    ) {
      total
      hits {
        id
        name
        isActive
        validFrom
        validTo
        created
        isStackable
        sortOrder
        updated
      }
    }
  }
`

export default SEARCH_EXTERNAL_RULES
