import React from "react"
import { Container, Divider, Row, Discount } from "./Totals.styled"
import Money from "../../../Money"
import DineroFactory from "dinero.js"
import { Loader } from "../../../Ui/Loader"
import { ShopperCartTotals } from "@lib/types/session"

type Props = {
  totals: ShopperCartTotals
  currencyCode: string
}

const Totals = ({ totals, currencyCode }: Props) => (
  <Container>
    {totals ? (
      <>
        <Row>
          <h4>Subtotal</h4> <Money amount={Math.round(totals.subTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>
        {totals.discountTotal > 0 && (
          <Row>
            <h4>Total discount</h4>
            <Discount>-<Money amount={Math.round(totals.discountTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/></Discount>
          </Row>
        )}
        <Divider />
        <Row>
          <h3>Grand total</h3> <h3><Money amount={Math.round(totals.grandTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/></h3>
        </Row>
      </>
    ) : (
      <Loader />
    )}
  </Container>
)

export default Totals
