import React from "react"
import { isSuperUser } from "../../../helpers/user"

import { Container, ButtonLoader } from "./SecondaryButton.styled"

type Props = {
  children: React.ReactNode
  loading?: boolean
  disabled?: boolean
  handleClick?: React.MouseEventHandler
  type?: "button"
  title?: string
  overrideDisabled?: boolean
}

const SecondaryButton = ({
  children,
  handleClick,
  loading = false,
  disabled = false,
  overrideDisabled = false,
  type,
  title,
  ...props
}: Props) => {
  return (
    <Container
      disabled={isSuperUser() ? disabled : overrideDisabled ? false : true}
      type={type}
      $loading={loading}
      onClick={handleClick}
      title={title || ""}
      {...props}
    >
      {loading && <ButtonLoader />}
      <span>{children}</span>
    </Container>
  )
}

export default SecondaryButton
