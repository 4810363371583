import React, { useState, useEffect } from "react"

const STORAGE_KEYS_PREFIX = "merchant-portal_"
const useStorage =
  (storage: Storage, keyPrefix: string) =>
  <T>(storageKey: string, fallbackState: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    if (!storageKey)
      throw new Error(`"storageKey" must be a nonempty string, but "${storageKey}" was passed.`)

    const storedString = storage.getItem(keyPrefix + storageKey)
    let parsedObject = null

    if (storedString !== null) parsedObject = JSON.parse(storedString)

    const [value, setValue] = useState<T>(parsedObject ?? fallbackState)

    useEffect(() => {
      storage.setItem(keyPrefix + storageKey, JSON.stringify(value))
    }, [value, storageKey])

    return [value, setValue]
  }

export const useLocalStorage = useStorage(localStorage, STORAGE_KEYS_PREFIX)
