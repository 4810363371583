import React, { useState } from "react"

import {
  Container,
  Flex,
  SearchWrapper,
  StyledSearch,
  SelectWrapper,
  Show,
  NumberOfHits,
  Wrapper,
  LabelWrapper,
  Label,
  Arrow,
  Result,
  Hits,
  Children
} from "./Filter.styled"

import { ReactComponent as FilterIcon } from "images/icons/sliders.svg"

type SearchOptions = {
  handleChange: (input: string) => void
  loading: boolean
  defaultValue: string
}

type Props = {
  children: React.ReactElement | React.ReactElement[]
  searchOptions?: SearchOptions
  isFilterActive: () => boolean
  numberOfHitsOptions?: string[]
  setNumberOfHits?: (hits: string) => void
  selectedNumberOfHits?: string
  resetFilter: () => void
  totalHits: number | undefined
  totalFilters?: number
}

const FilterSection = ({
  children,
  searchOptions,
  isFilterActive,
  numberOfHitsOptions,
  setNumberOfHits,
  selectedNumberOfHits,
  totalHits,
  totalFilters
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <Container>
      <Flex>
        <SearchWrapper>
          {searchOptions && (
            <StyledSearch
              handleOnChange={searchOptions.handleChange}
              loading={searchOptions.loading}
              defaultValue={searchOptions.defaultValue}
              padding="1rem 2rem 2rem 2rem"
              placeholder="Type to search..."
            />
          )}
          <LabelWrapper
            $isFilterActive={isFilterActive && isFilterActive()}
            onClick={() => setExpanded(!expanded)}
          >
            <Label $isFilterActive={isFilterActive && isFilterActive()}>
              <FilterIcon />
              Filter {isFilterActive() && `(${totalFilters})`}
              <Arrow $expanded={expanded} />
            </Label>
          </LabelWrapper>
          <Result>
            Results:{" "}
            <Hits>
              {totalHits?.toLocaleString()}
              {!!totalHits && totalHits === 1000 && "+"}
            </Hits>
          </Result>
        </SearchWrapper>

        <SelectWrapper>
          {numberOfHitsOptions && setNumberOfHits && selectedNumberOfHits && (
            <Show>
              <p>Show:</p>
              {numberOfHitsOptions.map((hits) => (
                <NumberOfHits
                  key={hits}
                  onClick={() => setNumberOfHits(hits)}
                  $active={hits === selectedNumberOfHits}
                >
                  {hits}
                </NumberOfHits>
              ))}
            </Show>
          )}
        </SelectWrapper>
      </Flex>
      <Wrapper $isFilterActive={isFilterActive && isFilterActive()}>
        {expanded && <Children>{children}</Children>}
      </Wrapper>
    </Container>
  )
}

export default FilterSection
