import gql from "graphql-tag"

const GET_STORE_GROUP = gql`
  query getStoreGroup($id: ID!) {
    getStoreGroup(id: $id) {
      id
      name
      channelType
      description
      countryOfSale
      created
      updated
      storeMarkets {
        storeGroupId
        countryCode
        name
        description
        currencyCode
        isTaxIncludedInPrice
        isTaxExemptionEligible
        isArchived
        created
        updated
        cartCapabilities {
          voyado {
            id
          }
          bonus {
            id
          }
          retain24 {
            id
          }
        }
        capabilities {
          shippingProviders {
            id
            type
          }
          paymentProviders {
            id
            type
          }
          giftCardProviders {
            id
            type
          }
        }
      }
    }
  }
`

export default GET_STORE_GROUP
