import React, { useState, MutableRefObject } from "react"
import styled from "styled-components/macro"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { FrontendCondition, Conditions, TagCondition } from "@lib/types/discount"

import Input from "components/Ui/Form/Input"
import QuickAdd from "components/Ui/Table/QuickAdd"
import { TagConditions } from "../../Ui/TagConditions/TagConditions"

const Container = styled.div`
  > div {
    display: block;
  }
`

const ConditionsAdd = styled(QuickAdd)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
`

type Inputs = {
  triggerQuantity: number
}

type Props = {
  condition?: FrontendCondition
  addCondition: (data: Conditions) => void
  submitRef: MutableRefObject<HTMLButtonElement>
}

const MinItems = ({ condition, addCondition, submitRef }: Props) => {
  const [tagConditions, setTagConditions] = useState<TagCondition[]>(condition?.data?.itemRule?.tagConditions ?? [])
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(
    condition?.data?.itemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPrices, setExcludeFullPrices] = useState(condition?.data?.itemRule?.excludeFullPrices ?? false)
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    addCondition({
      triggerQuantity: data.triggerQuantity,
      itemRule: { tagConditions, excludeDiscountedPrices, excludeFullPrices }
    })
  }

  return (
    <Container>
      <form>
        <ConditionsAdd>
          <Controller
            name="triggerQuantity"
            render={({ field }) => <Input {...field} type="number" label="Minimum cart items *" errors={errors} />}
            defaultValue={condition?.data?.triggerQuantity}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              }
            }}
          />
        </ConditionsAdd>
        <button onClick={handleSubmit(onSubmit)} ref={submitRef} type="submit" style={{ display: "none" }} />
      </form>
      <TagConditions
        tagConditions={tagConditions}
        setTagConditions={setTagConditions}
        excludeDiscountedPrices={excludeDiscountedPrices}
        setExcludeDiscountedPrices={setExcludeDiscountedPrices}
        excludeFullPrices={excludeFullPrices}
        setExcludeFullPrices={setExcludeFullPrices}
      />
    </Container>
  )
}

export default MinItems
