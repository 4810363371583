import gql from "graphql-tag"

const ALL_PAYMENT_PROVIDERS = gql`
  query getPaymentProviders {
    getPaymentProviders {
      id
      type
    }
  }
`

export default ALL_PAYMENT_PROVIDERS
