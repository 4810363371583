import gql from "graphql-tag"

const CREATE_COMPENSATION = gql`
  mutation createCompensation($orderId: ID!, $input: InputOrderCompensation!) {
    Order {
      createCompensation(orderId: $orderId, input: $input) {
        id
      }
    }
  }
`

export default CREATE_COMPENSATION
