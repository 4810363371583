import gql from "graphql-tag"

const SEARCH_CAMPAIGNS = gql`
  query searchCampaigns($from: Int!, $size: Int!, $sort: [SearchSorting!], $query: AWSJSON) {
    searchCampaigns(from: $from, size: $size, sort: $sort, query: $query) {
      total
      hits {
        campaignGroupId
        created
        endDate
        id
        name
        startDate
        updated
      }
    }
  }
`

export default SEARCH_CAMPAIGNS
