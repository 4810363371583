import React from "react"
import styled from "styled-components/macro"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { useNavigate } from "react-router-dom"

import Box from "components/Ui/Box"
import PageHeader from "components/Ui/Page/PageHeader"
import { ShowDiscountCodeRules } from "../DiscountCode/ShowDiscountCodeRules"
import { ShowCartRules } from "../CartRule/ShowCartRules"
import { ShowExternalRules } from "../ExternalRule/ShowExternalRules"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

const StyledTabList = styled(TabList)`
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`

export const ShowDiscountRules = () => {
  const navigate = useNavigate()

  return (
    <Box padding="0" margin="0">
      <PageHeader
        title="Discount rules"
        description="Edit your existing discount rules or create a new one."
        hasTabs={true}
      >
        <PrimaryButton
          type="button"
          handleClick={() => navigate("/discounts/create-discount-rule")}
        >
          <PlusIcon /> Add new discount rule
        </PrimaryButton>
      </PageHeader>
      <Tabs>
        <StyledTabList>
          <Tab>Code rules</Tab>
          <Tab>Cart rules</Tab>
          <Tab>External rules</Tab>
        </StyledTabList>
        <TabPanel>
          <ShowDiscountCodeRules />
        </TabPanel>
        <TabPanel>
          <ShowCartRules />
        </TabPanel>
        <TabPanel>
          <ShowExternalRules />
        </TabPanel>
      </Tabs>
    </Box>
  )
}
