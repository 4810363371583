import styled from "styled-components/macro"
import TableRowSimple from "../Ui/Table/TableRowSimple"

export const Container = styled.div`
  width: 100%;
`

export const StyledTableRow = styled(TableRowSimple)`
  padding: 1rem;
  margin: 0 0 1rem 0;

  > div {
    justify-content: space-between;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.5);
    cursor: initial;
  }
`

export const Name = styled.div`
  display: flex;

  img {
    width: 2.4rem;
    margin: 0 1.5rem 0 0.5rem;
  }
`

export const Actions = styled.div`
  display: flex;
  flex: initial !important;

  > button {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;

    svg {
      height: 1.4rem;
    }
  }
`

export const NotFound = styled.p`
  text-align: center;
  ${(p) => p.theme.bold};
`
