import gql from "graphql-tag"

const DELETE_TAX_GROUP = gql`
  mutation deleteTaxGroup($id: ID!) {
    deleteTaxGroup(id: $id) {
      status
    }
  }
`

export default DELETE_TAX_GROUP
