import gql from "graphql-tag"

const GET_PRODUCT = gql`
  query GetProduct(
    $id: ID!
  ) {
    Product {
      parent(id:$id) {
        created
        Variants {
          Stock {
            id
            inventories {
              created
              inventoryId
              quantity
              updated
            }
            stockQuantity
            validateStock
          }
          StoreGroupPrices {
            marketPrices {
              countryCode
              price {
                basePriceAmount
                currencyCode
                discountAmount
                campaign {
                  campaignId
                  name
                  campaignGroupId
                }
                priceRecords {
                  basePriceAmount
                  endDate
                  salePriceAmount
                  startDate
                  campaign {
                    campaignId
                    campaignGroupId
                    name
                  }
                }
                referencePriceAmount
                salePriceAmount
                startDate
              }
            }
            storeGroupId
          }
          TaxGroup {
            created
            description
            id
            name
            updated
          }
          created
          customAttributes
          description
          dimensions {
            height
            length
            width
          }
          shippingAttributes
          displayDescriptions
          displayNames
          id
          imageUrl
          isActive
          isArchived
          name
          productParentId
          tags
          updated
          weight
        }
        customAttributes
        description
        displayDescriptions
        displayNames
        id
        imageUrl
        isActive
        isArchived
        name
        tags
        updated
      }
    }
  }
`

export default GET_PRODUCT
