import React from "react"
import Footer from "../components/Ui/Footer"
import { Container, Logo, SidebarContainer, SidebarWrapper, Test, Wrapper } from "./ErrorView.styled"
import PageHeader from "../components/Ui/Page/PageHeader"
import { useConfig } from "../configuration/useConfig"
import PrimaryButton from "../components/Ui/Buttons/PrimaryButton"
import {ReactComponent as XmarkIcon} from "images/icons/circle-xmark.svg"

const ErrorView = () => {
  const { isTestEnvironment } = useConfig()
  return (
    <>
      <Wrapper>
        <SidebarWrapper>
          <SidebarContainer>
            <Logo $isTestEnvironment={isTestEnvironment} onClick={() =>  window.location.href = "/"} />
            {isTestEnvironment && <Test>test</Test>}
            <hr />
          </SidebarContainer>
        </SidebarWrapper>
        <PageHeader title={"Error page"} />
        <Container>
          <XmarkIcon/>
          <h1>Something went wrong</h1>
          <p>
            There was a problem. Please try again. If the issue continues please contact
            {" "}
            <a href="mailto:support@brinkcommerce.com">support</a>
          </p>
          <PrimaryButton
            overrideDisabled={true}
            handleClick={() => {
              window.location.href = "/"
            }}
          >
            Back to merchant portal
          </PrimaryButton>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}

export default ErrorView
