import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import GET_KLARNA_CHECKOUT_CONFIG from "graphql/queries/config/GetKlarnaCheckoutConfig"

import { Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "../../Ui/Table/TableRow"
import { BlurRow } from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as KlarnaIcon } from "images/providers/icons/klarna.svg"

export const getLoadingRow = () => (
  <>
    <KlarnaIcon />
    <div>
      <div>
        <Name>Merchant ID</Name>
        <Id>Merchant ID</Id>
      </div>
    </div>
    <div className="hideOnSmallScreens">
      <p>https://baseurl.se</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setEditId?: Dispatch<SetStateAction<string>>
}

export const KlarnaCheckoutConfigTableRow = ({ id, setEditId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_KLARNA_CHECKOUT_CONFIG, {
    variables: { id: id }
  })

  const klarnaConfig = data?.getKlarnaCheckoutConfig
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>

  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setEditId) setEditId(klarnaConfig.id)
        dispatch(showEditSidebar())
      }}
    >
      <KlarnaIcon />
      <div>
        <div>
          <Name>{klarnaConfig?.merchantId}</Name>
          <Id>{klarnaConfig?.id}</Id>
        </div>
      </div>
      <div className="hideOnSmallScreens">
        <p>{klarnaConfig?.baseUrl}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(klarnaConfig?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(klarnaConfig?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
