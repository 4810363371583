import React, { Dispatch, SetStateAction, useState } from "react"
import { Common, Discount } from "@lib/types"
import { useQuery } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"

import ALL_TAG_KEYS from "graphql/queries/tags/AllTagKeys"
import Options from "./Options"
import { Loader } from "components/Ui/Loader"
import { EditPopup } from "components/Ui/EditPopup/EditPopup"
import { Tooltip } from "components/Ui/Tooltip"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as RemoveIcon } from "images/icons/xmark.svg"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { ReactComponent as TagIcon } from "images/icons/tag.svg"
import { isSuperUser } from "helpers/user"
import {
  Actions, AddTagCondition,
  Conditions,
  Container, EmptyTags,
  Label,
  Operator,
  OperatorWrapper,
  TagHeader,
  TagKey,
  TagRow,
  Tags, TagSelect,
  TagValue,
  TagValues,
  RemoveTagValue
} from "./BundleTagConditions.styled"


type Props = {
  tagConditions: Discount.TagCondition[]
  setTagConditions: Dispatch<SetStateAction<Discount.TagCondition[]>>
  label?: string
  description?: string
}

type EditObject = {
  mode: string
  key: string
}

export const BundleTagConditions = ({
  tagConditions,
  setTagConditions,
  label,
  description,
  ...props
}: Props) => {
  const { loading, data } = useQuery(ALL_TAG_KEYS)
  const [addNewTag, setAddNewTag] = useState<string>("")
  const [editTagRule, setEditTagRule] = useState<EditObject | undefined>()
  const [tagValues, setTagValues] = useState<Common.Option[]>([])
  const [addTagKey, setAddTagKey] = useState<Common.Option | undefined>()

  if (loading) return <Loader color="black" />

  const clearForm = () => {
    setTagValues([])
    setAddNewTag("")
    setEditTagRule(undefined)
    setAddTagKey(undefined)
  }
  const addTagCondition = (mode: string) => {
    if (addTagKey?.value && tagValues) {
      const listClone = Array.from(tagConditions)
      const newTagCondition = {
        key: addTagKey.value,
        mode: mode,
        values: tagValues.map((value) => value.value)
      }
      listClone.push(newTagCondition)
      setTagConditions(listClone)
      clearForm()
    }
  }

  const removeTagCondition = (mode: string, key: string) => {
    const listClone = Array.from(tagConditions)
    const currentIndex = listClone.findIndex((tag) => tag.mode === mode && tag.key === key)
    listClone.splice(currentIndex, 1)
    setTagConditions(listClone)
  }

  const addTagValue = (mode: string, key: string) => {
    const listClone = Array.from(tagConditions)
    const current = listClone.find((tag) => tag.mode === mode && tag.key === key)
    const currentIndex = listClone.findIndex((tag) => tag.mode === mode && tag.key === key)
    if (!current) return
    const values = current.values.concat(tagValues.map((t) => t.value))
    listClone[currentIndex] = { mode, key, values }
    setTagConditions(listClone)
    clearForm()
  }

  const removeTagValue = (mode: string, key: string, value: string) => {
    const listClone = Array.from(tagConditions)
    const current = listClone.find((tag) => tag.mode === mode && tag.key === key)
    const currentIndex = listClone.findIndex((tag) => tag.mode === mode && tag.key === key)
    if (!current) return
    const values = current.values.filter((v) => v !== value)
    if (values.length === 0) {
      removeTagCondition("INCLUDE", key)
      return
    }
    listClone[currentIndex] = { mode, key, values }
    setTagConditions(listClone)
  }

  const includeTags = tagConditions?.filter((tagCondition) => tagCondition.mode === "INCLUDE")
  const excludeTags = tagConditions?.filter((tagCondition) => tagCondition.mode === "EXCLUDE")

  const includeOptions = data?.tagKeys?.keys
    ?.filter((key: string) => !includeTags.find((k) => k.key === key))
    .map((key: string) => ({
      value: key,
      label: key
    }))

  const excludeOptions = data?.tagKeys?.keys
    ?.filter((key: string) => !excludeTags.find((k) => k.key === key))
    .map((key: string) => ({
      value: key,
      label: key
    }))

  return (
    <Container $active={tagConditions.length > 0} {...props}>
      <h3>{label}</h3>
      {description && <p>{description}</p>}
      <Conditions>
        <Tags>
          <h4>Include tags:</h4>
          {includeTags.length > 0 ? (
            <>
              {includeTags.map((tagCondition, index) => {
                return (
                  <>
                    <TagRow key={uuidv4()}>
                      <TagHeader>
                        <TagKey>
                          <TagIcon /> <span>{tagCondition.key}</span>
                        </TagKey>
                        {isSuperUser() && (
                          <Actions>
                            <Tooltip id="add-tag-value" />
                            <PlusIcon
                              data-tooltip-id="add-tag-value"
                              data-tooltip-content="Add tag values"
                              onClick={() =>
                                setEditTagRule({ mode: "INCLUDE", key: tagCondition.key })
                              }
                            />
                            <Tooltip id="remove-tag-condition" />
                            <TrashIcon
                              data-tooltip-id="remove-tag-condition"
                              data-tooltip-content="Remove tag"
                              onClick={() => removeTagCondition("INCLUDE", tagCondition.key)}
                            />
                          </Actions>
                        )}
                      </TagHeader>

                      {editTagRule &&
                        editTagRule.mode === "INCLUDE" &&
                        editTagRule.key === tagCondition.key && (
                          <EditPopup
                            title={`Add tags to '${
                              tagCondition.key
                            }'`}
                            buttonText="Add tag values"
                            handleOkClick={() => addTagValue("INCLUDE", tagCondition.key)}
                            handleCloseClick={() => clearForm()}
                            disableButton={!tagValues.length}
                          >
                            <Options
                              tagKey={tagCondition.key}
                              tagValues={tagValues}
                              setTagValues={setTagValues}
                              tagConditions={tagConditions}
                            />
                          </EditPopup>
                        )}
                      <TagValues key={tagCondition.key}>
                        {tagCondition.values?.map((value) => {
                          return (
                            <TagValue key={uuidv4()}>
                              <Label>{value}</Label>
                              <Tooltip id="remove-tag-value" />
                              {isSuperUser() && (
                                <RemoveTagValue
                                  data-tooltip-id="remove-tag-value"
                                  data-tooltip-content="Remove tag value"
                                  onClick={() => removeTagValue("INCLUDE", tagCondition.key, value)}
                                >
                                  <RemoveIcon />
                                </RemoveTagValue>
                              )}
                            </TagValue>
                          )
                        })}
                      </TagValues>
                    </TagRow>
                    {index + 1 !== includeTags?.length && (
                      <OperatorWrapper>
                        <Operator>And</Operator>
                      </OperatorWrapper>
                    )}
                  </>
                )
              })}
            </>
          ) : (
            <EmptyTags>
              <p>No tags added</p>
            </EmptyTags>
          )}
          {isSuperUser() && (
            <>
              <Tooltip id="add-tag-key" />
              <AddTagCondition
                type="button"
                data-tooltip-id="add-tag-key"
                data-tooltip-content="Add new tag"
                onClick={() => setAddNewTag("INCLUDE")}
              >
                <PlusIcon />
              </AddTagCondition>
            </>
          )}

          {addNewTag === "INCLUDE" && (
            <EditPopup
              title="Add new tag to include"
              buttonText="Add tag"
              disableButton={!addTagKey || !tagValues.length}
              handleOkClick={() => addTagCondition("INCLUDE")}
              handleCloseClick={() => clearForm()}
            >
              <TagSelect options={includeOptions} setValue={setAddTagKey} />
              {addTagKey && (
                <Options
                  tagKey={addTagKey.value}
                  tagValues={tagValues}
                  setTagValues={setTagValues}
                  tagConditions={tagConditions}
                />
              )}
            </EditPopup>
          )}
        </Tags>

        <Tags>
          <h4>Exclude tags:</h4>
          {excludeTags.length > 0 ? (
            <>
              {excludeTags.map((tagCondition, index) => {
                return (
                  <>
                    <TagRow key={uuidv4()}>
                      <TagHeader>
                        <TagKey>
                          <TagIcon /> <span>{tagCondition.key}</span>
                        </TagKey>
                        {isSuperUser() && (
                          <Actions>
                            <Tooltip id="add-tag-value" />
                            <PlusIcon
                              data-tooltip-id="add-tag-value"
                              data-tooltip-content="Add tag values"
                              onClick={() =>
                                setEditTagRule({ mode: "EXCLUDE", key: tagCondition.key })
                              }
                            />
                            <Tooltip id="remove-tag-condition" />
                            <TrashIcon
                              data-tooltip-id="remove-tag-condition"
                              data-tooltip-content="Remove tag"
                              onClick={() => removeTagCondition("EXCLUDE", tagCondition.key)}
                            />
                          </Actions>
                        )}
                      </TagHeader>

                      {editTagRule &&
                        editTagRule.mode === "EXCLUDE" &&
                        editTagRule.key === tagCondition.key && (
                          <EditPopup
                            title={`Add tags to '${
                              tagCondition.key
                            }'`}
                            buttonText="Add tag values"
                            handleOkClick={() => addTagValue("EXCLUDE", tagCondition.key)}
                            handleCloseClick={() => clearForm()}
                            disableButton={!tagValues.length}
                          >
                            <Options
                              tagKey={tagCondition.key}
                              tagValues={tagValues}
                              setTagValues={setTagValues}
                              tagConditions={tagConditions}
                            />
                          </EditPopup>
                        )}
                      <TagValues key={tagCondition.key}>
                        {tagCondition.values?.map((value) => {
                          return (
                            <TagValue key={uuidv4()}>
                              <Label>{value}</Label>
                              <Tooltip id="remove-tag-value" />
                              {isSuperUser() && (
                                <RemoveTagValue
                                  data-tooltip-id="remove-tag-value"
                                  data-tooltip-content="Remove tag value"
                                  onClick={() => removeTagValue("EXCLUDE", tagCondition.key, value)}
                                >
                                  <RemoveIcon />
                                </RemoveTagValue>
                              )}
                            </TagValue>
                          )
                        })}
                      </TagValues>
                    </TagRow>
                    {index + 1 !== excludeTags?.length && (
                      <OperatorWrapper>
                        <Operator>Or</Operator>
                      </OperatorWrapper>
                    )}
                  </>
                )
              })}
            </>
          ) : (
            <EmptyTags>
              <p>No tags added</p>
            </EmptyTags>
          )}
          {isSuperUser() && (
            <>
              <Tooltip id="add-tag-key" />
              <AddTagCondition
                type="button"
                data-tooltip-id="add-tag-key"
                data-tooltip-content="Add new tag"
                onClick={() => setAddNewTag("EXCLUDE")}
              >
                <PlusIcon />
              </AddTagCondition>
            </>
          )}

          {addNewTag === "EXCLUDE" && (
            <EditPopup
              title="Add new tag to exclude"
              buttonText="Add tag"
              disableButton={!addTagKey || !tagValues.length}
              handleOkClick={() => addTagCondition("EXCLUDE")}
              handleCloseClick={() => clearForm()}
            >
              <TagSelect options={excludeOptions} setValue={setAddTagKey} />
              {addTagKey && (
                <Options
                  tagKey={addTagKey.value}
                  tagValues={tagValues}
                  setTagValues={setTagValues}
                  tagConditions={tagConditions}
                />
              )}
            </EditPopup>
          )}
        </Tags>
      </Conditions>
    </Container>
  )
}
