import React, { useState } from "react"
import Input from "../../../../Ui/Form/Input"
import { Error, GiftCardWrapper, InputWrapper, LabelWrapper } from "./OrderSummary.styled"
import {
  CompensationGiftCard,
  InputOrderCompensationGiftCard,
  OrderGiftCardBsgGiftCard,
  OrderGiftCardEmpty,
  OrderGiftCardKbsGiftCard,
  OrderGiftCardRetain24
} from "@lib/types/generated/graphql-types"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as ArrowDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ArrowUp } from "images/icons/chevron-up.svg"
import { ReactComponent as GiftCardIcon } from "images/icons/gift-card.svg"

type Props = {
  orderGiftCard:
    | OrderGiftCardBsgGiftCard
    | OrderGiftCardKbsGiftCard
    | OrderGiftCardRetain24
    | OrderGiftCardEmpty
  currencyUnit: string
  compensatedGiftCards: (CompensationGiftCard | null | undefined)[]
  updateFunction: (amount: number, giftCardId: string) => void
  compensationGiftCards: InputOrderCompensationGiftCard[] | undefined
}

const GiftCardInput = ({
  orderGiftCard,
  currencyUnit,
  updateFunction,
  compensatedGiftCards,
  compensationGiftCards
}: Props) => {
  const [expanded, setExpanded] = useState(false)

  function isRetain24(
    orderGiftCard:
      | OrderGiftCardBsgGiftCard
      | OrderGiftCardKbsGiftCard
      | OrderGiftCardRetain24
      | OrderGiftCardEmpty
  ): orderGiftCard is OrderGiftCardRetain24 {
    return (orderGiftCard as OrderGiftCardRetain24) !== undefined
  }

  const getCompletedGiftCardAmount = (id: string) => {
return compensatedGiftCards.find((compensatedGiftCard) => compensatedGiftCard?.giftCardId === id)?.amount ?? 0
  }

  if (!orderGiftCard) return null
  return (
    <InputWrapper>
      <LabelWrapper onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        <span>
          <GiftCardIcon />
          <b>Gift cards</b>
        </span>
        {expanded ? <ArrowUp /> : <ArrowDown />}
      </LabelWrapper>
      {isRetain24(orderGiftCard) &&
        expanded &&
        orderGiftCard.giftCards?.map((giftCard) => {
          return (
            <GiftCardWrapper key={giftCard?.id}>
              <b>{giftCard?.id}</b>
              {" - "}
              <Money
                amount={giftCard.amount - getCompletedGiftCardAmount(giftCard?.id)}
                currencyUnit={currencyUnit as DineroFactory.Currency}
              />
              <br />
              Amount in minor units:
              <Input
                name="amount"
                placeholder={"0"}
                type="number"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateFunction(parseInt(e.currentTarget.value), giftCard.id)
                }
              />
              <b>Formatted: </b>
              <Money
                amount={
                  compensationGiftCards?.find(
                    (compensationGiftCard) => compensationGiftCard.giftCardId === giftCard.id
                  )?.amount ?? 0
                }
                currencyUnit={currencyUnit as DineroFactory.Currency}
              />
              {(compensationGiftCards?.find(
                (compensationGiftCard) => compensationGiftCard.giftCardId === giftCard.id
              )?.amount ?? 0) > giftCard.amount - getCompletedGiftCardAmount(giftCard?.id) ? (
                <Error>{`- Amount can't be higher than total`}</Error>
              ) : (
                <></>
              )}
            </GiftCardWrapper>
          )
        })}
    </InputWrapper>
  )
}

export default GiftCardInput
