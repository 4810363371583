import gql from "graphql-tag"

const CREATE_RELEASE = gql`
  mutation createRelease($orderId: ID!, $input: InputOrderRelease!) {
    Order {
      createRelease(orderId: $orderId, input: $input) {
        id
      }
    }
  }
`

export default CREATE_RELEASE
