import { CurrencyCodeRecord } from "currency-codes"
import data from "currency-codes/data"

export const getCurrency = (currencyCode: string) =>
  data.find((currency: CurrencyCodeRecord) =>
    currency.code === currencyCode
      ? {
          code: currency.code,
          name: currency.currency,
          combined: `${currency.currency} [${currency.code}]`
        }
      : null
  )

export const CurrencyOptions = data.map((currency: CurrencyCodeRecord) => ({
  value: currency.code,
  label: `${currency.currency} [${currency.code}]`
}))
