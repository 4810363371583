import gql from "graphql-tag"

const RESEND_PASSWORD = gql`
    mutation resendPassword(
        $username: AWSEmail!
    ) {
        resendPassword(
            username: $username
        ) {
            status
        }
    }
`

export default RESEND_PASSWORD