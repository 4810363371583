import React from "react"
import { Controller } from "react-hook-form"
import { v4 as uuidv4 } from "uuid"
import { Control, UseFormSetValue, UseFormTrigger } from "react-hook-form/dist/types/form"
import { InputCreateOrUpdateDiscountCodeRule } from "lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { RULE_TYPE } from "lib/types/common"

import {
  Container,
  InputWrapper,
  Name,
  SortOrder,
  DateWrapper,
  CheckboxWrapper
} from "./DiscountRuleGeneralInput.styled"

import Input from "components/Ui/Form/Input"
import Checkbox from "components/Ui/Form/Checkbox"
import SecondaryButton from "components/Ui/Buttons/SecondaryButton"

interface ExtendedInputCreateOrUpdateDiscountCodeRule extends InputCreateOrUpdateDiscountCodeRule {
  id?: string
}

type Props = {
  control: Control<ExtendedInputCreateOrUpdateDiscountCodeRule>
  isStackable: boolean
  errors: FieldErrors<ExtendedInputCreateOrUpdateDiscountCodeRule>
  selectedType: RULE_TYPE | undefined
  setValue: UseFormSetValue<ExtendedInputCreateOrUpdateDiscountCodeRule>
  trigger: UseFormTrigger<ExtendedInputCreateOrUpdateDiscountCodeRule>
  validationError: boolean
}

const DiscountRuleGeneralInput = ({
  control,
  isStackable,
  errors,
  selectedType,
  setValue,
  trigger,
  validationError
}: Props) => {
  const getTypeText = () => {
    switch (selectedType) {
      case RULE_TYPE.CODE_RULE:
        return "discount rule with codes generated from Brink"
      case RULE_TYPE.CART_RULE:
        return "discount rule applicable in cart without code"
      case RULE_TYPE.EXTERNAL_RULE:
        return "discount rule with codes generated from external provider"
    }
  }

  return (
    <div>
      <Container>
        <div>
          <h2>General</h2>
          <p>General inputs for {getTypeText()} </p>
          <Name>
            {selectedType === RULE_TYPE.EXTERNAL_RULE && (
              <InputWrapper>
                <Controller
                  name="id"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      label="ID *"
                      placeholder="Aa - Zz, 0 - 9, -_."
                      errors={validationError ? { id: { message: "ID already exist" } } : errors}
                      description="Cannot be modified after save"
                    />
                  )}
                  control={control}
                  rules={{
                    required: "This is a required field",
                    pattern: {
                      value: /^[A-Za-z0-9_.-]+$/,
                      message: "Only Aa-Zz, 0-9 and _-. are allowed"
                    }
                  }}
                />
                <SecondaryButton
                  type="button"
                  handleClick={() => {
                    setValue("id", uuidv4())
                    trigger("id")
                  }}
                >
                  Generate
                </SecondaryButton>
              </InputWrapper>
            )}
            <Controller
              name="name"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  label="Name *"
                  placeholder="Aa - Zz, 0 - 9"
                  errors={errors}
                />
              )}
              control={control}
              rules={{
                required: "This is a required field"
              }}
            />
          </Name>
          <SortOrder>
            <Controller
              name="sortOrder"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  label="Sort order *"
                  placeholder="Minimum value is 1"
                  errors={errors}
                />
              )}
              control={control}
              rules={{
                required: "This is a required field",
                min: { value: 1, message: "Minimum value is 1" }
              }}
            />
          </SortOrder>
        </div>
        <CheckboxWrapper>
          <Checkbox
            control={control}
            name="isStackable"
            label="Is stackable"
            description="Can this rule stack with other rules?"
            defaultValue={false}
          />
          {selectedType !== RULE_TYPE.CART_RULE && (
            <>
              <Checkbox
                disabled={!isStackable}
                control={control}
                name="isExclusive"
                label="Is exclusive"
                description="Only one exclusive rule can be applied per cart."
                defaultValue={false}
              />
              <Checkbox
                disabled={!isStackable}
                control={control}
                name="applyLast"
                label="Apply last"
                description="Apply this discount rule last if multiple rules are applied."
                defaultValue={false}
              />
            </>
          )}
        </CheckboxWrapper>
      </Container>
      <hr />
      <h2>Time period limitation (Optional)</h2>
      <p> Limit discount rule to only be valid between selected dates</p>
      <DateWrapper>
        <Controller
          name="validDateRange.from"
          render={({ field }) => (
            <Input
              {...field}
              type="datetime-local"
              label="Valid from"
              placeholder="Date"
              errors={{}}
            />
          )}
          control={control}
        />
        <Controller
          name="validDateRange.to"
          render={({ field }) => (
            <Input
              {...field}
              type="datetime-local"
              label="Valid to"
              placeholder="Date"
              errors={{}}
            />
          )}
          control={control}
        />
      </DateWrapper>
    </div>
  )
}

export default DiscountRuleGeneralInput
