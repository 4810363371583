import React, { Dispatch, SetStateAction, useState } from "react"
import SingleSelect from "../../Ui/Form/SingleSelect"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import { StoreGroup, StoreMarket, StoreMarketSearchHit } from "lib/types/generated/graphql-types"
import { SelectWrapper } from "./AddStoreMarket.styled"
import { Label } from "./ConnectedStoreMarkets.styled"
import { getCountryName } from "../../../helpers/countries"
import { Option, PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"
import { useMutation } from "@apollo/client"
import UPDATE_STORE_MARKET from "../../../graphql/mutations/store/UpdateStoreMarket"
import { useDispatch } from "react-redux"
import alertActions from "lib/store/services/Alert/AlertSlice"

type Props = {
  storeMarkets: StoreMarket[]
  connectedStoreMarkets: StoreMarketSearchHit[]
  selectedStoreGroup: Option
  storeGroups: StoreGroup[]
  providerId: string
  setConnectedStoreMarkets: Dispatch<SetStateAction<StoreMarketSearchHit[]>>
  storeGroupId: string
  providerType: PROVIDER_TYPE
  providerCategory: PROVIDER_CATEGORY
}

const AddStoreMarket = ({
  storeMarkets,
  connectedStoreMarkets,
  selectedStoreGroup,
  storeGroups,
  providerId,
  setConnectedStoreMarkets,
  storeGroupId,
  providerType,
  providerCategory
}: Props) => {
  const [selectedStoreMarket, setSelectedStoreMarket] = useState<Option>()

  const getOptions = () => {
    return storeMarkets.filter(
      (storeMarket) =>
        !connectedStoreMarkets
          .filter(
            (connectedStoreMarket) => connectedStoreMarket.storeGroupId === selectedStoreGroup.value
          )
          .find(
            (connectedStoreMarket) => storeMarket.countryCode === connectedStoreMarket.countryCode
          )
    )
  }

  const [updateStoreMarket, { loading: updateStoreMarketLoading }] =
    useMutation(UPDATE_STORE_MARKET)
  const dispatch = useDispatch()

  const getCapabilities = (storeMarket: StoreMarket) => {
    if (
      providerCategory === PROVIDER_CATEGORY.PAYMENT ||
      providerCategory === PROVIDER_CATEGORY.SHIPPING ||
      providerCategory === PROVIDER_CATEGORY.GIFTCARD
    ) {
      return {
        ...storeMarket.capabilities,
        [providerCategory]: [
          ...(storeMarket?.capabilities?.[providerCategory] ?? []),
          { id: providerId, type: providerType }
        ]
      }
    } else {
      return storeMarket.capabilities
    }
  }

  const getCartCapabilities = (storeMarket: StoreMarket) => {
    if (
      (providerType !== "bonus" && storeMarket?.cartCapabilities?.["bonus"] === null) ||
      (providerType !== "voyado" && storeMarket?.cartCapabilities?.["voyado"] === null)
    )
      return {
        [providerType]: { id: providerId }
      }
    else
      return {
        ...storeMarket.cartCapabilities,
        [providerType]: { id: providerId }
      }
  }

  const addStoreMarket = () => {
    const storeMarket =
      storeGroups
        ?.find((storeGroup: StoreGroup) => storeGroup?.id === storeGroupId)
        ?.storeMarkets?.find(
          (storeMarket) => storeMarket?.countryCode === selectedStoreMarket?.value
        ) ?? undefined

    if (storeMarket) {
      updateStoreMarket({
        variables: {
          countryCode: selectedStoreMarket?.value,
          storeGroupId: storeGroupId,
          capabilities: getCapabilities(storeMarket),
          ...(providerCategory === PROVIDER_CATEGORY.CART_CAPABILITY
            ? { cartCapabilities: getCartCapabilities(storeMarket) }
            : {}),
          currencyCode: storeMarket.currencyCode,
          isArchived: storeMarket.isArchived,
          isTaxExemptionEligible: storeMarket.isTaxExemptionEligible,
          isTaxIncludedInPrice: storeMarket.isTaxIncludedInPrice
        }
      })
        .then((res) => {
          dispatch(
            alertActions.actions.createAlert({
              type: "success",
              message: `Store market ${getCountryName(storeMarket.countryCode)} successfully added`
            })
          )
          setConnectedStoreMarkets([...connectedStoreMarkets, res.data.updateStoreMarket])
          setSelectedStoreMarket(undefined)
        })
        .catch(() => {
          dispatch(
            alertActions.actions.createAlert({
              type: "error"
            })
          )
        })
    }
  }

  return (
    <>
      <Label>Add store market</Label>
      <SelectWrapper>
        <SingleSelect
          options={getOptions().map((storeMarket) => ({
            value: storeMarket.countryCode,
            label: getCountryName(storeMarket.countryCode) ?? ""
          }))}
          setValue={setSelectedStoreMarket}
          defaultValue={selectedStoreMarket}
          updateDefaultValue={true}
        />
        <PrimaryButton
          handleClick={addStoreMarket}
          loading={updateStoreMarketLoading}
          disabled={!selectedStoreMarket}
        >
          +
        </PrimaryButton>
      </SelectWrapper>
    </>
  )
}

export default AddStoreMarket
