import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"

import ALL_INVENTORIES from "graphql/queries/inventory/AllInventories"

import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { InventoryTable } from "../../components/Inventory/InventoryTable"
import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import UpdateInventories from "./UpdateInventories"
import { CreateInventory } from "./CreateInventories"
import { useSelector } from "react-redux"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"

const Container = styled.div`
  width: 100%;
`

export const Inventories = () => {
  const [editId, setEditId] = useState("")
  const dispatch = useAppDispatch()
  const showSidebar = useSelector(editSidebarOpen)
  const { loading, error, data, refetch } = useQuery(ALL_INVENTORIES)

  useEffect(() => {
    if (!showSidebar) setEditId("")
  }, [showSidebar])

  return (
    <Container>
      {editId && <UpdateInventories id={editId} setEditId={setEditId} refetch={refetch} />}
      {!editId && <CreateInventory refetch={refetch} />}
      <PageHeader title="Inventories">
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new Inventory
        </PrimaryButton>
      </PageHeader>
      <InventoryTable setEditId={setEditId} inventories={data?.getInventories || []} loading={loading} error={error} />
    </Container>
  )
}
