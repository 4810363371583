import React, { useState } from "react"
import { Controller } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import { SidebarDivider } from "../../Dashboard/Shared.styled"
import { AdyenConfig, AdyenEnvironment, InputAdyenConfig } from "lib/types/generated/graphql-types"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { Common } from "@lib/types"
import styled from "styled-components/macro"
import SingleSelect from "../../Ui/Form/SingleSelect"

const EnvironmentSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

interface ExtendedInputAdyenConfig extends InputAdyenConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputAdyenConfig>
  errors: FieldErrors
  setValue: UseFormSetValue<ExtendedInputAdyenConfig>
  showId?: boolean
  defaultValues?: AdyenConfig
}

const AdyenConfigInputs = ({ control, errors, setValue, showId, defaultValues }: Props) => {
  const [isLiveEnvironment, setIsLiveEnvironment] = useState<boolean>(defaultValues?.environment === AdyenEnvironment.Live)

  const updateEnvironment = (environment: Common.Option) => {
    const env = environment.value as AdyenEnvironment
    setValue("environment", env)
    setIsLiveEnvironment(env === AdyenEnvironment.Live)
  }

  return (
    <>
      {showId && (
        <Controller
          name="id"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="Identifier *"
              description="For internal use in Brink"
              placeholder="Aa - Zz, 0 - 9, -_."
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field",
            pattern: {
              value: /^[A-Za-z0-9_.-]+$/,
              message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
            }
          }}
          defaultValue=""
        />
      )}
      <SidebarDivider />
      <Controller
        name="merchantAccount"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Adyen merchant account *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.merchantAccount ?? ""}
      />
      <Controller
        name="apiKey"
        render={({ field }) => (
          <Input {...field} type="text" label="Adyen API-key *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="clientKey"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Adyen client key *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="environment"
        render={({ field }) => (
          <EnvironmentSelect
            {...field}
            options={[
              { label: "Live", value: AdyenEnvironment.Live },
              { label: "Test", value: AdyenEnvironment.Test }
            ]}
            setValue={updateEnvironment}
            defaultValue={
              defaultValues?.environment === AdyenEnvironment.Live
                ? { label: "Live", value: AdyenEnvironment.Live }
                : {
                    label: "Test",
                    value: AdyenEnvironment.Test
                  }
            }
            label="Environment *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="liveEndpointUrlPrefix"
        render={({ field }) => (
          <Input {...field} type="text" label={`Live endpoint url prefix${isLiveEnvironment ? ' *' : ''}`} errors={errors} />
        )}
        defaultValue={defaultValues?.liveEndpointUrlPrefix ?? ""}
        rules={{
          required: {
            value: isLiveEnvironment,
            message: "This is a required field"
          }
        }}
        control={control}
      />
    </>
  )
}

export default AdyenConfigInputs
