import React from "react"
import { Controller } from "react-hook-form"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { Common } from "lib/types"
import styled from "styled-components/macro"
import { InputRetain24Config, Retain24Config } from "@lib/types/generated/graphql-types"

import { SidebarDivider } from "components/Dashboard/Shared.styled"

import Input from "../../Ui/Form/Input"
import SingleSelect from "../../Ui/Form/SingleSelect"
import Checkbox from "components/Ui/Form/Checkbox"
import { TooltipIcon } from "components/Ui/TooltipIcon"

const BaseUrlSelect = styled(SingleSelect)`
  margin-bottom: 3rem;
`

const CheckboxWrapper = styled.div`
  display: flex;

  div {
    padding-top: 0;
  }
`

export interface FormInputRetain24Config extends Omit<InputRetain24Config, 'certificate'> {
  id: string
  certificate?: File
}

type Props = {
  control: Control<FormInputRetain24Config>
  errors: FieldErrors
  setValue: UseFormSetValue<FormInputRetain24Config>
  showId?: boolean
  defaultValues?: Retain24Config
}

export const Retain24ConfigInputs = ({
  control,
  errors,
  setValue,
  showId = false,
  defaultValues
}: Props) => {
  const updateBaseUrl = (baseUrl: Common.Option) => {
    setValue("baseUrl", baseUrl.value)
  }

  const baseUrlOptions = [
    {
      value: "https://mvalue-stage.mvoucher.se",
      label: "Stage | mvalue-stage.mvoucher.se"
    },
    {
      value: "https://mvalue.mvoucher.se",
      label: "Prod | mvalue.mvoucher.se"
    }
  ]

  return (
    <>
      {showId && (
        <>
          <Controller
            name="id"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Identifier *"
                placeholder="Aa - Zz, 0 - 9, -_."
                description="For internal use in Brink"
                errors={errors}
              />
            )}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_.-]+$/,
                message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
              }
            }}
            defaultValue=""
          />
          <SidebarDivider />
        </>
      )}
      <Controller
        name="simulationKey"
        render={({ field }) => (
          <Input {...field} type="text" label="Retain24 simulation key *" errors={errors} />
        )}
        control={control}
        defaultValue={defaultValues?.simulationKey ?? ""}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="certificate"
        render={({ field: { value: _value, onChange, ...field } }) => {
          // This input element accepts a filename, which may only be programmatically set to the empty string.
          // Ignore the value field and let the form input show the file name by itself
          return (
            <Input
              {...field}
              type="file"
              accept=".pfx"
              description="Must be password protected certificate (.pfx) file"
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLFormElement>) => {
                onChange(event.target.files[0] as File)
              }}
              errors={errors}
              label="Retain24 certificate *"
            />
          )
        }}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="certificatePassword"
        render={({ field }) => (
          <Input {...field} type="text" label="Retain24 certificate password *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="baseUrl"
        render={({ field }) => (
          <BaseUrlSelect
            {...field}
            options={baseUrlOptions}
            setValue={updateBaseUrl}
            defaultValue={{
              label:
                baseUrlOptions.find((option) => option.value === defaultValues?.baseUrl)?.label ??
                "Select base URL",
              value: defaultValues?.baseUrl ?? ""
            }}
            label="Base URL *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
      <CheckboxWrapper>
        <Checkbox
          name="redeem"
          label="Redeem"
          control={control}
          defaultValue={defaultValues?.redeem ?? false}
        />
        <TooltipIcon
          content={
            "Redeem when the order is created. The used gift card amount will instantly be captured."
          }
        />
      </CheckboxWrapper>
    </>
  )
}
