import React, { useMemo } from "react"
import debounce from "lodash.debounce"

import { AppliedDiscountCode } from "./DiscountCode.styled"
import { ReactComponent as DiscountCodeIcon } from "images/icons/tag.svg"
import { ReactComponent as CloseIcon } from "images/icons/xmark.svg"
import { Loader } from "../../../../Ui/Loader"
import { CartDiscountCode } from "lib/types/session"
import { useDeleteDiscountCodeMutation } from "lib/store/services/brink/actions"
import { useConfig } from "../../../../../configuration/useConfig"

interface Props {
  discountCode: CartDiscountCode
}

export default function DiscountCode({ discountCode }: Props) {
  const [deleteCode, { isLoading }] = useDeleteDiscountCodeMutation()
  const { awsRegion, customerName } = useConfig().config

  const onDelete = useMemo(() => {
    return debounce(() => {
      return deleteCode({ awsRegion, customerName, discountCode: discountCode.code })
    }, 500)
  }, [discountCode.code, deleteCode])

  return (
    <>
      <AppliedDiscountCode key={discountCode.code}>
        {isLoading && <Loader color="black" />}
        <span>
          <DiscountCodeIcon /> {discountCode.code}
        </span>
        <CloseIcon onClick={onDelete} />
      </AppliedDiscountCode>
    </>
  )
}
