import React from "react"
import { getLogicTypeDisplayName } from "helpers/getLogicTypeDisplayName"

import { LogicSelect } from "./LogicSelect"

const options = [
  {
    value: "CARTOVERTHRESHOLD",
    label: getLogicTypeDisplayName("CARTOVERTHRESHOLD"),
    description: "If the total value of items in the cart is X or higher."
  },
  {
    value: "CARTUNDERTHRESHOLD",
    label: getLogicTypeDisplayName("CARTUNDERTHRESHOLD"),
    description: "If the total value of items in the cart is X or lower.    "
  },
  {
    value: "MINITEMS",
    label: getLogicTypeDisplayName("MINITEMS"),
    description: "If the cart contains X items or more."
  },
  {
    value: "MINITEMSVALUE",
    label: getLogicTypeDisplayName("MINITEMSVALUE"),
    description: "If a selected group of items in the cart totals X or more."
  },
  {
    value: "STOREGROUP",
    label: getLogicTypeDisplayName("STOREGROUP"),
    description: "If the store is part of the specified store group X."
  },
  {
    value: "COUNTRYCODE",
    label: getLogicTypeDisplayName("COUNTRYCODE"),
    description: "If the visitor’s location is identified as country code X."
  }
]

type Props = {
  setValue: any // eslint-disable-line
  defaultValue?: string
  disabled?: boolean
}

export const ConditionSelect = ({ setValue, disabled, defaultValue }: Props) => (
  <LogicSelect
    options={options}
    setValue={setValue}
    disabled={disabled}
    placeholder="Select a condition type to add"
    defaultValue={defaultValue}
  />
)
