import React from "react"
import SecureRoutes from "./routes"
import { Authenticator, translations } from "@aws-amplify/ui-react"
import { Amplify } from "aws-amplify"
import { I18n } from "aws-amplify/utils"
import { useConfig } from "./configuration/useConfig"
import { ApolloClientProvider } from "./components/Apollo/ApolloClientProvider"
import styled from "styled-components/macro"

import Header from "components/Login/Header"
import Footer from "components/Login/Footer"
import SignInHeader from "components/Login/SignInHeader"
import SignInFooter from "components/Login/SignInFooter"

I18n.putVocabularies(translations)
I18n.setLanguage("en")

const components = {
  Header,
  Footer,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter
  }
}

I18n.putVocabulariesForLanguage("en", {
  "Setup TOTP": "Use QR code or key to setup MFA device/app",
  "Confirm TOTP Code": "Confirm with code from MFA device/app",
  Loading: "QR code would show here"
})

const StyledAuthentificator = styled(Authenticator)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.colors.blueDarker};

  > div {
    width: 100%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
  }

  .amplify-flex {
    display: flex;

    .amplify-button__loader-wrapper {
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }
  }

  form {
    margin: auto;
    max-width: 50rem;

    .amplify-flex {
      background-color: transparent;
      border: none;
      padding: 0;
      position: relative;
      color: white;
      justify-content: center;

      .amplify-field-group {
        width: 100%;

        > div {
          width: 100%;
        }
      }

      > div {
        flex-direction: column;

        > div {
          > div {
            flex-direction: row;
            margin: auto;
          }
        }

        img {
          margin: 0 auto 2rem auto;
        }

        svg {
          fill: white;
          margin-left: 1rem;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .amplify-alert--error {
        display: flex;
        color: white !important;
        flex-direction: row;
        margin: 1rem 0;

        .amplify-alert__icon {
          margin-right: 3rem;
        }
      }

      .amplify-radio__label {
        color: ${(p) => p.theme.colors.white};
      }
    }
  }

  .amplify-flex {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
  }

  .amplify-input {
    background: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.black};
    padding: 0.1rem 1.5rem 0;
    height: 5rem;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0;
  }

  .amplify-field__show-password {
    background: none;
    border: none;
    position: absolute;
    color: ${(p) => p.theme.colors.black};
    right: 1rem;
    top: 0;
    margin-top: 1.2rem;
    cursor: pointer;

    .amplify-visually-hidden {
      display: none;
    }
  }

  .amplify-visually-hidden {
    display: none;

    legend {
      display: none;
    }
  }

  .amplify-heading {
    color: ${(p) => p.theme.colors.white};
    margin: 1rem 0 2rem;
    font-size: 2rem;
    text-align: center;
  }

  .amplify-button {
    font-size: 1.5rem;
    ${(p) => p.theme.bold};
  }

  .amplify-button--link {
    color: ${(p) => p.theme.colors.white};
    border: none;
    display: block;
    background-color: transparent;
    line-height: 1.8rem;
    padding: 0;
    transition: all 0.2s;
    margin: 0 auto;
    border-bottom: 0.1rem solid #ffffff;
    padding-top: 3rem;

    &:hover {
      cursor: pointer;
      border-bottom: 0.1rem solid ${(p) => p.theme.colors.blueDarker};
    }
  }

  .amplify-alert__dismiss {
    display: none;
  }

  .amplify-button--primary {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    border: none;
    background: ${(p) => p.theme.colors.turquoiseDark};
    color: ${(p) => p.theme.colors.white};
    border-radius: 0.5rem;
    width: 100%;
    height: 5rem;
    letter-spacing: 0.05rem;
    text-transform: initial;
    margin: 1rem 0;
    cursor: pointer;
    transition: opacity 0.2s;
    overflow: hidden;
    align-self: flex-start;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .amplify-button--loading {
    display: flex;
    align-items: center;
    width: 100%;

    svg {
      display: none;
    }
  }

  .amplify-text--error {
    color: ${(p) => p.theme.colors.white};
    margin-top: 0;
  }

  .amplify-label {
    padding-bottom: 0.3rem;
    color: ${(p) => p.theme.colors.white};
    font-size: 1.5rem;
    display: block !important;
  }

  .amplify-button--small {
    background: none;
  }

  .amplify-link {
    margin: 2rem auto;
    color: ${(p) => p.theme.colors.white};
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.white};
    line-height: 1.8rem;
    transition: all 0.2s;

    &:hover {
      border-bottom: 0.1rem solid transparent;
    }
  }

  div {
    div {
      border: none !important;
    }
  }
`

export const Authentication = () => {
  const { cognitoUserPoolId, cognitoClientId, cognitoDomain, siteUrl, customerName } =
    useConfig().config

  const formFields = {
    setupTotp: {
      QR: {
        totpIssuer: `[${
          customerName.charAt(0).toUpperCase() + customerName.slice(1)
        }] Brink Commerce`
      }
    }
  }

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: cognitoClientId,
        userPoolId: cognitoUserPoolId,
        loginWith: {
          oauth: {
            domain: cognitoDomain,
            scopes: ["email", "profile", "openid"],
            responseType: "code",
            redirectSignIn: ["http://localhost:3000/", `https://${siteUrl}`],
            redirectSignOut: ["http://localhost:3000/", `https://${siteUrl}`]
          },
          username: true
        }
      }
    }
  })

  return (
    <StyledAuthentificator hideSignUp components={components} formFields={formFields}>
      <ApolloClientProvider>
        <SecureRoutes />
      </ApolloClientProvider>
    </StyledAuthentificator>
  )
}
