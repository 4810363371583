import gql from "graphql-tag"

const REMOVE_USER_FROM_GROUP = gql`
    mutation removeUserFromGroup(
        $username: AWSEmail!
        $groupName: String!
    ) {
        removeUserFromGroup(
            input: {username: $username groupname: $groupName}
        ) {
            status
        }
    }
`

export default REMOVE_USER_FROM_GROUP