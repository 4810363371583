import gql from "graphql-tag"

const ADD_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS = gql`
  mutation addExternalEventsDestinationEventBus($eventBusArn: String!) {
    addExternalEventsDestinationEventBus(eventBusArn: $eventBusArn) {
      eventBusArn
    }
  }
`

export default ADD_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS
