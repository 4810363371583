import React from "react"
import DineroFactory from "dinero.js"
import { BundleOutcome, Order, OrderLine } from "@lib/types/generated/graphql-types"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"

import {
  Columns, DiscountLabel,
  DiscountTotal,
  DiscountTotalLabel,
  DiscountTotalValue,
  Name,
  RuleLabel,
  RuleValue,
  StyledTableHeader,
  Total
} from "./OrderDiscount.styled"

import Money from "../../../../../components/Money"
import OrderBlock from "../../../../../components/Order/OrderBlock"
import { Item, Label, List, Value } from "../../../../../components/Ui/List/List"

import { ReactComponent as RuleIcon } from "../../../../../images/icons/cart-shopping.svg"
import { ReactComponent as CodeIcon } from "../../../../../images/icons/badge-percent.svg"
import { ReactComponent as ExternalRuleIcon } from "../../../../../images/icons/gear.svg"
import { ReactComponent as BundleGroupIcon } from "images/icons/boxes-stacked.svg"
import { ReactComponent as ArrowRight } from "../../../../../images/icons/arrow-right.svg"
import BundleDiscount from "../../../../../components/Order/Discount/BundleDiscount"

type Props = {
  data: Order
}

const OrderDiscount = ({ data }: Props) => {
  const navigate = useNavigate()
  const {
    totals,
    currencyCode,
    discountCodeRules,
    discountRules,
    orderLines,
    discountExternalRules
  } = data
  const productDiscountAmount =
    orderLines?.reduce((acc, a) => acc + a.discountAmount * a.quantity, 0) ?? 0
  const discountCartRuleAmount =
    discountRules?.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0
  const discountCodeAmount =
    discountCodeRules?.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0
  const discountExternalRuleAmount =
    discountExternalRules?.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0

  type BundleOrderLine = {
    orderLine: OrderLine
    bundles: BundleOutcome[]
  }

  const bundleOrderLines = orderLines
    ?.flatMap(
      (orderLine) =>
        ({
          orderLine: orderLine,
          bundles: orderLine.discountOutcome.bundles
        } as BundleOrderLine)
    )
    .filter((orderLine) => orderLine.bundles.length > 0)

  const discountBundlesAmountArray = bundleOrderLines?.flatMap(
    (orderLine) =>
      orderLine.bundles.reduce((acc, { discountAmount }) => acc + (discountAmount ?? 0), 0) ?? 0
  )

  const discountBundlesAmount = discountBundlesAmountArray?.reduce((acc, a) => acc + a, 0) ?? 0

  return (
    <>
      <Columns>
        {discountCodeRules && discountCodeRules.length > 0 && (
          <OrderBlock>
            <h2>
              <CodeIcon /> Discount codes used
            </h2>
            <List>
              <>
                <StyledTableHeader>
                  <div>Code</div>
                  <div>Rule</div>
                  <div>Discount value</div>
                </StyledTableHeader>
                {discountCodeRules.map((discountCodeRule) => (
                  <Item key={uuidv4()}>
                    <RuleLabel>{discountCodeRule.code}</RuleLabel>
                    <RuleValue>
                      <>
                        <Name
                          onClick={() => {
                            navigate(`/discounts/discount-code-rules/${discountCodeRule.id}`)
                          }}
                        >
                          {discountCodeRule.name}
                          <ArrowRight />
                        </Name>
                      </>
                    </RuleValue>
                    <Value>
                      <>
                        {(discountCodeRule?.discountAmount ?? 0) > 0 && (
                          <Money
                            amount={parseInt(`${discountCodeRule.discountAmount}`)}
                            currencyUnit={currencyCode as DineroFactory.Currency}
                          />
                        )}{" "}
                        {discountCodeRule.freeShipping && " (Free shipping)"}
                      </>
                    </Value>
                  </Item>
                ))}
              </>
            </List>
          </OrderBlock>
        )}
        {discountRules && discountRules.length > 0 && (
          <OrderBlock>
            <h2>
              <RuleIcon /> Cart rules applied
            </h2>
            <List>
              <>
                <StyledTableHeader>
                  <div>Cart rule</div>
                  <div style={{ textAlign: "right" }}>Discount value</div>
                </StyledTableHeader>
                {discountRules.map((discountRule) => (
                  <Item key={uuidv4()}>
                    <RuleLabel
                      handleOnClick={() => {
                        navigate(`/discounts/cart-rules/${discountRule.id}`)
                      }}
                    >
                      <>
                        {discountRule.name} <ArrowRight />
                      </>
                    </RuleLabel>
                    <Value>
                      <>
                        {(discountRule?.discountAmount ?? 0) > 0 && (
                          <Money
                            amount={parseInt(`${discountRule.discountAmount}`)}
                            currencyUnit={currencyCode as DineroFactory.Currency}
                          />
                        )}{" "}
                        {discountRule.freeShipping && " (Free shipping)"}
                      </>
                    </Value>
                  </Item>
                ))}
              </>
            </List>
          </OrderBlock>
        )}
        {discountExternalRules && discountExternalRules.length > 0 && (
          <OrderBlock>
            <h2>
              <ExternalRuleIcon /> External rules applied
            </h2>
            <List>
              <>
                <StyledTableHeader>
                  <div>External rule</div>
                  <div style={{ textAlign: "right" }}>Discount value</div>
                </StyledTableHeader>
                {discountExternalRules.map((discountRule) => (
                  <Item key={uuidv4()}>
                    <RuleLabel
                      handleOnClick={() => {
                        navigate(`/discounts/external-rules/${discountRule.id}`)
                      }}
                    >
                      <>
                        {discountRule.name} <ArrowRight />
                      </>
                    </RuleLabel>
                    <Value>
                      <>
                        {(discountRule?.discountAmount ?? 0) > 0 && (
                          <Money
                            amount={parseInt(`${discountRule.discountAmount}`)}
                            currencyUnit={currencyCode as DineroFactory.Currency}
                          />
                        )}{" "}
                        {discountRule.freeShipping && " (Free shipping)"}
                      </>
                    </Value>
                  </Item>
                ))}
              </>
            </List>
          </OrderBlock>
        )}
        {bundleOrderLines && bundleOrderLines?.length > 0 && (
          <>
            <DiscountLabel>
              <BundleGroupIcon />
              Bundle discount applied
            </DiscountLabel>
            {bundleOrderLines.map((bundleOrderLine) => (
              <BundleDiscount
                bundleOrderLine={bundleOrderLine}
                currencyCode={currencyCode}
                key={bundleOrderLine.orderLine.id}
              />
            ))}
          </>
        )}
      </Columns>
      <DiscountLabel>
       Total discount
      </DiscountLabel>
      <DiscountTotal>
        <List>
          {discountCodeAmount > 0 ? (
            <Total>
              <Label>Total discount from codes:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountCodeAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {discountCartRuleAmount > 0 ? (
            <Total>
              <Label>Total discount from cart rules:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountCartRuleAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {discountExternalRuleAmount > 0 ? (
            <Total>
              <Label>Total discount from external rules:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountExternalRuleAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {discountBundlesAmount > 0 ? (
            <Total>
              <Label>Total discount from bundles:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${discountBundlesAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {productDiscountAmount > 0 ? (
            <Total>
              <Label>Total discount from products:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${productDiscountAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          {productDiscountAmount > 0 &&
          discountCartRuleAmount === 0 &&
          discountCodeAmount === 0 &&
          productDiscountAmount !== totals?.discountTotal ? (
            <Total>
              <Label>Total discount from codes/rules/bundles:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${totals?.discountTotal - productDiscountAmount}`)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          ) : (
            <></>
          )}
          <Total>
            <DiscountTotalLabel>Total discount amount </DiscountTotalLabel>
            <DiscountTotalValue alignRight>
              <Money
                amount={parseInt(`${totals?.discountTotal}`)}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </DiscountTotalValue>
          </Total>
        </List>
      </DiscountTotal>
    </>
  )
}

export default OrderDiscount
