import gql from "graphql-tag"

const UPDATE_DELIVERY = gql`
  mutation updateDelivery($deliveryId: ID!, $input: InputOrderDelivery!) {
    Order {
      updateDelivery(deliveryId: $deliveryId, input: $input) {
        id
      }
    }
  }
`

export default UPDATE_DELIVERY
