import React from "react"
import styled from "styled-components/macro"
import { Controller } from "react-hook-form"
import { AvardaConfig, InputAvardaConfig } from "lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { SidebarDivider } from "components/Dashboard/Shared.styled"
import Input from "components/Ui/Form/Input"
import SingleSelect from "components/Ui/Form/SingleSelect"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"

const StyledSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

interface ExtendedInputAvardaConfig {
  id: string
  avardaConfig: InputAvardaConfig
}

type Props = {
  control: Control<ExtendedInputAvardaConfig>
  errors: FieldErrors
  setValue: UseFormSetValue<ExtendedInputAvardaConfig>
  showId?: boolean
  defaultValues?: AvardaConfig
}

export const AvardaConfigInputs = ({
  control,
  errors,
  setValue,
  showId = true,
  defaultValues
}: Props) => {

  const updateBaseUrl = (baseUrl: Common.Option) => {
    setValue("avardaConfig.baseUrl", baseUrl.value)
  }

  const updateAuthUrl = (authUrl: Common.Option) => {
    setValue("avardaConfig.authUrl", authUrl.value)
  }

  const updateShippingBroker = (brokerName: Common.Option) => {
    setValue("avardaConfig.shippingBroker.name", brokerName.value)
  }

  const baseUrlOptions = [
    {
      value: "https://stage.checkout-api.avarda.com",
      label: "Test | https://stage.checkout-api.avarda.com"
    },
    {
      value: "https://checkout-api.avarda.com",
      label: "Production | https://checkout-api.avarda.com"
    }
  ]

  const authUrlOptions = [
    {
      value: "https://stage.authorization-api.avarda.com/oauth2/token",
      label: "Test | https://stage.authorization-api.avarda.com/oauth2/token"
    },
    {
      value: "https://authorization-api.avarda.com/oauth2/token",
      label: "Production | https://authorization-api.avarda.com/oauth2/token"
    }
  ]

  const ShippingBrokerOptions = [
    {
      value: "Ingrid",
      label: "Ingrid"
    },
    {
      value: "Nshift",
      label: "Nshift"
    }
  ]

  return (
    <>
      {showId && (
        <>
          <Controller
            name="id"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Identifier *"
                description="For internal use in Brink"
                placeholder="Aa - Zz, 0 - 9, -_."
                errors={errors}
              />
            )}
            control={control}
            defaultValue=""
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_.-]+$/,
                message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
              }
            }}
          />
          <SidebarDivider />
        </>
      )}
      <Controller
        name="avardaConfig.baseUrl"
        render={({ field }) => (
          <StyledSelect
            {...field}
            options={baseUrlOptions}
            setValue={updateBaseUrl}
            defaultValue={{
              label:
                baseUrlOptions.find((option) => {
                  // remove trailing slash for comparison
                  return option.value === defaultValues?.baseUrl?.replace(/\/$/, "")
                })?.label ?? "Select base URL",
              value: defaultValues?.baseUrl ?? ""
            }}
            label="Base URL *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="avardaConfig.authUrl"
        render={({ field }) => (
          <StyledSelect
            {...field}
            options={authUrlOptions}
            setValue={updateAuthUrl}
            defaultValue={{
              label:
                authUrlOptions.find((option) => {
                  // remove trailing slash for comparison
                  return option.value === defaultValues?.authUrl?.replace(/\/$/, "")
                })?.label ?? "Select auth URL",
              value: defaultValues?.authUrl ?? ""
            }}
            label="Auth URL *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="avardaConfig.clientId"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Avarda client ID *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />

      <Controller
        name="avardaConfig.clientSecret"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Avarda client secret *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="avardaConfig.shippingBroker.name"
        render={({ field }) => (
          <StyledSelect
            {...field}
            options={ShippingBrokerOptions}
            setValue={updateShippingBroker}
            defaultValue={{
              label:
                ShippingBrokerOptions.find((option) => {
                  return option.value === defaultValues?.shippingBroker?.name
                })?.label ?? "Select shipping broker (optional)",
              value: defaultValues?.shippingBroker?.name ?? ""
            }}
            label="Shipping broker"
          />
        )}
        control={control}
      />
    </>
  )
}
