import styled from "styled-components/macro"

export const BoldText = styled.div`
  padding-bottom: 0.5rem;
  font-weight: 700;
`

export const BundleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`
export const Icon = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 2rem;
`
