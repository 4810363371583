import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputNShiftConfig } from "@lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_OR_UPDATE_NSHIFT_CONFIG from "graphql/mutations/config/CreateOrUpdateNShiftCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import NShiftConfigInputs from "../../../../components/Config/NShift/NShiftConfigInputs"

interface ExtendedInputNShiftConfig extends InputNShiftConfig {
  id: string
}

type Props = {
  refetch: () => void
}

const BASE_URL = "https://api.unifaun.com/rs-extapi/v1"

export const CreateNShiftConfig = ({ refetch }: Props) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ExtendedInputNShiftConfig>({ mode: "onBlur" })

  const [addNShiftConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_NSHIFT_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          clientId: "",
          deliveryCheckoutId: "",
          clientSecret: ""
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "nShift configuration successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputNShiftConfig> = (data) => {
    addNShiftConfig({ variables: { ...data, baseUrl: BASE_URL } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create nShift configuration">
          <PrimaryButton type="submit" loading={createLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <NShiftConfigInputs control={control} errors={errors} showId={true} />
      </EditSidebar>
    </form>
  )
}
