import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import Select from "react-select"
import { Common } from "@lib/types"
import { isSuperUser, isCampaignUser } from "helpers/user"
import { customStyles, Label, SelectContainer } from "./SingleSelect.styled"

type Props = {
  options: Common.Option[]
  setValue?: Dispatch<SetStateAction<any>> // eslint-disable-line
  defaultValue?: Common.Option | null
  placeholder?: string
  useCustomOnChange?: boolean
  handleOnChange?: () => void
  label?: string | JSX.Element
  disabled?: boolean
  overrideDisabled?: boolean
  updateDefaultValue?: boolean
  formatOptionLabel?: (data: Common.Option) => ReactNode
}

const SingleSelect: React.FC<Props> = React.forwardRef<any, Props>( // eslint-disable-line
  (
    {
      options,
      setValue,
      defaultValue,
      placeholder,
      useCustomOnChange,
      handleOnChange,
      label,
      disabled,
      overrideDisabled,
      formatOptionLabel,
      updateDefaultValue,
      ...props
    }: Props,
    ref
  ) => {
    const [selectedOption, setSelectedOption] = useState<Common.Option | null>(defaultValue ?? null)

    useEffect(() => {
      setValue && setValue(selectedOption)
    }, [selectedOption, setValue])

    useEffect(() => {
      updateDefaultValue && setSelectedOption(defaultValue ?? null)
    }, [defaultValue])

    const isDisabled = () => {
      if(disabled) return disabled
      if (overrideDisabled || isCampaignUser() || isSuperUser()) return false
      return !isSuperUser()
    }

    return (
      <SelectContainer invalid={false} {...props}>
        {label && <Label>{label}</Label>}
        <Select
          value={selectedOption}
          placeholder={placeholder}
          ref={ref}
          onChange={useCustomOnChange ? () => handleOnChange : (e) => setSelectedOption(e)}
          options={options}
          isDisabled={isDisabled()}
          formatOptionLabel={formatOptionLabel}
          styles={customStyles}
        />
      </SelectContainer>
    )
  }
)

SingleSelect.displayName = "SingleSelect"

export default SingleSelect
