import { OrderRefund, RefundFee } from "@lib/types/generated/graphql-types"

const getRefundFeeInput = (fee: RefundFee) => ({
  name: fee.name,
  amount: fee.amount,
  taxPercentage: fee.taxPercentage,
  taxPercentageDecimals: fee.taxPercentageDecimals
})

export const getRefundInput = (refund: OrderRefund | undefined) => ({
  ...(refund?.orderLines && {
    orderLines: refund?.orderLines.map ((orderLine) => ({
      orderLineId: orderLine.orderLineId,
      quantity: orderLine.quantity
    }))
  }),
  ...(refund?.reason && { reason: refund.reason }),
  ...(refund?.shippingFees && {
    shippingFees: refund.shippingFees.map ((fee) => fee.shippingFeeId)
  }),
  ...(refund?.gifts && { gifts: refund.gifts }),
  ...(refund?.fee && { fee: getRefundFeeInput(refund.fee)})
})
