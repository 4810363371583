import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Box from "components/Ui/Box"
import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { BundleGroupSearchHit } from "@lib/types/generated/graphql-types"
import { BundleGroupFilter, Option, Query } from "lib/types/common"
import { useLazyQuery } from "@apollo/client"
import SEARCH_BUNDLE_GROUPS from "../../../graphql/queries/bundles/SearchBundleGroups"
import BundleGroupFilters from "../../../components/Discount/Bundles/BundleGroupFilters"
import { BundleGroupTable } from "../../../components/Discount/Bundles/BundleGroupTable"

const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

const DEFAULT_BUNDLE_GROUP_FILTER = {
  fromDate: "",
  toDate: "",
  isActive: [] as Option[],
  numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
  searchQuery: ""
}

export const ShowBundleGroups = () => {
  const [bundleGroups, setBundleGroups] = useState<BundleGroupSearchHit[]>([])
  const [esbQuery, setEsbQuery] = useState<Query>()
  const [totalBundleGroups, setTotalBundleGroups] = useState<number>()
  const [bundleGroupFilter, setBundleGroupFilter] = useState<BundleGroupFilter>(
    DEFAULT_BUNDLE_GROUP_FILTER
  )
  const navigate = useNavigate()


  const setSearchQuery = (value: string) =>
    setBundleGroupFilter({ ...bundleGroupFilter, ...{ searchQuery: value } })
  const query = esbQuery?.toJSON() as Query

  const [searchBundleGroups, { loading, error }] = useLazyQuery(SEARCH_BUNDLE_GROUPS, {
    onCompleted: (data) => {
      setBundleGroups(data?.searchBundleGroups)
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if(query) {
          searchBundleGroups({
            variables: {
              from: 0,
              size: bundleGroupFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query)
            }
          }).then((response) => {
            setBundleGroups(response?.data?.searchBundleGroups?.hits)
            setTotalBundleGroups(response?.data?.searchBundleGroups?.total)
          })
        }
      },
      bundleGroupFilter?.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [bundleGroupFilter?.searchQuery, bundleGroupFilter?.numberOfHits, esbQuery])

  return (
    <Box padding="0" margin="0">
      <PageHeader
        title="Bundle groups"
        description="Edit your existing bundle groups or create a new one."
      >
        <PrimaryButton
          type="button"
          handleClick={() => navigate("/discounts/create-bundle-group")}
        >
          <PlusIcon /> Add new bundle group
        </PrimaryButton>
      </PageHeader>
      <Box padding="0" margin="0">
        <BundleGroupFilters
          setSearchQuery={setSearchQuery}
          loading={loading}
          searchQuery={bundleGroupFilter?.searchQuery ?? ""}
          setEsbQuery={setEsbQuery}
          bundleGroupFilter={bundleGroupFilter}
          setBundleGroupFilter={setBundleGroupFilter}
          numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
          defaultBundleGroupFilter={DEFAULT_BUNDLE_GROUP_FILTER}
          totalHits={totalBundleGroups}
        />
        <BundleGroupTable
          bundleGroups={bundleGroups}
          setBundleGroups={setBundleGroups}
          loading={loading}
          error={error}
        />
      </Box>
    </Box>
  )
}
