import React from "react"
import { Order, OrderRelease } from "lib/types/generated/graphql-types"
import { Container, Label, ShippingFeeWrapper } from "./ShippingFees.styled"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"

type Props = {
  release: OrderRelease
  currencyCode: string
  order: Order
}

const ShippingFees = ({ release, currencyCode, order }: Props) => {

  if (release?.shippingFees.length < 1) return null

  return (
    <Container addedReason={!!release?.reason}>
      <Label> Shipping:</Label>
      {release?.shippingFees.map((fee) => {
        const orderShippingFee = order?.shippingFees?.find(
          (orderFee) => orderFee.id === fee.shippingFeeId
        )
        return (
          <ShippingFeeWrapper key={fee.shippingFeeId}>
            <div>
              <b>{orderShippingFee?.name}</b>
              {" - "}
              <Money
                amount={orderShippingFee?.salePriceAmount ?? 0}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </div>
          </ShippingFeeWrapper>
        )
      })}
    </Container>
  )
}

export default ShippingFees
