import React, { ReactNode } from "react"
import { Container } from "./NoticeBanner.styled"
import { ReactComponent as ExclamationIcon } from "images/icons/circle-exclamation.svg"

type Props = {
  children: ReactNode
  type?: "info" | "reminder" | "important"
}

export const NoticeBanner = ({ children, type = "info" }: Props) => (
  <Container $type={type} >
    <ExclamationIcon />
    <p>{children}</p>
  </Container>
)
