import gql from "graphql-tag"

const DELETE_BUNDLE_GROUP = gql`
  mutation deleteBundleGroup($id: ID!) {
    deleteBundleGroup(id: $id) {
      status
    }
  }
`

export default DELETE_BUNDLE_GROUP
