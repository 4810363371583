import gql from "graphql-tag"

const SEARCH_STORE_MARKETS = gql`
  query searchStoreMarkets($from: Int!, $size: Int!, $sort: [SearchSorting!], $query: AWSJSON) {
    searchStoreMarkets(from: $from, size: $size, sort: $sort, query: $query) {
      total
      hits {
        storeGroupId
        countryCode
        name
        description
        currencyCode
        isTaxIncludedInPrice
        isTaxExemptionEligible
        isArchived
        created
        updated
        taxProviders {
          id
          type
        }
        paymentProviders {
          id
          type
        }
        shippingProviders {
          id
          type
        }
        cartCapabilities {
          voyado {
            id
          }
          bonus {
            id
          }
        }
        giftCardProviders {
          id
          type
        }
      }
    }
  }
`

export default SEARCH_STORE_MARKETS
