import React from "react"
import { DeliveryShippingFee, Order, OrderCompensation } from "lib/types/generated/graphql-types"
import { DeliveryButtonWrapper, Name, Row } from "../Shared.styled"

import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as PlusIcon } from "../../../../../images/icons/circle-plus.svg"
import { ReactComponent as ShippingIcon } from "../../../../../images/icons/cart-flatbed-boxes.svg"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import { compensationShippingFeesOutputToInput, getCompensationInput } from "../Helpers/helpers"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  shippingFee: DeliveryShippingFee
  order: Order
  currencyCode: string
  compensations: OrderCompensation[]
  refetch: () => void
}

export const ShippingRow = ({
  shippingFee,
  currencyCode,
  compensations,
  refetch,
  order
}: Props) => {
  const notCompletedCompensation = compensations.find(
    (compensation) => !compensation.started || (compensation.started && !compensation.completed)
  )
  const shippingFeeExistsInCompensation = notCompletedCompensation?.shippingFees?.find(
    (fee) => fee.shippingFeeId === shippingFee.shippingFeeId
  )

  const [updateCompensation, { loading: updateLoading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const orderShippingFee = order?.shippingFees?.find((fee) => fee.id === shippingFee.shippingFeeId)

  const updateOrderCompensation = () => {
    return updateCompensation({
      variables: {
        compensationId: notCompletedCompensation?.id,
        input: {
          ...getCompensationInput(notCompletedCompensation),
          shippingFees: [
            ...(compensationShippingFeesOutputToInput(
              notCompletedCompensation?.shippingFees ?? []
            ) ?? []),
            {
              shippingFeeId: shippingFee.shippingFeeId,
              taxPercentage: orderShippingFee?.taxPercentage,
              taxPercentageDecimals: orderShippingFee?.taxPercentageDecimals,
              totalAmount: orderShippingFee?.salePriceAmount,
              totalDiscountAmount: orderShippingFee?.discountAmount,
              totalTaxAmount: orderShippingFee?.taxAmount
            }
          ]
        }
      }
    })
  }

  return (
    <Row key={shippingFee.shippingFeeId}>
      <ShippingIcon />
      <div>
        <Name>{orderShippingFee?.name}</Name>
        <Money
          amount={shippingFee?.totalAmount ?? 0}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>
      </div>
      <DeliveryButtonWrapper>
        <SecondaryButton
          handleClick={updateOrderCompensation}
          disabled={!!shippingFeeExistsInCompensation}
          loading={updateLoading}
        >
          <PlusIcon />
          Include
        </SecondaryButton>
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={shippingFee?.totalAmount ?? 0}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </DeliveryButtonWrapper>
    </Row>
  )
}
