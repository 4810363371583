import React, { useState, MutableRefObject } from "react"
import styled from "styled-components/macro"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { Common } from "@lib/types"
import { Outcomes, FrontendOutcome, TagCondition } from "@lib/types/discount"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"

import SingleSelect from "components/Ui/Form/SingleSelect"
import Input from "components/Ui/Form/Input"
import QuickAdd from "components/Ui/Table/QuickAdd"
import { TagConditions } from "components/Discount/Ui/TagConditions/TagConditions"
import Checkbox from "components/Ui/Form/Checkbox"
import FixedDiscounts from "../../FixedDiscounts"

const ConditionsAdd = styled(QuickAdd)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
  display: block;
`

const Percentage = styled.div`
  width: 100%;
  flex: auto !important;
  margin-top: 2rem;
  display: flex;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0.5rem 0 0;
  margin-bottom: 1.5rem;
`

type Inputs = {
  discountValueType: string
  valueType: string
  triggerQuantity: number
  discountQuantity: number
  percentage: number
  currency: string
  amount: number
  isRecurrent: boolean
  combineItemDiscount: boolean
}

type Props = {
  outcome?: FrontendOutcome
  addOutcome: (data: Outcomes) => void
  currencyOptions: Common.Option[]
  submitRef: MutableRefObject<HTMLButtonElement>
}

const getOption = (type: string) => {
  switch (type) {
    case "RELATIVE":
      return {
        value: "RELATIVE",
        label: "Percentage"
      }
    case "FIXED":
      return {
        value: "FIXED",
        label: "Fixed amount"
      }
    default:
      return {
        label: "Select discount value type",
        value: ""
      }
  }
}

export const BuySelect = ({ outcome, addOutcome, currencyOptions, submitRef }: Props) => {
  const dispatch = useAppDispatch()
  const [fixedDiscounts, setFixedDiscounts] = useState<Common.Money[]>(outcome?.data?.discountValue?.fixedDiscounts ?? [])
  const [valueType, setValueType] = useState<string>(outcome?.data?.discountValue?.type ?? "")
  const [discountTagConditions, setDiscountTagConditions] = useState<TagCondition[]>(
    outcome?.data?.discountItemRule?.tagConditions ?? []
  )
  const [excludeDiscountedPricesDiscount, setExcludeDiscountedPricesDiscount] = useState(
    outcome?.data?.discountItemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPricesDiscount, setExcludeFullPricesDiscount] = useState(
    outcome?.data?.discountItemRule?.excludeFullPrices ?? false
  )
  const [excludeDiscountedPricesTrigger, setExcludeDiscountedPricesTrigger] = useState(
    outcome?.data?.triggerItemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPricesTrigger, setExcludeFullPricesTrigger] = useState(
    outcome?.data?.triggerItemRule?.excludeFullPrices ?? false
  )
  const [triggerTagConditions, setTriggerTagConditions] = useState<TagCondition[]>(
    outcome?.data?.triggerItemRule?.tagConditions ?? []
  )

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<Inputs>()

  const updateValueType = (valueType: Common.Option) => {
    setValueType(valueType.value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!valueType) {
      dispatch(
        alertActions.actions.createAlert({
          message: "Please select a discount value type",
          type: "error"
        })
      )
      return
    }
    if (valueType === "FIXED" && fixedDiscounts.length === 0) {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one fixed discount is required",
          type: "error"
        })
      )
      return
    }
    addOutcome({
      combineItemDiscount: data.combineItemDiscount,
      isRecurrent: data.isRecurrent,
      triggerQuantity: data.triggerQuantity,
      discountQuantity: data.discountQuantity,
      discountValue: {
        type: valueType,
        ...(valueType === "FIXED" && {
          fixedDiscounts: fixedDiscounts,
          percentage: undefined
        }),
        ...(valueType === "RELATIVE" && {
          percentage: data.percentage,
          fixedDiscounts: []
        })
      },
      discountItemRule: {
        tagConditions: discountTagConditions,
        excludeDiscountedPrices: excludeDiscountedPricesDiscount,
        excludeFullPrices: excludeFullPricesDiscount
      },
      triggerItemRule: {
        tagConditions: triggerTagConditions,
        excludeDiscountedPrices: excludeDiscountedPricesTrigger,
        excludeFullPrices: excludeFullPricesTrigger
      }
    })
  }

  return (
    <>
      <ConditionsAdd>
        <form>
          <StyledCheckbox
            control={control}
            name="isRecurrent"
            label="Is recurrent"
            description="Defines if an outcome is recurrent"
            defaultValue={outcome?.data?.isRecurrent ?? false}
          />
          <StyledCheckbox
            control={control}
            name="combineItemDiscount"
            label="Combine item discount"
            description="Combine discount with other discounts"
            defaultValue={outcome?.data?.combineItemDiscount ?? false}
          />
          <Controller
            name="triggerQuantity"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Trigger quantity *"
                description="Quantity that is used as a trigger"
                errors={errors}
              />
            )}
            defaultValue={outcome?.data?.triggerQuantity}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              }
            }}
          />
          <Controller
            name="discountQuantity"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                label="Discount quantity *"
                description="Quantity that will be discounted"
                errors={errors}
              />
            )}
            defaultValue={outcome?.data?.discountQuantity}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^\d+$/,
                message: "Only integers 0 - 9 is allowed"
              }
            }}
          />
          <Controller
            name="valueType"
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={[getOption("FIXED"), getOption("RELATIVE")]}
                setValue={updateValueType}
                label="Discount output"
                defaultValue={valueType ? getOption(valueType) : getOption("")}
              />
            )}
            control={control}
          />

          <>
            {valueType === "RELATIVE" && (
              <Percentage>
                <Controller
                  name="percentage"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Percentage, 1 - 100"
                      label="Percentage value *"
                      errors={errors}
                    />
                  )}
                  defaultValue={outcome?.data?.discountValue?.percentage ?? undefined}
                  control={control}
                  rules={{
                    required: "This is a required field",
                    pattern: {
                      value: /^\d+$/,
                      message: "Only integers 0 - 9 is allowed"
                    },
                    min: {
                      value: 1,
                      message: "Min value is 1"
                    },
                    max: { value: 100, message: "Max value is 100" }
                  }}
                />
              </Percentage>
            )}
            {valueType === "FIXED" && (
              <FixedDiscounts fixedDiscounts={fixedDiscounts} setFixedDiscounts={setFixedDiscounts} currencyOptions={currencyOptions} />
            )}
          </>
          <button onClick={handleSubmit(onSubmit)} ref={submitRef} type="submit" style={{ display: "none" }} />
        </form>
      </ConditionsAdd>
      <TagConditions
        key="discount"
        tagConditions={triggerTagConditions}
        setTagConditions={setTriggerTagConditions}
        label="Trigger tag rules"
        description="By adding this items to the cart the shopper can then receive discount on items specified in Discount tag rules"
        excludeDiscountedPrices={excludeDiscountedPricesTrigger}
        setExcludeDiscountedPrices={setExcludeDiscountedPricesTrigger}
        excludeFullPrices={excludeFullPricesTrigger}
        setExcludeFullPrices={setExcludeFullPricesTrigger}
      />
      <br />
      <TagConditions
        key="tag"
        tagConditions={discountTagConditions}
        setTagConditions={setDiscountTagConditions}
        label="Discount tag rules"
        description="When items in Trigger tag rules is in the cart the shopper can will receive discount on added items"
        excludeDiscountedPrices={excludeDiscountedPricesDiscount}
        setExcludeDiscountedPrices={setExcludeDiscountedPricesDiscount}
        excludeFullPrices={excludeFullPricesDiscount}
        setExcludeFullPrices={setExcludeFullPricesDiscount}
      />
    </>
  )
}
