import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components/macro"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"

import CopyButton from "../CopyButton"

import { ReactComponent as ArrowLeft } from "images/icons/arrow-left.svg"

const Container = styled.div`
  width: 100%;
  margin: 0 0 3rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 0 0 2rem;
  position: relative;

  > p {
    margin-top: 3rem;
    color: ${(p) => p.theme.colors.greyDarker};
  }
`

const Title = styled.div`
  margin: 9rem 0 0;
  display: flex;
  align-items: center;
  padding-right: 8rem;

  img {
    margin-right: 1rem;
  }

  svg {
    height: 3rem;
    max-width: 7rem;
    position: absolute;
    right: 0;
  }
`

const PageTitle = styled.h1`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
`

const Id = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 1.3rem;
    text-decoration: underline;
    color: ${(p) => p.theme.colors.greyDark};
    margin: 0;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -2rem 2rem;
  padding: 0 2rem;
  height: 7rem;
  position: fixed;
  z-index: 10000;
  width: 100%;
  top: 0;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.greyLighter};

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 55rem;
    margin: 0 -3rem 2rem;
    padding: 0 3rem;
  }
`

const Link = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 1.6rem;
    margin-right: 0.5rem;
  }

  > span {
    line-height: 1.8rem;
    cursor: pointer;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.black};
    transition: 0.2s;

    &:hover {
      border-bottom: 0.1rem solid transparent;
    }
  }
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin-left: 1rem;
  }
`

type Props = {
  children?: JSX.Element | JSX.Element[]
  title: string | JSX.Element
  icon?: JSX.Element
  id?: string
  cancelEvent?: () => void
  hideCancel?: boolean
  setEditId?: Dispatch<SetStateAction<string>>
}

const PageHeader = ({ children, title, id, icon, setEditId, cancelEvent, hideCancel, ...props }: Props) => {
  const dispatch = useAppDispatch()
  const closeSideBar = () => {
    dispatch(hideEditSidebar())
    setEditId && setEditId("")
  }

  return (
    <>
      <Container {...props}>
        <Header>
          {hideCancel ? <></>: cancelEvent ? (
            <Link onClick={cancelEvent}>
              <ArrowLeft />
              <span>Cancel</span>
            </Link>
          ) : (
            <Link onClick={closeSideBar}>
              <ArrowLeft />
              <span>Cancel</span>
            </Link>
          )}
          <Actions>{children}</Actions>
        </Header>
        <Title>
          {icon && icon}
          <PageTitle>{title}</PageTitle>
        </Title>

        {id && (
          <Id>
            <span>{id}</span>
            <CopyButton string={id} />
          </Id>
        )}
      </Container>
    </>
  )
}

export default PageHeader
