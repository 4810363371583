import styled from "styled-components/macro"


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`


export const Label = styled.h4`
margin: 1rem 0 0 0;
`

export const ShippingFeeWrapper = styled.div`
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 3rem;
  }
`


