import React, { Dispatch, ReactNode, SetStateAction } from "react"

import {
  Container,
  Blur,
  StyledErrorMessage,
  BarContainer,
  ProgressBar
} from "./ProgressPopup.styled"

import PrimaryButton from "./Buttons/PrimaryButton"
import { ButtonWrapper, CancelButton } from "./Popup.styled"

type Props = {
  title: string
  entity?: string
  progressBarText: string
  finishedText: string
  successCount: number
  failedCount: number
  total: number
  children?: ReactNode
  handleClose: Dispatch<SetStateAction<boolean>>
  resetCart: () => void
}

export const ProgressPopup = ({
  title,
  entity,
  progressBarText,
  finishedText,
  successCount,
  failedCount,
  total,
  children,
  handleClose,
  resetCart
}: Props) => {
  const percentage = ((successCount + failedCount) / total) * 100

  return (
    <>
      <Blur onClick={() => handleClose(false)} />
      <Container>
        <h2>{title}</h2>
        {entity}

        <BarContainer>
          <ProgressBar $percentage={percentage} />

          {successCount + failedCount === total ? (
            <span>{finishedText}</span>
          ) : (
            <span>{progressBarText}</span>
          )}
        </BarContainer>

        {failedCount > 0 && (
          <>
            <StyledErrorMessage
              message={
                <>
                  <b>{failedCount} </b> variant{failedCount > 1 && "s"} could not be added
                </>
              }
            />
            {children}
            <ButtonWrapper>
              <CancelButton
                handleClick={() => {
                  resetCart()
                  handleClose(false)
                }}
              >
                Cancel draft
              </CancelButton>
              <PrimaryButton
                handleClick={() => handleClose(false)}
                disabled={successCount + failedCount !== total || failedCount === total}
              >
                Continue anyway
              </PrimaryButton>
            </ButtonWrapper>{" "}
          </>
        )}
      </Container>
    </>
  )
}
