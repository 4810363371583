import React from "react"
import { OrderHistoryLogEntry } from "@lib/types/generated/graphql-types"
import { Container } from "./ErrorMessages.styled"
import ErrorMessageEntry from "./ErrorMessageEntry"

type Props = {
  orderHistory: OrderHistoryLogEntry[]
  operationId: string
  operation: string
}

const ErrorMessages = ({ orderHistory, operationId, operation }: Props) => {
  const errorMessages = orderHistory
    ?.filter((entry) => entry.operationId === operationId)
    ?.filter((entry) => entry.operation === operation)
    ?.filter((entry) => entry.status.endsWith("_FAILURE"))

  if (errorMessages?.length === 0) {
    return null
  }

  const entryKey = (e: OrderHistoryLogEntry) => `${e.operationId}-${e.timestamp}`
  return (
    <Container>
      {[...errorMessages].reverse().map((entry) => (
        <ErrorMessageEntry
          entry={entry}
          key={entryKey(entry)}
        />
      ))}
    </Container>
  )
}

export default ErrorMessages
