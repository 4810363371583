import React, { useState } from "react"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import SectionChoice from "../../../components/Discount/Bundles/SectionChoice"
import GeneralSettings from "../../../components/Discount/Bundles/Steps/GeneralSettings"
import Box from "../../../components/Ui/Box"
import { useForm } from "react-hook-form"
import {
  InputCreateOrUpdateBundleGroup,
  InputDiscountValue,
  InputDiscountValueType, InputMaybe,
  InputStoreMarket,
  StoreGroup
} from "lib/types/generated/graphql-types"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import {
  BundleSection,
  BundleSectionValue,
  InputBundleItemRuleWithId,
  InputBundleWithItemRuleId
} from "lib/types/discount"
import SetChannels from "../../../components/Discount/Bundles/Steps/SetChannels"
import { useMutation, useQuery } from "@apollo/client"
import ALL_STORE_GROUPS from "../../../graphql/queries/store/AllStoreGroups"
import AddBundles from "../../../components/Discount/Bundles/Steps/AddBundles"
import AddedBundles from "../../../components/Discount/Bundles/Steps/BundleItem/AddedBundles"
import { ButtonWrapper } from "./CreateBundleGroup.styled"
import Result from "../../../components/Discount/Bundles/Steps/Result"
import { ReactComponent as SaveIcon } from "images/icons/floppy-disk.svg"
import { ReactComponent as SaveActivateIcon } from "images/icons/floppy-disk-circle-arrow-right.svg"
import CREATE_OR_UPDATE_BUNDLE_GROUP from "../../../graphql/mutations/bundles/CreateOrUpdateBundleGroup"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"

const CreateBundleGroup = () => {
  const [addedStoreMarkets, setAddedStoreMarkets] = useState<InputStoreMarket[]>([])
  const [bundles, setBundles] = useState<InputBundleWithItemRuleId[]>([])
  const [storeGroups, setStoreGroups] = useState<StoreGroup[]>([])
  const [discountValue, setDiscountValue] = useState<InputDiscountValue | undefined>()
  const [itemRules, setItemRules] = useState<InputBundleItemRuleWithId[]>([])
  const [editBundleId, setEditBundleId] = useState<string>("")
  const [selectedSection, setSelectedSection] = useState<BundleSectionValue>(
    BundleSectionValue.GENERAL
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<InputCreateOrUpdateBundleGroup>()

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      setStoreGroups(
        data.getStoreGroups.filter(
          (storeGroup: StoreGroup) =>
            storeGroup?.storeMarkets && storeGroup?.storeMarkets?.length > 0
        )
      )
    }
  })

  const [createBundleGroup, { loading }] = useMutation(CREATE_OR_UPDATE_BUNDLE_GROUP, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Bundle group successfully created",
          type: "success"
        })
      )
      navigate(`/discounts/bundle-groups/${data.createOrUpdateBundleGroup.id}`)
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          message: error.message,
          type: "error"
        })
      )
    }
  })

  const isSectionSelected = (section: BundleSectionValue) => section === selectedSection

  const sections: BundleSection[] = [
    {
      value: BundleSectionValue.GENERAL,
      label: "general settings",
      valid: !!getValues("name")
    },
    {
      value: BundleSectionValue.CHANNELS,
      label: `set channels (${addedStoreMarkets?.reduce(
        (acc, { value }) => acc + (value.length ?? 0),
        0
      )})`,
      valid: addedStoreMarkets.length > 0 || selectedSection === BundleSectionValue.GENERAL
    },
    {
      value: BundleSectionValue.BUNDLES,
      label: `add bundles (${bundles.length})`,
      valid:
        bundles.length > 0 ||
        selectedSection === BundleSectionValue.GENERAL ||
        selectedSection === BundleSectionValue.CHANNELS
    },
    { value: BundleSectionValue.RESULT, label: "result", valid: true }
  ]

  const getDiscountValue = (discountValue: InputMaybe<InputDiscountValue> | undefined) => {
    if (discountValue) {
      if (discountValue.type === InputDiscountValueType.Relative && discountValue.percentage) {
        return discountValue
      }
      if (
        discountValue.type === InputDiscountValueType.Fixed &&
        discountValue.fixedDiscounts &&
        discountValue.fixedDiscounts.length > 0
      ) {
        return discountValue
      }
    }
    return undefined
  }

  const getMutationInput = (data: InputCreateOrUpdateBundleGroup, isActive: boolean) => ({
    id: uuidv4(),
    bundleGroup: {
      name: data.name,
      isActive: isActive,
      bundles: bundles.map((bundle) => ({
        id: bundle.id,
        ...(getDiscountValue(bundle?.discountValue) && {discountValue: getDiscountValue(bundle.discountValue)}),
        itemRules: bundle.itemRules.map((itemRule) => ({
          tagConditions: itemRule.tagConditions,
          excludeDiscountedPrices: itemRule.excludeDiscountedPrices,
          excludeFullPrices: itemRule.excludeFullPrices,
          productParents: itemRule.productParents,
          quantity: itemRule.quantity,
          ...(getDiscountValue(itemRule?.discountValue) && {discountValue: getDiscountValue(itemRule.discountValue)}),
        }))
      })),
      storeMarkets: addedStoreMarkets,
      ...(data.validDateRange?.from &&
        data.validDateRange.to && {
          validDateRange: {
            from: new Date(data.validDateRange.from),
            to: new Date(data.validDateRange.to)
          }
        })
    }
  })

  const onSubmit = (data: InputCreateOrUpdateBundleGroup) =>
    createBundleGroup({ variables: getMutationInput(data, false) })

  const onSubmitActivate = (data: InputCreateOrUpdateBundleGroup) =>
    createBundleGroup({ variables: getMutationInput(data, true) })

  const valid = () => addedStoreMarkets.length > 0 && bundles.length > 0 && getValues("name")

  return (
    <>
      <PageHeader
        title={"Create bundle group"}
        group="Bundles"
        goBackLinkUrl="/discounts/bundle-groups"
        goBackLinkText="Back to bundle groups"
        hasTabs={true}
      />
      <SectionChoice
        sections={sections}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
      <Box padding="1rem 0">
        <form>
          {isSectionSelected(BundleSectionValue.GENERAL) && (
            <>
              <GeneralSettings register={register} errors={errors} control={control} />
              <ButtonWrapper>
                <PrimaryButton handleClick={() => setSelectedSection(BundleSectionValue.CHANNELS)}>
                  Next
                </PrimaryButton>
              </ButtonWrapper>
            </>
          )}
          {isSectionSelected(BundleSectionValue.CHANNELS) && (
            <>
              <SetChannels
                addedStoreMarkets={addedStoreMarkets}
                setAddedStoreMarkets={setAddedStoreMarkets}
                storeGroups={storeGroups}
                setSelectedSection={setSelectedSection}
              />
              <ButtonWrapper>
                <PrimaryButton handleClick={() => setSelectedSection(BundleSectionValue.BUNDLES)}>
                  Next
                </PrimaryButton>
              </ButtonWrapper>
            </>
          )}
          {isSectionSelected(BundleSectionValue.BUNDLES) && (
            <>
              <AddBundles
                addedStoreMarkets={addedStoreMarkets}
                storeGroups={storeGroups}
                setBundles={setBundles}
                discountValue={discountValue}
                setDiscountValue={setDiscountValue}
                itemRules={itemRules}
                setItemRules={setItemRules}
                editBundleId={editBundleId}
                setEditBundleId={setEditBundleId}
              />
              <AddedBundles
                bundles={bundles}
                setBundles={setBundles}
                setDiscountValue={setDiscountValue}
                setItemRules={setItemRules}
                setEditBundleId={setEditBundleId}
              />
              <ButtonWrapper>
                <PrimaryButton handleClick={() => setSelectedSection(BundleSectionValue.RESULT)}>
                  Next
                </PrimaryButton>
              </ButtonWrapper>
            </>
          )}
          {isSectionSelected(BundleSectionValue.RESULT) && (
            <>
              <Result
                general={getValues()}
                addedStoreMarkets={addedStoreMarkets}
                bundles={bundles}
                storeGroups={storeGroups}
              />
              <ButtonWrapper>
                <PrimaryButton handleClick={handleSubmit(onSubmit)} disabled={loading || !valid()}>
                  <SaveIcon />
                  Save
                </PrimaryButton>
                <PrimaryButton
                  handleClick={handleSubmit(onSubmitActivate)}
                  disabled={loading || !valid()}
                >
                  <SaveActivateIcon />
                  Save and activate
                </PrimaryButton>
              </ButtonWrapper>
            </>
          )}
        </form>
      </Box>
    </>
  )
}

export default CreateBundleGroup
