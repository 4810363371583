import gql from "graphql-tag"

const GET_API_RESOURCES = gql`
    query apiResources {
        apiResources {
            identifier
            scopes {
                name
                description
            }
        }
    }
`

export default GET_API_RESOURCES
