import React from "react"
import styled from "styled-components/macro"

import { ReactComponent as Logo } from "images/brink-logo.svg"

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 10rem;

  svg {
    max-width: 12rem;
  }
`

const Header = () => (
  <Container>
    <Logo />
  </Container>
)

export default Header
