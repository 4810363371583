import styled from "styled-components/macro"
import QuickAdd from "../../../../Ui/Table/QuickAdd"

type StyledProps = {
  $active: boolean
}

export const Container = styled.div<StyledProps>`
  border-left: 0.3rem solid ${(p) => p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.greyDark};
  padding: 0 0 1rem 1rem;
`

export const ConditionsAdd = styled(QuickAdd)`
  padding: 1rem 0 0;
  position: relative;
  margin-bottom: 1rem;
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

export const Hits = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1rem 1.2rem 0.6rem;
  padding: 2rem;
  background: ${(p) => p.theme.colors.white};
  position: absolute;
  top: calc(100%);
  left: 0;
  right: 0;
  z-index: 1;

  ul {
    margin-top: 0;
  }
`

export const Hit = styled.li`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1.2rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HitBlur = styled(Hit)`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`


export const Name = styled.span`
  display: block;
  ${(p) => p.theme.bold}
`

export const Id = styled.span`
  color: ${(p) => p.theme.colors.greyDark};
`

export const Stock = styled.div`
  color: ${(p) => p.theme.colors.greyDark};
`

export const HitCount = styled.div`
  text-align: right;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding-bottom: 1rem;
`

export const Flex = styled.div`
  flex: 1;
`

export const NotFound = styled.p`
  text-align: center;
`
