export type TagCondition = {
  key: string
  values: string[]
  include: boolean
  mode?: string
}

export type CampaignTagRule = {
  tagConditions: TagCondition[]
}

export enum Operator {
  AND = "and",
  OR = "or",
  NOT = "not",
  EMPTY = ""
}
