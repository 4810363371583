import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Controller } from "react-hook-form"
import { InputQliroOneConfig, QliroOneConfig } from "lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { SidebarDivider } from "components/Dashboard/Shared.styled"
import Input from "components/Ui/Form/Input"
import SingleSelect from "components/Ui/Form/SingleSelect"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import CheckboxSimple from "../../Ui/Form/CheckboxSimple"
import { useConfig } from "../../../configuration/useConfig"

const StyledSelect = styled(SingleSelect)`
  margin-bottom: 2rem;
`

const StyledCheckbox = styled(CheckboxSimple)`
  padding-top: 0;
  padding-bottom: 2rem;
`

interface StyledBoxProps {
  show: boolean
}

const StyledBox = styled.div<StyledBoxProps>`
  display: ${(p) => p.show ? "block" : "none"};
`

interface ExtendedInputQliroOneConfig extends InputQliroOneConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputQliroOneConfig>
  errors: FieldErrors
  setValue: UseFormSetValue<ExtendedInputQliroOneConfig>
  showId?: boolean
  defaultValues?: QliroOneConfig
}

interface ExtendedInputQliroOneConfig extends InputQliroOneConfig {
  id: string
}

enum SHIPPING_NAME {
  INGRID = "Ingrid",
  NSHIFT = "Nshift"
}

export const QliroOneConfigInputs = ({
  control,
  errors,
  setValue,
  showId = true,
  defaultValues
}: Props) => {
  const { awsRegion, customerName } = useConfig().config
  const merchantOrderManagementStatusPushUrl = `https://management.${awsRegion}.${customerName}.brinkcommerce.io/order/callbacks/qliroone/notifications`

  const [showIntegratedShipping, setShowIntegratedShipping] = useState(
    !!defaultValues?.integratedShipping
  )
  const [showDeliveryCheckoutId, setShowDeliveryCheckoutId] = useState(false)
  const [showOrderManagementStatusPushUrlOverride, setShowOrderManagementStatusPushUrlOverride] =
    useState(
      // if config has a status push url, and is not Brink OrderManagement
      !!defaultValues?.merchantOrderManagementStatusPushUrl &&
        defaultValues?.merchantOrderManagementStatusPushUrl !== merchantOrderManagementStatusPushUrl
    )

  const updateBaseUrl = (baseUrl: Common.Option) => {
    setValue("baseUrl", baseUrl.value)
  }

  const baseUrlOptions = [
    {
      value: "https://pago.qit.nu",
      label: "Test | https://pago.qit.nu"
    },
    {
      value: "https://payments.qit.nu",
      label: "Production | https://payments.qit.nu"
    }
  ]

  const isValidHttpUrl = (string: string) => {
    try {
      const newUrl = new URL(string)
      return newUrl.protocol === "https:"
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    if (!showIntegratedShipping) {
      setValue("integratedShipping", undefined)
    }
  }, [showIntegratedShipping])

  useEffect(() => {
    if (!showOrderManagementStatusPushUrlOverride) {
      setValue("merchantOrderManagementStatusPushUrl", merchantOrderManagementStatusPushUrl)
    }
  }, [showOrderManagementStatusPushUrlOverride])

  return (
    <>
      {showId && (
        <>
          <Controller
            name="id"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Identifier *"
                description="For internal use in Brink"
                placeholder="Aa - Zz, 0 - 9, -_."
                errors={errors}
              />
            )}
            control={control}
            defaultValue=""
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_.-]+$/,
                message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
              }
            }}
          />
          <SidebarDivider />
        </>
      )}

      <StyledCheckbox
        name={"showIntegratedShipping"}
        label={"Integrated shipping"}
        setValue={setShowIntegratedShipping}
        value={showIntegratedShipping}
      />
      {showIntegratedShipping && (
        <>
          <Controller
            name="integratedShipping.name"
            render={({ field }) => (
              <StyledSelect
                {...field}
                options={[
                  {
                    value: SHIPPING_NAME.INGRID,
                    label: SHIPPING_NAME.INGRID
                  },
                  {
                    value: SHIPPING_NAME.NSHIFT,
                    label: SHIPPING_NAME.NSHIFT
                  }
                ]}
                label="Shipping provider *"
                defaultValue={
                  defaultValues?.integratedShipping
                    ? {
                        value: defaultValues.integratedShipping.name,
                        label: defaultValues.integratedShipping.name
                      }
                    : null
                }
                setValue={(e) => {
                  setValue("integratedShipping.name", e?.value)
                  if (e?.value === SHIPPING_NAME.NSHIFT) {
                    setShowDeliveryCheckoutId(true)
                  } else {
                    setShowDeliveryCheckoutId(false)
                    setValue("integratedShipping.deliveryCheckoutId", undefined)
                  }
                }}
              />
            )}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          {showDeliveryCheckoutId && (
            <Controller
              name="integratedShipping.deliveryCheckoutId"
              render={({ field }) => (
                <Input {...field} type="text" errors={errors} label="Delivery checkout id *" />
              )}
              defaultValue={defaultValues?.integratedShipping?.deliveryCheckoutId ?? ""}
              control={control}
              rules={{
                required: "This is a required field"
              }}
            />
          )}
        </>
      )}
      <SidebarDivider />

      <Controller
        name="baseUrl"
        render={({ field }) => (
          <StyledSelect
            {...field}
            options={baseUrlOptions}
            setValue={updateBaseUrl}
            defaultValue={{
              label:
                baseUrlOptions.find((option) => {
                  // remove trailing slash for comparison
                  return option.value === defaultValues?.baseUrl?.replace(/\/$/, "")
                })?.label ?? "Select base URL",
              value: defaultValues?.baseUrl ?? ""
            }}
            label="Base URL *"
          />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
      />
      <Controller
        name="merchantApiKey"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Qliro One merchant API-key *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="merchantApiSecret"
        render={({ field }) => (
          <Input {...field} type="text" errors={errors} label="Qliro One merchant API-secret *" />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />

      <StyledCheckbox
        name={"showOrderManagementStatusPushUrlOverride"}
        label={"Override OrderManagement Status Push Url"}
        description={"Set by default to Brink OrderManagement"}
        setValue={setShowOrderManagementStatusPushUrlOverride}
        value={showOrderManagementStatusPushUrlOverride}
      />
      <StyledBox show={showOrderManagementStatusPushUrlOverride}>
        <Controller
          name="merchantOrderManagementStatusPushUrl"
          defaultValue={
            defaultValues?.merchantOrderManagementStatusPushUrl ??
            merchantOrderManagementStatusPushUrl
          }
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="OrderManagement status push URL *"
              description="Must be https:// format"
              errors={errors}
            />
          )}
          control={control}
          rules={{
            validate: {
              url: (value) => {
                if (!isValidHttpUrl(value)) return "Must be https:// format"
              }
            },
            required: "This is a required field"
          }}
        />
      </StyledBox>

      <Controller
        name="useLegacyIds"
        defaultValue={defaultValues?.useLegacyIds ?? false}
        render={({ field: {ref: _, ...field} }) => (
          <StyledCheckbox
            {...field}
            label={"Override QliroOne OrderItem ID generation"}
            description={"By default unique IDs are used per item, if enabled productVariantId is used instead"}
            setValue={field.onChange}
            value={field.value as boolean | undefined}
          />
        )}
        control={control}
      />
    </>
  )
}
