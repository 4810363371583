import gql from "graphql-tag"

const CREATE_OR_UPDATE_INVENTORY = gql`
    mutation createOrUpdateInventory(
        $id: ID!
        $name: String!
        $description: String!
        $address: InputInventoryAddress
    ) {
        createOrUpdateInventory(
            id: $id
            inventory: {
                name: $name
                description: $description
                address: $address
            }

        ) {
            id
            name
        }
    }
`

export default CREATE_OR_UPDATE_INVENTORY
