import gql from "graphql-tag"

const ALL_TAG_KEYS = gql`
  query tagKeys {
    tagKeys {
      keys
    }
  }
`

export default ALL_TAG_KEYS
