import * as graphql from "lib/types/generated/graphql-types"
import FailedCompensation from "./FailedCompensation"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"
import React, { useState } from "react"
import CompensationAction from "./Cart/CompensationAction"
import StatusPopup from "./Cart/StatusPopup"

type Props = {
  order: graphql.Order
  refetch: () => void
  loading: boolean
  failedCompensations: graphql.OrderCompensation[]
  orderHistory: graphql.OrderHistory
}

const CompensationOrder = ({ order, refetch, loading, failedCompensations, orderHistory }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [startedCompensation, setStartedCompensation] = useState("")
  const filteredCompensations = order?.Compensations?.filter(
    (compensation) => !compensation.completed && !compensation.started
  )
  const [giftCardAction, setGiftCardAction] = useState<graphql.InputActionGiftCard>({
    actionType: graphql.InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<graphql.InputActionBonus>({
    actionType: graphql.InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<graphql.InputActionPaymentCompensation>({
    actionType: graphql.InputActionType.Auto
  })

  return (
    <>
      {showStatusPopup && startedCompensation && (
        <StatusPopup
          setShowStatusPopup={setShowStatusPopup}
          compensationId={startedCompensation}
          refetchOrder={refetch}
        />
      )}
      {failedCompensations?.length > 0 ? (
        failedCompensations.map((compensation: graphql.OrderCompensation) => (
          <FailedCompensation
            key={compensation.id}
            failedCompensation={compensation}
            setShowStatusPopup={setShowStatusPopup}
            setStartedCompensation={setStartedCompensation}
            orderHistory={orderHistory.history}
          />
        ))
      ) : !showConfirmation ? (
        <Container>
          <div>
            <OrderSummary order={order} refetch={refetch} orderLoading={loading} />
          </div>
          <div>
            <Cart order={order} refetch={refetch} setShowConfirmation={setShowConfirmation} />
          </div>
        </Container>
      ) : (
        <Container>
          <div>
            <Cart
              order={order}
              refetch={refetch}
              setShowConfirmation={setShowConfirmation}
              summaryMode={true}
            />
          </div>
          <div>
            {filteredCompensations?.map((compensation) => (
              <CompensationAction
                key={compensation.id}
                compensation={compensation}
                actionState={{
                  giftCardAction,
                  setGiftCardAction,
                  bonusAction,
                  setBonusAction,
                  paymentAction,
                  setPaymentAction,
                }}
                orderId={order.id}
                setShowStatusPopup={setShowStatusPopup}
                setStartedCompensation={setStartedCompensation}
                setShowConfirmation={setShowConfirmation}
              />
            ))}
          </div>
        </Container>
      )}
    </>
  )
}

export default CompensationOrder
