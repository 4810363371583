import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"

import DELETE_STORE_MARKET from "graphql/mutations/store/DeleteStoreMarket"

import alertActions from "lib/store/services/Alert/AlertSlice"
import Popup from "components/Ui/Popup"
import ActionButton from "components/Ui/Table/ActionButton"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { StoreMarket } from "@lib/types/generated/graphql-types"

type Props = {
  name: string
  storeGroupId: string
  countryCode: string
  refetch: () => void
  icon?: boolean
  setEditStoreMarket?: Dispatch<SetStateAction<StoreMarket | undefined>>
  refetchStoreGroups?: () => void
}

const DeleteStoreMarket = ({
  name,
  storeGroupId,
  countryCode,
  refetch,
  icon = false,
  setEditStoreMarket
}: Props) => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()

  const [deleteStoreMarket, { loading }] = useMutation(DELETE_STORE_MARKET, {
    onCompleted: (data) => {
      if (data.deleteStoreMarket.status === "ok") {
        refetch()
        setOpen(false)
        setEditStoreMarket && setEditStoreMarket(undefined)
        dispatch(
          alertActions.actions.createAlert({
            message: `Store market "${countryCode}" successfully deleted`,
            type: "success"
          })
        )
      } else {
        alertActions.actions.createAlert({
          type: "error"
        })
      }
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Store market could not be deleted",
          type: "error"
        })
      )
    }
  })

  const onDelete = () => {
    deleteStoreMarket({
      variables: {
        storeGroupId,
        countryCode
      }
    }).then(() => setEditStoreMarket && setEditStoreMarket(undefined))
  }

  return (
    <>
      {icon ? (
        <ActionButton
          mode="delete"
          handleOnClick={() => {
            setOpen(true)
          }}
        />
      ) : (
        <PrimaryButton
          mode="delete"
          handleClick={() => {
            setOpen(true)
          }}
        >
          Delete
        </PrimaryButton>
      )}
      {open && (
        <Popup
          title="Delete store market"
          subtitle={`Are you sure you want to delete the following store market?`}
          item={{ itemId: name, itemName: countryCode }}
          handleCloseClick={() => setOpen(false)}
          handleOkClick={onDelete}
          type="delete"
          loading={loading}
        />
      )}
    </>
  )
}

export default DeleteStoreMarket
