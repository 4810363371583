import gql from "graphql-tag"

const GET_INVENTORY = gql`
  query getInventory($id: ID!) {
      getInventory(id: $id) {
      id
      name
      description
      created
      updated
      address {
          postalCode
          city
          streetAddress
          country
          region
      }
    }
  }
`

export default GET_INVENTORY
