import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"
import { transformLogicData } from "helpers/transformLogicData"
import { Discount } from "@lib/types"
import { DiscountExternalRule, LogicHolder } from "@lib/types/generated/graphql-types"
import { getLogicData } from "helpers/getLogicData"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { RULE_TYPE } from "lib/types/common"
import { getJson } from "helpers/getJson"

import GET_DISCOUNT_EXTERNAL_RULE from "graphql/queries/discount/GetDiscountExternalRule"
import CREATE_OR_UPDATE_DISCOUNT_EXTERNAL_RULE from "graphql/mutations/discount/CreateOrUpdateDiscountExternalRule"

import { Activate, Deactivate, Divider } from "../Shared.styled"

import { BrinkLoader } from "components/Ui/BrinkLoader"
import Box from "components/Ui/Box"
import PageHeader from "components/Ui/Page/PageHeader"
import LogicRules from "components/Discount/LogicRules"
import DuplicateExternalRule from "./DuplicateExternalRule"
import DiscountRuleGeneralUpdateInput from "components/Discount/DiscountRule/DiscountRuleGeneralUpdateInput"
import { ActionDropdownButton } from "components/Ui/Buttons/ActionDropdownButton"
import Status from "components/Ui/Status"

import { ReactComponent as CodeIcon } from "images/icons/brackets-curly.svg"
import { ReactComponent as OnIcon } from "images/icons/circle-play.svg"
import { ReactComponent as OffIcon } from "images/icons/circle-minus.svg"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"

export const UpdateExternalRule = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [duplicateExternalRule, setDuplicateExternalRule] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<Discount.DiscountRuleInputs>()

  const { data, loading, refetch } = useQuery(GET_DISCOUNT_EXTERNAL_RULE, {
    variables: { id }
  })

  const discountExternalRule: DiscountExternalRule = data?.getDiscountExternalRule

  const [logicState, setLogicState] = useState<Discount.LogicState>({
    [uuidv4()]: {
      conditions: [],
      outcomes: []
    }
  })

  useEffect(() => {
    setValue("name", discountExternalRule?.name)
    setValue("isActive", discountExternalRule?.isActive)

    if (
      discountExternalRule?.logicRules !== undefined &&
      discountExternalRule?.logicRules.length > 0
    ) {
      setLogicState({})
      discountExternalRule?.logicRules.forEach((logicRule: LogicHolder) => {
        setLogicState((prev: Discount.LogicState) => ({
          ...prev,
          ...(getLogicData(logicRule, discountExternalRule) as Discount.LogicState)
        }))
      })
    }
  }, [discountExternalRule])

  const isStackable = watch("isStackable")

  const [addDiscountExternalRule, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_DISCOUNT_EXTERNAL_RULE,
    {
      onCompleted: () => {
        refetch()
        dispatch(
          alertActions.actions.createAlert({
            message: "External rule successfully updated",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<Discount.DiscountRuleInputs> = (data) => {
    const { name, isActive, isStackable, applyLast, isExclusive, sortOrder, from, to } = data

    const transformedData = transformLogicData(logicState)
    addDiscountExternalRule({
      variables: {
        id,
        name,
        isActive: isActive ?? false,
        isStackable: isStackable ?? false,
        applyLast: isStackable ? applyLast ?? false : false,
        isExclusive: isStackable ? isExclusive ?? false : false,
        sortOrder,
        ...transformedData,
        ...(from &&
          to && {
            validDateRange: {
              from: new Date(from),
              to: new Date(to)
            }
          })
      }
    })
  }

  const updateActive = (status: boolean) => {
    addDiscountExternalRule({
      variables: {
        ...discountExternalRule,
        isActive: status
      }
    })
  }

  useEffect(() => {
    if (isStackable === undefined) {
      setValue("isStackable", discountExternalRule?.isStackable)
      return
    }
    if (!isStackable) {
      setValue("isExclusive", false)
      setValue("applyLast", false)
    }
  }, [isStackable, discountExternalRule, setValue])

  if (loading) return <BrinkLoader />
  if (!discountExternalRule) return null

  return (
    <>
      {duplicateExternalRule && (
        <DuplicateExternalRule
          externalRuleData={discountExternalRule}
          logicState={logicState}
          setDuplicateExternalRule={setDuplicateExternalRule}
        />
      )}
      <Box padding="0" margin="0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageHeader
            title={
              <>
                {discountExternalRule.name}
                {discountExternalRule.isActive ? (
                  <Status status="Active" />
                ) : (
                  <Status status="Inactive" />
                )}
              </>
            }
            group="External rule"
            id={id}
            hasTabs
            goBackLinkUrl="/discounts/discount-rules"
            goBackLinkText="Back to discount rules"
          >
            <ActionDropdownButton
              title="Save"
              submitEvent={handleSubmit(onSubmit)}
              submitLoading={createLoading}
            >
              <li
                onClick={() => {
                  setDuplicateExternalRule(true)
                  dispatch(showEditSidebar())
                }}
              >
                <CopyIcon /> Duplicate
              </li>
              <li
                onClick={() => {
                  getJson(discountExternalRule)
                }}
              >
                <CodeIcon /> Get rule JSON
              </li>
              {discountExternalRule.isActive ? (
                <>
                  <Divider />
                  <Deactivate
                    onClick={() => {
                      updateActive(false)
                    }}
                  >
                    <OffIcon /> Deactivate rule
                  </Deactivate>
                </>
              ) : (
                <>
                  <Divider />
                  <Activate
                    onClick={() => {
                      updateActive(true)
                    }}
                  >
                    <OnIcon /> Activate rule
                  </Activate>
                </>
              )}
            </ActionDropdownButton>
          </PageHeader>
          <Tabs>
            <TabList>
              <Tab>General</Tab>
              <Tab>Logic rules</Tab>
            </TabList>
            <TabPanel>
              <DiscountRuleGeneralUpdateInput
                discountRule={discountExternalRule}
                control={control}
                isStackable={isStackable}
                errors={errors}
                type={RULE_TYPE.EXTERNAL_RULE}
              />
            </TabPanel>
            <TabPanel>
              <LogicRules state={logicState} setState={setLogicState} />
            </TabPanel>
          </Tabs>
        </form>
      </Box>
    </>
  )
}
