import React from "react"
import styled from "styled-components/macro"

const Container = styled.div`
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
  }

  > div {
    flex: 1;

    &:first-child {
      width: 100%;

      ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        max-width: 60rem;
        padding-right: 6rem;
      }
    }
  }
`

type Props = {
  children: JSX.Element | JSX.Element[]
}

const PageSection = ({ children, ...props }: Props) => <Container {...props}>{children}</Container>

export default PageSection
