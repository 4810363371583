import styled from "styled-components/macro"

export const Container = styled.div`
  width: 100%;
  border:  0.1rem solid ${p => p.theme.colors.borderLight};
  background: ${p => p.theme.colors.white};
  padding: 2rem;
  border-radius: 0.6rem;
  margin-top: 2rem;
`

export const Title = styled.div`
width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Provider = styled.div`
background: ${p => p.theme.colors.errorBackground};
  width: fit-content;
  color: ${p => p.theme.colors.errorText};
  padding: 0.3rem 1rem;
  border-radius: 0.6rem;
  margin-left: 1rem;
`

export const ProviderWrapper = styled.div`
display: flex;
  align-items: center;
`
