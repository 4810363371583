import styled from "styled-components/macro"

export const ShowMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  button {
    padding: 1rem 6rem;
  }
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

export const PopupWrapper = styled.div`
  color: black;
  svg {
    fill: ${(p) => p.theme.colors.black};
  }
`

export const PopupLabel = styled(Label)`
  color: ${(p) => p.theme.colors.black};
  padding-bottom: 0;
`

export const PopupInfo = styled.div`
  color: ${(p) => p.theme.colors.black};
`

export const PopupHint = styled.div`
  margin-top: 1.5rem;
  background: ${(p) => p.theme.colors.greyLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  color: ${(p) => p.theme.colors.black};
  padding: 1.5rem;
  display: flex;
  border-radius: 0.8rem;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  svg {
    width: 1.5rem;
    margin-right: 0.8rem;
    fill: ${(p) => p.theme.colors.black};
  }
`

export const Notice = styled.div`
  padding: 1.3rem;
  margin: 2rem 0;
  display: flex;
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  line-height: 2.2rem;

  border-left: 0.3rem solid ${p => p.theme.colors.turquoiseDark};
  background: ${(p) => p.theme.colors.white};

  p {
    word-break: break-word;
    margin: 0;
  }

`
