import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import styled from "styled-components/macro"
import { useNavigate, useParams } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"

import GET_ORDER from "graphql/queries/order/GetOrder"
import RESEND_ORDER_EVENT from "graphql/mutations/order/ResendOrderEvent"

import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import PageHeader from "components/Ui/Page/PageHeader"
import Box from "components/Ui/Box"
import OrderPayment from "./OrderInfo/Payment/OrderPayment"
import OrderOverview from "./OrderInfo/OrderOverview"
import OrderDiscount from "./OrderInfo/Discount/OrderDiscount"
import Shipping from "./OrderInfo/Shipping/OrderShipping"
import { OrderLines } from "./OrderInfo/OrderLines"
import CopyButton from "components/Ui/CopyButton"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import Giftcard from "./OrderInfo/Giftcard/Default"
import Popup from "components/Ui/Popup"
import Promotion from "./OrderInfo/Promotion/Default"
import { ActionDropdownButton } from "components/Ui/Buttons/ActionDropdownButton"

import { ReactComponent as ArrowIcon } from "images/icons/arrows-repeat.svg"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import { ReactComponent as SettingsIcon } from "images/icons/gear.svg"
import Bonus from "./OrderInfo/Bonus/Default"
import { NoticeBanner } from "../../../components/Ui/Messages/NoticeBanner"
import {
  OrderCancellation,
  OrderCompensation,
  OrderDelivery,
  OrderGiftCardProduct,
  OrderGiftCardProductRetain24, OrderModificationOrderLines,
  OrderRefund
} from "@lib/types/generated/graphql-types"
import Updates from "./OrderInfo/Updates/Updates"
import { getJson } from "helpers/getJson"
import { ReactComponent as CodeIcon } from "images/icons/brackets-curly.svg"
import Vouchers from "./OrderInfo/Vouchers/Vouchers"

const StyledPageHeader = styled(PageHeader)`
  p {
    margin-top: 0;
    color: ${(p) => p.theme.colors.greyDark};
  }
`

const Id = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 1.3rem;
    text-decoration: underline;
    color: ${(p) => p.theme.colors.greyDark};
    margin: 0;
  }
`

const Columns = styled.div`
  ul {
    grid-template-columns: repeat(3, 1fr);
  }
`

const NoticeWrapper = styled.div`
  display: flex;

  span {
    display: flex;
    position: absolute;
    right: 2rem;

    svg {
      fill: ${(p) => p.theme.colors.black};
      margin: 0 0.5rem 0 0;
      display: flex;
      align-items: center;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const ViewOrder = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: { id },
    fetchPolicy: "network-only"
  })
  const [showPopup, setShowPopup] = useState(false)
  const [resendOrderEventError, setResendOrderEventError] = useState("")

  const dispatch = useDispatch()

  const [resendOrderEvent, { loading: resendLoading }] = useMutation(RESEND_ORDER_EVENT, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Order event successfully sent",
          type: "success"
        })
      )
      setShowPopup(false)
    },
    onError: () => {
      setResendOrderEventError(
        "An error occured when trying to resend the order event for the provided order ID."
      )
    }
  })

  if (loading) return <BrinkLoader />

  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading Order. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const { details: orderDetails, history: orderHistory } = data.Order
  const failedRefunds =
    orderDetails?.Refunds?.filter((refund: OrderRefund) => !!refund.failed && !refund.completed)
      .length > 0

  const failedDeliveries =
    orderDetails?.Deliveries?.filter(
      (delivery: OrderDelivery) => !!delivery.failed && !delivery.completed
    ).length > 0

  const failedCompensations =
    orderDetails?.Compensations?.filter(
      (compensation: OrderCompensation) => !!compensation.failed && !compensation.completed
    ).length > 0

  const failedModifications =
    orderDetails?.Modifications?.filter(
      (modification: OrderModificationOrderLines) => !modification.completed && !!modification.failed
    ).length > 0


  const notCompletedDeliveries =
    orderDetails?.Deliveries?.filter(
      (delivery: OrderDelivery) => !delivery.completed && !delivery.failed
    ).length > 0

  const notCompletedRefunds =
    orderDetails?.Refunds?.filter((refund: OrderRefund) => !refund.completed && !refund.failed)
      .length > 0

  const notCompletedCancellations =
    orderDetails?.Cancellations?.filter(
      (cancellation: OrderCancellation) => !cancellation.completed && !cancellation.failed
    ).length > 0

  const notCompletedCompensations =
    orderDetails?.Compensations?.filter(
      (compensation: OrderCompensation) => !compensation.completed && !compensation.failed
    ).length > 0

  const notCompletedModifications =
    orderDetails?.Modifications?.filter(
      (modification: OrderModificationOrderLines) => !modification.completed && !modification.failed
    ).length > 0

  const failedCancellations = !!orderDetails?.Cancellations[0]?.failed

  const completedCancellationsExists =
    orderDetails?.Cancellations.filter((cancellation: OrderCancellation) => cancellation.completed)
      .length > 0

  const showPromotions = () => {
    const {
      providerId: promotionProviderId,
      providerName: promotionProviderName,
      status: promotionStatus
    } = orderDetails.Promotion

    return promotionProviderId !== "" || promotionProviderName !== "" || promotionStatus !== ""
  }

  const showVouchers = () => {
    const {
      providerId: voucherProviderId,
      providerName: voucherProviderName,
      status: voucherStatus
    } = orderDetails.Voucher

    return voucherProviderName !== "" || voucherProviderId !== "" || voucherStatus !== ""
  }

  const updatesLength = () =>
    (orderDetails?.Deliveries?.length ?? 0) +
    (orderDetails?.Refunds?.length ?? 0) +
    (orderDetails?.Cancellations?.length ?? 0) +
    (orderDetails?.Compensations?.length ?? 0) +
    (orderDetails.Releases?.length ?? 0) +
    (orderDetails.Modifications?.length ?? 0)

  const showGiftCard = () => {
    const { providerId, providerName, status } = orderDetails.GiftCard
    return providerId !== "" || providerName !== "" || status !== ""
  }

  function isOrderGiftCardProductRetain24(
    object: OrderGiftCardProduct
  ): object is OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  enum OPERATION_TYPE {
    DELIVERY = "Delivery",
    REFUND = "Refund",
    RELEASE = "Release",
    COMPENSATION = "Compensation",
    CANCELLATION = "Cancellation",
    MODIFICATION = "Modification"
  }

  const getFailedNotice = (type: OPERATION_TYPE) => {
    return (
      <NoticeBanner type="important">
        <NoticeWrapper>
          Failed {type}{" "}
          <span onClick={() => navigate(`/orders/manage-order/${id}`)}>
            <SettingsIcon />
            Manage {type}s
          </span>
        </NoticeWrapper>
      </NoticeBanner>
    )
  }

  const getNotCompletedNotice = (type: OPERATION_TYPE) => {
    return(<NoticeBanner type="reminder">
      <NoticeWrapper>
        Not completed {type}
        <span onClick={() => navigate(`/orders/manage-order/${id}`)}>
          <SettingsIcon />
          Manage order
        </span>
      </NoticeWrapper>
    </NoticeBanner>)
  }


  return (
    <Box padding="0" margin="0">
      <StyledPageHeader
        title={"Order #" + orderDetails.reference}
        goBackLinkUrl="/orders"
        goBackLinkText="Back to orders"
        hasTabs
        description={
          id && (
            <Id>
              <span>{id}</span>
              <CopyButton string={id} />
            </Id>
          )
        }
      >
        <ActionDropdownButton title="Actions">
          <li onClick={() => navigate(`/orders/create-order-from-existing-order/${id}`)}>
            <CopyIcon /> Duplicate order
          </li>
          <li onClick={() => setShowPopup(true)}>
            <ArrowIcon />
            Resend order event
          </li>
          <li
            onClick={() => {
              getJson(orderDetails)
            }}
          >
            <CodeIcon /> Get order JSON
          </li>
          {!completedCancellationsExists ? (
            <li onClick={() => navigate(`/orders/manage-order/${id}`)}>
              <SettingsIcon /> Manage order
            </li>
          ) : (
            <></>
          )}
        </ActionDropdownButton>
      </StyledPageHeader>
      <>
        {showPopup && (
          <Popup
            title={"Are you sure?"}
            subtitle={"Do you want to resend order event for the following order:"}
            item={{ itemId: "", itemName: `#${orderDetails.reference}` }}
            type="default"
            buttonText="Yes"
            handleOkClick={() => {
              setResendOrderEventError("")
              resendOrderEvent({ variables: { orderId: id } })
            }}
            handleCloseClick={() => {
              setResendOrderEventError("")
              setShowPopup(false)
            }}
            loading={resendLoading}
            errorMessage={resendOrderEventError}
          />
        )}
      </>
      {notCompletedRefunds ? getNotCompletedNotice(OPERATION_TYPE.REFUND) : <></>}
      {notCompletedDeliveries ? getNotCompletedNotice(OPERATION_TYPE.DELIVERY) : <></>}
      {notCompletedCompensations ? getNotCompletedNotice(OPERATION_TYPE.COMPENSATION) : <></>}
      {notCompletedCancellations ? getNotCompletedNotice(OPERATION_TYPE.CANCELLATION) : <></>}
      {notCompletedModifications ? getNotCompletedNotice(OPERATION_TYPE.MODIFICATION) : <></>}

      {failedRefunds ? getFailedNotice(OPERATION_TYPE.REFUND) : <></>}
      {failedDeliveries ? getFailedNotice(OPERATION_TYPE.DELIVERY) : <></>}
      {failedCompensations ? getFailedNotice(OPERATION_TYPE.COMPENSATION) : <></>}
      {failedCancellations ? getFailedNotice(OPERATION_TYPE.CANCELLATION) : <></>}
      {failedModifications ? getFailedNotice(OPERATION_TYPE.MODIFICATION) : <></>}
      <Columns>
        <Tabs>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>
              Order lines{" "}
              <b>
                (
                {orderDetails?.orderLines?.length +
                  orderDetails?.gifts?.length +
                  (isOrderGiftCardProductRetain24(orderDetails?.GiftCardProduct)
                    ? orderDetails?.GiftCardProduct?.giftCardProducts?.length
                    : 0)}
                )
              </b>
            </Tab>
            <Tab>Shipping</Tab>
            <Tab>Payment</Tab>
            <Tab>Discount</Tab>
            {showGiftCard() && <Tab>Gift card</Tab>}
            {showPromotions() && <Tab>Promotion</Tab>}
            {showVouchers() && <Tab>Vouchers</Tab>}
            {orderDetails.Bonus && <Tab>Bonus</Tab>}
            {updatesLength() > 0 && (
              <Tab>
                Updates <b>({updatesLength()})</b>
              </Tab>
            )}
          </TabList>
          <TabPanel>
            <OrderOverview order={orderDetails} orderHistory={orderHistory} />
          </TabPanel>
          <TabPanel>
            <OrderLines order={orderDetails} orderHistory={orderHistory} />
          </TabPanel>
          <TabPanel>
            <Shipping
              orderShipping={orderDetails.Shipping}
              shippingAddress={orderDetails.shippingAddress}
              shippingFees={orderDetails.shippingFees}
            />
          </TabPanel>
          <TabPanel>
            <OrderPayment
              orderPayment={orderDetails.Payment}
              billingAddress={orderDetails.billingAddress}
            />
          </TabPanel>
          <TabPanel>
            <OrderDiscount data={orderDetails} />
          </TabPanel>
          {showGiftCard() && (
            <TabPanel>
              <Giftcard orderGiftcard={orderDetails.GiftCard} />
            </TabPanel>
          )}
          {showPromotions() && (
            <TabPanel>
              <Promotion promotionDetails={orderDetails.Promotion} />
            </TabPanel>
          )}
          {showVouchers() && (
            <TabPanel>
              <Vouchers voucherDetails={orderDetails.Voucher} />
            </TabPanel>
          )}
          {orderDetails.Bonus && (
            <TabPanel>
              <Bonus orderBonus={orderDetails.Bonus} />
            </TabPanel>
          )}
          {updatesLength() > 0 && (
            <TabPanel>
              <Updates order={orderDetails} />
            </TabPanel>
          )}
        </Tabs>
      </Columns>
    </Box>
  )
}
