import gql from "graphql-tag"

const START_DELIVERY = gql`
  mutation startDelivery($deliveryId: ID!, $input: InputOrderStartDelivery!) {
    Order {
      startDelivery(deliveryId: $deliveryId, input: $input) {
        status
      }
    }
  }
`

export default START_DELIVERY
