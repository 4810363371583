import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"

import GET_SVEA_CHECKOUT_CONFIG from "graphql/queries/config/GetSveaCheckoutConfig"

import { Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as Logo } from "images/providers/icons/svea.svg"

export const getLoadingRow = () => (
  <>
    <Logo />

    <div>
      <div>
        <Name>Merchant account</Name>
        <Id>merchant-id</Id>
      </div>
    </div>
    <div>
      <p>PRODUCTION</p>
    </div>
    <div>
      <p>2000-00-00</p>
    </div>
    <div>
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setSveaConfigId?: Dispatch<SetStateAction<string>>
}

export const SveaConfigTableRow = ({ id, setSveaConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_SVEA_CHECKOUT_CONFIG, {
    variables: { id: id }
  })

  const sveaCheckoutConfig = data?.getSveaCheckoutConfig
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>
  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setSveaConfigId) setSveaConfigId(sveaCheckoutConfig.id)
        dispatch(toggleEditSidebar())
      }}
    >
      <Logo />

      <div>
        <div>
          <Name>{sveaCheckoutConfig?.merchantId}</Name>
          <Id>{sveaCheckoutConfig?.id}</Id>
        </div>
      </div>
      <div>
        <p>{sveaCheckoutConfig?.checkoutBaseUrl?.includes("stage") ? "TEST" : "PRODUCTION"}</p>
      </div>
      <div>
        <p>{dayjs(sveaCheckoutConfig?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div>
        <p>{dayjs(sveaCheckoutConfig?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
