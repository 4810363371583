import gql from "graphql-tag"

const GET_SHIPPING_TAX_GROUP = gql`
    query getShippingTaxGroup {
        getShippingTaxGroup {
            taxGroupId
            created
            updated
        }
    }
`

export default GET_SHIPPING_TAX_GROUP
