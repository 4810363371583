import React, { useState } from "react"
import {
  InputDiscountValue,
  InputDiscountValueType,
  InputMaybe
} from "lib/types/generated/graphql-types"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { Header } from "./FixedDiscountOnItemRule.styled"
import { Currency, CurrencyWrapper } from "../AddedBundles.styled"
import Money from "../../../../../Money"
import DineroFactory from "dinero.js"

type Props = {
  discountValue: InputMaybe<InputDiscountValue> | undefined
}

const FixedDiscountOnItemRule = ({ discountValue }: Props) => {
  const [expanded, setExpanded] = useState(false)

  if (!discountValue || discountValue?.type !== InputDiscountValueType.Fixed) return null
  return (
    <div>
      <Header onClick={() => setExpanded(!expanded)}>
        Fixed discounts ({discountValue.fixedDiscounts?.length})<DownIcon />
      </Header>
      {expanded && (
        <CurrencyWrapper>
          {discountValue.fixedDiscounts?.map((fixedDiscount) => (
            <Currency key={fixedDiscount.currencyCode}>
              <Money
                currencyUnit={fixedDiscount.currencyCode as DineroFactory.Currency}
                amount={fixedDiscount.units}
              />
            </Currency>
          ))}
        </CurrencyWrapper>
      )}
    </div>
  )
}

export default FixedDiscountOnItemRule
