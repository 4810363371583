import gql from "graphql-tag"

const UPDATE_COMPENSATION = gql`
  mutation updateCompensation($compensationId: ID!, $input: InputOrderCompensation!) {
    Order {
      updateCompensation(compensationId: $compensationId, input: $input) {
        id
      }
    }
  }
`

export default UPDATE_COMPENSATION
