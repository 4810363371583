import React, { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { ProductVariantSearchHits } from "@lib/types/generated/graphql-types"
import { Query } from "@lib/types/common"
import { Common } from "@lib/types"

import SEARCH_PRODUCT_VARIANTS from "graphql/queries/product/SearchProductVariants"

import { Header } from "../Shared.styled"

import VariantSearch from "./VariantSearch"
import { SearchResult } from "./SearchResult"
import { GoBackButton } from "./AddVariant.styled"

import { ReactComponent as ArrowLeft } from "images/icons/arrows-repeat.svg"

const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

const DEFAULT_PRODUCT_FILTER = {
  isActive: true,
  isArchived: false,
  numberOfHits: "25",
  searchQuery: ""
}

type Props = {
  resetCart: () => void
  showGoBackButton: boolean
}

export const AddVariant = ({ resetCart, showGoBackButton }: Props) => {
  const [productVariants, setProductVariants] = useState<ProductVariantSearchHits>()
  const [esbQuery, setEsbQuery] = useState<Query>()
  const [productFilter, setProductFilter] = useState<Common.ProductFilter>(DEFAULT_PRODUCT_FILTER)
  const setSearchQuery = (value: string) =>
    setProductFilter({ ...productFilter, ...{ searchQuery: value } })
  const query = esbQuery?.toJSON() as Query

  const [searchProductVariants, { loading: variantLoading, error: variantError }] = useLazyQuery(
    SEARCH_PRODUCT_VARIANTS,
    {
      onCompleted: (data) => {
        setProductVariants(data.searchProductVariants)
      }
    }
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchProductVariants({
          variables: {
            from: 0,
            size: productFilter?.numberOfHits,
            sort: [{ field: "updated", order: "DESC" }],
            query: JSON.stringify(query?.query),
            trackTotalHits: true
          }
        }).then((response) => {
          setProductVariants(response?.data?.searchProductVariants)
        })
      },
      productFilter?.searchQuery === "" ? 0 : 10
    )
    return () => clearTimeout(delayDebounceFn)
  }, [productFilter?.searchQuery, esbQuery, productFilter.numberOfHits])

  return (
    <>
      <Header>
        <h2>Add items</h2>
        {showGoBackButton && (
          <GoBackButton onClick={resetCart}>
            <ArrowLeft /> Change store group
          </GoBackButton>
        )}
      </Header>
      <VariantSearch
        setSearchQuery={setSearchQuery}
        loading={variantLoading}
        searchQuery={productFilter?.searchQuery ?? ""}
        setEsbQuery={setEsbQuery}
        productFilter={productFilter}
        setProductFilter={setProductFilter}
        numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
        defaultProductFilter={DEFAULT_PRODUCT_FILTER}
      />
      <SearchResult
        productData={productVariants}
        loading={variantLoading}
        searchQuery={productFilter?.searchQuery}
        error={variantError}
      />
    </>
  )
}
