import React, { useState } from "react"
import styled from "styled-components/macro"
import { useDispatch } from "react-redux"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useQuery } from "@apollo/client"

import GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES from "graphql/queries/settings/externalEvents/GetExternalEventsDestinationEventBuses"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import EventBusesTable from "components/ExternalEvents/EventBusesTable"
import CreateEventBus from "./CreateEventBus"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

const EventBuses = () => {
  const dispatch = useDispatch()
  const { data, loading, error, refetch } = useQuery(GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES)
  const [createEventBus, setCreateEventBus] = useState<boolean>(false)
  const eventBuses = data?.getExternalEventsDestinationEventBuses?.eventBuses

  return (
    <>
      <Wrapper>
        {createEventBus && <CreateEventBus setCreateEventBus={setCreateEventBus} refetch={refetch} />}
        <Container>
          <Actions>
            <h2>Event buses</h2>
            <PrimaryButton
              handleClick={() => {
                dispatch(showEditSidebar())
                setCreateEventBus(true)
              }}
            >
              <PlusIcon />
              Add new Event bus
            </PrimaryButton>
          </Actions>
          <EventBusesTable eventBuses={eventBuses} loading={loading} error={error} refetch={refetch} />
        </Container>
      </Wrapper>
    </>
  )
}

export default EventBuses
