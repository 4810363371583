import gql from "graphql-tag"

const CREATE_OR_UPDATE_DISCOUNT_CODE = gql`
    mutation createOrUpdateDiscountCode(
        $code: String!
        $discountCodeRuleId: String!
        $isUnlimited: Boolean!
        $usageLimit: Int
        $validDateRange: InputValidDateRange
    ) {
        createOrUpdateDiscountCode(
            code: $code
            discountCode: {
                discountCodeRuleId: $discountCodeRuleId
                isUnlimited: $isUnlimited
                usageLimit: $usageLimit
                validDateRange: $validDateRange
            }
        ) {
            code
            isUnlimited
            discountCodeRuleId
            usageLimit
        }
    }
`

export default CREATE_OR_UPDATE_DISCOUNT_CODE
