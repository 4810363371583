import React, { MutableRefObject } from "react"
import { Outcomes } from "@lib/types/discount"

type Props = {
  addOutcome: (data: Outcomes | undefined) => void
  submitRef: MutableRefObject<HTMLButtonElement>
}

export const FreeShipping = ({ addOutcome, submitRef }: Props) => (
  <button onClick={() => addOutcome(undefined)} ref={submitRef} type="button" style={{ display: "none" }} />
)
