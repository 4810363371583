import gql from "graphql-tag"

const GET_CANCELLATION = gql`
  query getCancellation($cancellationId: ID!) {
    Order {
      cancellation(cancellationId: $cancellationId) {
        id
        started
        completed
        failed
        created
        updated
        restarts
        restarted
        giftCardProvider {
          providerName
          providerId
          status {
            current
          }
        }
        paymentProvider {
          providerName
          providerId
          status {
            current
          }
        }
        bonusProvider {
          providerName
          providerId
          status {
            current
          }
        }
        voucherProvider {
          providerId
          providerName
        }
        giftCards {
          giftCardId
          status
        }
        bonus {
          reservationId
        }
        reason {
          cause
          code
        }
      }
    }
  }
`

export default GET_CANCELLATION
