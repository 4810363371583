import React, { Dispatch, SetStateAction, useState } from "react"
import { InputSveaCheckoutConfig } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"

import DELETE_SVEA_CHECKOUT_CONFIG from "graphql/mutations/config/DeleteSveaCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"

import { ReactComponent as Logo } from "images/providers/svea-logo.svg"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import ConnectedStoreMarket from "../../../../components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import CREATE_OR_UPDATE_SVEA_CHECKOUT_CONFIG from "../../../../graphql/mutations/config/CreateOrUpdateSveaCheckoutConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import VALIDATE_SVEA from "../../../../graphql/queries/config/ValidateSveaConfig"
import { SubmitHandler, useForm } from "react-hook-form"
import Popup from "../../../../components/Ui/Popup"
import { SveaConfigInputs } from "../../../../components/Config/Svea/SveaConfigInputs"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"
import GET_SVEA_CHECKOUT_CONFIG from "../../../../graphql/queries/config/GetSveaCheckoutConfig"

interface ExtendedInputSveaCheckoutConfig extends InputSveaCheckoutConfig {
  id: string
}

type Props = {
  refetch: () => void
  sveaConfigId: string
  setSveaConfigId: Dispatch<SetStateAction<string>>
}

const EditSveaConfig = ({ refetch, sveaConfigId, setSveaConfigId }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const closeSidebar = () => {
    setSveaConfigId("")
    dispatch(hideEditSidebar())
  }

  const { data } = useQuery(GET_SVEA_CHECKOUT_CONFIG, { variables: { id: sveaConfigId } })

  const sveaConfig = data?.getSveaCheckoutConfig

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputSveaCheckoutConfig>({ mode: "onBlur" })

  const [updateSveaConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_SVEA_CHECKOUT_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Svea Checkout config successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateSvea, { loading }] = useLazyQuery(VALIDATE_SVEA)

  const onSubmit: SubmitHandler<ExtendedInputSveaCheckoutConfig> = (data) => {
    validateSvea({
      variables: {
        baseUrl: data.checkoutBaseUrl,
        merchantId: data.merchantId,
        checkoutSecret: data.apiKey
      }
    }).then((response) => {
      if (response.data.Configuration.validate.sveaCheckout.valid) {
        updateSveaConfig({ variables: { ...data, id: sveaConfig.id } })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputSveaCheckoutConfig> = (data) => {
    updateSveaConfig({ variables: { ...data, id: sveaConfig.id } })
    setValidationError(false)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader
          icon={<Logo />}
          title={sveaConfig?.merchantId}
          id={sveaConfig?.id}
          cancelEvent={() => closeSidebar()}
        >
          <DeleteButton
            id={sveaConfig?.id}
            name={sveaConfig?.merchantId}
            entity={"Svea Checkout configuration"}
            redirectUrl="/settings/providers/svea"
            mutation={DELETE_SVEA_CHECKOUT_CONFIG}
            refetch={refetch}
            postDeleteFunction={() => setSveaConfigId("")}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Svea. Do you want to save the Svea checkout config anyway? "
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <SveaConfigInputs
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValues={sveaConfig}
            />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={sveaConfig.id}
              providerType={PROVIDER_TYPE.SVEA_CHECKOUT}
              providerCategory={PROVIDER_CATEGORY.PAYMENT}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}

export default EditSveaConfig
