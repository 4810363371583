import gql from "graphql-tag"

const GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES = gql`
  query getExternalEventsDestinationEventBuses {
    getExternalEventsDestinationEventBuses {
      eventBuses
    }
  }
`

export default GET_EXTERNAL_EVENTS_DESTINATION_EVENT_BUSES
