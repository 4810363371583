import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { isCampaignUser, isSuperUser } from "helpers/user"
import { Option } from "@lib/types/common"
import { getCountryName } from "helpers/countries"
import { InputStoreMarket, StoreGroup } from "lib/types/generated/graphql-types"

import {
  Container,
  CountryInfo,
  Description,
  SelectWrapper,
  StoreGroupLabel,
  StoreMarketRow,
  StoreMarketsTable,
  StyledMultiSelect,
  Value,
  Wrapper
} from "./CampaignStoreMarket.styled"

import Popup from "components/Ui/Popup"

import FlagIcon from "components/Ui/FlagIcon"
import SingleSelect from "components/Ui/Form/SingleSelect"

type Props = {
  addedStoreMarkets: InputStoreMarket[]
  setAddedStoreMarkets: Dispatch<SetStateAction<InputStoreMarket[]>>
  storeGroups: StoreGroup[] | undefined
  isCampaignOngoing?: boolean
}

export const CampaignStoreMarket = ({
  addedStoreMarkets,
  setAddedStoreMarkets,
  storeGroups,
  isCampaignOngoing
}: Props) => {
  const [selectedStoreMarkets, setSelectedStoreMarkets] = useState<Option[]>([])
  const [selectedStoreGroup, setSelectedStoreGroup] = useState<StoreGroup | undefined>()
  const [storeMarketToDelete, setStoreMarketToDelete] = useState<string>("")
  const [openPopup, setOpenPopup] = useState(false)

  const storeGroupOptions = storeGroups?.map((storeGroup: StoreGroup) => ({
    value: storeGroup.id,
    label: storeGroup.name
  })) as Option[]

  const storeMarketOptions = selectedStoreGroup?.storeMarkets?.map((storeMarket) => ({
    label: getCountryName(storeMarket?.countryCode ?? "") ?? "",
    value: storeMarket?.countryCode ?? ""
  }))

  const onDeletStoreMarket = () => {
    setAddedStoreMarkets((prev: InputStoreMarket[]) =>
      prev.filter((campaignStoreMarket) => campaignStoreMarket.key !== storeMarketToDelete)
    )
    setOpenPopup(false)
  }

  useEffect(() => {
    const addedMarkets = addedStoreMarkets.find((as) => as.key === selectedStoreGroup?.id)
    if (addedMarkets) {
      const addedCountries = addedMarkets?.value?.map((countryCode: string) => ({
        label: getCountryName(countryCode) ?? "",
        value: countryCode
      }))
      setSelectedStoreMarkets(addedCountries)
    } else {
      setSelectedStoreMarkets([])
    }
  }, [selectedStoreGroup])

  useEffect(() => {
    const storeMarkets = selectedStoreMarkets.map((option) => option.value)
    if (selectedStoreMarkets.length !== 0) {
      if (
        addedStoreMarkets.length === 0 ||
        !addedStoreMarkets.find((storeGroup) => storeGroup?.key === selectedStoreGroup?.id)
      ) {
        setAddedStoreMarkets((prev) => [
          ...prev,
          {
            key: selectedStoreGroup?.id ?? "",
            value: storeMarkets
          }
        ])
      } else {
        setAddedStoreMarkets(
          addedStoreMarkets.map((storeGroup) =>
            storeGroup?.key === selectedStoreGroup?.id
              ? {
                  key: storeGroup.key,
                  value: storeMarkets
                }
              : storeGroup
          )
        )
      }
    } else {
      setAddedStoreMarkets((prev: InputStoreMarket[]) =>
        prev.filter((campaignStoreMarket) => campaignStoreMarket.key !== selectedStoreGroup?.id)
      )
    }
  }, [selectedStoreMarkets])

  return (
    <Container>
      <h2>Countries</h2>
      <Description>Make your selection on store market(s).</Description>
      <SelectWrapper>
        <SingleSelect
          label="Store groups *"
          options={storeGroupOptions}
          disabled={isCampaignOngoing}
          setValue={(storeGroup) =>
            setSelectedStoreGroup(storeGroups?.find((sg) => sg.id === storeGroup?.value))
          }
          defaultValue={
            selectedStoreGroup
              ? { label: selectedStoreGroup.name, value: selectedStoreGroup.id }
              : undefined
          }
          placeholder="Select store group"
        />
        <StyledMultiSelect
          label="Store markets *"
          value={selectedStoreMarkets}
          options={selectedStoreGroup ? storeMarketOptions ?? [] : []}
          setValue={(e) => {
            setSelectedStoreMarkets(e)
          }}
          disabled={isCampaignOngoing}
          selectAllEnabled={true}
          placeholder="Select store market"
        />
      </SelectWrapper>
      <StoreMarketsTable>
        {addedStoreMarkets.length > 0 ? (
          <>
            {addedStoreMarkets.map((storeMarket) => {
              return (
                <Wrapper key={uuidv4()}>
                  <StoreGroupLabel>
                    <span>{storeGroups?.find((sg) => sg.id === storeMarket?.key)?.name}</span>
                  </StoreGroupLabel>
                  <StoreMarketRow
                    deleteEnabled={(isSuperUser() || isCampaignUser()) && !isCampaignOngoing}
                    smallButtons
                    disableHover
                    handleDelete={() => {
                      setStoreMarketToDelete(storeMarket.key)
                      setOpenPopup(true)
                    }}
                  >
                    <Value>
                      {storeMarket.value.map((countryCode) => (
                        <Value key={countryCode}>
                          <CountryInfo>
                            <FlagIcon countryCode={countryCode} />
                            {getCountryName(countryCode)}
                          </CountryInfo>
                        </Value>
                      ))}
                    </Value>
                  </StoreMarketRow>
                  {openPopup && storeMarketToDelete === storeMarket.key && (
                    <Popup
                      title="Delete market rule"
                      subtitle={`Are you sure you want to delete all store market(s) from?`}
                      item={{
                        itemId: "",
                        itemName: storeGroups?.find((sg) => sg.id === storeMarket?.key)?.name ?? ""
                      }}
                      handleCloseClick={() => setOpenPopup(false)}
                      handleOkClick={() => onDeletStoreMarket()}
                      type="delete"
                      overrideOkButton={!isSuperUser()}
                    />
                  )}
                </Wrapper>
              )
            })}
          </>
        ) : (
          <p>No store markets added</p>
        )}
      </StoreMarketsTable>
    </Container>
  )
}
