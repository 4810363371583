import React from "react"
import styled from "styled-components/macro"
import { useOutlet } from "react-router-dom"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Settings = () => {
  return <Container>{useOutlet()}</Container>
}
