import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"

import { GET_BONUS_CONFIG } from "graphql/queries/config/GetBonusProvider"

import { Name } from "components/Ui/Table/Shared.styled"

import { Url } from "./BonusConfigTableRow.styled"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"
import TableRow from "components/Ui/Table/TableRow"

import { ReactComponent as BonusLogo } from "images/icons/hand-holding-dollar.svg"
import { ReactComponent as TibberLogo } from "images/providers/tibber-logo.svg"

export const getLoadingRow = () => (
  <>
    <BonusLogo />
    <div>
      <Name>bonus-config-id</Name>
    </div>
    <Url className="hideOnSmallScreens">
      <p>https://baseurl.se</p>
    </Url>
    <Url className="hideOnSmallScreens">
      <p>https://authurl.se</p>
    </Url>
    <div className="hideOnSmallScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setBonusConfigId?: Dispatch<SetStateAction<string>>
}

export const BonusConfigTableRow = ({ id, setBonusConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_BONUS_CONFIG, {
    variables: { id: id }
  })

  const getBonusConfig = data?.getBonusConfig

  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>

  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setBonusConfigId) setBonusConfigId(getBonusConfig.id)
        dispatch(showEditSidebar())
      }}
    >
      {getBonusConfig?.baseUrl.includes("tibber") ? <TibberLogo /> : <BonusLogo />}
      <div>
        <Name>{getBonusConfig?.id}</Name>
      </div>
      <Url className="hideOnSmallScreens">
        <p>{getBonusConfig?.baseUrl}</p>
      </Url>
      <Url className="hideOnSmallScreens">
        <p>{getBonusConfig?.authUrl}</p>
      </Url>
      <div className="hideOnSmallScreens">
        <p>{dayjs(getBonusConfig?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens">
        <p>{dayjs(getBonusConfig?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
