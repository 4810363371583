import gql from "graphql-tag"

const DELETE_CANCELLATION = gql`
  mutation deleteCancellation($cancellationId: ID!) {
    Order {
      deleteCancellation(cancellationId: $cancellationId) {
        status
      }
    }
  }
`

export default DELETE_CANCELLATION
