import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import {  CampaignGroup } from "lib/types/generated/graphql-types"

import alertActions from "lib/store/services/Alert/AlertSlice"
import Popup from "../../Ui/Popup"
import ActionButton from "../../Ui/Table/ActionButton"
import DELETE_CAMPAIGN_GROUP from "../../../graphql/mutations/campaign/DeleteCampaignGroup"
import { GroupedCampaign } from "@lib/types/common"

type Props = {
  campaignGroup: CampaignGroup | undefined
  setGroupedCampaigns: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
}

const DeleteCampaignGroup = ({ campaignGroup, setGroupedCampaigns }: Props) => {
  const [openPopup, setOpenPopup] = useState(false)
  const dispatch = useAppDispatch()

  const [deleteCampaign, { loading: deleteLoading }] = useMutation(DELETE_CAMPAIGN_GROUP, {

    onCompleted: () => {
      setOpenPopup(false)
      setGroupedCampaigns((prev) => prev?.filter((c) => campaignGroup?.id !== c?.campaignGroup?.id))
      dispatch(
        alertActions.actions.createAlert({
          message: `Campaign group successfully deleted`,
          type: "success"
        })
      )
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          message: error.message,
          type: "error"
        })
      )
    }
  })
  const onDelete = () => {
    deleteCampaign({
      variables: {
        id: campaignGroup?.id
      }
    })
  }

  return (
    <>
      <ActionButton
        mode="delete"
        handleOnClick={(e) => {
          e.stopPropagation()
          setOpenPopup(true)
        }}
      />
      {openPopup && (
        <Popup
          title="Delete campaign group"
          subtitle={`Are you sure you want to delete the following campaign group?`}
          item={{
            itemId: campaignGroup?.id,
            itemName: campaignGroup?.name ?? ""
          }}
          handleCloseClick={(e) => {
            e.stopPropagation()
            setOpenPopup(false)
          }}
          handleOkClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
          loading={deleteLoading}
          type="delete"
        />
      )}
    </>
  )
}

export default DeleteCampaignGroup
