import gql from "graphql-tag"

const CREATE_OR_UPDATE_CART_RULE = gql`
  mutation createOrUpdateDiscountRule(
    $id: ID!
    $name: String!
    $logicRules: [InputLogicHolder!]!
    $isActive: Boolean!
    $isStackable: Boolean!
    $sortOrder: Int!
    $conditions: [InputCondition!]!
    $outcomes: [InputOutcome!]!
    $validDateRange: InputValidDateRange
  ) {
    createOrUpdateDiscountRule(
      id: $id
      discountRule: {
        name: $name
        logicRules: $logicRules
        isActive: $isActive
        isStackable: $isStackable
        sortOrder: $sortOrder
        conditions: $conditions
        outcomes: $outcomes
        validDateRange: $validDateRange
      }
    ) {
      id
    }
  }
`

export default CREATE_OR_UPDATE_CART_RULE
