import React, { Dispatch, SetStateAction, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputQliroOneConfig } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import GET_QLIRO_ONE_CONFIG from "graphql/queries/config/Providers/Qliro/GetQliroOneConfig"
import { VALIDATE_QLIRO_ONE } from "graphql/queries/config/Providers/Qliro/ValidateQliroOneConfig"
import { DELETE_QLIRO_ONE_CONFIG } from "graphql/mutations/config/Providers/Qliro/DeleteQliroOneConfig"
import { CREATE_OR_UPDATE_QLIRO_ONE_CONFIG } from "graphql/mutations/config/Providers/Qliro/CreateOrUpdateQliroOneConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"
import { QliroOneConfigInputs } from "components/Config/Qliro/QliroOneConfigInputs"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"

import { ReactComponent as Logo } from "images/providers/qliro-logo.svg"

type Props = {
  refetch: () => void
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

interface ExtendedInputQliroOneConfig extends InputQliroOneConfig {
  id: string
}

export const EditQliroOneConfig = ({ refetch, editId, setEditId }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const { data } = useQuery(GET_QLIRO_ONE_CONFIG, {
    variables: { id: editId }
  })
  const closeSidebar = () => {
    setEditId("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputQliroOneConfig>({ mode: "onBlur" })

  const [updateQliroOneConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_QLIRO_ONE_CONFIG,
    {
      onCompleted: () => {
        dispatch(hideEditSidebar())

        dispatch(
          alertActions.actions.createAlert({
            message: "Qliro One configuration successfully updated",
            type: "success"
          })
        )
        reset({
          merchantApiKey: "",
          merchantApiSecret: ""
        })
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )
  const qliroOneConfig = data?.getQliroOneConfig
  const { id } = qliroOneConfig

  const [validateQliroOne, { loading }] = useLazyQuery(VALIDATE_QLIRO_ONE)

  const onSubmit: SubmitHandler<ExtendedInputQliroOneConfig> = (data) => {
    validateQliroOne({
      variables: {
        input: {
          baseUrl: data.baseUrl,
          merchantOrderManagementStatusPushUrl: data.merchantOrderManagementStatusPushUrl,
          merchantApiKey: data.merchantApiKey,
          merchantApiSecret: data.merchantApiSecret,
          ...(data.integratedShipping && { integratedShipping: data.integratedShipping })
        }
      }
    }).then((response) => {
      if (response.data.Configuration.validate.qliroOne.valid) {
        updateQliroOneConfig({
          variables: {
            ...data,
            ...(data.integratedShipping && { integratedShipping: data.integratedShipping }),
            id
          }
        })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputQliroOneConfig> = (data) => {
    updateQliroOneConfig({
      variables: {
        ...data,
        ...(data.integratedShipping && { integratedShipping: data.integratedShipping }),
        id
      }
    })
    setValidationError(false)
  }

  if (!qliroOneConfig) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader icon={<Logo />} title={id} id={id} cancelEvent={() => closeSidebar()}>
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
          <DeleteButton
            id={id}
            name={id}
            entity={"Qliro One configuration"}
            redirectUrl="/settings/providers/qliro"
            mutation={DELETE_QLIRO_ONE_CONFIG}
            refetch={refetch}
            postDeleteFunction={() => setEditId("")}
            secureDelete={true}
          />
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Qliro One. Do you want to save the Qliro One config anyway?"
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <QliroOneConfigInputs
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValues={qliroOneConfig}
              showId={false}
            />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={qliroOneConfig.id}
              providerType={PROVIDER_TYPE.QLIRO_ONE}
              providerCategory={PROVIDER_CATEGORY.PAYMENT}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}
