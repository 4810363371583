import gql from "graphql-tag"

export const CREATE_OR_UPDATE_ADYEN_CONFIG = gql`
  mutation createOrUpdateAdyenConfig(
    $id: ID!
    $apiKey: String!
    $clientKey: String!
    $environment: AdyenEnvironment!
    $liveEndpointUrlPrefix: String!
    $merchantAccount: String!
  ) {
    createOrUpdateAdyenConfig(
      id: $id
      adyenConfig: {
        apiKey: $apiKey
        clientKey: $clientKey
        environment: $environment
        liveEndpointUrlPrefix: $liveEndpointUrlPrefix
        merchantAccount: $merchantAccount
      }
    ) {
      id
    }
  }
`

export const GENERATE_WEBHOOKS = gql`
  mutation generateAdyenWebhooks($id: ID!) {
    generateAdyenWebhooks(id: $id) {
      started
    }
  }
`
