import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components/macro"
import { useSelector } from "react-redux"
import { useAppDispatch } from "lib/store"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { StoreMarket, TaxMarket } from "@lib/types/generated/graphql-types"

import { BrinkLoader } from "../BrinkLoader"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 0 2rem 4rem;
  color: ${(p) => p.theme.colors.black};
  z-index: 10001;
  overflow-y: scroll;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 55rem;
    left: auto;
    padding: 0 3rem 4rem;
  }

  hr {
    margin: 2rem 0 3rem;
  }
`

const Blur = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000;
`

type Props = {
  children?: JSX.Element | JSX.Element[]
  handleOkClick?: React.MouseEventHandler
  setEditId?: Dispatch<SetStateAction<string>>
  setEditStoreMarket?: Dispatch<SetStateAction<StoreMarket | undefined>>
  setEditTaxMarket?: Dispatch<SetStateAction<TaxMarket | undefined>>
  loading?: boolean
  cancelEvent?: () => void
}

const EditSidebar = ({
  children,
  loading,
  setEditId,
  setEditStoreMarket,
  setEditTaxMarket,
  cancelEvent
}: Props) => {
  const showSidebar = useSelector(editSidebarOpen)
  const dispatch = useAppDispatch()

  const closeSideBar = () => {
    if (cancelEvent) {
      cancelEvent()
    } else {
      dispatch(hideEditSidebar())
      if (setEditId) setEditId("")
      if (setEditStoreMarket) setEditStoreMarket(undefined)
      if (setEditTaxMarket) setEditTaxMarket(undefined)
    }
  }

  if (!showSidebar.show) return null

  return (
    <>
      <Blur onClick={closeSideBar} />
      {loading ? (
        <Container>
          <BrinkLoader />
        </Container>
      ) : (
        <Container>{children}</Container>
      )}
    </>
  )
}

export default EditSidebar
