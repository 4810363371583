import gql from "graphql-tag"

const ENABLE_USER = gql`
    mutation enableUser(
        $username: AWSEmail!
    ) {
        enableUser(
            username: $username
        ) {
          status  
        }
    }
`

export default ENABLE_USER