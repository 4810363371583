import gql from "graphql-tag"

const RESTART_CANCELLATION = gql`
  mutation restartRefund($cancellationId: ID!, $input: InputOrderStartCancellation!) {
    Order {
      restartCancellation(cancellationId: $cancellationId, input: $input) {
        status
      }
    }
  }
`

export default RESTART_CANCELLATION
