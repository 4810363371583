import React, { Dispatch, SetStateAction, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Common } from "@lib/types"
import { v4 as uuidv4 } from "uuid"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import DineroFactory from "dinero.js"
import { isSuperUser } from "helpers/user"

import {
  Container,
  Add,
  AddButton,
  CurrencySelect,
  Formatted,
  Thresholds,
  TresholdHeader,
  ThresholdRow,
  Currency,
  Amount
} from "./Threshold.styled"

import Input from "components/Ui/Form/Input"
import Money from "components/Money"
import Popup from "components/Ui/Popup"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

type Inputs = {
  currency: string
  amount: number
  key: string
  value: number
}

type Props = {
  thresholds: Common.Money[]
  setThresholds: Dispatch<SetStateAction<Common.Money[]>>
  currencyOptions: Common.Option[]
}

const Threshold = ({ thresholds, setThresholds, currencyOptions }: Props) => {
  const dispatch = useAppDispatch()
  const [currency, setCurrency] = useState<string>("")
  const [amount, setAmount] = useState<number>(0)
  const [openPopup, setOpenPopup] = useState(false)
  const [currencyCodeToDelete, setCurrencyCodeToDelete] = useState<string>("")
  const {
    control,
    formState: { errors }
  } = useForm<Inputs>()

  const updateCurrency = (conditionType: Common.Option) => {
    setCurrency(conditionType?.value)
  }

  const addThreshold = () => {
    thresholds.find((t) => t.currencyCode === currency)
      ? dispatch(
          alertActions.actions.createAlert({
            type: "error",
            message: "Currency already exist. Only one threshold per currency is allowed"
          })
        )
      : setThresholds((prev: Common.Money[]) => [
          ...prev,
          {
            units: amount,
            currencyCode: currency
          }
        ])
  }

  const onDeleteThreshold = () => {
    setThresholds((prev: Common.Money[]) =>
      prev.filter((u: { currencyCode: string }) => u.currencyCode !== currencyCodeToDelete)
    )
    setOpenPopup(false)
  }

  return (
    <Container>
      <Add>
        <h3>Add threshold(s)</h3>
        <Controller
          name={"currency"}
          render={({ field }) => (
            <CurrencySelect
              {...field}
              options={currencyOptions}
              setValue={updateCurrency}
              placeholder="Select currency"
              label="Currency:"
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name={"amount"}
          render={({ field }) => (
            <Input
              {...field}
              type="number"
              label={<>Amount in minor units:</>}
              placeholder="Amount in minor units"
              errors={errors}
              onChange={(e: { target: { value: number } }) => {
                setAmount(e.target.value)
              }}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />

        <Formatted>
          <strong>Example: </strong>
          {amount && currency ? (
            <Money
              amount={parseInt(`${amount}`)}
              currencyUnit={currency as DineroFactory.Currency}
            />
          ) : (
            "0"
          )}
        </Formatted>
        <AddButton type="button" handleClick={addThreshold} disabled={!currency || !amount}>
          <PlusIcon /> Add
        </AddButton>
      </Add>
      <Thresholds>
        {thresholds?.length > 0 ? (
          <>
            <TresholdHeader>
              <div>Currency</div>
              <div>Amount</div>
            </TresholdHeader>
            <div>
              {thresholds.map((t) => {
                const fullCurrency = currencyOptions.find(
                  (c: Common.Option) => c.value === t.currencyCode
                ) || {
                  label: "",
                  value: ""
                }

                return (
                  <React.Fragment key={uuidv4()}>
                    <ThresholdRow
                      deleteEnabled={isSuperUser()}
                      smallButtons
                      disableHover
                      handleDelete={() => {
                        setCurrencyCodeToDelete(t.currencyCode)
                        setOpenPopup(true)
                      }}
                    >
                      <Currency>
                        <span>{t.currencyCode}</span>
                        {fullCurrency.label.split("[")[0]}
                      </Currency>
                      <Amount>
                        <span>{t.units}</span>
                        <Money
                          amount={parseInt(`${t.units}`)}
                          currencyUnit={t.currencyCode as DineroFactory.Currency}
                        />
                      </Amount>
                    </ThresholdRow>
                    {openPopup && currencyCodeToDelete === t.currencyCode && (
                      <Popup
                        title="Delete threshold"
                        subtitle={`Are you sure you want to delete the threshold for following currency?`}
                        item={{ itemId: "", itemName: t.currencyCode }}
                        handleCloseClick={() => setOpenPopup(false)}
                        handleOkClick={onDeleteThreshold}
                        type="delete"
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </>
        ) : (
          <p>No thresholds added</p>
        )}
      </Thresholds>
    </Container>
  )
}

export default Threshold
