import React, { useState } from "react"

import { useMutation, useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"
import { Common } from "@lib/types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import dayjs from "dayjs"
import { StoreGroupSearchHit, TaxGroupSearchHit } from "@lib/types/generated/graphql-types"

import GET_SHIPPING_TAX_GROUP from "graphql/queries/tax/GetShippingTaxGroup"
import SET_SHIPPING_TAX_GROUP from "graphql/mutations/tax/SetShippingTaxGroup"
import SEARCH_TAX_GROUPS from "graphql/queries/tax/SearchTaxGroups"

import {
  Container,
  AddButtonContainer,
  AddShippingTaxButton,
  StyledTableHeader
} from "./ShippingTax.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import { UpdateShippingTax } from "./UpdateShippingTax"
import PageSectionHeader from "components/Ui/Page/PageSectionHeader"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as TaxGroupIcon } from "images/icons/receipt.svg"

export const ShippingTax = () => {
  const [selectedTaxGroup, setSelectedTaxGroup] = useState<Common.Option>()
  const isSidebarOpen = useSelector(editSidebarOpen)

  const dispatch = useAppDispatch()

  const { data, loading, error, refetch } = useQuery(GET_SHIPPING_TAX_GROUP)
  const { data: taxGroupsData, loading: taxGroupsLoading } = useQuery(SEARCH_TAX_GROUPS, {
    variables: { from: 0, size: 1000 }
  })

  const [setShippingTax, { loading: createLoading }] = useMutation(SET_SHIPPING_TAX_GROUP, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Tax group successfully updated",
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  if ((loading || taxGroupsLoading) && !isSidebarOpen.show) return <BrinkLoader />

  const taxGroup = taxGroupsData?.searchTaxGroups?.hits?.find(
    (taxGroup: TaxGroupSearchHit) => taxGroup.id === data?.getShippingTaxGroup?.taxGroupId
  )
  const shippingTaxGroup = data?.getShippingTaxGroup

  return (
    <Container>
      <PageSectionHeader title="Shipping tax group" description="Handle tax group for shipping" />
      <StyledTableHeader>
        <div>Tax Group</div>
        <div className="hideOnSmallScreens">Created</div>
        <div className="hideOnSmallScreens">Updated</div>
      </StyledTableHeader>
      {error ? (
        <ErrorMessage
          message={
            <>
              Error loading Shipping tax. If not configured try to add shipping tax or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      ) : (
        <TableRow handleOnClick={() => dispatch(showEditSidebar())}>
          <TaxGroupIcon />
          <div>{taxGroup?.name}</div>
          <div className="hideOnSmallScreens">
            {dayjs(shippingTaxGroup?.created).format("YYYY-MM-DD")}
          </div>
          <div className="hideOnSmallScreens">
            {dayjs(shippingTaxGroup?.updated).format("YYYY-MM-DD")}
          </div>
        </TableRow>
      )}
      {isSidebarOpen.show && (
        <UpdateShippingTax
          setValue={setSelectedTaxGroup}
          updateTaxGroupFunction={() =>
            setShippingTax({
              variables: { taxGroupId: selectedTaxGroup?.value }
            })
          }
          defaultValue={taxGroup}
          createLoading={createLoading}
          options={taxGroupsData?.searchTaxGroups?.hits?.map((storeGroup: StoreGroupSearchHit) => ({
            value: storeGroup.id,
            label: storeGroup.name
          }))}
        />
      )}
      {!data?.getShippingTaxGroup?.taxGroupId ? (
        <AddButtonContainer>
          <AddShippingTaxButton type="button" handleClick={() => dispatch(showEditSidebar())}>
            <PlusIcon /> Add shipping tax
          </AddShippingTaxButton>
        </AddButtonContainer>
      ) : (
        <></>
      )}
    </Container>
  )
}
