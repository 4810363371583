import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputIngridConfig } from "@lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_OR_UPDATE_INGRID_CONFIG from "graphql/mutations/config/CreateOrUpdateIngridCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import IngridConfigInputs from "../../../../components/Config/Ingrid/IngridConfigInputs"


interface ExtendedInputIngridConfig extends InputIngridConfig {
  id: string
}

type Props = {
  refetch: () => void
}

export const CreateIngridConfig = ({ refetch }: Props) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputIngridConfig>({ mode: "onBlur" })

  const [addIngridConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_INGRID_CONFIG,
    {
      onCompleted: () => {
        reset({
          baseUrl: "",
          siteId: "",
          apiKey: ""
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Ingrid configuration successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputIngridConfig> = (data) => {
    addIngridConfig({ variables: { ...data } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Ingrid configuration">
          <PrimaryButton type="submit" loading={createLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <IngridConfigInputs control={control} errors={errors} setValue={setValue} showId={true} />
      </EditSidebar>
    </form>
  )
}
