import gql from "graphql-tag"

export const GET_WALLEY_CHECKOUT_CONFIG = gql`
  query getWalleyCheckoutConfig($id: ID!) {
    getWalleyCheckoutConfig(id: $id) {
      id
      storeId
      created
      updated
      baseUrl
      scope
      profileName
      deliveryModuleEnabled
      credentials {
        clientId
        secret
      }
    }
  }
`
