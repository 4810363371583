import React from "react"
import { Currency } from "helpers/money"
import DineroFactory from "dinero.js"

type Props = {
  amount: number
  currencyUnit: DineroFactory.Currency
  short?: boolean
}

const Money = ({ amount, currencyUnit, short = false }: Props) => {
  const Amount = Currency(currencyUnit)
  const money = Amount(amount)

  return <>{short ? money.toUnit() : money.toFormat()}</>
}

export default Money
