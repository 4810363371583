import React, { Dispatch, SetStateAction, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Common } from "@lib/types"
import { countryOptions, getCountryName } from "helpers/countries"
import { ChannelType, StoreMarket } from "@lib/types/generated/graphql-types"

import { Container, StyledTableRow, Name, Actions, NotFound } from "./StoreMarketTable.styled"

import FlagIcon from "components/Ui/FlagIcon"
import CreateStoreMarket from "views/Stores/StoreMarkets/CreateStoreMarket"
import ActionButton from "components/Ui/Table/ActionButton"
import QuickAdd from "components/Ui/Table/QuickAdd"
import DeleteStoreMarket from "./DeleteStoreMarket"
import SingleSelect from "components/Ui/Form/SingleSelect"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

type Props = {
  id: string
  storeMarkets: StoreMarket[]
  refetch: () => void
  setEditStoreMarket: Dispatch<SetStateAction<StoreMarket | undefined>>
  channelType: ChannelType
}

type Inputs = {
  countryCode: string
  currencyCode: string
  languageCodes: string[]
  isTaxIncludedInPrice: boolean
}

function StoreMarketTable({ id, storeMarkets, refetch, setEditStoreMarket, channelType }: Props) {
  const [editMode, setEditMode] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const { control } = useForm<Inputs>()
  const updateCountryCode = (countryCode: Common.Option) => {
    setCountryCode(countryCode.value)
  }

  return (
    <div>
      <QuickAdd inSidebar>
        <Controller
          name="countryCode"
          render={({ field }) => (
            <SingleSelect
              {...field}
              options={countryOptions}
              setValue={updateCountryCode}
              defaultValue={{ label: "Select country", value: "" }}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />

        <PrimaryButton type="button" handleClick={() => setEditMode(true)} disabled={!countryCode}>
          <PlusIcon /> Add
        </PrimaryButton>
      </QuickAdd>
      {editMode && (
        <CreateStoreMarket
          id={id}
          countryCode={countryCode}
          refetch={refetch}
          setEditMode={setEditMode}
          setEditStoreMarket={setEditStoreMarket}
          channelType={channelType}
        />
      )}
      {storeMarkets.length > 0 ? (
        <>
          <Container>
            {storeMarkets.map((storeMarket) => {
              const name = getCountryName(storeMarket.countryCode) ?? ""
              return (
                <StyledTableRow
                  key={storeMarket.countryCode}
                  isInactive={storeMarket.isArchived ?? true}
                  handleOnClick={() => {
                    setCountryCode(storeMarket.countryCode)
                  }}
                  missingCapabilities={!storeMarket.capabilities}
                >
                  <Name>
                    <FlagIcon countryCode={storeMarket.countryCode} />
                    {name} ({storeMarket.countryCode})
                  </Name>
                  <Actions missingCapabilities={!storeMarket.capabilities}>
                    <ActionButton
                      mode="edit"
                      handleOnClick={() => {
                        setEditStoreMarket(storeMarket)
                      }}
                    />
                    <DeleteStoreMarket
                      name={name}
                      storeGroupId={id}
                      countryCode={storeMarket.countryCode}
                      refetch={refetch}
                      icon={true}
                    />
                  </Actions>
                </StyledTableRow>
              )
            })}
          </Container>
        </>
      ) : (
        <NotFound>No store markets added</NotFound>
      )}
    </div>
  )
}

export default StoreMarketTable
