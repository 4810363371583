import gql from "graphql-tag"

const DELETE_CART_RULE = gql`
  mutation deleteDiscountRule($id: ID!) {
    deleteDiscountRule(id: $id) {
      status
    }
  }
`

export default DELETE_CART_RULE
