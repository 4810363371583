import styled from "styled-components/macro"

import { ReactComponent as MagnifyingGlass } from "images/icons/magnifying-glass.svg"
import { ReactComponent as ResetButton } from "images/icons/xmark.svg"

interface StyledProps {
  $show?: boolean
  $inSidebar?: boolean
  $padding?: string
}

export const Container = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  margin-bottom: ${(p) => (p.$inSidebar ? "2rem" : "2rem")};
  display: flex;
  flex: 1;
  align-items: center;
  gap: 2rem;
  max-width: 55rem;

  > div {
    padding-bottom: 0;
    flex: 1;
  }

  input {
    padding-bottom: 0;
    padding-left: 4.5rem;
  }
`

export const SearchIcon = styled(MagnifyingGlass)`
  width: 2rem;
  height: 2rem;
  position: absolute;
  z-index: 1;
  left: 1.5rem;
`

export const Actions = styled.div`
  position: absolute;
  right: 4rem;
  display: flex;
`

export const ResetSearch = styled(ResetButton)<StyledProps>`
  height: 2.5rem;
  cursor: pointer;
  transition: opacity 0.3s;
  transition-delay: 0.3s;
  opacity: ${(p) => (p.$show ? "1" : "0")};
`
