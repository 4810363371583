import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputBonusConfig } from "lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import { CREATE_OR_UPDATE_BONUS_CONFIG } from "graphql/mutations/config/CreateOrUpdateBonusConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import BonusConfigInput from "../../../../components/Config/Bonus/BonusConfigInput"

type Props = {
  refetch: () => void
}

interface ExtendedInputBonusConfig extends InputBonusConfig {
  id: string
}

export function CreateBonusConfig({ refetch }: Props) {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ExtendedInputBonusConfig>({ mode: "onBlur" })

  const [addBonusConfig, { loading }] = useMutation(CREATE_OR_UPDATE_BONUS_CONFIG, {
    onCompleted: () => {
      reset({
        id: "",
        baseUrl: "",
        authUrl: "",
        clientId: "",
        clientSecret: ""
      })
      dispatch(hideEditSidebar())
      dispatch(
        alertActions.actions.createAlert({
          message: "Custom bonus provider configuration successfully created",
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<ExtendedInputBonusConfig> = (data) => {
    addBonusConfig({ variables: { ...data } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create custom bonus provider">
          <PrimaryButton type="submit" loading={loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <BonusConfigInput control={control} errors={errors} showId={true}/>
      </EditSidebar>
    </form>
  )
}
