import styled from "styled-components/macro"
import { Loader } from "../Loader"

type StyledProps = {
  $loading?: boolean
}

export const Container = styled.button<StyledProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  border: none;
  color: ${(p) => p.theme.colors.black};
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  letter-spacing: 0.05rem;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  height: 4rem;
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;

  svg {
    height: 1.6rem;
    margin-right: 1rem;
    fill: ${(p) => p.theme.colors.black};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${(p) => p.theme.colors.greyDark};
  }

  span {
    display: flex;
    align-items: center;
    position: relative;
    height: 1.6rem;
    fill: ${(p) => p.theme.colors.white};
    opacity: ${(p) => (p.$loading ? "0" : "1")};
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    transition: opacity 0.2s;
    opacity: 0;
    z-index: 1;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;

    &:hover {
      &:before {
        opacity: 0.1;
      }
    }
  }
`

export const ButtonLoader = styled(Loader)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: 0;
`
