import React, { useState } from "react"
import { ProductVariant } from "@lib/types/generated/graphql-types"
import { List } from "../../Ui/List/List"
import {
  Block, Campaign,
  Flex,
  Row,
  StoreGroupWrapper, StyledTabList,
  TableData,
  TableHeader,
  VariantWrapper
} from "./ShowPrices.styled"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import HistoricalPrices from "./HistoricalPrices"
import { getCountryName } from "../../../helpers/countries"
import FlagIcon from "../../Ui/FlagIcon"
import { Tab, TabPanel, Tabs } from "react-tabs"
import placeholder from "../../../images/placeholder.svg"
import { useNavigate } from "react-router-dom"

type Props = {
  variant: ProductVariant
  getStoreGroupName: (id: string) => string
}

const VariantPrice = ({ variant, getStoreGroupName }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const storeGroupPrices = [...variant.StoreGroupPrices].sort((a, b) => {
    const nameA = getStoreGroupName(a?.storeGroupId ?? "")
    const nameB = getStoreGroupName(b?.storeGroupId ?? "")
    return nameA.localeCompare(nameB)
  })

  const navigate = useNavigate()

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <Block>
      <List>
        <Flex $expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <VariantWrapper>
            <img src={variant?.imageUrl ?? ""} alt={variant.name} onError={handleImageError}/>
            <span>
              <h3>{variant.name}</h3>
              <p>{variant.id}</p>
            </span>
          </VariantWrapper>
          {expanded ? <ChevronUp /> : <ChevronDown />}
        </Flex>
        {expanded ? (
          storeGroupPrices.length === 0 ? (
            <p>- No price data</p>
          ) : (
            <Tabs defaultIndex={0}>
              <StyledTabList>
                {storeGroupPrices.map((storeGroup) => (
                  <Tab key={storeGroup?.storeGroupId}>
                    {getStoreGroupName(storeGroup?.storeGroupId ?? "")}
                  </Tab>
                ))}
              </StyledTabList>
              <StoreGroupWrapper>
                {storeGroupPrices.map((storeGroup) => {
                  const markets = [...(storeGroup?.marketPrices ?? [])]
                  return (
                    <TabPanel key={storeGroup?.storeGroupId}>
                      <Row>
                        <TableHeader>Market</TableHeader>
                        <TableHeader>Base price</TableHeader>
                        <TableHeader>Sale price</TableHeader>
                        <TableHeader className="hideOnSmallScreens">Discount</TableHeader>
                        <TableHeader className="hideOnSmallScreens">Reference Price</TableHeader>
                        <TableHeader className="hideOnSmallScreens">Campaign</TableHeader>
                      </Row>

                      {markets
                        ?.sort((a, b) => {
                          const nameA = getCountryName(a?.countryCode) ?? ""
                          const nameB = getCountryName(b?.countryCode) ?? ""
                          return nameA.localeCompare(nameB)
                        })
                        .map((marketPrice, index) => (
                          <Row key={marketPrice.countryCode}>
                            <TableData key={marketPrice.countryCode} $row={index % 2 === 0}>
                              <FlagIcon countryCode={marketPrice.countryCode} />
                              {getCountryName(marketPrice.countryCode)}
                            </TableData>
                            <TableData $row={index % 2 === 0}>
                              <Money
                                key={marketPrice.countryCode}
                                amount={marketPrice.price.basePriceAmount}
                                currencyUnit={
                                  marketPrice.price.currencyCode as DineroFactory.Currency
                                }
                              />
                            </TableData>
                            <TableData $row={index % 2 === 0}>
                              <Money
                                key={marketPrice.countryCode}
                                amount={marketPrice.price.salePriceAmount}
                                currencyUnit={
                                  marketPrice.price.currencyCode as DineroFactory.Currency
                                }
                              />
                            </TableData>
                            <TableData $row={index % 2 === 0} className="hideOnSmallScreens">
                              <Money
                                key={marketPrice.countryCode}
                                amount={marketPrice.price.discountAmount}
                                currencyUnit={
                                  marketPrice.price.currencyCode as DineroFactory.Currency
                                }
                              />
                            </TableData>

                            <TableData $row={index % 2 === 0} className="hideOnSmallScreens">
                              <Money
                                key={marketPrice.countryCode}
                                amount={marketPrice.price?.referencePriceAmount ?? 0}
                                currencyUnit={
                                  marketPrice.price.currencyCode as DineroFactory.Currency
                                }
                              />
                            </TableData>
                            <TableData $row={index % 2 === 0} className="hideOnSmallScreens">
                              {marketPrice?.price?.campaign ? <Campaign onClick={() => navigate(`/campaigns/campaign/${marketPrice?.price?.campaign?.campaignId}`)}>{marketPrice?.price?.campaign?.name ?? "-"}</Campaign> : <div>-</div>}
                            </TableData>
                          </Row>
                        ))}
                      <HistoricalPrices marketPrices={storeGroup?.marketPrices} />
                    </TabPanel>
                  )
                })}
              </StoreGroupWrapper>
            </Tabs>
          )
        ) : (
          <></>
        )}
      </List>
    </Block>
  )
}

export default VariantPrice
