export const getLogicTypeDisplayName = (type: string) => {
  switch (type) {
    case "CARTOVERTHRESHOLD":
      return "Cart over threshold"

    case "CARTUNDERTHRESHOLD":
      return "Cart under threshold"

    case "MINITEMSVALUE":
      return "Minimum cart items value"

    case "MINITEMS":
      return "Minimum cart items"

    case "STOREGROUP":
      return "Store group"

    case "COUNTRYCODE":
      return "Country code"

    case "CARTDISCOUNT":
      return "Cart discount"

    case "ITEMDISCOUNTS":
      return "Item discounts"

    case "BUYGET":
      return "Buy X get X"

    case "BUYSELECT":
      return "Buy X get discount on Y"

    case "FREESHIPPING":
      return "Free shipping"

    case "GIFT":
      return "Gift with purchase (GWP)"

    case "FIXED":
      return "Fixed amount"

    case "RELATIVE":
      return "Percentage"

    case "SELECTITEMS":
      return "Item discount with specified quantity"

    case "OPTIMIZEBUYGETS":
      return "Buy X get X, with suboutcomes"

    default:
      return ""
  }
}
