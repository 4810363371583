import gql from "graphql-tag"

const UPDATE_USER = gql`
    mutation updateUser(
        $username: AWSEmail!
        $givenName: String!
        $familyName: String!
    ) {
        updateUser(
            username: $username
            input: {givenName: $givenName familyName: $familyName}
        ) {
            status
        }
    }
`

export default UPDATE_USER