import gql from "graphql-tag"

const CREATE_API_CLIENT = gql`
    mutation createApiClient($name: String!, $scopes: [String!]!) {
        createApiClient(input: { name: $name, scopes: $scopes }) {
            clientId
            clientSecret
            name
            scopes
        }
    }
`
export default CREATE_API_CLIENT
