import styled from "styled-components/macro"

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

export const InputWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;

  button {
    margin-left: 2rem;
    height: 4.5rem;
  }
`
