import React, { ReactElement } from "react"
import styled, { css } from "styled-components/macro"
import { TooltipIcon } from "../TooltipIcon"
import { ErrorMessage } from "@hookform/error-message"
import { isCampaignUser, isSuperUser } from "../../../helpers/user"
import { UseFormRegister } from "react-hook-form"

interface ContainerProps {
  removePaddingBottom?: boolean
}

interface StyledProps {
  show?: boolean
  invalid?: boolean
}

const Container = styled.div<ContainerProps>`
  padding-bottom: ${(p) => (p.removePaddingBottom ? "0" : "2rem")};
  position: relative;
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding-bottom: ${(p) => (p.removePaddingBottom ? "0" : "2.4rem")};
  }

  input[type="file"]::-webkit-file-upload-button {
    border: none;
    padding: 0.5rem 1.2rem;
    background: ${(p) => p.theme.colors.turquoiseDark};
    color: ${(p) => p.theme.colors.white};
    font-size: 1em;
    transition: all 0.2s;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-right: 1rem;
    letter-spacing: 0.05rem;
  }
`

const Field = styled.input<StyledProps>`
  width: 100%;
  height: 4.5rem;
  padding: 0.1rem 2rem 0;
  border: 0.1rem solid
    ${(p) => (p.invalid ? p.theme.colors.error.background : p.theme.colors.greyLight)};
  border-radius: 0.5rem;
  transition: all 0.2s;
  box-shadow: 0 0 0.5rem 0 ${(p) => (!p.invalid ? "transparent" : p.theme.colors.error.background)};

  &[type="file"] {
    background-color: field;
    line-height: 4rem;
  }

  ${(p) =>
    !p.invalid &&
    css`
      &:focus {
        box-shadow: 0 0 0.5rem 0 ${(p) => p.theme.colors.turquoiseDark};
        border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
      }

      ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        &:hover {
          border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
        }
      }
    `}
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

const ErrorMessageInfo = styled.p<StyledProps>`
  position: relative;
  padding: 0 1.2rem 0 0.5rem;
  font-size: 1.2rem;
  color: ${(p) => p.theme.colors.error.background} !important;
  letter-spacing: 0;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.show ? "1" : "0")};
  margin: 0;
`

const Description = styled.span`
  color: ${(p) => p.theme.colors.greyDarker};
  font-size: 1.3rem;
  margin: 0.6rem 0;
  display: block;
`

const Error = styled(Description)`
  color: ${(p) => p.theme.colors.error.background};
`

type TypeInput = {
  placeholder?: string
  label?: string | ReactElement
  name: string
  type?: string
  errors?: any // eslint-disable-line
  onChange?: any // eslint-disable-line
  disabled?: boolean
  defaultValue?: string | number
  errorType?: string
  description?: string
  removePaddingBottom?: boolean
  overrideDisabled?: boolean
  value?: any // eslint-disable-line
  toolTip?: string
  hooksErrorMessage?: boolean
  accept?: string
  min?: string | number
  register?: UseFormRegister<any> // eslint-disable-line
}

const Input: React.FC<TypeInput> = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<TypeInput>
>(
  (
    {
      type,
      name,
      placeholder,
      description,
      label,
      errors,
      onChange,
      defaultValue,
      disabled,
      removePaddingBottom = false,
      value,
      overrideDisabled,
      toolTip,
      hooksErrorMessage,
      min,
      register,
      ...props
    },
    ref
  ) => {
    const error = errors?.[name]

    const isDisabled = () => {
      if (disabled) return disabled
      if (overrideDisabled || isCampaignUser() || isSuperUser()) return false
      return !isSuperUser()
    }

    return (
      <Container removePaddingBottom={removePaddingBottom}>
        {label && (
          <Label>
            {label}
            {toolTip && <TooltipIcon content={toolTip} />}
            <ErrorMessageInfo show={error}>- {error?.message}</ErrorMessageInfo>
          </Label>
        )}

        {register ? (
          <Field
            {...register(name)}
            disabled={isDisabled()}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
        ) : (
          <Field
            ref={ref}
            type={type || "text"}
            placeholder={placeholder}
            invalid={error}
            defaultValue={defaultValue}
            onChange={(e) => onChange(e)}
            disabled={isDisabled()}
            onWheelCapture={(e) => e.currentTarget.blur()}
            value={value}
            min={min}
            {...props}
          />
        )}
        {description && <Description>{description}</Description>}
        {name && hooksErrorMessage && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <Error>{message}</Error>}
          />
        )}
      </Container>
    )
  }
)

Input.displayName = "Input"
export default Input
