import React from "react"
import { Tabs, TabList, Tab, TabPanel } from "react-tabs"

import PageHeader from "components/Ui/Page/PageHeader"
import ExternalEventsTable from "components/ExternalEvents/ExternalEventsTable"
import EventBuses from "./EventBuses/EventBuses"
import DestinationAPIs from "./DestinationAPIs/DestinationAPIs"
import { TooltipIcon } from "components/Ui/TooltipIcon"

export const ExternalEvents = () => (
  <>
    <PageHeader
      title="External events"
      description={
        <>
          Manage your external events in Brink Commerce.
          <a
            href="https://docs.brinkcommerce.com/retail-api/general/event-management/"
            target="_blank"
            rel="noreferrer"
          >
            <TooltipIcon content="Read more about External events in our Docs" />
          </a>
        </>
      }
      hasTabs
    />

    <Tabs>
      <TabList>
        <Tab>Event rules</Tab>
        <Tab>API destinations</Tab>
        <Tab>Event buses</Tab>
      </TabList>
      <TabPanel>
        <h2>Event rules</h2>
        <ExternalEventsTable />
      </TabPanel>
      <TabPanel>
        <DestinationAPIs />
      </TabPanel>
      <TabPanel>
        <EventBuses />
      </TabPanel>
    </Tabs>
  </>
)
