import React from "react"
import {
  DiscountCodeRuleSearchHit,
  DiscountCodeRuleSearchHits
} from "@lib/types/generated/graphql-types"
import CountUp from "react-countup"
import { useNavigate } from "react-router-dom"
import styled from "styled-components/macro"

import { Wrapper, IconWrapper, Info, Number } from "./Shared.styled"

import { ReactComponent as CartRuleIcon } from "images/icons/tag.svg"

const StyledNumber = styled(Number)`
  span {
    &:last-of-type {
      font-size: 1.3rem;

      ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        font-size: 1.6rem;
      }
    }
  }
`

type Props = {
  discountCodeRules: DiscountCodeRuleSearchHits
}

const DiscountCodes = ({ discountCodeRules }: Props) => {
  const navigate = useNavigate()
  const activeCartRules = discountCodeRules?.hits.filter(
    (discountCodeRule: DiscountCodeRuleSearchHit) => discountCodeRule.isActive
  )

  return (
    <Wrapper onClick={() => navigate("/discounts/discount-rules")}>
      <IconWrapper>
        <CartRuleIcon />
      </IconWrapper>
      <Info>
        Code rules
        <StyledNumber>
          <CountUp end={activeCartRules?.length} duration={1} />
          /
          <CountUp end={discountCodeRules?.total} duration={1} />
        </StyledNumber>
      </Info>
    </Wrapper>
  )
}

export default DiscountCodes
