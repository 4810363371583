import React from "react"
import {
  ProductVariantSearchHit,
  ProductVariantSearchHits
} from "@lib/types/generated/graphql-types"

import { Id, Name, ProductImage } from "../Shared.styled"
import { EmptyList, SearchResultContainer } from "./Search.styled"

import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import { SearchResultRow } from "./SearchResultRow"

import placeholder from "images/placeholder.svg"
import { ReactComponent as ProductIcon } from "images/icons/bags-shopping.svg"

type Props = {
  productData: ProductVariantSearchHits | undefined
  loading: boolean
  searchQuery: string
  error: object | undefined
}

export const SearchResult = ({ productData, loading, error, searchQuery }: Props) => {
  const tableHeader = <></>

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const getRows = (productVariant?: ProductVariantSearchHit) =>
    productVariant ? (
      <SearchResultRow key={productVariant.id} productVariant={productVariant} />
    ) : (
      <>
        <ProductImage src={""} onError={handleImageError} />
        <div>
          <Name>Product name</Name>
          <Id>Product id</Id>
        </div>
      </>
    )

  if (loading && !searchQuery)
    return (
      <>
        <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getRows()} />
      </>
    )

  return (
    <SearchResultContainer>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading product variants. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {productData && productData?.hits?.length > 0 ? (
        <>
          {tableHeader}
          <div>{productData.hits.map((product) => getRows(product))}</div>
        </>
      ) : (
        <EmptyList>
          <ProductIcon />
          <p> No products found</p>
        </EmptyList>
      )}
    </SearchResultContainer>
  )
}
