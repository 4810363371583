import React, { ChangeEvent, useMemo, useState } from "react"
import debounce from "lodash.debounce"

import {
  AppliedDiscountCodes,
  ArrowWrapper,
  CheckoutTab,
  DiscountCodeContainer,
  ErrorMessage,
  InputWrapper
} from "./DiscountCode.styled"

import DiscountCode from "./DiscountCode"

import { ReactComponent as DiscountCodeIcon } from "images/icons/tag.svg"
import { ReactComponent as ChevronDownIcon } from "images/icons/chevron-down.svg"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { useTypedSelector } from "lib/store"
import { selectCart } from "lib/store/services/brink/selectors"
import Input from "../../../../Ui/Form/Input"
import { useAddDiscountCodeMutation } from "lib/store/services/brink/actions"
import { useConfig } from "../../../../../configuration/useConfig"

const Discount = () => {
  const [expanded, setExpanded] = useState(false)
  const [discountCode, setDiscountCode] = useState("")
  const [discountCodeError, setDiscountCodeError] = useState<string | undefined>(undefined)
  const [addDiscountCode, { isLoading }] = useAddDiscountCodeMutation()
  const { awsRegion, customerName } = useConfig().config
  const cart = useTypedSelector(selectCart)

  const getErrorMessage = (error: string) => {
    switch (error) {
      case "EXPIRED_DISCOUNT_CODE":
        return "Discount code has expired"
      case "MISSING_DISCOUNT_CODE":
        return "No discount code found"
      case "INACTIVE_DISCOUNT_CODE":
        return "Discount code not active"
      case "UPCOMING_DISCOUNT_CODE":
        return "Discount code not yet active"
      case "CONSUMED_DISCOUNT_CODE":
        return "Discount code consumed"
      case "ALREADY_ADDED_DISCOUNT_CODE":
        return "Discount code already added"
      case "MAX_DISCOUNT_CODES":
        return "Maximum added discount codes"
      case "EXCLUSIVE_DISCOUNT_CODE":
        return "Discount code not applicable"
      case "CONDITION_DISCOUNT_CODE":
        return "Discount code not applicable"
    }
    return error
  }

  const handleSetDiscountCode = () => {
    onAddDiscountCode(discountCode)
  }

  const onAddDiscountCode = useMemo(() => {
    return debounce((code: string) => {
      return addDiscountCode({ awsRegion, customerName, discountCode: code })
        .unwrap()
        .then(() => setExpanded(false))
        .catch((error) => {
          setDiscountCodeError(error.data.error)
        })
    }, 500)
  }, [addDiscountCode])

  const handleChangeDiscountCode = (event: ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value)
  }

  return (
    <DiscountCodeContainer>
      <CheckoutTab onClick={() => setExpanded(!expanded)}>
        <span>
          <DiscountCodeIcon />
          Apply Discount code
        </span>
        <ArrowWrapper $expanded={expanded}>
          <ChevronDownIcon />
        </ArrowWrapper>
      </CheckoutTab>
      <InputWrapper $expanded={expanded}>
        <div>
          <Input
            type="text"
            name="code"
            placeholder="Discount code"
            value={discountCode}
            onChange={handleChangeDiscountCode}
          />
          <PrimaryButton
            type="button"
            disabled={!discountCode}
            handleClick={handleSetDiscountCode}
            loading={isLoading}
          >
            Apply
          </PrimaryButton>
        </div>
        {discountCodeError && <ErrorMessage>{getErrorMessage(discountCodeError)}</ErrorMessage>}
      </InputWrapper>
      {cart && cart.discountCodes.length > 0 && (
        <AppliedDiscountCodes $expanded={expanded}>
          {cart.discountCodes.map((discount) => (
            <DiscountCode key={discount.code} discountCode={discount} />
          ))}
        </AppliedDiscountCodes>
      )}
    </DiscountCodeContainer>
  )
}

export default Discount
