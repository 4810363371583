import React, { Dispatch, SetStateAction } from "react"
import Popup from "../../../../Ui/Popup"
import { useQuery } from "@apollo/client"
import GET_REFUND from "../../../../../graphql/queries/order/GetRefund"
import { ButtonWrapper, ProviderContainer } from "./StatusPopup.styled"
import Status from "../../../../Ui/Status"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as RefreshIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"

type Props = {
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  refundId: string
  refetchOrder: () => void
}

const StatusPopup = ({ setShowStatusPopup, refundId, refetchOrder }: Props) => {
  const { data, refetch, loading } = useQuery(GET_REFUND, { variables: { refundId } })

  const refund = data?.Order?.refund

  return (
    <Popup
      title={"Provider status"}
      handleCloseClick={() => {
        setShowStatusPopup(false)
        refetchOrder()
      }}
      handleOkClick={() => {
        setShowStatusPopup(false)
        refetchOrder()
      }}
      buttonText={"Ok"}
    >
      <div>
        <ButtonWrapper>
          <PrimaryButton handleClick={() => refetch()} loading={loading}>
            <RefreshIcon />
            Refresh status
          </PrimaryButton>
        </ButtonWrapper>
        {refund?.bonus && (
          <ProviderContainer>
            <b>Bonus</b>
            <Status status={refund?.bonusProvider?.status?.current} />
          </ProviderContainer>
        )}
        {refund?.giftCards.length > 0 && (
          <ProviderContainer>
            <b>Gift card</b>
            <Status status={refund?.giftCardProvider?.status?.current} />
          </ProviderContainer>
        )}
        {refund?.paymentProvider && (
          <ProviderContainer>
            <b>Payment</b>
            <Status status={refund?.paymentProvider?.status?.current} />
          </ProviderContainer>
        )}
      </div>
    </Popup>
  )
}

export default StatusPopup
