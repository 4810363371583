import gql from "graphql-tag"

const DELETE_RELEASE = gql`
  mutation deleteRelease($releaseId: ID!) {
    Order {
      deleteRelease(releaseId: $releaseId) {
        status
      }
    }
  }
`

export default DELETE_RELEASE
