import React, { Dispatch, SetStateAction } from "react"
import { CapabilityProvider } from "@lib/types/generated/graphql-types"

import { Url } from "./BonusConfig.styled"
import { Container, Wrapper, IconTableHeader, EmptyList } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import { getLoadingRow, BonusConfigTableRow } from "./BonusConfigTableRow"

import { ReactComponent as BonusLogo } from "images/icons/hand-holding-dollar.svg"

type Props = {
  bonusConfigurations: CapabilityProvider[]
  error: object | undefined
  setBonusConfigId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

const tableHeader = (
  <IconTableHeader>
    <div>Identifier</div>
    <Url className="hideOnSmallScreens">Base URL</Url>
    <Url className="hideOnSmallScreens">Auth URL</Url>
    <div className="hideOnSmallScreens">Created</div>
    <div className="hideOnSmallScreens">Updated</div>
  </IconTableHeader>
)

export const BonusConfigTable = ({
  bonusConfigurations,
  setBonusConfigId,
  error,
  loading
}: Props) => {
  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (bonusConfigurations?.length < 1)
    return (
      <EmptyList>
        <BonusLogo /> <p> No configurations added</p>
      </EmptyList>
    )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Bonus provider settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...bonusConfigurations].map((bonusProvider) => (
          <BonusConfigTableRow
            key={bonusProvider.id}
            id={bonusProvider.id}
            setBonusConfigId={setBonusConfigId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
