import gql from "graphql-tag"

const DELETE_SVEA_CHECKOUT_CONFIG = gql`
  mutation deleteSveaCheckoutConfig($id: ID!) {
    deleteSveaCheckoutConfig(id: $id) {
      status
    }
  }
`

export default DELETE_SVEA_CHECKOUT_CONFIG
