import gql from "graphql-tag"

const UPDATE_MODIFICATION = gql`
  mutation updateOrderModificationOrderLines($modificationId: ID!, $input: InputOrderModificationOrderLines!) {
    Order {
      updateOrderModificationOrderLines(modificationId: $modificationId, input: $input) {
        id
      }
    }
  }
`

export default UPDATE_MODIFICATION
