import React, { useState } from "react"
import styled from "styled-components/macro"

import { Label, List } from "components/Ui/List/List"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as TagIcon } from "images/icons/tag.svg"

type StyledProps = {
  $expanded: boolean
}

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 3rem;
  position: relative;
  height: calc(100% - 6.4rem);
`

const TagValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  div {
    background: ${(p) => p.theme.colors.greyLight};
    padding: 0.3rem 1rem;
    margin: 0.5rem;
    text-align: center;
    border-radius: 0.6rem;
    font-weight: 700;
    font-size: 1.2rem;
    width: fit-content;
  }
`

const Arrow = styled(DownIcon)<StyledProps>`
  width: 1.2rem !important;
  margin-left: 1rem;
  transition: transform 0.2s;
  transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
`

const Tab = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => (p.$expanded ? "none" : `0.1rem solid ${p.theme.colors.greyLight}`)};

  svg {
    width: 1.6rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.turquoiseDark};

    svg {
      fill: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

type Props = {
  tags: any // eslint-disable-line
  insideEditSidebar?: boolean
}

export const ProductVariantTags = ({ tags, insideEditSidebar }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <>
      <Tab onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        <TagIcon />
        {insideEditSidebar ? <h4>Tags</h4> : <h2>Tags</h2>}
        <Arrow $expanded={expanded} />
      </Tab>
      {expanded && (
        <Block>
          <List>
            {Object.keys(tags).length > 0 ? (
              tags &&
              Object.keys(tags)?.map((tagKey) => (
                <div key={tagKey}>
                  <Label>
                    <>{tagKey}:</>
                  </Label>
                  <TagValues>
                    {tags[tagKey]?.map((value: string) => (
                      <div key={value}>{value}</div>
                    ))}
                  </TagValues>
                </div>
              ))
            ) : (
              <p>No tags set</p>
            )}
          </List>
        </Block>
      )}
    </>
  )
}
