import gql from "graphql-tag"

const GET_API_CLIENT = gql`
    query apiClient($clientId: ID!) {
        apiClient(clientId: $clientId) {
            clientId
            clientSecret
            name
            scopes
            created
            updated
        }
    }
`

export default GET_API_CLIENT
