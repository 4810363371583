import gql from "graphql-tag"

const GET_KLARNA_CHECKOUT_CONFIG = gql`
    query getKlarnaCheckoutConfig($id: ID!) {
        getKlarnaCheckoutConfig(id: $id) {
            id
            created
            updated
            baseUrl
            merchantId
            klarnaShippingAssistantEnabled
            credentials {
                username
                password
            }
        }
    }
`

export default GET_KLARNA_CHECKOUT_CONFIG
