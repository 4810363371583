import React, { Dispatch, SetStateAction } from "react"
import { FrontendLogicItem } from "@lib/types/discount"

import { Container } from "./CopyNotice.styled"
import { Tooltip } from "components/Ui/Tooltip"

import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"

const DEFAULT_LOGIC_ITEM = {
  type: "",
  title: ""
}

type Props = {
  copyLogic: FrontendLogicItem
  setCopyLogic: Dispatch<SetStateAction<FrontendLogicItem>>
}

export const CopyNotice = ({ copyLogic, setCopyLogic }: Props) => {
  if (!copyLogic) return null

  return (
    <Container>
      <Tooltip id="clear-clipboard" />
      <b>1 {copyLogic.type}</b> in clipboard{" "}
      <DeleteIcon
        data-tooltip-id="clear-clipboard"
        data-tooltip-content="Clear clipboard"
        onClick={() => {
          setCopyLogic(DEFAULT_LOGIC_ITEM)
        }}
      />
    </Container>
  )
}
