import React from "react"
import {
  Order,
  OrderGiftCardProduct,
  OrderGiftCardProductRetain24
} from "@lib/types/generated/graphql-types"
import Refund from "../../../../../components/Order/Updates/Refund"
import { ReactComponent as RefundIcon } from "images/icons/arrows-repeat.svg"
import { ReactComponent as DeliverIcon } from "../../../../../images/icons/truck-ramp-box.svg"
import { ReactComponent as CancelIcon } from "../../../../../images/icons/ban.svg"
import { ReactComponent as ReleaseIcon } from "images/icons/cart-arrow-up.svg"
import { ReactComponent as ModifyIcon } from "images/icons/sliders.svg"

import { Container, Info } from "./Updates.styled"
import Delivery from "../../../../../components/Order/Updates/Delivery"
import Cancellation from "../../../../../components/Order/Updates/Cancellation"
import Compensation from "../../../../../components/Order/Updates/Compensation"
import Release from "../../../../../components/Order/Updates/Release"
import Modification from "../../../../../components/Order/Updates/Modification"

type Props = {
  order: Order
}

const Updates = ({ order }: Props) => {
  function isOrderGiftCardProductRetain24(
    object: OrderGiftCardProduct
  ): object is OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  const {
    Cancellations,
    Refunds,
    Deliveries,
    Releases,
    Compensations,
    Modifications,
    orderLines,
    gifts,
    currencyCode,
    shippingFees,
    GiftCardProduct
  } = order
  return (
    <>
      {Modifications.length > 0 && (
        <Container>
          <Info>
            <ModifyIcon />
            <h2>Modifications</h2>
          </Info>
          {Modifications?.map((modification) => (
            <Modification
              key={modification.id}
              modificationId={modification.id}
              currencyCode={order.currencyCode}
              orderLines={order?.orderLines ?? []}
            />
          ))}
        </Container>
      )}
      {Cancellations.length > 0 && (
        <Container>
          <Info>
            <CancelIcon />
            <h2>Cancellation</h2>
          </Info>
          {Cancellations?.map((cancellation) => (
            <Cancellation key={cancellation.id} cancellationId={cancellation.id} />
          ))}
        </Container>
      )}
      {Compensations.length > 0 && (
        <Container>
          <Info>
            <RefundIcon />
            <h2>Compensations</h2>
          </Info>
          {Compensations?.map((compensation) => (
            <Compensation
              key={compensation.id}
              compensationId={compensation.id}
              shippingFees={shippingFees ?? []}
              currencyCode={currencyCode}
              orderLines={orderLines ?? []}
            />
          ))}
        </Container>
      )}
      {Refunds.length > 0 && (
        <Container>
          <Info>
            <RefundIcon />
            <h2>Refunds</h2>
          </Info>
          {Refunds?.map((refund) => (
            <Refund
              key={refund.id}
              refundId={refund.id}
              shippingFees={shippingFees ?? []}
              currencyCode={currencyCode}
              orderLines={orderLines ?? []}
              gifts={gifts ?? []}
            />
          ))}
        </Container>
      )}
      {Deliveries.length > 0 && (
        <Container>
          <Info>
            <DeliverIcon />
            <h2>Deliveries</h2>
          </Info>
          {Deliveries?.map((delivery) => (
            <Delivery
              key={delivery.id}
              deliveryId={delivery.id}
              currencyCode={currencyCode}
              orderLines={orderLines ?? []}
              gifts={gifts ?? []}
              shippingFees={shippingFees ?? []}
              giftCardProducts={
                isOrderGiftCardProductRetain24(GiftCardProduct)
                  ? GiftCardProduct.giftCardProducts
                  : []
              }
            />
          ))}
        </Container>
      )}
      {Releases.length > 0 && (
        <Container>
          <Info>
            <ReleaseIcon />
            <h2>Releases</h2>
          </Info>
          {Releases?.map((release) => (
            <Release
              key={release.id}
              releaseId={release.id}
              currencyCode={currencyCode}
              orderLines={orderLines ?? []}
              gifts={gifts ?? []}
              shippingFees={shippingFees ?? []}
            />
          ))}
        </Container>
      )}
    </>
  )
}
export default Updates
