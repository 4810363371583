import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"

import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { CreateAvardaConfig } from "./CreateAvardaConfig"
import { EditAvardaConfig } from "./EditAvardaConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as AvardaLogo } from "images/providers/avarda-logo.svg"
import { AvardaConfigTable } from "../../../../components/Config/Avarda/AvardaConfigTable"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PageAvardaLogo = styled(AvardaLogo)`
  height: 5rem;
`

export const AvardaConfiguration = () => {
  const [editId, setEditId] = useState<string>("")
  const isSidebarOpen = useSelector(editSidebarOpen)
  const { loading, data, refetch, error } = useQuery(ALL_PAYMENT_PROVIDERS)
  const paymentProviders = data?.getPaymentProviders
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isSidebarOpen) {
      setEditId("")
    }
  }, [isSidebarOpen])

  return (
    <Container>
      <PageHeader
        title={<PageAvardaLogo />}
        description="Manage your Avarda configurations"
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(toggleEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!editId && <CreateAvardaConfig refetch={refetch} />}
      {editId && (
        <EditAvardaConfig
          editId={editId}
          setEditId={setEditId}
          refetch={refetch}
        />
      )}
      <AvardaConfigTable
        providers={paymentProviders}
        error={error}
        setEditId={setEditId}
        loading={loading}
      />
    </Container>
  )
}
