import styled from "styled-components/macro"

export const SelectWrapper = styled.div`
display: flex;
margin-bottom: 2rem;
  button {
margin-left: 1rem;
    height: 4.4rem;
  }
`
