import React, { useEffect, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import { Order, OrderLine, StoreGroup } from "lib/types/generated/graphql-types"
import { useTypedSelector } from "lib/store"
import { selectCart, selectCheckoutToken } from "lib/store/services/brink/selectors"
import { useDispatch } from "react-redux"
import {
  reset,
  useAddItemMutation,
  useStartSessionMutation
} from "lib/store/services/brink/actions"
import { useConfig } from "configuration/useConfig"
import { useNavigate, useParams } from "react-router-dom"
import esb from "elastic-builder"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"
import GET_ORDER from "graphql/queries/order/GetOrder"
import SEARCH_ORDERS from "graphql/queries/order/SearchOrders"

import { Container, Flex, Select, StyledPageHeader } from "./CreateOrder.styled"

import Box from "components/Ui/Box"
import { Cart } from "components/Order/CreateOrder/Cart/Cart"
import { AddVariant } from "components/Order/CreateOrder/AddVariant/AddVariant"
import Popup from "components/Ui/Popup"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import { RadioButton } from "components/Ui/Form/RadioButton"
import { OrderSummary } from "components/Order/CreateOrder/OrderSummary/OrderSummary"
import { VariantRow } from "components/Order/CreateOrder/VariantRow"
import { ProgressPopup } from "components/Ui/ProgressPopup"

import { ReactComponent as CheckIcon } from "images/icons/check.svg"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import alertActions from "lib/store/services/Alert/AlertSlice"

const CHECKOUT_URL = "https://checkout.brinkcommerce.com"

export const CloneOrder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { customerName, awsRegion } = useConfig().config
  const [storeGroups, setStoreGroups] = useState<StoreGroup[]>([])
  const [showPopup, setShowPoup] = useState<boolean>(false)
  const [termsUrl, setTermsUrl] = useState<string>("")
  const [copyActive, setCopyActive] = useState(false)
  const [orderReference, setOrderReference] = useState<string>("")
  const [order, setOrder] = useState<Order>()
  const [itemsLoading, setItemsLoading] = useState<boolean>(false)
  const [addItem] = useAddItemMutation()
  const [itemTotal, setItemTotal] = useState<number>(0)
  const [itemCount, setItemCount] = useState<number>(0)
  const [itemsLoaded, setItemsLoaded] = useState<boolean>(false)
  const cart = useTypedSelector(selectCart)
  const checkoutToken = useTypedSelector(selectCheckoutToken)
  const { id } = useParams()
  const [isCartInitiated, setIsCartInitiated] = useState<boolean>(false)
  const [faultyItems, setFaultyItems] = useState<OrderLine[]>([])

  const [startSession, { isLoading }] = useStartSessionMutation()

  const [getOrder, { loading: getOrderLoading }] = useLazyQuery(GET_ORDER, {
    onCompleted: (data) => {
      setOrder(data.Order.details)
    }
  })

  const [searchOrders, { loading: searchOrderLoading }] = useLazyQuery(SEARCH_ORDERS, {
    onCompleted: (data) => {
      if (data.searchOrders.hits.length === 0) {
        dispatch(
          alertActions.actions.createAlert({
            type: "error",
            message: "Could not find order"
          })
        )
        return
      }
      const id = data.searchOrders.hits[0].id
      if (!id) return
      getOrder({
        variables: { id }
      })
    }
  })

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => setStoreGroups(data.getStoreGroups)
  })

  const resetCart = () => {
    dispatch(reset())
    setIsCartInitiated(false)
    setFaultyItems([])
  }

  const closePopup = () => {
    resetCart()
    setTermsUrl("")
    setShowPoup(false)
  }

  const onAddToCart = async (productVariantId: string, quantity: number, item: OrderLine) => {
    addItem({
      awsRegion,
      customerName,
      productVariantId,
      quantity
    })
      .unwrap()
      .then(() => {
        setItemCount((prev) => prev + 1)
      })
      .catch(() => {
        setFaultyItems((prev) => [...prev, item])
      })
  }

  const createOrderDraft = async (storeGroupId: string, countryCode: string) => {
    startSession({
      awsRegion,
      customerName,
      storeGroupId,
      countryCode,
      languageCode: "en"
    })
      .unwrap()
      .then(() => {
        setIsCartInitiated(true)
      })
  }

  useEffect(() => {
    resetCart()
  }, [])

  useEffect(() => {
    if (!id) return
    getOrder({
      variables: { id }
    })
  }, [id])

  useEffect(() => {
    if (itemCount > 0 && itemCount === itemTotal) {
      setItemsLoaded(true)
      setItemsLoading(false)
    }
  }, [itemCount])

  useEffect(() => {
    if (!cart || !order || itemsLoaded) return
    const items = order.orderLines
    if (!items) return
    const totalItems = items.length
    setItemTotal(totalItems)
    setItemsLoading(true)
    for (let i = 0; i < totalItems; i++) {
      setTimeout(function () {
        onAddToCart(items[i].productVariantId, items[i].quantity, items[i])
      }, i * 500)
    }
  }, [isCartInitiated])

  useEffect(() => {
    if (checkoutToken) {
      setShowPoup(true)
    }
  }, [checkoutToken])

  return (
    <Box padding="0" margin="0">
      <>
        {itemsLoading && (
          <ProgressPopup
            title="Creating order draft"
            progressBarText="Adding products"
            finishedText="Finished"
            successCount={itemCount}
            failedCount={faultyItems.length}
            total={itemTotal}
            handleClose={setItemsLoading}
            resetCart={resetCart}
          >
            {faultyItems?.map((productVariant) => (
              <VariantRow
                key={productVariant.id}
                productVariant={productVariant}
                stockErrorText="Too few left in stock"
              />
            ))}
          </ProgressPopup>
        )}
      </>
      <StyledPageHeader
        title="Create order"
        description={
          <>
            Create a cart with store group, store market and products variants. In the next step you
            will get an checkout URL for shipping options and payment.
            <br />
            You can edit the new order once you have created a new draft.
          </>
        }
      />
      <>
        {showPopup && (
          <Popup
            title={"Brink checkout created"}
            subtitle={"Copy checkout url with button below and paste in browser:"}
            handleCloseClick={closePopup}
            hideOkButton={true}
          >
            {copyActive ? (
              <PrimaryButton>
                <CheckIcon /> Copied
              </PrimaryButton>
            ) : (
              <PrimaryButton
                handleClick={() => {
                  setCopyActive(true)
                  setTimeout(() => setCopyActive(false), 1000)
                  navigator.clipboard.writeText(
                    `${CHECKOUT_URL}/?merchant=${customerName}&token=${checkoutToken}&terms=${encodeURIComponent(
                      termsUrl
                    )}`
                  )
                }}
              >
                <CopyIcon />
                Copy checkout URL
              </PrimaryButton>
            )}
          </Popup>
        )}
      </>
      <Select>
        <RadioButton
          name="mode"
          label="Create new order"
          id="new"
          checked={false}
          onChange={() => {
            resetCart()
            navigate(`/orders/create-order`)
          }}
        />
        <RadioButton
          name="mode"
          label="Create order from existing order"
          id="clone"
          checked
          onChange={() => null}
        />
      </Select>
      <Container>
        <div>
          {!id && !order && (
            <>
              <h2>Fetch order</h2>
              <Flex>
                <Input
                  name="orderId"
                  type="text"
                  placeholder="Order reference"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setOrderReference(e.currentTarget.value)
                  }
                  defaultValue={orderReference}
                />
                <PrimaryButton
                  handleClick={() => {
                    searchOrders({
                      variables: {
                        from: 0,
                        size: 1,
                        query: JSON.stringify(
                          esb.boolQuery().must(esb.termsQuery("reference", orderReference))
                        )
                      }
                    })
                  }}
                  disabled={orderReference.length < 8}
                  loading={getOrderLoading || searchOrderLoading}
                >
                  Fetch order
                </PrimaryButton>
              </Flex>
            </>
          )}
          {order && !cart && (
            <OrderSummary
              order={order}
              createOrderDraft={() => createOrderDraft(order.storeGroupId, order.countryCode)}
              startSessionLoading={isLoading}
              isCartInitiated={isCartInitiated}
            />
          )}
          {cart && <AddVariant resetCart={resetCart} showGoBackButton={false} />}
        </div>
        <div>
          <Cart
            setTermsUrl={setTermsUrl}
            termsUrl={termsUrl}
            storeGroups={storeGroups}
            checkoutUrl={CHECKOUT_URL}
          />
        </div>
      </Container>
    </Box>
  )
}
