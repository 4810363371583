import React from "react"
import styled, { css } from "styled-components"
import { Loader } from "../Loader"
import { isSuperUser } from "../../../helpers/user"

type StyledProps = {
  small?: boolean
  mode?: string
  $loading?: boolean
}

const Container = styled.button<StyledProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.small ? "0 1rem" : "0 2rem")};
  border: none;
  background: ${(p) => p.theme.colors.turquoiseDark};
  color: ${(p) => p.theme.colors.white};
  letter-spacing: 0.05rem;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  height: ${(p) => (p.small ? "3rem" : "4rem")};
  cursor: pointer;
  transition: all 0.2s;
  overflow: hidden;

  &:disabled {
    cursor: not-allowed;
    background: ${(p) => p.theme.colors.greyDark};
  }

  ${(p) =>
    p.mode === "delete" &&
    css`
      background: ${(p) => p.theme.colors.errorText};
      color: ${(p) => p.theme.colors.white};
    `}
  ${(p) =>
    p.mode === "secondary" &&
    css`
      background: transparent;
      color: ${(p) => p.theme.colors.white};
      border: 0.2rem solid ${(p) => p.theme.colors.white};
    `}
  span {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    opacity: ${(p) => (p.$loading ? "0" : "1")};
  }

  svg {
    height: 1.6rem;
    margin-right: 1rem;
    fill: ${(p) => p.theme.colors.white};
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    transition: opacity 0.2s;
    opacity: 0;
    z-index: 1;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;

    &:hover {
      &:before {
        opacity: 0.1;
      }
    }
  }
`

const ButtonLoader = styled(Loader)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: 0;
`

type Props = {
  children: React.ReactNode
  small?: boolean
  type?: "submit" | "reset" | "button"
  mode?: "default" | "delete" | "secondary"
  loading?: boolean
  disabled?: boolean
  handleClick?: React.MouseEventHandler
  overrideDisabled?: boolean
  title?: string
}

const PrimaryButton = ({
  children,
  mode = "default",
  small = false,
  type = "button",
  handleClick,
  loading = false,
  disabled = false,
  overrideDisabled,
  title,
  ...props
}: Props) => {
  return (
    <Container
      disabled={isSuperUser() ? (loading || disabled) : overrideDisabled ? false : true}
      type={type}
      mode={mode}
      small={small}
      $loading={loading}
      title={title || ""}
      onClick={handleClick}
      {...props}
    >
      {loading && <ButtonLoader />}
      <span>{children}</span>
    </Container>
  )
}

export default PrimaryButton
