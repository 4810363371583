import React, { Dispatch, SetStateAction } from "react"
import { StyledTableRow } from "./ConnectedStoreMarkets.styled"
import FlagIcon from "../../Ui/FlagIcon"
import { getCountryName } from "../../../helpers/countries"
import ActionButton from "../../Ui/Table/ActionButton"
import {
  Maybe,
  Provider,
  StoreGroup,
  StoreMarket,
  StoreMarketSearchHit
} from "lib/types/generated/graphql-types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useMutation } from "@apollo/client"
import UPDATE_STORE_MARKET from "../../../graphql/mutations/store/UpdateStoreMarket"
import { useDispatch } from "react-redux"
import { Loader } from "../../Ui/Loader"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

type Props = {
  storeMarket: StoreMarketSearchHit
  storeGroups: StoreGroup[]
  providerId: string
  connectedStoreMarkets: StoreMarketSearchHit[]
  setConnectedStoreMarkets: Dispatch<SetStateAction<StoreMarketSearchHit[]>>
  providerCategory: PROVIDER_CATEGORY
  refetch: () => void
  providerType: PROVIDER_TYPE
}

const StoreMarketRow = ({
  storeMarket,
  storeGroups,
  providerId,
  connectedStoreMarkets,
  setConnectedStoreMarkets,
  providerCategory,
  refetch,
  providerType
}: Props) => {
  const [updateStoreMarket, { loading: updateStoreMarketLoading }] =
    useMutation(UPDATE_STORE_MARKET)
  const dispatch = useDispatch()

  const getCapabilities = (storeMarket: StoreMarket) => {
    if (
      providerCategory === PROVIDER_CATEGORY.PAYMENT ||
      providerCategory === PROVIDER_CATEGORY.SHIPPING ||
      providerCategory === PROVIDER_CATEGORY.GIFTCARD
    ) {
      return {
        ...storeMarket.capabilities,
        [providerCategory]:
          storeMarket?.capabilities?.[providerCategory]?.filter(
            (provider: Maybe<Provider>) => provider?.id !== providerId
          ) ?? storeMarket?.capabilities?.[providerCategory]
      }
    } else {
      return storeMarket?.capabilities
    }
  }
  const getCartCapabilities = (storeMarket: StoreMarket) => {
    if (
      providerCategory === PROVIDER_CATEGORY.CART_CAPABILITY &&
      (providerType === "voyado" || providerType === "bonus") &&
      storeMarket?.cartCapabilities
    ) {
      const { [providerType]: _, ...remaining } = storeMarket.cartCapabilities // eslint-disable-line @typescript-eslint/no-unused-vars

      if (remaining.voyado !== null && remaining.bonus !== null) {
        return remaining
      } else {
        return {}
      }
    }
  }

  const removeStoreMarket = (storeGroupId: string, countryCode: string) => {
    const storeMarket =
      storeGroups
        ?.find((storeGroup: StoreGroup) => storeGroup?.id === storeGroupId)
        ?.storeMarkets?.find((storeMarket) => storeMarket?.countryCode === countryCode) ?? undefined

    if (storeMarket) {
      updateStoreMarket({
        variables: {
          countryCode: countryCode,
          storeGroupId: storeGroupId,
          capabilities: getCapabilities(storeMarket),
          cartCapabilities: getCartCapabilities(storeMarket),
          currencyCode: storeMarket.currencyCode,
          isArchived: storeMarket.isArchived,
          isTaxExemptionEligible: storeMarket.isTaxExemptionEligible,
          isTaxIncludedInPrice: storeMarket.isTaxIncludedInPrice
        }
      })
        .then(() => {
          dispatch(
            alertActions.actions.createAlert({
              type: "success",
              message: `Store market ${getCountryName(countryCode)} successfully removed`
            })
          )
          setConnectedStoreMarkets(
            connectedStoreMarkets.filter(
              (storeMarket) =>
                !(
                  storeMarket.countryCode === countryCode &&
                  storeMarket.storeGroupId === storeGroupId
                )
            )
          )
          refetch()
        })
        .catch(() =>
          dispatch(
            alertActions.actions.createAlert({
              type: "error",
              message: "Error: can't remove all payment providers on store market"
            })
          )
        )
    }
  }
  return (
    <StyledTableRow key={storeMarket.countryCode}>
      <FlagIcon countryCode={storeMarket.countryCode} />
      {getCountryName(storeMarket.countryCode)}
      {updateStoreMarketLoading ? (
        <span>
          <Loader color="grey" />
        </span>
      ) : (
        <ActionButton
          mode="delete"
          handleOnClick={() => {
            removeStoreMarket(storeMarket.storeGroupId, storeMarket.countryCode)
          }}
        />
      )}
    </StyledTableRow>
  )
}

export default StoreMarketRow
