import gql from "graphql-tag"

const GET_ADYEN_CONFIG = gql`
  query getConfig($id: ID!) {
    getAdyenConfig(id: $id) {
      clientKey
      id
      created
      updated
      environment
      liveEndpointUrlPrefix
      merchantAccount
      credentials {
        apiKey
      }

      webhooks {
        id
        description
        configId
        externalId
        target
        status
        created
        updated
        sequenceNumber
      }
    }
  }
`

export default GET_ADYEN_CONFIG
