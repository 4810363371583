import React, { Dispatch, SetStateAction } from "react"
import { CapabilityProvider } from "@lib/types/generated/graphql-types"

import { Container, Wrapper, IconTableHeader, EmptyList } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import { getLoadingRow, VoyadoConfigTableRow } from "./VoyadoConfigTableRow"

import { ReactComponent as VoyadoLogo } from "images/providers/icons/voyado.svg"

type Props = {
  voyadoConfigurations: CapabilityProvider[]
  error: object | undefined
  setVoyadoConfigId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const VoyadoConfigTable = ({
  voyadoConfigurations,
  setVoyadoConfigId,
  error,
  loading
}: Props) => {
  const tableHeader = (
    <IconTableHeader>
      <div>Identifier</div>
      <div className="hideOnSmallScreens">Base URL</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Created</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Updated</div>
    </IconTableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (voyadoConfigurations?.length < 1)
    return (
      <EmptyList>
        <VoyadoLogo /> <p> No configurations added</p>
      </EmptyList>
    )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Voyado settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...voyadoConfigurations].map((voyadoProvider) => (
          <VoyadoConfigTableRow
            key={voyadoProvider.id}
            id={voyadoProvider.id}
            setVoyadoConfigId={setVoyadoConfigId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
