import React from "react"
import styled from "styled-components/macro"
import {
  OrderAddress,
  OrderPayment,
  OrderPaymentAdyen,
  OrderPaymentAvarda,
  OrderPaymentBrinkZeroPayment,
  OrderPaymentKlarnaCheckout,
  OrderPaymentQliroOne,
  OrderPaymentSveaCheckout,
  OrderPaymentWalleyCheckout
} from "@lib/types/generated/graphql-types"
import { getCountryName } from "helpers/countries"

import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import Adyen from "./Adyen"
import Klarna from "./Klarna"
import Svea from "./Svea"
import QliroOne from "./QliroOne"
import FlagIcon from "components/Ui/FlagIcon"

import { ReactComponent as Icon } from "images/icons/credit-card.svg"
import { ReactComponent as LocationIcon } from "images/icons/location-dot.svg"
import Walley from "./Walley"
import BrinkZeroPayment from "./BrinkZeroPayment"
import Avarda from "./Avarda"

const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }

  > div {
    flex: 1;
  }
`

type Props = {
  orderPayment:
    | OrderPaymentAdyen
    | OrderPaymentKlarnaCheckout
    | OrderPaymentSveaCheckout
    | OrderPaymentQliroOne
    | OrderPaymentBrinkZeroPayment
  billingAddress: OrderAddress | undefined
}

function isOrderPaymentAdyen(object: OrderPayment): object is OrderPaymentAdyen {
  return object.providerName === "Adyen"
}

function isOrderPaymentKlarnaCheckout(object: OrderPayment): object is OrderPaymentKlarnaCheckout {
  return object.providerName === "KlarnaCheckout"
}

function isOrderPaymentSveaCheckout(object: OrderPayment): object is OrderPaymentSveaCheckout {
  return object.providerName === "SveaCheckout"
}

function isOrderPaymentWalleyCheckout(object: OrderPayment): object is OrderPaymentWalleyCheckout {
  return object.providerName === "WalleyCheckout"
}

function isOrderPaymentQliroOne(object: OrderPayment): object is OrderPaymentQliroOne {
  return object.providerName === "QliroOne"
}

function isOrderPaymentBrinkZero(object: OrderPayment): object is OrderPaymentBrinkZeroPayment {
  return object.providerName === "BrinkZeroPayment"
}

function isOrderPaymentAvarda(object: OrderPayment): object is OrderPaymentAvarda {
  return object.providerName === "Avarda"
}

const OrderPaymentInfo = ({ orderPayment, billingAddress }: Props) => {
  return (
    <Columns>
      <OrderBlock>
        <h2>
          <Icon />
          Payment details
        </h2>
        <>
          {isOrderPaymentAdyen(orderPayment) && <Adyen orderPayment={orderPayment} />}
          {isOrderPaymentKlarnaCheckout(orderPayment) && <Klarna orderPayment={orderPayment} />}
          {isOrderPaymentSveaCheckout(orderPayment) && <Svea orderPayment={orderPayment} />}
          {isOrderPaymentWalleyCheckout(orderPayment) && <Walley orderPayment={orderPayment} />}
          {isOrderPaymentQliroOne(orderPayment) && <QliroOne orderPayment={orderPayment} />}
          {isOrderPaymentBrinkZero(orderPayment) && (
            <BrinkZeroPayment orderPayment={orderPayment} />
          )}
          {isOrderPaymentAvarda(orderPayment) && <Avarda orderPayment={orderPayment} />}
        </>
      </OrderBlock>
      <OrderBlock>
        <h2>
          <LocationIcon />
          Billing address
        </h2>
        <List>
          <Item>
            <Label>Name:</Label>
            <Value>
              <>
                {billingAddress?.givenName} {billingAddress?.familyName}
              </>
            </Value>
          </Item>
          <Item>
            <Label>Street address:</Label>
            <Value>{billingAddress?.streetAddress}</Value>
          </Item>
          {billingAddress?.streetAddress2 ? (
            <Item>
              <Label>Street address:</Label>
              <Value>{billingAddress?.streetAddress2}</Value>
            </Item>
          ) : (
            <></>
          )}
          <Item>
            <Label>Postal code:</Label>
            <Value>{billingAddress?.postalCode}</Value>
          </Item>
          <Item>
            <Label>City:</Label>
            <Value>{billingAddress?.city}</Value>
          </Item>
          <Item>
            <Label>Country:</Label>
            <Value flex>
              <>
                <FlagIcon countryCode={billingAddress?.country ?? ""} />
                {getCountryName(billingAddress?.country ?? "") ?? billingAddress?.country}
              </>
            </Value>
          </Item>
          <Item>
            <Label>Telephone number:</Label>
            <Value>{billingAddress?.telephoneNumber}</Value>
          </Item>
        </List>
      </OrderBlock>
    </Columns>
  )
}

export default OrderPaymentInfo
