import gql from "graphql-tag"

const SEARCH_STORE_GROUPS = gql`
  query searchStoreGroups(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
  ) {
      searchStoreGroups(
      from: $from
      size: $size
      sort: $sort
      query: $query
    ) {
      total
      hits {
        id
        name
      }
    }
  }
`

export default SEARCH_STORE_GROUPS
