import React from "react"
import styled from "styled-components/macro"
import TableRow from "./TableRow"
import { v4 as uuidv4 } from "uuid"

const StyledTableRow = styled(TableRow)`
  > div > div {
    flex: 1;
  }
`

export const BlurRow = styled(StyledTableRow)`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);

  &:hover {
    background: white;
    cursor: default;
  }
`

type Props = {
  numberOfRows?: number
  rows: React.ReactElement
  tableHeader?: React.ReactElement
}

const TableBlurLoading = ({ numberOfRows = 3, rows, tableHeader }: Props) => {
  return (
    <>
      {tableHeader}
      {[...Array(numberOfRows)].map(() => (
        <BlurRow key={uuidv4()}>{rows}</BlurRow>
      ))}
    </>
  )
}

export default TableBlurLoading
