import gql from "graphql-tag"

const RESTART_MODIFICATION = gql`
  mutation restartOrderModificationOrderLines($modificationId: ID!, $input: InputOrderModificationOrderLinesStart!) {
    Order {
      restartOrderModificationOrderLines(modificationId: $modificationId, input: $input) {
        status
      }
    }
  }
`

export default RESTART_MODIFICATION
