import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { StoreGroup } from "@lib/types/generated/graphql-types"

import {
  Wrapper,
  Container,
  StoreMarkets,
  StyledTableHeader,
  Id,
  Name,
  Description,
  EmptyList
} from "./StoreGroupTable.styled"

import TableRow from "components/Ui/Table/TableRow"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"

import { ReactComponent as SGIcon } from "images/icons/circle-nodes.svg"

type Props = {
  storeGroups: StoreGroup[]
  loading: boolean
  error: object | undefined
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

function StoreGroupTable({ storeGroups, loading = false, error, setEditId }: Props) {
  const dispatch = useAppDispatch()

  const tableHeader = (
    <StyledTableHeader>
      <div>Name</div>
      <Description className="hideOnSmallScreens">Description</Description>
      <div className="hideOnSmallScreens">Type</div>
      <StoreMarkets>Store markets #</StoreMarkets>
    </StyledTableHeader>
  )

  const getTableRow = (storeGroup?: StoreGroup) => {
    if (storeGroup)
      return (
        <TableRow
          key={storeGroup.id}
          handleOnClick={() => {
            setEditId(storeGroup.id)
            dispatch(showEditSidebar())
          }}
        >
          <SGIcon />
          <div>
            <div>
              <Name>{storeGroup.name}</Name>
              <Id>{storeGroup.id}</Id>
            </div>
          </div>
          <Description className="hideOnSmallScreens">
            <p>{storeGroup.description}</p>
          </Description>
          <div className="hideOnSmallScreens">
            <p>{storeGroup.channelType}</p>
          </div>
          <StoreMarkets>{storeGroup?.storeMarkets?.length}</StoreMarkets>
        </TableRow>
      )
    return (
      <>
        <SGIcon />
        <div>
          <div>
            <Name>Store group name</Name>
            <Id>Store group id</Id>
          </div>
        </div>
        <Description className="hideOnSmallScreens">
          <p>Description of the store group</p>
        </Description>
        <div className="hideOnSmallScreens">B2C</div>
        <StoreMarkets>10</StoreMarkets>
      </>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getTableRow()} />

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Store groups. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {storeGroups.length > 0 ? (
          <>
            {tableHeader}
            {storeGroups.map((storeGroup) => getTableRow(storeGroup))}
          </>
        ) : (
          <EmptyList>
            <SGIcon />
            <p> No store groups found</p>
          </EmptyList>
        )}
      </Container>
    </Wrapper>
  )
}

export default StoreGroupTable
