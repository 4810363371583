import styled from "styled-components/macro"

import TableHeader from "components/Ui/Table/TableHeader"
import TableRow from "components/Ui/Table/TableRow"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
`

export const StyledTableRow = styled(TableRow)`
  > div > div {
    flex: 1;
  }
`

export const Username = styled.div`
  min-width: 40rem;
`

export const Email = styled.p`
  font-size: 1.3rem;
  margin: 0;
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const Date = styled.div`
  padding-left: 5rem;
`
