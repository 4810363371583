import React, { Dispatch, SetStateAction } from "react"
import { Order } from "lib/types/generated/graphql-types"

import { ButtonWrapper, CartContainer, CartItems, EmptyContainer } from "./Cart.styled"

import CartItem from "./CartItem"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import { handleOrderManagementError } from "../../Helpers/errors"
import DELETE_MODIFICATION from "../../../../../graphql/mutations/order/modification/DeleteModification"
import Totals from "./Totals"
import Reason from "./Reason"

type Props = {
  order: Order
  refetch: () => void
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
  summaryMode?: boolean
}

export const Cart = ({
  order,
  refetch,
  setShowConfirmation,
  summaryMode = false,
}: Props) => {
  const filteredModifications = order?.Modifications?.filter(
    (modification) => !modification.completed && !modification.started
  )

  const [deleteModification, { loading: deleteLoading }] = useMutation(DELETE_MODIFICATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  if (!order) return null
  return (
    <>
      {filteredModifications?.length > 0 ? (
        <>
          <h2>New order draft</h2>
          {filteredModifications?.map((modification) => (
            <CartContainer key={modification.id}>
              <CartItems>
                {order?.orderLines?.map((orderLine) => (
                  <CartItem
                    currencyCode={order.currencyCode}
                    key={orderLine.id}
                    item={orderLine}
                    refetch={refetch}
                  />
                ))}
              </CartItems>
              <Reason modification={modification} refetch={refetch} />
              <Totals totals={order.totals} currencyCode={order.currencyCode} />
              <ButtonWrapper>
                {!summaryMode && (
                  <SecondaryButton
                    loading={deleteLoading}
                    handleClick={() => {
                      deleteModification({ variables: { modificationId: modification.id } })
                    }}
                  >
                    Cancel modification draft
                  </SecondaryButton>
                )}
                {!summaryMode && (
                  <PrimaryButton handleClick={() => setShowConfirmation(true)}>Next</PrimaryButton>
                )}
              </ButtonWrapper>
            </CartContainer>
          ))}
        </>
      ) : (
        <>
          <h2>New order draft</h2>
          <EmptyContainer>
            <h2>Create modification to finalize.</h2>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
