import gql from "graphql-tag"

const GET_PRODUCT_VARIANT = gql`
  query GetProduct($id: ID!) {
    Product {
      variant(id: $id) {
        Stock {
          id
          inventories {
            created
            inventoryId
            quantity
            updated
          }
          stockQuantity
          validateStock
        }
        StoreGroupPrices {
          marketPrices {
            countryCode
            price {
              basePriceAmount
              currencyCode
              discountAmount
              campaign {
                campaignGroupId
                campaignId
                name
              }
              priceRecords {
                basePriceAmount
                endDate
                salePriceAmount
                startDate
                campaign {
                  campaignGroupId
                  campaignId
                  name
                }
              }
              referencePriceAmount
              salePriceAmount
              startDate
            }
          }
          storeGroupId
        }
        TaxGroup {
          created
          description
          id
          name
          updated
        }
        created
        customAttributes
        description
        dimensions {
          height
          length
          width
        }
        displayDescriptions
        displayNames
        id
        imageUrl
        isActive
        isArchived
        name
        productParentId
        tags
        updated
        weight
      }
    }
  }
`

export default GET_PRODUCT_VARIANT
