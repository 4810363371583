import { createSlice } from "@reduxjs/toolkit"

type InitialState = {
  userGroups: string[]
  userAttributes: {
    email: string
    email_verified: string
    family_name: string
    given_name: string
    sub: string
  }
}

const initialStateValue: InitialState = {
  userGroups: [],
  userAttributes: {
    email: "",
    email_verified: "",
    family_name: "",
    given_name: "",
    sub: ""}
}

const slice = createSlice({
  name: "authentication",
  initialState: initialStateValue,
  reducers: {
    setAuthenticationUserGroups(state, action) {
      state.userGroups = action.payload.userGroups
    },
    setAuthenticationUserAttributes(state, action) {
      state.userAttributes = action.payload.userAttributes
    }}
})

const { actions, reducer } = slice
export const { setAuthenticationUserGroups, setAuthenticationUserAttributes } = actions

export default reducer
