import gql from "graphql-tag"

const GET_QLIRO_ONE_CONFIG = gql`
  query getQliroOneConfig($id: ID!) {
    getQliroOneConfig(id: $id) {
      id
      created
      updated
      baseUrl
      merchantOrderManagementStatusPushUrl
      integratedShipping {
        name
        deliveryCheckoutId
      }
      useLegacyIds
    }
  }
`

export default GET_QLIRO_ONE_CONFIG
