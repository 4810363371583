import gql from "graphql-tag"

export const CREATE_OR_UPDATE_QLIRO_ONE_CONFIG = gql`
  mutation createOrUpdateQliroOneConfig(
    $id: ID!
    $baseUrl: String!
    $merchantOrderManagementStatusPushUrl: String!
    $merchantApiKey: String!
    $merchantApiSecret: String!
    $integratedShipping: InputQliroOneIntegratedShipping
    $useLegacyIds: Boolean
  ) {
    createOrUpdateQliroOneConfig(
      id: $id
      qliroOneConfig: {
        baseUrl: $baseUrl
        merchantOrderManagementStatusPushUrl: $merchantOrderManagementStatusPushUrl
        merchantApiKey: $merchantApiKey
        merchantApiSecret: $merchantApiSecret
        integratedShipping: $integratedShipping
        useLegacyIds: $useLegacyIds
      }
    ) {
      id
    }
  }
`
