import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components/macro"

import { ReactComponent as LogoIcon } from "images/brink-logo-icon.svg"
import { ReactComponent as MenuIcon } from "images/icons/bars.svg"
import { ReactComponent as CloseIcon } from "images/icons/xmark.svg"

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 5rem;
  padding: 0 2rem;
  background-color: ${(p) => p.theme.colors.background};
  border-bottom: 0.1rem solid rgb(223, 227, 232);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 11;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: none;
  }

  svg {
    height: 2.5rem;
  }
`

type Props = {
  sideBarExpanded: boolean
  setSidebarExpanded: Dispatch<SetStateAction<boolean>>
}

export const MobileHeader = ({ sideBarExpanded, setSidebarExpanded }: Props) => (
  <Container>
    <LogoIcon />
    {sideBarExpanded ? (
      <CloseIcon onClick={() => setSidebarExpanded(false)} />
    ) : (
      <MenuIcon onClick={() => setSidebarExpanded(true)} />
    )}
  </Container>
)
