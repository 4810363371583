import gql from "graphql-tag"

const CREATE_STORE_GROUP = gql`
  mutation createStoreGroup($id: ID!, $name: String!, $channelType: ChannelType!, $description: String!, $countryOfSale: String) {
    createStoreGroup(
      id: $id
      storeGroup: { name: $name, description: $description, channelType: $channelType, countryOfSale: $countryOfSale}
    ) {
      id
      name
      description
      channelType
      countryOfSale
    }
  }
`

export default CREATE_STORE_GROUP
