import React, { useState } from "react"
import { Option, PROVIDER_CATEGORY, PROVIDER_TYPE, Query } from "lib/types/common"
import { useQuery } from "@apollo/client"
import SEARCH_STORE_MARKETS from "../../../graphql/queries/store/SearchStoreMarkets"
import esb from "elastic-builder"
import SingleSelect from "../../Ui/Form/SingleSelect"
import ALL_STORE_GROUPS from "../../../graphql/queries/store/AllStoreGroups"
import { StoreGroup, StoreMarketSearchHit } from "lib/types/generated/graphql-types"
import { BlurDiv, Label, StoreMarketsContainer } from "./ConnectedStoreMarkets.styled"
import StoreMarketRow from "./StoreMarketRow"
import AddStoreMarket from "./AddStoreMarket"
import { getCountryName } from "../../../helpers/countries"

type Props = {
  providerId: string
  providerCategory: PROVIDER_CATEGORY
  providerType: PROVIDER_TYPE
}

const ConnectedStoreMarket = ({ providerId, providerCategory, providerType }: Props) => {
  const [selectedStoreGroup, setSelectedStoreGroup] = useState<Option | undefined>()
  const [connectedStoreMarkets, setConnectedStoreMarkets] = useState<StoreMarketSearchHit[]>([])
  const sortedConnectedStoreMarkets = [...connectedStoreMarkets].sort((a, b) => {
    const nameA = getCountryName(a?.countryCode ?? "") ?? ""
    const nameB = getCountryName(b?.countryCode ?? "") ?? ""
    return nameA.localeCompare(nameB)
  })

  const esbQuery =
    providerCategory === PROVIDER_CATEGORY.CART_CAPABILITY
      ? esb.requestBodySearch().query(
          esb
            .nestedQuery()
            .path(providerCategory)
            .query(
              esb
                .nestedQuery()
                .path(`${providerCategory}.${providerType}`)
                .query(
                  esb
                    .boolQuery()
                    .filter([esb.matchQuery(`${providerCategory}.${providerType}.id`, providerId)])
                )
            )
        )
      : esb.requestBodySearch().query(
          esb
            .nestedQuery()
            .path(providerCategory)
            .query(esb.boolQuery().filter([esb.matchQuery(`${providerCategory}.id`, providerId)]))
        )

  const query = esbQuery.toJSON() as Query
  const { loading: storeMarketLoading } = useQuery(SEARCH_STORE_MARKETS, {
    variables: { from: 0, to: 200, size: 200, query: JSON.stringify(query.query) },
    onCompleted: (data) => {
      setConnectedStoreMarkets(data.searchStoreMarkets?.hits)
    }
  })

  const {
    data: storeGroupsData,
    loading: storeGroupLoading,
    refetch
  } = useQuery(ALL_STORE_GROUPS, {
    variables: { from: 0, to: 200, size: 200, query: JSON.stringify(query.query) }
  })
  const storeGroups = storeGroupsData?.getStoreGroups

  if (storeGroupLoading || storeMarketLoading)
    return (
      <BlurDiv>
        <SingleSelect
          label="Store group"
          options={storeGroups?.map((storeGroup: StoreGroup) => ({
            label: storeGroup.name,
            value: storeGroup.id
          }))}
        ></SingleSelect>
      </BlurDiv>
    )

  return (
    <div>
      <SingleSelect
        label="Store group"
        options={storeGroups?.map((storeGroup: StoreGroup) => ({
          label: storeGroup.name,
          value: storeGroup.id
        }))}
        defaultValue={selectedStoreGroup}
        setValue={setSelectedStoreGroup}
        overrideDisabled={true}
      ></SingleSelect>

      <StoreMarketsContainer>
        {selectedStoreGroup && (
          <>
            <AddStoreMarket
              storeMarkets={
                storeGroups.find(
                  (storeGroup: StoreGroup) => storeGroup.id === selectedStoreGroup.value
                ).storeMarkets
              }
              connectedStoreMarkets={connectedStoreMarkets}
              selectedStoreGroup={selectedStoreGroup}
              storeGroups={storeGroups ?? []}
              setConnectedStoreMarkets={setConnectedStoreMarkets}
              providerId={providerId}
              storeGroupId={selectedStoreGroup.value}
              providerType={providerType}
              providerCategory={providerCategory}
            />
            <Label>Connected store markets</Label>
            {sortedConnectedStoreMarkets.length === 0 && <p>- No connected store markets</p>}
            {sortedConnectedStoreMarkets
              ?.filter((storeMarket) => storeMarket.storeGroupId === selectedStoreGroup.value)
              .map((filteredStoreMarket) => (
                <StoreMarketRow
                  key={`${filteredStoreMarket.countryCode}-${filteredStoreMarket.storeGroupId}`}
                  storeMarket={filteredStoreMarket}
                  storeGroups={storeGroups ?? []}
                  providerId={providerId}
                  providerType={providerType}
                  connectedStoreMarkets={connectedStoreMarkets}
                  setConnectedStoreMarkets={setConnectedStoreMarkets}
                  providerCategory={providerCategory}
                  refetch={refetch}
                />
              ))}
          </>
        )}
      </StoreMarketsContainer>
    </div>
  )
}

export default ConnectedStoreMarket
