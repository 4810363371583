import React, { useEffect } from "react"
import { useQuery } from "@apollo/client"
import styled from "styled-components/macro"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import GET_GENERATE_CODES from "graphql/queries/discount/GetGenerateCodes"

import { Loader } from "../../Ui/Loader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import Status from "components/Ui/Status"

import { ReactComponent as CsvIcon } from "images/icons/file-csv.svg"

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 10.5rem;
  right: 3rem;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`

const FieldTitle = styled.span`
  font-weight: bold;
`

const StyledButton = styled(PrimaryButton)`
  margin: 0 auto 3rem;
`

type Props = {
  jobId: string
}

const GeneratedCodesResult = ({ jobId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading, refetch, error } = useQuery(GET_GENERATE_CODES, {
    variables: { jobId }
  })

  const { status, numberOfCodesRequested, numberOfCodesGenerated, prefix, usageLimit, fileUrl, discountCodeRuleId } =
    data?.getGenerateCodes || {}

  useEffect(() => {
    if (status === "COMPLETED") {
      dispatch(
        alertActions.actions.createAlert({
          message: "Discount codes successfully generated",
          type: "success"
        })
      )
      return
    }
    const intervalId = setInterval(() => {
      refetch()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [status])

  return (
    <>
      {loading || (status !== "COMPLETED" && <StyledLoader color={"black"} />)}
      {error ? (
        <ErrorMessage
          message={
            <>
              Error loading job data. Please contact <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      ) : (
        <>
          <Row>
            <FieldTitle>Status:</FieldTitle>
            <Status status={status} />
          </Row>
          <Row>
            <FieldTitle>Number of codes requested:</FieldTitle>
            {numberOfCodesRequested}
          </Row>
          <Row>
            <FieldTitle>Number of codes generated:</FieldTitle>
            {numberOfCodesGenerated}
          </Row>
          <Row>
            <FieldTitle>Prefix:</FieldTitle>
            {prefix}
          </Row>
          <Row>
            <FieldTitle>Usage limit:</FieldTitle>
            {usageLimit}
          </Row>
          <Row>
            <FieldTitle>Job ID:</FieldTitle>
            {jobId}
          </Row>
          <Row>
            <FieldTitle>Rule ID:</FieldTitle>
            {discountCodeRuleId}
          </Row>
          <hr />
          <a href={fileUrl} target="_blank" rel="noreferrer">
            <StyledButton disabled={status !== "COMPLETED"}>
              <CsvIcon /> Download generated codes
            </StyledButton>
          </a>
        </>
      )}
    </>
  )
}

export default GeneratedCodesResult
