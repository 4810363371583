import gql from "graphql-tag"

const ORDERS_TOTAL_HITS_LAST_FIVE_DAYS = gql`
  query searchOrdersLastFiveDays($from: Int!, $size: Int!, $sort: [SearchSorting!], $query1: AWSJSON , $query2: AWSJSON , $query3: AWSJSON , $query4: AWSJSON, $query5: AWSJSON $trackTotalHits: Boolean) {
    query1: searchOrders(from: $from, size: $size, sort: $sort, query: $query1, trackTotalHits: $trackTotalHits) {
      total
    }
    query2: searchOrders(from: $from, size: $size, sort: $sort, query: $query2, trackTotalHits: $trackTotalHits) {
      total
    }
    query3: searchOrders(from: $from, size: $size, sort: $sort, query: $query3, trackTotalHits: $trackTotalHits) {
      total
    }
    query4: searchOrders(from: $from, size: $size, sort: $sort, query: $query4, trackTotalHits: $trackTotalHits) {
      total
    }
    query5: searchOrders(from: $from, size: $size, sort: $sort, query: $query5, trackTotalHits: $trackTotalHits) {
      total
    }
  }
`

export default ORDERS_TOTAL_HITS_LAST_FIVE_DAYS
