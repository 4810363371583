import gql from "graphql-tag"

const ALL_GIFT_CARD_PROVIDERS = gql`
  query getGiftCardProviders {
    getGiftCardProviders {
      id
      type
    }
  }
`

export default ALL_GIFT_CARD_PROVIDERS
