import React, { useState } from "react"
import { Container, Format, InfoWrapper, InputWrapper, UpdateButton } from "./Totals.styled"
import Money from "../../../../../Money"
import DineroFactory from "dinero.js"
import { DeliveryGiftCard, OrderCompensation } from "lib/types/generated/graphql-types"
import { ReactComponent as EditIcon } from "../../../../../../images/icons/gear.svg"
import Input from "../../../../../Ui/Form/Input"
import { ReactComponent as ArrowIcon } from "../../../../../../images/icons/circle-xmark.svg"
import { ReactComponent as UpdateIcon } from "../../../../../../images/icons/rotate-right.svg"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import { getCompensationInput } from "../../Helpers/helpers"
import { Error } from "../../OrderSummary/OrderSummary.styled"
import { handleOrderManagementError } from "../../../Helpers/errors"

type Props = {
  deliveryGiftCard: DeliveryGiftCard
  currencyCode: string
  compensation: OrderCompensation
  refetch: () => void
}

const GiftCardItem = ({ deliveryGiftCard, currencyCode, compensation, refetch }: Props) => {
  const [amount, setAmount] = useState<number>()
  const [expanded, setExpanded] = useState<boolean>(false)

  const compensationGiftCard = compensation?.giftCards?.find(
    (giftCard) => giftCard.giftCardId === deliveryGiftCard.giftCardId
  )

  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
      setAmount(0)
      setExpanded(false)
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const updateOrderCompensation = () => {
    return updateCompensation({
      variables: {
        compensationId: compensation?.id,
        input: {
          ...getCompensationInput(compensation),
          ...(compensation?.giftCards && {
            giftCards: compensation.giftCards.map((giftCard) =>
              giftCard.giftCardId === deliveryGiftCard.giftCardId
                ? {
                    giftCardId: giftCard.giftCardId,
                    amount: amount
                  }
                : { giftCardId: giftCard.giftCardId, amount: giftCard.amount }
            )
          })
        }
      }
    })
  }

  return (
    <Container $loading={loading}>
      <InfoWrapper>
        <span>
          <b>{deliveryGiftCard.giftCardId}:</b>
          <Money
            amount={compensationGiftCard?.amount ?? 0}
            currencyUnit={currencyCode as DineroFactory.Currency}
          />
        </span>
        {expanded ? (
          <ArrowIcon onClick={() => setExpanded(false)} />
        ) : (
          <EditIcon onClick={() => setExpanded(true)} />
        )}
      </InfoWrapper>
      {expanded && (
        <InputWrapper>
          <span>
            <Input
              name="paymentAmount"
              type="number"
              placeholder="New amount in minor units"
              defaultValue={amount}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setAmount(parseInt(e.currentTarget.value))
              }
            />
            <UpdateButton
              disabled={
                (!amount && amount !== 0) || amount < 0 || (amount ?? 0) > deliveryGiftCard.amount
              }
              handleClick={updateOrderCompensation}
            >
              <UpdateIcon />
            </UpdateButton>
          </span>
          <Format>
            Formatted:{" "}
            <Money amount={amount || 0} currencyUnit={currencyCode as DineroFactory.Currency} />
          </Format>
          {(amount ?? 0) > deliveryGiftCard.amount && (
            <Error>{`- Amount can't be higher than total`}</Error>
          )}
        </InputWrapper>
      )}
    </Container>
  )
}

export default GiftCardItem
