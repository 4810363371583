import React, { useState } from "react"
import {
  ProductVariantSearchHit,
  ProductVariantSearchHits
} from "@lib/types/generated/graphql-types"
import dayjs from "dayjs"
import TableRow from "components/Ui/Table/TableRow"
import { ReactComponent as ProductIcon } from "images/icons/bags-shopping.svg"
import placeholder from "images/placeholder.svg"
import {
  Container,
  EmptyList,
  HeaderName,
  Id,
  Name,
  Product,
  ProductImage,
  ProductImageContainer,
  StyledTableHeader
} from "./ProductPreveiw.styled"
import TableBlurLoading from "../../../Ui/Table/TableBlurLoading"
import ErrorMessage from "../../../Ui/Messages/ErrorMessage"
import ShowPreviewVariant from "./ShowPreviewVariant"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

type Props = {
  productData: ProductVariantSearchHits | undefined
  loading: boolean
  searchQuery: string
  error: object | undefined
}

export const ProductPreviewTable = ({ productData, loading, error, searchQuery }: Props) => {
  const [showVariantId, setShowVariantId] = useState("")
  const dispatch = useAppDispatch()
  const tableHeader = (
    <StyledTableHeader>
      <HeaderName>Name</HeaderName>
    </StyledTableHeader>
  )
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const getRows = (productVariant?: ProductVariantSearchHit) =>
    productVariant ? (
      <TableRow
        key={productVariant.id}
        handleOnClick={() => {
          dispatch(showEditSidebar())
          setShowVariantId(productVariant.id)
        }}
      >
        <ProductImageContainer>
          <ProductImage src={productVariant?.imageUrl as string} onError={handleImageError} />
        </ProductImageContainer>
        <Product>
          <Name>{productVariant.name}</Name>
          <Id>{productVariant.id}</Id>
        </Product>
      </TableRow>
    ) : (
      <>
        <ProductImageContainer>
          <ProductImage src={placeholder} />
        </ProductImageContainer>
        <Product>
          <Name>Product name</Name>
          <Id>Product id</Id>
        </Product>
        <div className="hideOnSmallScreens">
          <p>{dayjs("2000-00-00").format("YYYY-MM-DD")}</p>
        </div>
      </>
    )

  if (loading && !searchQuery)
    return (
      <>
        <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getRows()} />
      </>
    )

  return (
    <Container>
      {showVariantId && <ShowPreviewVariant variantId={showVariantId} showParent={true} />}
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading product variants. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {productData && productData?.hits?.length > 0 ? (
        <>
          {tableHeader}
          <div>{productData.hits.map((product) => getRows(product))}</div>
        </>
      ) : (
        <EmptyList>
          <ProductIcon />
          <p> No products found</p>
        </EmptyList>
      )}
    </Container>
  )
}
