import React, { Dispatch, SetStateAction } from "react"
import PrimaryButton from "../../../../../Ui/Buttons/PrimaryButton"
import Input from "../../../../../Ui/Form/Input"
import { ButtonWrapper, Container, PercentageWrapper } from "./ItemRuleDiscountSelector.styled"
import {
  InputDiscountValue,
  InputDiscountValueType,
  InputMoney,
  InputStoreMarket,
  StoreGroup
} from "lib/types/generated/graphql-types"
import SecondaryButton from "../../../../../Ui/Buttons/SecondaryButton"
import BundleFixedAmountInSidebar from "./BundleFixedAmountInSidebar"
import { InputBundleItemRuleWithId } from "lib/types/discount"

type Props = {
  setDiscountValue: Dispatch<SetStateAction<InputDiscountValue>>
  discountValue: InputDiscountValue
  storeGroups: StoreGroup[] | undefined
  addedStoreMarkets: InputStoreMarket[]
  editBundleItemRule?: InputBundleItemRuleWithId
}

const ItemRuleDiscountSelector = ({
  setDiscountValue,
  discountValue,
  storeGroups,
  addedStoreMarkets,
  editBundleItemRule
}: Props) => {
  const updateDiscountValueType = (type: InputDiscountValueType) => {
    setDiscountValue((prev) => ({ ...prev, type }))
  }

  const updateFixedDiscounts = (fixedDiscounts: InputMoney[]) => {
    setDiscountValue({ ...discountValue, fixedDiscounts } as InputDiscountValue)
  }

  const getButton = (type: InputDiscountValueType) => {
    if (type === InputDiscountValueType.Relative) {
      return discountValue.type === type ? (
        <PrimaryButton>Percentage</PrimaryButton>
      ) : (
        <SecondaryButton
          handleClick={() => updateDiscountValueType(InputDiscountValueType.Relative)}
        >
          Percentage
        </SecondaryButton>
      )
    }
    return discountValue.type === type ? (
      <PrimaryButton>Fixed amount</PrimaryButton>
    ) : (
      <SecondaryButton handleClick={() => updateDiscountValueType(InputDiscountValueType.Fixed)}>
        Fixed amount
      </SecondaryButton>
    )
  }

  const defaultPercentageValue = () => {
    if (
      editBundleItemRule?.discountValue?.type === InputDiscountValueType.Relative &&
      editBundleItemRule?.discountValue?.percentage
    ) {
      return editBundleItemRule?.discountValue?.percentage
    }
    if (discountValue.type === InputDiscountValueType.Relative && discountValue?.percentage) {
      return discountValue.percentage
    }
    return undefined
  }

  const active = () =>
    (discountValue.type === InputDiscountValueType.Relative &&
      discountValue?.percentage &&
      discountValue?.percentage > 0) ||
    (discountValue.type === InputDiscountValueType.Fixed &&
      discountValue?.fixedDiscounts &&
      discountValue?.fixedDiscounts?.length > 0) ||
    false


  return (
    <Container $active={active()}>
      <ButtonWrapper>
        {getButton(InputDiscountValueType.Relative)}
        {getButton(InputDiscountValueType.Fixed)}
      </ButtonWrapper>
      {discountValue.type === InputDiscountValueType.Fixed ? (
        <BundleFixedAmountInSidebar
          storeGroups={storeGroups}
          addedStoreMarkets={addedStoreMarkets}
          fixedDiscounts={discountValue?.fixedDiscounts}
          updateFixedDiscounts={updateFixedDiscounts}
        />
      ) : (
        <PercentageWrapper>
          <h4>Define a percentage discount to be subtracted from base price</h4>
          <Input
            name="percentage"
            type="number"
            placeholder="Percentage, 1 - 100"
            defaultValue={defaultPercentageValue()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setDiscountValue({ ...discountValue, percentage: parseInt(event.target.value) })
            }
          />
        </PercentageWrapper>
      )}
    </Container>
  )
}

export default ItemRuleDiscountSelector
