import React, { useState } from "react"
import { v4 as uuid } from "uuid"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { useMutation } from "@apollo/client"
import { ExternalEventDestinationApIs } from "@lib/types/generated/graphql-types"

import DELETE_EXTERNAL_EVENTS_DESTINATION_API from "graphql/mutations/settings/externalEvents/DeleteExternalEventsDestinationAPI"

import { Container, EmptyList, Wrapper } from "components/Ui/Table/Shared.styled"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"

import { ReactComponent as Code } from "images/icons/laptop-code.svg"
import Popup from "components/Ui/Popup"
import Status from "../Ui/Status"
import { Endpoint, Name, StyledIconTableHeader, StyledTableRow } from "./DestinationApisTable.styled"
import ActionButton from "../Ui/Table/ActionButton"
import CopyButton from "../Ui/CopyButton"

type Props = {
  apiDestinations: ExternalEventDestinationApIs
  loading: boolean
  error: object | undefined
  refetch: () => void
}

const DestinationApisTable = ({ apiDestinations, loading, error, refetch }: Props) => {
  const dispatch = useDispatch()
  const [deleteItem, setDeleteItem] = useState<string>("")

  const getApiDestinationStatus = (status: boolean) => (status ? "ACTIVE" : "NOT ACTIVE")

  const [deleteApisDestination, { loading: deleteLoading }] = useMutation(
    DELETE_EXTERNAL_EVENTS_DESTINATION_API,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: `API destionation ${deleteItem} is now deleted`,
            type: "success"
          })
        )
        setDeleteItem("")
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading API destinations. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}

        {deleteItem && (
          <Popup
            title="Delete API destination?"
            subtitle="Are you sure you want to delete the following:"
            buttonText="Yes, delete"
            item={{ itemId: undefined, itemName: deleteItem }}
            loading={deleteLoading}
            type="delete"
            handleOkClick={() =>
              deleteApisDestination({
                variables: {
                  destName: deleteItem
                }
              })
            }
            handleCloseClick={() => setDeleteItem("")}
          />
        )}

        {loading && !error ? (
          <BrinkLoader />
        ) : (
          <>
            {apiDestinations?.apiDestinationsDetails.length === 0 ? (
              <EmptyList>
                <Code />
                <p>No API destinations added</p>
              </EmptyList>
            ) : (
              <>
                <StyledIconTableHeader>
                  <Name>Name</Name>
                  <div className="hideOnSmallScreens">Endpoint</div>
                  <div>Status</div>
                </StyledIconTableHeader>
                {apiDestinations?.apiDestinationsDetails.map((apiDestination) => (
                  <StyledTableRow key={uuid()}>
                    <Code />
                    <div>
                      <Name>{apiDestination.name}</Name>
                    </div>
                    <Endpoint className="hideOnSmallScreens">
                      <div
                        data-tooltip-id="tooltip"
                        data-tooltip-content={apiDestination.endpoint}
                        data-tooltip-delay-hide={100}
                      >
                        {apiDestination.endpoint?.slice(0,30)}{"..."}
                      </div>
                      <CopyButton string={apiDestination.endpoint}/>
                    </Endpoint>
                    <div>
                      <Status status={getApiDestinationStatus(apiDestination.active)} />
                    </div>
                    <ActionButton
                      handleOnClick={() => setDeleteItem(apiDestination.name)}
                      mode={"delete"}
                    />
                  </StyledTableRow>
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default DestinationApisTable
