import React from "react"
import { Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"
import { CapabilityProvider, InputUpdateStoreMarket } from "@lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import SingleSelect from "../../Ui/Form/SingleSelect"
import { ALL_BONUS_PROVIDERS } from "../../../graphql/queries/config/AllBonusProviders"

const InputSection = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

type Props = {
  defaultValue: string | undefined
  control: Control
  errors: FieldErrors
  setValue: UseFormSetValue<InputUpdateStoreMarket>
}

export const BonusSelect = ({ defaultValue, control, errors, setValue }: Props) => {
  const { data: bonusData } = useQuery(ALL_BONUS_PROVIDERS)
  const bonusConfigOptions =
    bonusData?.getBonusConfigs.length > 0
      ? bonusData?.getBonusConfigs?.map((config: CapabilityProvider) => ({
        label: config.id,
        value: config.id
      }))
      : []
  const options = [{ label: "Select bonus config...", value: undefined }, ...bonusConfigOptions]
  const updateBonusConfig = (bonusConfig: Common.Option) => {
    setValue("cartCapabilities.bonus", { id: bonusConfig?.value })
  }

  if (bonusConfigOptions.length === 0) return null

  return (
    <InputSection>
      <Label>Bonus</Label>
      <Controller
        name="cartCapabilities.bonus"
        render={() => (
          <SingleSelect
            defaultValue={defaultValue ? { label: defaultValue, value: defaultValue } : options[0]}
            options={options}
            setValue={updateBonusConfig}
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name="cartCapabilities.bonus" />
    </InputSection>
  )
}

export default BonusSelect
