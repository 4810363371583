import React from "react"
import { Container, Divider, Provider, Row } from "./Totals.styled"

import DineroFactory from "dinero.js"

import { OrderCompensationTotals } from "@lib/types/generated/graphql-types"
import Money from "../../Money"
import { Loader } from "../../Ui/Loader"

type Props = {
  totals: OrderCompensationTotals
  currencyCode: string
  label: string
}

const Totals = ({ totals, currencyCode, label }: Props) => (
  <Container>
    {totals ? (
      <>
          <Row>
            <Provider>Payment total</Provider> -{" "}
            <Money
              amount={totals.grandTotal - totals.giftCardTotal - totals.bonusTotal}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </Row>
        {totals.bonusTotal > 0 && (
          <Row>
            <Provider>Bonus total</Provider> -{" "}
            <Money
              amount={totals.bonusTotal}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </Row>
        )}
        {totals.giftCardTotal > 0 && (
          <Row>
            <Provider>Gift card total</Provider> -{" "}
            <Money
              amount={totals.giftCardTotal}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </Row>
        )}
        <Divider />
        <Row>
          <h3>{label}</h3>{" "}
          <h3>
            <Money
              amount={totals.grandTotal}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </h3>
        </Row>
      </>
    ) : (
      <Loader />
    )}
  </Container>
)

export default Totals
