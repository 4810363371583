import React, { Dispatch, SetStateAction, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputAvardaConfig } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"

import { ReactComponent as Logo } from "images/providers/avarda-logo.svg"
import GET_AVARDA_CONFIG from "../../../../graphql/queries/config/Providers/Avarda/GetAvardaConfig"
import { CREATE_OR_UPDATE_AVARDA_CONFIG } from "../../../../graphql/mutations/config/Providers/Avarda/CreateOrUpdateAvardaConfig"
import { VALIDATE_AVARDA } from "../../../../graphql/queries/config/Providers/Avarda/ValidateQliroOneConfig"
import { DELETE_AVARDA_CONFIG } from "../../../../graphql/mutations/config/Providers/Avarda/DeleteAvardaConfig"
import { AvardaConfigInputs } from "../../../../components/Config/Avarda/AvardaConfigInputs"

type Props = {
  refetch: () => void
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

interface ExtendedInputAvardaConfig {
  id: string
  avardaConfig: InputAvardaConfig
}

export const EditAvardaConfig = ({ refetch, editId, setEditId }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const { data } = useQuery(GET_AVARDA_CONFIG, {
    variables: { id: editId }
  })
  const closeSidebar = () => {
    setEditId("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputAvardaConfig>({ mode: "onBlur" })

  const [updateAvardaConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_AVARDA_CONFIG,
    {
      onCompleted: () => {
        dispatch(hideEditSidebar())
        reset(avardaConfig)
        dispatch(
          alertActions.actions.createAlert({
            message: "Avarda configuration successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )
  const avardaConfig = data?.getAvardaConfig
  const { id } = avardaConfig

  const [validateAvarda, { loading }] = useLazyQuery(VALIDATE_AVARDA)

  const onSubmit: SubmitHandler<ExtendedInputAvardaConfig> = (data) => {
    const {baseUrl, authUrl, clientSecret, clientId, shippingBroker} = data.avardaConfig
    validateAvarda({
      variables: {
        input: {
          baseUrl,
          authUrl,
          clientSecret,
          clientId,
        }
      }
    }).then((response) => {
      if (response.data.Configuration.validate.avarda.valid) {
        updateAvardaConfig({
          variables: {
            id: editId,
            avardaConfig: {
              baseUrl,
              authUrl,
              clientId,
              clientSecret,
              ...(shippingBroker?.name && { shippingBroker }),
            }
          }
        })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputAvardaConfig> = (data) => {
    const {baseUrl, authUrl, clientSecret, clientId, shippingBroker} = data.avardaConfig

    updateAvardaConfig({
      variables: {
        id: editId,
        avardaConfig: {
          baseUrl,
          authUrl,
          clientId,
          clientSecret,
          ...(shippingBroker?.name && { shippingBroker }),
        }
      }
    })
    setValidationError(false)
  }

  if (!avardaConfig) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader icon={<Logo />} title={id} id={id} cancelEvent={() => closeSidebar()}>
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
          <DeleteButton
            id={id}
            name={id}
            entity={"Avarda configuration"}
            redirectUrl="/settings/providers/avarda"
            mutation={DELETE_AVARDA_CONFIG}
            refetch={refetch}
            postDeleteFunction={() => setEditId("")}
            secureDelete={true}
          />
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Avarda. Do you want to save the Avarda config anyway?"
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <AvardaConfigInputs
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValues={avardaConfig}
              showId={false}
            />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={avardaConfig.id}
              providerType={PROVIDER_TYPE.AVARDA}
              providerCategory={PROVIDER_CATEGORY.PAYMENT}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}
