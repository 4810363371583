import { OrderLineModificationCreateData, OrderLineModificationUpdateData } from "@lib/types/generated/graphql-types"

export const updateOutputToInput = (data: OrderLineModificationUpdateData) => {
  return {
    description: data.description,
    id: data.id,
    name: data.name,
    productVariantId: data.productVariantId,
    productParentId: data.productParentId,
    taxGroupId: data.taxGroupId,
    ...( data.imageUrl && {imageUrl: data.imageUrl}),
    price: {
      basePriceAmount: data?.price?.basePriceAmount,
      quantity: data.price?.quantity,
      distributedTotalPriceAmount: data?.price?.distributedTotalPriceAmount,
      salePriceAmount: data?.price?.salePriceAmount,
      taxPercentage: data?.price?.taxPercentage,
      taxPercentageDecimals: data?.price?.taxPercentageDecimals,
      totalPriceAmount: data?.price?.totalPriceAmount
    }
  }
}

export const createOutputToInput = (data: OrderLineModificationCreateData) => {
  return {
    description: data.description,
    name: data.name,
    productVariantId: data.productVariantId,
    productParentId: data.productParentId,
    taxGroupId: data.taxGroupId,
    ...( data.imageUrl && {imageUrl: data.imageUrl}),
    basePriceAmount: data?.basePriceAmount,
    quantity: data?.quantity,
    distributedTotalPriceAmount: data?.distributedTotalPriceAmount,
    salePriceAmount: data?.salePriceAmount,
    taxPercentage: data?.taxPercentage,
    taxPercentageDecimals: data?.taxPercentageDecimals,
    totalPriceAmount: data?.totalPriceAmount
  }
}
