import React from "react"
import * as graphql from "@lib/types/generated/graphql-types"
import { Container } from "./OrderHistory.styled"
import OrderHistoryEntry from "./OrderHistoryEntry"

type Props = {
  orderHistory: graphql.OrderHistory
  currencyCode: string
  order: graphql.Order
}

const OrderHistory = ({ orderHistory, currencyCode,order}: Props) => {
  const entryKey = (e: graphql.OrderHistoryLogEntry) => `${e.operationId}-${e.timestamp}`
  return (
    <Container>
      {[...orderHistory.history].reverse().map((entry) => (
        <OrderHistoryEntry
          entry={entry}
          key={entryKey(entry)}
          currencyCode={currencyCode}
          deliveries={order.Deliveries}
          refunds={order.Refunds}
        />
      ))}
    </Container>
  )
}

export default OrderHistory
