import React from "react"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { OrderCancellation } from "@lib/types/generated/graphql-types"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import { useQuery } from "@apollo/client"
import Status from "../../../Ui/Status"
import { Container, Label, ProviderStatus } from "../../Updates/Shared.styled"
import GET_CANCELLATION from "../../../../graphql/queries/order/GetCancellation"

type Props = {
  pendingCancellation: OrderCancellation
  orderRefetch: () => void
}

const PendingCancellation = ({ pendingCancellation, orderRefetch }: Props) => {
  const { data, refetch, loading } = useQuery(GET_CANCELLATION, {
    variables: { cancellationId: pendingCancellation?.id }
  })

  const cancellation = data?.Order?.cancellation as OrderCancellation
  if (!cancellation) return null

  return (
    <Container>
      <h4>Pending cancellation #{cancellation.id}</h4>
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>{" "}
          <Status status={cancellation.paymentProvider.status?.current ?? ""} />
        </div>
        {cancellation?.shippingProvider && (
          <div>
            <Label>Shipping status:</Label>
            <Status status={cancellation?.shippingProvider?.status?.current ?? ""} />
          </div>
        )}
        {cancellation?.bonusProvider && (
          <div>
            <Label>Bonus status:</Label>
            <Status status={cancellation?.bonusProvider?.status?.current ?? ""} />
          </div>
        )}
        {cancellation?.giftCardProvider && (
          <div>
            <Label>Gift card status:</Label>
            <Status status={cancellation?.giftCardProvider?.status?.current ?? ""} />
          </div>
        )}
      </ProviderStatus>
      <PrimaryButton
        handleClick={() => {
          refetch()
          orderRefetch()
        }}
        loading={loading}
      >
        <RefreshIcon />
        Refresh status
      </PrimaryButton>
    </Container>
  )
}

export default PendingCancellation
