import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"
import { v4 as uuidv4 } from "uuid"
import Input from "components/Ui/Form/Input"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import SEARCH_PRODUCT_PARENTS from "../../../../../graphql/queries/product/SearchProductParents"
import { ProductParentSearchHit } from "@lib/types/generated/graphql-types"
import ProductParent from "./ProductParent"
import { ConditionsAdd, Container, Flex, Hit, HitCount, Hits, Id, Name, NotFound } from "./AddProductParents.styled"

type Props = {
  updateProductParents: Dispatch<SetStateAction<string[]>>
  productParents: string[]
}

export const AddProductParents = ({ updateProductParents, productParents }: Props) => {
  const [searchInput, setSearchInput] = useState("")
  const [productParentsResult, setProductParentsResult] = useState([])

  const referenceSearchQuery = esb.requestBodySearch().query(
    esb
      .boolQuery()
      .must(esb.termQuery("isActive", true))
      .must([
        esb
          .queryStringQuery(searchInput.trim() + "*")
          .defaultOperator("AND")
          .analyzeWildcard(true)
      ])
  )

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 20,
    query: JSON.stringify(query.query)
  }

  const [searchProductParents] = useLazyQuery(SEARCH_PRODUCT_PARENTS, {
    variables
  })

  const addProductParent = (parentId: string) => {
    const copy = [...productParents]
    copy.push(parentId)
    updateProductParents(copy)
  }
  const removeProductParent = (parentId: string) => {
    updateProductParents(productParents.filter((parent) => parent !== parentId))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchProductParents({
          variables
        }).then((response) => {
          setProductParentsResult(response?.data?.searchProductParents.hits)
        })
      },
      searchInput === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [searchInput])

  return (
    <Container $active={productParents.length > 0 }>
      <ConditionsAdd>
        <Input
          name={"search"}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value)
          }}
          value={searchInput}
          placeholder="Search product parents"
          label="Include product parents:"
        />
        <>
          {searchInput !== "" && (
            <Hits>
              <HitCount>{productParentsResult.length} results</HitCount>
              <ul>
                {productParentsResult.length > 0 ? (
                  productParentsResult.map((hit: ProductParentSearchHit) => (
                    <Hit key={uuidv4()}>
                      <Flex>
                        <Name>{hit.name}</Name>
                        <Id>{hit.id}</Id>
                      </Flex>
                      <PrimaryButton
                        handleClick={() => {
                          addProductParent(hit.id)
                          setSearchInput("")
                        }}
                        disabled={productParents.includes(hit.id)}
                      >
                        Add
                      </PrimaryButton>
                    </Hit>
                  ))
                ) : (
                  <NotFound>No search results found...</NotFound>
                )}
              </ul>
            </Hits>
          )}
        </>
      </ConditionsAdd>
      {productParents.map((productParent) => (
        <ProductParent
          key={productParent}
          productParentId={productParent}
          removeProductParent={removeProductParent}
        />
      ))}
    </Container>
  )
}
