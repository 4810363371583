import styled from "styled-components/macro"

type StyledProps = {
  $checked: boolean
}

export const Container = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    margin: 0 0.8rem 0 0.1rem;
    border: 0.1rem solid #ccc;
    background: ${(p) => p.theme.colors.white};

    &:after {
      display: ${(p) => (p.$checked ? "block" : "none")};
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      top: 0.4rem;
      left: 0.4rem;
      background: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`
