import { store } from "lib/store"

const SUPER_USER = "brinkcommerce-superuser"
const USER_ADMIN = "brinkcommerce-useradmin"
const CAMPAIGN_USER = "brinkcommerce-campaignuser"
const READ_ONLY_USER = "brinkcommerce-readonly"

export const isSuperUser = () => {
  const userGroups = store.getState().auth.userGroups
  return userGroups?.includes(SUPER_USER)
}

export const isUserAdmin = () => {
  const userGroups = store.getState().auth.userGroups
  return userGroups?.includes(USER_ADMIN)
}

export const isCampaignUser = () => {
  const userGroups = store.getState().auth.userGroups
  return userGroups?.includes(CAMPAIGN_USER)
}

export const isReadOnlyUser = () => {
  const userGroups = store.getState().auth.userGroups
  return userGroups?.includes(READ_ONLY_USER)
}
