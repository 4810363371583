import React from "react"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { OrderDelivery } from "@lib/types/generated/graphql-types"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import { useQuery } from "@apollo/client"
import GET_DELIVERY from "../../../../graphql/queries/order/GetDelivery"
import Status from "../../../Ui/Status"
import { Label } from "../../Updates/Shared.styled"
import { Container, ProviderStatus } from "./Shared.styled"

type Props = {
  pendingDelivery: OrderDelivery
  orderRefetch: () => void
  orderLoading: boolean
}

const PendingDelivery = ({ pendingDelivery, orderRefetch, orderLoading }: Props) => {
  const { data, refetch, loading } = useQuery(GET_DELIVERY, {
    variables: { deliveryId: pendingDelivery?.id },
    fetchPolicy: "network-only"
  })

  const delivery = data?.Order?.delivery as OrderDelivery
  if (!delivery) return null

  return (
    <Container>
      <h4>Pending delivery #{pendingDelivery.id}</h4>
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>{" "}
          <Status status={delivery.paymentProvider.status?.current ?? ""} />
        </div>
        {delivery?.shippingProvider && (
          <div>
            <Label>Shipping status:</Label>
            <Status status={delivery?.shippingProvider?.status?.current ?? ""} />
          </div>
        )}
        {delivery?.bonusProvider && (
          <div>
            <Label>Bonus status:</Label>
            <Status status={delivery?.bonusProvider?.status?.current ?? ""} />
          </div>
        )}
        {delivery?.giftCardProvider && (
          <div>
            <Label>Gift card status:</Label>
            <Status status={delivery?.giftCardProvider?.status?.current ?? ""} />
          </div>
        )}
      </ProviderStatus>
      <PrimaryButton
        handleClick={() => {
          refetch()
          orderRefetch()
        }}
        loading={loading || orderLoading}
      >
        <RefreshIcon />
        Refresh status
      </PrimaryButton>
    </Container>
  )
}

export default PendingDelivery
