import React, { useState } from "react"
import { ApiClient, ApiResource } from "lib/types/generated/graphql-types"
import { useDispatch } from "react-redux"
import { useLazyQuery, useQuery } from "@apollo/client"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import ALL_API_CLIENTS from "graphql/queries/apiConfig/AllApiClients"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import ApiClientTable from "components/Settings/ApiClients/ApiClientTable"
import { CreateApiClient } from "./CreateApiClient"
import UpdateApiClient from "./UpdateApiClient"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { Notice, ShowMoreWrapper } from "./ApiClients.styled"
import GET_API_RESOURCES from "../../../graphql/queries/apiConfig/GetApiResources"
import CopyButton from "../../../components/Ui/CopyButton"
import { useConfig } from "../../../configuration/useConfig"

const MAXIMUM_API_CLIENTS = 15

export const ApiClients = () => {
  const [editApiClient, setEditApiClient] = useState<string>("")
  const [apiClients, setApiClients] = useState<ApiClient[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)
  const {customerName, awsRegion} = useConfig().config
  const { data } = useQuery(GET_API_RESOURCES)

  const tokenUrl = `https://management.${awsRegion}.${customerName}.brinkcommerce.io/auth/oauth2/token`

  const availableScopes = data?.apiResources
    ?.map((r: ApiResource) =>
      r?.scopes?.filter((scope) => scope.name.endsWith("read") || scope.name.endsWith("write"))
    )
    .flat()

  const [getApiClients, { loading: fetchMoreLoading, error: fetchMoreError }] =
    useLazyQuery(ALL_API_CLIENTS)
  const dispatch = useDispatch()

  const { refetch, error, loading } = useQuery(ALL_API_CLIENTS, {
    variables: { limit: 20 },
    onCompleted: (data) => {
      setApiClients(data?.apiClients?.apiClients)
      setNextToken(data?.apiClients?.nextToken)
    }
  })

  const fetchMoreApiClients = () => {
    getApiClients({ variables: { limit: 20, token: nextToken } }).then((result) => {
      setApiClients([...apiClients, ...result.data.apiClients.apiClients])
      setNextToken(result?.data?.apiClients?.nextToken)
    })
  }

  return (
    <>
      {!editApiClient && (
        <CreateApiClient
          refetch={refetch}
          setEditApiClient={setEditApiClient}
          availableScopes={availableScopes}
          tokenUrl={tokenUrl}
        />
      )}
      <PageHeader
        title="API clients"
        description="Manage API clients connected to Brink Commerce"
        hasTabs={true}
      >
        <PrimaryButton
          handleClick={() => dispatch(showEditSidebar())}
          disabled={apiClients.length >= 15}
        >
          <PlusIcon />
          Create new client
        </PrimaryButton>
      </PageHeader>
      {editApiClient && (
        <UpdateApiClient
          availableScopes={availableScopes}
          editApiClient={editApiClient}
          setEditApiClient={setEditApiClient}
          refetch={refetch}
        />
      )}
      {loading && !nextToken ? (
        <BrinkLoader />
      ) : (
        <>
          <Notice>
            <p>Token url:{" "}
              <b>
                {tokenUrl}
                <CopyButton string={tokenUrl}/>
              </b> </p>
          </Notice>
          <Notice>
            <p>Api clients used:{" "}
            <b>
              {apiClients.length} / {MAXIMUM_API_CLIENTS}
            </b>{" "}</p>
          </Notice>
          <ApiClientTable
            apiClients={apiClients}
            error={error || fetchMoreError}
            editId={editApiClient}
            setEditId={setEditApiClient}
          />
        </>
      )}

      {nextToken && (
        <ShowMoreWrapper>
          <PrimaryButton loading={fetchMoreLoading} handleClick={() => fetchMoreApiClients()}>
            Show more clients
          </PrimaryButton>
        </ShowMoreWrapper>
      )}
    </>
  )
}
