import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTypedSelector } from "lib/store"
import { selectCapabilities, selectCart } from "lib/store/services/brink/selectors"
import { useStartCheckoutMutation } from "lib/store/services/brink/actions"
import { useConfig } from "configuration/useConfig"
import Select, { components, OptionProps, SingleValueProps } from "react-select"
import { Common } from "lib/types"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { StoreGroup } from "@lib/types/generated/graphql-types"
import { useLazyQuery } from "@apollo/client"
import { getCountryName } from "helpers/countries"
import { getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"
import { getProviderIcon } from "helpers/providers"

import GET_KLARNA_CHECKOUT_CONFIG from "graphql/queries/config/GetKlarnaCheckoutConfig"
import { GET_WALLEY_CHECKOUT_CONFIG } from "graphql/queries/config/Providers/Walley/GetWalleyCheckoutConfig"
import GET_QLIRO_ONE_CONFIG from "graphql/queries/config/Providers/Qliro/GetQliroOneConfig"

import {
  CartHeader,
  EmptyContainer,
  ButtonWrapper,
  CartContainer,
  CartItems,
  Label,
  IconContainer
} from "./Cart.styled"
import { customStyles, SelectContainer } from "components/Ui/Form/SingleSelect.styled"

import CartItem from "./CartItem"
import Totals from "./Totals"
import Discount from "./Discount"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import { NoticeBanner } from "components/Ui/Messages/NoticeBanner"
import FlagIcon from "components/Ui/FlagIcon"

import { ReactComponent as StoreIcon } from "images/icons/store.svg"

type Props = {
  setTermsUrl: Dispatch<SetStateAction<string>>
  termsUrl: string
  storeGroups: StoreGroup[]
  checkoutUrl: string
}

const OptionLayout = ({ label, value }: Common.Option) => (
  <>
    <IconContainer>{getProviderIcon(label)}</IconContainer>
    <span>
      <b>{getPaymentProviderDisplayName(label)}</b> | {value}
    </span>
  </>
)

const SingleValue = (props: SingleValueProps<Common.Option>) => (
  <components.SingleValue {...props}>
    <OptionLayout label={props.data.label} value={props.data.value} />
  </components.SingleValue>
)

const Option = (props: OptionProps<Common.Option>) => (
  <components.Option {...props}>
    <OptionLayout label={props.data.label} value={props.data.value} />
  </components.Option>
)

export const Cart = ({ setTermsUrl, termsUrl, storeGroups, checkoutUrl }: Props) => {
  const cart = useTypedSelector(selectCart)
  const hasItems = (cart?.items.length ?? 0) > 0
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<Common.Option | null>()
  const [selectedShippingProvider, setSelectedShippingProvider] = useState<Common.Option | null>()
  const [startCheckout, { isLoading, error }] = useStartCheckoutMutation()
  const { awsRegion, customerName } = useConfig().config
  const capabilities = useTypedSelector(selectCapabilities)
  const [urlError, setUrlError] = useState(false)
  const [hideShipping, setHideShipping] = useState(false)
  const dispatch = useDispatch()

  const isValidHttpUrl = (string: string) => {
    try {
      const newUrl = new URL(string)
      return newUrl.protocol === "https:"
    } catch (err) {
      return false
    }
  }

  const [getKlarnaCheckout] = useLazyQuery(GET_KLARNA_CHECKOUT_CONFIG)
  const [getWalleyCheckout] = useLazyQuery(GET_WALLEY_CHECKOUT_CONFIG)
  const [getQliroOne] = useLazyQuery(GET_QLIRO_ONE_CONFIG)

  const getPaymentProviders = () =>
    capabilities?.paymentProviders?.filter(
      (provider) =>
        provider.name === "KlarnaCheckout" ||
        provider.name === "Adyen" ||
        provider.name === "WalleyCheckout" ||
        provider.name === "QliroOne"
    )

  const getShippingProviders = () =>
    capabilities?.shippingProviders?.filter((provider) => provider.name === "Ingrid")

  const updatePaymentProvider = (provider: Common.Option) => {
    setHideShipping(false)
    const paymentProvider = provider.label
    if (paymentProvider === "KlarnaCheckout") {
      getKlarnaCheckout({ variables: { id: provider.value } }).then((res) => {
        const klarnaCheckoutProvider = res.data.getKlarnaCheckoutConfig
        if (klarnaCheckoutProvider.klarnaShippingAssistantEnabled) {
          setHideShipping(true)
          setSelectedShippingProvider(null)
        }
      })
    }
    if (paymentProvider === "WalleyCheckout") {
      getWalleyCheckout({ variables: { id: provider.value } }).then((res) => {
        const walleyCheckoutProvider = res.data.getWalleyCheckoutConfig
        if (walleyCheckoutProvider.deliveryModuleEnabled) {
          setHideShipping(true)
          setSelectedShippingProvider(null)
        }
      })
    }
    if (paymentProvider === "QliroOne") {
      getQliroOne({ variables: { id: provider.value } }).then((res) => {
        const qliroOneProvider = res.data.getQliroOneConfig
        if (qliroOneProvider.integratedShipping) {
          setHideShipping(true)
          setSelectedShippingProvider(null)
        }
      })
    }
    setSelectedPaymentProvider(provider)
  }

  const getPaymentName = (id: string) =>
    capabilities?.paymentProviders?.find((provider) => provider.id === id)?.name

  const getShippingName = (id: string) =>
    capabilities?.shippingProviders?.find((provider) => provider.id === id)?.name

  const handleStartCheckout = () => {
    if (!isValidHttpUrl(termsUrl ?? "")) {
      setUrlError(true)
      return
    }

    const paymentProvider = {
      id: selectedPaymentProvider?.value ?? "",
      name: getPaymentName(selectedPaymentProvider?.value ?? "") ?? ""
    }
    const shippingProvider = {
      id: selectedShippingProvider?.value ?? "",
      name: getShippingName(selectedShippingProvider?.value ?? "") ?? ""
    }

    if (selectedPaymentProvider)
      startCheckout({
        awsRegion,
        customerName,
        paymentProvider,
        ...(selectedShippingProvider && { shippingProvider })
      }).then(() => {
        setSelectedPaymentProvider(null)
        setSelectedShippingProvider(null)
        setUrlError(false)
      })
  }

  useEffect(() => {
    if (error) {
      if ("status" in error) {
        const errMsg = "error" in error ? error.error : JSON.stringify(error?.data?.error)
        dispatch(
          alertActions.actions.createAlert({
            type: "error",
            message: errMsg
          })
        )
      }
    }
  }, [error])

  return (
    <>
      {cart && hasItems ? (
        <>
          <h2>New order draft</h2>
          <CartContainer>
            <CartHeader>
              <div>
                <StoreIcon />
                <b>{storeGroups.find((storeGroup) => storeGroup.id === cart.storeGroupId)?.name}</b>
              </div>
              <div>
                <FlagIcon countryCode={cart.countryCode ?? "dummy"} />{" "}
                <b>{getCountryName(cart.countryCode ?? "")}</b>
              </div>
            </CartHeader>
            <Label>Cart items ({cart?.items.length ?? 0})</Label>
            <CartItems>
              {cart.items?.map((item) => (
                <CartItem currencyCode={cart.currencyCode} key={item.id} item={item} />
              ))}
            </CartItems>
            <Label>Discount</Label>
            {<Discount />}
            <>
              <div>
                <Label>Payment</Label>
                {capabilities && (
                  <SelectContainer invalid={false}>
                    <Select
                      placeholder="Select payment"
                      value={selectedPaymentProvider}
                      onChange={(e) => updatePaymentProvider(e as Common.Option)}
                      options={getPaymentProviders()?.map((provider) => ({
                        label: provider.name,
                        value: provider.id
                      }))}
                      components={{ Option, SingleValue }}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  </SelectContainer>
                )}
              </div>
              {getPaymentName(selectedPaymentProvider?.value ?? "") === "Adyen" && (
                <NoticeBanner>
                  <b>{checkoutUrl}</b> must be set as allowed origin in Adyen to be able to complete
                  the purchase.
                </NoticeBanner>
              )}
              {!hideShipping && (
                <div>
                  <Label>Shipping</Label>
                  {cart && hasItems && capabilities && (
                    <SelectContainer invalid={false}>
                      <Select
                        options={getShippingProviders()?.map((provider) => ({
                          label: provider.name,
                          value: provider.id
                        }))}
                        components={{ Option, SingleValue }}
                        value={selectedShippingProvider}
                        placeholder="Select shipping"
                        onChange={setSelectedShippingProvider}
                        styles={customStyles}
                        isSearchable={false}
                      />
                    </SelectContainer>
                  )}
                </div>
              )}
              {capabilities && (
                <>
                  <div>
                    <Label>Terms and conditions URL</Label>
                    <Input
                      name="termsUrl"
                      defaultValue="https://"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setUrlError(false)
                        setTermsUrl(e.target.value)
                      }}
                      errors={urlError ? { termsUrl: { message: "Must be https:// format" } } : {}}
                    />
                  </div>

                  <Totals currencyCode={cart.currencyCode} totals={cart.totals} />

                  <ButtonWrapper>
                    <PrimaryButton
                      disabled={
                        !selectedPaymentProvider ||
                        !termsUrl ||
                        (!hideShipping && !selectedShippingProvider)
                      }
                      handleClick={handleStartCheckout}
                      loading={isLoading}
                    >
                      Create checkout URL
                    </PrimaryButton>
                  </ButtonWrapper>
                </>
              )}
            </>
          </CartContainer>
        </>
      ) : (
        <>
          <h2>New order draft</h2>
          <EmptyContainer>
            <h2>Empty cart</h2>
            <p>Add items or import from an existing order to create a new order draft.</p>
          </EmptyContainer>
        </>
      )}
    </>
  )
}
