import gql from "graphql-tag"

export const CREATE_OR_UPDATE_WALLEY_CHECKOUT_CONFIG = gql`
  mutation createOrUpdateWalleyCheckoutConfig(
    $id: ID!
    $storeId: String!
    $baseUrl: String!
    $scope: String!
    $profileName: String
    $clientId: String!
    $secret: String!
    $deliveryModuleEnabled: Boolean!
  ) {
    createOrUpdateWalleyCheckoutConfig(
      id: $id
      walleyCheckoutConfig: {
        storeId: $storeId
        baseUrl: $baseUrl
        scope: $scope
        profileName: $profileName
        clientId: $clientId
        secret: $secret
        deliveryModuleEnabled: $deliveryModuleEnabled
      }
    ) {
      id
    }
  }
`
