import React from "react"
import { getLogicTypeDisplayName } from "helpers/getLogicTypeDisplayName"

import { LogicSelect } from "./LogicSelect"

const options = [
  {
    value: "CARTDISCOUNT",
    label: getLogicTypeDisplayName("CARTDISCOUNT"),
    description: "Apply a discount to the entire cart value."
  },
  {
    value: "BUYGET",
    label: getLogicTypeDisplayName("BUYGET"),
    description: "Buy one pair of socks, get discount on another pair."
  },
  {
    value: "BUYSELECT",
    label: getLogicTypeDisplayName("BUYSELECT"),
    description: "Buy a pair of socks, get a discount on something else."
  },
  {
    value: "ITEMDISCOUNTS",
    label: getLogicTypeDisplayName("ITEMDISCOUNTS"),
    description: "Apply discounts to specific products in the cart."
  },
  {
    value: "SELECTITEMS",
    label: getLogicTypeDisplayName("SELECTITEMS"),
    description: "Item discount with quantity. Get discount on most expensive item"
  },
  {
    value: "OPTIMIZEBUYGETS",
    label: getLogicTypeDisplayName("OPTIMIZEBUYGETS"),
    description: "3 for 2, or buy 2 and get 50% off."
  },
  {
    value: "FREESHIPPING",
    label: getLogicTypeDisplayName("FREESHIPPING"),
    description: "Qualify for free shipping when a condition is met."
  },
  {
    value: "GIFT",
    label: getLogicTypeDisplayName("GIFT"),
    description: "A selected product is automatically added to the cart."
  }
]

type Props = {
  setValue: any // eslint-disable-line
  defaultValue?: string
  disabled?: boolean
}

export const OutcomeSelect = ({ setValue, disabled, defaultValue }: Props) => (
  <LogicSelect
    options={options}
    setValue={setValue}
    disabled={disabled}
    placeholder="Select outcome type to add"
    defaultValue={defaultValue}
  />
)
