import React from "react"
import SingleSelect from "../../../Ui/Form/SingleSelect"
import { getCountryName } from "../../../../helpers/countries"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { StoreGroup } from "lib/types/generated/graphql-types"
import { Common } from "lib/types"
import { useStartSessionMutation } from "lib/store/services/brink/actions"
import { StartSessionContainer } from "./StartSession.styled"
import { useConfig } from "../../../../configuration/useConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"

type Props = {
  storeGroups: StoreGroup[]
  updateSelectedStoreGroup: (storeGroupOption: Common.Option) => void
  selectedStoreGroup: StoreGroup | undefined
  updateSelectedStoreMarket: (storeMarketOption: Common.Option) => void
  selectedStoreMarket: string | undefined
}

export const StartSession = ({
  storeGroups,
  updateSelectedStoreGroup,
  selectedStoreGroup,
  updateSelectedStoreMarket,
  selectedStoreMarket
}: Props) => {
  const [startSession, { isLoading }] = useStartSessionMutation()
  const { config } = useConfig()
  const { awsRegion, customerName } = config
  const dispatch = useDispatch()

  const startShopperSession = async () => {
    startSession({
      awsRegion,
      customerName,
      storeGroupId: selectedStoreGroup?.id ?? "",
      countryCode: selectedStoreMarket ?? "",
      languageCode: "en"
    })
      .unwrap()
      .catch(() => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      })
  }
  return (
    <StartSessionContainer>
      <h2>Select store group and market</h2>
      <SingleSelect
        placeholder="Select store group"
        options={storeGroups.map((storeGroup) => ({
          label: storeGroup.name,
          value: storeGroup.id
        }))}
        setValue={updateSelectedStoreGroup}
      />

      <SingleSelect
        placeholder="Select store market"
        disabled={!selectedStoreGroup}
        options={
          selectedStoreGroup?.storeMarkets
            ? selectedStoreGroup?.storeMarkets.map((storeMarket) => ({
                value: storeMarket?.countryCode ?? "",
                label: getCountryName(storeMarket?.countryCode ?? "") ?? ""
              }))
            : []
        }
        setValue={updateSelectedStoreMarket}
      />
      <PrimaryButton
        disabled={!selectedStoreMarket}
        handleClick={() => startShopperSession()}
        loading={isLoading}
      >
        Add product variants
      </PrimaryButton>
    </StartSessionContainer>
  )
}
