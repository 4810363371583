import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectPopupError } from "lib/store/services/Alert/AlertSelector"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { ReactComponent as ErrorIcon } from "images/icons/circle-exclamation.svg"
import Popup from "../Popup"
import { v4 as uuid } from "uuid"
import { Container, Title } from "./GraphqlErrorMessages.styled"

const defaultErrorMessage = (
  <>
    An error occured. Please contact
    <a href="mailto:support@brinkcommerce.com">support</a>.
  </>
)

const GraphqlErrorMessage = () => {
  const { popupError } = useSelector(selectPopupError)
  const [notifications, setNotifications] = useState<string[]>()
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (popupError?.messages?.length > 0 || popupError?.title) {
      setNotifications(popupError?.messages)
      setShow(true)
    }
  }, [popupError])

  const close = () => {
    setShow(false)
    dispatch(alertActions.actions.resetPopupError())
  }

  return (
    <>
      {show && (
        <Popup
          title={
            <Title>
              <div>
                <ErrorIcon />
                Error:
              </div>
              <span>{popupError?.title}</span>
            </Title>
          }
          handleCloseClick={() => close()}
          hideOkButton={true}
          cancelButtonText="Close"
        >
          {notifications?.map((notification) => (
            <Container key={uuid()}>
              <p>{notification || defaultErrorMessage}</p>
            </Container>
          ))}
        </Popup>
      )}
    </>
  )
}

export default GraphqlErrorMessage
