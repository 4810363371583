import gql from "graphql-tag"

const GET_DISCOUNT_CODE = gql`
    query getDiscountCode($code: String!) {
        getDiscountCode(code: $code) {
            code
            discountCodeRuleId
            isUnlimited
            usageLimit
            validDateRange {
                from
                to
            }
        }
    }
`

export default GET_DISCOUNT_CODE
