import React from "react"
import styled from "styled-components/macro"

import { ProductBlock } from "./ProductBlock"
import { List, Label } from "components/Ui/List/List"

import { ReactComponent as TagIcon } from "images/icons/tag.svg"

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 4rem;
  position: relative;
`

const TagValues = styled.div`
  display: flex;
  width: auto;
  flex-wrap: wrap;
  div {
    background: ${p => p.theme.colors.greyLight};
    padding: 0.3rem 1rem;
    margin: 0.5rem;
    text-align: center;
    border-radius: 0.6rem;
    font-weight: 700;
    font-size: 1.2rem;
  }

`
const TagItem = styled.div`
width: fit-content;
`


type Props = {
  tags: any // eslint-disable-line
}

export const Tags = ({ tags }: Props) => (
  <ProductBlock>
    <h2>
      <TagIcon />
      Tags
    </h2>
    <Block>
      <List>
        {Object.keys(tags).length > 0 ? (
          tags &&
          Object.keys(tags)?.map((tagKey) => (
            <div key={tagKey}>
              <Label><>{tagKey}:</></Label>
              <TagItem>
                <TagValues>
                  {tags[tagKey]?.map((value: string) => (
                    <div key={value}>{value}</div>
                  ))}
                </TagValues>
              </TagItem>
            </div>
          ))
        ) : (
          <p>No tags set</p>
        )}
      </List>
    </Block>
  </ProductBlock>
)
