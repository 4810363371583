import gql from "graphql-tag"

export const GET_BONUS_CONFIG = gql`
  query getBonusConfig($id: ID!) {
    getBonusConfig(id: $id) {
      id
      baseUrl
      authUrl
      credentials {
        clientId
        clientSecret
      }
      created
      updated
    }
  }
`
