import styled from "styled-components/macro"

import Search from "components/Ui/Search"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ResetButton } from "images/icons/xmark-thick.svg"
import { ReactComponent as ArrowRightIcon } from "images/icons/arrow-right.svg"

type StyledProps = {
  $isFilterActive?: boolean
  $active?: boolean
  $expanded?: boolean
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Wrapper = styled.div<StyledProps>`
  margin-bottom: 1rem;
`

export const Flex = styled.div`
  padding: 0;
  gap: 3rem;
  border-bottom: 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    justify-content: space-between;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-top: 0;
  }

  p {
    margin-right: 0.5rem;
  }
`

export const Show = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    gap: 1rem;
  }
`

export const ClearButton = styled(PrimaryButton)`
  background: transparent !important;
  border: 0.1rem solid rgba(57, 179, 155, 0.6) !important;
  color: ${(p) => p.theme.colors.turquoiseDarker};
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border-radius: 2rem;

  &:hover {
    background: ${(p) => p.theme.colors.turquoiseDarker};
    cursor: pointer;
  }
`

export const NumberOfHits = styled.div<StyledProps>`
  width: 3rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 1.5rem 2.5rem;
  background: ${(p) => (p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.greyLighter)};
  color: ${(p) => (p.$active ? p.theme.colors.white : p.theme.colors.black)};
  font-weight: ${(p) => (p.$active ? p.theme.bold : "initial")};

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.grey};
  }
`

export const LabelWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 6rem;
  padding: 1.5rem;

  &:hover {
    cursor: pointer;
  }

  > svg {
    width: 1.2rem;
    margin-right: 1rem;
  }
`

export const Label = styled.div<StyledProps>`
  display: flex;
  align-items: center;

  svg {
    &:first-of-type {
      width: 1.8rem;
      margin-right: 1rem;
      fill: ${(p) => (p.$isFilterActive ? p.theme.colors.turquoiseDark : p.theme.colors.black)};
    }
  }
`

export const Children = styled.div`
  padding: 3rem 2rem 2rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: rgb(239, 241, 244);
  border-radius: 0.5rem;
  margin: 1rem 0;
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  max-width: 75rem;
`

export const StyledSearch = styled(Search)`
  margin-bottom: 0;
  padding: 0;
  border: none;

  > div {
    &:last-of-type {
      right: 2rem;
    }
  }
`

export const Arrow = styled(ChevronDown)<StyledProps>`
  width: 1rem !important;
  margin-left: 1rem;
  transition: transform 0.2s;
  transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
`

export const Result = styled.div`
  padding-left: 2rem;
  border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  align-items: center;
  height: 4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 1.6rem;
  }
`

export const Hits = styled.span`
  ${(p) => p.theme.bold};
  display: inline-block;
  margin-left: 0.4rem;
`

export const ActiveFilters = styled.div`
  background: ${(p) => p.theme.colors.turquoiseLighter};
  border: 0.1rem solid ${(p) => p.theme.colors.turquoiseBorder};
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  > div {
    display: flex;
    align-items: center;
  }

  h4 {
    margin: 0;
`

export const ActiveListList = styled.ul`
  display: flex;
  gap: 1rem;
  margin: 0;
  margin-left: 2rem;
`

export const ActiveListListItem = styled.li`
  padding: 0.2rem 1.5rem;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid rgba(57, 179, 155, 0.2) !important;
  color: ${(p) => p.theme.colors.turquoiseDarker};
  height: 4rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;

  svg {
    fill: ${(p) => p.theme.colors.turquoiseDarker};
    margin: 0 0.8rem 0 0;
    width: 1.6rem;
    height: 1.6rem;
  }
`

export const RemoveButton = styled(ResetButton)`
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin: 0 0 0 1rem !important;

  &:hover {
    cursor: pointer;
  }
`

export const FromIcon = styled(ArrowRightIcon)`
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin: 0 0.8rem 0 -0.3rem !important;
`

export const ToIcon = styled(ArrowRightIcon)`
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin: 0 0.5rem 0 0 !important;
`
