import React from "react"
import { PROVIDER_TYPE } from "lib/types/common"

import { ReactComponent as KlarnaIcon } from "images/providers/icons/klarna.svg"
import { ReactComponent as AdyenIcon } from "images/providers/icons/adyen.svg"
import { ReactComponent as SveaIcon } from "images/providers/icons/svea.svg"
import { ReactComponent as WalleyIcon } from "images/providers/icons/walley.svg"
import { ReactComponent as QliroIcon } from "images/providers/icons/qliro.svg"
import { ReactComponent as AvardaIcon } from "images/providers/icons/avarda.svg"
import { ReactComponent as IngridIcon } from "images/providers//ingrid-logo.svg"
import { ReactComponent as NShiftIcon } from "images/providers/icons/nshift.svg"
import { ReactComponent as VoyadoIcon } from "images/providers/icons/voyado.svg"
import { ReactComponent as Retain24Logo } from "images/providers/retain24-logo.svg"
import { ReactComponent as BonusIcon } from "images/icons/hand-holding-dollar.svg"
import { ReactComponent as CardIcon } from "images/icons/gift-card.svg"
import { ReactComponent as BrinkIcon } from "images/brink-logo-icon.svg"


export const getProviderIcon = (provider: string) => {
  switch (provider) {
    case "KlarnaCheckout":
      return <KlarnaIcon />
    case "Adyen":
      return <AdyenIcon />
    case "SveaCheckout":
      return <SveaIcon />
    case "QliroOne":
      return <QliroIcon />
    case "WalleyCheckout":
      return <WalleyIcon />
    case "Avarda":
      return <AvardaIcon />
    case "Ingrid":
      return <IngridIcon />
    case "Nshift":
      return <NShiftIcon />
    case "BrinkZeroPayment":
      return <BrinkIcon/>
    default:
      return provider
  }
}

export type ProviderDetails = {
  type: string
  name: string
  desc: string
  icon: JSX.Element
  slug: string
  statusUrl: string | undefined
  noEdit?: boolean
}

export type ProviderType = {
  [key: string]: ProviderDetails
}

export type ProvidersData = {
  [key: string]: ProviderDetails[]
}

export const providersData: ProvidersData = {
  Payment: [
    {
      type: PROVIDER_TYPE.KLARNA_CHECKOUT,
      name: "Klarna Checkout",
      desc: "A user-friendly online payment system allowing seamless transactions and various payment options for enhanced shopping experiences.",
      icon: <KlarnaIcon />,
      slug: "/settings/providers/klarna",
      statusUrl: "https://status.klarna.com/"
    },
    {
      type: PROVIDER_TYPE.ADYEN,
      name: "Adyen",
      desc: "A global payment platform offering seamless transactions, robust security, and extensive payment methods worldwide.",
      icon: <AdyenIcon />,
      slug: "/settings/providers/adyen",
      statusUrl: "https://status.adyen.com/"
    },
    {
      type: PROVIDER_TYPE.SVEA_CHECKOUT,
      name: "Svea Checkout",
      desc: "A comprehensive online payment platform providing seamless transactions and diverse payment options for customers in the Nordics.",
      icon: <SveaIcon />,
      slug: "/settings/providers/svea",
      statusUrl: "https://status.svea.com/"
    },
    {
      type: PROVIDER_TYPE.WALLEY_CHECKOUT,
      name: "Walley Checkout",
      desc: "An efficient online payment system facilitating secure transactions and diverse payment methods for seamless customer experiences.",
      icon: <WalleyIcon />,
      slug: "/settings/providers/walley",
      statusUrl: "https://status.walleypay.com/"
    },
    {
      type: PROVIDER_TYPE.QLIRO_ONE,
      name: "Qliro One",
      desc: "A unified online payment platform offering streamlined transactions and multiple payment options for enhanced customer convenience.",
      icon: <QliroIcon />,
      slug: "/settings/providers/qliro",
      statusUrl: "https://status.qliro.com/"
    },
    {
      type: PROVIDER_TYPE.AVARDA,
      name: "Avarda",
      desc: "Avarda offers true white-label payment solutions and sales financing for e-com merchants in the Nordics.",
      icon: <AvardaIcon />,
      slug: "/settings/providers/avarda",
      statusUrl: "https://docs.avarda.com/"
    }
  ],
  Shipping: [
    {
      type: PROVIDER_TYPE.NSHIFT,
      name: "nShift",
      desc: "A platform that optimizes and manages the delivery process, ensuring efficient and timely shipment of goods to customers.",
      icon: <NShiftIcon />,
      slug: "/settings/providers/Nshift",
      statusUrl: "https://status.nshiftportal.com/"
    },
    {
      type: PROVIDER_TYPE.INGRID,
      name: "Ingrid",
      desc: "An efficient logistics solution, streamlining delivery processes for businesses to ensure timely and reliable shipments, optimizing overall operations.",
      icon: <IngridIcon />,
      slug: "/settings/providers/ingrid",
      statusUrl: "http://status.ingrid.com/"
    }
  ],
  CRM: [
    {
      type: PROVIDER_TYPE.VOYADO,
      name: "Voyado",
      desc: "A customer retention platform offering personalized marketing strategies and targeted promotions to enhance customer loyalty and drive sales growth for businesses.",
      icon: <VoyadoIcon />,
      slug: "/settings/providers/voyado",
      statusUrl: "https://status.voyado.com/"
    }
  ],
  ["Gift card"]: [
    {
      type: PROVIDER_TYPE.RETAIN24,
      name: "Retain24",
      desc: " A versatile and customizable digital or physical voucher facilitating purchases at affiliated businesses, enhancing customer engagement and loyalty.",
      icon: <Retain24Logo />,
      slug: "/settings/providers/retain24",
      statusUrl: "https://www.retain24status.com/retain24status/"
    },
    {
      type: PROVIDER_TYPE.BSG_GIFT_CARD,
      name: "BSG - Custom",
      desc: "Custom Gift card provider for Babyshop Group.",
      icon: <CardIcon />,
      slug: "",
      statusUrl: undefined,
      noEdit: true
    },
    {
      type: PROVIDER_TYPE.KBS_GIFT_CARD,
      name: "KBS - Custom",
      desc: "Custom Gift card provider for Kids Brand Store.",
      icon: <CardIcon />,
      slug: "",
      statusUrl: undefined,
      noEdit: true
    }
  ],
  Bonus: [
    {
      type: PROVIDER_TYPE.BONUS,
      name: "Custom bonus provider",
      desc: "Setup a custom bonus provvider together with Brink.",
      icon: <BonusIcon />,
      slug: "/settings/providers/bonus",
      statusUrl: undefined
    }
  ]
}
