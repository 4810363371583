import styled from "styled-components/macro"

export const Actions = styled.div`
  max-width: 7rem;
  display: flex;
  justify-content: center;

  button {
    margin-left: 0;
  }
`

export const BundleGroupInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 40rem;
`
