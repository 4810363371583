import React from "react"
import { ReactComponent as PriceIcon } from "images/icons/money-bill.svg"
import { ProductVariant, StoreGroupSearchHit } from "@lib/types/generated/graphql-types"
import VariantPrice from "./VariantPrice"
import { useQuery } from "@apollo/client"
import ALL_STORE_GROUPS from "../../../graphql/queries/store/AllStoreGroups"

type Props = {
  variants: ProductVariant[]
}

const ShowPrices = ({ variants }: Props) => {
  const {data} = useQuery(ALL_STORE_GROUPS)

  const getStoreGroupName = (id: string) => {
    const storeGroup = data?.getStoreGroups?.find((storeGroup: StoreGroupSearchHit) => storeGroup.id === id)
    return storeGroup?.name ?? id
  }

  return (
    <>
      <h2>
        <PriceIcon />
        Prices
      </h2>
      {variants.map((variant) => (
      <VariantPrice key={variant.id} variant={variant} getStoreGroupName={getStoreGroupName}/>
      ))}
    </>
  )
}

export default ShowPrices
