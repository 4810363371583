import styled from "styled-components/macro"

type StyledProps = {
  $loading?: boolean
}

export const CartItemContainer = styled.div`
  display: flex;
  padding: 0.7rem;
  position: relative;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.borderLight};

  &:last-of-type {
    border-bottom: none;
  }
`

export const InfoWrapper = styled.div`
display: flex;
`

export const ItemImage = styled.div`
  width: 10rem;
  display: flex;
  align-items: center;

  img {
    position: relative !important;
    object-fit: contain;
    background: ${(p) => p.theme.colors.background};
  }

  svg {
    height: 4rem;
  }
`

export const ItemDetails = styled.div`
  padding: 1rem 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 1.5rem 2rem;
  }
`

export const ItemName = styled.div`
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  ${(p) => p.theme.bold};
  word-break: break-word;
`

export const ItemPrice = styled.div`
  span {
    text-decoration: line-through;
    color: #666;
    font-weight: 400;
    margin-left: 1rem;
  }
`

export const ActionWrapper = styled.div<StyledProps>`
  filter: ${p => p.$loading ? "blur(0.3rem)" : "none"};
  -webkit-filter: ${p => p.$loading ? "blur(0.3rem)" : "none"};
  border: 0.1rem solid ${(p) => p.theme.colors.borderLight};
  padding: 1rem;
  width: 30rem;
  font-size: 1.1rem;

  span {
    display: flex;
    justify-content: space-between;

    label {
      font-size: 1.1rem !important;
      font-weight: normal;
    }

    div {
      padding: 0;
    }
  }

  button {
    height: 2.5rem;
    padding: 0.2rem 1rem;
  }

  hr {
    margin: 1rem 0;
  }
`

export const QtySelector = styled.div`
  display: flex;
  align-items: center;

  span {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    &:first-of-type,
    &:last-of-type {
      width: 2.5rem;
      border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
      font-size: 1.6rem;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 0.3rem;
      padding: 0;

      ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
        &:hover {
          background: ${(p) => p.theme.colors.greyLight};
          border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
        }
      }
    }
  }
`
