import React from "react"
import styled from "styled-components/macro"
import { ReactComponent as LogoIcon } from "images/brink-logo-icon.svg"

const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2.5rem 0;
  margin-top: 5rem;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  font-size: 1.2rem;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
  }

  a {
    color: ${(p) => p.theme.colors.black};
    line-height: 2rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    justify-content: flex-end;
    flex-wrap: nowrap;
    font-size: 1.4rem;
    height: 9rem;

    > div {
      width: auto;
      margin: 0;
    }
  }
`

const Logo = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.8rem 0 0;

  svg {
    fill: ${(p) => p.theme.colors.black};
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: 0 1rem 0 0.5rem;
  }
`

const FooterLink = styled.a`
  margin: 0.5rem;
  position: relative;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: 0 0.5rem;
  }
`

const UnderlineLink = styled(FooterLink)`
  margin: 0.5rem 0.8rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.black};

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    &:hover {
      border-bottom: 0.1rem solid transparent;
    }
  }
`

const Footer = () => (
  <Container>
    <div>
      <UnderlineLink
        href="https://status.brinkcommerce.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Status
      </UnderlineLink>{" "}
      |
      <UnderlineLink
        href="https://docs.brinkcommerce.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Documentation
      </UnderlineLink>
    </div>
    <div>
      <Logo>
        <LogoIcon />
      </Logo>
      &copy; {new Date().getFullYear()}
      <FooterLink href="https://www.brinkcommerce.com/" target="_blank" rel="noopener noreferrer">
        <strong>Brink Commerce</strong>. All rights reserved
      </FooterLink>
    </div>
  </Container>
)

export default Footer
