import React, { Dispatch, SetStateAction } from "react"
import Popup from "../../../../Ui/Popup"
import { useQuery } from "@apollo/client"
import { ButtonWrapper, ProviderContainer } from "./StatusPopup.styled"
import Status from "../../../../Ui/Status"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as RefreshIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"
import GET_DELIVERY from "../../../../../graphql/queries/order/GetDelivery"

type Props = {
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  deliveryId: string
  refetchOrder: () => void
}

const StatusPopup = ({ setShowStatusPopup, deliveryId, refetchOrder }: Props) => {
  const { data, refetch, loading } = useQuery(GET_DELIVERY, {
    variables: { deliveryId },
    fetchPolicy: "network-only"
  })

  const delivery = data?.Order?.delivery

  return (
    <Popup
      title={"Provider status"}
      handleCloseClick={() => {
        refetchOrder()
        setShowStatusPopup(false)
      }}
      handleOkClick={() => {
        refetchOrder()
        setShowStatusPopup(false)
      }}
      buttonText={"Ok"}
    >
      <div>
        <ButtonWrapper>
          <PrimaryButton handleClick={() => refetch()} loading={loading}>
            <RefreshIcon />
            Refresh status
          </PrimaryButton>
        </ButtonWrapper>
        {delivery?.bonus && (
          <ProviderContainer>
            <b>Bonus</b>
            <Status status={delivery?.bonusProvider?.status?.current} />
          </ProviderContainer>
        )}
        {delivery?.giftCards.length > 0 && (
          <ProviderContainer>
            <b>Gift card</b>
            <Status status={delivery?.giftCardProvider?.status?.current} />
          </ProviderContainer>
        )}
        {delivery?.paymentProvider && (
          <ProviderContainer>
            <b>Payment</b>
            <Status status={delivery?.paymentProvider?.status?.current} />
          </ProviderContainer>
        )}
        {delivery?.shippingProvider && (
          <ProviderContainer>
            <b>Shipping</b>
            <Status status={delivery?.shippingProvider?.status?.current} />
          </ProviderContainer>
        )}
      </div>
    </Popup>
  )
}

export default StatusPopup
