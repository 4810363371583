import React from "react"
import { AddedReason, Label, Wrapper } from "./Reason.styled"
import { OrderRelease } from "@lib/types/generated/graphql-types"

type Props = {
  release: OrderRelease
}

const Reason = ({ release }: Props) => {
  return (
    <Wrapper>
      <Label>Reason</Label>
      <AddedReason>
        {release?.reason ? (
          <div>
            <span>
              <b>Cause:</b> {release?.reason.cause}
            </span>
            <span>
              <b>Code:</b> {release?.reason.code}
            </span>
          </div>
        ) : (
          <div>-No reason added</div>
        )}
      </AddedReason>
    </Wrapper>
  )
}

export default Reason
