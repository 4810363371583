import gql from "graphql-tag"

const ORDERS_TOTAL_HITS_24_HOURS = gql`
  query searchOrdersLast24Hours(
    $from: Int!,
    $size: Int!,
    $sort: [SearchSorting!],
    $query1: AWSJSON,
    $query2: AWSJSON,
    $query3: AWSJSON,
    $query4: AWSJSON,
    $query5: AWSJSON,
    $query6: AWSJSON,
    $query7: AWSJSON,
    $query8: AWSJSON,
    $query9: AWSJSON,
    $query10: AWSJSON,
    $query11: AWSJSON,
    $query12: AWSJSON,
    $query13: AWSJSON,
    $query14: AWSJSON,
    $query15: AWSJSON,
    $query16: AWSJSON,
    $query17: AWSJSON,
    $query18: AWSJSON,
    $query19: AWSJSON,
    $query20: AWSJSON,
    $query21: AWSJSON,
    $query22: AWSJSON,
    $query23: AWSJSON,
    $query24: AWSJSON,
    $query25: AWSJSON,
    $trackTotalHits: Boolean) {
    query1: searchOrders(from: $from, size: $size, sort: $sort, query: $query1, trackTotalHits: $trackTotalHits) {
      total
    }
    query2: searchOrders(from: $from, size: $size, sort: $sort, query: $query2, trackTotalHits: $trackTotalHits) {
      total
    }
    query3: searchOrders(from: $from, size: $size, sort: $sort, query: $query3, trackTotalHits: $trackTotalHits) {
      total
    }
    query4: searchOrders(from: $from, size: $size, sort: $sort, query: $query4, trackTotalHits: $trackTotalHits) {
      total
    }
    query5: searchOrders(from: $from, size: $size, sort: $sort, query: $query5, trackTotalHits: $trackTotalHits) {
      total
    }
    query6: searchOrders(from: $from, size: $size, sort: $sort, query: $query6, trackTotalHits: $trackTotalHits) {
      total
    }
    query7: searchOrders(from: $from, size: $size, sort: $sort, query: $query7, trackTotalHits: $trackTotalHits) {
      total
    }
    query8: searchOrders(from: $from, size: $size, sort: $sort, query: $query8, trackTotalHits: $trackTotalHits) {
      total
    }
    query9: searchOrders(from: $from, size: $size, sort: $sort, query: $query9, trackTotalHits: $trackTotalHits) {
      total
    }
    query10: searchOrders(from: $from, size: $size, sort: $sort, query: $query10, trackTotalHits: $trackTotalHits) {
      total
    }
    query11: searchOrders(from: $from, size: $size, sort: $sort, query: $query11, trackTotalHits: $trackTotalHits) {
      total
    }
    query12: searchOrders(from: $from, size: $size, sort: $sort, query: $query12, trackTotalHits: $trackTotalHits) {
      total
    }
    query13: searchOrders(from: $from, size: $size, sort: $sort, query: $query13, trackTotalHits: $trackTotalHits) {
      total
    }
    query14: searchOrders(from: $from, size: $size, sort: $sort, query: $query14, trackTotalHits: $trackTotalHits) {
      total
    }
    query15: searchOrders(from: $from, size: $size, sort: $sort, query: $query15, trackTotalHits: $trackTotalHits) {
      total
    }
    query16: searchOrders(from: $from, size: $size, sort: $sort, query: $query16, trackTotalHits: $trackTotalHits) {
      total
    }
    query17: searchOrders(from: $from, size: $size, sort: $sort, query: $query17, trackTotalHits: $trackTotalHits) {
      total
    }
    query18: searchOrders(from: $from, size: $size, sort: $sort, query: $query18, trackTotalHits: $trackTotalHits) {
      total
    }
    query19: searchOrders(from: $from, size: $size, sort: $sort, query: $query19, trackTotalHits: $trackTotalHits) {
      total
    }
    query20: searchOrders(from: $from, size: $size, sort: $sort, query: $query20, trackTotalHits: $trackTotalHits) {
      total
    }
    query21: searchOrders(from: $from, size: $size, sort: $sort, query: $query21, trackTotalHits: $trackTotalHits) {
      total
    }
    query22: searchOrders(from: $from, size: $size, sort: $sort, query: $query22, trackTotalHits: $trackTotalHits) {
      total
    }
    query23: searchOrders(from: $from, size: $size, sort: $sort, query: $query23, trackTotalHits: $trackTotalHits) {
      total
    }
    query24: searchOrders(from: $from, size: $size, sort: $sort, query: $query24, trackTotalHits: $trackTotalHits) {
      total
    }
    query25: searchOrders(from: $from, size: $size, sort: $sort, query: $query25, trackTotalHits: $trackTotalHits) {
      total
    }
  }
`

export default ORDERS_TOTAL_HITS_24_HOURS
