import { ApolloError } from "@apollo/client"
import type { GraphQLError } from "graphql/index"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { store } from "lib/store"

interface ErrorInfo {
  error: string
  errors: string[]
  requestId: string
}

interface CustomError extends GraphQLError {
  errorInfo: ErrorInfo
}

export const handleOrderManagementError = (error: ApolloError) => {
  const graphqlError = error?.graphQLErrors[0] as CustomError
  const errors = graphqlError?.errorInfo?.errors
  if (errors?.length > 0) {
    store.dispatch(
      alertActions.actions.createPopupError({
        title: error.message,
        messages: errors
      })
    )
    return
  }
  store.dispatch(
    alertActions.actions.createAlert({
      type: "error",
      message: error?.message
    })
  )
}
