import React from "react"
import styled from "styled-components/macro"
import { OrderPaymentBrinkZeroPayment } from "@lib/types/generated/graphql-types"
import { Item, Label, List, Value } from "components/Ui/List/List"
import {
  getPaymentMethodDisplayName,
  getPaymentProviderDisplayName
} from "helpers/getPaymentDisplayName"

import { ReactComponent as BrinkLogo } from "images/brink-logo-dark.svg"

const Logo = styled(BrinkLogo)`
  position: absolute;
  height: 3rem;
  right: 3rem;
`

type Props = {
  orderPayment: OrderPaymentBrinkZeroPayment
}

const BrinkZeroPayment = ({ orderPayment }: Props) => {
  if (!orderPayment) return null

  return (
    <List>
      <Logo />
      <Item>
        <Label>Payment provider:</Label>
        <Value>{getPaymentProviderDisplayName(orderPayment.providerName)}</Value>
      </Item>
      <Item>
        <Label>Payment method:</Label>
        <Value>{getPaymentMethodDisplayName(orderPayment.method)}</Value>
      </Item>
      <Item>
        <Label>Payment reference:</Label>
        <Value>{orderPayment.reference}</Value>
      </Item>
      <Item>
        <Label>Original provider:</Label>
        <Value>{getPaymentMethodDisplayName(orderPayment.originalProvider.name)}</Value>
      </Item>
      <Item>
        <Label>Original provider id:</Label>
        <Value>{getPaymentMethodDisplayName(orderPayment.originalProvider.id)}</Value>
      </Item>
    </List>
  )
}

export default BrinkZeroPayment
