import React from "react"
import styled from "styled-components/macro"

type StyledProps = {
  padding?: string
  margin?: string
  overflow?: string
}

const StyledBox = styled.div<StyledProps>`
  overflow: ${(p) => p.overflow};
  padding: ${(p) => p.padding};
  margin: ${(p) => p.margin};
  border-radius: 0.5rem;
  width: 100%;
`

type Props = {
  children: JSX.Element | JSX.Element[]
  padding?: string
  margin?: string
  overflow?: string
}

const Box = ({
  children,
  padding = "4rem",
  margin = "0.5rem 1rem",
  overflow = "visible"
}: Props) => {
  return (
    <StyledBox padding={padding} margin={margin} overflow={overflow}>
      {children}
    </StyledBox>
  )
}

export default Box
