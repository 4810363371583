import gql from "graphql-tag"

const DELETE_DISCOUNT_EXTERNAL_RULE = gql`
  mutation deleteDiscountExternalRule($id: ID!) {
    deleteDiscountExternalRule(id: $id) {
      status
    }
  }
`

export default DELETE_DISCOUNT_EXTERNAL_RULE
