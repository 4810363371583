import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import esb, { RequestBodySearch } from "elastic-builder"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"
import {
  Provider,
  StoreGroup as StoreGroupType,
  StoreGroup,
  StoreMarket as StoreMarketType
} from "lib/types/generated/graphql-types"
import { Common } from "lib/types"
import { getCountryName } from "helpers/countries"
import { getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"
import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"

import {
  ActiveFilters,
  ActiveListList,
  ActiveListListItem,
  ClearButton,
  FromIcon,
  RemoveButton,
  ToIcon
} from "components/Ui/Table/Filter/Filter.styled"

import FilterDate from "../Ui/Table/Filter/FilterDate"
import Filter from "../Ui/Table/Filter/Filter"
import FilterSection from "../Ui/Table/Filter/FilterSection"

import { ReactComponent as StatusIcon } from "images/icons/tag.svg"
import { ReactComponent as StoreGroupIcon } from "images/icons/store.svg"
import { ReactComponent as EarthIcon } from "images/icons/earth.svg"
import { ReactComponent as CreditCardIcon } from "images/icons/credit-card.svg"
import { ReactComponent as CalendarIcon } from "images/icons/calendar-days.svg"
import { ReactComponent as TimeZoneIcon } from "images/icons/calendar-clock.svg"

import { Option } from "lib/types/common"
import SingleSelect from "../Ui/Form/SingleSelect"
import { FilterWrapper, Icon } from "./OrderFilters.styled"

type Props = {
  setSearchQuery: (input: string) => void
  loading: boolean
  searchQuery: string
  setEsbQuery: (query: RequestBodySearch) => void
  orderFilter: Common.OrderFilter
  setOrderFilter: (filter: Common.OrderFilter) => void
  numberOfHitsOption: string[]
  defaultOrderFilter: Common.OrderFilter
  totalHits: number | undefined
}

const OrderFilters = ({
  setSearchQuery,
  loading,
  searchQuery,
  setEsbQuery,
  orderFilter,
  setOrderFilter,
  numberOfHitsOption,
  defaultOrderFilter,
  totalHits
}: Props) => {
  const [countryOptions, setCountryOptions] = useState<Common.Option[]>([])
  const [paymentOptions, setPaymentOptions] = useState<Common.Option[]>([])
  const [timeZone, setTimeZone] = useState<Option>({
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    label: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const timeZones = Intl.supportedValuesOf("timeZone")

  const { storeGroups, countries, payments, fromDate, toDate } = orderFilter

  const isFilterActive = () => {
    return (
      storeGroups.length > 0 ||
      countries.length > 0 ||
      payments.length > 0 ||
      orderFilter?.status?.length > 0 ||
      fromDate !== "" ||
      toDate !== ""
    )
  }
  const setStatusFilter = (value: Common.Option[]) =>
    setOrderFilter({ ...orderFilter, ...{ status: value } })
  const setStoreGroupFilter = (value: Common.Option[]) =>
    setOrderFilter({ ...orderFilter, ...{ storeGroups: value } })
  const setCountriesFilter = (value: Common.Option[]) =>
    setOrderFilter({ ...orderFilter, ...{ countries: value } })
  const setPaymentsFilter = (value: Common.Option[]) =>
    setOrderFilter({ ...orderFilter, ...{ payments: value } })
  const setFromDate = (value: string) => setOrderFilter({ ...orderFilter, ...{ fromDate: value } })
  const setToDate = (value: string) => setOrderFilter({ ...orderFilter, ...{ toDate: value } })
  const setNumberOfHits = (value: string) =>
    setOrderFilter({ ...orderFilter, ...{ numberOfHits: value } })

  const fromDateCount = orderFilter?.fromDate === "" ? 0 : 1
  const toDateCount = orderFilter?.toDate === "" ? 0 : 1
  const totalFilters =
    orderFilter &&
    orderFilter.countries.length +
      orderFilter.payments.length +
      orderFilter.storeGroups.length +
      orderFilter?.status?.length +
      fromDateCount +
      toDateCount

  const { data } = useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      const storeMarkets = data.getStoreGroups
        .map((storeGroup: StoreGroupType) => storeGroup.storeMarkets)
        .flat()
      const countryCodes = storeMarkets.map(
        (storeMarket: StoreMarketType) => storeMarket.countryCode
      )
      const uniqueCountryCodes = [...new Set(countryCodes)] as string[]
      setCountryOptions(
        uniqueCountryCodes
          .map(
            (code: string) =>
              ({
                value: code,
                label: getCountryName(code)
              } as Option)
          )
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }
  })

  const storeGroupOptions = data?.getStoreGroups?.map((storeGroup: StoreGroup) => ({
    value: storeGroup.id,
    label: storeGroup.name
  }))

  const statusOptions = [
    { label: "Delivered", value: "FULLY_DELIVERED" },
    { label: "Delivered in part", value: "PARTIALLY_DELIVERED" },
    { label: "Refunded", value: "FULLY_REFUNDED" },
    { label: "Refunded in part", value: "PARTIALLY_REFUNDED" },
    { label: "Compensated", value: "FULLY_COMPENSATED" },
    { label: "Compensated in part", value: "PARTIALLY_COMPENSATED" },
    { label: "Cancelled", value: "CANCELLED" },
    { label: "Placed", value: "PLACED" },
    { label: "Released", value: "RELEASED" }
  ]

  useQuery(ALL_PAYMENT_PROVIDERS, {
    onCompleted: (data) => {
      const paymentMethods = data.getPaymentProviders.map(
        (paymentProvider: Provider) => paymentProvider.type
      )
      const uniquePaymentMethods = [...new Set(paymentMethods)] as string[]
      setPaymentOptions(
        uniquePaymentMethods.map((payment: string) => ({
          value: payment,
          label: getPaymentProviderDisplayName(payment)
        }))
      )
    }
  })

  const getValues = (options: Common.Option[]) => {
    return options.map((option) => option.value)
  }
  useEffect(() => {
    const boolQuery = esb.boolQuery()

    if (orderFilter?.status?.length > 0) {
      boolQuery.must(esb.termsQuery("status.orderStates", getValues(orderFilter.status)))
    }

    if (orderFilter.storeGroups.length > 0) {
      boolQuery.must(esb.termsQuery("storeGroupId", getValues(orderFilter.storeGroups)))
    }

    if (orderFilter.countries.length > 0) {
      boolQuery.must(esb.termsQuery("countryCode", getValues(orderFilter.countries)))
    }

    if (orderFilter.payments?.length > 0) {
      boolQuery.must(esb.termsQuery("paymentProviderName", getValues(orderFilter.payments)))
    }

    if (orderFilter.fromDate && !orderFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("date")
          .gt(orderFilter.fromDate.replace("T", "-"))
          .lt(dayjs().format("YYYY-MM-DD-HH:mm"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (!orderFilter.fromDate && orderFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("date")
          .gt(dayjs().subtract(1, "month").format("YYYY-MM-DD-HH:mm"))
          .lt(orderFilter.toDate.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

    if (orderFilter.fromDate && orderFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("date")
          .gt(orderFilter.fromDate.replace("T", "-"))
          .lt(orderFilter.toDate.replace("T", "-"))
          .format("yyyy-MM-dd-HH:mm")
          .timeZone(timeZone.value)
      )
    }

   boolQuery.must([
      esb
        .queryStringQuery(searchQuery.trim() + "*")
        .fields([
          "discountCodes",
          "billingAddress.email",
          "reference",
          "paymentReference",
          "billingAddress.givenName",
          "billingAddress.familyName",
          "id"
        ])
    ])

    setEsbQuery(esb.requestBodySearch().query(boolQuery))
  }, [orderFilter, searchQuery, timeZone])

  const resetFilter = () => {
    setOrderFilter(defaultOrderFilter)
  }

  return (
    <>
      <Filter
        isFilterActive={isFilterActive}
        setNumberOfHits={setNumberOfHits}
        numberOfHitsOptions={numberOfHitsOption}
        selectedNumberOfHits={orderFilter?.numberOfHits}
        resetFilter={resetFilter}
        totalHits={totalHits}
        searchOptions={{
          handleChange: (input: string) => {
            setSearchQuery(input)
          },
          loading: loading,
          defaultValue: searchQuery
        }}
        totalFilters={totalFilters}
      >
        <FilterSection
          defaultValue={orderFilter?.status}
          setValue={setStatusFilter}
          options={statusOptions}
          icon={<StatusIcon />}
          placeHolder="Order status"
        />
        <FilterSection
          defaultValue={orderFilter?.storeGroups}
          setValue={setStoreGroupFilter}
          options={storeGroupOptions}
          icon={<StoreGroupIcon />}
          placeHolder="Store groups"
        />
        <FilterSection
          defaultValue={orderFilter?.countries}
          setValue={setCountriesFilter}
          options={countryOptions}
          icon={<EarthIcon />}
          placeHolder="Country"
        />
        <FilterSection
          defaultValue={orderFilter?.payments}
          setValue={setPaymentsFilter}
          options={paymentOptions}
          icon={<CreditCardIcon />}
          placeHolder="Payment provider"
        />
        <FilterDate
          defaultFromDate={orderFilter?.fromDate}
          defaultToDate={orderFilter?.toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
        />
        <FilterWrapper>
          <Icon>
            <TimeZoneIcon />
          </Icon>
          <SingleSelect
            options={timeZones.map((timeZone) => ({ value: timeZone, label: timeZone }))}
            setValue={setTimeZone}
            defaultValue={{
              value: Intl.DateTimeFormat().resolvedOptions().timeZone,
              label: Intl.DateTimeFormat().resolvedOptions().timeZone
            }}
          />
        </FilterWrapper>
      </Filter>

      {isFilterActive() && (
        <ActiveFilters>
          <div>
            <b>Active filters:</b>
            <ActiveListList>
              {orderFilter.storeGroups.map((f) => (
                <ActiveListListItem
                  key={uuidv4()}
                  onClick={() =>
                    setStoreGroupFilter(orderFilter.storeGroups.filter((o) => o.value !== f.value))
                  }
                >
                  <StoreGroupIcon /> {f.label} <RemoveButton />
                </ActiveListListItem>
              ))}
              {orderFilter?.status?.map((f) => (
                <ActiveListListItem
                  key={uuidv4()}
                  onClick={() =>
                    setStatusFilter(orderFilter.status.filter((o) => o.value !== f.value))
                  }
                >
                  <StoreGroupIcon /> {f.label} <RemoveButton />
                </ActiveListListItem>
              ))}
              {orderFilter.countries.map((f) => (
                <ActiveListListItem
                  key={uuidv4()}
                  onClick={() =>
                    setCountriesFilter(orderFilter.countries.filter((o) => o.value !== f.value))
                  }
                >
                  <EarthIcon /> {f.label} <RemoveButton />
                </ActiveListListItem>
              ))}
              {orderFilter.payments.map((f) => (
                <ActiveListListItem
                  key={uuidv4()}
                  onClick={() =>
                    setPaymentsFilter(orderFilter.payments.filter((o) => o.value !== f.value))
                  }
                >
                  <CreditCardIcon /> {f.label} <RemoveButton />
                </ActiveListListItem>
              ))}
              {orderFilter.fromDate && (
                <ActiveListListItem onClick={() => setFromDate("")}>
                  <CalendarIcon />
                  <FromIcon /> {dayjs(orderFilter.fromDate).format("YYYY-MM-DD HH:mm")}{" "}
                  <RemoveButton />
                </ActiveListListItem>
              )}
              {orderFilter.toDate && (
                <ActiveListListItem onClick={() => setToDate("")}>
                  <ToIcon />
                  <CalendarIcon /> {dayjs(orderFilter.toDate).format("YYYY-MM-DD HH:mm")}{" "}
                  <RemoveButton />
                </ActiveListListItem>
              )}
            </ActiveListList>
          </div>
          {isFilterActive() && (
            <ClearButton
              handleClick={(e) => {
                e.stopPropagation()
                resetFilter()
              }}
            >
              Reset
            </ClearButton>
          )}
        </ActiveFilters>
      )}
    </>
  )
}
export default OrderFilters
