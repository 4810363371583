import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components/macro"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useMutation } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Common } from "@lib/types"

import ADD_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API from "graphql/mutations/settings/externalEvents/AddExternalEventsDestinationAPI"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import EditSidebarSection from "components/Ui/EditSidebar/EditSidebarSection"
import Input from "components/Ui/Form/Input"
import SingleSelect from "components/Ui/Form/SingleSelect"

const AuthContainer = styled.div`
  padding-top: 2rem;
`

type Props = {
  setCreateEventBus: Dispatch<SetStateAction<boolean>>
  refetch: () => void
}

type Inputs = {
  destName: string
  apiDestinationEndpoint: string
  user?: string
  password?: string
  name?: string
  key?: string
}

const CreateApiDestination = ({ setCreateEventBus, refetch }: Props) => {
  const dispatch = useDispatch()
  const [authSelect, setAuthSelect] = useState<string>("")

  const handleChange = (e: Common.Option) => {
    setAuthSelect(e.value)
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<Inputs>()

  const [createApiDestination, { loading: createLoading }] = useMutation(
    ADD_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API,
    {
      onCompleted: (data) => {
        dispatch(
          alertActions.actions.createAlert({
            message: `API Destination ${data.addExternalEventsDestinationAPI.apiDestinationName} successfully created`,
            type: "success"
          })
        )
        setCreateEventBus(false)
        dispatch(hideEditSidebar())
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const auth =
      authSelect === "basicAuth"
        ? {
            basicAuth: {
              user: data.user,
              password: data.password
            }
          }
        : authSelect === "apiKey"
        ? {
            apiKeyAuth: {
              name: data.name,
              key: data.key
            }
          }
        : undefined

    createApiDestination({
      variables: {
        destName: data.destName,
        options: {
          apiDestinationEndpoint: data.apiDestinationEndpoint,
          ...auth
        }
      }
    })
  }

  return (
    <EditSidebar
      cancelEvent={() => {
        dispatch(hideEditSidebar())
        reset()
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader title="Create new API Destination">
          <PrimaryButton loading={createLoading} type="submit">
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <EditSidebarSection>
          <Controller
            name="destName"
            render={({ field }) => <Input {...field} label="Destination name: *" errors={errors} />}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field",
              maxLength: {
                value: 20,
                message: "Max length 20"
              },
              pattern: {
                value: /^[a-zA-Z][.\-_A-Za-z0-9]+$/,
                message: "The input contains unallowed characters."
              }
            }}
          />
          <Controller
            name="apiDestinationEndpoint"
            render={({ field }) => (
              <Input
                {...field}
                label="Destination endpoint: *"
                description="NOTE: Needs to be an existing and valid endpoint before creating API Destination."
                errors={errors}
              />
            )}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <SingleSelect
            options={[
              { label: "Basic Auth", value: "basicAuth" },
              { label: "API Key", value: "apiKey" }
            ]}
            label="Authentication: *"
            setValue={handleChange}
            defaultValue={{
              label: "Authentication method",
              value: ""
            }}
          />
          <>
            {authSelect === "basicAuth" && (
              <AuthContainer>
                <Controller
                  name="user"
                  render={({ field }) => <Input {...field} label="User: *" errors={errors} />}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                />
                <Controller
                  name="password"
                  render={({ field }) => <Input {...field} label="Password: *" errors={errors} />}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                />
              </AuthContainer>
            )}
          </>
          <>
            {authSelect === "apiKey" && (
              <AuthContainer>
                <p>Enter an API key to include in the header for each authentication request.</p>

                <Controller
                  name="name"
                  render={({ field }) => <Input {...field} label="Name: *" errors={errors} />}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                />
                <Controller
                  name="key"
                  render={({ field }) => <Input {...field} label="Key: *" errors={errors} />}
                  defaultValue=""
                  control={control}
                  rules={{
                    required: "This is a required field",
                    minLength: 1,
                    maxLength: 512
                  }}
                />
              </AuthContainer>
            )}
          </>
        </EditSidebarSection>
      </form>
    </EditSidebar>
  )
}

export default CreateApiDestination
