import gql from "graphql-tag"

const DELETE_EXTERNAL_EVENTS_DESTINATION_API = gql`
  mutation deleteExternalEventsDestinationAPI($destName: String!) {
    deleteExternalEventsDestinationAPI(destName: $destName) {
      status
    }
  }
`

export default DELETE_EXTERNAL_EVENTS_DESTINATION_API
