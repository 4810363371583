import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useForm, SubmitHandler } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"
import { transformLogicData } from "helpers/transformLogicData"
import { BrinkLoader } from "components/Ui/BrinkLoader"
import { Discount } from "@lib/types"
import { DiscountRule, LogicHolder } from "@lib/types/generated/graphql-types"
import { getLogicData } from "helpers/getLogicData"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { getJson } from "helpers/getJson"

import CREATE_OR_UPDATE_CART_RULE from "graphql/mutations/discount/CreateOrUpdateCartRule"
import GET_CART_RULE from "graphql/queries/discount/GetCartRule"

import { Activate, Deactivate, Divider } from "../Shared.styled"

import Box from "components/Ui/Box"
import PageHeader from "components/Ui/Page/PageHeader"
import LogicRules from "components/Discount/LogicRules"
import DuplicateCartRule from "./DuplicateCartRule"
import CartRuleGeneralUpdateInput from "components/Discount/DiscountRule/CartRuleGeneralUpdateInput"
import { ActionDropdownButton } from "components/Ui/Buttons/ActionDropdownButton"
import Status from "components/Ui/Status"

import { ReactComponent as CodeIcon } from "images/icons/brackets-curly.svg"
import { ReactComponent as OnIcon } from "images/icons/circle-play.svg"
import { ReactComponent as OffIcon } from "images/icons/circle-minus.svg"

export const UpdateCartRule = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const [duplicateCartRule, setDuplicateCartRule] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<Discount.DiscountRuleInputs>()
  const { data, loading, refetch } = useQuery(GET_CART_RULE, {
    variables: { id }
  })

  const discountRule: DiscountRule = data?.getDiscountRule

  const [logicState, setLogicState] = useState<Discount.LogicState>({
    [uuidv4()]: {
      conditions: [],
      outcomes: []
    }
  })
  useEffect(() => {
    setValue("name", discountRule?.name)
    setValue("isActive", discountRule?.isActive)

    if (discountRule?.logicRules !== undefined && discountRule?.logicRules.length > 0) {
      setLogicState({})
      discountRule?.logicRules.forEach((logicRule: LogicHolder) => {
        setLogicState((prev: Discount.LogicState) => ({
          ...prev,
          ...(getLogicData(logicRule, discountRule) as Discount.LogicState)
        }))
      })
    }
  }, [discountRule])

  const [addCartRule, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_CART_RULE, {
    onCompleted: () => {
      refetch()
      dispatch(
        alertActions.actions.createAlert({
          message: "Cart rule successfully updated",
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<Discount.DiscountRuleInputs> = (data) => {
    const { name, isActive, isStackable, sortOrder, from, to } = data

    const transformedData = transformLogicData(logicState)
    addCartRule({
      variables: {
        id,
        name,
        isActive: isActive,
        isStackable: isStackable,
        sortOrder,
        ...transformedData,
        ...(from &&
          to && {
            validDateRange: {
              from: new Date(from),
              to: new Date(to)
            }
          })
      }
    })
  }

  const updateActive = (status: boolean) => {
    addCartRule({
      variables: {
        ...discountRule,
        isActive: status
      }
    })
  }

  if (loading) return <BrinkLoader />
  if (!discountRule) return null

  return (
    <Box padding="0" margin="0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={
            <>
              {discountRule.name}
              {discountRule.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
            </>
          }
          group="Cart rule"
          id={id}
          hasTabs
          goBackLinkUrl="/discounts/discount-rules"
          goBackLinkText="Back to discount rules"
        >
          <ActionDropdownButton
            title="Save"
            submitEvent={handleSubmit(onSubmit)}
            submitLoading={createLoading}
          >
            <li
              onClick={() => {
                setDuplicateCartRule(true)
                dispatch(showEditSidebar())
              }}
            >
              <CopyIcon /> Duplicate
            </li>
            <li
              onClick={() => {
                getJson(discountRule)
              }}
            >
              <CodeIcon /> Get rule JSON
            </li>
            {discountRule.isActive ? (
              <>
                <Divider />
                <Deactivate
                  onClick={() => {
                    updateActive(false)
                  }}
                >
                  <OffIcon /> Deactivate rule
                </Deactivate>
              </>
            ) : (
              <>
                <Divider />
                <Activate
                  onClick={() => {
                    updateActive(true)
                  }}
                >
                  <OnIcon /> Activate rule
                </Activate>
              </>
            )}
          </ActionDropdownButton>
        </PageHeader>
        <>
          {duplicateCartRule && (
            <DuplicateCartRule
              cartRuleData={discountRule}
              logicState={logicState}
              setDuplicateCartRule={setDuplicateCartRule}
            />
          )}
        </>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Logic rules</Tab>
          </TabList>
          <TabPanel>
            <CartRuleGeneralUpdateInput cartRule={discountRule} control={control} errors={errors} />
          </TabPanel>
          <TabPanel>
            <LogicRules state={logicState} setState={setLogicState} />
          </TabPanel>
        </Tabs>
      </form>
    </Box>
  )
}
