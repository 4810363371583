import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { RULE_TYPE } from "lib/types/common"
import {
  DiscountCodeRuleSearchHit,
  DiscountExternalRuleSearchHit,
  DiscountRuleSearchHit
} from "@lib/types/generated/graphql-types"

import DELETE_DISCOUNT_CODE_RULE from "graphql/mutations/discount/DeleteDiscountCodeRule"
import DELETE_CART_RULE from "graphql/mutations/discount/DeleteCartRule"
import DELETE_DISCOUNT_EXTERNAL_RULE from "graphql/mutations/discount/DeleteDiscountExternalRule"

import alertActions from "lib/store/services/Alert/AlertSlice"
import Popup from "components/Ui/Popup"
import ActionButton from "components/Ui/Table/ActionButton"

type Props = {
  discountRule?: DiscountCodeRuleSearchHit | DiscountRuleSearchHit | DiscountExternalRuleSearchHit
  // eslint-disable-next-line
  discountRules: any
  setDiscountRules:
    | Dispatch<SetStateAction<DiscountCodeRuleSearchHit[]>>
    | Dispatch<SetStateAction<DiscountExternalRuleSearchHit[]>>
    | Dispatch<SetStateAction<DiscountRuleSearchHit[]>>
  type: RULE_TYPE
}

const DeleteDiscountRule = ({ discountRule, discountRules, setDiscountRules, type }: Props) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [deleteRule, setDeleteRule] = useState<
    DiscountCodeRuleSearchHit | DiscountRuleSearchHit | DiscountExternalRuleSearchHit | undefined
  >()
  const dispatch = useAppDispatch()

  const getMutation = () => {
    switch (type) {
      case RULE_TYPE.CART_RULE:
        return DELETE_CART_RULE
      case RULE_TYPE.CODE_RULE:
        return DELETE_DISCOUNT_CODE_RULE
      case RULE_TYPE.EXTERNAL_RULE:
        return DELETE_DISCOUNT_EXTERNAL_RULE
    }
  }

  const [deleteDiscountRule, { loading: deleteLoading }] = useMutation(getMutation(), {
    onCompleted: () => {
      setOpenPopup(false)
      setDiscountRules(
        discountRules.filter(
          (
            discountRule:
              | DiscountCodeRuleSearchHit
              | DiscountRuleSearchHit
              | DiscountExternalRuleSearchHit
          ) => discountRule.id !== deleteRule?.id
        )
      )
      dispatch(
        alertActions.actions.createAlert({
          message: `Discount rule successfully deleted`,
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })
  const onDelete = () => {
    deleteDiscountRule({
      variables: {
        id: deleteRule?.id
      }
    })
  }

  return (
    <>
      <ActionButton
        mode="delete"
        handleOnClick={(e) => {
          e.stopPropagation()
          setDeleteRule(discountRule)
          setOpenPopup(true)
        }}
      />
      {openPopup && (
        <Popup
          title="Delete discount rule"
          subtitle={`Are you sure you want to delete the following discount rule?`}
          item={{
            itemId: deleteRule?.id,
            itemName: deleteRule?.name ?? ""
          }}
          handleCloseClick={(e) => {
            e.stopPropagation()
            setOpenPopup(false)
          }}
          handleOkClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
          loading={deleteLoading}
          type="delete"
        />
      )}
    </>
  )
}

export default DeleteDiscountRule
