import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import GET_RETAIN24_CONFIG from "graphql/queries/config/GetRetain24Config"

import { Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as Retain24Logo } from "images/providers/retain24-logo.svg"

export const getLoadingRow = () => (
  <>
    <Retain24Logo />
    <div>
      <div>
        <Name>Simulation key</Name>
        <Id>retain24-id</Id>
      </div>
    </div>
    <div className="hideOnSmallScreens">
      <p>https://baseurl.se</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setEditId?: Dispatch<SetStateAction<string>>
}

export const Retain24ConfigTableRow = ({ id, setEditId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_RETAIN24_CONFIG, {
    variables: { id: id }
  })

  const retain24Config = data?.getRetain24Config
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>
  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setEditId) setEditId(retain24Config.id)
        dispatch(showEditSidebar())
      }}
    >
      <Retain24Logo />
      <div>
        <div>
          <Name>{retain24Config?.simulationKey}</Name>
          <Id>{retain24Config?.id}</Id>
        </div>
      </div>
      <div className="hideOnSmallScreens">
        <p>{retain24Config?.baseUrl}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(retain24Config?.created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(retain24Config?.updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
