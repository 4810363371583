import gql from "graphql-tag"

const GENERATE_ADYEN_CREDENTIALS = gql`
  mutation generateAdyenWebhookCredentials($id: ID!) {
    generateAdyenWebhookCredentials(id: $id) {
      id
      username
      password
    }
  }
`

export default GENERATE_ADYEN_CREDENTIALS
