import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"

import { ALL_BONUS_PROVIDERS } from "graphql/queries/config/AllBonusProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { CreateBonusConfig } from "./CreateBonusConfig"
import { BonusConfigTable } from "components/Config/Bonus/BonusConfigTable"
import { EditBonusConfig } from "./EditBonusConfig"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as BonusLogo } from "images/icons/hand-holding-dollar.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledBonusLogo = styled(BonusLogo)`
  height: 3.5rem;
  margin-right: 1.5rem;
`

export const BonusConfiguration = () => {
  const [bonusConfigId, setBonusConfigId] = useState<string>("")
  const isSidebarOpen = useSelector(editSidebarOpen)
  const { loading, data, refetch, error } = useQuery(ALL_BONUS_PROVIDERS)
  const bonusProviders = data?.getBonusConfigs
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isSidebarOpen) {
      setBonusConfigId("")
    }
  }, [isSidebarOpen])

  return (
    <Container>
      <PageHeader
        title={
          <>
            <StyledBonusLogo /> Custom bonus providers
          </>
        }
        description="Handle your bonus providers"
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(toggleEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!bonusConfigId && <CreateBonusConfig refetch={refetch} />}
      {bonusConfigId && (
        <EditBonusConfig
          bonusConfigId={bonusConfigId}
          setBonusConfigId={setBonusConfigId}
          refetch={refetch}
        />
      )}
      <BonusConfigTable
        bonusConfigurations={bonusProviders}
        error={error}
        setBonusConfigId={setBonusConfigId}
        loading={loading}
      />
    </Container>
  )
}
