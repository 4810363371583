import React from "react"
import { Controller } from "react-hook-form"
import MultiSelect from "../../Ui/Form/MultiSelect"
import { ErrorMessage } from "@hookform/error-message"
import { Common } from "@lib/types"
import { InputUpdateStoreMarket, Provider } from "@lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"
import ALL_PAYMENT_PROVIDERS from "../../../graphql/queries/config/AllPaymentProviders"
import styled from "styled-components/macro"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"

const InputSection = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

type Props = {
  defaultValue: Common.Option[] | undefined
  control: Control
  errors: FieldErrors
  setValue: UseFormSetValue<InputUpdateStoreMarket>
}

export const PaymentProviders = ({ defaultValue, control, errors, setValue }: Props) => {
  const { data } = useQuery(ALL_PAYMENT_PROVIDERS)

  const paymentProviderOptions = data?.getPaymentProviders?.map((provider: Provider) => ({
    value: provider.id,
    label: provider.id
  }))

  const updatePaymentProviders = (providers: Common.Option[]) =>
    setValue(
      "capabilities.paymentProviders",
      providers.map((provider) => ({
        id: provider.value,
        type: data?.getPaymentProviders?.find((p: Provider) => p.id === provider.value)?.type
      }))
    )

  if (!paymentProviderOptions) return null

  return (
    <InputSection>
      <Label>Payment providers</Label>
      <Controller
        name="capabilities.paymentProviders"
        render={() => (
          <MultiSelect
            defaultValue={defaultValue}
            options={paymentProviderOptions}
            setValue={updatePaymentProviders}
          />
        )}
        rules={{
          required: "This is a required field"
        }}
        control={control}
      />
      <ErrorMessage errors={errors} name="capabilities.paymentProviders" />
    </InputSection>
  )
}
