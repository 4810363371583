import styled from "styled-components/macro"
import TableRow from "../../Ui/Table/TableRow"

export const StyledTableRow = styled(TableRow)`
padding-left: 4rem;
  height: 8rem;
  display: flex;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding-left: 8rem;
  }

`
