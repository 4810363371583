import React, { Dispatch, SetStateAction } from "react"
import { Campaign } from "@lib/types/generated/graphql-types"
import { Name } from "./CampaignGroupRow.styled"
import { ReactComponent as CampaignIcon } from "images/icons/badge-percent.svg"
import { Actions, CampaignProgress, Created, Date } from "../CampaignTable.styled"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import DeleteCampaign from "../DeleteCampaign"
import { StyledTableRow } from "./InlineCampaignRow.styled"
import { GroupedCampaign } from "@lib/types/common"

type Props = {
  campaign: Campaign
  setGroupedCampaigns: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
}

const CampaignGroupRow = ({ campaign, setGroupedCampaigns }: Props) => {
  const navigate = useNavigate()

  return (
    <StyledTableRow handleOnClick={() => navigate(`/campaigns/campaign/${campaign.id}`)}>
      <CampaignIcon />
      <div>
        <div>
          <Name>{campaign.name}</Name>
        </div>
      </div>
      <div>
        <CampaignProgress startDate={campaign.startDate} endDate={campaign.endDate} />
      </div>
      <Date >{dayjs(campaign.startDate).format("YYYY-MM-DD HH:mm")}</Date>
      <Date >{dayjs(campaign.endDate).format("YYYY-MM-DD HH:mm")}</Date>
      <Created>{dayjs(campaign.created).format("YYYY-MM-DD HH:mm")}</Created>
      <Actions>
        <DeleteCampaign campaign={campaign} setGroupedCampaigns={setGroupedCampaigns} />
      </Actions>
    </StyledTableRow>
  )
}

export default CampaignGroupRow
