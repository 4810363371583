import gql from "graphql-tag"

const DELETE_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS = gql`
  mutation deleteExternalEventsDestinationEventBus($eventBusArn: String!) {
    deleteExternalEventsDestinationEventBus(eventBusArn: $eventBusArn) {
      status
    }
  }
`

export default DELETE_EXTERNAL_EVENTS_DESTINATION_EVENT_BUS
