import gql from "graphql-tag"

const START_COMPENSATION = gql`
  mutation startCompensation($compensationId: ID!, $input: InputOrderStartCompensation!) {
    Order {
      startCompensation(compensationId: $compensationId, input: $input) {
        status
      }
    }
  }
`

export default START_COMPENSATION
