import React, { Dispatch, SetStateAction } from "react"
import Popup from "../../../../Ui/Popup"
import { useQuery } from "@apollo/client"
import { ButtonWrapper, ProviderContainer } from "./StatusPopup.styled"
import Status from "../../../../Ui/Status"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as RefreshIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"
import GET_COMPENSATION from "../../../../../graphql/queries/order/GetCompensation"

type Props = {
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  compensationId: string
  refetchOrder: () => void
}

const StatusPopup = ({ setShowStatusPopup, compensationId, refetchOrder }: Props) => {
  const { data, refetch, loading } = useQuery(GET_COMPENSATION, { variables: { compensationId } })

  const compensation = data?.Order?.compensation

  return (
    <Popup
      title={"Provider status"}
      handleCloseClick={() => {
        refetchOrder()
        setShowStatusPopup(false)
      }}
      handleOkClick={() => {
        refetchOrder()
        setShowStatusPopup(false)
      }}
      buttonText={"Ok"}
    >
      <div>
        <ButtonWrapper>
          <PrimaryButton handleClick={() => refetch()} loading={loading}>
            <RefreshIcon />
            Refresh status
          </PrimaryButton>
        </ButtonWrapper>
        {compensation?.bonus && (
          <ProviderContainer>
            <b>Bonus</b>
            <Status status={compensation?.bonusProvider?.status?.current} />
          </ProviderContainer>
        )}
        {compensation?.giftCards?.length > 0 && (
          <ProviderContainer>
            <b>Gift card</b>
            <Status status={compensation?.giftCardProvider?.status?.current} />
          </ProviderContainer>
        )}
        {compensation?.payment && (
          <ProviderContainer>
            <b>Payment</b>
            <Status status={compensation?.paymentProvider?.status?.current} />
          </ProviderContainer>
        )}
      </div>
    </Popup>
  )
}

export default StatusPopup
