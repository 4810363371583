import gql from "graphql-tag"

const SEARCH_TAX_GROUPS = gql`
    query searchTaxGroups($from: Int!, $size: Int!, $sort: [SearchSorting!], $query: AWSJSON) {
        searchTaxGroups(from:$from, size: $size, sort: $sort, query: $query ) {
            total
            hits {
                id
                name
                description
                created
                updated
            }
        }
    }
`

export default SEARCH_TAX_GROUPS
