import gql from "graphql-tag"

const SEARCH_ORDERS_TOTAL_HITS = gql`
  query searchOrders($from: Int!, $size: Int!, $sort: [SearchSorting!], $query: AWSJSON, $trackTotalHits: Boolean) {
    searchOrders(from: $from, size: $size, sort: $sort, query: $query, trackTotalHits: $trackTotalHits) {
      total
      hits {
        storeGroupId
      }
    }
  }
`

export default SEARCH_ORDERS_TOTAL_HITS
