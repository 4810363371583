import React from "react"
import { Order, OrderDelivery, ShippingFee } from "lib/types/generated/graphql-types"
import { DeliveryButtonWrapper, Name, Row } from "../Shared.styled"

import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import CREATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/CreateDelivery"
import UPDATE_DELIVERY from "../../../../../graphql/mutations/order/deliveries/UpdateDelivery"
import { ReactComponent as DeliverIcon } from "../../../../../images/icons/truck-ramp-box.svg"
import { ReactComponent as ShippingIcon } from "../../../../../images/icons/cart-flatbed-boxes.svg"
import Status from "../../../../Ui/Status"
import { handleOrderManagementError } from "../../Helpers/errors"
import { getDeliveryInput } from "../Helpers/helpers"

type Props = {
  shippingFee: ShippingFee
  order: Order
  currencyCode: string
  deliveries: OrderDelivery[]
  refetch: () => void
}

export const ShippingRow = ({ shippingFee, order, currencyCode, deliveries, refetch }: Props) => {
  const notCompletedDelivery = deliveries.find(
    (delivery) => !delivery.started || (delivery.started && !delivery.completed)
  )
  const shippingFeeDelivered = order.Deliveries.filter((delivery) => !!delivery.completed).find(
    (delivery) => delivery?.shippingFees?.find((fee) => fee.shippingFeeId == shippingFee.id)
  )
  const shippingFeeExistsInDelivery = notCompletedDelivery?.shippingFees.find(
    (fee) => fee.shippingFeeId === shippingFee.id
  )

  const [createDelivery, { loading: createLoading }] = useMutation(CREATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const [updateDelivery, { loading: updateLoading }] = useMutation(UPDATE_DELIVERY, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const createOrUpdateOrderDelivery = () => {
    if (!notCompletedDelivery) {
      return createDelivery({
        variables: {
          orderId: order.id,
          input: {
            shippingFees: [shippingFee.id]
          }
        }
      })
    }
    return updateDelivery({
      variables: {
        deliveryId: notCompletedDelivery.id,
        input: {
          ...getDeliveryInput(notCompletedDelivery),
          shippingFees: [
            ...notCompletedDelivery.shippingFees.map((fee) => fee.shippingFeeId),
            shippingFee.id
          ]
        }
      }
    })
  }

  return (
    <Row key={shippingFee.id}>
      <ShippingIcon />
      <div>
        <Name>{shippingFee?.name}</Name>
        <Money
          amount={shippingFee?.salePriceAmount ?? 0}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>
      </div>
      <DeliveryButtonWrapper>
        {shippingFeeDelivered ? (
          <Status status="Delivered" />
        ) : (
          <SecondaryButton
            handleClick={createOrUpdateOrderDelivery}
            disabled={!!shippingFeeExistsInDelivery}
            loading={createLoading || updateLoading}
          >
            <DeliverIcon />
            Deliver
          </SecondaryButton>
        )}
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={shippingFee?.salePriceAmount ?? 0}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </DeliveryButtonWrapper>
    </Row>
  )
}
