import React from "react"
import styled from "styled-components/macro"
import { Control, Controller } from "react-hook-form"
import dayjs from "dayjs"

import { DateWrapper } from "./DiscountRule/DiscountRuleGeneralInput.styled"

import Input from "components/Ui/Form/Input"
import { FieldErrors } from "react-hook-form/dist/types/errors"

const TimePeriodPageSection = styled.div`
  width: 100%;
`

type Props = {
  control: Control<any> // eslint-disable-line
  defaultValues?: { from: string; to: string }
  inSideBar?: boolean
  errors: FieldErrors
  description?: string
}

const validateDates = (selectedDate: string, compareDate: string, errorMessage: string) => {
  return (
    (!!selectedDate && !compareDate) ||
    (!selectedDate && !compareDate) ||
    (!!selectedDate && !!compareDate) ||
    errorMessage
  )
}

const TimePeriodSelection = ({ control, defaultValues, errors, description }: Props) => (
  <TimePeriodPageSection>
    <h2>Time period limitation (Optional)</h2>
    {description ? (
      <p>{description}</p>
    ) : (
      <p>Limit discount rule to only be valid between selected dates</p>
    )}
    <DateWrapper>
      <Controller
        name="from"
        render={({ field }) => (
          <Input
            {...field}
            type="datetime-local"
            label="Valid from"
            placeholder="Date"
            errors={errors}
          />
        )}
        rules={{
          validate: (value, formValues) => validateDates(value, formValues.to, "Missing from date")
        }}
        defaultValue={defaultValues && dayjs(defaultValues?.from).format("YYYY-MM-DD HH:mm")}
        control={control}
      />
      <Controller
        name="to"
        render={({ field }) => (
          <Input
            {...field}
            type="datetime-local"
            label="Valid to"
            placeholder="Date"
            errors={errors}
          />
        )}
        rules={{
          validate: (value, formValues) => validateDates(value, formValues.from, "Missing to date")
        }}
        defaultValue={defaultValues && dayjs(defaultValues?.to).format("YYYY-MM-DD HH:mm")}
        control={control}
      />
    </DateWrapper>
  </TimePeriodPageSection>
)

export default TimePeriodSelection
