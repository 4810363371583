import React, { Dispatch, SetStateAction, useState } from "react"
import DeleteCampaignGroup from "./DeleteCampaignGroup"
import { Campaign, CampaignGroupSearchHit } from "@lib/types/generated/graphql-types"
import { ReactComponent as DownIcon } from "../../../images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "../../../images/icons/chevron-up.svg"
import { Actions, IconWrapper, InfoWrapper, Name, StyledTableRow } from "./CampaignGroupRow.styled"
import { Id } from "../../Ui/Table/Shared.styled"
import InlineCampaignRow from "./InlineCampaignRow"
import { useQuery } from "@apollo/client"
import GET_CAMPAIGN_GROUP from "../../../graphql/queries/campaign/GetCampaignGroup"
import { BlurRow } from "../../Ui/Table/TableBlurLoading"
import { GroupedCampaign } from "@lib/types/common"

type Props = {
  campaignGroupSearchHit: CampaignGroupSearchHit
  setGroupedCampaigns: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
}

const CampaignGroupSearchHitRow = ({
  campaignGroupSearchHit,
  setGroupedCampaigns
}: Props) => {
  const [expanded, setExpanded] = useState(false)

  const { data, loading } = useQuery(GET_CAMPAIGN_GROUP, {
    variables: { id: campaignGroupSearchHit.id }
  })

  const campaignGroupResult = data?.getCampaignGroup

  if (loading)
    return (
      <BlurRow>
        {" "}
        <IconWrapper>
          <DownIcon />
        </IconWrapper>
        <InfoWrapper>
          <Name>
            <div>Name</div>
            <span>id</span>
          </Name>
          <Id>Description</Id>
        </InfoWrapper>
        <Actions>
          <DeleteCampaignGroup
            campaignGroup={campaignGroupResult?.campaignGroup}
            setGroupedCampaigns={setGroupedCampaigns}
          />
        </Actions>
      </BlurRow>
    )
  return (
    <>
      <StyledTableRow $expanded={expanded}>
        <IconWrapper onClick={() => setExpanded(!expanded)}>
          {campaignGroupResult.campaigns.length > 0 ? expanded ? <UpIcon /> : <DownIcon /> : <></>}
        </IconWrapper>
        <InfoWrapper>
          <Name>
            <div>{campaignGroupResult.campaignGroup?.name}</div>
            <span>{campaignGroupResult.campaignGroup.id}</span>
          </Name>
          <Id>{campaignGroupResult.campaignGroup?.description}</Id>
        </InfoWrapper>
        <Actions>
          <DeleteCampaignGroup
            campaignGroup={campaignGroupResult.campaignGroup}
            setGroupedCampaigns={setGroupedCampaigns}
          />
        </Actions>
      </StyledTableRow>
      {expanded &&
        campaignGroupResult?.campaigns?.map((campaign: Campaign) => (
          <InlineCampaignRow
            key={campaign.id}
            campaign={campaign}
            setGroupedCampaigns={setGroupedCampaigns}
          />
        ))}
    </>
  )
}

export default CampaignGroupSearchHitRow
