import React, { useState } from "react"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import { Order } from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"

import GET_STORE_GROUP from "../../../../../graphql/queries/store/GetStoreGroup"

import { Header } from "../Shared.styled"
import { Blur, ButtonWrapper, Summary } from "./OrderSummary.styled"

import Money from "../../../../Money"
import { Item, Label, Value } from "../../../../Ui/List/List"
import FlagIcon from "../../../../Ui/FlagIcon"
import DotLoader from "../../../../Ui/DotLoader"
import CopyButton from "../../../../Ui/CopyButton"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { VariantRow } from "./VariantRow"
import { ShippingRow } from "./ShippingRow"
import CREATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/CreateCompensation"
import GiftCardInput from "./GiftCardInput"
import {
  getCompensationBonusTotals,
  getCompensationGiftCardTotals,
  getCompensationPaymentTotals,
  getDeliveryBonusTotals,
  getDeliveryGiftCardTotals,
  getDeliveryPaymentTotals
} from "../Helpers/helpers"
import AmountInput from "./AmountInput"
import { ReactComponent as PaymentIcon } from "images/icons/credit-card.svg"
import { ReactComponent as BonusIcon } from "images/icons/hand-holding-dollar.svg"
import { NoticeBanner } from "../../../../Ui/Messages/NoticeBanner"
import { handleOrderManagementError } from "../../Helpers/errors"

type Compensation = {
  payment?: { amount: number }
  bonus?: { amount: number }
  giftCards?: { amount: number; giftCardId: string }[]
}

type Props = {
  order: Order
  refetch: () => void
  orderLoading: boolean
}

export const OrderSummary = ({ order, refetch }: Props) => {
  const [compensation, setCompensation] = useState<Compensation>()
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  const notCompletedCompensations = order?.Compensations?.filter(
    (compensation) => !compensation.completed
  )

  const updateCompensationPayment = (amount: number) => {
    if (!amount) {
      const copy = compensation
      delete copy?.payment
      if (copy && Object.keys(copy).length === 0) {
        setCompensation(undefined)
        return
      }
      setCompensation(copy)
      return
    }
    setCompensation({ ...compensation, payment: { amount } })
  }
  const updateCompensationBonus = (amount: number) => {
    if (!amount) {
      const copy = compensation
      delete copy?.bonus
      if (copy && Object.keys(copy).length === 0) {
        setCompensation(undefined)
        return
      }
      setCompensation(copy)
      return
    }
    setCompensation({ ...compensation, bonus: { amount } })
  }
  const updateCompensationGiftCard = (amount: number, giftCardId: string) => {
    const giftCardExists = compensation?.giftCards?.find(
      (giftCard) => giftCard.giftCardId === giftCardId
    )
    if (!amount) {
      const newGiftCards = compensation?.giftCards?.filter(
        (giftCard) => giftCard.giftCardId !== giftCardId
      )
      if (newGiftCards?.length === 0) {
        const copy = compensation
        delete copy?.giftCards
        if (copy && Object.keys(copy).length === 0) {
          setCompensation(undefined)
          return
        }
        setCompensation(copy)
        return
      }
      setCompensation({
        ...compensation,
        giftCards: newGiftCards
      })
      return
    }
    setCompensation({
      ...compensation,
      giftCards: giftCardExists
        ? compensation?.giftCards?.map((giftCard) =>
            giftCard.giftCardId === giftCardId
              ? {
                  giftCardId: giftCardId,
                  amount: amount
                }
              : giftCard
          )
        : [...(compensation?.giftCards ?? []), { giftCardId: giftCardId, amount: amount }]
    })
  }

  const [createCompensation, { loading: createLoading }] = useMutation(CREATE_COMPENSATION, {
    onCompleted: () => {
      setCompensation(undefined)
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const createOrderCompensation = () => {
    return createCompensation({
      variables: {
        orderId: order.id,
        input: {
          ...(compensation?.payment && { payment: compensation.payment }),
          ...(compensation?.bonus && { bonus: compensation.bonus }),
          ...(compensation?.giftCards &&
            compensation?.giftCards?.length > 0 && { giftCards: compensation.giftCards })
        }
      }
    })
  }

  const completedDeliveries = order?.Deliveries?.filter((delivery) => !!delivery.completed)
  const completedCompensations = order?.Compensations?.filter(
    (compensation) => !!compensation.completed
  )
  const orderLinesInDeliveries = completedDeliveries?.flatMap((delivery) => delivery.orderLines)
  const shippingFeesInDeliveries = completedDeliveries?.flatMap((delivery) => delivery.shippingFees)

  const totalPayment =
    getDeliveryPaymentTotals(completedDeliveries) -
    getCompensationPaymentTotals(completedCompensations)
  const totalGiftCard =
    getDeliveryGiftCardTotals(completedDeliveries) -
    getCompensationGiftCardTotals(completedCompensations)
  const totalBonus =
    getDeliveryBonusTotals(completedDeliveries) - getCompensationBonusTotals(completedCompensations)

  const refundExists = order.Refunds.filter((refund) => refund.completed).length > 0

  const giftCardsInDeliveries = completedDeliveries.flatMap((delivery) => delivery.giftCards)

  const filteredGiftCardsInDeliveries = [
    ...giftCardsInDeliveries
      .reduce((map, current) => {
        const { giftCardId } = current
        const grouped = map.get(giftCardId)
        if (!grouped) {
          map.set(giftCardId, { ...current })
        } else {
          map.set(giftCardId, { ...grouped, amount: grouped.amount + current.amount })
        }
        return map
      }, new Map())
      .values()
  ]
  const compensatedGiftCards = completedCompensations?.flatMap(
    (compensation) => compensation.giftCards
  )

  const filteredGiftCardsCompensated = [
    ...compensatedGiftCards
      .reduce((map, current) => {
        const giftCardId = current?.giftCardId
        const grouped = map.get(giftCardId)
        if (!grouped) {
          map.set(giftCardId, { ...current })
        } else {
          map.set(giftCardId, { ...grouped, amount: grouped.amount + current?.amount })
        }
        return map
      }, new Map())
      .values()
  ]

  if (!order)
    return (
      <Blur>
        <Header>
          <h2>Order: #1234567</h2>
        </Header>
        <Summary>
          <Item flexibleHeight>
            <Label>E-mail:</Label>
            <Value>
              <>
                john.doe@email.com
                <CopyButton string={""} />
              </>
            </Value>
          </Item>
          <Item>
            <Label>Store group:</Label>
            <Value>store group</Value>
          </Item>
          <Item>
            <Label>Store market:</Label>
            <Value flex>
              <>Sweden</>
            </Value>
          </Item>
          <Item>
            <Label>Order date:</Label>
            <Value>2000-00-00 00:00</Value>
          </Item>
          <Item>
            <Label>Total order value:</Label>
            <Value>SEK 200.00</Value>
          </Item>
        </Summary>
      </Blur>
    )
  if (!order) return null
  return (
    <>
      <Header>
        <h2>Order: #{order?.reference}</h2>
      </Header>
      <Summary>
        <Item flexibleHeight>
          <Label>E-mail:</Label>
          <Value>
            <>
              {order?.billingAddress?.email}
              <CopyButton string={order?.billingAddress?.email} />
            </>
          </Value>
        </Item>
        <Item>
          <Label>Store group:</Label>
          <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
        </Item>
        <Item>
          <Label>Store market:</Label>
          <Value flex>
            <>
              <FlagIcon countryCode={order?.countryCode} />
              {getCountryName(order?.countryCode)}
            </>
          </Value>
        </Item>
        <Item>
          <Label>Order date:</Label>
          <Value>{dayjs(order?.date).format("YYYY-MM-DD HH:mm")}</Value>
        </Item>
        {totalGiftCard > 0 ? (
          <Item>
            <Label>Gift card total:</Label>
            <Value>
              <Money
                amount={parseInt(`${totalGiftCard}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Item>
        ) : (
          <></>
        )}
        {totalBonus > 0 ? (
          <Item>
            <Label>Bonus total:</Label>
            <Value>
              <Money
                amount={parseInt(`${totalBonus}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Item>
        ) : (
          <></>
        )}
        {totalPayment > 0 ? (
          <Item>
            <Label>Payment total:</Label>
            <Value>
              <Money
                amount={parseInt(`${totalPayment}`)}
                currencyUnit={order?.currencyCode as DineroFactory.Currency}
              />
            </Value>
          </Item>
        ) : (
          <></>
        )}
      </Summary>
      <h2>Compensation</h2>
      {notCompletedCompensations?.length === 0 && (
        <>
          {!refundExists && (totalPayment > 0 || totalBonus > 0 || totalGiftCard > 0) ? (
            orderLinesInDeliveries?.length > 0 || shippingFeesInDeliveries?.length > 0 ? (
              <>
                {totalPayment > 0 && (
                  <AmountInput
                    name="paymentAmount"
                    label="Payment"
                    currencyCode={order.currencyCode}
                    updateFunction={updateCompensationPayment}
                    amount={compensation?.payment?.amount || 0}
                    icon={<PaymentIcon />}
                    maxAmount={totalPayment}
                  />
                )}
                {order.totals.bonusTotal > 0 && (
                  <AmountInput
                    name="bonusAmount"
                    label="Bonus"
                    currencyCode={order.currencyCode}
                    updateFunction={updateCompensationBonus}
                    amount={compensation?.bonus?.amount || 0}
                    icon={<BonusIcon />}
                    maxAmount={totalBonus}
                  />
                )}
                {filteredGiftCardsInDeliveries.length > 0 && (
                  <GiftCardInput
                    orderGiftCard={order.GiftCard}
                    currencyUnit={order.currencyCode}
                    updateFunction={updateCompensationGiftCard}
                    compensationGiftCards={compensation?.giftCards ?? undefined}
                    compensatedGiftCards={filteredGiftCardsCompensated ?? []}
                  />
                )}
              </>
            ) : (
              <div>
                <h2>Order lines ({orderLinesInDeliveries?.length ?? 0})</h2>{" "}
                <NoticeBanner type="important">
                  Missing completed deliveries for orderlines
                </NoticeBanner>
                <h2>Shipping ({shippingFeesInDeliveries?.length ?? 0})</h2>{" "}
                <NoticeBanner type="important">
                  Missing completed deliveries for shipping
                </NoticeBanner>
              </div>
            )
          ) : (
            <div>
              {totalPayment === 0 && totalBonus === 0 && totalGiftCard === 0 ? (
                <NoticeBanner type="important">{`Missing amount to compensate`}</NoticeBanner>
              ) : (
                <NoticeBanner type="important">{`Can't create compensation when refund exists`}</NoticeBanner>
              )}
            </div>
          )}
        </>
      )}
      {notCompletedCompensations?.length === 0 ? (
        <>
          {!refundExists && (totalPayment > 0 || totalBonus > 0 || totalGiftCard > 0) ? (
            <ButtonWrapper>
              <PrimaryButton
                handleClick={createOrderCompensation}
                loading={createLoading}
                disabled={Object.keys(compensation ?? {}).length === 0}
              >
                Create compensation draft
              </PrimaryButton>
            </ButtonWrapper>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <h2>Delivered order lines ({orderLinesInDeliveries.length})</h2>
          {orderLinesInDeliveries?.map((orderLine) => (
            <VariantRow
              key={orderLine?.orderLineId}
              orderLine={orderLine}
              order={order}
              refetch={refetch}
              currencyCode={order?.currencyCode}
              compensations={order?.Compensations}
            />
          ))}
          <h2>Shipping ({shippingFeesInDeliveries?.length ?? 0})</h2>
          {shippingFeesInDeliveries?.map((shippingFee) => (
            <ShippingRow
              key={shippingFee?.shippingFeeId}
              shippingFee={shippingFee}
              order={order}
              refetch={refetch}
              currencyCode={order?.currencyCode}
              compensations={order?.Compensations}
            />
          ))}
        </>
      )}
    </>
  )
}
