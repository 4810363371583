import React from "react"

import { Container, SearchIcon, Actions, ResetSearch } from "./Search.styled"

import { Loader } from "./Loader"
import Input from "./Form/Input"

type Props = {
  handleOnChange: (input: string) => void
  placeholder?: string
  loading?: boolean
  defaultValue?: string
  inSideBar?: boolean
  padding?: string
}

const Search = ({
  handleOnChange,
  placeholder,
  loading,
  defaultValue,
  inSideBar,
  padding = "2rem",
  ...props
}: Props) => (
  <Container $inSidebar={inSideBar} $padding={padding} {...props}>
    <SearchIcon />
    <Input
      name={"search"}
      onChange={(e: React.FormEvent<HTMLInputElement>) => handleOnChange(e.currentTarget.value)}
      placeholder={placeholder || "Search..."}
      overrideDisabled={true}
      value={defaultValue}
    />
    <Actions>
      {loading ? (
        <Loader color="black" />
      ) : (
        <ResetSearch $show={defaultValue !== ""} onClick={() => handleOnChange("")} />
      )}
    </Actions>
  </Container>
)

export default Search
