import gql from "graphql-tag"

const CREATE_OR_UPDATE_CAMPAIGN_GROUP = gql`
  mutation createOrUpdateCampaignGroup($id: ID!, $name: String!, $description: String!) {
    createOrUpdateCampaignGroup(
      id: $id
      campaignGroup: { name: $name, description: $description }
    ) {
        id
        name
        description
        created
        updated
    }
  }
`

export default CREATE_OR_UPDATE_CAMPAIGN_GROUP
