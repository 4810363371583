import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputKlarnaCheckoutConfig } from "lib/types/generated/graphql-types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_OR_UPDATE_KLARNA_CHECKOUT_CONFIG from "graphql/mutations/config/CreateOrUpdateKlarnaCheckoutConfig"
import VALIDATE_KLARNA from "graphql/queries/config/ValidateKlarnaConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { KlarnaCheckoutConfigInputs } from "components/Config/KlarnaCheckout/KlarnaCheckoutConfigInputs"
import Popup from "components/Ui/Popup"

type Props = {
  refetch: () => void
}

interface ExtendedInputKlarnaConfig extends InputKlarnaCheckoutConfig {
  id: string
}

function CreateKlarnaConfig({ refetch }: Props) {
  const dispatch = useAppDispatch()
  const [validationError, setValidationError] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputKlarnaConfig>({ mode: "onBlur" })

  const [addKlarnaConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_KLARNA_CHECKOUT_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          baseUrl: "",
          merchantId: "",
          password: "",
          username: "",
          klarnaShippingAssistantEnabled: false
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Klarna checkout configuration successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )
  const [validateKlarna, { loading }] = useLazyQuery(VALIDATE_KLARNA)

  const onSubmit: SubmitHandler<ExtendedInputKlarnaConfig> = (data) => {
    validateKlarna({
      variables: {
        baseUrl: data.baseUrl,
        username: data.username,
        password: data.password
      }
    }).then((response) => {
      if (response.data.Configuration.validate.klarnaCheckout.valid) {
        addKlarnaConfig({ variables: { ...data } })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputKlarnaConfig> = (data) => {
    addKlarnaConfig({ variables: { ...data } })
    setValidationError(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Klarna checkout configuration">
          {validationError ? (
            <Popup
              title="Validation error"
              subtitle="Couldn't validate provided credentials with Klarna. Do you want to save the Klarna checkout config anyway? "
              buttonText="Yes, save"
              handleOkClick={handleSubmit(directSubmit)}
              handleCloseClick={() => setValidationError(false)}
            />
          ) : (
            <></>
          )}
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <KlarnaCheckoutConfigInputs
          control={control}
          errors={errors}
          setValue={setValue}
          showId={true}
        />
      </EditSidebar>
    </form>
  )
}

export default CreateKlarnaConfig
