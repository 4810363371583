import gql from "graphql-tag"

const TAG_VALUES = gql`
  query tagValues($key: String!, $prefix: String!) {
    tagValues(key: $key, prefix: $prefix) {
      key
      values
    }
  }
`

export default TAG_VALUES
