import React, { Dispatch, SetStateAction, useState } from "react"
import { InputWalleyCheckoutConfig } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import { DELETE_WALLEY_CHECKOUT_CONFIG } from "graphql/mutations/config/Providers/Walley/DeleteWalleyCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"

import { ReactComponent as Logo } from "images/providers/walley-logo.svg"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CREATE_OR_UPDATE_WALLEY_CHECKOUT_CONFIG } from "../../../../graphql/mutations/config/Providers/Walley/CreateOrUpdateWalleyCheckoutConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { VALIDATE_WALLEY } from "../../../../graphql/queries/config/Providers/Walley/ValidateWalleyConfig"
import { SubmitHandler, useForm } from "react-hook-form"
import Popup from "../../../../components/Ui/Popup"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"
import WalleyConfigInputs from "../../../../components/Config/Walley/WalleyConfigInputs"
import { GET_WALLEY_CHECKOUT_CONFIG } from "../../../../graphql/queries/config/Providers/Walley/GetWalleyCheckoutConfig"

type Props = {
  refetch: () => void
  walleyConfigId: string
  setWalleyConfig: Dispatch<SetStateAction<string>>
}

interface ExtendedInputWalleyCheckoutConfig extends InputWalleyCheckoutConfig {
  id: string
}

export const EditWalleyConfig = ({ refetch, walleyConfigId, setWalleyConfig }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const closeSidebar = () => {
    setWalleyConfig("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputWalleyCheckoutConfig>({ mode: "onBlur" })

  const { data } = useQuery(GET_WALLEY_CHECKOUT_CONFIG, {variables: {id: walleyConfigId}})

  const walleyConfig = data.getWalleyCheckoutConfig

  const [updateWalleyConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_WALLEY_CHECKOUT_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Walley Checkout config successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateWalley, { loading }] = useLazyQuery(VALIDATE_WALLEY)

  const onSubmit: SubmitHandler<ExtendedInputWalleyCheckoutConfig> = (data) => {
    validateWalley({
      variables: {
        input: {
          storeId: data.storeId,
          baseUrl: data.baseUrl,
          scope: data.scope,
          ...(data.profileName && { profileName: data.profileName }),
          clientId: data.clientId,
          secret: data.secret,
          deliveryModuleEnabled: data.deliveryModuleEnabled
        }
      }
    }).then((response) => {
      if (response.data.Configuration.validate.walleyCheckout.valid) {
        updateWalleyConfig({
          variables: { ...data, ...(data.profileName && { profileName: data.profileName }), id: walleyConfig.id }
        })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputWalleyCheckoutConfig> = (data) => {
    updateWalleyConfig({
      variables: {
        ...data,
        ...(data.profileName && { profileName: data.profileName }),
        id: walleyConfig.id
      }
    })
    setValidationError(false)
  }
  if (!walleyConfig) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader
          icon={<Logo />}
          title={walleyConfig?.storeId}
          id={walleyConfig?.id}
          cancelEvent={() => closeSidebar()}
        >
          <DeleteButton
            id={walleyConfig?.id}
            name={walleyConfig?.storeId}
            entity={"Walley Checkout configuration"}
            redirectUrl="/settings/providers/walley"
            mutation={DELETE_WALLEY_CHECKOUT_CONFIG}
            refetch={refetch}
            postDeleteFunction={() => setWalleyConfig("")}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Walley. Do you want to save the Walley checkout config anyway? "
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <WalleyConfigInputs
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValues={walleyConfig}
            />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={walleyConfig.id}
              providerType={PROVIDER_TYPE.WALLEY_CHECKOUT}
              providerCategory={PROVIDER_CATEGORY.PAYMENT}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}
