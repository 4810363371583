import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import {
  Actions,
  AddTagConditionButton,
  AddTagRuleButton,
  ButtonWrapper,
  Conditions,
  EmptyTags,
  Label,
  OperatorDiv,
  OperatorsWrapper,
  TagHeader,
  TagKey,
  TagRow,
  TagRuleTitle,
  Tags,
  TagSelect,
  TagValue,
  TagValues,
  TagWrapper
} from "./ProductVariantRules.styled"
import { Tooltip } from "components/Ui/Tooltip"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as GearIcon } from "images/icons/gear.svg"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { ReactComponent as TagIcon } from "images/icons/tag.svg"
import { EditPopup } from "components/Ui/EditPopup/EditPopup"
import { Common } from "@lib/types"
import { v4 as uuidv4 } from "uuid"
import ALL_TAG_KEYS from "graphql/queries/tags/AllTagKeys"
import Options from "./Options"
import { CampaignTagRule, Operator } from "lib/types/campaign"
import PrimaryButton from "../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../Ui/Buttons/SecondaryButton"
import Status from "../../Ui/Status"
import { isSuperUser } from "helpers/user"

type Props = {
  tagRules: CampaignTagRule[]
  setTagRules: Dispatch<SetStateAction<CampaignTagRule[]>>
  isCampaignOngoing?: boolean
}

type PopupState = {
  open: boolean
  tagRuleIndex: number
  editConditionKey?: string
  editConditionInclude?: boolean
}

const ProductVariantRules = ({ tagRules, setTagRules, isCampaignOngoing }: Props) => {
  const [tagKey, setTagKey] = useState<Common.Option | undefined>()
  const [tagValues, setTagValues] = useState<Common.Option[]>([])
  const [popupState, setPopupState] = useState<PopupState>({
    open: false,
    tagRuleIndex: 0
  })

  const { data } = useQuery(ALL_TAG_KEYS)
  const getTagKeyOptions = () => {
    return data?.tagKeys?.keys
      .filter((key: string) => {
        const addedKeys = tagRules[popupState.tagRuleIndex].tagConditions.filter(
          (condition) => condition.key === key
        )
        return addedKeys.length < 2
      })
      .map((key: string) => ({
        value: key,
        label: key
      }))
  }

  const tagKeyAlreadyAdded = (include: boolean) => {
    const added = tagRules[popupState.tagRuleIndex].tagConditions.filter(
      (condition) => condition.key === tagKey?.value && condition.include === include
    )
    return added.length > 0
  }

  const addTagCondition = (include: boolean) => {
    if (tagKey && tagValues.length > 0) {
      setTagRules(() =>
        [...tagRules].map((tr, index) =>
          index === popupState.tagRuleIndex
            ? {
                ...tr,
                tagConditions: [
                  ...tr.tagConditions,
                  {
                    key: tagKey.value,
                    values: tagValues.map((t) => t.value),
                    operator: Operator.AND,
                    include
                  }
                ]
              }
            : tr
        )
      )
    }
  }

  const updateTagCondition = () => {
    if (tagKey && tagValues.length > 0) {
      setTagRules(() =>
        [...tagRules].map((tr, index) =>
          index === popupState.tagRuleIndex
            ? {
                ...tr,
                tagConditions: [
                  ...tr.tagConditions.map((condition) =>
                    condition.key === popupState.editConditionKey &&
                    condition.include === popupState.editConditionInclude
                      ? {
                          ...condition,
                          values: tagValues.map((rule) => rule.value)
                        }
                      : condition
                  )
                ]
              }
            : tr
        )
      )
    }
  }

  const addTagRule = () => {
    setTagRules((prev) => [...prev, { tagConditions: [], operator: Operator.AND }])
  }

  const removeTagRule = (index: number) => {
    setTagRules((prev) => prev.filter((_, i) => index !== i))
  }

  const removeTagCondition = (key: string, tagRuleIndex: number, include: boolean) => {
    const listClone = tagRules[tagRuleIndex].tagConditions.filter(
      (condition) => !(condition.key === key && condition.include === include)
    )
    setTagRules(() =>
      [...tagRules].map((tr, index) =>
        index === tagRuleIndex
          ? {
              ...tr,
              tagConditions: listClone
            }
          : tr
      )
    )
  }

  const clearForm = () => {
    setTagValues([])
    setTagKey(undefined)
    setPopupState({ open: false, tagRuleIndex: 0 })
  }

  useEffect(() => {
    if (popupState.editConditionKey) {
      setTagValues(
        tagRules[popupState.tagRuleIndex].tagConditions
          .find(
            (condition) =>
              condition.key === popupState?.editConditionKey &&
              condition.include === popupState?.editConditionInclude
          )
          ?.values.map((value) => ({ value: value, label: value })) ?? []
      )
    }
  }, [popupState.editConditionKey])
  return (
    <div>
      <h2>Product rules</h2>
      <Conditions>
        <Tags>
          {tagRules?.map((tagRule, i) => (
            <div key={uuidv4()}>
              {i !== 0 && (
                <OperatorsWrapper>
                  <OperatorDiv $operator={"or"}>
                    <span>OR</span>
                  </OperatorDiv>
                </OperatorsWrapper>
              )}
              <TagWrapper>
                <TagRuleTitle>
                  <span>Rule {i + 1}</span>{" "}
                  {i !== 0 && (
                    <TrashIcon
                      onClick={() => removeTagRule(i)}
                      data-tooltip-id="remove-tag-rule"
                      data-tooltip-content="Remove rule"
                    />
                  )}
                </TagRuleTitle>

                {tagRule.tagConditions.length > 0 ? (
                  <>
                    {tagRule.tagConditions.map((tagCondition, index) => {
                      return (
                        <div key={uuidv4()}>
                          {index !== 0 && (
                            <OperatorsWrapper>
                              <OperatorDiv $operator={"and"}>
                                <span>AND</span>
                              </OperatorDiv>
                            </OperatorsWrapper>
                          )}
                          <TagRow>
                            <TagHeader>
                              <TagKey>
                                <TagIcon />
                                <span>{tagCondition.key}</span>
                                <Status status={tagCondition.include ? "Include" : "Exclude"} />
                              </TagKey>
                              <Actions>
                                <Tooltip id="edit-tag-value" />
                                {!isCampaignOngoing && (
                                  <>
                                    <GearIcon
                                      data-tooltip-id="edit-tag-value"
                                      data-tooltip-content="Edit tag values"
                                      onClick={() => {
                                        setPopupState({
                                          open: true,
                                          tagRuleIndex: i,
                                          editConditionKey: tagCondition.key,
                                          editConditionInclude: tagCondition.include
                                        })
                                      }}
                                    />
                                    <Tooltip id="remove-tag-condition" />
                                    <TrashIcon
                                      data-tooltip-id="remove-tag-condition"
                                      data-tooltip-content="Remove tag"
                                      onClick={() =>
                                        removeTagCondition(
                                          tagCondition.key,
                                          i,
                                          tagCondition.include
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </Actions>
                            </TagHeader>
                            <TagValues key={uuidv4()}>
                              {tagCondition.values?.map((value) => {
                                return (
                                  <TagValue key={uuidv4()}>
                                    <Label>{value}</Label>
                                  </TagValue>
                                )
                              })}
                            </TagValues>
                          </TagRow>
                        </div>
                      )
                    })}
                  </>
                ) : (
                  <EmptyTags>
                    <p>Make your product selection here</p>
                  </EmptyTags>
                )}
                <AddTagConditionButton
                  type="button"
                  data-tooltip-id="add-tag-key"
                  data-tooltip-content="Add new tag"
                  disabled={isCampaignOngoing}
                  onClick={() => {
                    setPopupState({ open: true, tagRuleIndex: i })
                  }}
                >
                  <PlusIcon />
                </AddTagConditionButton>
              </TagWrapper>
            </div>
          ))}
          <ButtonWrapper>
            <AddTagRuleButton
              overrideDisabled={!isSuperUser() && tagRules[0]?.tagConditions?.length !== 0}
              disabled={tagRules[0]?.tagConditions?.length === 0 || isCampaignOngoing}
              handleClick={(e) => {
                e?.preventDefault()
                addTagRule()
              }}
            >
              <PlusIcon />
              <b>Add rule</b>
            </AddTagRuleButton>
          </ButtonWrapper>

          {popupState.open && (
            <EditPopup
              title="Add new tag"
              hideOkButton={true}
              handleCloseClick={() => {
                clearForm()
              }}
            >
              <TagSelect
                options={getTagKeyOptions()}
                setValue={setTagKey}
                disabled={!!popupState?.editConditionKey}
                defaultValue={
                  popupState?.editConditionKey
                    ? {
                        value: popupState?.editConditionKey,
                        label: popupState?.editConditionKey
                      }
                    : undefined
                }
              />
              {tagKey?.value && !popupState?.editConditionKey && (
                <Options
                  tagKey={tagKey.value}
                  tagValues={tagValues}
                  setTagValues={setTagValues}
                  tagConditions={tagRules[popupState.tagRuleIndex].tagConditions}
                />
              )}
              {popupState?.editConditionKey && (
                <Options
                  tagKey={popupState?.editConditionKey}
                  tagValues={tagValues}
                  setTagValues={setTagValues}
                  tagConditions={tagRules[popupState.tagRuleIndex].tagConditions}
                />
              )}
              <br />
              {popupState?.editConditionKey ? (
                <PrimaryButton
                  disabled={tagValues.length === 0}
                  handleClick={() => {
                    updateTagCondition()
                    clearForm()
                  }}
                >
                  Save
                </PrimaryButton>
              ) : (
                <>
                  <PrimaryButton
                    overrideDisabled={
                      (!isSuperUser() && tagValues.length !== 0) || tagKeyAlreadyAdded(false)
                    }
                    disabled={tagValues.length === 0 || tagKeyAlreadyAdded(true)}
                    handleClick={() => {
                      clearForm()
                      addTagCondition(true)
                    }}
                  >
                    Include selected tags
                  </PrimaryButton>
                  <SecondaryButton
                    overrideDisabled={
                      (!isSuperUser() && tagValues.length !== 0) || tagKeyAlreadyAdded(false)
                    }
                    disabled={tagValues.length === 0 || tagKeyAlreadyAdded(true)}
                    handleClick={() => {
                      clearForm()
                      addTagCondition(false)
                    }}
                  >
                    Exclude selected tags
                  </SecondaryButton>
                </>
              )}
            </EditPopup>
          )}
        </Tags>
      </Conditions>
    </div>
  )
}

export default ProductVariantRules
