import styled from "styled-components/macro"
import SingleSelect from "components/Ui/Form/SingleSelect"
import TableRowSimple from "components/Ui/Table/TableRowSimple"
import { TresholdCell } from "components/Discount/Threshold.styled"
import MultiSelect from "../../Ui/Form/MultiSelect"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SelectWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: flex-start;

  button {
    margin-bottom: 0.3rem;
  }

  margin-bottom: 2rem;
`

export const StyledMultiSelect = styled(MultiSelect)`
  div {
    div {
      div {
        input {
          z-index: 10000 !important;
        }
      }
    }
  }

`

export const CountryInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  img {
    height: 1.2rem;
    margin-right: 0.3rem;
  }
`

export const CountrySelect = styled(SingleSelect)`
  input {
    z-index: 1000 !important;
  }
`

export const StoreMarketRow = styled(TableRowSimple)`
  background: rgba(255, 255, 255, 0.5);
  padding: 1.2rem 2rem 1rem;
  width: 100%;
`

export const StoreGroupLabel = styled(TresholdCell)`
  margin-bottom: 0.5rem;
`

export const Value = styled(TresholdCell)`

`

export const Wrapper = styled.div`
width: 100%;
`

export const StoreMarketsTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 30rem;

  p {
    text-align: center;
  }
`

export const Description = styled.span`
  font-size: 1.4rem;
  color: ${(p) => p.theme.colors.greyDarker};
  margin-bottom: 1.5rem;
`
