import TableHeader from "components/Ui/Table/TableHeader"
import styled from "styled-components/macro"

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
  line-height: 1.4rem;

  div {
    padding-right: 1rem;
  }
`

export const Container = styled.div`
  a {
    text-decoration: underline;
  }
`

export const Header = styled.div`
  margin-bottom: 5rem;
`

export const Title = styled.h2`
  margin: 0 0 1rem;
  font-size: 2.4rem;
  display: flex;
`

export const Name = styled.div`
  ${(p) => p.theme.bold};
`

export const Period = styled.span`
  font-size: 1.3rem;
  text-transform: lowercase;
`
