import gql from "graphql-tag"

const ALL_API_CLIENTS = gql`
  query apiClients(
    $limit: Int!
    $token: String) {
    apiClients(
      limit: $limit
      token: $token
    ) {
      apiClients {
        clientId
        name
      }
      nextToken
    }
  }
`

export default ALL_API_CLIENTS
