import React, { Dispatch, SetStateAction } from "react"
import { RULE_TYPE } from "lib/types/common"
import { isSuperUser } from "helpers/user"

import { Wrapper, Button, Title } from "./TypeSelector.styled"

import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as ShoppingCartIcon } from "images/icons/cart-shopping.svg"
import { ReactComponent as BrinkIcon } from "images/icons/badge-percent.svg"
import { ReactComponent as ExternalIcon } from "images/icons/arrow-up-right-from-square.svg"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

type Props = {
  setGeneralStep: () => void
  setSelectedType: Dispatch<SetStateAction<RULE_TYPE | undefined>>
}

function TypeSelector({ setGeneralStep, setSelectedType }: Props) {

  const handleClick = (type: RULE_TYPE) => {
    setSelectedType(type)
    setGeneralStep()
  }

  return (
    <>
      <Title>Select the type of discount rule you want to create:</Title>
      <Wrapper>
        <Button onClick={() => isSuperUser() && handleClick(RULE_TYPE.CODE_RULE)}>
          <BrinkIcon />
          <h2>Brink code rule</h2>
          <p>Discount rule with discount codes generated in Brink</p>
          <PrimaryButton>
            <PlusIcon /> Create
          </PrimaryButton>
        </Button>
        <Button onClick={() => isSuperUser() && handleClick(RULE_TYPE.EXTERNAL_RULE)}>
          <ExternalIcon />
          <h2>External code rule</h2>
          <p>Discount rule with discount codes generated from external provider</p>
          <PrimaryButton>
            <PlusIcon /> Create
          </PrimaryButton>
        </Button>
        <Button onClick={() => isSuperUser() && handleClick(RULE_TYPE.CART_RULE)}>
          <ShoppingCartIcon />
          <h2>Cart rule</h2>
          <p>Discount rule applicable in cart without code</p>
          <PrimaryButton>
            <PlusIcon /> Create
          </PrimaryButton>
        </Button>
      </Wrapper>
    </>
  )
}

export default TypeSelector
