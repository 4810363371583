import React, { Dispatch, SetStateAction } from "react"
import EditSidebar from "../../../components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "../../../components/Ui/EditSidebar/EditSidebarHeader"
import EditSidebarSection from "../../../components/Ui/EditSidebar/EditSidebarSection"
import SingleSelect from "../../../components/Ui/Form/SingleSelect"
import { TaxGroup } from "@lib/types/generated/graphql-types"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import { Common } from "@lib/types"

type Props = {
  setValue: Dispatch<SetStateAction<Common.Option | undefined>>
  defaultValue: TaxGroup
  updateTaxGroupFunction: () => void
  createLoading: boolean
  options: Common.Option[]
}
export const UpdateShippingTax = ({
  setValue,
  defaultValue,
  updateTaxGroupFunction,
  createLoading,
  options
}: Props) => {
  const defaultOption = { label: defaultValue?.name, value: defaultValue?.id }
  return (
    <EditSidebar>
      <EditSidebarHeader title="Select shipping tax group">
        <PrimaryButton
          type="submit"
          handleClick={() => updateTaxGroupFunction()}
          loading={createLoading}
        >
          Save
        </PrimaryButton>
      </EditSidebarHeader>
      <EditSidebarSection>
        <SingleSelect
          options={options}
          setValue={setValue}
          defaultValue={defaultOption}
        />
      </EditSidebarSection>
    </EditSidebar>
  )
}
