import gql from "graphql-tag"

const USER = gql`
    query user(
        $username: AWSEmail!
    ) {
        user(
            username: $username
        ) {
            created
            enabled
            familyName
            givenName
            status
            updated
            username
            groups {
                name
                description
            }
        }}
`

export default USER