import gql from "graphql-tag"

const GET_INVENTORY_STORE_MARKET = gql`
  query getInventoryStoreMarket($storeGroupId: String!, $countryCode: String!) {
    getInventoryStoreMarket(
      storeGroupId: $storeGroupId
      countryCode: $countryCode
    ) {
      storeGroupId
      countryCode
      inventories {
        id
        name
        address {
          postalCode
          city
          streetAddress
          country
          region
        }
        created
        updated
      }
      created
      updated
    }
  }
`

export default GET_INVENTORY_STORE_MARKET
