import React from "react"
import { DeliveryOrderLine, Order, OrderCompensation } from "lib/types/generated/graphql-types"
import { DeliveryButtonWrapper, Id, Name, ProductImage, Row } from "../Shared.styled"

import placeholder from "../../../../../images/placeholder.svg"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { useMutation } from "@apollo/client"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as PlusIcon } from "../../../../../images/icons/circle-plus.svg"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import {
  compensationOrderLinesOutputToInput,
  deliveryOrderLineOutputToCompensationInput,
  getCompensationInput
} from "../Helpers/helpers"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  orderLine: DeliveryOrderLine
  order: Order
  currencyCode: string
  compensations: OrderCompensation[]
  refetch: () => void
}

export const VariantRow = ({ orderLine, order, currencyCode, compensations, refetch }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const notCompletedCompensation = compensations.find(
    (compensation) => !compensation.started || (compensation.started && !compensation.completed)
  )
  const completedCompensations = compensations.filter((delivery) => !!delivery.completed)
  const orderLineCompensated = completedCompensations.map((compensation) =>
    compensation?.orderLines?.find((ol) => ol.orderLineId === orderLine.orderLineId)
  )
  const getCompensatedQuantity = () =>
    orderLineCompensated.reduce((a, b) => {
      return a + (b?.quantity ?? 0)
    }, 0)

  const [updateCompensation, { loading: updateLoading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const orderLineExistInCompensation = (id: string) => {
    return notCompletedCompensation?.orderLines?.find((ol) => ol.orderLineId === id)
  }

  const getOrderLine = () => {
    return order?.orderLines?.find((ol) => ol.id === orderLine.orderLineId)
  }

  const updateOrderCompensation = () => {
    return updateCompensation({
      variables: {
        compensationId: notCompletedCompensation?.id,
        input: {
          ...getCompensationInput(notCompletedCompensation),
          orderLines: [
            ...compensationOrderLinesOutputToInput(notCompletedCompensation?.orderLines ?? []),
            ...deliveryOrderLineOutputToCompensationInput([orderLine])
          ]
        }
      }
    })
  }

  return (
    <Row key={orderLine.orderLineId}>
      <ProductImage src={getOrderLine()?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{getOrderLine()?.name}</Name>
        <Money
          amount={orderLine.totalAmount}
          currencyUnit={currencyCode as DineroFactory.Currency}
        ></Money>

        <div>Order quantity: {orderLine.quantity}</div>
        {getCompensatedQuantity() > 0 && (
          <div>Compensated quantity: {getCompensatedQuantity()}</div>
        )}
        <Id>
          <b>Variant ID:</b> <span>{getOrderLine()?.productVariantId}</span>
        </Id>
      </div>
      <DeliveryButtonWrapper>
        <SecondaryButton
          handleClick={updateOrderCompensation}
          disabled={!!orderLineExistInCompensation(orderLine.orderLineId)}
          loading={updateLoading}
        >
          <PlusIcon />
          Include
        </SecondaryButton>
        <span>
          {" "}
          Total :{" "}
          <b>
            {" "}
            <Money
              amount={orderLine.totalAmount}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </b>
        </span>
      </DeliveryButtonWrapper>
    </Row>
  )
}
