import gql from "graphql-tag"

const DELETE_MODIFICATION = gql`
  mutation deleteOrderModificationOrderLines($modificationId: ID!) {
    Order {
      deleteOrderModificationOrderLines(modificationId: $modificationId) {
        status
      }
    }
  }
`

export default DELETE_MODIFICATION
