import gql from "graphql-tag"

const ADD_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API = gql`
  mutation addExternalEventsDestinationAPI($destName: String!, $options: InputAddExternalEventsDestinationAPI!) {
    addExternalEventsDestinationAPI(destName: $destName, options: $options) {
      apiDestinationName
    }
  }
`

export default ADD_EXTERNAL_EVENTS_DESTINATION_DESTINATION_API
