import React from "react"
import styled from "styled-components/macro"

import { ProductBlock } from "./ProductBlock"
import { List, Item, Label, Value } from "components/Ui/List/List"

import { ReactComponent as DescriptionIcon } from "images/icons/align-left.svg"
import { ReactComponent as NameIcon } from "images/icons/spell-check.svg"

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 4rem;
  position: relative;
`

type Props = {
  attributes: string // eslint-disable-line
  name: boolean
}

const DisplayAttributes = ({ name, attributes }: Props) => {
  const translations = JSON.parse(attributes)

  return (
    <>
      <ProductBlock>
        <h2>
          {name ? <NameIcon /> : <DescriptionIcon />}
          {name ? "Display names" : "Display descriptions"}
        </h2>
        <Block>
          <List>
            {Object.keys(translations).length > 0 ? (
              Object.keys(translations).map((key) => (
                <Item key={key} flexibleHeight showAsColumn>
                  <Label>{key}</Label>
                  <Value>{translations[key]}</Value>
                </Item>
              ))
            ) : (
              <p> {name ? "No display names set" : "No display descriptions set"}</p>
            )}
          </List>
        </Block>
      </ProductBlock>
    </>
  )
}

export default DisplayAttributes
