import styled from "styled-components/macro"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import SecondaryButton from "components/Ui/Buttons/SecondaryButton"

export const Page = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6rem;
  }
`

export const Column = styled.div`
  width: 100%;
  min-height: 15rem;
  min-width: 16rem;
  border-radius: 0.6rem;

  h2 {
    display: flex;
    align-items: center;

    svg {
      height: 2.8rem;
      width: 2.8rem;
      margin-right: 1rem;
      transform: rotate(-90deg);
    }
  }
`

export const Container = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem;
  border-radius: 0.6rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 3rem;
  }
`

export const Providers = styled.div`
  width: 100%;
  position: relative;
  padding: 0 0 2rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  svg {
    max-height: 3rem;
    max-width: 10rem;
    margin: 2rem 0 0;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin: 0 3rem 0 0;
    }
  }
`

export const ProviderContainer = styled.div`
  border-radius: 0.6rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  background: ${(p) => p.theme.colors.white};
  display: flex;
  flex-wrap: wrap;

  &:last-of-type {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      padding: 1.5rem 1.5rem 0;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0 1.5rem;
  }

  svg {
    max-height: 3.5rem;
    height: 100%;
    margin: 0;
  }
`

export const CustomLogo = styled.div`
  display: flex;
  line-height: 1.8rem;
  flex: 1;
  align-items: center;
  ${(p) => p.theme.bold};

  svg {
    height: 3rem;
    width: 3rem;
    margin-right: 1.5rem;
  }
`

export const ConnectButton = styled(PrimaryButton)`
  width: auto;
  height: 3.2rem;
  padding: 0 1.5rem;

  svg {
    height: 1.5rem;
    margin-right: 1rem;
  }
`

export const Label = styled.h4`
  width: 100%;
  margin: 0;
`

export const EditButton = styled(SecondaryButton)`
  height: 3.2rem;
  padding: 0 1.5rem;
`

export const IconContainer = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid rgb(223, 227, 232);
  border-radius: 0.5rem;
  padding: 0.5rem;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1.5rem;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  width: 100%;
  height: 5.3rem;
  align-self: flex-end;

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${(p) => p.theme.colors.turquoiseDarker};

    svg {
      height: 1.2rem;
      width: 1.2rem;
      fill: ${(p) => p.theme.colors.turquoiseDarker};
    }
  }
`
