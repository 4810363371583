import styled from "styled-components/macro"

import { IconTableHeader } from "../../Ui/Table/Shared.styled"

export const StyledIconTableHeader = styled(IconTableHeader)`
  padding-right: 8rem;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  width: 100%;
`

export const Flex = styled.div`
  display: flex;
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const Description = styled.div``

export const NameHeader = styled.div`
  padding-right: 3rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding-right: 6rem;
  }
`


export const StyledTableHeader = styled(IconTableHeader)`
`

