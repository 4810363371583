import React from "react"
import { Tabs, TabList, Tab, TabPanel } from "react-tabs"
import { useQuery } from "@apollo/client"

import PLATFORM_CONFIG from "graphql/queries/settings/platformConfig/platformConfig"

import PageHeader from "components/Ui/Page/PageHeader"
import { RateLimits } from "./RateLimits"
import { DiscountQuotas } from "./DiscountQuotas"

export const UsagePlan = () => {
  const { data, loading, error } = useQuery(PLATFORM_CONFIG)

  return (
    <>
      <PageHeader title="Usage plan" description={<>Manage your usage plan in Brink Commerce.</>} hasTabs />
      <Tabs>
        <TabList>
          <Tab>Rate limits</Tab>
          <Tab>Discount quotas</Tab>
        </TabList>
        <TabPanel>
          <RateLimits PlatformConfig={data?.PlatformConfig} loading={loading} error={error} />
        </TabPanel>
        <TabPanel>
          <DiscountQuotas PlatformConfig={data?.PlatformConfig} error={error} />
        </TabPanel>
      </Tabs>
    </>
  )
}
