import gql from "graphql-tag"

const START_RELEASE = gql`
  mutation startRelease($releaseId: ID!, $input: InputOrderStartRelease!) {
    Order {
      startRelease(releaseId: $releaseId, input: $input) {
        status
      }
    }
  }
`

export default START_RELEASE
