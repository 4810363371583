import gql from "graphql-tag"

const GET_VOYADO_CONFIG = gql`
  query getVoyadoConfig($id: ID!) {
    getVoyadoConfig(id: $id) {
      id
      created
      updated
      baseUrl
      credentials {
        apiKey
      }
    }
  }
`

export default GET_VOYADO_CONFIG
