import gql from "graphql-tag"

const START_REFUND = gql`
  mutation startRefund($refundId: ID!, $input: InputOrderStartRefund!) {
    Order {
      startRefund(refundId: $refundId, input: $input) {
        status
      }
    }
  }
`

export default START_REFUND
