import gql from "graphql-tag"

const CREATE_OR_UPDATE_TAX_GROUP = gql`
  mutation createOrUpdateTaxGroup(
    $id: ID!
    $name: String!
    $description: String!
  ) {
    createOrUpdateTaxGroup(
      id: $id
      taxGroup: { name: $name, description: $description }
    ) {
      id
      name
      description
    }
  }
`

export default CREATE_OR_UPDATE_TAX_GROUP
