import React, { Dispatch, SetStateAction } from "react"
import Popup from "../../../Ui/Popup"
import { useQuery } from "@apollo/client"
import { ButtonWrapper, ProviderContainer } from "./StatusPopup.styled"
import Status from "../../../Ui/Status"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import GET_CANCELLATION from "../../../../graphql/queries/order/GetCancellation"

type Props = {
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  cancellationId: string
  refetchOrder: () => void
}

const StatusPopup = ({ setShowStatusPopup, cancellationId, refetchOrder }: Props) => {
  const { data, refetch, loading } = useQuery(GET_CANCELLATION, { variables: { cancellationId } })

  const cancellation = data?.Order?.cancellation

  return (
    <Popup
      title={"Provider status"}
      handleCloseClick={() => {
        setShowStatusPopup(false)
        refetchOrder()
      }}
      handleOkClick={() => {
        setShowStatusPopup(false)
        refetchOrder()
      }}
      buttonText={"Ok"}
    >
      <div>
        <ButtonWrapper>
          <PrimaryButton handleClick={() => refetch()} loading={loading}>
            <RefreshIcon />
            Refresh status
          </PrimaryButton>
        </ButtonWrapper>
        {cancellation?.bonus && (
          <ProviderContainer>
            <b>Bonus</b>
            <Status status={cancellation?.bonusProvider?.status?.current} />
          </ProviderContainer>
        )}
        {cancellation?.giftCards.length > 0 && (
          <ProviderContainer>
            <b>Gift card</b>
            <Status status={cancellation?.giftCardProvider?.status?.current} />
          </ProviderContainer>
        )}
        {cancellation?.paymentProvider && (
          <ProviderContainer>
            <b>Payment</b>
            <Status status={cancellation?.paymentProvider?.status?.current} />
          </ProviderContainer>
        )}
      </div>
    </Popup>
  )
}

export default StatusPopup
