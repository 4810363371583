import React, { Component, ErrorInfo, ReactNode } from "react"
import { useConfig } from "configuration/useConfig"
import { AwsRumClient } from "helpers/awsRumClient"
import ErrorView from "./views/ErrorView"

interface ErrorBoundaryProps {
  children: ReactNode
  rumIdentityPool: string
  rumAppMonitorId: string
  environment: string
  awsRegion: string
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private readonly rumIdentityPool: string
  private readonly rumAppMonitorId: string
  private readonly environment: string
  private readonly awsRegion: string

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.rumIdentityPool = props.rumIdentityPool
    this.rumAppMonitorId = props.rumAppMonitorId
    this.environment = props.environment
    this.awsRegion = props.awsRegion
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (this.environment === "development") {
      return
    }

    const rumClient = AwsRumClient(this.rumAppMonitorId, this.rumIdentityPool, this.awsRegion)

    try {
      rumClient.recordError(error)
    } catch (rumError) {
      // eslint-disable-next-line no-console
      console.error("Error caught by RUM client:", rumError)
    }

    // eslint-disable-next-line no-console
    console.error("Error caught by ErrorBoundary:", error, errorInfo)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <ErrorView />
    }
    return this.props.children
  }
}

interface ErrorBoundaryWrapperProps {
  children: ReactNode
}

export const ErrorBoundaryWrapper = ({ children }: ErrorBoundaryWrapperProps) => {
  const { rumAppMonitorId, rumIdentityPool, environment, awsRegion } = useConfig().config
  return (
    <ErrorBoundary
      rumAppMonitorId={rumAppMonitorId}
      rumIdentityPool={rumIdentityPool}
      environment={environment}
      awsRegion={awsRegion}
    >
      {children}
    </ErrorBoundary>
  )
}
