import gql from "graphql-tag"

const DELETE_CAMPAIGN_GROUP = gql`
  mutation deleteCampaignGroup($id: ID!) {
    deleteCampaignGroup(id: $id) {
      status
    }
  }
`

export default DELETE_CAMPAIGN_GROUP
