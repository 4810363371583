import gql from "graphql-tag"

export const CREATE_OR_UPDATE_AVARDA_CONFIG = gql`
  mutation createOrUpdateAvardaConfig(
    $id: ID!
    $avardaConfig: InputAvardaConfig!
  ) {
    createOrUpdateAvardaConfig(
      id: $id
      avardaConfig: $avardaConfig
    ) {
      id
    }
  }
`
