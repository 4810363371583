import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"

import GET_QLIRO_ONE_CONFIG from "graphql/queries/config/Providers/Qliro/GetQliroOneConfig"

import { Name } from "components/Ui/Table/Shared.styled"
import { PushUrl } from "./QliroOneConfigTableRow.styled"

import { BlurRow } from "components/Ui/Table/TableBlurLoading"
import TableRow from "components/Ui/Table/TableRow"

import { ReactComponent as Logo } from "images/providers/icons/qliro.svg"

export const getLoadingRow = () => (
  <>
    <Logo />
    <div>
      <Name>Identifier</Name>
    </div>
    <div>
      <p>TEST</p>
    </div>
    <PushUrl className="hideOnSmallScreens">
      <p>https://payments.qit.nu</p>
    </PushUrl>
    <div className="hideOnSmallScreens">
      <p>2000-00-00</p>
    </div>
    <div className="hideOnSmallScreens">
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setEditId?: Dispatch<SetStateAction<string>>
}

export const QliroOneConfigTableRow = ({ id, setEditId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_QLIRO_ONE_CONFIG, {
    variables: { id: id }
  })

  const qliroOneConfig = data?.getQliroOneConfig
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>

  if (!qliroOneConfig) return null

  const { created, updated, baseUrl, merchantOrderManagementStatusPushUrl } = qliroOneConfig

  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setEditId) setEditId(qliroOneConfig.id)
        dispatch(toggleEditSidebar())
      }}
    >
      <Logo />
      <div>
        <Name>{id}</Name>
      </div>
      <div>
        <p>{baseUrl?.includes("pago.qit.nu") ? "TEST" : "PRODUCTION"}</p>
      </div>
      <PushUrl className="hideOnSmallScreens">
        <p>{merchantOrderManagementStatusPushUrl}</p>
      </PushUrl>
      <div className="hideOnSmallScreens">
        <p>{dayjs(created).format("YYYY-MM-DD")}</p>
      </div>
      <div className="hideOnSmallScreens">
        <p>{dayjs(updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
