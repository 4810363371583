import React from "react"
import { OrderLineModificationDeleteData } from "lib/types/generated/graphql-types"
import { Id, Name, ProductImage, Row, Status } from "../Shared.styled"
import placeholder from "../../../../../images/placeholder.svg"


type Props = {
  orderLine: OrderLineModificationDeleteData
}

export const VariantRowDeleted = ({ orderLine }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <Row key={orderLine.id}>
      <ProductImage src={orderLine?.imageUrl as string} onError={handleImageError} />
      <div>
        <Status $deleted={true}>Deleted</Status>
        <Name>{orderLine?.name}</Name>
        <div>Order quantity: {orderLine.quantity}</div>
        <Id>
          <b>Variant ID:</b> <span>{orderLine?.productVariantId}</span>
        </Id>
      </div>
    </Row>
  )
}
