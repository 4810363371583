import gql from "graphql-tag";

const DELETE_VOYADO_CONFIG = gql`
  mutation deleteVoyadoConfig($id: ID!) {
    deleteVoyadoConfig(id: $id) {
      status
    }
  }
`;

export default DELETE_VOYADO_CONFIG;
