import gql from "graphql-tag"

const UPDATE_REFUND = gql`
  mutation updateRefund($refundId: ID!, $input: InputOrderRefund!) {
    Order {
      updateRefund(refundId: $refundId, input: $input) {
        id
      }
    }
  }
`

export default UPDATE_REFUND
