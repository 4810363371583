import gql from "graphql-tag"

const GET_CAMPAIGN_GROUP = gql`
  query getCampaignGroup($id: ID!) {
    getCampaignGroup(id: $id) {
      campaignGroup {
        id
        name
        description
        created
        updated
      }
      campaigns {
        id
        campaignGroupId
        name
        priceRule {
          type
          percentage
          percentageDecimals
          monies {
            key
            value
          }
        }
        productVariantRules {
          tagLogic
        }
        roundingRules {
          defaultRule {
            roundUp
            roundDown
            targets
          }
          marketRules {
            key
            value {
              roundUp
              roundDown
              targets
            }
          }
        }
        storeMarkets {
          key
          value
        }
        startDate
        endDate
        created
        updated
      }
    }
  }
`

export default GET_CAMPAIGN_GROUP
