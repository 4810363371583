import React, { useState } from "react"
import {
  InputCreateOrUpdateBundleGroup,
  InputStoreMarket,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { InputBundleWithItemRuleId } from "lib/types/discount"
import {
  CountryInfo,
  StoreGroupLabel,
  StoreMarketRow,
  Value,
  Values,
  Wrapper
} from "./SetChannels.styled"
import { v4 as uuidv4 } from "uuid"
import FlagIcon from "../../../Ui/FlagIcon"
import { getCountryName } from "helpers/countries"
import { ReactComponent as StoreGroupIcon } from "images/icons/circle-nodes.svg"
import AddedBundle from "./BundleItem/AddedBundle"
import {
  ChannelsWrapper,
  Container,
  ErrorMessage,
  GeneralHeader,
  GeneralWrapper,
  Header,
  StyledStoreMarketsTable, TimePeriod
} from "./Result.styled"
import { ReactComponent as ExclamationIcon } from "images/icons/circle-exclamation.svg"
import { ReactComponent as StoreMarketIcon } from "images/icons/store.svg"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import { ReactComponent as Arrow } from "images/icons/arrows-left-right.svg"
import dayjs from "dayjs"

type Props = {
  general: InputCreateOrUpdateBundleGroup
  addedStoreMarkets: InputStoreMarket[]
  bundles: InputBundleWithItemRuleId[]
  storeGroups: StoreGroup[]
}

const Result = ({ general, addedStoreMarkets, storeGroups, bundles }: Props) => {
  const [channelsExpanded, setChannelsExpanded] = useState(false)

  return (
    <Container>
      {general.name && (
        <GeneralWrapper>
          <GeneralHeader>
            <div>
              <span>
                <h2>{general.name}</h2>
              </span>
              {general?.validDateRange?.from && general?.validDateRange?.to && (
                <TimePeriod>
                  <b>Valid:</b>
                  {general?.validDateRange?.from
                    ? dayjs(general?.validDateRange?.from).format("YYYY-MM-DD: HH:mm")
                    : "No date set"}
                  <Arrow/>
                  {general?.validDateRange?.to
                    ? dayjs(general?.validDateRange?.to).format("YYYY-MM-DD: HH:mm")
                    : "No date set"}
                </TimePeriod>
              )}
            </div>
          </GeneralHeader>
        </GeneralWrapper>
      )}
      {!general.name && (
        <ErrorMessage>
          <ExclamationIcon /> No name added
        </ErrorMessage>
      )}
      {addedStoreMarkets.length === 0 && (
        <ErrorMessage>
          <ExclamationIcon /> No channels added
        </ErrorMessage>
      )}
      {addedStoreMarkets.length > 0 && (
        <ChannelsWrapper>
          <Header onClick={() => setChannelsExpanded(!channelsExpanded)}>
            <div>
              <span>
                <StoreMarketIcon /> <h3>Selected channels</h3>
              </span>
              <div>
                <b>Store groups:</b> {addedStoreMarkets.length}
              </div>
              <div>
                <b>Store markets:</b>{" "}
                {addedStoreMarkets?.reduce((acc, { value }) => acc + (value.length ?? 0), 0)}
              </div>
            </div>
            {channelsExpanded ? <ChevronUp /> : <ChevronDown />}
          </Header>
          {channelsExpanded && (
            <StyledStoreMarketsTable>
              {addedStoreMarkets?.map((storeMarket) => {
                return (
                  <Wrapper key={uuidv4()}>
                    <StoreMarketRow deleteEnabled={false} smallButtons disableHover>
                      <StoreGroupLabel>
                        <StoreGroupIcon />
                        <span>{storeGroups?.find((sg) => sg.id === storeMarket?.key)?.name}</span>
                      </StoreGroupLabel>
                      <Values>
                        {storeMarket.value.map((countryCode) => (
                          <Value key={countryCode}>
                            <CountryInfo>
                              <FlagIcon countryCode={countryCode} />
                              {getCountryName(countryCode)}
                            </CountryInfo>
                          </Value>
                        ))}
                      </Values>
                    </StoreMarketRow>
                  </Wrapper>
                )
              })}
            </StyledStoreMarketsTable>
          )}
        </ChannelsWrapper>
      )}

      {bundles.length === 0 && (
        <ErrorMessage>
          <ExclamationIcon /> No bundles added
        </ErrorMessage>
      )}
      {bundles.map((bundle, index) => (
        <AddedBundle bundle={bundle} key={bundle.id} index={index} />
      ))}
    </Container>
  )
}

export default Result
