import React, { useState } from "react"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"

import ALL_GIFT_CARD_PROVIDERS from "graphql/queries/config/AllGiftCardProviders"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { Retain24ConfigTable } from "components/Config/Retain24/Retain24ConfigTable"
import { EditRetain24Config } from "./EditRetain24Config"
import { CreateRetain24Config } from "./CreateRetain24Config"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as Retain24Logo } from "images/providers/retain24-logo.svg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Retain24Config = () => {
  const [editId, setEditId] = useState<string>("")
  const { loading, data, refetch, error } = useQuery(ALL_GIFT_CARD_PROVIDERS)
  const dispatch = useDispatch()
  const giftCardProviders = data?.getGiftCardProviders

  return (
    <Container>
      <PageHeader
        title={<Retain24Logo />}
        description={<>Handle your Retain24 configuration</>}
        goBackLinkText="Back to providers"
        goBackLinkUrl={"/settings/providers"}
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new configuration
        </PrimaryButton>
      </PageHeader>
      {!editId && <CreateRetain24Config refetch={refetch} />}
      {editId && <EditRetain24Config refetch={refetch} editId={editId} setEditId={setEditId} />}
      <Retain24ConfigTable
        providers={giftCardProviders}
        loading={loading}
        error={error}
        setEditId={setEditId}
      />
    </Container>
  )
}
