import gql from "graphql-tag"

const RESTART_REFUND = gql`
  mutation restartRefund($refundId: ID!, $input: InputOrderStartRefund!) {
    Order {
      restartRefund(refundId: $refundId, input: $input) {
        status
      }
    }
  }
`

export default RESTART_REFUND
