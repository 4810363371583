import gql from "graphql-tag"

const DELETE_DISCOUNT_CODE = gql`
  mutation deleteDiscountCode($code: String!) {
    deleteDiscountCode(code: $code) {
      status
    }
  }
`

export default DELETE_DISCOUNT_CODE
