import React, { Dispatch, SetStateAction, useState } from "react"
import { Common } from "lib/types"
import { v4 as uuidv4 } from "uuid"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import DineroFactory from "dinero.js"
import { isSuperUser } from "helpers/user"

import Input from "../Ui/Form/Input"
import Money from "../Money"
import Popup from "../Ui/Popup"

import { ReactComponent as PlusIcon } from "../../images/icons/plus.svg"
import {
  Add,
  AddButton,
  Amount,
  Container,
  Currency,
  CurrencySelect, FixedDiscount,
  FixedDiscountHeader,
  FixedDiscountRow,
  Formatted
} from "./FixedDiscounts.styled"

type Props = {
  fixedDiscounts: Common.Money[]
  setFixedDiscounts: Dispatch<SetStateAction<Common.Money[]>>
  currencyOptions: Common.Option[]
}

const FixedDiscounts = ({ fixedDiscounts, setFixedDiscounts, currencyOptions }: Props) => {
  const dispatch = useAppDispatch()
  const [currency, setCurrency] = useState<string>("")
  const [amount, setAmount] = useState<number>(0)
  const [openPopup, setOpenPopup] = useState(false)
  const [currencyCodeToDelete, setCurrencyCodeToDelete] = useState<string>("")

  const updateCurrency = (conditionType: Common.Option) => {
    setCurrency(conditionType?.value)
  }

  const addFixedDiscount = () => {
    fixedDiscounts.find((t) => t.currencyCode === currency)
      ? dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: "Currency already exist. Only one fixed discount per currency is allowed"
        })
      )
      : setFixedDiscounts((prev: Common.Money[]) => [
        ...prev,
        {
          units: amount,
          currencyCode: currency
        }
      ])
    setCurrency("")
    setAmount(0)
  }

  const onDeleteFixedDiscount = () => {
    setFixedDiscounts((prev: Common.Money[]) =>
      prev.filter((u: { currencyCode: string }) => u.currencyCode !== currencyCodeToDelete)
    )
    setOpenPopup(false)
  }

  return (
    <Container>
      <Add>
        <h3>Add fixed discount(s)</h3>
            <CurrencySelect
              options={currencyOptions}
              setValue={updateCurrency}
              placeholder="Select currency"
              label="Currency:"
            />
            <Input
              type="number"
              name="amount"
              label={<>Amount in minor units:</>}
              placeholder="Amount in minor units"
              onChange={(e: { target: { value: number } }) => {
                setAmount(e.target.value)
              }}
            />
        <Formatted>
          <strong>Example: </strong>
          {amount && currency ? (
            <Money
              amount={parseInt(`${amount}`)}
              currencyUnit={currency as DineroFactory.Currency}
            />
          ) : (
            "0"
          )}
        </Formatted>
        <AddButton type="button" handleClick={addFixedDiscount} disabled={!currency || !amount}>
          <PlusIcon /> Add
        </AddButton>
      </Add>
      <FixedDiscount>
        {fixedDiscounts?.length > 0 ? (
          <>
            <FixedDiscountHeader>
              <div>Currency</div>
              <div>Amount</div>
            </FixedDiscountHeader>
            <div>
              {fixedDiscounts.map((t) => {
                const fullCurrency = currencyOptions.find(
                  (c: Common.Option) => c.value === t.currencyCode
                ) || {
                  label: "",
                  value: ""
                }

                return (
                  <React.Fragment key={uuidv4()}>
                    <FixedDiscountRow
                      deleteEnabled={isSuperUser()}
                      smallButtons
                      disableHover
                      handleDelete={() => {
                        setCurrencyCodeToDelete(t.currencyCode)
                        setOpenPopup(true)
                      }}
                    >
                      <Currency>
                        <span>{t.currencyCode}</span>
                        {fullCurrency.label.split("[")[0]}
                      </Currency>
                      <Amount>
                        <span>{t.units}</span>
                        <Money
                          amount={parseInt(`${t.units}`)}
                          currencyUnit={t.currencyCode as DineroFactory.Currency}
                        />
                      </Amount>
                    </FixedDiscountRow>
                    {openPopup && currencyCodeToDelete === t.currencyCode && (
                      <Popup
                        title="Delete fixed discount"
                        subtitle={`Are you sure you want to delete the fixed discount for following currency?`}
                        item={{ itemId: "", itemName: t.currencyCode }}
                        handleCloseClick={() => setOpenPopup(false)}
                        handleOkClick={onDeleteFixedDiscount}
                        type="delete"
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </>
        ) : (
          <p>No fixed discount added</p>
        )}
      </FixedDiscount>
    </Container>
  )
}

export default FixedDiscounts
