import React, { useEffect } from "react"
import esb, { RequestBodySearch } from "elastic-builder"
import { Common } from "@lib/types"
import Filter from "../Ui/Table/Filter/Filter"
import CheckboxSimple from "../Ui/Form/CheckboxSimple";

type Props = {
  setSearchQuery: (input: string) => void
  loading: boolean
  searchQuery: string
  setEsbQuery: (query: RequestBodySearch) => void
  productFilter: Common.ProductFilter
  setProductFilter: (filter: Common.ProductFilter) => void
  numberOfHitsOption: string[]
  defaultProductFilter: Common.ProductFilter
  totalHits: number | undefined
}

const ProductFilters = ({
  setSearchQuery,
  loading,
  searchQuery,
  setEsbQuery,
  productFilter,
  setProductFilter,
  numberOfHitsOption,
  defaultProductFilter,
  totalHits
}: Props) => {


  const isFilterActive = () => {
      const { isActive,isArchived } = productFilter
      return !isActive || isArchived
    }

  const setIsActive = (value: boolean) => setProductFilter({ ...productFilter, ...{ isActive: value } })
  const setIsArchived = (value: boolean) => setProductFilter({ ...productFilter, ...{ isArchived: value } })
  const setNumberOfHits = (value: string) => setProductFilter({ ...productFilter, ...{ numberOfHits: value } })

  useEffect(() => {
    const boolQuery = esb.boolQuery()
    boolQuery.must(esb.termsQuery("isActive", productFilter.isActive))
    boolQuery.must(esb.termsQuery("isArchived", productFilter.isArchived))
    boolQuery.must([esb.queryStringQuery(searchQuery.trim() + "*").defaultOperator("AND").analyzeWildcard(true)])
    setEsbQuery(esb.requestBodySearch().query(boolQuery))
  }, [productFilter, searchQuery])

  const resetFilter = () => {
    setProductFilter(defaultProductFilter)
  }

  return (
    <Filter
      isFilterActive={isFilterActive}
      setNumberOfHits={setNumberOfHits}
      numberOfHitsOptions={numberOfHitsOption}
      selectedNumberOfHits={productFilter?.numberOfHits}
      resetFilter={resetFilter}
      totalHits={totalHits}
      totalFilters={isFilterActive() ? 1:0}
      searchOptions={{
        handleChange: (input: string) => {
          setSearchQuery(input)
        },
        loading: loading,
        defaultValue: searchQuery
      }}
    >
      <CheckboxSimple name="active" label="active" setValue={setIsActive} value={productFilter.isActive}/>
      <CheckboxSimple name="archived" label="archived" setValue={setIsArchived} value={productFilter.isArchived}/>
    </Filter>
  )
}
export default ProductFilters
