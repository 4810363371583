import gql from "graphql-tag"

const GET_MODIFICATION = gql`
  query orderModificationOrderLines($modificationId: ID!) {
    Order {
      orderModificationOrderLines(modificationId: $modificationId) {
        id
        orderId
        started
        completed
        failed
        created
        updated
        restarts
        restarted
        revision
        type
        oldTotals {
          subTotal
          discountTotal
          grandTotal
          shippingTotal
          giftCardTotal
          bonusTotal
          voucherTotal
          taxTotal
        }
        newTotals {
          subTotal
          discountTotal
          grandTotal
          shippingTotal
          giftCardTotal
          bonusTotal
          voucherTotal
          taxTotal
        }
        giftCardProvider {
          providerName
          providerId
          status {
            current
          }
        }
        paymentProvider {
          providerName
          providerId
          status {
            current
          }
        }
        bonusProvider {
          providerName
          providerId
          status {
            current
          }
        }
        promotionProvider {
          providerName
          providerId
        }
        shippingProvider {
          providerName
          providerId
          status {
            current
          }
        }
        voucherProvider {
          providerId
          providerName
        }
        reason {
          code
          cause
        }
        orderReference
        orderLines {
          modificationType
          data {
            ... on OrderLineModificationCreateData {
              id
              name
              basePriceAmount
              discountAmount
              imageUrl
              productVariantId
              quantity
              salePriceAmount
            }
            ... on OrderLineModificationDeleteData {
              id
              name
              basePriceAmount
              discountAmount
              imageUrl
              productVariantId
              quantity
              salePriceAmount
            }
            ... on OrderLineModificationUpdateData {
              id
              name
              imageUrl
              productVariantId
              price {
                quantity
                salePriceAmount
                basePriceAmount
                discountAmount
              }
            }
          }
        }
      }
    }
  }
`

export default GET_MODIFICATION
