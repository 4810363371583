import gql from "graphql-tag"

const DELETE_DISCOUNT_CODE_RULE = gql`
  mutation deleteDiscountCodeRule($id: ID!) {
    deleteDiscountCodeRule(id: $id) {
      status
    }
  }
`

export default DELETE_DISCOUNT_CODE_RULE
