import React, { useMemo, useState } from "react"
import debounce from "lodash.debounce"
import { useConfig } from "configuration/useConfig"
import { useDeleteItemMutation, useUpdateItemMutation } from "lib/store/services/brink/actions"
import DineroFactory from "dinero.js"
import { ShopperCartItem } from "lib/types/session"

import {
  CartItemContainer,
  ItemDelete,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice,
  QtySelector
} from "./CartItem.styled"

import { Loader } from "../../../Ui/Loader"
import Money from "../../../Money"

import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"
import placeholder from "images/placeholder.svg"

type Props = {
  item: ShopperCartItem
  currencyCode?: string
}

const CartItem = ({ item, currencyCode, ...props }: Props) => {
  const [quantity, setQuantity] = useState(item.quantity)
  const [deleteItem] = useDeleteItemMutation()
  const [updateItem] = useUpdateItemMutation()
  const [previousQuantity, setPreviousQuantity] = useState(item.quantity)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { awsRegion, customerName } = useConfig().config

  const onUpdate = useMemo(() => {
    return debounce((qty: number) => {
      if (qty === 0) {
        return deleteItem({ awsRegion, customerName, itemId: item.id })
          .then(() => setIsLoading(false))
          .catch(() => {
            setIsLoading(false)
          })
      }
      return updateItem({ awsRegion, customerName, itemId: item.id, quantity: qty })
        .unwrap()
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false)
          setQuantity(previousQuantity)
        })
    }, 500)
  }, [item.id, deleteItem, updateItem, previousQuantity])

  const updateQuantity = (mod: number) => {
    setIsLoading(true)
    const qty = Math.max(quantity + mod, 0)
    setPreviousQuantity(quantity)
    setQuantity(qty)
    onUpdate(qty)
  }

  const onDelete = (qty: number) => {
    setIsLoading(true)
    onUpdate(qty)
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <CartItemContainer {...props}>
      {isLoading && <Loader color="black" />}
      <ItemDelete onClick={() => onDelete(0)}>
        <DeleteIcon />
      </ItemDelete>
      <ItemImage>
        <img src={item.imageUrl} onError={handleImageError} />
      </ItemImage>
      <ItemDetails>
        <ItemName>{item.name}</ItemName>
        {item.productVariantId}
        <ItemPrice>
          <Money
            amount={Number(item.salePriceAmount)}
            currencyUnit={currencyCode as DineroFactory.Currency}
          />
          {item.salePriceAmount < item.basePriceAmount && (
            <span>
              <Money
                amount={Number(item.basePriceAmount)}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </span>
          )}
        </ItemPrice>
        <QtySelector>
          <span onClick={() => updateQuantity(-1)}>-</span>
          <span>{item.quantity}</span>
          <span onClick={() => updateQuantity(+1)}>+</span>
        </QtySelector>
      </ItemDetails>
    </CartItemContainer>
  )
}

export default CartItem
