import React, { Dispatch, SetStateAction, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import OutsideClickHandler from "react-outside-click-handler"
import { useConfig } from "configuration/useConfig"
import { useSelector } from "react-redux"
import { selectUserGroups } from "lib/store/services/auth/selectors"
import { isCampaignUser, isReadOnlyUser, isSuperUser, isUserAdmin } from "helpers/user"

import {
  protectedCampaignViews,
  protectedSuperUserViews,
  protectedUserAdminViews,
  views
} from "views"

import {
  Wrapper,
  Container,
  Logo,
  Test,
  Navigation,
  NavItem,
  NavNoLink,
  NavLink,
  User,
  SubNav,
  SubNavLink,
  SubNavExternalLink,
  SubTitle,
  SectionTitle
} from "./Sidebar.styled"

import { ReactComponent as UserIcon } from "images/icons/user-large.svg"
import { ReactComponent as ExternalIcon } from "images/icons/arrow-up-right-from-square.svg"

type Props = {
  sideBarExpanded: boolean
  setSidebarExpanded: Dispatch<SetStateAction<boolean>>
}

export const Sidebar = ({ sideBarExpanded, setSidebarExpanded }: Props) => {
  const [expandedNav, setExpandedNav] = useState("")
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isTestEnvironment, config } = useConfig()
  const userGroups = useSelector(selectUserGroups)
  const userAdminAccess = userGroups?.includes("brinkcommerce-useradmin")
  const superUserAccess = userGroups?.includes("brinkcommerce-superuser")
  const campaignUserAccess = userGroups?.includes("brinkcommerce-campaignuser")
  const userReadOnlyAccess = userGroups?.includes("brinkcommerce-readonly")
  const onlyCampaignUserAccess =
    !userAdminAccess &&
    !superUserAccess &&
    !userReadOnlyAccess &&
    campaignUserAccess &&
    config.campaignEnabled

  const allViews =
    config.campaignEnabled &&
    (isSuperUser() || isCampaignUser() || isReadOnlyUser()) &&
    !onlyCampaignUserAccess
      ? views.concat(protectedCampaignViews)
      : onlyCampaignUserAccess
      ? protectedCampaignViews
      : views

  const getViewFromPath = (path: string) => {
    const view = allViews.find((view) => view.path === path)
    return {
      ...view,
      children: [
        ...(view?.children ?? []),
        ...(isSuperUser()
          ? protectedSuperUserViews.filter((view) => view.parentPath === path)
          : []),
        ...(isUserAdmin() ? protectedUserAdminViews.filter((view) => view.parentPath === path) : [])
      ]
    }
  }

  const ViewsWithProtectedRoutes = allViews.map((view) => getViewFromPath(view.path))

  const routes = userGroups ? ViewsWithProtectedRoutes : []
  const sortedRoutes = routes.sort((a, b) =>
    a.sortOrder && b.sortOrder ? a.sortOrder - b.sortOrder : 0
  )

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setExpandedNav("")
      }}
    >
      <Wrapper $expanded={sideBarExpanded}>
        <Container>
          <Logo onClick={() => navigate("/")} $isTestEnvironment={isTestEnvironment} />
          {isTestEnvironment && <Test>test</Test>}
          <hr />
          <Navigation>
            {sortedRoutes.map((view) => {
              const children = view.children?.filter((c) => c.showInSidebar)
              const activeChild = children?.find(
                (c) => c.parentPath && pathname?.includes(c.parentPath)
              )
              if (!view.title) return null
              return (
                <>
                  {view.title === "Info" && <hr />}
                  <NavItem
                    $active={
                      pathname === view.path ||
                      (view.path !== "/" &&
                        view.path &&
                        pathname?.includes(view.path) &&
                        children?.length === 0) ||
                      !!activeChild
                    }
                    key={view.path}
                  >
                    {children && children?.length !== 0 ? (
                      <NavNoLink
                        key={view.path}
                        onClick={() =>
                          expandedNav === view.path
                            ? setExpandedNav("")
                            : setExpandedNav(view?.path ?? "")
                        }
                        $subMenuExpanded={expandedNav === view.path}
                      >
                        {view.icon && <view.icon />}
                        {view.title}
                      </NavNoLink>
                    ) : (
                      <NavLink
                        key={view.path}
                        to={view?.path ?? ""}
                        onClick={() => {
                          setExpandedNav("")
                          setSidebarExpanded(false)
                        }}
                      >
                        {view.icon && <view.icon />}
                        {view.title}
                      </NavLink>
                    )}
                  </NavItem>
                </>
              )
            })}
          </Navigation>
          <hr />
          <User $active={pathname === "/user"} to="/user" onClick={() => setSidebarExpanded(false)}>
            <UserIcon />
          </User>
        </Container>
        {sortedRoutes.map((view) => {
          const children = view.children?.filter((c) => c.showInSidebar)
          if (!children) return false

          return (
            <SubNav $subMenuExpanded={expandedNav === view.path} key={view.path}>
              <SubTitle>{view.title}</SubTitle>
              {children.map(
                (sublink) =>
                  sublink.showInSidebar && (
                    <div key={sublink.path}>
                      {sublink.section !== undefined && (
                        <SectionTitle>{sublink.section}</SectionTitle>
                      )}
                      {sublink.path.includes("http") ? (
                        <SubNavExternalLink
                          key={sublink.path}
                          href={sublink.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {sublink.title} <ExternalIcon />
                        </SubNavExternalLink>
                      ) : (
                        <SubNavLink
                          key={sublink.path}
                          to={sublink.path}
                          onClick={() => {
                            setExpandedNav("")
                            setSidebarExpanded(false)
                          }}
                          $active={pathname === sublink.path ? true : undefined}
                        >
                          {sublink.title}
                        </SubNavLink>
                      )}
                    </div>
                  )
              )}
            </SubNav>
          )
        })}
      </Wrapper>
    </OutsideClickHandler>
  )
}
