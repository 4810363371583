import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import GET_BUNDLE_GROUP from "../../../graphql/queries/bundles/GetBundleGroup"
import { useParams } from "react-router-dom"
import Status from "../../../components/Ui/Status"
import Box from "../../../components/Ui/Box"
import PageHeader from "../../../components/Ui/Page/PageHeader"
import {
  Bundle,
  BundleGroup,
  InputCreateOrUpdateBundleGroup,
  InputDiscountValue, InputDiscountValueType, InputMaybe,
  InputStoreMarket,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { Blur } from "./UpdateBundleGroup.styled"
import { ActionDropdownButton } from "../../../components/Ui/Buttons/ActionDropdownButton"
import { getJson } from "helpers/getJson"
import { Activate, Deactivate, Divider } from "../Shared.styled"
import { useForm } from "react-hook-form"
import CREATE_OR_UPDATE_BUNDLE_GROUP from "../../../graphql/mutations/bundles/CreateOrUpdateBundleGroup"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useDispatch } from "react-redux"
import { ReactComponent as OnIcon } from "images/icons/circle-play.svg"
import { ReactComponent as OffIcon } from "images/icons/circle-minus.svg"
import { ReactComponent as CodeIcon } from "images/icons/brackets-curly.svg"
import GeneralSettings from "../../../components/Discount/Bundles/Steps/GeneralSettings"
import SetChannels from "../../../components/Discount/Bundles/Steps/SetChannels"
import ALL_STORE_GROUPS from "../../../graphql/queries/store/AllStoreGroups"
import { InputBundleItemRuleWithId, InputBundleWithItemRuleId } from "@lib/types/discount"
import { v4 as uuidv4 } from "uuid"
import AddedBundles from "../../../components/Discount/Bundles/Steps/BundleItem/AddedBundles"
import AddBundles from "../../../components/Discount/Bundles/Steps/AddBundles"

const UpdateBundleGroup = () => {
  const { id } = useParams()
  const [bundleGroup, setBundleGroup] = useState<BundleGroup>()
  const [addedStoreMarkets, setAddedStoreMarkets] = useState<InputStoreMarket[]>([])
  const [bundles, setBundles] = useState<InputBundleWithItemRuleId[]>([])
  const [storeGroups, setStoreGroups] = useState<StoreGroup[]>([])
  const [discountValue, setDiscountValue] = useState<InputDiscountValue | undefined>()
  const [itemRules, setItemRules] = useState<InputBundleItemRuleWithId[]>([])
  const [editBundleId, setEditBundleId] = useState<string>("")

  const dispatch = useDispatch()

  const { loading, refetch } = useQuery(GET_BUNDLE_GROUP, {
    variables: { id },
    onCompleted: (data) => {
      setBundleGroup(data.getBundleGroup)
      setAddedStoreMarkets(data.getBundleGroup.storeMarkets)
      setBundles(
        data.getBundleGroup.bundles.map((bundle: Bundle) => ({
          ...bundle,
          itemRules: bundle.itemRules.map((itemRule) => ({ ...itemRule, id: uuidv4() }))
        }))
      )
    }
  })

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      setStoreGroups(
        data.getStoreGroups.filter(
          (storeGroup: StoreGroup) =>
            storeGroup?.storeMarkets && storeGroup?.storeMarkets?.length > 0
        )
      )
    }
  })

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useForm<InputCreateOrUpdateBundleGroup>()

  const [updateBundleGroup, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_BUNDLE_GROUP,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Bundle group successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: (error) => {
        dispatch(
          alertActions.actions.createAlert({
            message: error.message,
            type: "error"
          })
        )
      }
    }
  )

  const getDiscountValue = (discountValue: InputMaybe<InputDiscountValue> | undefined) => {
    if (discountValue) {
      if (discountValue.type === InputDiscountValueType.Relative && discountValue.percentage) {
        return discountValue
      }
      if (
        discountValue.type === InputDiscountValueType.Fixed &&
        discountValue.fixedDiscounts &&
        discountValue.fixedDiscounts.length > 0
      ) {
        return discountValue
      }
    }
    return undefined
  }

  const onSubmit = (data: InputCreateOrUpdateBundleGroup) => {
    updateBundleGroup({
      variables: {
        id: bundleGroup?.id,
        bundleGroup: {
          name: data.name,
          isActive: bundleGroup?.isActive,
          bundles: bundles.map((bundle) => ({
            id: bundle.id,
            ...(getDiscountValue(bundle?.discountValue) && {discountValue: getDiscountValue(bundle.discountValue)}),
            itemRules: bundle.itemRules.map((itemRule) => ({
              tagConditions: itemRule.tagConditions,
              excludeDiscountedPrices: itemRule.excludeDiscountedPrices,
              excludeFullPrices: itemRule.excludeFullPrices,
              productParents: itemRule.productParents,
              quantity: itemRule.quantity,
              ...(getDiscountValue(itemRule?.discountValue) && {discountValue: getDiscountValue(itemRule.discountValue)}),
            }))
          })),
          storeMarkets: addedStoreMarkets,
          ...(data.validDateRange?.from &&
            data.validDateRange.to && {
              validDateRange: {
                from: new Date(data.validDateRange.from),
                to: new Date(data.validDateRange.to)
              }
            })
        }
      }
    })
  }

  const updateActive = (status: boolean) => {
    updateBundleGroup({
      variables: {
        id: bundleGroup?.id,
        bundleGroup: {
          name: bundleGroup?.name,
          isActive: status,
          bundles: bundles.map((bundle) => ({
            ...bundle,
            itemRules: bundle.itemRules.map((itemRule) => ({
              tagConditions: itemRule.tagConditions,
              excludeDiscountedPrices: itemRule.excludeDiscountedPrices,
              excludeFullPrices: itemRule.excludeFullPrices,
              productParents: itemRule.productParents,
              quantity: itemRule.quantity,
              ...(getDiscountValue(itemRule?.discountValue) && {discountValue: getDiscountValue(itemRule.discountValue)}),
            }))
          })),
          storeMarkets: addedStoreMarkets,
          ...(bundleGroup?.validDateRange && bundleGroup?.validDateRange)
        }
      }
    })
  }

  if (!bundleGroup || !bundles || !storeGroups)
    return (
      <Box padding="0" margin="0">
        <PageHeader
          title={
            <Blur>
              Bundle name
              <Status status="Active" />
            </Blur>
          }
          group="Bundle group"
          id={id}
          hasTabs
          goBackLinkUrl="/discounts/bundle-groups"
          goBackLinkText="Back to bundle groups"
        ></PageHeader>
      </Box>
    )
  return (
    <Box padding="0" margin="0">
      <PageHeader
        title={
          <>
            {loading ? (
              <Blur>
                Bundle name
                {<Status status="Active" />}
              </Blur>
            ) : (
              <>
                {bundleGroup?.name}
                {bundleGroup?.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
              </>
            )}
          </>
        }
        group="Bundle group"
        id={id}
        hasTabs
        goBackLinkUrl="/discounts/bundle-groups"
        goBackLinkText="Back to bundle groups"
      >
        <ActionDropdownButton
          title="Save"
          disabled={!!editBundleId}
          submitEvent={handleSubmit(onSubmit)}
          submitLoading={createLoading}
        >
          <li
            onClick={() => {
              getJson(bundleGroup ?? {})
            }}
          >
            <CodeIcon /> Get rule JSON
          </li>
          {bundleGroup?.isActive ? (
            <>
              <Divider />
              <Deactivate onClick={() => updateActive(false)}>
                <OffIcon /> Deactivate bundle
              </Deactivate>
            </>
          ) : (
            <>
              <Divider />
              <Activate onClick={() => updateActive(true)}>
                <OnIcon /> Activate bundle
              </Activate>
            </>
          )}
        </ActionDropdownButton>
      </PageHeader>
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Channels</Tab>
          <Tab>Bundles</Tab>
        </TabList>
        <TabPanel>
          <GeneralSettings
            register={register}
            control={control}
            errors={errors}
            defaultName={bundleGroup?.name}
            defaultTimeRange={bundleGroup?.validDateRange}
          />
        </TabPanel>
        <TabPanel>
          <SetChannels
            addedStoreMarkets={addedStoreMarkets}
            setAddedStoreMarkets={setAddedStoreMarkets}
            storeGroups={storeGroups}
            expanded={true}
          />
        </TabPanel>
        <TabPanel>
          <AddBundles
            addedStoreMarkets={addedStoreMarkets}
            storeGroups={storeGroups}
            setBundles={setBundles}
            discountValue={discountValue}
            setDiscountValue={setDiscountValue}
            itemRules={itemRules}
            setItemRules={setItemRules}
            editBundleId={editBundleId}
            setEditBundleId={setEditBundleId}
          />
          <AddedBundles
            bundles={bundles}
            setBundles={setBundles}
            setDiscountValue={setDiscountValue}
            setItemRules={setItemRules}
            setEditBundleId={setEditBundleId}
          />
        </TabPanel>
      </Tabs>
    </Box>
  )
}

export default UpdateBundleGroup
