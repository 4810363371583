import React, { Dispatch, SetStateAction } from "react"

import { Container } from "./RadioButton.styled"

type Props = {
  name: string
  label: string
  id: string
  checked: boolean
  onChange: Dispatch<SetStateAction<boolean>>
}

export const RadioButton = ({ name, label, id, checked, onChange }: Props) => {
  const handleOnChange = () => {
    onChange(!checked)
  }

  return (
    <Container $checked={checked} onClick={handleOnChange}>
      <input type="radio" name={name} id={id} checked={checked} readOnly /> {label}
    </Container>
  )
}
