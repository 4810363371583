export interface DynamicConfig {
  awsRegion: string
  cognitoClientId: string
  cognitoDomain: string
  cognitoUserPoolId: string
  graphqlApiUrl: string
  siteUrl: string
  rumIdentityPool: string
  rumAppMonitorId: string
  accountType: string
  customerName: string
  campaignEnabled: boolean
  environment: string
}

export const defaultConfig: DynamicConfig = {
  awsRegion: "",
  cognitoClientId: "",
  cognitoDomain: "",
  cognitoUserPoolId: "",
  graphqlApiUrl: "",
  accountType: "",
  siteUrl: "",
  rumIdentityPool: "",
  rumAppMonitorId: "",
  customerName: "",
  campaignEnabled: false,
  environment: "production"
}

export const dynamicConfigUrl = "/config.json"
