import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { toggleEditSidebar } from "lib/store/services/editSidebar/slice"

import { GET_WALLEY_CHECKOUT_CONFIG } from "graphql/queries/config/Providers/Walley/GetWalleyCheckoutConfig"

import { Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import { BlurRow } from "components/Ui/Table/TableBlurLoading"

import { ReactComponent as Logo } from "images/providers/icons/walley.svg"

export const getLoadingRow = () => (
  <>
    <Logo />
    <div>
      <div>
        <Name>Store ID</Name>
        <Id>store-id</Id>
      </div>
    </div>
    <div>
      <p>PRODUCTION</p>
    </div>
    <div>
      <p>Profile Name</p>
    </div>
    <div>
      <p>2000-00-00</p>
    </div>
    <div>
      <p>2000-00-00</p>
    </div>
  </>
)

type Props = {
  id: string
  setWalleyConfigId?: Dispatch<SetStateAction<string>>
}

export const WalleyConfigTableRow = ({ id, setWalleyConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading } = useQuery(GET_WALLEY_CHECKOUT_CONFIG, {
    variables: { id: id }
  })

  const walleyCheckoutConfig = data?.getWalleyCheckoutConfig
  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>

  if (!walleyCheckoutConfig) return null

  const { storeId, created, updated, baseUrl, profileName } = walleyCheckoutConfig

  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setWalleyConfigId) setWalleyConfigId(walleyCheckoutConfig.id)
        dispatch(toggleEditSidebar())
      }}
    >
      <Logo />
      <div>
        <div>
          <Name>{storeId}</Name>
          <Id>{id}</Id>
        </div>
      </div>
      <div>
        <p>{baseUrl?.includes("walleydev") ? "TEST" : "PRODUCTION"}</p>
      </div>
      <div>
        <p>{profileName || "-"}</p>
      </div>
      <div>
        <p>{dayjs(created).format("YYYY-MM-DD")}</p>
      </div>
      <div>
        <p>{dayjs(updated).format("YYYY-MM-DD")}</p>
      </div>
    </TableRow>
  )
}
