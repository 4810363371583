import React from "react"
import { Order, OrderCompensation } from "@lib/types/generated/graphql-types"
import TotalItem from "./TotalItem"
import GiftCardItem from "./GiftCardItem"
import {
  getDeliveryBonusTotals,
  getDeliveryGiftCardTotals,
  getDeliveryPaymentTotals
} from "../../Helpers/helpers"

enum TYPE {
  PAYMENT,
  BONUS
}

type Props = {
  compensation: OrderCompensation
  order: Order
  refetch: () => void
}

const Totals = ({ compensation, order, refetch }: Props) => {
  const completedDeliveries = order?.Deliveries?.filter((delivery) => !!delivery.completed)

  const giftCardsInDeliveries = completedDeliveries.flatMap((delivery) => delivery.giftCards)

  const filteredGiftCardsInDeliveries = [
    ...giftCardsInDeliveries
      .reduce((map, current) => {
        const { giftCardId } = current
        const grouped = map.get(giftCardId)
        if (!grouped) {
          map.set(giftCardId, { ...current })
        } else {
          map.set(giftCardId, { ...grouped, amount: grouped.amount + current.amount })
        }
        return map
      }, new Map())
      .values()
  ]

  const totalDeliveryPayment = getDeliveryPaymentTotals(completedDeliveries)
  const totalDeliveryBonus = getDeliveryBonusTotals(completedDeliveries)

  return (
    <>
      {getDeliveryPaymentTotals(completedDeliveries) > 0 && compensation?.payment?.amount && (
        <>
          Payment:
          <TotalItem
            currencyCode={order.currencyCode}
            compensation={compensation}
            refetch={refetch}
            type={TYPE.PAYMENT}
            totalDeliveryAmount={totalDeliveryPayment}
          />
        </>
      )}
      {getDeliveryBonusTotals(completedDeliveries) > 0 && compensation?.bonus?.amount && (
        <>
          Bonus:
          <TotalItem
            currencyCode={order.currencyCode}
            compensation={compensation}
            refetch={refetch}
            type={TYPE.BONUS}
            totalDeliveryAmount={totalDeliveryBonus}
          />
        </>
      )}
      {getDeliveryGiftCardTotals(completedDeliveries) > 0 &&
        compensation?.giftCards &&
        compensation?.giftCards?.length > 0 && (
          <>
            Gift cards:
            {filteredGiftCardsInDeliveries?.map((giftCard) => (
              <GiftCardItem
                deliveryGiftCard={giftCard}
                currencyCode={order.currencyCode}
                key={giftCard.giftCardId}
                refetch={refetch}
                compensation={compensation}
              />
            ))}
          </>
        )}
    </>
  )
}

export default Totals
