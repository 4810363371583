import React from "react"
import { useQuery } from "@apollo/client"
import { Provider } from "@lib/types/generated/graphql-types"
import { useNavigate } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { providersData, ProviderDetails, ProviderType, ProvidersData } from "helpers/providers"
import { v4 as uuidv4 } from "uuid"

import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"
import ALL_SHIPPING_PROVIDERS from "graphql/queries/config/AllShippingProviders"
import ALL_GIFT_CARD_PROVIDERS from "graphql/queries/config/AllGiftCardProviders"
import GET_VOYADO_CONFIGS from "graphql/queries/config/Providers/Voyado/GetVoyadoConfigs"
import { ALL_BONUS_PROVIDERS } from "graphql/queries/config/AllBonusProviders"

import {
  Page,
  Wrapper,
  Column,
  Container,
  Providers,
  ProviderContainer,
  ConnectButton,
  Label,
  EditButton,
  IconContainer,
  Footer
} from "./ShowProviders.styled"
import { EmptyList } from "components/Ui/Table/Shared.styled"

import PageHeader from "components/Ui/Page/PageHeader"
import { Loader } from "components/Ui/Loader"

import { ReactComponent as PlugIcon } from "images/icons/plug.svg"
import { ReactComponent as ConnectIcon } from "images/icons/grid-2-plus.svg"
import { ReactComponent as NetworkIcon } from "images/icons/grid-2.svg"
import { ReactComponent as ExternalIcon } from "images/icons/arrow-up-right-from-square.svg"

export const ShowProviders = () => {
  const { data, loading: paymentLoading } = useQuery(ALL_PAYMENT_PROVIDERS)
  const { data: shippingData, loading: shippingLoading } = useQuery(ALL_SHIPPING_PROVIDERS)
  const { data: giftCardData, loading: giftCardLoading } = useQuery(ALL_GIFT_CARD_PROVIDERS)
  const { data: voyadoData, loading: voyadoLoading } = useQuery(GET_VOYADO_CONFIGS)
  const { data: bonusData, loading: bonusLoading } = useQuery(ALL_BONUS_PROVIDERS)

  const allProviders = data?.getPaymentProviders.concat(
    shippingData?.getShippingProviders,
    giftCardData?.getGiftCardProviders,
    voyadoData?.getVoyadoConfigs.map((c: { id: string }) => ({ type: "voyado", id: c.id })),
    bonusData?.getBonusConfigs.map((c: { id: string }) => ({ type: "bonus", id: c.id }))
  )

  const providerExists = (type: string) => {
    return allProviders?.find((p: Provider) => p?.type === type)
  }

  const navigate = useNavigate()

  const loading =
    paymentLoading || shippingLoading || giftCardLoading || voyadoLoading || bonusLoading

  const connectedProviders: ProvidersData = {}
  const availableProviders: ProvidersData = {}

  Object.keys(providersData).forEach((typeKey: string) => {
    const providerType = providersData[typeKey as keyof ProviderType].filter(
      (provider: ProviderDetails) => providerExists(provider.type)
    )
    connectedProviders[typeKey as keyof ProviderType] = providerType
  })

  Object.keys(providersData).forEach((typeKey: string) => {
    const providerType = providersData[typeKey as keyof ProviderType].filter(
      (provider: ProviderDetails) => !providerExists(provider.type)
    )
    availableProviders[typeKey as keyof ProviderType] = providerType
  })

  return (
    <Page>
      <PageHeader
        title={"Providers"}
        description={"Overview of connected providers and available providers to connect."}
      />
      <Wrapper>
        <Column>
          <h2>
            <NetworkIcon />
            Connected providers
          </h2>
          <Container>
            {loading ? (
              <Loader color="black" />
            ) : (
              <>
                <Tabs>
                  <TabList>
                    {Object.keys(providersData).map((key: string) => (
                      <Tab key={uuidv4()}>
                        <Label>
                          {key} ({connectedProviders[key].length})
                        </Label>
                      </Tab>
                    ))}
                  </TabList>
                  <>
                    {Object.keys(providersData).map((key: string) => (
                      <TabPanel key={uuidv4()}>
                        {connectedProviders[key].length > 0 ? (
                          <Providers>
                            {connectedProviders[key].map((provider: ProviderDetails) => (
                              <ProviderContainer key={uuidv4()}>
                                <div>
                                  <div>
                                    <IconContainer>{provider.icon}</IconContainer>
                                    <b>{provider.name}</b>
                                  </div>
                                </div>
                                <p>{provider.desc}</p>
                                <Footer>
                                  {provider.statusUrl && (
                                    <a href={provider.statusUrl} target="_blank" rel="noreferrer">
                                      Status <ExternalIcon />
                                    </a>
                                  )}
                                  {provider.slug !== "" && (
                                    <EditButton handleClick={() => navigate(provider.slug)}>
                                      Manage
                                    </EditButton>
                                  )}
                                </Footer>
                              </ProviderContainer>
                            ))}
                          </Providers>
                        ) : (
                          <EmptyList>
                            <p> No connected providers</p>
                          </EmptyList>
                        )}
                      </TabPanel>
                    ))}
                  </>
                </Tabs>
              </>
            )}
          </Container>
        </Column>
        <Column>
          <h2>
            <ConnectIcon />
            Available providers
          </h2>
          <Container>
            {loading ? (
              <Loader color="black" />
            ) : (
              <>
                <Tabs>
                  <TabList>
                    {Object.keys(providersData).map((key: string) => (
                      <Tab key={uuidv4()}>
                        <Label>
                          {key} ({availableProviders[key].filter((p) => !p.noEdit).length})
                        </Label>
                      </Tab>
                    ))}
                  </TabList>
                  {Object.keys(providersData).map((key: string) => (
                    <TabPanel key={uuidv4()}>
                      {availableProviders[key].filter((p) => !p.noEdit).length > 0 ? (
                        <Providers>
                          {availableProviders[key].map((provider: ProviderDetails) => {
                            if (provider.noEdit) return
                            return (
                              <>
                                <ProviderContainer>
                                  <div>
                                    <div>
                                      <IconContainer>{provider.icon}</IconContainer>
                                      <b>{provider.name}</b>
                                    </div>
                                  </div>
                                  <p>{provider.desc}</p>
                                  <Footer>
                                    <span></span>
                                    <ConnectButton handleClick={() => navigate(provider.slug)}>
                                      <PlugIcon /> Connect
                                    </ConnectButton>
                                  </Footer>
                                </ProviderContainer>
                              </>
                            )
                          })}
                        </Providers>
                      ) : (
                        <EmptyList>
                          <p> No available providers</p>
                        </EmptyList>
                      )}
                    </TabPanel>
                  ))}
                </Tabs>
              </>
            )}
          </Container>
        </Column>
      </Wrapper>
    </Page>
  )
}
