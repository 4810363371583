import gql from "graphql-tag"

const VALIDATE_KLARNA = gql`
    query validateKlarna($baseUrl: String!, $username: String!, $password: String!) {
        Configuration {
            validate {
                klarnaCheckout(baseUrl: $baseUrl, username: $username, password: $password) {
                    errorMessage
                    valid
                }
            }
        }
    }
`

export default VALIDATE_KLARNA
