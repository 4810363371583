import React from "react"
import { OrderSearchHit, OrderSearchHits, StoreGroup } from "@lib/types/generated/graphql-types"
import { useNavigate } from "react-router-dom"
import DineroFactory from "dinero.js"
import dayjs from "dayjs"
import {
  getPaymentMethodDisplayName,
  getPaymentProviderDisplayName
} from "helpers/getPaymentDisplayName"
import { getProviderIcon } from "helpers/providers"
import { useQuery } from "@apollo/client"
import { ReactComponent as InfoIcon } from "images/icons/circle-info.svg"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import {
  Container,
  Customer,
  EmptyList,
  FlexColumn,
  IconContainer,
  OrderDate,
  PaymentContainer,
  PaymentMethod,
  SmallInfo,
  StatusCancel,
  StatusColumn,
  StatusFull,
  StatusHeader,
  StatusPart,
  StatusWrapper, StoreGroupColumn,
  StyledTableHeader
} from "./OrderTable.styled"

import TableRow from "components/Ui/Table/TableRow"
import Money from "components/Money"
import FlagIcon from "components/Ui/FlagIcon"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import CopyButton from "components/Ui/CopyButton"
import DotLoader from "components/Ui/DotLoader"

import { ReactComponent as CartIcon } from "images/icons/cart-shopping.svg"
import { Tooltip } from "../Ui/Tooltip"

type Props = {
  orderData: OrderSearchHits | undefined
  loading: boolean
  searchQuery: string
  error: object | undefined
}

export const OrderTable = ({ orderData, loading, searchQuery, error }: Props) => {
  const navigate = useNavigate()

  const getStatus = (state: string, padding: string) => {
    switch (state) {
      case "FULLY_DELIVERED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Delivered</SmallInfo>
          </StatusFull>
        )
      case "PARTIALLY_DELIVERED":
        return (
          <StatusPart padding={padding}>
            <SmallInfo>Delivered in part</SmallInfo>
          </StatusPart>
        )
      case "PARTIALLY_REFUNDED":
        return (
          <StatusPart padding={padding}>
            <SmallInfo>Refunded in part</SmallInfo>
          </StatusPart>
        )
      case "FULLY_REFUNDED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Refunded</SmallInfo>
          </StatusFull>
        )
      case "FULLY_COMPENSATED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Compensated</SmallInfo>
          </StatusFull>
        )
      case "PARTIALLY_COMPENSATED":
        return (
          <StatusPart padding={padding}>
            <SmallInfo>Compensated in part</SmallInfo>
          </StatusPart>
        )
      case "CANCELLED":
        return (
          <StatusCancel padding={padding}>
            <SmallInfo>Cancelled</SmallInfo>
          </StatusCancel>
        )
      case "PLACED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Placed</SmallInfo>
          </StatusFull>
        )
      case "RELEASED":
        return (
          <StatusFull padding={padding}>
            <SmallInfo>Released</SmallInfo>
          </StatusFull>
        )
      default:
        return state
    }
  }

  const getPadding = (length: number) =>
    length > 1 ? (length === 3 ? "0 0.5rem" : "0.5rem 1rem") : "1.5rem 1rem"

  const { data, loading: storeGroupLoading } = useQuery(ALL_STORE_GROUPS)
  const { getStoreGroups: storeGroups } = data ?? []


  const tableHeader = (
    <StyledTableHeader>
      <div>Reference</div>
      <Customer className="hideOnSmallScreens">Customer</Customer>
      <div>Total order value</div>
      <PaymentContainer className="hideOnSmallScreens hideOnMeduimScreens">
        Payment
      </PaymentContainer>
      <StoreGroupColumn className="hideOnSmallScreens hideOnMeduimScreens">Store group</StoreGroupColumn>
      <FlexColumn className="hideOnSmallScreens">Order date</FlexColumn>
      <StatusHeader className="hideOnSmallScreens hideOnMeduimScreens">Status</StatusHeader>
    </StyledTableHeader>
  )

  const getRows = (order?: OrderSearchHit) =>
    order? (
      <TableRow
        key={order.id}
        handleOnClick={() => {
          navigate(`/orders/${order.id}`)
        }}
      >
        <FlagIcon countryCode={order.countryCode} />
        <FlexColumn>
          <div>
            {order.reference} <CopyButton string={order.reference} />
            <Tooltip id="tooltip" />
            {order.merchantReference1 && (
              <SmallInfo
                className={"hideOnSmallScreens"}
                data-tooltip-id="tooltip"
                data-tooltip-content={order.merchantReference1}
                data-tooltip-delay-hide={100}
              >
                Merchant ref. 1
                <InfoIcon />
              </SmallInfo>
            )}
            {order.merchantReference2 && (
              <SmallInfo
                className={"hideOnSmallScreens"}
                data-tooltip-id="tooltip"
                data-tooltip-content={order.merchantReference2}
                data-tooltip-delay-hide={100}
              >
                Merchant ref. 2
                <InfoIcon />
              </SmallInfo>
            )}
          </div>
        </FlexColumn>
        <Customer className="hideOnSmallScreens">
          <div>
            {order.billingAddress.givenName} {order.billingAddress.familyName}
            <SmallInfo>{order.billingAddress?.email}</SmallInfo>
          </div>
        </Customer>
        <FlexColumn>
          <p>
            <Money
              amount={parseInt(`${order.totals?.grandTotal}`)}
              currencyUnit={order.currencyCode as DineroFactory.Currency}
            />
          </p>
        </FlexColumn>
        <PaymentContainer className="hideOnSmallScreens hideOnMeduimScreens">
          <IconContainer>{getProviderIcon(order.paymentProviderName)}</IconContainer>
          <span>
            <SmallInfo>{getPaymentProviderDisplayName(order.paymentProviderName)}</SmallInfo>
            <PaymentMethod>{getPaymentMethodDisplayName(order.paymentMethod)}</PaymentMethod>
          </span>
        </PaymentContainer>
        <StoreGroupColumn className="hideOnSmallScreens hideOnMeduimScreens">
          {storeGroupLoading ? (
            <DotLoader />
          ) : (
            <SmallInfo>
              {storeGroups
                ?.find((sg: StoreGroup) => sg.id === order.storeGroupId)
                ?.name}
            </SmallInfo>
          )}
        </StoreGroupColumn>
        <OrderDate className="hideOnSmallScreens">
          <div>{dayjs(order.date).format("YYYY-MM-DD")}</div>
          <SmallInfo>{dayjs(order.date).format("HH:mm")}</SmallInfo>
        </OrderDate>
        <StatusColumn className="hideOnSmallScreens hideOnMeduimScreens">
          <StatusWrapper>
            {order.status.orderStates.map((status) =>
              getStatus(status, getPadding(order.status.orderStates.length))
            )}
          </StatusWrapper>
        </StatusColumn>
      </TableRow>
    ) : (
      <>
        <FlagIcon countryCode="dummy" />
        <FlexColumn>
          <p>777455dhd6c</p>
        </FlexColumn>
        <Customer className="hideOnSmallScreens">
          <div>
            John Doe
            <SmallInfo>john.doe@email.com</SmallInfo>
          </div>
        </Customer>
        <FlexColumn>
          <p>SEK 100</p>
        </FlexColumn>
        <PaymentContainer className="hideOnSmallScreens hideOnMeduimScreens">
          Klarna
          <PaymentMethod>Card</PaymentMethod>
        </PaymentContainer>
        <StoreGroupColumn className="hideOnSmallScreens hideOnMeduimScreens">Brink Commerce</StoreGroupColumn>
        <OrderDate className="hideOnSmallScreens">
          <p>2000-00-00 00:00</p>
        </OrderDate>
        <StatusColumn className="hideOnSmallScreens hideOnMeduimScreens"></StatusColumn>
      </>
    )

  if (loading && !searchQuery)
    return <TableBlurLoading numberOfRows={4} tableHeader={tableHeader} rows={getRows()} />

  return (
    <Container>
      {error && (
        <ErrorMessage
          showRefreshButton
          message={
            <>
              Error loading Orders. Try refreshing the page, or contact{" "}
              <a href="mailto:support@brinkcommerce.com">support</a>.
            </>
          }
        />
      )}
      {orderData && orderData?.hits?.length > 0 ? (
        <>
          {tableHeader}
          <div>{orderData.hits.map((order) => getRows(order))}</div>
        </>
      ) : (
        <EmptyList>
          <CartIcon />
          <p> No orders found</p>
        </EmptyList>
      )}
    </Container>
  )
}
