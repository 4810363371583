import gql from "graphql-tag"

const GET_SVEA_CHECKOUT_CONFIG = gql`
  query getSveaCheckoutConfig($id: ID!) {
    getSveaCheckoutConfig(id: $id) {
      id
      merchantId
      created
      updated
      checkoutBaseUrl
      paymentAdminBaseUrl
      credentials {
        apiKey
      }
    }
  }
`

export default GET_SVEA_CHECKOUT_CONFIG
