import styled from "styled-components/macro"
import { ReactComponent as logo } from "images/brink-logo-icon.svg"

export const Wrapper = styled.div`
  padding: 2rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: ${(p) => p.theme.colors.background};

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 6rem 6rem 0 16rem;
    margin-top: 0;
  }
`

export const Container = styled.div`
  height: 100%;
  min-height: calc(100vh - 30rem);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;

  svg {
    width: 8rem;
    fill: ${(p) => p.theme.colors.errorText};
  }
  p {
    margin: 0 0 2rem;
  }
`

export const SidebarWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-top: 0;
  }
`

export const SidebarContainer = styled.div`
  position: relative;
  width: 10rem;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.colors.blueDarker};
  z-index: 10002;
  display: none;

  hr {
    width: calc(100% - 3rem);
    margin: 1.5rem auto;
    border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
  }

`

export const Logo = styled(logo)<{ $isTestEnvironment: boolean | undefined }>`
  width: 4.5rem;
  padding-top: 2.5rem;
  fill: ${(p) => p.theme.colors.white};
  cursor: pointer;
  margin-right: ${(p) => (p.$isTestEnvironment ? "1rem" : "initial")};
  margin-bottom: 2rem;
`

export const Test = styled.p`
  background: ${(p) => p.theme.colors.yellow};
  color: ${(p) => p.theme.colors.black};
  position: absolute;
  right: 1.6rem;
  top: 0.8rem;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.1rem 0.5rem 0;
  border-radius: 0.3rem;
  line-height: 1.8rem;
`
