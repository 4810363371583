import gql from "graphql-tag"

const CREATE_OR_UPDATE_NSHIFT_CONFIG = gql`
    mutation createOrUpdateNShiftConfig(
        $id: ID!
        $baseUrl: String!
        $clientId: String!
        $deliveryCheckoutId: String!
        $clientSecret: String!
    ) {
        createOrUpdateNShiftConfig(
            id: $id
            nShiftConfig: {
                baseUrl: $baseUrl
                clientId: $clientId
                deliveryCheckoutId: $deliveryCheckoutId
                clientSecret: $clientSecret
            }
        ) {
            id
        }
    }
`

export default CREATE_OR_UPDATE_NSHIFT_CONFIG
