import styled from "styled-components/macro"

export const Default = styled.span`
  ${(p) => p.theme.bold}
  letter-spacing: 0;
  padding: 0 1rem;
  border-radius: 0.75rem;
  letter-spacing: 0.03rem;
  background: #eee;
  height: 2.7rem;
  width: auto !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem !important;
  word-break: break-word;


  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 1.3rem !important;
    padding: 0 1.5rem;
    min-width: 10rem;
  }
`

export const Success = styled(Default)`
  background: #dcf4e5;
  color: rgb(9, 174, 76);
  border: 0.1rem solid rgba(9, 174, 76, 0.15);
`

export const Waiting = styled(Default)`
  color: #e69503;
  background: #ffedcd;
`

export const Error = styled(Default)`
  background: #ffe6e6;
  color: #e64d4d;
  border: 0.1rem solid rgba(230, 77, 77, 0.15);
`
