import gql from "graphql-tag"

const CREATE_OR_UPDATE_RETAIN24_CONFIG = gql`
  mutation createOrUpdateRetain24Config(
    $id: ID!
    $baseUrl: String!
    $certificate: String!
    $certificatePassword: String!
    $simulationKey: String
    $redeem: Boolean!
  ) {
    createOrUpdateRetain24Config(
      id: $id
      retain24Config: {
        baseUrl: $baseUrl
        certificate: $certificate
        certificatePassword: $certificatePassword
        simulationKey: $simulationKey
        redeem: $redeem
      }
    ) {
      id
    }
  }
`
export default CREATE_OR_UPDATE_RETAIN24_CONFIG
