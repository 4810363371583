import gql from "graphql-tag"

const USERS = gql`
    query users(
        $limit: Int!
        $token: String
    ) {
        users(
            limit: $limit
            token: $token
        ) {
            nextToken
            users {
                created
                enabled
                familyName
                givenName
                status
                updated
                username
            }
        }
    }
`

export default USERS
