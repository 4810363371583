import styled from "styled-components/macro"
import TableRowSimple from "components/Ui/Table/TableRowSimple"

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  button {
    position: absolute;
    right: 0;
    bottom: 2rem;

    svg {
      margin-left: 1rem;
    }
  }
`

export const Row = styled(TableRowSimple)`
  min-height: 7rem;
  padding: 1.5rem 2rem;
  margin: 0 0 2rem;
  display: flex;
`

export const ProductImage = styled.img`
  width: 5rem !important;
  display: block;
  flex-shrink: 0;
  border-radius: 0.6rem;
`

export const Id = styled.p`
  font-size: 1.2rem;
  margin: 0;
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`
