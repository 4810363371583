import React, { Dispatch, ReactElement, SetStateAction } from "react"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import { Inventory } from "@lib/types/generated/graphql-types"
import styled from "styled-components/macro"
import {ReactComponent as BarIcon} from "images/icons/bars.svg"
import {ReactComponent as DeleteIcon} from "images/icons/trash-can.svg"
import { isSuperUser } from "helpers/user"



const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.1rem;
  padding-right: 2rem;
`

const Container = styled.div`
  width: 100%;
`

const SortRow = styled.div`
background: ${p => p.theme.colors.white};
  padding: 1.2rem 1rem 1.2rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  &:hover {
    background: ${(p) => p.theme.colors.greyLightest};
  }
`

export const BlurRow = styled(SortRow)`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);

`

const Name = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 1.6rem;
    margin-right: 1.5rem;
  }
`

const Order = styled.div`
display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
`

type StyledProps = {
  isInactive?: boolean
  wrapContent?: boolean
  smallButtons?: boolean
  disableHover?: boolean
}

const ActionButton = styled.button<StyledProps>`
  width: ${(p) => (p.smallButtons ? "3rem" : "4rem")};
  height: ${(p) => (p.smallButtons ? "3rem" : "4rem")};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: ${(p) => (p.smallButtons ? "1.5rem" : "2rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 2rem;

  svg {
    height: ${(p) => (p.smallButtons ? "1.4rem" : "1.8rem")};
    margin-right: 0;
    fill: ${(p) => p.theme.colors.greyDark};
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.colors.white};
    }
  }
`

const DeleteButton = styled(ActionButton)`
  &:hover {
    background: ${(p) => p.theme.colors.error.background};
    border: 0.1rem solid ${(p) => p.theme.colors.error.background};
  }
`

type Props = {
  values: Inventory[]
  setValues: Dispatch<SetStateAction<Inventory[]>>
  onChange?: (values: Inventory[]) => void
  onDelete?: (inventoryId: string) => void
  loading: boolean
}
const DragAndDropSorting = ({ values, setValues, onChange, onDelete, loading }: Props) => {

  const reorder = (list: Inventory[], startIndex: number, endIndex: number): Inventory[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return
    }

    const items: Inventory[] = reorder(values, result.source.index, result.destination.index)
    setValues(items)
    onChange && onChange(items)
  }

  const handleDelete = (id: string) => {
    onDelete && onDelete(id)
  }

  if(loading && values.length === 0) return (
    <>
      <BlurRow>
        <Name>
          <BarIcon />
          inventory
        </Name>
        <Order>
          {1}
          <DeleteButton
            type="button"
            title="Delete"
            smallButtons={true}
          >
            <DeleteIcon />
          </DeleteButton>
        </Order>
      </BlurRow>
      <BlurRow>
        <Name>
          <BarIcon />
          inventory
        </Name>
        <Order>
          {2}
          <DeleteButton
            type="button"
            title="Delete"
            smallButtons={true}
          >
            <DeleteIcon />
          </DeleteButton>
        </Order>
      </BlurRow>
    </>
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {values.length > 0 && <Header><div>Name</div><div>Sort order</div></Header>}
      <Droppable droppableId="droppable">
        {(provided) => (
          <Container {...provided.droppableProps} ref={provided.innerRef}>
            {values.map((value, index) => (
              <Draggable key={value.id} draggableId={value.id} index={index} isDragDisabled={!isSuperUser()}>
                {(provided): ReactElement => (
                  <SortRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                   <Name><BarIcon/>{value.name}</Name>
                    <Order>{index +1}
                      {isSuperUser() && <DeleteButton type="button" title="Delete" smallButtons={true} onClick={() => handleDelete(value.id)}>
                        <DeleteIcon />
                      </DeleteButton>}
                    </Order>

                  </SortRow>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DragAndDropSorting
