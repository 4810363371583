import React from "react"
import EditSidebar from "../../components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "../../components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "../../components/Ui/Buttons/PrimaryButton"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Input from "../../components/Ui/Form/Input"
import { useMutation } from "@apollo/client"
import CREATE_OR_UPDATE_INVENTORY from "../../graphql/mutations/inventory/CreateOrUpdateInventory"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import { InputInventory } from "@lib/types/generated/graphql-types"

type Props = {
  refetch: () => void
}

export const CreateInventory = ({ refetch }: Props) => {

  interface FullInputInventory extends InputInventory {
    id: string;
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FullInputInventory>({ mode: "onBlur" })

  const dispatch = useAppDispatch()

  const [addInventory, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_INVENTORY,
    {
      onCompleted: (data) => {
        dispatch(
          alertActions.actions.createAlert({
            message: `Inventory ${data?.createOrUpdateInventory?.name} successfully created`,
            type: "success"
          })
        )
        reset({
          id: "",
          name: "",
          description: "",
          address: {
            city: "",
            country: "",
            postalCode: "",
            region: "",
            streetAddress: ""
          }
        })
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<FullInputInventory> = (data) => {
    addInventory({
        variables: { ...data, id: data.id.replaceAll(" ", "") }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Inventory">
          <PrimaryButton
            type="submit"
            handleClick={handleSubmit(onSubmit)}
            loading={createLoading}
          >
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Controller
          name="id"
          render={({ field }) => (
            <Input
              {...field}
              label="Identifier *"
              type="text"
              placeholder="Identifier *"
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              label="Name *"
              type="text"
              placeholder="Name *"
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="description"
          render={({ field }) => (
            <Input
              {...field}
              label="Description *"
              type="text"
              placeholder="Description *"
              errors={errors}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="address.city"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="City *"
              placeholder="City *"
              errors={errors}
            />
          )}
          control={control}
          defaultValue={""}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="address.country"
          render={({ field }) => (
            <Input
              {...field}
              label="Country *"
              type="text"
              placeholder="Country *"
              errors={errors}
            />
          )}
          control={control}
          defaultValue={""}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="address.postalCode"
          render={({ field }) => (
            <Input
              {...field}
              label="Postal code *"
              type="text"
              placeholder="Postal code *"
              errors={errors}
            />
          )}
          control={control}
          defaultValue={""}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="address.region"
          render={({ field }) => (
            <Input
              {...field}
              label="Region *"
              type="text"
              placeholder="Region *"
              errors={errors}
            />
          )}
          control={control}
          defaultValue={""}
          rules={{
            required: "This is a required field"
          }}
        />
        <Controller
          name="address.streetAddress"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
              label="Street address *"
              placeholder="Street Address *"
              errors={errors}
            />
          )}
          control={control}
          defaultValue={""}
          rules={{
            required: "This is a required field"
          }}
        />
      </EditSidebar>
    </form>
  )
}
