import gql from "graphql-tag"

const ENABLE_EXTERNAL_EVENT_RULE = gql`
  mutation addExternalEventsRule($ruleName: String!) {
    addExternalEventsRule(ruleName: $ruleName) {
      ruleName
    }
  }
`

export default ENABLE_EXTERNAL_EVENT_RULE
