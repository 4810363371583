import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { Inventory } from "@lib/types/generated/graphql-types"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"

import { StyledTableHeader, Wrapper, Container, Name, Id, EmptyList } from "./InventoryTable.styled"

import TableRow from "components/Ui/Table/TableRow"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"

import { ReactComponent as WarehouseIcon } from "images/icons/warehouse.svg"

type Props = {
  inventories: Inventory[]
  loading: boolean
  error: object | undefined
  setEditId: Dispatch<SetStateAction<string>>
}

export const InventoryTable = ({ inventories, loading = false, setEditId, error }: Props) => {
  const dispatch = useAppDispatch()

  const tableHeader = (
    <StyledTableHeader>
      <div>Name</div>
      <div className="hideOnSmallScreens">Description</div>
    </StyledTableHeader>
  )

  const getTableRow = (inventory?: Inventory) => {
    if (inventory) {
      return (
        <TableRow
          key={inventory.id}
          handleOnClick={() => {
            setEditId(inventory.id)
            dispatch(showEditSidebar())
          }}
        >
          <WarehouseIcon />
          <div>
            <div>
              <Name>{inventory.name}</Name>
              <Id>{inventory.id}</Id>
            </div>
          </div>
          <div className="hideOnSmallScreens">
            <p>{inventory.description}</p>
          </div>
        </TableRow>
      )
    }
    return (
      <TableRow>
        <WarehouseIcon />
        <div>
          <div>
            <Name>Inventory</Name>
            <Id>inventoryid</Id>
          </div>
        </div>
        <div className="hideOnSmallScreens">
          <p>Inventory description</p>
        </div>
      </TableRow>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getTableRow()} />

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Inventories. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {inventories.length > 0 ? (
          <>
            {tableHeader}
            {[...inventories].map((inventory) => getTableRow(inventory))}
          </>
        ) : (
          <EmptyList>
            <WarehouseIcon />
            <p> No inventories found</p>
          </EmptyList>
        )}
      </Container>
    </Wrapper>
  )
}
