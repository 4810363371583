import React, { Dispatch, SetStateAction } from "react"
import { InputBundleItemRuleWithId, InputBundleWithItemRuleId } from "@lib/types/discount"
import AddedBundle from "./AddedBundle"
import { Container } from "./AddedBundles.styled"
import { InputDiscountValue } from "@lib/types/generated/graphql-types"

type Props = {
  bundles: InputBundleWithItemRuleId[]
  setBundles: Dispatch<SetStateAction<InputBundleWithItemRuleId[]>>
  setDiscountValue: Dispatch<SetStateAction<InputDiscountValue | undefined>>
  setItemRules: Dispatch<SetStateAction<InputBundleItemRuleWithId[]>>
  setEditBundleId: Dispatch<SetStateAction<string>>
}

const AddedBundles = ({
  bundles,
  setBundles,
  setDiscountValue,
  setItemRules,
  setEditBundleId
}: Props) => {
  const removeBundle = (bundleId: string) => {
    setBundles((prev) => prev.filter((bundle) => bundle.id !== bundleId))
  }

  const editBundle = (bundle: InputBundleWithItemRuleId) => {
    if(bundle.discountValue) setDiscountValue(bundle.discountValue)
    setItemRules(bundle.itemRules)
    setEditBundleId(bundle.id)
    window.scrollTo(0, 0)
  }

  return (
    <Container>
      {bundles.map((bundle, index) => (
        <AddedBundle
          bundle={bundle}
          key={bundle.id}
          index={index}
          removeBundle={removeBundle}
          editBundle={editBundle}
        />
      ))}
    </Container>
  )
}

export default AddedBundles
