import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useLazyQuery } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { InputRetain24Config } from "lib/types/generated/graphql-types"

import CREATE_OR_UPDATE_RETAIN24_CONFIG from "graphql/mutations/config/CreateOrUpdateRetain24Config"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import {
  Retain24ConfigInputs,
  FormInputRetain24Config
} from "components/Config/Retain24/Retain24ConfigInputs"
import VALIDATE_RETAIN24 from "graphql/queries/config/ValidateRetain24Config"
import Popup from "components/Ui/Popup"

interface ExtendedInputRetain24Config extends InputRetain24Config {
  id: string
}

type Props = {
  refetch: () => void
}

export const CreateRetain24Config = ({ refetch }: Props) => {
  const dispatch = useAppDispatch()
  const [validationError, setValidationError] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FormInputRetain24Config>({ mode: "onBlur" })

  const [addRetain24Config, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_RETAIN24_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          baseUrl: "",
          simulationKey: "",
          certificate: undefined,
          certificatePassword: "",
          redeem: false
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Retain24 configuration successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateRetain24, { loading: validateLoading }] = useLazyQuery(VALIDATE_RETAIN24)

  const onSubmit: SubmitHandler<FormInputRetain24Config> = async (data) => {
    const certificate = await toBase64(data.certificate as File)

    const variables: ExtendedInputRetain24Config = {
      id: data.id,
      baseUrl: data.baseUrl,
      certificate: certificate,
      certificatePassword: data.certificatePassword,
      redeem: data.redeem,
      simulationKey: data.simulationKey
    }

    validateRetain24({
      variables: {
        baseUrl: data.baseUrl,
        certificate: certificate,
        certificatePassword: data.certificatePassword
      }
    }).then((response) => {
      if (!response.data?.Configuration.validate.retain24.valid) {
        setValidationError(true)
        return
      } else {
        return addRetain24Config({ variables: variables })
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Retain24 configuration">
          {validationError ? (
            <Popup
              title="Validation error"
              subtitle="Couldn't validate the provided credentials with Retain24."
              buttonText="Ok"
              handleOkClick={() => {
                setValidationError(false)
              }}
              handleCloseClick={() => setValidationError(false)}
            />
          ) : (
            <></>
          )}
          <PrimaryButton type="submit" loading={validateLoading || createLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Retain24ConfigInputs control={control} errors={errors} setValue={setValue} showId={true} />
      </EditSidebar>
    </form>
  )
}

const toBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      const result = reader.result as ArrayBuffer
      let encoded = result.toString().replace(/^data:(.*,)?/, "")

      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4))
      }

      resolve(encoded)
    }
    reader.onerror = reject
  })
}
