import gql from "graphql-tag"

const CREATE_STORE_MARKET = gql`
    mutation createStoreMarket(
        $storeGroupId: String!
        $countryCode: String!
        $currencyCode: String!
        $isTaxIncludedInPrice: Boolean!
        $isTaxExemptionEligible: Boolean
    ) {
        createStoreMarket(
            storeGroupId: $storeGroupId
            countryCode: $countryCode
            storeMarket: { currencyCode: $currencyCode, isTaxIncludedInPrice: $isTaxIncludedInPrice, isTaxExemptionEligible: $isTaxExemptionEligible }
        ) {
            countryCode
            currencyCode
            isTaxIncludedInPrice
            isTaxExemptionEligible
            storeGroupId
            capabilities {
                shippingProviders {
                    id
                    type
                }
                paymentProviders {
                    id
                    type
                }
            }
        }
    }
`

export default CREATE_STORE_MARKET
