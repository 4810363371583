import React, { Dispatch, SetStateAction } from "react"
import { InputNShiftConfig } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import DELETE_NSHIFT_CONFIG from "graphql/mutations/config/DeleteNShiftCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"

import { ReactComponent as NShiftLogo } from "images/providers/nshift-logo.svg"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import CREATE_OR_UPDATE_NSHIFT_CONFIG from "../../../../graphql/mutations/config/CreateOrUpdateNShiftCheckoutConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import NShiftConfigInputs from "../../../../components/Config/NShift/NShiftConfigInputs"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"
import GET_NSHIFT_CONFIG from "../../../../graphql/queries/config/GetNShiftConfig"

type Props = {
  refetch: () => void
  nShiftConfigId: string
  setNShiftConfigId: Dispatch<SetStateAction<string>>
}

interface ExtendedInputNShiftConfig extends InputNShiftConfig {
  id: string
}

export const EditNShiftConfig = ({ refetch, nShiftConfigId, setNShiftConfigId }: Props) => {
  const dispatch = useAppDispatch()
  const closeSidebar = () => {
    setNShiftConfigId("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ExtendedInputNShiftConfig>({ mode: "onBlur" })

  const { data } = useQuery(GET_NSHIFT_CONFIG, {variables: {id: nShiftConfigId}})

  const nShiftConfig = data.getNShiftConfig

  const [updateShiftConfig, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_NSHIFT_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "nShift configuration successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputNShiftConfig> = (data) => {
    updateShiftConfig({ variables: { ...data, baseUrl: nShiftConfig.baseUrl, id: nShiftConfigId } })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader
          icon={<NShiftLogo />}
          title={nShiftConfigId}
          id={nShiftConfigId}
          cancelEvent={() => closeSidebar()}
        >
          <DeleteButton
            id={nShiftConfigId}
            name={nShiftConfig?.baseUrl}
            entity={"nShift configuration"}
            redirectUrl="/settings/providers/Nshift"
            mutation={DELETE_NSHIFT_CONFIG}
            refetch={refetch}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={updateLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <NShiftConfigInputs control={control} errors={errors} defaultValues={nShiftConfig} />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={nShiftConfigId}
              providerType={PROVIDER_TYPE.NSHIFT}
              providerCategory={PROVIDER_CATEGORY.SHIPPING}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}
