import gql from "graphql-tag"

const CREATE_REFUND = gql`
  mutation createRefund($orderId: ID!, $input: InputOrderRefund!) {
    Order {
      createRefund(orderId: $orderId, input: $input) {
        id
      }
    }
  }
`

export default CREATE_REFUND
