import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import { State } from "lib/types"
import { api } from "lib/store/services/brink/api"


const initialState: State.BrinkState = {
  storeGroupID: 'brinkcommerce',
  countryCode: 'SE',
  token: undefined,
  cart: undefined,
  capabilities: undefined,
  cartCapabilities: undefined,
  isCartOpen: false,
  isCartLoading: false,
  addDiscountCodeError: undefined,
  checkoutToken: undefined,
  termsUrl: undefined
}

export const BRINK_TOKEN_KEY = 'brinkcommerce.token'

function reset(state: State.BrinkState) {
  localStorage.removeItem(BRINK_TOKEN_KEY)
  state.token = initialState.token
  state.cart = initialState.cart
  state.capabilities = initialState.capabilities
  state.cartCapabilities = initialState.cartCapabilities
  state.isCartOpen = initialState.isCartOpen
  state.isCartLoading = initialState.isCartLoading
  state.checkoutToken = initialState.checkoutToken
  state.termsUrl = initialState.termsUrl
}

export const slice = createSlice({
  name: "brink",
  initialState: { ...initialState },
  reducers: {
    reset: reset,
    showCart(state, action: PayloadAction<boolean>) {
      state.isCartOpen = action.payload
    },
    setStoreGroupId(state, action: PayloadAction<string>) {
      state.storeGroupID = action.payload
    },
    setCountryCode(state, action: PayloadAction<string>) {
      state.countryCode = action.payload
    },
    setTermsUrl(state, action: PayloadAction<string>) {
      state.termsUrl = action.payload
    }
  },
  extraReducers: (builder) => {
    const sessionPending = isAnyOf(
      api.endpoints.getSession.matchPending,
      api.endpoints.startSession.matchPending,
      api.endpoints.addItem.matchPending,
      api.endpoints.updateItem.matchPending,
      api.endpoints.deleteItem.matchPending,
      api.endpoints.addDiscountCode.matchPending,
      api.endpoints.deleteDiscountCode.matchPending,
      api.endpoints.startCheckout.matchPending
    )
    const sessionFulfilled = isAnyOf(
      api.endpoints.getSession.matchFulfilled,
      api.endpoints.startSession.matchFulfilled,
      api.endpoints.addItem.matchFulfilled,
      api.endpoints.updateItem.matchFulfilled,
      api.endpoints.deleteItem.matchFulfilled,
      api.endpoints.addDiscountCode.matchFulfilled,
      api.endpoints.deleteDiscountCode.matchFulfilled,
      api.endpoints.startCheckout.matchFulfilled
    )
    const sessionRejected = isAnyOf(
      api.endpoints.getSession.matchRejected,
      api.endpoints.startSession.matchRejected,
      api.endpoints.addItem.matchRejected,
      api.endpoints.updateItem.matchRejected,
      api.endpoints.deleteItem.matchRejected,
      api.endpoints.addDiscountCode.matchRejected,
      api.endpoints.deleteDiscountCode.matchRejected,
      api.endpoints.startCheckout.matchRejected
    )

    const checkoutFulfilled = api.endpoints.startCheckout.matchFulfilled

    builder.addMatcher(sessionPending, (state) => {
      state.isCartLoading = true
    })

    builder.addMatcher(sessionFulfilled, (state, action) => {
      state.token = action.payload.token
      state.cart = action.payload.cart
      state.capabilities = action.payload.capabilities
      state.cartCapabilities = action.payload.cartCapabilities
      state.isCartLoading = false
    })

    builder.addMatcher(checkoutFulfilled, (state, action) => {
      state.checkoutToken = action.payload.token
    })

    builder.addMatcher(sessionRejected, (state, action) => {

      state.isCartLoading = false
      if (action.payload?.status === 403 && action.payload?.data?.error == 'cart closed') {
        reset(state)
      }
    })
  }
})

export default slice.reducer
