import styled from "styled-components/macro"

import TableHeader from "components/Ui/Table/TableHeader"
import TableRow from "components/Ui/Table/TableRow"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
  line-height: 1.4rem;

  div {
    padding-right: 1rem;
  }
`

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: initial;
    background: initial;
  }
`

export const Name = styled.div`
  ${(p) => p.theme.bold};
  text-transform: capitalize;
`

export const Period = styled.span`
  font-size: 1.3rem;
  text-transform: lowercase;
`
