import gql from "graphql-tag"

const DISABLE_EXTERNAL_EVENT_RULE = gql`
  mutation deleteExternalEventsRule($ruleName: String!) {
    deleteExternalEventsRule(ruleName: $ruleName) {
      status
    }
  }
`

export default DISABLE_EXTERNAL_EVENT_RULE
