import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import {
  DiscountCodeRuleSearchHit,
  DiscountExternalRuleSearchHit,
  DiscountRuleSearchHit
} from "@lib/types/generated/graphql-types"
import { useNavigate } from "react-router-dom"
import { RULE_TYPE } from "lib/types/common"

import { RuleInfo, Actions } from "./DiscountRuleTable.styled"

import { IconTableHeader, EmptyList, Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import ActionButton from "components/Ui/Table/ActionButton"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import Status from "components/Ui/Status"
import DeleteDiscountRule from "./DeleteDiscountRule"

import { ReactComponent as ListTreeIcon } from "images/icons/arrow-up-right-dots.svg"
import { ApolloError } from "@apollo/client"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"

type Props = {
  loading: boolean
  setDiscountRules:
    | Dispatch<SetStateAction<DiscountCodeRuleSearchHit[]>>
    | Dispatch<SetStateAction<DiscountExternalRuleSearchHit[]>>
    | Dispatch<SetStateAction<DiscountRuleSearchHit[]>>
  type: RULE_TYPE
  // eslint-disable-next-line
  discountRules: any
  error: ApolloError | undefined
}

export const DiscountRuleTable = ({ discountRules, setDiscountRules, loading, type, error }: Props) => {
  const navigate = useNavigate()

  const getUrl = (id: string) => {
    switch (type) {
      case RULE_TYPE.CART_RULE:
        return `/discounts/cart-rules/${id}`
      case RULE_TYPE.CODE_RULE:
        return `/discounts/discount-code-rules/${id}`
      case RULE_TYPE.EXTERNAL_RULE:
        return `/discounts/external-rules/${id}`
    }
  }

  const getName = () => {
    switch (type) {
      case RULE_TYPE.CART_RULE:
        return `cart rules`
      case RULE_TYPE.CODE_RULE:
        return `discount code rules`
      case RULE_TYPE.EXTERNAL_RULE:
        return `external rules`
    }
  }

  const tableHeader = (
    <IconTableHeader>
      <RuleInfo>Name</RuleInfo>
      <div>Status</div>
      <div>Valid from</div>
      <div>Valid to</div>
      <div>Sort order</div>
      <Actions></Actions>
    </IconTableHeader>
  )

  const getTableRow = (
    discountRule?: DiscountCodeRuleSearchHit | DiscountRuleSearchHit | DiscountExternalRuleSearchHit
  ) => {
    if (discountRule)
      return (
        <>
          <TableRow>
            <ListTreeIcon />
            <RuleInfo>
              <div>
                <Name>{discountRule.name} </Name>
                <Id>{discountRule.id} </Id>
              </div>
            </RuleInfo>
            <div>
              {discountRule.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
            </div>
            <div>
              {discountRule.validFrom ? dayjs(discountRule.validFrom).format("YYYY-MM-DD") : "-"}
            </div>
            <div>
              {discountRule.validTo ? dayjs(discountRule.validTo).format("YYYY-MM-DD") : "-"}
            </div>
            <div>{discountRule.sortOrder}</div>
            <Actions>
              <DeleteDiscountRule
                discountRule={discountRule}
                discountRules={discountRules}
                setDiscountRules={setDiscountRules}
                type={type}
              />
            </Actions>
          </TableRow>
        </>
      )
    return (
      <>
        <ListTreeIcon />
        <RuleInfo>
          <div>
            <Name>Discount rule name</Name>
            <Id>b40c453e-bc09-4d07-b031-825cb4d89b3d</Id>
          </div>
        </RuleInfo>
        <div>
          <Status status="Default" />
        </div>
        <div>2000-00-00</div>
        <div>2000-00-00</div>
        <div>1</div>
        <Actions>
          <ActionButton mode="delete" />
        </Actions>
      </>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={10} tableHeader={tableHeader} rows={getTableRow()} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            {`Error loading ${getName()}. Try refreshing the page, or contact `}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  return discountRules?.length > 0 ? (
    <>
      {tableHeader}
      {discountRules.map(
        (
          discountRule:
            | DiscountCodeRuleSearchHit
            | DiscountRuleSearchHit
            | DiscountExternalRuleSearchHit
        ) => (
          <div key={discountRule.id} onClick={() => navigate(getUrl(discountRule.id))}>
            {getTableRow(discountRule)}
          </div>
        )
      )}
    </>
  ) : (
    <>
      {!loading && (
        <EmptyList>
          <ListTreeIcon />
          <p> No discount rules found</p>
        </EmptyList>
      )}
    </>
  )
}
