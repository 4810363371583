import React, { useState, MutableRefObject } from "react"
import styled from "styled-components/macro"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { Common } from "@lib/types"
import { Outcomes, FrontendOutcome, TagCondition } from "@lib/types/discount"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"

import SingleSelect from "components/Ui/Form/SingleSelect"
import Input from "components/Ui/Form/Input"
import { TagConditions } from "components/Discount/Ui/TagConditions/TagConditions"
import Checkbox from "components/Ui/Form/Checkbox"
import FixedDiscounts from "../../FixedDiscounts"

const ConditionsAdd = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0 1rem;
  display: block;
  margin-bottom: 3rem;
`

const Divider = styled.div`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  margin-top: 3rem;
`

const Percentage = styled.div`
  width: 100%;
  flex: auto !important;
  margin-top: 2rem;
  display: flex;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 0.5rem 0 0;
  margin-bottom: 1.5rem;
`

type Inputs = {
  discountValueType: string
  valueType: string
  percentage: number
  currency: string
  amount: number
  combineItemDiscount: boolean
}

type Props = {
  outcome?: FrontendOutcome
  addOutcome: (data: Outcomes) => void
  currencyOptions: Common.Option[]
  submitRef: MutableRefObject<HTMLButtonElement>
}

export const ItemDiscounts = ({ outcome, addOutcome, currencyOptions, submitRef }: Props) => {
  const dispatch = useAppDispatch()
  const [fixedDiscounts, setFixedDiscounts] = useState<Common.Money[]>(
    outcome?.data?.discountValue?.fixedDiscounts ?? []
  )
  const [valueType, setValueType] = useState<string>(outcome?.data?.discountValue?.type ?? "")
  const [tagConditions, setTagConditions] = useState<TagCondition[]>(
    outcome?.data?.discountItemRule?.tagConditions ?? []
  )
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(
    outcome?.data?.discountItemRule?.excludeDiscountedPrices ?? false
  )
  const [excludeFullPrices, setExcludeFullPrices] = useState(
    outcome?.data?.discountItemRule?.excludeFullPrices ?? false
  )
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<Inputs>()

  const updateValueType = (valueType: Common.Option) => {
    setValueType(valueType.value)
  }

  const getOption = (type: string) => {
    switch (type) {
      case "RELATIVE":
        return {
          value: "RELATIVE",
          label: "Percentage %"
        }
      case "FIXED":
        return {
          value: "FIXED",
          label: "Fixed amount"
        }
      default:
        return {
          label: "Select discount value type",
          value: ""
        }
    }
  }
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!valueType) {
      dispatch(
        alertActions.actions.createAlert({
          message: "Please select a discount value type",
          type: "error"
        })
      )
      return
    }
    if (valueType === "FIXED" && fixedDiscounts.length === 0) {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one fixed discount is required",
          type: "error"
        })
      )
      return
    }
    addOutcome({
      discountValue: {
        type: valueType,
        ...(valueType === "FIXED" && {
          fixedDiscounts: fixedDiscounts,
          percentage: undefined
        }),
        ...(valueType === "RELATIVE" && {
          percentage: data.percentage,
          fixedDiscounts: []
        })
      },
      combineItemDiscount: data.combineItemDiscount,
      discountItemRule: {
        tagConditions,
        excludeDiscountedPrices,
        excludeFullPrices
      }
    })
  }

  return (
    <>
      <ConditionsAdd>
        <form>
          <Controller
            name="valueType"
            render={({ field }) => (
              <SingleSelect
                {...field}
                options={[getOption("FIXED"), getOption("RELATIVE")]}
                setValue={updateValueType}
                defaultValue={valueType ? getOption(valueType) : getOption("")}
              />
            )}
            control={control}
          />

          <>
            {valueType === "RELATIVE" && (
              <Percentage>
                <Controller
                  name="percentage"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="Percentage, 1 - 100"
                      label="Discount % *"
                      errors={errors}
                    />
                  )}
                  defaultValue={outcome?.data?.discountValue?.percentage ?? undefined}
                  control={control}
                  rules={{
                    required: "This is a required field",
                    pattern: {
                      value: /^\d+$/,
                      message: "Only integers 0 - 9 is allowed"
                    },
                    min: {
                      value: 1,
                      message: "Min value is 1"
                    },
                    max: { value: 100, message: "Max value is 100" }
                  }}
                />
              </Percentage>
            )}
            {valueType === "FIXED" && (
              <FixedDiscounts
                fixedDiscounts={fixedDiscounts}
                setFixedDiscounts={setFixedDiscounts}
                currencyOptions={currencyOptions}
              />
            )}
          </>
          <button
            onClick={handleSubmit(onSubmit)}
            ref={submitRef}
            type="submit"
            style={{ display: "none" }}
          />
        </form>
      </ConditionsAdd>

      <StyledCheckbox
        control={control}
        name="combineItemDiscount"
        label="Combine item discount"
        description="Combine discount with other discounts"
        defaultValue={outcome?.data?.combineItemDiscount ?? false}
      />

      <Divider />

      <TagConditions
        tagConditions={tagConditions}
        setTagConditions={setTagConditions}
        excludeDiscountedPrices={excludeDiscountedPrices}
        setExcludeDiscountedPrices={setExcludeDiscountedPrices}
        excludeFullPrices={excludeFullPrices}
        setExcludeFullPrices={setExcludeFullPrices}
      />
    </>
  )
}
