import { Money } from "./common"
import {
  DiscountValue,
  Gift, InputBundle,
  InputBundleItemRule,
  InputSubOutcome,
  ItemRule,
  SubOutcome
} from "@lib/types/generated/graphql-types"
import { Common } from "@lib/types/index"

export interface LogicCondition {
  validate: string
}

export interface TagCondition {
  key: string
  mode: string
  values: string[]
}

export interface FrondEndConditionData {
  type: string
  identifiers?: string[]
  thresholds?: Money[]
  triggerQuantity?: number
  itemRule: ItemRule
}

export interface FrontendCondition {
  id?: string
  type: string
  title: string
  data?: FrondEndConditionData
}

export type FrontendLogicItem = FrontendCondition | FrontendOutcome

export interface FrontEndOutcomeData {
  type: string
  discountValue?: DiscountValue
  discountItemRule?: ItemRule
  triggerItemRule?: ItemRule
  isRecurrent?: boolean
  triggerQuantity?: number
  discountQuantity?: number
  gifts?: Gift[]
  combineItemDiscount?: boolean
  subOutcomes: SubOutcome[]
}

export interface FrontendOutcome {
  id?: string
  type: string
  title: string
  content?: string
  data?: FrontEndOutcomeData
}

export interface LogicRule {
  outcomes: FrontendOutcome[]
  conditions: FrontendCondition[]
}

export interface LogicState {
  [key: string]: LogicRule
}

export interface DiscountRuleInputs {
  name: string
  sortOrder: number
  isActive: boolean
  isStackable: boolean
  isExclusive?: boolean
  applyLast?: boolean
  from: string
  to: string
  conditionType: string
  currency: string
  amount: number
  outcomeType: string
}

export interface CodeInputs {
  name: string
  code: string
  usageLimit: number | undefined
  from: string
  to: string
}

interface ThresholdCondition {
  thresholds: Common.Money[]
  itemRule?: ItemRule
}

interface StoreCondition {
  identifiers: string[]
  itemRule?: ItemRule
}

interface MinCartItemsCondition {
  triggerQuantity: number
  itemRule: ItemRule
}

interface BuyGetOutcome {
  discountItemRule: ItemRule
  isRecurrent: boolean
  triggerQuantity: number
  discountQuantity: number
  discountValue: DiscountValue
}

interface BuySelectOutcome extends BuyGetOutcome {
  triggerItemRule: ItemRule
  combineItemDiscount: boolean
}

interface ItemDiscountOutcome {
  discountItemRule: ItemRule
  discountValue: DiscountValue
  combineItemDiscount: boolean
}

interface OptimizeBuyGetsOutcome {
  discountItemRule: ItemRule
  subOutcomes: InputSubOutcome[]
}

interface CartDiscountOutcome {
  discountValue: DiscountValue
}

interface GiftOutcome {
  gifts: Gift[]
}

export type Conditions = ThresholdCondition | StoreCondition | MinCartItemsCondition

export type Outcomes =
  | BuyGetOutcome
  | BuySelectOutcome
  | ItemDiscountOutcome
  | GiftOutcome
  | CartDiscountOutcome
  | OptimizeBuyGetsOutcome

export type BundleSection = {
  value: BundleSectionValue
  label: string
  valid?: boolean
}

export enum BundleSectionValue {
  GENERAL,
  BUNDLES,
  CHANNELS,
  RESULT
}

export interface InputBundleItemRuleWithId extends InputBundleItemRule {
  id: string
}

export interface InputBundleWithItemRuleId extends InputBundle {
itemRules: InputBundleItemRuleWithId[]
}
