import React, { SetStateAction, Dispatch } from "react"
import { Campaign } from "@lib/types/generated/graphql-types"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import { Wrapper, Container, Name, Actions, CampaignProgress, Date, StyledTableRow } from "./CampaignTable.styled"
import { IconTableHeader, Id, EmptyList } from "components/Ui/Table/Shared.styled"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "../Ui/Table/TableBlurLoading"
import ActionButton from "components/Ui/Table/ActionButton"
import { ReactComponent as CampaignIcon } from "images/icons/badge-percent.svg"
import { GroupedCampaign } from "@lib/types/common"

type Props = {
  campaigns: Campaign[]
  loading: boolean
  error: object | undefined
  setGroupedCampaigns?: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
  hideDelete?: boolean
}

function CampaignTable({ campaigns, loading = false, error, hideDelete }: Props) {
  const navigate = useNavigate()

  const tableHeader = (
    <IconTableHeader>
      <div>Name</div>
      <div>Status</div>
      <Date className="hideOnSmallScreens">Start date</Date>
      <Date className="hideOnSmallScreens">End date</Date>
      <>{!hideDelete && <Actions></Actions>}</>
    </IconTableHeader>
  )

  const getTableRow = (campaign?: Campaign) => {
    if (campaign)
      return (
        <StyledTableRow handleOnClick={() => navigate(`/campaigns/campaign/${campaign.id}`)}>
          <CampaignIcon />
          <div>
            <div>
              <Name>{campaign.name}</Name>
            </div>
          </div>
          <div>
            <CampaignProgress startDate={campaign.startDate} endDate={campaign.endDate} />
          </div>
          <Date className="hideOnSmallScreens">{dayjs(campaign.startDate).format("YYYY-MM-DD HH:mm")}</Date>
          <Date className="hideOnSmallScreens">{dayjs(campaign.endDate).format("YYYY-MM-DD HH:mm")}</Date>
        </StyledTableRow>
      )
    return (
      <>
        <CampaignIcon />
        <div>
          <div>
            <Name>Campaign name</Name>
            <Id>Campaign id</Id>
          </div>
        </div>
        <div>
          <CampaignProgress startDate="2000-00-00 00:00" endDate="2000-00-00 00:00" />
        </div>
        <Date>2000-00-00 00:00</Date>
        <Date>2000-00-00 00:00</Date>
        <Actions>
          <ActionButton mode="delete" />
        </Actions>
      </>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={10} tableHeader={tableHeader} rows={getTableRow()} />

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Campaigns. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {campaigns.length > 0 ? (
          <>
            {tableHeader}
            {campaigns.map((campaigns) => (
              <div key={campaigns.id}>{getTableRow(campaigns)}</div>
            ))}
          </>
        ) : (
          <EmptyList>
            <CampaignIcon />
            <p> No campaigns found</p>
          </EmptyList>
        )}
      </Container>
    </Wrapper>
  )
}

export default CampaignTable
