import gql from "graphql-tag"

const SEARCH_ORDERS = gql`
  query searchOrders(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
    $trackTotalHits: Boolean
  ) {
    searchOrders(
      from: $from
      size: $size
      sort: $sort
      query: $query
      trackTotalHits: $trackTotalHits
    ) {
      total
      hits {
        billingAddress {
          city
          country
          email
          familyName
          givenName
          postalCode
          stateOrProvince
          streetAddress
          streetAddress2
          telephoneNumber
        }
        countryCode
        currencyCode
        date
        id
        paymentMethod
        paymentProviderName
        paymentReference
        reference
        merchantReference1
        merchantReference2
        status {
          orderStates
        }
        shippingAddress {
          city
          country
          email
          familyName
          givenName
          postalCode
          stateOrProvince
          streetAddress
          streetAddress2
          telephoneNumber
        }
        storeGroupId
        totals {
          discountTotal
          grandTotal
          shippingTotal
          subTotal
          taxTotal
        }
      }
    }
  }
`

export default SEARCH_ORDERS
