import gql from "graphql-tag"

const CREATE_USER = gql`
    mutation createUser(
        $username: AWSEmail!
        $givenName: String!
        $familyName: String!
    ) {
        createUser(
            input: {username: $username givenName: $givenName familyName: $familyName}
        ) {
            username
        }
    }
`

export default CREATE_USER