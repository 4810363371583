import gql from "graphql-tag"

const GROUPS = gql`
    query groups(
        $limit: Int!
        $token: String
    ) {
        groups(
            limit: $limit
            token: $token
        ) {
            nextToken
            groups {
                name
                description
            }
        }
    }
`

export default GROUPS