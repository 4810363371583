import gql from "graphql-tag"

const VALIDATE_EXTERNAL_RULE_ID = gql`
  query searchDiscountExternalRules(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
  ) {
    searchDiscountExternalRules(from: $from, size: $size, sort: $sort, query: $query) {
      total
    }
  }
`

export default VALIDATE_EXTERNAL_RULE_ID
