import gql from "graphql-tag"

const SEARCH_DISCOUNT_RULES = gql`
  query searchDiscountRules(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
  ) {
    searchDiscountRules(from: $from, size: $size, sort: $sort, query: $query) {
      total
      hits {
        id
        name
        isActive
        validFrom
        validTo
        created
        isStackable
        sortOrder
        updated
      }
    }
  }
`

export default SEARCH_DISCOUNT_RULES
