import React, { useState } from "react"
import DineroFactory from "dinero.js"
import { isSuperUser } from "helpers/user"
import {
  InputMaybe,
  InputMoney,
  InputStoreMarket,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { Common } from "lib/types"
import { getCurrency } from "helpers/currency"

import Input from "../../../../Ui/Form/Input"
import Money from "../../../../Money"

import { ReactComponent as PlusIcon } from "../../../../../images/icons/plus.svg"
import {
  Add,
  AddButton,
  Amount,
  Container,
  Currency,
  CurrencySelect,
  FixedPrice,
  FixedPriceHeader,
  FixedPriceRow,
  Formatted,
  InputWrapper
} from "../AddBundles.styled"
import { v4 as uuidv4 } from "uuid"
import Popup from "../../../../Ui/Popup"

type Props = {
  fixedDiscounts: InputMaybe<InputMoney[] | undefined>
  storeGroups: StoreGroup[] | undefined
  addedStoreMarkets: InputStoreMarket[]
  updateFixedDiscounts: (fixedDiscounts: InputMoney[]) => void
}

const BundleFixedAmount = ({
  storeGroups,
  addedStoreMarkets,
  fixedDiscounts = [],
  updateFixedDiscounts
}: Props) => {
  const [currency, setCurrency] = useState<string>("")
  const [amount, setAmount] = useState<number>(0)
  const [openPopup, setOpenPopup] = useState(false)
  const [currencyCodeToDelete, setCurrencyCodeToDelete] = useState("")

  const updateKey = (keyType: Common.Option) => {
    setCurrency(keyType?.value)
  }

  const addedCurrencies = fixedDiscounts?.map((fixedDiscounts) => fixedDiscounts.currencyCode)

  const getAddedCurrencies = (storeGroupId: string, countryCodes: string[]) => {
    const storeGroup = storeGroups?.find((storeGroup) => storeGroup.id === storeGroupId)
    return storeGroup?.storeMarkets?.filter((val) => countryCodes.includes(val?.countryCode ?? ""))
  }

  const uniqueCurrencies = [
    ...new Set(
      addedStoreMarkets
        .map((addedStoreMarket) => getAddedCurrencies(addedStoreMarket.key, addedStoreMarket.value))
        .flatMap((storeMarket) => storeMarket?.flatMap((storeMarket) => storeMarket?.currencyCode))
    )
  ]

  const currencyOptions = uniqueCurrencies
    ?.filter((currency) => !addedCurrencies?.includes(currency ?? ""))
    ?.map((currency) => ({
      value: currency ?? "",
      label: `${getCurrency(currency ?? "")?.currency} [${currency}]`
    }))

  const addFixedDiscount = () => {
    if (!addedCurrencies?.includes(currency ?? "")) {
      const copy = [...(fixedDiscounts ?? [])]
      copy.push({ currencyCode: currency, units: amount })
      updateFixedDiscounts(copy)
    }
    setCurrency("")
  }

  const onDeleteCurrency = (currencyCode: string) => {
    const copy = [...(fixedDiscounts ?? [])]
    updateFixedDiscounts(
      copy?.filter((fixedDiscount) => fixedDiscount?.currencyCode !== currencyCode)
    )
    setCurrencyCodeToDelete("")
  }

  return (
    <Container>
      <Add>
        <h3>Add price rule(s)</h3>
        <InputWrapper>
          <CurrencySelect
            options={currencyOptions}
            setValue={updateKey}
            placeholder="Select currency"
            label="Currency:"
            updateDefaultValue={true}
          />
          <Input
            name="amount"
            type="number"
            label={<>Amount in minor units:</>}
            placeholder="Amount in minor units"
            value={amount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAmount(parseInt(e.target.value))
            }}
          />
          <AddButton
            type="button"
            overrideDisabled={!!currency && !!amount && !isSuperUser()}
            handleClick={addFixedDiscount}
          >
            <PlusIcon /> Add
          </AddButton>
        </InputWrapper>
        <Formatted>
          <strong>Example: </strong>
          {amount && currency ? (
            <Money
              amount={parseInt(`${amount}`)}
              currencyUnit={currency as DineroFactory.Currency}
            />
          ) : (
            "0"
          )}
        </Formatted>
      </Add>
      <FixedPrice>
        {fixedDiscounts && fixedDiscounts?.length > 0 ? (
          <>
            <FixedPriceHeader>
              <div>Currency</div>
              <div>Amount</div>
            </FixedPriceHeader>
            <div>
              {fixedDiscounts.map((fixedDiscount) => {
                return (
                  <React.Fragment key={uuidv4()}>
                    <FixedPriceRow
                      deleteEnabled={isSuperUser()}
                      smallButtons
                      disableHover
                      handleDelete={() => {
                        setCurrencyCodeToDelete(fixedDiscount.currencyCode)
                        setOpenPopup(true)
                      }}
                    >
                      <Currency>
                        <span>{fixedDiscount.currencyCode}</span>
                        {getCurrency(fixedDiscount.currencyCode).currency}
                      </Currency>
                      <Amount>
                        <span>{fixedDiscount.units}</span>
                        <Money
                          amount={parseInt(`${fixedDiscount.units}`)}
                          currencyUnit={fixedDiscount.currencyCode as DineroFactory.Currency}
                        />
                      </Amount>
                    </FixedPriceRow>
                    {openPopup && currencyCodeToDelete === fixedDiscount.currencyCode && (
                      <Popup
                        title="Delete fixed discount"
                        subtitle={`Are you sure you want to delete the fixed discount for following currency?`}
                        item={{ itemId: "", itemName: fixedDiscount.currencyCode }}
                        handleCloseClick={() => setOpenPopup(false)}
                        handleOkClick={() => onDeleteCurrency(fixedDiscount.currencyCode)}
                        type="delete"
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </>
        ) : (
          <p>No fixed discounts added</p>
        )}
      </FixedPrice>
    </Container>
  )
}

export default BundleFixedAmount
