import countries from "i18n-iso-countries"
import English from "i18n-iso-countries/langs/en.json"
countries.registerLocale(English)

export const getCountryName = (countryCode: string) =>
  countries.getName(countryCode, "en")

export const countryOptions = Object.entries(
  countries.getNames("en", { select: "official" })
).map(([key, value]) => ({ value: key, label: `${value} [${key}]` }))
