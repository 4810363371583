import React, { Dispatch, SetStateAction } from "react"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentDelivery,
  InputActionShipping,
  InputActionType,
  OrderDelivery
} from "lib/types/generated/graphql-types"
import {
  ActionContainer,
  ActionWrapper,
  ButtonWrapper,
  Container,
  GiftCardInputWrapper,
  InfoWrapper,
  InputContainer,
  InputWrapper,
  LabelWrapper,
  StyledActionDropdownButton
} from "./DeliveryAction.styled"
import { ActionDropdownButton } from "../../../../Ui/Buttons/ActionDropdownButton"
import { ReactComponent as GiftCardIcon } from "../../../../../images/icons/gift.svg"
import { ReactComponent as BonusIcon } from "../../../../../images/icons/hand-holding-dollar.svg"
import { ReactComponent as PaymentIcon } from "../../../../../images/icons/credit-card.svg"
import { ReactComponent as ShippingIcon } from "../../../../../images/icons/cart-flatbed-boxes.svg"
import Input from "../../../../Ui/Form/Input"
import { useMutation } from "@apollo/client"
import START_DELIVERY from "../../../../../graphql/mutations/order/deliveries/StartDelivery"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { handleOrderManagementError } from "../../Helpers/errors"

type ActionState = {
  giftCardAction: InputActionGiftCard
  setGiftCardAction: Dispatch<SetStateAction<InputActionGiftCard>>
  bonusAction: InputActionBonus
  setBonusAction: Dispatch<SetStateAction<InputActionBonus>>
  paymentAction: InputActionPaymentDelivery
  setPaymentAction: Dispatch<SetStateAction<InputActionPaymentDelivery>>
  shippingAction: InputActionShipping
  setShippingAction: Dispatch<SetStateAction<InputActionShipping>>
}

type Props = {
  delivery: OrderDelivery
  actionState: ActionState
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  orderId: string
  setStartedDelivery: Dispatch<SetStateAction<string>>
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
}

const DeliveryAction = ({
  actionState,
  delivery,
  setShowStatusPopup,
  setStartedDelivery,
  setShowConfirmation
}: Props) => {
  const {
    giftCardAction,
    setGiftCardAction,
    bonusAction,
    setBonusAction,
    paymentAction,
    setPaymentAction,
    shippingAction,
    setShippingAction
  } = actionState

  const [startDelivery, { loading }] = useMutation(START_DELIVERY, {
    onCompleted: () => {
      setStartedDelivery(delivery.id)
      setShowStatusPopup(true)
      setShowConfirmation(false)
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const startOrderDelivery = () => {
    startDelivery({
      variables: {
        deliveryId: delivery.id,
        input: {
          ...(delivery?.bonus && { bonus: bonusAction }),
          ...(delivery?.giftCards?.length > 0 && { giftCard: giftCardAction }),
          shipping: shippingAction,
          payment: paymentAction
        }
      }
    })
  }

  const updateGiftCardActionCards = (id: string, value: string) => {
    const idExists = giftCardAction?.giftCards?.find((giftCard) => giftCard.giftCardId === id)
    if (idExists) {
      return setGiftCardAction({
        ...giftCardAction,
        giftCards: giftCardAction?.giftCards?.map((giftCard) =>
          giftCard.giftCardId === id
            ? {
                giftCardId: id,
                transactionId: value
              }
            : giftCard
        )
      })
    }
    return setGiftCardAction({
      ...giftCardAction,
      giftCards: [...(giftCardAction.giftCards ?? []), { giftCardId: id, transactionId: value }]
    })
  }

  const updatePaymentCaptureReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, captureReference: reference })
  }

  const updateShippingAction = (key: string, value: string) => {
    setShippingAction({ ...shippingAction, [key]: value })
  }

  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <>
      {" "}
      <h2>Delivery actions</h2>
      <Container>
        <InfoWrapper>
          <b>Auto</b>{" "}
          <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
          <b>Manual</b>{" "}
          <p>
            The action has been performed in an external system and will be stored as manually
            completed.
          </p>
          <b>Skip</b> <p>The action will be skipped entirely.</p>
        </InfoWrapper>
        <div>
          {delivery?.giftCards?.length > 0 && (
            <div>
              <ActionContainer>
                <ActionWrapper>
                  <LabelWrapper>
                    <GiftCardIcon />
                    <b>GiftCard - {delivery?.giftCardProvider?.providerName}</b>
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(giftCardAction.actionType)}>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
                {giftCardAction.actionType === InputActionType.Manual && (
                  <>
                    {delivery.giftCards.map((giftCard) => {
                      return (
                        <GiftCardInputWrapper key={giftCard.giftCardId}>
                          <p>
                            {giftCard.giftCardId} -{" "}
                            <Money
                              amount={giftCard.amount}
                              currencyUnit={giftCard.currencyCode as DineroFactory.Currency}
                            />
                          </p>
                          <Input
                            removePaddingBottom={true}
                            name="transactionId"
                            placeholder="Transaction id"
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                              updateGiftCardActionCards(giftCard.giftCardId, e.currentTarget.value)
                            }
                          />
                        </GiftCardInputWrapper>
                      )
                    })}
                  </>
                )}
              </ActionContainer>
            </div>
          )}
          {delivery?.bonus && (
            <div>
              <ActionContainer>
                <ActionWrapper>
                  <LabelWrapper>
                    <BonusIcon />
                    <b>Bonus - {delivery?.bonusProvider?.providerName}</b>{" "}
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(bonusAction.actionType)}>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
              </ActionContainer>
            </div>
          )}
          {delivery?.shippingFees.length > 0 && <ActionContainer>
            <ActionWrapper>
              <LabelWrapper>
                <ShippingIcon />
                <b>Shipping - {delivery?.shippingProvider?.providerName}</b>
              </LabelWrapper>
              <StyledActionDropdownButton title={getLabel(shippingAction.actionType)}>
                <li onClick={() => setShippingAction({ actionType: InputActionType.Auto })}>
                  Auto
                </li>
                <li onClick={() => setShippingAction({ actionType: InputActionType.Manual })}>
                  Manual
                </li>
                <li onClick={() => setShippingAction({ actionType: InputActionType.Skip })}>
                  Skip
                </li>
              </StyledActionDropdownButton>
            </ActionWrapper>
            {shippingAction.actionType === InputActionType.Manual && (
              <InputContainer>
                <InputWrapper>
                  <Input
                    name="shippingCompany"
                    placeholder="Shipping Company"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateShippingAction("shippingCompany", e.currentTarget.value)
                    }
                  />
                  <Input
                    name="shippingMethod"
                    placeholder="Shipping Method"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateShippingAction("shippingMethod", e.currentTarget.value)
                    }
                  />
                </InputWrapper>
                <Input
                  name="trackingReference"
                  placeholder="Tracking reference"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateShippingAction("trackingReference", e.currentTarget.value)
                  }
                />
                <Input
                  name="trackingUrl"
                  placeholder="Tracking url"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateShippingAction("trackingUrl", e.currentTarget.value)
                  }
                />
              </InputContainer>
            )}
          </ActionContainer>}
          <ActionContainer>
            <ActionWrapper>
              <LabelWrapper>
                <PaymentIcon />
                <b>Payment - {delivery?.paymentProvider?.providerName}</b>
              </LabelWrapper>
              <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
                <li onClick={() => setPaymentAction({ actionType: InputActionType.Auto })}>Auto</li>
                <li onClick={() => setPaymentAction({ actionType: InputActionType.Manual })}>
                  Manual
                </li>
                <li onClick={() => setPaymentAction({ actionType: InputActionType.Skip })}>Skip</li>
              </StyledActionDropdownButton>
            </ActionWrapper>
            {paymentAction.actionType === InputActionType.Manual && (
              <InputContainer>
                <Input
                  name="captureReference"
                  placeholder="Capture reference"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updatePaymentCaptureReference(e.currentTarget.value)
                  }
                />
              </InputContainer>
            )}
          </ActionContainer>
        </div>
        <ButtonWrapper>
          <SecondaryButton handleClick={() => setShowConfirmation(false)}>
            Back to draft
          </SecondaryButton>
          <PrimaryButton handleClick={startOrderDelivery} loading={loading}>
            Finalize Delivery
          </PrimaryButton>
        </ButtonWrapper>
      </Container>
    </>
  )
}

export default DeliveryAction
