import gql from "graphql-tag"

const RESEND_ORDER_EVENT = gql`
  mutation resendOrderEvent($orderId: ID!) {
    Order {
      resendOrderEvent(orderId: $orderId) {
        status
      }
    }
  }
`

export default RESEND_ORDER_EVENT
