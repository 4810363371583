import styled from "styled-components/macro"
import underline from "images/underline.svg"
import { ReactComponent as CalenderClockIcon } from "images/icons/calendar-clock.svg"


export const Flex = styled.div`
  display: flex;
`

export const Grid = styled.div`
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-bottom: 3rem;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    grid-template-columns: repeat(6, 1fr);
  }
`

export const TimeZoneWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`

export const TimeZoneIcon = styled(CalenderClockIcon)`
  margin-right: 1rem;
  border: 0.1rem solid ${p => p.theme.colors.greyLight};
  height: 4.5rem;
  padding: 0.8rem;
  background: ${p => p.theme.colors.white};
  border-radius: 0.6rem;
`

export const WelcomeGreeting = styled.h2`
  margin-bottom: 4rem;
  font-size: 2.4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-bottom: 6rem;
    font-size: 3.6rem;
  }

  span {
    padding-bottom: 0.3rem;
    background-image: url(${underline});
    background-repeat: no-repeat;
    background-position: center bottom;
  }
`
