import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import dayjs from "dayjs"

import GET_API_CLIENT from "graphql/queries/apiConfig/GetApiClient"
import DELETE_API_CLIENT from "graphql/mutations/apiConfig/deleteApiClient"

import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import { Item, Label, List, Value } from "components/Ui/List/List"
import { ReactComponent as Code } from "images/icons/laptop-code.svg"
import { Scopes } from "lib/types/common"
import { ApiScope, InputUpdateApiClient } from "@lib/types/generated/graphql-types"
import ScopeSelect from "../../../components/Settings/ApiClients/ScopeSelect"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Input from "../../../components/Ui/Form/Input"
import alertActions from "lib/store/services/Alert/AlertSlice"
import UPDATE_API_CLIENT from "../../../graphql/mutations/apiConfig/updateApiClient"

type Props = {
  editApiClient: string
  setEditApiClient: Dispatch<SetStateAction<string>>
  availableScopes: ApiScope[]
  refetch: () => void
}

const UpdateApiClient = ({ editApiClient, setEditApiClient, availableScopes, refetch }: Props) => {
  const [selectedScopes, setSelectedScopes] = useState<Scopes>({ read: [], write: [] })
  const [scopeError, setScopeError] = useState(false)

  const dispatch = useAppDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<InputUpdateApiClient>()

  const [updateApiClient, { loading: updateLoading }] = useMutation(UPDATE_API_CLIENT, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: `Client ${data.updateApiClient.name} successfully updated`,
          type: "success"
        })
      )
      setScopeError(false)
      dispatch(hideEditSidebar())
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error",
          message: error.message
        })
      )
    }
  })

  const { loading, error, data } = useQuery(GET_API_CLIENT, {
    variables: { clientId: editApiClient },
    onCompleted: (data) => {
      const scopes = availableScopes.filter((availableScope) =>
        data?.apiClient?.scopes.find((scope: string) => scope === availableScope.name)
      )
      setSelectedScopes({
        read: scopes?.filter((scope: ApiScope) => scope.name.endsWith("read")),
        write: scopes?.filter((scope: ApiScope) => scope.name.endsWith("write"))
      })
    }
  })

  const onSubmit: SubmitHandler<InputUpdateApiClient> = (data) => {
    const scopes = [
      ...selectedScopes.read.map((scope) => scope.name),
      ...selectedScopes.write.map((scope) => scope.name)
    ]
    if (scopes.length > 0) {
      updateApiClient({
        variables: {
          clientId,
          ...data,
          scopes
        }
      })
    } else {
      setScopeError(true)
    }
  }

  const closeSidebar = () => {
    dispatch(hideEditSidebar())
    setEditApiClient("")
  }

  if (loading) return <EditSidebar loading={true} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading API client. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const { clientId, name, clientSecret, created } = data.apiClient

  return (
    <EditSidebar setEditId={setEditApiClient} cancelEvent={closeSidebar}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader
          title={"Update API client"}
          setEditId={setEditApiClient}
          icon={<Code />}
          id={clientId}
        >
          <DeleteButton
            id={clientId}
            name={name}
            entity={"API client"}
            identifier="clientId"
            redirectUrl="/settings/api-configs"
            mutation={DELETE_API_CLIENT}
            refetch={refetch}
            postDeleteFunction={() => setEditApiClient("")}
            secureDelete={true}
          />
          <PrimaryButton loading={updateLoading} type="submit">
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <List>
          <Item>
            <Label>Client ID:</Label>
            <Value>{clientId}</Value>
          </Item>
          <Item>
            <Label>Client secret:</Label>
            <Value>{clientSecret}</Value>
          </Item>
          <Item>
            <Label>Created:</Label>
            <Value>{dayjs(created).format("YYYY-MM-DD")}</Value>
          </Item>
          <Controller
            name="name"
            render={({ field }) => (
              <Input {...field} label="Name *" placeholder="Name" errors={errors} />
            )}
            defaultValue={name}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
        </List>
      </form>
      <ScopeSelect
        error={scopeError}
        selectedScopes={selectedScopes}
        setSelectedScopes={setSelectedScopes}
        availableScopes={availableScopes}
      />
    </EditSidebar>
  )
}

export default UpdateApiClient
