import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputWalleyCheckoutConfig } from "lib/types/generated/graphql-types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import { CREATE_OR_UPDATE_WALLEY_CHECKOUT_CONFIG } from "graphql/mutations/config/Providers/Walley/CreateOrUpdateWalleyCheckoutConfig"
import { VALIDATE_WALLEY } from "graphql/queries/config/Providers/Walley/ValidateWalleyConfig"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"
import WalleyConfigInputs from "../../../../components/Config/Walley/WalleyConfigInputs"

type Props = {
  refetch: () => void
}

interface ExtendedInputWalleyCheckoutConfig extends InputWalleyCheckoutConfig {
  id: string
}

export const CreateWalleyConfig = ({ refetch }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputWalleyCheckoutConfig>({ mode: "onBlur" })

  const [addWalleyConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_WALLEY_CHECKOUT_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          storeId: "",
          baseUrl: "",
          profileName: "",
          clientId: "",
          secret: "",
          deliveryModuleEnabled: false
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Walley Checkout config successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateWalley, { loading }] = useLazyQuery(VALIDATE_WALLEY)

  const onSubmit: SubmitHandler<ExtendedInputWalleyCheckoutConfig> = (data) => {
    validateWalley({
      variables: {
        input: {
          storeId: data.storeId,
          baseUrl: data.baseUrl,
          scope: data.scope,
          ...(data.profileName && {profileName: data.profileName}),
          clientId: data.clientId,
          secret: data.secret,
          deliveryModuleEnabled: data.deliveryModuleEnabled
        }
      }
    }).then((response) => {
      if (response.data.Configuration.validate.walleyCheckout.valid) {
        addWalleyConfig({
          variables: { ...data, ...(data.profileName && {profileName: data.profileName}) }
        })
      } else {
        setValidationError(true)
      }
    })
  }


  const directSubmit: SubmitHandler<ExtendedInputWalleyCheckoutConfig> = (data) => {
    addWalleyConfig({
      variables: { ...data, ...(data.profileName && {profileName: data.profileName}) }
    })
    setValidationError(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Walley Checkout configuration">
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Walley. Do you want to save the Walley checkout config anyway? "
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <WalleyConfigInputs control={control} errors={errors} setValue={setValue} showId={true}/>
      </EditSidebar>
    </form>
  )
}
