import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Common } from "@lib/types"
import { Control, Controller, FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form"
import SingleSelect from "components/Ui/Form/SingleSelect"
import { FrontendOutcome } from "lib/types/discount"
import {
  DiscountValue,
  InputDiscountValueType,
  InputOutcome
} from "lib/types/generated/graphql-types"
import Input from "components/Ui/Form/Input"
import FixedDiscounts from "./FixedDiscounts"

const SuboutcomeWrapper = styled.div`
  background: ${(p) => p.theme.colors.white};
  padding: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
`

const Percentage = styled.div`
  width: 100%;
  flex: auto !important;
  margin-top: 2rem;
  display: flex;
`

type Props = {
  index: number
  outcome?: FrontendOutcome | undefined
  currencyOptions: Common.Option[]
  control: Control<InputOutcome>
  setValue: UseFormSetValue<InputOutcome>
  errors: FieldErrors<InputOutcome>
  register: UseFormRegister<InputOutcome>
}

export const Suboutcome = ({
  index,
  outcome,
  currencyOptions,
  control,
  setValue,
  errors,
  register
}: Props) => {
  const [valueType, setValueType] = useState<string>(outcome?.data?.discountValue?.type ?? "")
  const [fixedDiscounts, setFixedDiscounts] = useState<Common.Money[]>(() => {
    return outcome?.data?.subOutcomes?.[index].discountValue?.fixedDiscounts ?? []
  })

  const updateValueType = (valueType: Common.Option) => {
    setValue(
      `subOutcomes.${index}.discountValue.type` as "discountValue.type",
      valueType.value as InputDiscountValueType
    )
    if (valueType.value === InputDiscountValueType.Relative) {
      setValue(
        `subOutcomes.${index}.discountValue.fixedDiscounts` as "discountValue.fixedDiscounts",
        []
      )
    } else if (valueType.value === InputDiscountValueType.Fixed) {
      setValue(`subOutcomes.${index}.discountValue.percentage` as "discountValue.percentage", null)
    }
    setValueType(valueType.value)
  }

  const getOption = (type: string) => {
    switch (type) {
      case "RELATIVE":
        return {
          value: InputDiscountValueType.Relative,
          label: "Percentage %"
        }
      case "FIXED":
        return {
          value: InputDiscountValueType.Fixed,
          label: "Fixed amount"
        }
      default:
        return {
          label: "Select discount value type",
          value: ""
        }
    }
  }

  useEffect(() => {
    setValue(`subOutcomes.${index}.discountValue.fixedDiscounts`, fixedDiscounts)
  }, [fixedDiscounts])

  return (
    <SuboutcomeWrapper>
      <Controller
        name={`subOutcomes.${index}.triggerQuantity`}
        render={({ field }) => (
          <Input
            {...field}
            type="number"
            label="Trigger quantity *"
            description="Quantity that is used as a trigger"
            errors={errors}
            hooksErrorMessage={true}
          />
        )}
        defaultValue={outcome?.data?.subOutcomes?.[index].triggerQuantity ?? undefined}
        control={control}
        rules={{
          required: "This is a required field",
          pattern: {
            value: /^\d+$/,
            message: "Only integers 0 - 9 is allowed"
          }
        }}
      />
      <Controller
        name={`subOutcomes.${index}.discountQuantity`}
        render={({ field }) => (
          <>
            <Input
              {...field}
              name={`subOutcomes[${index}].discountQuantity`}
              type="number"
              label="Discount quantity *"
              description={"Quantity that will be discounted."}
              errors={errors}
              hooksErrorMessage={true}
            />
          </>
        )}
        defaultValue={outcome?.data?.subOutcomes?.[index]?.discountQuantity ?? undefined}
        control={control}
        rules={{
          required: "This is a required field",
          pattern: {
            value: /^\d+$/,
            message: "Only integers 0 - 9 is allowed"
          },
          validate: (value, formValues) =>
            Number(formValues?.subOutcomes?.[index]?.triggerQuantity ?? 0) >= Number(value ?? 0) ||
            "Discount quantity cannot be bigger than trigger quantity."
        }}
      />
      <Controller
        name={`subOutcomes.${index}.discountValue.type`}
        render={({ field }) => (
          <SingleSelect
            {...field}
            options={[getOption("FIXED"), getOption("RELATIVE")]}
            label="Discount output"
            setValue={updateValueType}
            defaultValue={
              (outcome?.data?.subOutcomes?.[index]?.discountValue?.type as string) !== undefined
                ? getOption(
                    (outcome?.data?.subOutcomes?.[index]?.discountValue as DiscountValue).type
                  )
                : getOption("")
            }
          />
        )}
        control={control}
      />
      <>
        {valueType === InputDiscountValueType.Relative && (
          <Percentage>
            <Controller
              name={`subOutcomes.${index}.discountValue.percentage`}
              render={({ field }) => (
                <Input
                  {...field}
                  name={`subOutcomes.${index}.discountValue.percentage`}
                  type="number"
                  placeholder="Percentage, 1 - 100"
                  label="Percentage value *"
                  errors={errors}
                />
              )}
              defaultValue={
                outcome?.data?.subOutcomes?.[index]?.discountValue?.percentage ?? undefined
              }
              control={control}
              rules={{
                required: "This is a required field",
                pattern: {
                  value: /^\d+$/,
                  message: "Only integers 0 - 9 is allowed"
                },
                min: {
                  value: 1,
                  message: "Min value is 1"
                },
                max: { value: 100, message: "Max value is 100" }
              }}
            />
          </Percentage>
        )}

        {valueType === InputDiscountValueType.Fixed && (
          <FixedDiscounts
            {...register(`subOutcomes.${index}.discountValue.fixedDiscounts`)}
            fixedDiscounts={fixedDiscounts}
            setFixedDiscounts={setFixedDiscounts}
            currencyOptions={currencyOptions}
          />
        )}
      </>
    </SuboutcomeWrapper>
  )
}
