import React from "react"

import {
  Container,
  Flex,
  NumberOfHits,
  ProductSearch,
  SearchWrapper,
  SelectWrapper,
  Show
} from "./ProductPreviewFilter.styled"
import { Common } from "@lib/types"

type Props = {
  searchQuery: string
  setSearchQuery: (input: string) => void
  loading: boolean
  productFilter: Common.ProductPreviewFilter
  numberOfHitsOptions?: string[]
  setNumberOfHits?: (hits: string) => void
  selectedNumberOfHits?: string
  expanded: boolean
}

const FilterSection = ({
  setSearchQuery,
  searchQuery,
  numberOfHitsOptions,
  setNumberOfHits,
  selectedNumberOfHits,
  loading,
  expanded
}: Props) => {

  const searchOptions = {
    handleChange: (input: string) => {
      setSearchQuery(input)
    },
    loading: loading,
    defaultValue: searchQuery
  }

  if(!expanded) return null

  return (
    <Container>
      <Flex>
        <SearchWrapper>
          {searchOptions && (
            <ProductSearch
              handleOnChange={searchOptions.handleChange}
              loading={searchOptions.loading}
              defaultValue={searchOptions.defaultValue}
              padding="1rem 2rem 2rem 2rem"
              placeholder="Type to search..."
            />
          )}
        </SearchWrapper>
        <SelectWrapper>
          {numberOfHitsOptions && setNumberOfHits && selectedNumberOfHits && (
            <Show>
              <p>Show:</p>
              {numberOfHitsOptions.map((hits) => (
                <NumberOfHits
                  key={hits}
                  onClick={() => setNumberOfHits(hits)}
                  $active={hits === selectedNumberOfHits}
                >
                  {hits}
                </NumberOfHits>
              ))}
            </Show>
          )}
        </SelectWrapper>
      </Flex>
    </Container>
  )
}

export default FilterSection
