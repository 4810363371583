import React from "react"
import { useQuery } from "@apollo/client"
import { Provider } from "@lib/types/generated/graphql-types"
import { useNavigate } from "react-router-dom"
import { PROVIDER_TYPE } from "lib/types/common"

import ALL_PAYMENT_PROVIDERS from "graphql/queries/config/AllPaymentProviders"
import ALL_SHIPPING_PROVIDERS from "graphql/queries/config/AllShippingProviders"
import ALL_GIFT_CARD_PROVIDERS from "graphql/queries/config/AllGiftCardProviders"
import GET_VOYADO_CONFIGS from "graphql/queries/config/Providers/Voyado/GetVoyadoConfigs"
import { ALL_BONUS_PROVIDERS } from "graphql/queries/config/AllBonusProviders"

import { Wrapper, Container, Label, Logos, Logo } from "./Providers.styled"
import { Loader } from "components/Ui/Loader"

import { ReactComponent as KlarnaLogo } from "images/providers/klarna-logo.svg"
import { ReactComponent as AdyenLogo } from "images/providers/adyen-vector-logo.svg"
import { ReactComponent as SveaLogo } from "images/providers/svea-logo.svg"
import { ReactComponent as IngridLogo } from "images/providers/ingrid-logo.svg"
import { ReactComponent as Retain24Logo } from "images/providers/retain24-logo.svg"
import { ReactComponent as NShiftLogo } from "images/providers/nshift-logo.svg"
import { ReactComponent as WalleyLogo } from "images/providers/walley-logo.svg"
import { ReactComponent as VoyadoLogo } from "images/providers/voyado-logo.svg"
import { ReactComponent as QliroLogo } from "images/providers/qliro-logo.svg"

export const Providers = () => {
  const { data: paymebtData, loading: paymentLoading } = useQuery(ALL_PAYMENT_PROVIDERS)
  const { data: shippingData, loading: shippingLoading } = useQuery(ALL_SHIPPING_PROVIDERS)
  const { data: giftCardData, loading: giftCardLoading } = useQuery(ALL_GIFT_CARD_PROVIDERS)
  const { data: voyadoData, loading: voyadoLoading } = useQuery(GET_VOYADO_CONFIGS)
  const { data: bonusData, loading: bonusLoading } = useQuery(ALL_BONUS_PROVIDERS)

  const paymentProviders = paymebtData?.getPaymentProviders
  const shippingProviders = shippingData?.getShippingProviders
  const giftCardProviders = giftCardData?.getGiftCardProviders
  const voyadoConfigs = voyadoData?.getVoyadoConfigs
  const bonusConfigs = bonusData?.getBonusConfigs

  const navigate = useNavigate()

  const paymentProviderExists = (type: string) => {
    return paymentProviders?.find((p: Provider) => p.type === type)
  }

  const shippingProviderExists = (type: string) => {
    return shippingProviders?.find((p: Provider) => p.type === type)
  }

  const giftCardProviderExists = (type: string) => {
    return giftCardProviders?.find((p: Provider) => p.type === type)
  }

  return (
    <>
      <Wrapper>
        <Container>
          <Label>Connected shipping providers</Label>
          {shippingLoading ? (
            <Loader />
          ) : (
            <>
              {shippingProviders?.length < 1 && <p>No shipping providers configured</p>}
              <Logos>
                {shippingProviderExists(PROVIDER_TYPE.INGRID) && (
                  <Logo onClick={() => navigate("/settings/providers/ingrid")}>
                    <IngridLogo />
                  </Logo>
                )}
                {shippingProviderExists(PROVIDER_TYPE.NSHIFT) && (
                  <Logo onClick={() => navigate("/settings/providers/Nshift")}>
                    <NShiftLogo />
                  </Logo>
                )}
              </Logos>
            </>
          )}
        </Container>

        <Container>
          <Label>Connected payment providers</Label>
          {paymentLoading ? (
            <Loader />
          ) : (
            <>
              {paymentProviders?.length < 1 && <p>No payment providers configured</p>}
              <Logos>
                {paymentProviderExists(PROVIDER_TYPE.KLARNA_CHECKOUT) && (
                  <Logo onClick={() => navigate("/settings/providers/klarna")}>
                    <KlarnaLogo />
                  </Logo>
                )}
                {paymentProviderExists(PROVIDER_TYPE.ADYEN) && (
                  <Logo onClick={() => navigate("/settings/providers/adyen")}>
                    <AdyenLogo />
                  </Logo>
                )}
                {paymentProviderExists(PROVIDER_TYPE.SVEA_CHECKOUT) && (
                  <Logo onClick={() => navigate("/settings/providers/svea")}>
                    <SveaLogo />
                  </Logo>
                )}
                {paymentProviderExists(PROVIDER_TYPE.WALLEY_CHECKOUT) && (
                  <Logo onClick={() => navigate("/settings/providers/walley")}>
                    <WalleyLogo />
                  </Logo>
                )}
                {paymentProviderExists(PROVIDER_TYPE.QLIRO_ONE) && (
                  <Logo onClick={() => navigate("/settings/providers/qliro")}>
                    <QliroLogo />
                  </Logo>
                )}
              </Logos>
            </>
          )}
        </Container>

        <Container>
          <Label>Connected gift card providers</Label>
          {giftCardLoading ? (
            <Loader />
          ) : (
            <>
              {giftCardProviders?.length < 1 && <p>No gift card providers configured</p>}
              <Logos>
                {giftCardProviderExists(PROVIDER_TYPE.RETAIN24) && (
                  <Logo onClick={() => navigate("/settings/providers/retain24")}>
                    <Retain24Logo />
                  </Logo>
                )}
                {giftCardProviderExists(PROVIDER_TYPE.BSG_GIFT_CARD) && <Logo>Custom</Logo>}
                {giftCardProviderExists(PROVIDER_TYPE.KBS_GIFT_CARD) && <Logo>Custom</Logo>}
              </Logos>
            </>
          )}
        </Container>
        <Container>
          <Label>Other connected providers</Label>
          {voyadoLoading || bonusLoading ? (
            <Loader />
          ) : (
            <>
              {voyadoConfigs?.length < 1 && bonusConfigs?.length < 1 && (
                <p>No other providers configured</p>
              )}
              <Logos>
                {voyadoConfigs?.length > 0 && (
                  <Logo onClick={() => navigate("/settings/providers/voyado")}>
                    <VoyadoLogo />
                  </Logo>
                )}
                {bonusConfigs?.length > 0 && (
                  <Logo onClick={() => navigate("/settings/providers/bonus")}>Custom</Logo>
                )}
              </Logos>
            </>
          )}
        </Container>
      </Wrapper>
    </>
  )
}

export default Providers
