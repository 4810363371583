import React from "react"
import styled, { keyframes } from "styled-components/macro"

const styledKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`

const LoaderComponent = styled.div`
  position: relative;
  width: 2rem;
  max-width: 2em;
  height: 2rem;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid ${(p) => p.color || p.theme.colors.white};
    border-radius: 50%;
    animation: ${styledKeyframes} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p) => p.color || p.theme.colors.white} transparent
      transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`

type Props = {
  color?: string
}

const Loader = ({ color, ...props }: Props) => (
  <Container {...props}>
    <LoaderComponent color={color}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderComponent>
  </Container>
)

export { Loader }
