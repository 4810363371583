import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { StoreGroup } from "lib/types/generated/graphql-types"
import { Common } from "lib/types"
import { useTypedSelector } from "lib/store"
import { selectCart, selectCheckoutToken } from "lib/store/services/brink/selectors"
import { useDispatch } from "react-redux"
import { reset } from "lib/store/services/brink/actions"
import { useConfig } from "configuration/useConfig"
import { useNavigate } from "react-router-dom"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import { Container, Select, StyledPageHeader } from "./CreateOrder.styled"

import Box from "components/Ui/Box"
import { StartSession } from "components/Order/CreateOrder/StartSession/StartSession"
import { Cart } from "components/Order/CreateOrder/Cart/Cart"
import { AddVariant } from "components/Order/CreateOrder/AddVariant/AddVariant"
import Popup from "components/Ui/Popup"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { RadioButton } from "components/Ui/Form/RadioButton"

import { ReactComponent as CheckIcon } from "images/icons/check.svg"
import { ReactComponent as CopyIcon } from "images/icons/copy.svg"

const CHECKOUT_URL = "https://checkout.brinkcommerce.com"

export const CreateOrder = () => {
  const [storeGroups, setStoreGroups] = useState<StoreGroup[]>([])
  const [showPopup, setShowPoup] = useState<boolean>(false)
  const [selectedStoreGroup, setSelectedStoreGroup] = useState<StoreGroup>()
  const [selectedStoreMarket, setSelectedStoreMarket] = useState<string>()
  const [termsUrl, setTermsUrl] = useState<string>("")
  const [copyActive, setCopyActive] = useState(false)
  const cart = useTypedSelector(selectCart)
  const checkoutToken = useTypedSelector(selectCheckoutToken)
  const dispatch = useDispatch()
  const { customerName } = useConfig().config
  const navigate = useNavigate()

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => setStoreGroups(data.getStoreGroups)
  })

  const updateSelectedStoreGroup = (storeGroupOption: Common.Option) => {
    const storeGroup = storeGroups.find((storeGroup) => storeGroup.id === storeGroupOption?.value)
    setSelectedStoreGroup(storeGroup)
  }
  const updateSelectedStoreMarket = (storeMarketOption: Common.Option) => {
    setSelectedStoreMarket(storeMarketOption?.value)
  }

  const closePopup = () => {
    resetCart()
    setTermsUrl("")
    setShowPoup(false)
  }

  const resetCart = () => dispatch(reset())

  useEffect(() => {
    if (checkoutToken) {
      setShowPoup(true)
    }
  }, [checkoutToken])

  return (
    <Box padding="0" margin="0">
      <StyledPageHeader
        title="Create order"
        description={
          <>
            Create a cart with store group, store market and products variants. In the next step you
            will get an checkout URL for shipping options and payment.
            <br />
            You can edit the new order once you have created a new draft.
          </>
        }
      ></StyledPageHeader>
      {showPopup ? (
        <Popup
          title={"Brink checkout created"}
          subtitle={"Copy checkout url with button below and paste in browser:"}
          handleCloseClick={closePopup}
          hideOkButton={true}
        >
          {copyActive ? (
            <PrimaryButton>
              <CheckIcon /> Copied
            </PrimaryButton>
          ) : (
            <PrimaryButton
              handleClick={() => {
                setCopyActive(true)
                setTimeout(() => setCopyActive(false), 1000)
                navigator.clipboard.writeText(
                  `${CHECKOUT_URL}/?merchant=${customerName}&token=${checkoutToken}&terms=${encodeURIComponent(
                    termsUrl
                  )}`
                )
              }}
            >
              <CopyIcon />
              Copy checkout url
            </PrimaryButton>
          )}
        </Popup>
      ) : (
        <></>
      )}
      <Select>
        <RadioButton name="mode" label="Create new order" id="new" checked onChange={() => null} />
        <RadioButton
          name="mode"
          label="Create order from existing order"
          id="clone"
          checked={false}
          onChange={() => {
            resetCart()
            navigate(`/orders/create-order-from-existing-order`)
          }}
        />
      </Select>
      <Container>
        <div>
          {cart ? (
            <AddVariant resetCart={resetCart} showGoBackButton />
          ) : (
            <StartSession
              storeGroups={storeGroups}
              updateSelectedStoreGroup={updateSelectedStoreGroup}
              selectedStoreGroup={selectedStoreGroup}
              updateSelectedStoreMarket={updateSelectedStoreMarket}
              selectedStoreMarket={selectedStoreMarket}
            />
          )}
        </div>
        <div>
          <Cart
            setTermsUrl={setTermsUrl}
            termsUrl={termsUrl}
            storeGroups={storeGroups}
            checkoutUrl={CHECKOUT_URL}
          />
        </div>
      </Container>
    </Box>
  )
}
