import React, { useState } from "react"

import { MarketPrice } from "@lib/types/generated/graphql-types"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "images/icons/chevron-up.svg"
import { ReactComponent as HistoricalIcon } from "images/icons/clock-rotate-left.svg"
import FlagIcon from "../../Ui/FlagIcon"
import { getCountryName } from "../../../helpers/countries"
import dayjs from "dayjs"
import Money from "../../Money"
import { v4 as uuidv4 } from "uuid"
import DineroFactory from "dinero.js"
import {
  Campaign,
  HistoricalPricesContainer,
  HistoricalPricesInfo,
  MarketPriceHeader, Row,
  TableData,
  Time
} from "./HistoricalPrices.styled"
import { TableHeader } from "./ShowPrices.styled"
import { useNavigate } from "react-router-dom"

type Props = {
  marketPrices: MarketPrice[] | undefined
}

const HistoricalPrices = ({ marketPrices }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const sortedMarketPrice = [...(marketPrices ?? [])]?.sort((a, b) => {
    const nameA = getCountryName(a?.countryCode) ?? ""
    const nameB = getCountryName(b?.countryCode) ?? ""
    return nameA.localeCompare(nameB)
  })

  const navigate = useNavigate()

  const historicalData = marketPrices
    ?.map((marketPrice) => marketPrice.price.priceRecords.length)
    .reduce((partialSum, a) => partialSum + a, 0) !== 0

  if(!historicalData) return null

  return (
    <HistoricalPricesContainer>
      <span onClick={() => setExpanded(!expanded)}>
        <div>
          <HistoricalIcon /> <h3>Historical prices - last 30 days</h3>
        </div>
        {expanded ? <UpIcon /> : <DownIcon />}
      </span>
      {expanded &&
        sortedMarketPrice?.map((marketPrice) => {
          if (marketPrice.price.priceRecords.length === 0) return null
          const reverseList = [...marketPrice.price.priceRecords].reverse()
          return (
            <HistoricalPricesInfo key={marketPrice.countryCode}>
              <MarketPriceHeader>
                <FlagIcon countryCode={marketPrice.countryCode} />
                <h4>{getCountryName(marketPrice.countryCode)}</h4>
              </MarketPriceHeader>
              <Row>
                <TableHeader>Period</TableHeader>
                <TableHeader className="hideOnSmallScreens">Base price</TableHeader>
                <TableHeader>Sale price</TableHeader>
                <TableHeader className="hideOnSmallScreens">Campaign</TableHeader>
              </Row>
                {reverseList.map((priceRecord, index) => (
                    <Row key={uuidv4()}>
                      <TableData $row={index % 2 === 0}>
                        <HistoricalIcon className="hideOnSmallScreens" />
                        <Time>
                          <div>{priceRecord.startDate === "0001-01-01T00:00:00Z" ? "Initial": dayjs(priceRecord.startDate).format("YYYY-MM-DD HH:mm")}</div>
                          <span className="hideOnSmallScreens">-</span>
                          <div>{dayjs(priceRecord.endDate).format("YYYY-MM-DD HH:mm")} </div>
                        </Time>
                      </TableData>
                      <TableData $row={index % 2 === 0} className="hideOnSmallScreens">
                        <Money
                          key={marketPrice.countryCode}
                          amount={priceRecord.basePriceAmount}
                          currencyUnit={marketPrice.price.currencyCode as DineroFactory.Currency}
                        />
                      </TableData>
                      <TableData $row={index % 2 === 0}>
                        <Money
                          key={marketPrice.countryCode}
                          amount={priceRecord.salePriceAmount}
                          currencyUnit={marketPrice.price.currencyCode as DineroFactory.Currency}
                        />
                      </TableData>
                      <TableData $row={index % 2 === 0} className="hideOnSmallScreens">
                        {priceRecord?.campaign ? <Campaign onClick={() => navigate(`/campaigns/campaign/${priceRecord?.campaign?.campaignId}`)}>{priceRecord?.campaign?.name}</Campaign>: <div>-</div>}
                      </TableData>
                    </Row>
                  ))
                }
            </HistoricalPricesInfo>
          )
        })}
    </HistoricalPricesContainer>
  )
}

export default HistoricalPrices
