import gql from "graphql-tag"

export const VALIDATE_WALLEY = gql`
  query validateWalley($input: InputWalleyCheckoutConfig!) {
    Configuration {
      validate {
        walleyCheckout(input: $input) {
          errorMessage
          valid
        }
      }
    }
  }
`
