import styled from "styled-components/macro"

import { Progress } from "./Progress"
import TableRow from "../Ui/Table/TableRow"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  width: 100%;
  cursor: auto !important;
`

export const CampaignProgress = styled(Progress)`
  width: 100%;
  margin-right: 1.5rem;
  background: ${(p) => p.theme.colors.white};
`

export const Date = styled.div`
  max-width: 20rem;
  display: none !important;
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex !important;
  }
  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    display: none !important;
  }
`

export const Created = styled.div`
  display: none !important;
  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    display: flex !important;
  }
`

export const Name = styled.p`
  ${(p) => p.theme.bold};
  margin: 0;
`

export const Actions = styled.div`
  max-width: 7rem;
  display: flex;
  justify-content: center;

  button {
    margin-left: 0;
  }
`

export const StyledTableRow = styled(TableRow)`
  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
    min-height: 8rem;
  }
`
