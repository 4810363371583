import gql from "graphql-tag"

const UPDATE_CAMPAIGN_PERIOD = gql`
  mutation updateCampaignPeriod(
    $id: ID!
    $endDate: AWSDateTime!
  ) {
    updateCampaignPeriod(
      id: $id
      period: {
        endDate: $endDate
      }
    ) {
      id
      campaignGroupId
      name
    }
  }
`

export default UPDATE_CAMPAIGN_PERIOD
