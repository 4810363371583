import React from "react"
import * as graphql from "@lib/types/generated/graphql-types"
import { v4 as uuid } from "uuid"

import { Title } from "./OrderLines.styled"
import { Orderline } from "./OrderLine"

import { ReactComponent as OrderLineIcon } from "images/icons/cart-shopping.svg"
import { GiftLine } from "./GiftLine"
import { ReactComponent as GiftIcon } from "images/icons/gift.svg"
import { ReactComponent as CardIcon } from "images/icons/gift-card.svg"
import { GiftCardProductLine } from "./GiftCardProductLine"

type Props = {
  order: graphql.Order
  orderHistory: graphql.OrderHistory
}

export const OrderLines = ({ order, orderHistory }: Props) => {
  const { orderLines, gifts, GiftCardProduct } = order

  function isOrderGiftCardProductRetain24(
    object: graphql.OrderGiftCardProduct
  ): object is graphql.OrderGiftCardProductRetain24 {
    return object.providerName === "Retain24"
  }

  return (
    <>
      {orderLines && orderLines?.length > 0 && (
        <>
          <Title>
            <OrderLineIcon />
            Products
          </Title>

          {orderLines.map((orderLine: graphql.OrderLine) => (
            <Orderline key={uuid()} orderLine={orderLine} orderHistory={orderHistory} />
          ))}
        </>
      )}
      {gifts && gifts?.length > 0 && (
        <>
          <Title>
            <GiftIcon />
            Gifts
          </Title>

          {gifts &&
            gifts.map((gift: graphql.OrderGift) => (
              <GiftLine key={uuid()} gift={gift} orderHistory={orderHistory} />
            ))}
        </>
      )}
      {isOrderGiftCardProductRetain24(GiftCardProduct) && (
        <>
          <Title>
            <CardIcon />
            Gift cards
          </Title>
          {GiftCardProduct?.giftCardProducts?.map((product) => (
            <GiftCardProductLine key={product.id} giftCardProductLine={product} order={order} orderHistory={orderHistory}/>
          ))}
        </>
      )}
    </>
  )
}
