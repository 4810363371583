import React, { Dispatch, SetStateAction } from "react"
import { useMutation } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { LogicState, DiscountRuleInputs } from "@lib/types/discount"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import { DiscountCodeRule, DiscountCodeSearchHit } from "lib/types/generated/graphql-types"
import { transformLogicData } from "helpers/transformLogicData"
import { duplicateLogicRules } from "helpers/duplicateDiscount"

import CREATE_OR_UPDATE_DISCOUNT_CODE_RULE from "graphql/mutations/discount/CreateOrUpdateDiscountCodeRule"

import { EditPopup } from "components/Ui/EditPopup/EditPopup"
import Input from "components/Ui/Form/Input"

type Props = {
  discountCodeRuleData: DiscountCodeRule
  logicState: LogicState
  setDuplicateDiscountCodeRule: Dispatch<SetStateAction<boolean>>
  setDiscountCodes: Dispatch<SetStateAction<DiscountCodeSearchHit[]>>
}

const DuplicateDiscountCodeRule = ({
  discountCodeRuleData,
  logicState,
  setDuplicateDiscountCodeRule,
  setDiscountCodes
}: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const closeSidebar = () => {
    dispatch(hideEditSidebar())
    setDuplicateDiscountCodeRule(false)
  }

  const [addDiscountRule, { loading }] = useMutation(CREATE_OR_UPDATE_DISCOUNT_CODE_RULE, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: `New discount code rule created. ${discountCodeRuleData.name} successfully duplicated`,
          type: "success"
        })
      )
      navigate(`/discounts/discount-code-rules/${data.createOrUpdateDiscountCodeRule.id}`)
      setDiscountCodes([])
      closeSidebar()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })
  const onSubmit: SubmitHandler<DiscountRuleInputs> = (data) => {
    const { name } = data
    const transformedData = transformLogicData(duplicateLogicRules(logicState))
    addDiscountRule({
      variables: {
        id: uuidv4(),
        name,
        isActive: discountCodeRuleData.isActive ?? false,
        isStackable: discountCodeRuleData.isStackable ?? false,
        applyLast: discountCodeRuleData.applyLast ?? false,
        isExclusive: discountCodeRuleData.isExclusive ?? false,
        sortOrder: discountCodeRuleData.sortOrder,
        ...transformedData,
        ...(discountCodeRuleData.validDateRange &&
          discountCodeRuleData.validDateRange.from &&
          discountCodeRuleData.validDateRange.to && {
            validDateRange: {
              from: new Date(discountCodeRuleData.validDateRange.from),
              to: new Date(discountCodeRuleData.validDateRange.to)
            }
          })
      }
    })
  }

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<DiscountRuleInputs>()

  return (
    <EditPopup
      title="Duplicate discount code rule"
      buttonText="Duplicate"
      handleOkClick={handleSubmit(onSubmit)}
      handleCloseClick={() => closeSidebar()}
      loading={loading}
    >
      <form>
        <Controller
          name="name"
          render={({ field }) => (
            <Input label="Name" errors={errors} {...field} description={"A new name is required"} />
          )}
          defaultValue={`${discountCodeRuleData.name} Copy`}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
      </form>
    </EditPopup>
  )
}

export default DuplicateDiscountCodeRule
