import gql from "graphql-tag";

const DELETE_ADYEN_CONFIG = gql`
    mutation deleteInventory($id: ID!) {
        deleteAdyenConfig(id: $id) {
            status
        }
    }
`;

export default DELETE_ADYEN_CONFIG;
