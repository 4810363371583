import gql from "graphql-tag"

const DELETE_TAX_MARKET = gql`
  mutation deleteTaxMarket($taxGroupId: String!, $countryCode: String!) {
    deleteTaxMarket(taxGroupId: $taxGroupId, countryCode: $countryCode) {
      status
    }
  }
`

export default DELETE_TAX_MARKET
