import React, { useState } from "react"
import { OrderGift, OrderLine, OrderRelease, ShippingFee } from "lib/types/generated/graphql-types"

import dayjs from "dayjs"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import {
  Action,
  AdditionalData,
  BaseData,
  Bold,
  Container,
  Dates,
  Discount,
  ExpandIcon,
  Footer,
  Id,
  ImageContainer,
  Info,
  Label,
  NotCompleted,
  OrderLineWrapper,
  PaymentInfo,
  PriceWrapper,
  ProductImage,
  ProviderStatus, Reason,
  Row
} from "./Shared.styled"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import placeholder from "../../../images/placeholder.svg"
import { useQuery } from "@apollo/client"
import Status from "../../Ui/Status"
import LoadingRow from "./LoadingRow"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import GET_RELEASE from "../../../graphql/queries/order/GetRelease"

type Props = {
  releaseId: string
  currencyCode: string
  orderLines: OrderLine[]
  gifts: OrderGift[]
  shippingFees: ShippingFee[]
}
const Release = ({ currencyCode, orderLines, gifts, releaseId, shippingFees }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const getOrderLine = (id: string) => {
    return orderLines?.find((ol) => ol.id === id)
  }
  const getGiftLine = (id: string) => {
    return gifts?.find((og) => og.id === id)
  }
  const getShippingFee = (id: string) => {
    return shippingFees?.find((fee) => fee.id === id)
  }

  const { data, loading } = useQuery(GET_RELEASE, { variables: { releaseId: releaseId } })

  const release = data?.Order?.release as OrderRelease
  if (loading) return <LoadingRow />
  return (
    <Container>
      <BaseData onClick={() => setExpanded(!expanded)}>
        {!release.completed && <NotCompleted>Not completed</NotCompleted>}
        <h3>Release #{release.id}</h3>
        <Dates>
          <div>
            Started: <b>{dayjs(release.created).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          <div>
            Last updated: <b>{dayjs(release.updated).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          {release.completed && (
            <div>
              Completed: <b>{dayjs(release?.completed).format("YYYY-MM-DD : HH:mm:ss")}</b>
            </div>
          )}
          <div>
            Order lines: <b>{release.orderLines.length}</b>
          </div>
          {release.gifts.length > 0 && (
            <div>
              Gifts: <b>{release.gifts.length}</b>
            </div>
          )}
          {release?.restarted && (
            <>
              <div>
                Restarted: <b>{dayjs(release.restarted).format("YYYY-MM-DD : HH:mm:ss")}</b>
              </div>
              <div>
                Restarts: <b>{release.restarts}</b>
              </div>
            </>
          )}
        </Dates>
      </BaseData>
      {release.reason && (
        <Reason>
          Reason:{" "}
          <b>
            {release?.reason?.code}, {release?.reason?.cause}
          </b>
        </Reason>
      )}
      {expanded && (
        <AdditionalData>
          {release.orderLines.map((ol) => (
            <OrderLineWrapper key={ol.orderLineId}>
              <Info>
                <ImageContainer>
                  <ProductImage
                    src={getOrderLine(ol.orderLineId)?.imageUrl as string}
                    onError={handleImageError}
                  />
                </ImageContainer>
                <div>
                  <Bold>{getOrderLine(ol.orderLineId)?.name}</Bold>
                  <PriceWrapper>
                    <Bold>
                      {" "}
                      <Money
                        amount={parseInt(`${ol.totalAmount}`)}
                        currencyUnit={currencyCode as DineroFactory.Currency}
                      />
                    </Bold>
                    {ol?.totalDiscountAmount && (
                      <Discount>
                        <Money
                          amount={parseInt(`${ol.totalDiscountAmount + ol.totalAmount}`)}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </Discount>
                    )}
                  </PriceWrapper>
                  <div>Order quantity: {getOrderLine(ol.orderLineId)?.quantity}</div>
                  <Id>{getOrderLine(ol.orderLineId)?.productVariantId}</Id>
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>
                  Was released
                </span>
                <span>
                  <b>Items released:</b> {ol.quantity}
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {release.gifts.map((gift) => (
            <OrderLineWrapper key={gift.giftId}>
              <Info>
                <ImageContainer>
                  <ProductImage
                    src={getGiftLine(gift.giftId)?.imageUrl as string}
                    onError={handleImageError}
                  />
                </ImageContainer>
                <div>
                  <Bold>Gift: {getGiftLine(gift.giftId)?.displayName}</Bold>
                  <div>Order quantity: {getGiftLine(gift.giftId)?.quantity}</div>
                  <Id>{getGiftLine(gift.giftId)?.productVariantId}</Id>
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>
                  Was released
                </span>
                <span>
                  <b>Items released:</b> {gift.quantity}
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {release?.shippingFees?.map((fee) => (
            <OrderLineWrapper key={fee.shippingFeeId}>
              <Info>
                <span>
                  <ShippingIcon />
                </span>
                <div>
                  <Bold>Shipping: {getShippingFee(fee.shippingFeeId)?.displayName}</Bold>
                  <Money
                    amount={parseInt(`${fee.totalAmount}`)}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>Was released
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          <ProviderStatus>
            <div>
              <Label>Payment status:</Label>{" "}
              <Status status={release.paymentProvider.status?.current ?? ""} />
            </div>
            {release?.shippingProvider?.status?.current && (
              <div>
                <Label>Shipping status:</Label>
                <Status status={release?.shippingProvider?.status?.current ?? ""} />
              </div>
            )}
            {release?.bonusProvider?.status?.current  && (
              <div>
                <Label>Bonus status:</Label>
                <Status status={release?.bonusProvider?.status?.current ?? ""} />
              </div>
            )}
            {release?.giftCardProvider?.status?.current && (
              <div>
                <Label>Gift card status:</Label>
                <Status status={release?.giftCardProvider?.status?.current ?? ""} />
              </div>
            )}
          </ProviderStatus>
          <PaymentInfo>
            {release?.releasedPayment && (
              <div>
                Payment reference: <b>{release.releasedPayment?.reference}</b>
              </div>
            )}
            {release?.bonus && (
              <div>
                Bonus reservation id: <b>{release.bonus?.reservationId}</b>
              </div>
            )}
            {release?.giftCards.length > 0 && (
              <>
                {`Gift card${release?.giftCards.length > 1 ? "s" : ""}:`}
                {release.giftCards.map((giftCard) => (
                  <Row key={giftCard.giftCardId}>
                    <span>
                      Id: <b>{giftCard.giftCardId}</b>
                    </span>
                    <span>
                      Amount:{" "}
                      <b>
                        <Money
                          amount={giftCard.amount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </b>
                    </span>
                    <span>
                      Status: <Status status={giftCard.status} />
                    </span>
                  </Row>
                ))}
              </>
            )}
          </PaymentInfo>
        </AdditionalData>
      )}
      <Footer></Footer>
      <ExpandIcon $expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}

export default Release
