import gql from "graphql-tag"

const GET_GENERATE_CODES = gql`
  query getGenerateCodes($jobId: String!) {
    getGenerateCodes(jobId: $jobId) {
      jobId
      discountCodeRuleId
      prefix
      usageLimit
      numberOfCodesRequested
      numberOfCodesGenerated
      status
      fileUrl
      created
      updated
    }
  }
`

export default GET_GENERATE_CODES
