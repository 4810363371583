import gql from "graphql-tag"

const GET_INGRID_CONFIG = gql`
    query getIngridConfig($id: ID!) {
        getIngridConfig(id: $id) {
            id
            created
            updated
            baseUrl
            siteId
        }
    }
`

export default GET_INGRID_CONFIG
