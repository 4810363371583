import styled from "styled-components/macro"

import QuickAdd from "components/Ui/Table/QuickAdd"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import TableRowSimple from "components/Ui/Table/TableRowSimple"
import TableHeader from "components/Ui/Table/TableHeader"
import SingleSelect from "components/Ui/Form/SingleSelect"

export const Container = styled.div`
  margin-right: 0;
  padding-bottom: 1rem;

  > div {
    display: block;
  }
`

export const Add = styled(QuickAdd)`
  background: transparent;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
`

export const AddButton = styled(PrimaryButton)`
  margin: -3rem 0 0 auto;
`

export const FixedDiscount = styled.div`
  width: 100%;

  p {
    text-align: center;
  }
`

export const FixedDiscountHeader = styled(TableHeader)`
  padding: 0 6rem 0 2rem;
`

export const FixedDiscountRow = styled(TableRowSimple)`
  background: rgba(255, 255, 255, 0.5);
  padding: 1.2rem 2rem 1rem;
  margin-bottom: 0;
`

export const FixedDiscountCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  font-size: 1.1rem;
  line-height: 1.7rem;
  padding-right: 1rem;

  span {
    line-height: 1.8rem;
    font-size: 1.4rem;
    ${(p) => p.theme.bold}
  }
`

export const Currency = styled(FixedDiscountCell)`
  width: 50%;
  flex: auto;
`

export const Amount = styled(FixedDiscountCell)`
  flex: auto;
  width: calc(50% - 8rem);
`

export const CurrencySelect = styled(SingleSelect)`
  margin-bottom: 1rem;
`

export const Formatted = styled.div`
  margin: 1rem 0;
`
