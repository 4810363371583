import styled from "styled-components/macro"
import TableRow from "../../Ui/Table/TableRow"

export const StoreMarketsContainer = styled.div`
  margin: 2rem 0;
`

export const StyledTableRow = styled(TableRow)`
  margin: 0.5rem 0;
  max-height: 5rem;
  display: flex;
  justify-content: space-between;

  button {
    position: absolute;
    right: 1rem;
    height: 3rem;
    width: 3rem;
  }
  span {
    position: absolute;
    right: 1rem;
  }
  &:hover {
    background: ${p => p.theme.colors.white};
    cursor: initial;
  }
`

export const BlurDiv = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);

  &:hover {
    cursor: default;
  }
`

export const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`
