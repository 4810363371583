import styled from "styled-components/macro"

export const Header = styled.div`
  margin-bottom: 5rem;
`

export const Title = styled.h2`
  margin: 0 0 1rem;
  font-size: 2.4rem;
  display: flex;
`

export const TableRow = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem;
  margin: 0 0 2rem 0;
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 5rem;
  text-align: center;

  h3 {
    margin-bottom: 0;
  }
`
