import gql from "graphql-tag"

const DELETE_REFUND = gql`
  mutation deleteRefund($refundId: ID!) {
    Order {
      deleteRefund(refundId: $refundId) {
        status
      }
    }
  }
`

export default DELETE_REFUND
