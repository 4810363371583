import React, { Dispatch, SetStateAction } from "react"
import {
  InputActionBonus,
  InputActionGiftCard,
  InputActionPaymentRelease,
  InputActionType,
  OrderRelease
} from "lib/types/generated/graphql-types"

import { ActionDropdownButton } from "../../../../Ui/Buttons/ActionDropdownButton"
import { ReactComponent as GiftCardIcon } from "../../../../../images/icons/gift.svg"
import { ReactComponent as BonusIcon } from "../../../../../images/icons/hand-holding-dollar.svg"
import { ReactComponent as PaymentIcon } from "../../../../../images/icons/credit-card.svg"
import Input from "../../../../Ui/Form/Input"
import { useMutation } from "@apollo/client"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { handleOrderManagementError } from "../../Helpers/errors"
import START_RELEASE from "../../../../../graphql/mutations/order/releases/StartRelease"
import {
  ActionContainer,
  ActionWrapper, ButtonWrapper,
  Container,
  GiftCardInputWrapper,
  InfoWrapper,
  InputContainer,
  LabelWrapper,
  StyledActionDropdownButton
} from "./ReleaseAction.styled"

type ActionState = {
  giftCardAction: InputActionGiftCard
  setGiftCardAction: Dispatch<SetStateAction<InputActionGiftCard>>
  bonusAction: InputActionBonus
  setBonusAction: Dispatch<SetStateAction<InputActionBonus>>
  paymentAction: InputActionPaymentRelease
  setPaymentAction: Dispatch<SetStateAction<InputActionPaymentRelease>>
}

type Props = {
  release: OrderRelease
  actionState: ActionState
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  orderId: string
  setStartedRelease: Dispatch<SetStateAction<string>>
  setShowConfirmation: Dispatch<SetStateAction<boolean>>
}

const ReleaseAction = ({
  actionState,
  release,
  setShowStatusPopup,
  setStartedRelease,
  setShowConfirmation
}: Props) => {
  const {
    giftCardAction,
    setGiftCardAction,
    bonusAction,
    setBonusAction,
    paymentAction,
    setPaymentAction
  } = actionState

  const giftCards = release.giftCards ?? []

  const [startRelease, { loading }] = useMutation(START_RELEASE, {
    onCompleted: () => {
      setStartedRelease(release.id)
      setShowStatusPopup(true)
      setShowConfirmation(false)
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const startOrderRelease = () => {
    startRelease({
      variables: {
        releaseId: release.id,
        input: {
          ...(release?.bonus && { bonus: bonusAction }),
          ...(giftCards.length > 0 && { giftCard: giftCardAction }),
          payment: paymentAction
        }
      }
    })
  }

  const updateGiftCardActionCards = (id: string, value: string) => {
    const idExists = giftCardAction?.giftCards?.find((giftCard) => giftCard.giftCardId === id)
    if (idExists) {
      return setGiftCardAction({
        ...giftCardAction,
        giftCards: giftCardAction?.giftCards?.map((giftCard) =>
          giftCard.giftCardId === id
            ? {
                giftCardId: id,
                transactionId: value
              }
            : giftCard
        )
      })
    }
    return setGiftCardAction({
      ...giftCardAction,
      giftCards: [...(giftCardAction.giftCards ?? []), { giftCardId: id, transactionId: value }]
    })
  }

  const updatePaymentReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, reference: reference })
  }

  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <>
      {" "}
      <h2>Release actions</h2>
      <Container>
        <InfoWrapper>
          <b>Auto</b>{" "}
          <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
          <b>Manual</b>{" "}
          <p>
            The action has been performed in an external system and will be stored as manually
            completed.
          </p>
          <b>Skip</b> <p>The action will be skipped entirely.</p>
        </InfoWrapper>
        <div>
          {giftCards.length > 0 && (
            <div>
              <ActionContainer>
                <ActionWrapper>
                  <LabelWrapper>
                    <GiftCardIcon />
                    <b>GiftCard - {release?.giftCardProvider?.providerName}</b>
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(giftCardAction.actionType)}>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
                {giftCardAction.actionType === InputActionType.Manual && (
                  <>
                    {giftCards.map((giftCard) => {
                      return (
                        <GiftCardInputWrapper key={giftCard.giftCardId}>
                          <p>{giftCard.giftCardId}</p>
                          <InputContainer>
                            <Input
                              removePaddingBottom={true}
                              name="transactionId"
                              placeholder="Transaction id"
                              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                updateGiftCardActionCards(
                                  giftCard.giftCardId,
                                  e.currentTarget.value
                                )
                              }
                            />
                          </InputContainer>
                        </GiftCardInputWrapper>
                      )
                    })}
                  </>
                )}
              </ActionContainer>
            </div>
          )}
          {release?.bonus && (
            <div>
              <ActionContainer>
                <ActionWrapper>
                  <LabelWrapper>
                    <BonusIcon />
                    <b>Bonus - {release?.bonusProvider?.providerName}</b>{" "}
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(bonusAction.actionType)}>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setBonusAction({ actionType: InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
              </ActionContainer>
            </div>
          )}
            <ActionContainer>
              <ActionWrapper>
                <LabelWrapper>
                  <PaymentIcon />
                  <b>Payment - {release?.paymentProvider?.providerName}</b>
                </LabelWrapper>
                <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
                  <li onClick={() => setPaymentAction({ actionType: InputActionType.Auto })}>
                    Auto
                  </li>
                  <li onClick={() => setPaymentAction({ actionType: InputActionType.Manual })}>
                    Manual
                  </li>
                  <li onClick={() => setPaymentAction({ actionType: InputActionType.Skip })}>
                    Skip
                  </li>
                </StyledActionDropdownButton>
              </ActionWrapper>
              {paymentAction.actionType === InputActionType.Manual && (
                <InputContainer>
                  <Input
                    name="captureReference"
                    placeholder="Capture reference"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updatePaymentReference(e.currentTarget.value)
                    }
                  />
                </InputContainer>
              )}
            </ActionContainer>
        </div>
        <ButtonWrapper>
          <SecondaryButton handleClick={() => setShowConfirmation(false)}>
            Back to draft
          </SecondaryButton>
          <PrimaryButton handleClick={startOrderRelease} loading={loading}>
            Finalize Release
          </PrimaryButton>
        </ButtonWrapper>
      </Container>
    </>
  )
}

export default ReleaseAction
