import {
  FrontendCondition,
  FrontendLogicItem,
  FrontendOutcome,
  LogicRule,
  LogicState
} from "@lib/types/discount"
import { v4 as uuidv4 } from "uuid"

export const isCondition = (item: FrontendLogicItem) => {
  return item?.type === "condition"
}

export const isOutcome = (item: FrontendLogicItem) => {
  return item?.type === "outcome"
}

export const generateNewId = (item: FrontendLogicItem) => {
  if (!item) return item

  return {
    ...item,
    id: uuidv4()
  }
}

export const pasteOutcome = (listId: string, item: FrontendLogicItem, state: LogicState) => {
  return {
    ...state,
    [listId]: {
      ...state[listId],
      outcomes:
        state[listId].outcomes.length !== 0
          ? [
              ...state[listId].outcomes,
              {
                id: uuidv4(),
                title: "AND",
                type: "operator"
              },
              generateNewId(item)
            ]
          : [generateNewId(item)]
    }
  }
}

export const pasteCondition = (listId: string, item: FrontendLogicItem, state: LogicState) => {
  return {
    ...state,
    [listId]: {
      ...state[listId],
      conditions:
        state[listId].conditions.length !== 0
          ? [
              ...state[listId].conditions,
              {
                id: uuidv4(),
                title: "AND",
                type: "operator"
              },
              generateNewId(item)
            ]
          : [generateNewId(item)]
    }
  }
}

export const duplicateLogicRule = (list: LogicRule) => {
  const clonedOutcomes = list.outcomes.map((outcome: FrontendOutcome) => generateNewId(outcome))
  const clonedConditions = list.conditions.map((condition: FrontendCondition) =>
    generateNewId(condition)
  )

  return {
    [uuidv4()]: {
      outcomes: clonedOutcomes,
      conditions: clonedConditions
    }
  }
}

export const duplicateLogicRules = (state: LogicState) => {
  let duplicatedRules = {}
  Object.keys(state).forEach((key: string) => {
    duplicatedRules = {
      ...duplicatedRules,
      ...duplicateLogicRule(state[key])
    }
  })

  return duplicatedRules
}
