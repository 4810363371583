import styled from "styled-components/macro"

type StyledProps = {
  $percentage: number
}

export const Container = styled.div`
  width: 25rem;
  height: 4rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(9, 174, 76);
  ${(p) => p.theme.bold};
  margin-right: 6rem;
  position: relative;

  span {
    z-index: 2;
    display: flex;
    align-items: center;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;
      fill: rgb(9, 174, 76);
    }
  }
`

export const ProgressBar = styled.div<StyledProps>`
  background: #dcf4e5;
  height: 2.8rem;
  left: 0.5rem;
  max-width: calc(100% - 1rem);
  width: ${(p) => p.$percentage}%;
  z-index: 1;
  position: absolute;
`

export const Upcoming = styled.span`
  color: #0066cc !important;

  svg {
    fill: #0066cc !important;
  }
`
