import React, { useState } from "react"
import { Container, Format, InfoWrapper, InputWrapper, UpdateButton } from "./Totals.styled"
import Money from "../../../../../Money"
import DineroFactory from "dinero.js"
import Input from "../../../../../Ui/Form/Input"
import { ReactComponent as EditIcon } from "../../../../../../images/icons/gear.svg"
import { ReactComponent as ArrowIcon } from "../../../../../../images/icons/circle-xmark.svg"
import { ReactComponent as UpdateIcon } from "../../../../../../images/icons/rotate-right.svg"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import { OrderCompensation } from "lib/types/generated/graphql-types"
import { getCompensationInput } from "../../Helpers/helpers"
import { Error } from "../../OrderSummary/OrderSummary.styled"
import { handleOrderManagementError } from "../../../Helpers/errors"

enum TYPE {
  PAYMENT,
  BONUS
}

type Props = {
  currencyCode: string
  compensation: OrderCompensation
  refetch: () => void
  type: TYPE
  totalDeliveryAmount: number
}

const TotalItem = ({ currencyCode, compensation, refetch, type, totalDeliveryAmount }: Props) => {
  const [amount, setAmount] = useState<number>()
  const [expanded, setExpanded] = useState<boolean>(false)
  const maxAmount = totalDeliveryAmount

  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
      setAmount(0)
      setExpanded(false)
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const getInputAmount = () =>
    type == TYPE.PAYMENT ? { payment: { amount: amount } } : { bonus: { amount: amount } }

  const updateOrderCompensation = () => {
    return updateCompensation({
      variables: {
        compensationId: compensation?.id,
        input: {
          ...getCompensationInput(compensation),
          ...getInputAmount()
        }
      }
    })
  }

  const getAmount = () => {
    if (type == TYPE.PAYMENT) return compensation?.payment?.amount
    return compensation?.bonus?.amount
  }
  return (
    <Container $loading={loading}>
      <InfoWrapper>
        <span>
          <b>Amount:</b>
          <Money amount={getAmount() ?? 0} currencyUnit={currencyCode as DineroFactory.Currency} />
        </span>
        {expanded ? (
          <ArrowIcon onClick={() => setExpanded(false)} />
        ) : (
          <EditIcon onClick={() => setExpanded(true)} />
        )}
      </InfoWrapper>
      {expanded && (
        <InputWrapper>
          <span>
            <Input
              name="amount"
              type="number"
              placeholder="New amount in minor units"
              defaultValue={amount}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setAmount(parseInt(e.currentTarget.value))
              }
            />
            <UpdateButton
              disabled={(!amount && amount !== 0) || amount < 0 || (amount ?? 0) > maxAmount}
              handleClick={updateOrderCompensation}
            >
              <UpdateIcon />
            </UpdateButton>
          </span>
          <Format>
            Formatted:{" "}
            <Money amount={amount || 0} currencyUnit={currencyCode as DineroFactory.Currency} />
          </Format>
          {(amount ?? 0) > maxAmount && <Error>{`- Amount can't be higher than total`}</Error>}
        </InputWrapper>
      )}
    </Container>
  )
}

export default TotalItem
