import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { Campaign } from "@lib/types/generated/graphql-types"

import alertActions from "lib/store/services/Alert/AlertSlice"
import Popup from "components/Ui/Popup"
import ActionButton from "components/Ui/Table/ActionButton"
import DELETE_CAMPAIGN from "../../graphql/mutations/campaign/DeleteCampaign"
import { GroupedCampaign } from "@lib/types/common"

type Props = {
  campaign: Campaign
  setGroupedCampaigns: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
}

const DeleteCampaign = ({ campaign, setGroupedCampaigns }: Props) => {
  const [openPopup, setOpenPopup] = useState(false)
  const dispatch = useAppDispatch()

  const [deleteCampaign, { loading: deleteLoading }] = useMutation(DELETE_CAMPAIGN, {
    onCompleted: () => {
      setOpenPopup(false)
      setGroupedCampaigns((prev) =>
        prev?.map((gc) => ({
          ...gc,
          campaigns: gc.campaigns.filter((c) => c.id !== campaign.id)
        }))
      )
      dispatch(
        alertActions.actions.createAlert({
          message: `Campaign successfully deleted`,
          type: "success"
        })
      )
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          message: error.message,
          type: "error"
        })
      )
    }
  })
  const onDelete = () => {
    deleteCampaign({
      variables: {
        id: campaign.id
      }
    })
  }

  return (
    <>
      <ActionButton
        mode="delete"
        handleOnClick={(e) => {
          e.stopPropagation()
          setOpenPopup(true)
        }}
      />
      {openPopup && (
        <Popup
          title="Delete campaign"
          subtitle={`Are you sure you want to delete the following campaign?`}
          item={{
            itemId: campaign?.id,
            itemName: campaign?.name ?? ""
          }}
          handleCloseClick={(e) => {
            e.stopPropagation()
            setOpenPopup(false)
          }}
          handleOkClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
          loading={deleteLoading}
          type="delete"
        />
      )}
    </>
  )
}

export default DeleteCampaign
