import styled from "styled-components/macro"

import { IconTableHeader } from "components/Ui/Table/Shared.styled"
import TableRow from "components/Ui/Table/TableRow"

export const StyledIconTableHeader = styled(IconTableHeader)`
  padding-right: 8rem;
`

export const StyledTableRow = styled(TableRow)`

  &:hover {
    cursor: initial;
  }
`

export const Endpoint = styled.div`
&:hover {cursor: default}
  overflow: visible;
`

export const Name = styled.div`
  ${(p) => p.theme.bold};
  width: 17rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 25rem;
  }
`
