import styled from "styled-components/macro"

export const Title = styled.h2`
  display: flex;
  align-items: center;

  svg {
    height: 2.8rem;
    width: 2.8rem;
    margin-right: 1rem;
  }
`
