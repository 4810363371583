import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import EditSidebar from "../../../../../Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "../../../../../Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "../../../../../Ui/Buttons/PrimaryButton"
import Input from "../../../../../Ui/Form/Input"
import { Discount } from "@lib/types"
import {
  InputDiscountValue,
  InputDiscountValueType,
  InputStoreMarket,
  InputTagCondition,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { v4 as uuidv4 } from "uuid"

import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import { InputBundleItemRuleWithId } from "lib/types/discount"
import { AddProductParents } from "../AddProductParents"
import { BundleTagConditions } from "../../../../Ui/BundleTagConditions/BundleTagConditions"
import CheckboxSimple from "../../../../../Ui/Form/CheckboxSimple"
import ItemRuleDiscountSelector from "./ItemRuleDiscountSelector"

type Props = {
  editBundleItemRule?: InputBundleItemRuleWithId
  setItemRules: Dispatch<SetStateAction<InputBundleItemRuleWithId[]>>
  setEditBundleItemRule: Dispatch<SetStateAction<InputBundleItemRuleWithId | undefined>>
  storeGroups: StoreGroup[] | undefined
  addedStoreMarkets: InputStoreMarket[]
}

const BundleItemRuleSidebar = ({
  setItemRules,
  editBundleItemRule,
  setEditBundleItemRule,
  storeGroups,
  addedStoreMarkets
}: Props) => {
  const [excludeDiscountedPrices, setExcludeDiscountedPrices] = useState(false)
  const [excludeFullPrices, setExcludeFullPrices] = useState(false)
  const [tagConditions, setTagConditions] = useState<Discount.TagCondition[]>([])
  const [quantity, setQuantity] = useState<number>(1)
  const [productParents, setProductParents] = useState<string[]>([])
  const [discountValue, setDiscountValue] = useState<InputDiscountValue>({
    type: InputDiscountValueType.Relative
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (editBundleItemRule) {
      setExcludeDiscountedPrices(editBundleItemRule.excludeDiscountedPrices)
      setExcludeFullPrices(editBundleItemRule.excludeFullPrices)
      setTagConditions(editBundleItemRule.tagConditions)
      setQuantity(editBundleItemRule.quantity)
      setProductParents(editBundleItemRule.productParents)
      if (editBundleItemRule?.discountValue) setDiscountValue(editBundleItemRule?.discountValue)
      return
    }
    setExcludeDiscountedPrices(false)
    setExcludeFullPrices(false)
    setTagConditions([])
    setQuantity(1)
    setProductParents([])
  }, [editBundleItemRule])

  const clearItemRule = () => {
    setExcludeFullPrices(false)
    setExcludeDiscountedPrices(false)
    setTagConditions([])
    setQuantity(1)
    setProductParents([])
    setDiscountValue({
      type: InputDiscountValueType.Relative
    })
    closeSideBar()
  }

  const discountValueExists = () => {
    if (discountValue) {
      if (
        discountValue.type === InputDiscountValueType.Relative &&
        discountValue?.percentage &&
        discountValue?.percentage > 0
      ) {
        return true
      }
      if (
        discountValue.type === InputDiscountValueType.Fixed &&
        discountValue?.fixedDiscounts &&
        discountValue?.fixedDiscounts?.length > 0
      ) {
        return true
      }
      return false
    }
  }

  const addItemRule = () => {
    setItemRules((prev) => {
      if (!editBundleItemRule) {
        const copy = [...prev]
        copy.push({
          id: uuidv4(),
          excludeFullPrices,
          excludeDiscountedPrices,
          tagConditions: tagConditions as InputTagCondition[],
          quantity,
          productParents,
          ...(discountValueExists() && { discountValue })
        })
        return copy
      }
      return prev.map((itemRule) =>
        itemRule.id === editBundleItemRule?.id
          ? {
              id: editBundleItemRule.id,
              excludeFullPrices,
              excludeDiscountedPrices,
              tagConditions: tagConditions as InputTagCondition[],
              quantity,
              productParents,
              ...(discountValueExists() && { discountValue })
            }
          : itemRule
      )
    })
    clearItemRule()
  }

  const removeItemRule = () => {
    setItemRules((prev) => prev.filter((itemRule) => itemRule.id !== editBundleItemRule?.id))
    clearItemRule()
  }

  const closeSideBar = () => {
    setEditBundleItemRule(undefined)
    setDiscountValue({
      type: InputDiscountValueType.Relative
    })
    dispatch(hideEditSidebar())
  }

  return (
    <EditSidebar handleOkClick={() => setEditBundleItemRule(undefined)} cancelEvent={closeSideBar}>
      <EditSidebarHeader title={`Add bundle items`} cancelEvent={closeSideBar}>
        {editBundleItemRule ? (
          <PrimaryButton handleClick={removeItemRule} mode={"delete"}>
            Remove
          </PrimaryButton>
        ) : (
          <></>
        )}
        <PrimaryButton
          handleClick={addItemRule}
          disabled={productParents.length === 0 && tagConditions.length === 0}
        >
          {editBundleItemRule ? "Update" : "Add"}
        </PrimaryButton>
      </EditSidebarHeader>
      <h2>General</h2>
      <CheckboxSimple
        name="excludeDiscountedPrices"
        label="Exclude discounted prices"
        description="Should items with discounted prices be excluded?"
        value={excludeDiscountedPrices}
        setValue={(e: boolean) => {
          setExcludeDiscountedPrices(e)
          if (excludeFullPrices) {
            setExcludeFullPrices(false)
          }
        }}
        disabled={excludeFullPrices}
      />
      <CheckboxSimple
        name="excludeFullPrices"
        label="Exclude full prices"
        description="Should items with full prices be excluded?"
        value={excludeFullPrices}
        setValue={(e: boolean) => {
          setExcludeFullPrices(e)
          if (excludeDiscountedPrices) {
            setExcludeDiscountedPrices(false)
          }
        }}
        disabled={excludeDiscountedPrices}
      />
      <Input
        name={"quantity"}
        label={"Quantity that must be added to the cart"}
        type={"number"}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setQuantity(parseInt(e.currentTarget.value))
        }}
        defaultValue={editBundleItemRule?.quantity ?? quantity}
      />
      <h2>Select Products</h2>
      <AddProductParents updateProductParents={setProductParents} productParents={productParents} />
      <h3>And / or</h3>
      <BundleTagConditions tagConditions={tagConditions} setTagConditions={setTagConditions} />
      <h3>Set rule specific discount (Optional)</h3>
      <ItemRuleDiscountSelector
        discountValue={discountValue}
        setDiscountValue={setDiscountValue}
        storeGroups={storeGroups}
        addedStoreMarkets={addedStoreMarkets}
        editBundleItemRule={editBundleItemRule}
      />
    </EditSidebar>
  )
}

export default BundleItemRuleSidebar
