import React from "react"
import styled from "styled-components/macro"

import Input from "../../Form/Input"

import { ReactComponent as ArrowRight } from "images/icons/arrows-left-right.svg"
import { ReactComponent as CalendarIcon } from "images/icons/calendar.svg"

const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  div {
    padding: 0;
  }

  svg {
    display: none;
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 2rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
      display: block;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  svg {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 0;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    flex-direction: row;
    gap: 1.5rem;
  }
`

type Props = {
  setFromDate: (e: string) => void
  setToDate: (e: string) => void
  defaultFromDate: string
  defaultToDate: string
  type?: string
}
const FilterSection = ({
  setFromDate,
  setToDate,
  defaultFromDate,
  defaultToDate,
  type = "datetime-local"
}: Props) => (
  <Container>
    <CalendarIcon />
    <Wrapper>
      <Input
        value={defaultFromDate}
        type={type}
        name="fromDate"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFromDate(e.target.value)}
        overrideDisabled={true}
      />
      <ArrowRight />
      <Input
        value={defaultToDate}
        type={type}
        name="toDate"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToDate(e.target.value)}
        overrideDisabled={true}
      />
    </Wrapper>
  </Container>
)

export default FilterSection
