import gql from "graphql-tag"

const CREATE_OR_UPDATE_INGRID_CONFIG = gql`
    mutation createOrUpdateIngridConfig(
        $id: ID!
        $baseUrl: String!
        $siteId: String!
        $apiKey: String!
    ) {
        createOrUpdateIngridConfig(
            id: $id
            ingridConfig: {
                baseUrl: $baseUrl
                siteId: $siteId
                apiKey: $apiKey
            }
        ) {
            id
        }
    }
`

export default CREATE_OR_UPDATE_INGRID_CONFIG
