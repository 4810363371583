import React, { Dispatch, SetStateAction, useState } from "react"
import * as graphql from "lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"
import CopyButton from "../../../Ui/CopyButton"
import { ActionDropdownButton } from "../../../Ui/Buttons/ActionDropdownButton"
import Input from "../../../Ui/Form/Input"
import { ReactComponent as GiftCardIcon } from "images/icons/gift.svg"
import { ReactComponent as BonusIcon } from "images/icons/hand-holding-dollar.svg"
import { ReactComponent as PaymentIcon } from "images/icons/credit-card.svg"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import ErrorMessages from "../ErrorMessages"
import { handleOrderManagementError } from "../Helpers/errors"
import RESTART_RELEASE from "../../../../graphql/mutations/order/releases/RestartRelease"
import GET_RELEASE from "../../../../graphql/queries/order/GetRelease"
import {
  ActionWrapper,
  ButtonWrapper,
  Container,
  GiftCardInputWrapper,
  InfoWrapper,
  InputContainer,
  InputWrapper,
  LabelWrapper,
  Provider,
  Providers,
  ProviderWrapper,
  StyledActionDropdownButton,
  Title
} from "./FailedRelease.styled"

type Providers = {
  bonus: boolean
  giftCard: boolean
  payment: boolean
  shipping: boolean
}

type Props = {
  failedRelease: graphql.OrderRelease
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  setStartedRelease: Dispatch<SetStateAction<string>>
  orderStatus: graphql.OrderHistoryLogEntry[]
  refetch: () => void
}

enum FAILURE {
  BONUS = "RELEASE_BONUS_FAILURE",
  GIFT_CARD = "RELEASE_GIFT_CARD_FAILURE",
  PAYMENT = "RELEASE_PAYMENT_FAILURE",
  SHIPPING = "RELEASE_SHIPMENT_FAILURE"
}

const FailedRelease = ({
  failedRelease,
  setShowStatusPopup,
  setStartedRelease,
  orderStatus,
  refetch
}: Props) => {
  const [failedProviders, setFailedProviders] = useState<Providers | undefined>()
  const [giftCardAction, setGiftCardAction] = useState<graphql.InputActionGiftCard>({
    actionType: graphql.InputActionType.Auto
  })
  const [bonusAction, setBonusAction] = useState<graphql.InputActionBonus>({
    actionType: graphql.InputActionType.Auto
  })
  const [paymentAction, setPaymentAction] = useState<graphql.InputActionPaymentRelease>({
    actionType: graphql.InputActionType.Auto
  })
  const [shippingAction, setShippingAction] = useState<graphql.InputActionPaymentRelease>({
    actionType: graphql.InputActionType.Auto
  })

  const [restartRelease, { loading }] = useMutation(RESTART_RELEASE, {
    onCompleted: () => {
      setShowStatusPopup(true)
      refetch()
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const restartOrderRelease = () => {
    setStartedRelease(failedRelease.id)
    restartRelease({
      variables: {
        releaseId: failedRelease.id,
        input: {
          ...(failedProviders?.bonus && { bonus: bonusAction }),
          ...(failedProviders?.giftCard && { giftCard: giftCardAction }),
          ...(failedProviders?.payment && { payment: paymentAction }),
          ...(failedProviders?.shipping && { shipping: shippingAction })
        }
      }
    })
  }

  const updateGiftCardActionCards = (id: string, value: string) => {
    const idExists = giftCardAction?.giftCards?.find((giftCard) => giftCard.giftCardId === id)
    if (idExists) {
      return setGiftCardAction({
        ...giftCardAction,
        giftCards: giftCardAction?.giftCards?.map((giftCard) =>
          giftCard.giftCardId === id
            ? {
                giftCardId: id,
                transactionId: value
              }
            : giftCard
        )
      })
    }
    return setGiftCardAction({
      ...giftCardAction,
      giftCards: [...(giftCardAction.giftCards ?? []), { giftCardId: id, transactionId: value }]
    })
  }

  const updateShippingAction = (key: string, value: string) => {
    setShippingAction({ ...shippingAction, [key]: value })
  }

  const updatePaymentReference = (reference: string) => {
    setPaymentAction({ ...paymentAction, reference: reference })
  }
  useQuery(GET_RELEASE, {
    variables: { releaseId: failedRelease.id },
    onCompleted: (data) => {
      const releaseDetails = data?.Order?.release
      setFailedProviders({
        bonus: releaseDetails?.bonusProvider?.status?.current === FAILURE.BONUS,
        giftCard: releaseDetails?.giftCardProvider?.status?.current === FAILURE.GIFT_CARD,
        payment: releaseDetails?.paymentProvider?.status?.current === FAILURE.PAYMENT,
        shipping: releaseDetails?.shippingProvider?.status?.current === FAILURE.SHIPPING
      })
    }
  })

  const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)
  const getLabel = (value: string) => capitalize(value.toLowerCase())

  return (
    <>
      <Container>
        <Title>
          <div>
            <h2>Failed Release</h2>
          </div>
        </Title>
        id: {failedRelease.id} <CopyButton string={failedRelease.id} />
        <ProviderWrapper>
          <h4>Failed providers:</h4>
          {failedProviders?.bonus && <Provider>Bonus</Provider>}
          {failedProviders?.giftCard && <Provider>Gift card</Provider>}
          {failedProviders?.payment && <Provider>Payment</Provider>}
          {failedProviders?.shipping && <Provider>Shipping</Provider>}
        </ProviderWrapper>
        <>
          <InfoWrapper>
            <b>Auto</b>{" "}
            <p>Brink commerce will perform the necessary requests to fulfill the action.</p>
            <b>Manual</b>{" "}
            <p>
              The action has been performed in an external system and will be stored as manually
              completed.
            </p>
            <b>Skip</b> <p>The action will be skipped entirely.</p>
          </InfoWrapper>
          <Providers>
            {failedProviders?.giftCard && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <GiftCardIcon />
                    <b>GiftCard:</b>
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(giftCardAction.actionType)}>
                    <li onClick={() => setGiftCardAction({ actionType: graphql.InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: graphql.InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setGiftCardAction({ actionType: graphql.InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
                {giftCardAction.actionType === graphql.InputActionType.Manual && (
                  <>
                    {failedRelease?.giftCards?.map((giftCard) => {
                      return (
                        <GiftCardInputWrapper key={giftCard.giftCardId}>
                          <p>{giftCard.giftCardId}</p>
                          <Input
                            removePaddingBottom={true}
                            name="transactionId"
                            placeholder="Transaction id"
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                              updateGiftCardActionCards(giftCard.giftCardId, e.currentTarget.value)
                            }
                          />
                        </GiftCardInputWrapper>
                      )
                    })}
                  </>
                )}
              </div>
            )}
            {failedProviders?.bonus && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <BonusIcon />
                    <b>Bonus:</b>{" "}
                  </LabelWrapper>
                  <ActionDropdownButton title={getLabel(bonusAction.actionType)}>
                    <li onClick={() => setBonusAction({ actionType: graphql.InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setBonusAction({ actionType: graphql.InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setBonusAction({ actionType: graphql.InputActionType.Skip })}>
                      Skip
                    </li>
                  </ActionDropdownButton>
                </ActionWrapper>
              </div>
            )}
            {failedProviders?.shipping && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <ShippingIcon />
                    <b>Shipping: </b>
                  </LabelWrapper>
                  <StyledActionDropdownButton title={getLabel(shippingAction.actionType)}>
                    <li onClick={() => setShippingAction({ actionType: graphql.InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setShippingAction({ actionType: graphql.InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setShippingAction({ actionType: graphql.InputActionType.Skip })}>
                      Skip
                    </li>
                  </StyledActionDropdownButton>
                </ActionWrapper>
                {shippingAction.actionType === graphql.InputActionType.Manual && (
                  <InputContainer>
                    <InputWrapper>
                      <Input
                        name="shippingCompany"
                        placeholder="Shipping Company"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          updateShippingAction("shippingCompany", e.currentTarget.value)
                        }
                      />
                      <Input
                        name="shippingMethod"
                        placeholder="Shipping Method"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          updateShippingAction("shippingMethod", e.currentTarget.value)
                        }
                      />
                    </InputWrapper>
                    <Input
                      name="trackingReference"
                      placeholder="Tracking reference"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateShippingAction("trackingReference", e.currentTarget.value)
                      }
                    />
                    <Input
                      name="trackingUrl"
                      placeholder="Tracking url"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateShippingAction("trackingUrl", e.currentTarget.value)
                      }
                    />
                  </InputContainer>
                )}
              </div>
            )}
            {failedProviders?.payment && (
              <div>
                <ActionWrapper>
                  <LabelWrapper>
                    <PaymentIcon />
                    <b>Payment:</b>
                  </LabelWrapper>
                  <StyledActionDropdownButton title={getLabel(paymentAction.actionType)}>
                    <li onClick={() => setPaymentAction({ actionType: graphql.InputActionType.Auto })}>
                      Auto
                    </li>
                    <li onClick={() => setPaymentAction({ actionType: graphql.InputActionType.Manual })}>
                      Manual
                    </li>
                    <li onClick={() => setPaymentAction({ actionType: graphql.InputActionType.Skip })}>
                      Skip
                    </li>
                  </StyledActionDropdownButton>
                </ActionWrapper>
                {paymentAction.actionType === graphql.InputActionType.Manual && (
                  <Input
                    name="captureReference"
                    label="Capture reference"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updatePaymentReference(e.currentTarget.value)
                    }
                  />
                )}
              </div>
            )}
          </Providers>
        </>
        <ErrorMessages
          orderHistory={orderStatus}
          operationId={failedRelease.id}
          operation={"RELEASE"}
        />
        <ButtonWrapper>
          <SecondaryButton handleClick={restartOrderRelease} loading={loading}>
            Restart release
          </SecondaryButton>
        </ButtonWrapper>
      </Container>
    </>
  )
}

export default FailedRelease
