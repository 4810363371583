import React from "react"
import styled from "styled-components/macro"

interface StyledProps {
  inSidebar?: boolean
}

const Container = styled.div<StyledProps>`
  width: 100%;
  background: ${(p) => p.theme.colors.greyLighter};
  margin-bottom: ${(p) => (p.inSidebar ? "2rem" : "4rem")};
  padding: ${(p) => (p.inSidebar ? "0" : "2rem")};
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  gap: 2rem;

  > div {
    padding-bottom: 0;
    flex: 1;
  }

  input {
    padding-bottom: 0;
  }
`

type Props = {
  children: JSX.Element | JSX.Element[]
  inSidebar?: boolean
}

const QuickAdd = ({ children, inSidebar, ...props }: Props) => {
  return (
    <Container inSidebar={inSidebar} {...props}>
      {children}
    </Container>
  )
}

export default QuickAdd
