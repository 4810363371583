import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import {
  AdyenConfig,
  AdyenWebhook,
  QueryGetAdyenConfigArgs,
  SubscriptionOnAdyenWebhookChangedArgs
} from "lib/types/generated/graphql-types"

import GET_ADYEN_CONFIG from "graphql/queries/config/GetAdyenConfig"
import ON_ADYEN_WEBHOOK_UPDATED from "graphql/subscriptions/config/OnAdyenWebhookConfigUpdated"

import { Name, Id } from "components/Ui/Table/Shared.styled"
import { NameCell } from "./AdyenConfigTable.styled"

import TableRow from "../../Ui/Table/TableRow"
import { BlurRow } from "../../Ui/Table/TableBlurLoading"

import { ReactComponent as AdyenIcon } from "images/providers/icons/adyen.svg"

type Props = {
  id: string
  adyenConfigId: string
  setAdyenConfigId: Dispatch<SetStateAction<string>>
}

export const getLoadingRow = () => (
  <>
    <AdyenIcon />
    <NameCell>
      <div>
        <Name>Merchant account</Name>
        <Id>Merchant ID</Id>
      </div>
    </NameCell>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>PRODUCTION</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00, 00:00</p>
    </div>
    <div className="hideOnSmallScreens hideOnMeduimScreens">
      <p>2000-00-00, 00:00</p>
    </div>
  </>
)

export const AdyenConfigTableRow = ({ id, adyenConfigId, setAdyenConfigId }: Props) => {
  const dispatch = useAppDispatch()

  const { data, loading, subscribeToMore } = useQuery<AdyenConfigQuery, QueryGetAdyenConfigArgs>(
    GET_ADYEN_CONFIG,
    {
      variables: { id: id }
    }
  )

  subscribeToMore<AdyenWebhookSubscription, SubscriptionOnAdyenWebhookChangedArgs>({
    document: ON_ADYEN_WEBHOOK_UPDATED,
    variables: { configId: id },
    updateQuery(prev, { subscriptionData }) {
      if (!subscriptionData.data) return prev

      const webhook = subscriptionData.data.OnAdyenWebhookChanged
      let adyenConfig = prev.getAdyenConfig
      const existingWebhooks = adyenConfig.webhooks ?? []

      const exists = existingWebhooks.some((wh: AdyenWebhook) => wh.id === webhook.id)

      if (exists) {
        // update existing webhook only if sequence number is newer
        adyenConfig = {
          ...adyenConfig,
          webhooks: existingWebhooks.map((wh: AdyenWebhook) => {
            if (wh.id === webhook.id && wh.sequenceNumber < webhook.sequenceNumber) {
              return webhook
            }
            return wh
          })
        }
      } else {
        // add new webhook to existing adyen config
        adyenConfig = {
          ...adyenConfig,
          webhooks: [...existingWebhooks, webhook]
        }
      }

      // update the current adyen config if its open in an edit sidebar
      if (adyenConfigId === adyenConfig.id && setAdyenConfigId) {
        setAdyenConfigId(adyenConfigId)
      }

      return { getAdyenConfig: adyenConfig }
    }
  })

  const adyenConfig = data?.getAdyenConfig

  if (loading) return <BlurRow>{getLoadingRow()}</BlurRow>

  return (
    <TableRow
      key={id}
      handleOnClick={() => {
        if (setAdyenConfigId) setAdyenConfigId(id)
        dispatch(showEditSidebar())
      }}
    >
      <AdyenIcon />
      <NameCell>
        <div>
          <Name>{adyenConfig?.merchantAccount}</Name>
          <Id>{adyenConfig?.id}</Id>
        </div>
      </NameCell>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{adyenConfig?.environment}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(adyenConfig?.created).format("YYYY-MM-DD, HH:mm")}</p>
      </div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">
        <p>{dayjs(adyenConfig?.updated).format("YYYY-MM-DD, HH:mm")}</p>
      </div>
    </TableRow>
  )
}

interface AdyenConfigQuery {
  getAdyenConfig: AdyenConfig
}

interface AdyenWebhookSubscription {
  OnAdyenWebhookChanged: AdyenWebhook
}
