import React, { ReactNode } from "react"
import styled from "styled-components/macro"

const Container = styled.div`
  width: 100%;
`

type Props = {
  children: ReactNode
}

const EditSidebarSection = ({ children }: Props) => (
  <Container>{children}</Container>
)

export default EditSidebarSection
