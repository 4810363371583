import { OrderDelivery } from "@lib/types/generated/graphql-types"

export const getDeliveryInput = (delivery: OrderDelivery | undefined) => ({
  ...(delivery?.orderLines && {
    orderLines: delivery?.orderLines.map((orderLine) => ({
      orderLineId: orderLine.orderLineId,
      quantity: orderLine.quantity
    }))
  }),
  ...(delivery?.shippingFees && {
    shippingFees: delivery.shippingFees.map((fee) => fee.shippingFeeId)
  }),
  ...(delivery?.gifts && { gifts: delivery.gifts }),
  ...(delivery?.giftCardProducts && {
    giftCardProducts: delivery.giftCardProducts.map((giftCardProduct) => ({
      giftCardProductId: giftCardProduct.giftCardProductId
    }))
  })
})
