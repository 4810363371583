import gql from "graphql-tag";

const DELETE_RETAIN24_CONFIG = gql`
    mutation deleteRetain24Config($id: ID!) {
        deleteRetain24Config(id: $id) {
            status
        }
    }
`;

export default DELETE_RETAIN24_CONFIG;
