import gql from "graphql-tag"

const GET_TAX_MARKETS = gql`
  query getTaxMarkets($taxGroupId: String!) {
    getTaxMarkets(taxGroupId: $taxGroupId) {
      taxGroupId
      countryCode
      taxPercentage
      taxPercentageDecimals
      created
      updated
    }
  }
`

export default GET_TAX_MARKETS
