import React, { useState } from "react"
import styled from "styled-components/macro"
import { OrderPaymentQliroOne } from "@lib/types/generated/graphql-types"
import {
  getPaymentMethodDisplayName,
  getPaymentProviderDisplayName
} from "helpers/getPaymentDisplayName"

import { Item, Label, List, Value } from "components/Ui/List/List"

import { ReactComponent as AdyenLogo } from "images/providers/qliro-logo.svg"
import Status from "../../../../../components/Ui/Status"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "images/icons/chevron-up.svg"

const Logo = styled(AdyenLogo)`
  position: absolute;
  height: 2.5rem;
  right: 4rem;
`

const StyledLabel = styled(Label)`
  width: 22rem;
`

const OrderItems = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  padding: 1rem 0;
`

const OrderItemLabel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  user-select: none;

  svg {
    height: 1.6rem;
    margin-left: 1.5rem;
  }

  h4 {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`

type Props = {
  orderPayment: OrderPaymentQliroOne
}

const QliroOne = ({ orderPayment }: Props) => {
  const [orderItemsExpanded, setOrderItemsExpanded] = useState<boolean>(false)
  if (!orderPayment) return null

  return (
    <div>
      <List>
        <Logo />
        <Item>
          <StyledLabel>Payment provider:</StyledLabel>
          <Value>{getPaymentProviderDisplayName(orderPayment.providerName)}</Value>
        </Item>
        <Item>
          <StyledLabel>Payment method:</StyledLabel>
          <Value>{getPaymentMethodDisplayName(orderPayment.method)}</Value>
        </Item>
        <Item>
          <StyledLabel>Payment reference:</StyledLabel>
          <Value>{orderPayment.reference}</Value>
        </Item>
        {orderPayment?.paymentTypeCode ? (
          <Item>
            <StyledLabel>Payment type code:</StyledLabel>
            <Value>{orderPayment?.paymentTypeCode}</Value>
          </Item>
        ) : (
          <></>
        )}
        <Item>
          <StyledLabel>Status:</StyledLabel>
          <Status status={orderPayment.status} />
        </Item>
        <Item>
          <StyledLabel>Identity verified:</StyledLabel>
          <Status status={orderPayment.identityVerified ? "Verified" : "Not verified"} />
          <Value>{orderPayment.identityVerified}</Value>
        </Item>
        <Item>
          <StyledLabel>Merchant question answer:</StyledLabel>
          <Status
            status={orderPayment.merchantProvidedQuestionAnswer ? "Provided" : "Not provided"}
          />
        </Item>
        <Item>
          <StyledLabel>Require identity verification:</StyledLabel>
          <Status status={orderPayment.requireIdentityVerification ? "Required" : "Not required"} />
        </Item>
        <Item>
          <StyledLabel>Signup for newsletter:</StyledLabel>
          <Status status={orderPayment.signupForNewsletter ? "Enabled" : "Disabled"} />
        </Item>
        <OrderItems>
          <OrderItemLabel onClick={() => setOrderItemsExpanded(!orderItemsExpanded)}>
            {" "}
            <h4>Order Items </h4>
            {orderItemsExpanded ? <UpIcon /> : <DownIcon />}
          </OrderItemLabel>

          {orderItemsExpanded && (
            <>
              <Grid>
                <b>Merchant reference:</b>
                <b>Payment transaction id:</b>
              </Grid>
              {orderPayment?.orderItems?.map((orderItem) => (
                <Grid key={orderItem.merchantReference}>
                  <div>{orderItem.merchantReference}</div>
                  <div>{orderItem.paymentTransactionId}</div>
                </Grid>
              ))}
            </>
          )}
        </OrderItems>
      </List>
    </div>
  )
}

export default QliroOne
