interface ClosestValue {
  price: number
  distance: number
}

export const getRoundingRuleValue = (
  roundUp: boolean,
  roundDown: boolean,
  targets: string[],
  value: number
): number | undefined  => {
  let cv: ClosestValue | null = null

  const updateClosestValue = (
    cv: ClosestValue | null,
    dist: number,
    amount: number
  ): ClosestValue | null => {
    if (!cv || cv.distance > dist) {
      return { price: amount, distance: dist }
    }
    return cv
  }

  for (const target of targets) {
    const significantDigits = target.length
    const mod = 10 ** significantDigits
    const rest = value % mod
    const intTarget = parseInt(target, 10)
    if (isNaN(intTarget)) {
      throw new Error("Unable to convert to int64")
    }

    if (roundUp) {
      if (rest <= intTarget) {
        const dist = intTarget - rest
        const amount = value + dist
        cv = updateClosestValue(cv, dist, amount)
      }

      if (rest > intTarget) {
        const dist = mod - rest + intTarget
        const amount = value + dist
        cv = updateClosestValue(cv, dist, amount)
      }
    }

    if (roundDown && intTarget < value) {
      if (rest < intTarget) {
        const dist = mod - intTarget + rest
        const amount = value - dist
        cv = updateClosestValue(cv, dist, amount)
      }

      if (rest >= intTarget) {
        const dist = rest - intTarget
        const amount = value - dist
        cv = updateClosestValue(cv, dist, amount)
      }
    }
  }

  return cv?.price
}
