import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"
import Input from "../../../../Ui/Form/Input"
import {
  Order,
  OrderLine,
  ProductVariant,
  ProductVariantSearchHit,
  TaxMarket
} from "@lib/types/generated/graphql-types"
import {
  ConditionsAdd,
  Container,
  HitCount,
  Hits,
  Label,
  NotFound
} from "./AddOrUpdateProductVariant.styled"
import SEARCH_PRODUCT_VARIANTS from "../../../../../graphql/queries/product/SearchProductVariants"
import ProductVariantSearchHitRow from "./ProductVariantSearchHitRow"
import AddedProductVariantRow from "./AddedProductVariantRow"

type Props = {
  updateProductVariant: (variant: ProductVariant | undefined) => void,
  productVariant: ProductVariant | undefined
  storeGroupId: string
  countryCode: string
  currencyCode: string
  quantity: number
  setQuantity: Dispatch<SetStateAction<number>>
  discount: number
  setDiscount: Dispatch<SetStateAction<number>>
  setTaxMarket: Dispatch<SetStateAction<TaxMarket | undefined>>
  editOrderLine: OrderLine | undefined
  order: Order
  amountToHigh: boolean
}

export const AddOrUpdateProductVariant = ({
  updateProductVariant,
  productVariant,
  storeGroupId,
  countryCode,
  currencyCode,
  quantity,
  setQuantity,
  discount,
  setDiscount,
  setTaxMarket,
  editOrderLine,
  order,
  amountToHigh
}: Props) => {
  const [searchInput, setSearchInput] = useState("")
  const [productVariantResult, setProductVariantResult] = useState([])

  const referenceSearchQuery = esb.requestBodySearch().query(
    esb
      .boolQuery()
      .must(esb.termQuery("isActive", true))
      .mustNot(esb.termQuery("imageUrl", ""))
      .must([
        esb
          .queryStringQuery(searchInput.trim() + "*")
          .defaultOperator("AND")
          .analyzeWildcard(true)
      ])
  )

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 20,
    query: JSON.stringify(query.query)
  }

  const [searchProductVariants] = useLazyQuery(SEARCH_PRODUCT_VARIANTS, {
    variables
  })

  const addProductVariant = (productVariant: ProductVariant) => {
    updateProductVariant(productVariant)
    setSearchInput("")
  }

  useEffect(() => {
    if (!productVariant) {
      const delayDebounceFn = setTimeout(
        () => {
          searchProductVariants({
            variables
          }).then((response) => {
            setProductVariantResult(response?.data?.searchProductVariants?.hits)
          })
        },
        searchInput === "" ? 0 : 300
      )
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchInput])

  return (
    <Container $active={!!productVariant}>
      <Label>{editOrderLine ? "Product variant" : "Select product variant"}</Label>
      {!productVariant && (
        <ConditionsAdd>
          {!productVariant ? (
            <Input
              name={"search"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(e.target.value)
              }}
              value={searchInput}
              placeholder="Search product variant"
              disabled={!!productVariant}
            />
          ) : (
            <></>
          )}
          <>
            {searchInput !== "" && (
              <Hits>
                <HitCount>{productVariantResult.length} results</HitCount>
                <ul>
                  {productVariantResult.length > 0 ? (
                    productVariantResult.map((hit: ProductVariantSearchHit) => (
                      <ProductVariantSearchHitRow
                        key={hit.id}
                        variantId={hit.id}
                        addProductVariant={addProductVariant}
                      />
                    ))
                  ) : (
                    <NotFound>No search results found...</NotFound>
                  )}
                </ul>
              </Hits>
            )}
          </>
        </ConditionsAdd>
      )}
      {productVariant && (
        <AddedProductVariantRow
          key={productVariant.id}
          productVariant={productVariant}
          updateProductVariant={updateProductVariant}
          storeGroupId={storeGroupId}
          countryCode={countryCode}
          currencyCode={currencyCode}
          quantity={quantity}
          setQuantity={setQuantity}
          discount={discount}
          setDiscount={setDiscount}
          setTaxMarket={setTaxMarket}
          editOrderLine={editOrderLine}
          order={order}
          amountToHigh={amountToHigh}
        />
      )}
    </Container>
  )
}
