import gql from "graphql-tag"

const DELETE_API_CLIENT = gql`
    mutation deleteApiClient($clientId: ID!) {
        deleteApiClient(clientId: $clientId ) {
            status
        }
    }
`
export default DELETE_API_CLIENT
