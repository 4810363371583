import React from "react"
import { Item, Value } from "../../Ui/List/List"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import { Blur, BundleId, RuleLabel } from "./Bundle.styled"
import { BundleOutcome } from "@lib/types/generated/graphql-types"
import { ReactComponent as ArrowRight } from "../../../images/icons/arrow-right.svg"
import { useQuery } from "@apollo/client"
import GET_BUNDLE_GROUP from "../../../graphql/queries/bundles/GetBundleGroup"
import { useNavigate } from "react-router-dom"

type Props = {
  bundle: BundleOutcome
  currencyCode: string
}

const Bundle = ({ bundle, currencyCode }: Props) => {
  const { data, loading } = useQuery(GET_BUNDLE_GROUP, { variables: { id: bundle.bundleGroupId } })
  const navigate = useNavigate()

  if (loading) return (
    <Blur>
      <Item flexibleHeight={true}>
        <RuleLabel>
          <h4>
            Name
            <ArrowRight />
          </h4>
          <BundleId>
            <b>Bundle id:</b> bundleId
          </BundleId>
        </RuleLabel>
        <Value>
          200
        </Value>
      </Item>
    </Blur>
  )

  const bundleGroup = data?.getBundleGroup

  return (
    <Item flexibleHeight={true}>
      <RuleLabel onClick={() => navigate(`/discounts/bundle-groups/${bundleGroup.id}`)}>
        <h4>
          {bundleGroup.name}
          <ArrowRight />
        </h4>
        <BundleId>
          <b>Bundle id:</b> {bundle.bundleId}
        </BundleId>
      </RuleLabel>

      <Value>
        <>
          {(bundle?.discountAmount ?? 0) > 0 && (
            <Money
              amount={parseInt(`${bundle.discountAmount}`)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          )}
        </>
      </Value>
    </Item>
  )
}

export default Bundle
