import gql from "graphql-tag"

const GET_ADYEN_WEBHOOKS = gql`
    query getAdyenWebhooks{
        getAdyenWebhooks {
            id
            created
            updated
            username
        }
    }
`

export default GET_ADYEN_WEBHOOKS