import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import styled from "styled-components/macro"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Input from "components/Ui/Form/Input"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useLazyQuery, useMutation } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import { v4 as uuidv4 } from "uuid"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useSelector } from "react-redux"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"
import esb from "elastic-builder"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import Box from "components/Ui/Box"

import CREATE_OR_UPDATE_CAMPAIGN_GROUP from "graphql/mutations/campaign/CreateOrUpdateCampaignGroup"
import { CampaignGroup, InputCampaignGroup } from "@lib/types/generated/graphql-types"
import SecondaryButton from "components/Ui/Buttons/SecondaryButton"
import SEARCH_CAMPAIGN_GROUPS from "graphql/queries/campaign/SearchCampaignGroups"
import { Query } from "@lib/types/common"
import { isCampaignUser, isSuperUser } from "helpers/user"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  position: relative;
`
const GenerateIdWrapper = styled.div`
  display: flex;
  gap: 1rem;

  button {
    margin-top: 2.8rem;
    height: 4.3rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin-top: 2.9rem;
    }
  }

  span {
    margin-bottom: 0;
  }
`

const Name = styled.div`
  margin-right: 1rem;
  width: 100%;
`

const Description = styled.div`
  width: 100%;
`

type Inputs = {
  id: string
  name: string
  description: string
}

type Props = {
  setEditId?: Dispatch<SetStateAction<string>>
  setCampaignGroups: Dispatch<SetStateAction<CampaignGroup[]>>
}

export const CreateCampaignGroup = ({ setEditId, setCampaignGroups }: Props) => {
  const dispatch = useAppDispatch()

  const showSidebar = useSelector(editSidebarOpen)
  const [validationError, setValidationError] = useState<boolean>(false)
  const [validateCampaignGroupId] = useLazyQuery(SEARCH_CAMPAIGN_GROUPS)
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset
  } = useForm<Inputs>()

  useEffect(() => {
    if (!showSidebar && setEditId) setEditId("")
  }, [showSidebar])

  const closeSidebar = () => {
    dispatch(hideEditSidebar())
  }

  const validateId = async () => {
    const id = getValues("id")

    const referenceSearchQuery = esb
      .requestBodySearch()
      .query(esb.boolQuery().must(esb.termsQuery("id", id)))
    const query = referenceSearchQuery.toJSON() as Query
    const response = await validateCampaignGroupId({
      variables: {
        from: 0,
        size: 1000,
        query: JSON.stringify(query.query)
      }
    })

    if (response.data.searchCampaignGroups.total !== 0) {
      setValidationError(true)
      return false
    } else {
      setValidationError(false)
      return true
    }
  }
  const [addCampaignGroup, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_CAMPAIGN_GROUP,
    {
      onCompleted: (data) => {
        if (setEditId) setEditId("")
        setCampaignGroups((prev) => {
          // prepend newly created campaign group to the existing list
          return [data.createOrUpdateCampaignGroup, ...(prev ?? [])]
        })
        closeSidebar()
        reset({ name: undefined, id: undefined, description: undefined })
        dispatch(
          alertActions.actions.createAlert({
            message: "Campaign Group successfully created",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputCampaignGroup> = async (data) => {
    const valid = await validateId()

    if (valid) {
      addCampaignGroup({
        variables: { ...data, description: data.description === undefined ? "" : data.description }
      })
    }
  }
  return (
    <EditSidebar>
      <Box padding="0" margin="0">
        <form>
          <EditSidebarHeader title="New campaign group">
            <PrimaryButton
              type="button"
              handleClick={handleSubmit(onSubmit)}
              loading={createLoading}
              overrideDisabled={!isSuperUser() && isCampaignUser()}
            >
              Save
            </PrimaryButton>
          </EditSidebarHeader>
          <Container>
            <GenerateIdWrapper>
              <Controller
                name="id"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    label="ID *"
                    placeholder="Aa - Zz, 0 - 9, -_."
                    errors={validationError ? { id: { message: "ID already exist" } } : errors}
                    description="Cannot be modified after save"
                  />
                )}
                control={control}
                rules={{
                  required: "This is a required field",
                  pattern: {
                    value: /^[A-Za-z0-9_.-]+$/,
                    message: "Only Aa-Zz, 0-9 and _-. are allowed"
                  }
                }}
              />
              <SecondaryButton
                type="button"
                handleClick={() => {
                  setValue("id", uuidv4())
                }}
                overrideDisabled={!isSuperUser() && isCampaignUser()}
              >
                Generate
              </SecondaryButton>
            </GenerateIdWrapper>
            <div>
              <Name>
                <Controller
                  name="name"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      label="Name *"
                      placeholder="Aa - Zz, 0 - 9"
                      errors={errors}
                    />
                  )}
                  control={control}
                  rules={{
                    required: "This is a required field"
                  }}
                />
              </Name>
              <Description>
                <Controller
                  name="description"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      label="Description"
                      placeholder="Aa - Zz, 0 - 9"
                      errors={errors}
                    />
                  )}
                  control={control}
                />
              </Description>
            </div>
          </Container>
        </form>
      </Box>
    </EditSidebar>
  )
}
