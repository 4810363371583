import styled from "styled-components/macro"
import Search from "components/Ui/Search"

type StyledProps = {
  $isFilterActive?: boolean
  $active?: boolean
  $expanded?: boolean
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 2rem 0;
  user-select: none;
`

export const Flex = styled.div`
  padding: 0;
  gap: 3rem;
  border-bottom: 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    justify-content: space-between;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin-top: 0;
  }

  p {
    margin-right: 0.5rem;
  }
`

export const Show = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    gap: 1rem;
  }
`

export const NumberOfHits = styled.div<StyledProps>`
  width: 3rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 1.5rem 2.5rem;
  background: ${(p) => (p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.greyLighter)};
  color: ${(p) => (p.$active ? p.theme.colors.white : p.theme.colors.black)};
  font-weight: ${(p) => (p.$active ? p.theme.bold : "initial")};

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.grey};
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  max-width: 75rem;
`

export const ProductSearch = styled(Search)`
  margin-bottom: 0;
  padding: 0;
  border: none;

  > div {
    &:last-of-type {
      right: 2rem;
    }
  }
`

export const Result = styled.div`
  padding-left: 1rem;
  border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  display: flex;
  align-items: center;
  height: 4rem;
`

export const Hits = styled.span`
  ${(p) => p.theme.bold};
  display: inline-block;
  margin-left: 0.4rem;
`
