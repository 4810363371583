import gql from "graphql-tag"

const SEARCH_CODES_GENERATIONS = gql`
  query searchCodesGenerations(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
  ) {
    searchCodesGenerations(
      from: $from
      size: $size
      sort: $sort
      query: $query
    ) {
      total
      hits {
        id
        discountCodeRuleId
        prefix
        usageLimit
        numberOfCodesRequested
        numberOfCodesGenerated
        status
        created
        updated
      }
    }
  }
`

export default SEARCH_CODES_GENERATIONS
