import React, { Dispatch, SetStateAction, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import DineroFactory from "dinero.js"
import { isSuperUser } from "helpers/user"
import {
  CampaignMoney,
  InputStoreMarket,
  InputPriceRule,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { Common } from "@lib/types"
import { getCurrency } from "helpers/currency"

import {
  Add,
  AddButton,
  Amount,
  Container,
  Currency,
  CurrencySelect,
  Formatted,
  ThresholdRow,
  Thresholds,
  TresholdHeader
} from "../../Discount/Threshold.styled"
import { InputWrapper } from "./PriceRule.styled"

import Input from "components/Ui/Form/Input"
import Money from "components/Money"
import Popup from "components/Ui/Popup"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

type Props = {
  thresholds: CampaignMoney[]
  addMoney: (currency: string, amount: number) => void
  storeGroups: StoreGroup[] | undefined
  addedStoreMarkets: InputStoreMarket[]
  priceRule: InputPriceRule
  setPriceRule: Dispatch<SetStateAction<InputPriceRule>>
  isCampaignOngoing: boolean
}

const CampaignThreshold = ({
  thresholds,
  addMoney,
  storeGroups,
  addedStoreMarkets,
  priceRule,
  setPriceRule,
  isCampaignOngoing
}: Props) => {
  const [currency, setCurrency] = useState<string>("")
  const [amount, setAmount] = useState<number>(0)
  const [openPopup, setOpenPopup] = useState(false)
  const [currencyCodeToDelete, setCurrencyCodeToDelete] = useState<string>("")

  const updateKey = (keyType: Common.Option) => {
    setCurrency(keyType?.value)
  }

  const getAddedCurrencies = (storeGroupId: string, countryCodes: string[]) => {
    const storeGroup = storeGroups?.find((storeGroup) => storeGroup.id === storeGroupId)
    return storeGroup?.storeMarkets?.filter((val) => countryCodes.includes(val?.countryCode ?? ""))
  }

  const uniqueCurrencies = [
    ...new Set(
      addedStoreMarkets
        .map((addedStoreMarket) => getAddedCurrencies(addedStoreMarket.key, addedStoreMarket.value))
        .flatMap((storeMarket) => storeMarket?.flatMap((storeMarket) => storeMarket?.currencyCode))
    )
  ]

  const addedCurrencies = priceRule?.monies?.map((money) => money.key)

  const currencyOptions = uniqueCurrencies
    ?.filter((currency) => !addedCurrencies?.includes(currency ?? ""))
    ?.map((currency) => ({
      value: currency ?? "",
      label: `${getCurrency(currency ?? "")?.currency} [${currency}]`
    }))

  const updateMonies = () => {
    if (!addedCurrencies?.includes(currency ?? "")) {
      addMoney(currency, amount)
    }
    setCurrency("")
  }

  const onDeleteCurrency = (currency: string) => {
    setPriceRule({
      ...priceRule,
      monies: priceRule?.monies?.filter((money) => money.key !== currency)
    })
    setOpenPopup(false)
  }
  return (
    <Container>
      <Add>
        <h3>Add price rule(s)</h3>
        <InputWrapper>
          <CurrencySelect
            options={currencyOptions}
            setValue={updateKey}
            placeholder="Select currency"
            label="Currency:"
            disabled={isCampaignOngoing}
            updateDefaultValue={true}
          />
          <Input
            name="amount"
            type="number"
            disabled={isCampaignOngoing}
            label={<>Amount in minor units:</>}
            placeholder="Amount in minor units"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAmount(parseInt(e.target.value))
            }}
          />
          <AddButton
            type="button"
            handleClick={updateMonies}
            overrideDisabled={!!currency && !!amount && !isSuperUser()}
            disabled={(!currency || !amount) || isCampaignOngoing}
          >
            <PlusIcon /> Add
          </AddButton>
        </InputWrapper>

        <Formatted>
          <strong>Example: </strong>
          {amount && currency ? (
            <Money
              amount={parseInt(`${amount}`)}
              currencyUnit={currency as DineroFactory.Currency}
            />
          ) : (
            "0"
          )}
        </Formatted>
      </Add>
      <Thresholds>
        {thresholds?.length > 0 ? (
          <>
            <TresholdHeader>
              <div>Currency</div>
              <div>Amount</div>
            </TresholdHeader>
            <div>
              {thresholds.map((t) => {
                return (
                  <React.Fragment key={uuidv4()}>
                    <ThresholdRow
                      deleteEnabled={isSuperUser()}
                      smallButtons
                      disableHover
                      handleDelete={() => {
                        setCurrencyCodeToDelete(t.key)
                        setOpenPopup(true)
                      }}
                    >
                      <Currency>
                        <span>{t.key}</span>
                        {getCurrency(t.key).currency}
                      </Currency>
                      <Amount>
                        <span>{t.value}</span>
                        <Money
                          amount={parseInt(`${t.value}`)}
                          currencyUnit={t.key as DineroFactory.Currency}
                        />
                      </Amount>
                    </ThresholdRow>
                    {openPopup && currencyCodeToDelete === t.key && (
                      <Popup
                        title="Delete threshold"
                        subtitle={`Are you sure you want to delete the threshold for following currency?`}
                        item={{ itemId: "", itemName: t.key }}
                        handleCloseClick={() => setOpenPopup(false)}
                        handleOkClick={() => onDeleteCurrency(t.key)}
                        type="delete"
                      />
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </>
        ) : (
          <p>No thresholds added</p>
        )}
      </Thresholds>
    </Container>
  )
}

export default CampaignThreshold
