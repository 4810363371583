import React, { useState } from "react"
import { useAppDispatch } from "lib/store"
import { v4 as uuid } from "uuid"
import { useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"

import GET_EXTERNAL_EVENT_RULES from "graphql/queries/settings/externalEvents/GetExternalEventsRules"
import ENABLE_EXTERNAL_EVENT_RULE from "graphql/mutations/settings/externalEvents/EnableExternalEventsRule"
import DISABLE_EXTERNAL_EVENT_RULE from "graphql/mutations/settings/externalEvents/DisableExternalEventsRule"

import { Container, Wrapper } from "components/Ui/Table/Shared.styled"
import { StyledIconTableHeader, StyledTableRow, Name } from "./ExternalEventsTable.styled"

import { BrinkLoader } from "components/Ui/BrinkLoader"
import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import Popup from "components/Ui/Popup"
import Status from "components/Ui/Status"
import ActionButton from "components/Ui/Table/ActionButton"

import { ReactComponent as SatelliteIcon } from "images/icons/satellite-dish.svg"

const ExternalEventsTable = () => {
  const { data, error, loading, refetch } = useQuery(GET_EXTERNAL_EVENT_RULES)
  const [enableItem, setEnableItem] = useState<string>("")
  const [disableItem, setDisableItem] = useState<string>("")
  const eventRules = data?.getExternalEventsRules?.rules
  const dispatch = useAppDispatch()

  const [enableEventRule, { loading: enableLoading }] = useMutation(ENABLE_EXTERNAL_EVENT_RULE, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `Event rule ${enableItem} is now enabled`,
          type: "success"
        })
      )
      setEnableItem("")
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [disableEventRule, { loading: disableLoading }] = useMutation(DISABLE_EXTERNAL_EVENT_RULE, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: `Event rule ${disableItem} is now disabled`,
          type: "success"
        })
      )
      setDisableItem("")
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Event rules. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}

        {enableItem && (
          <Popup
            title="Enable Event rule?"
            subtitle="Are you sure you want to enable the following:"
            item={{ itemId: undefined, itemName: enableItem }}
            loading={enableLoading}
            buttonText="Yes, enable"
            handleOkClick={() =>
              enableEventRule({
                variables: {
                  ruleName: enableItem
                }
              })
            }
            handleCloseClick={() => setEnableItem("")}
          />
        )}

        {disableItem && (
          <Popup
            title="Disable Event rule?"
            subtitle="Are you sure you want to disable the following:"
            buttonText="Yes, disable"
            item={{ itemId: undefined, itemName: disableItem }}
            loading={disableLoading}
            type="delete"
            handleOkClick={() =>
              disableEventRule({
                variables: {
                  ruleName: disableItem
                }
              })
            }
            handleCloseClick={() => setDisableItem("")}
          />
        )}

        {loading && !error ? (
          <BrinkLoader />
        ) : (
          <>
            <StyledIconTableHeader>
              <Name>Name</Name>
              <div>Status</div>
            </StyledIconTableHeader>
            {eventRules &&
              [...eventRules]
                .sort((a, b) => a.ruleName.localeCompare(b.ruleName))
                .map((rule) => (
                  <StyledTableRow key={uuid()}>
                    <SatelliteIcon className="hideOnSmallScreens" />
                    <div>
                      <Name>{rule.ruleName}</Name>
                    </div>
                    <div>
                      <Status status={rule.state} />
                    </div>
                    {rule.state === "ENABLED" ? (
                      <ActionButton
                        handleOnClick={() => setDisableItem(rule.ruleName)}
                        mode="edit"
                      />
                    ) : (
                      <ActionButton
                        handleOnClick={() => setEnableItem(rule.ruleName)}
                        mode={"edit"}
                      />
                    )}
                  </StyledTableRow>
                ))}
          </>
        )}
      </Container>
    </Wrapper>
  )
}

export default ExternalEventsTable
