import React from "react"
import { Order, OrderRefund } from "lib/types/generated/graphql-types"
import { Container, Label, ShippingFeeWrapper } from "./ShippingFees.styled"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { useMutation } from "@apollo/client"
import UPDATE_REFUND from "../../../../../graphql/mutations/order/refunds/UpdateRefund"
import { getRefundInput } from "../Helpers/helpers"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  refund: OrderRefund
  currencyCode: string
  refetch: () => void
  order: Order
}

const ShippingFees = ({ refund, currencyCode, refetch, order }: Props) => {
  const [updateRefund, { loading }] = useMutation(UPDATE_REFUND, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const deliveryShippingFees = order.Deliveries.filter((delivery) => !!delivery.completed).flatMap(
    (delivery) => delivery.shippingFees
  )

  const shippingFeeExistsInRefund = (id: string) =>
    !!refund?.shippingFees?.find((fee) => fee.shippingFeeId === id)

  const shippingFeeAlreadyRefunded = (id: string) => {
    const refundedFees = order.Refunds.filter((refund) => !!refund.completed).filter((refund) =>
      refund.shippingFees.find((fee) => fee.shippingFeeId === id)
    )
    return refundedFees.length > 0
  }

  const addShippingFee = (id: string) => {
    updateRefund({
      variables: {
        refundId: refund.id,
        input: {
          ...getRefundInput(refund),
          shippingFees: [...refund.shippingFees, id]
        }
      }
    })
  }

  const rempoveShippingFee = (id: string) => {
    updateRefund({
      variables: {
        refundId: refund.id,
        input: {
          ...getRefundInput(refund),
          shippingFees: refund.shippingFees.filter((fee) => fee.shippingFeeId !== id)
        }
      }
    })
  }

  return (
    <Container addedReason={!!refund?.reason}>
      <Label> include shipping:</Label>
      {deliveryShippingFees.map((fee) => {
        const orderShippingFee = order?.shippingFees?.find(
          (orderFee) => orderFee.id === fee.shippingFeeId
        )
        if (shippingFeeAlreadyRefunded(fee.shippingFeeId))
          return <div>No shipping fee to refund</div>
        return (
          <ShippingFeeWrapper key={fee.shippingFeeId}>
            <div>
              <b>{orderShippingFee?.name}</b>
              {" - "}
              <Money
                amount={orderShippingFee?.salePriceAmount ?? 0}
                currencyUnit={currencyCode as DineroFactory.Currency}
              />
            </div>
            {shippingFeeExistsInRefund(fee.shippingFeeId ?? "") ? (
              <PrimaryButton
                loading={loading}
                mode="delete"
                handleClick={() => rempoveShippingFee(fee?.shippingFeeId ?? "")}
              >
                Exclude
              </PrimaryButton>
            ) : (
              <PrimaryButton
                loading={loading}
                handleClick={() => addShippingFee(fee?.shippingFeeId ?? "")}
              >
                Include
              </PrimaryButton>
            )}
          </ShippingFeeWrapper>
        )
      })}
    </Container>
  )
}

export default ShippingFees
