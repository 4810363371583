import styled from "styled-components/macro"

type StyledProps = {
  $showFullSummary: boolean
}

type PriceProps = {
  $hasDiscountPrice: boolean
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 2rem 1.5rem 0 1.5rem;
  margin: 0 0 2rem 0;
  cursor: pointer;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 6rem 0 2rem;
  }
`

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    grid-template-columns: repeat(2, 50% 25% 25%);
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_X_LARGE} {
  }
`

export const ImageAndDetails = styled.div`
  display: flex;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding-bottom: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    border-bottom: none;
    padding-bottom: 0;
  }
`

export const PriceInfo = styled.div<StyledProps>`
  transition: all 0.2s;
  max-height: ${(p) => (p.$showFullSummary ? "30rem" : "2.5rem")};
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5rem;
    transition: all 0.3s;
    background: ${(p) =>
      !p.$showFullSummary
        ? "linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)"
        : "transparent"};
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  height: 5rem;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
  }
`

export const ImageContainer = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  flex: initial !important;
  align-self: center;
  margin-right: 1rem;
  height: 9rem;
  width: 9rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    transition: all 0.2s;
    margin-right: 2rem;
    height: ${(p) => (p.$showFullSummary ? "17rem" : "9rem")};
    width: ${(p) => (p.$showFullSummary ? "17rem" : "9rem")};
  }
`

export const IconWrapper = styled.div<StyledProps>`
display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
  width: 9rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    transition: all 0.2s;
    margin-right: 2rem;
    height: ${(p) => (p.$showFullSummary ? "17rem" : "9rem")};
    width: ${(p) => (p.$showFullSummary ? "17rem" : "9rem")};
  }

  svg {
    height: ${(p) => (p.$showFullSummary ? "6rem" : "4rem")};
    width: ${(p) => (p.$showFullSummary ? "6rem" : "4rem")};
  }
`

export const ProductImage = styled.img<StyledProps>`
  width: 100%;
  display: block;
  flex-shrink: 0;
  max-height: 100%;
  object-fit: contain;
`

export const Name = styled.h3`
  margin: 0;
  display: block;
  padding-right: 2rem;
  line-height: 2rem;
`

export const Id = styled.span`
  color: ${(p) => p.theme.colors.greyDark};
  margin: 0.5rem 0 1rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    margin: 0.5rem 0 1.5rem;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  span {
    &:last-of-type {
      white-space: nowrap;
    }
  }
`

export const FieldTitle = styled.span`
  ${(p) => p.theme.bold};
  line-height: 1.8rem;
`

export const TotalTitle = styled(FieldTitle)`
  font-size: 1.5rem;
  margin-left: 1rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    font-size: 1.6rem;
  }
`

export const Price = styled.span`
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
`

export const BasePrice = styled.span<PriceProps>`
  text-decoration: ${(p) => (p.$hasDiscountPrice ? "line-through" : "none")};
`

export const SalePrice = styled.span`
  color: ${(p) => p.theme.colors.turquoiseDark};
`

export const ExpandIcon = styled.span<StyledProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  border-radius: 0 0.6rem 0.6rem 0;
  padding: 1.6rem 0;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    border-left: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    transition: all 0.2s;
    overflow: hidden;
    transform: rotate(${(p) => (p.$showFullSummary ? "180deg" : "0deg")});
  }
`

export const ParentId = styled.span`
  a {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.greyDark};
  }
`

export const Qty = styled.span`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1rem 0 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    border-bottom: none;
    padding: 0;
  }

  div {
    margin-left: 0.5rem;
  }
`

export const Refunded = styled.span`
padding: 0.5rem 1rem;
  background: ${p => p.theme.colors.yellowLighter};
  border: 0.1rem solid ${p => p.theme.colors.yellowBorder};
  width: fit-content;
  border-radius: 0.6rem;
`
export const Delivered = styled.span`
  padding: 0.5rem 1rem;
  background: ${p => p.theme.colors.turquoiseLighter};
  border: 0.1rem solid ${p => p.theme.colors.turquoiseLight};
  width: fit-content;
  border-radius: 0.6rem;
  margin-right: 1rem;
`
