import gql from "graphql-tag"

const RESTART_COMPENSATION = gql`
  mutation restartCompensation($compensationId: ID!, $input: InputOrderStartCompensation!) {
    Order {
      restartCompensation(compensationId: $compensationId, input: $input) {
        status
      }
    }
  }
`

export default RESTART_COMPENSATION
