import React, { useState } from "react"
import { Inventory, StoreMarket } from "@lib/types/generated/graphql-types"
import { useMutation, useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { Option } from "@lib/types/common"

import ALL_INVENTORIES from "graphql/queries/inventory/AllInventories"
import GET_INVENTORY_STORE_MARKET from "graphql/queries/store/GetInventoryStoreMarket"
import UPDATE_INVENTORY_STORE_MARKET_INVENTORIES from "graphql/mutations/inventory/UpdateInventoryStoreMarketInventories"

import { Label, InputWrapper } from "./Inventories.styled"

import DragAndDropSorting from "../Ui/DragAndDropSorting"
import SingleSelect from "../Ui/Form/SingleSelect"
import PrimaryButton from "../Ui/Buttons/PrimaryButton"

type Props = {
  editStoreMarket: StoreMarket
}

const Inventories = ({ editStoreMarket }: Props) => {
  const [inventories, setInventories] = useState<Inventory[]>([])
  const [selectedInventory, setSelectedInventory] = useState<Option | null>(null)
  const dispatch = useAppDispatch()

  const getInventoryIds = () => {
    return inventories?.map((inventory) => inventory.id)
  }

  const { data: inventoriesData } = useQuery(ALL_INVENTORIES)

  const { refetch, loading } = useQuery(GET_INVENTORY_STORE_MARKET, {
    variables: {
      storeGroupId: editStoreMarket?.storeGroupId,
      countryCode: editStoreMarket?.countryCode
    },
    onCompleted: (data) => {
      setInventories(data.getInventoryStoreMarket?.inventories)
    }
  })

  const [addInventoryStoreMarketInventories, { loading: createLoadingInventories }] = useMutation(
    UPDATE_INVENTORY_STORE_MARKET_INVENTORIES
  )

  const updateInventoryOrder = (updatedInventories: Inventory[]) => {
    addInventoryStoreMarketInventories({
      variables: {
        storeGroupId: editStoreMarket.storeGroupId,
        countryCode: editStoreMarket.countryCode,
        inventoryIds: updatedInventories.map((inventory) => inventory.id)
      },
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Inventory sort order updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    })
  }

  const handleAddInventory = () => {
    addInventoryStoreMarketInventories({
      variables: {
        storeGroupId: editStoreMarket.storeGroupId,
        countryCode: editStoreMarket.countryCode,
        inventoryIds: [...getInventoryIds(), selectedInventory?.value]
      },
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Inventory successfully added",
            type: "success"
          })
        )
        setSelectedInventory(null)
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    })
  }

  const deleteInventory = (inventoryId: string) => {
    addInventoryStoreMarketInventories({
      variables: {
        storeGroupId: editStoreMarket.storeGroupId,
        countryCode: editStoreMarket.countryCode,
        inventoryIds: getInventoryIds().filter((id) => id !== inventoryId)
      },
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Inventory successfully deleted",
            type: "success"
          })
        )
        setSelectedInventory(null)
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    })
  }

  const inventoryOptions = inventoriesData?.getInventories
    ?.filter(({ id }: Inventory) => !getInventoryIds()?.includes(id))
    .map((inventory: Inventory) => ({
      value: inventory.id,
      label: inventory.name
    }))

  return (
    <>
      <Label>Inventories</Label>
      <InputWrapper>
        <SingleSelect
          options={inventoryOptions}
          setValue={setSelectedInventory}
          defaultValue={selectedInventory}
          updateDefaultValue={true}
        />{" "}
        <PrimaryButton
          disabled={!selectedInventory}
          loading={createLoadingInventories}
          handleClick={handleAddInventory}
        >
          Add
        </PrimaryButton>
      </InputWrapper>

      <DragAndDropSorting
        values={inventories}
        setValues={setInventories}
        onChange={updateInventoryOrder}
        onDelete={deleteInventory}
        loading={loading}
      />
    </>
  )
}
export default Inventories
