import React from "react"
import { OrderLine } from "@lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"

import GET_PRODUCT_VARIANT_STOCK from "graphql/queries/product/GetProductVariantStock"

import { Id, Name, ProductImage, Row } from "./Shared.styled"
import { Stock } from "./AddVariant/Search.styled"
import { Error } from "components/Ui/Status.styled"

import { Loader } from "components/Ui/Loader"
import Status from "components/Ui/Status"

import placeholder from "images/placeholder.svg"

type Props = {
  productVariant: OrderLine
  stockErrorText?: string
}

export const VariantRow = ({ productVariant, stockErrorText }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const { data, loading } = useQuery(GET_PRODUCT_VARIANT_STOCK, {
    variables: { id: productVariant.productVariantId }
  })

  const stockQty = data?.Product.variant.Stock.stockQuantity

  return (
    <Row key={productVariant.id}>
      <ProductImage src={productVariant?.imageUrl as string} onError={handleImageError} />
      <div>
        <Name>{productVariant.name}</Name>
        <Id>{productVariant.productVariantId}</Id>
        <span>
          Order quantity: <b>{productVariant.quantity}</b>
        </span>
      </div>
      {stockErrorText ? (
        <Error>{<>{stockErrorText}</>}</Error>
      ) : (
        <Stock>
          {loading ? (
            <Loader color="black" />
          ) : (
            <>
              {stockQty < productVariant.quantity ? (
                <Error>{<>Only {stockQty} left in stock</>}</Error>
              ) : (
                <Status status="In stock" />
              )}
            </>
          )}
        </Stock>
      )}
    </Row>
  )
}
