import React, { useState } from "react"
import styled from "styled-components/macro"

import { List } from "components/Ui/List/List"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import { Maybe } from "@graphql-tools/utils"

type StyledProps = {
  $expanded: boolean
}

const Block = styled.div`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 3rem;
  position: relative;
  height: calc(100% - 6.4rem);
`

const TagValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  div {
    background: ${(p) => p.theme.colors.greyLight};
    padding: 0.3rem 1rem;
    margin: 0.5rem;
    text-align: center;
    border-radius: 0.6rem;
    font-weight: 700;
    font-size: 1.2rem;
    width: fit-content;
  }
`

const Arrow = styled(DownIcon)<StyledProps>`
  width: 1.2rem !important;
  margin-left: 1rem;
  transition: transform 0.2s;
  transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
`

const Tab = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => (p.$expanded ? "none" : `0.1rem solid ${p.theme.colors.greyLight}`)};

  svg {
    width: 1.6rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.turquoiseDark};

    svg {
      fill: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

type Props = {
  shippingAttributes: Maybe<string[]> | undefined
}

export const ProductVariantShippingAttributes = ({
  shippingAttributes
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <>
      <Tab onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        <ShippingIcon />
        <h4>Shipping attributes</h4>
        <Arrow $expanded={expanded} />
      </Tab>
      {expanded && (
        <Block>
          <List>
            {shippingAttributes && shippingAttributes?.length > 0 ? (
              shippingAttributes?.map((attribute) => (
                <div key={attribute}>
                  <TagValues>
                    <div>{attribute}</div>
                  </TagValues>
                </div>
              ))
            ) : (
              <p>No shipping attributes set</p>
            )}
          </List>
        </Block>
      )}
    </>
  )
}
