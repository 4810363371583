import gql from "graphql-tag"

const DISABLE_USER = gql`
    mutation disableUser(
        $username: AWSEmail!
    ) {
        disableUser(
            username: $username
        ) {
          status  
        }
    }
`

export default DISABLE_USER