import gql from "graphql-tag"

const CREATE_DELIVERY = gql`
  mutation createDelivery($orderId: ID!, $input: InputOrderDelivery!) {
    Order {
      createDelivery(orderId: $orderId, input: $input) {
        id
      }
    }
  }
`

export default CREATE_DELIVERY
