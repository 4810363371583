import gql from "graphql-tag"

const GROUP_USERS = gql`
    query groupUsers(
        $groupname: String!
        $limit: Int!
        $token: String
    ) {
        groupUsers(
            groupname: $groupname
            limit: $limit
            token: $token
        ) {
            nextToken
            users {
                created
                enabled
                familyName
                givenName
                status
                updated
                username
            }
        }
    }
`

export default GROUP_USERS
