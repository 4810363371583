import React, { Dispatch, SetStateAction, useState } from "react"
import styled from "styled-components/macro"
import { useMutation, useQuery } from "@apollo/client"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import GET_TAX_GROUP from "../../../graphql/queries/tax/GetTaxGroup"
import CREATE_OR_UPDATE_TAX_GROUP from "../../../graphql/mutations/tax/CreateOrUpdateTaxGroup"
import DELETE_TAX_GROUP from "../../../graphql/mutations/tax/DeleteTaxGroup"
import GET_TAX_MARKETS from "../../../graphql/queries/tax/GetTaxMarkets"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import Input from "../../../components/Ui/Form/Input"
import Box from "../../../components/Ui/Box"
import EditSidebarHeader from "../../../components/Ui/EditSidebar/EditSidebarHeader"
import PageSectionHeader from "../../../components/Ui/Page/PageSectionHeader"
import ErrorMessage from "../../../components/Ui/Messages/ErrorMessage"
import EditSidebarSection from "../../../components/Ui/EditSidebar/EditSidebarSection"
import EditSidebar from "../../../components/Ui/EditSidebar/EditSidebar"
import DeleteButton from "../../../components/Ui/EditSidebar/DeleteButton"
import UpdateTaxMarket from "../TaxMarket/UpdateTaxMarket"
import TaxMarketTable from "../../../components/TaxMarket/TaxMarketTable"
import { useAppDispatch } from "lib/store"
import { TaxMarket } from "lib/types/generated/graphql-types"

const Form = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
`

type Inputs = {
  name: string
  description: string
}

type Props = {
  id: string
  setEditId: Dispatch<SetStateAction<string>>
  refetch: () => void
  deleteTaxGroup: (id: string) => void
}

const UpdateTaxGroup = ({ id, setEditId, refetch: refetchTaxGroups, deleteTaxGroup }: Props) => {
  const dispatch = useAppDispatch()
  const [editTaxMarket, setEditTaxMarket] = useState<TaxMarket | undefined>()
  const [taxMarkets, setTaxMarkets] = useState<TaxMarket[]>()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<Inputs>()

  const resetForm = () => {
    deleteTaxGroup(id)
    reset({ name: "", description: "" })
    setEditId("")
  }

  const { loading, error, data, refetch } = useQuery(GET_TAX_GROUP, {
    variables: { id: id }
  })

  const { loading: loadingTaxMarkets, refetch: refetchTaxMarkets } = useQuery(GET_TAX_MARKETS, {
    variables: { taxGroupId: id },
    onCompleted: (data) => {
      setTaxMarkets(data.getTaxMarkets)
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [addTaxGroup, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_TAX_GROUP, {
    onCompleted: () => {
      refetch()
      dispatch(
        alertActions.actions.createAlert({
          message: "Tax Group successfully updated",
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) =>
    addTaxGroup({
      variables: {
        id: id,
        name: data?.name,
        description: data?.description
      }
    })

  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            Error loading Tax group. Try refreshing the page, or contact{" "}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  const taxGroup = data?.getTaxGroup
  if (loading && loadingTaxMarkets) return <EditSidebar loading={true} />
  if (!data) return null

  return (
    <>
      {editTaxMarket ? (
        <UpdateTaxMarket
          editTaxMarket={editTaxMarket}
          setEditTaxMarket={setEditTaxMarket}
          refetch={refetch}
          refetchTaxGroups={refetchTaxGroups}
          refetchTaxMarkets={refetchTaxMarkets}
        />
      ) : (
        <EditSidebar setEditId={setEditId}>
          <Box padding="0" margin="0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <EditSidebarHeader title={taxGroup?.name} id={id} setEditId={setEditId}>
                <DeleteButton
                  id={id}
                  name={taxGroup?.name}
                  mutation={DELETE_TAX_GROUP}
                  redirectUrl="/stores/taxes"
                  entity="Tax group"
                  postDeleteFunction={resetForm}
                />
                <PrimaryButton type="submit" handleClick={handleSubmit(onSubmit)} loading={createLoading}>
                  Save
                </PrimaryButton>
              </EditSidebarHeader>
              <Form>
                <EditSidebarSection>
                  <div>
                    <Controller
                      name="name"
                      render={({ field }) => (
                        <Input {...field} type="text" label="Name *" placeholder="Name" errors={errors} />
                      )}
                      defaultValue={taxGroup?.name}
                      control={control}
                      rules={{
                        required: "This is a required field"
                      }}
                    />
                    <Controller
                      name="description"
                      render={({ field }) => (
                        <Input {...field} type="text" label="Description *" placeholder="Description" errors={errors} />
                      )}
                      defaultValue={taxGroup?.description}
                      control={control}
                      rules={{
                        required: "This is a required field"
                      }}
                    />
                  </div>
                </EditSidebarSection>
              </Form>
              <hr />
            </form>
          </Box>
          <EditSidebarSection>
            <PageSectionHeader title="Tax markets" description="Each tax market represents a unique currency." />
            <TaxMarketTable
              id={id || ""}
              taxMarkets={taxMarkets || []}
              refetch={refetchTaxMarkets}
              setEditTaxMarket={setEditTaxMarket}
            />
          </EditSidebarSection>
        </EditSidebar>
      )}
    </>
  )
}

export default UpdateTaxGroup
