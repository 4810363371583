import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { TaxMarket } from "@lib/types/generated/graphql-types"

import DELETE_TAX_MARKET from "../../graphql/mutations/tax/DeleteTaxMarket"

import alertActions from "lib/store/services/Alert/AlertSlice"
import Popup from "components/Ui/Popup"
import ActionButton from "components/Ui/Table/ActionButton"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

type Props = {
  name: string
  taxGroupId: string
  countryCode: string
  refetch: () => void
  icon?: boolean
  setEditTaxMarket?: Dispatch<SetStateAction<TaxMarket | undefined>>
  refetchTaxGroups?: () => void
  refetchTaxMarkets?: () => void
}

const DeleteTaxMarket = ({
  name,
  taxGroupId,
  countryCode,
  refetch,
  icon = false,
  setEditTaxMarket,
  refetchTaxGroups,
  refetchTaxMarkets
}: Props) => {
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()

  const [deleteTaxMarket, { loading }] = useMutation(DELETE_TAX_MARKET, {
    onCompleted: (data) => {
      if (data.deleteTaxMarket.status === "ok") {
        setOpen(false)
        setEditTaxMarket && setEditTaxMarket(undefined)
        refetchTaxMarkets && refetchTaxMarkets()
        refetch()

        if (refetchTaxGroups) return refetchTaxGroups()
        setEditTaxMarket && setEditTaxMarket(undefined)
        dispatch(
          alertActions.actions.createAlert({
            message: `Tax market "${countryCode}" successfully deleted`,
            type: "success"
          })
        )
      } else {
        alertActions.actions.createAlert({
          type: "error"
        })
      }
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Tax market could not be deleted",
          type: "error"
        })
      )
    }
  })

  const onDelete = () => {
    deleteTaxMarket({
      variables: {
        taxGroupId,
        countryCode
      }
    })
  }

  return (
    <>
      {icon ? (
        <ActionButton
          mode="delete"
          handleOnClick={() => {
            setOpen(true)
          }}
        />
      ) : (
        <PrimaryButton
          mode="delete"
          handleClick={() => {
            setOpen(true)
          }}
        >
          Delete
        </PrimaryButton>
      )}
      {open && (
        <Popup
          title="Delete store market"
          subtitle={`Are you sure you want to delete the following store market?`}
          item={{ itemId: name, itemName: countryCode }}
          handleCloseClick={() => setOpen(false)}
          handleOkClick={onDelete}
          type="delete"
          loading={loading}
        />
      )}
    </>
  )
}

export default DeleteTaxMarket
