import styled from "styled-components/macro"

const Custom = styled.li`
  height: 5rem !important;

  svg {
    width: 1.8rem !important;
    height: 1.8rem !important;
    margin-left: -0.2rem;
  }
`

export const Activate = styled(Custom)`
  color: #089d44;

  svg {
    fill: #089d44;
  }
`

export const Deactivate = styled(Custom)`
  color: #e64d4d;

  svg {
    fill: #e64d4d;
  }
`

export const Divider = styled.span`
  margin: 0.5rem 0;
  height: 0.1rem;
  width: 100%;
  background: ${(p) => p.theme.colors.greyLighter};
  display: block;
`
