import React, { Dispatch, SetStateAction, useState } from "react"
import { AdyenEnvironment, AdyenWebhook, AdyenWebhookStatus, InputAdyenConfig } from "lib/types/generated/graphql-types"
import styled from "styled-components/macro"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Maybe } from "@graphql-tools/utils"
import { theme } from "theme"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import DELETE_ADYEN_CONFIG from "graphql/mutations/config/DeleteAdyenConfig"

import TableRow from "components/Ui/Table/TableRow"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"
import { Loader } from "components/Ui/Loader"

import { ReactComponent as Code } from "images/icons/code.svg"
import { ReactComponent as CircleCheck } from "images/icons/circle-check.svg"
import { ReactComponent as CircleExclamation } from "images/icons/circle-exclamation.svg"
import { ReactComponent as AdyenLogo } from "images/providers/adyen-vector-logo.svg"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { CREATE_OR_UPDATE_ADYEN_CONFIG } from "../../../../graphql/mutations/config/CreateOrUpdateAdyenConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import VALIDATE_ADYEN from "../../../../graphql/queries/config/ValidateAdyenConfig"
import Popup from "../../../../components/Ui/Popup"
import AdyenConfigInputs from "../../../../components/Config/Adyen/AdyenConfigInputs"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"
import GET_ADYEN_CONFIG from "../../../../graphql/queries/config/GetAdyenConfig"


const CircleCheckIcon = styled(CircleCheck)`
  fill: ${(p) => p.theme.colors.turquoiseDarker};
`

const CircleExclamationIcon = styled(CircleExclamation)`
  fill: ${(p) => p.theme.colors.error};
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

const Container = styled.div`
  display: flex-row;
`

const WebhookIcon = styled.span`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const WebhookName = styled.div`
  display: flex;
  flex-direction: column;
`

const WebhookId = styled.p`
  margin: 0;
  font-size: 80%;
`

const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: default;
    background: rgba(255, 255, 255, 0.5);
  }
`

interface ExtendedInputAdyenConfig extends InputAdyenConfig {
  id: string
}

type Props = {
  refetch: () => void
  adyenConfigId: string
  setAdyenConfigId: Dispatch<SetStateAction<string>>
}

function EditAdyenConfig({ refetch, adyenConfigId, setAdyenConfigId }: Props) {
  const dispatch = useAppDispatch()
  const [validationError, setValidationError] = useState<boolean>(false)

  const {data} = useQuery(GET_ADYEN_CONFIG, {variables: {id: adyenConfigId}})

  const adyenConfig = data?.getAdyenConfig

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputAdyenConfig>({ mode: "onBlur" })
  const closeSidebar = () => {
    setAdyenConfigId("")
    dispatch(hideEditSidebar())
  }

  const [updateAdyenConfig, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_ADYEN_CONFIG, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Adyen configuration successfully updated",
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const [validateAdyen, { loading: validateLoading }] = useLazyQuery(VALIDATE_ADYEN)

  const getBaseUrl = (type: AdyenEnvironment, liveUrlPrefix: string) =>
    type === AdyenEnvironment.Test
      ? "https://checkout-test.adyen.com"
      : `https://${liveUrlPrefix}-checkout-live.adyenpayments.com/checkout`

  const onSubmit: SubmitHandler<ExtendedInputAdyenConfig> = (data) => {
    validateAdyen({
      variables: {
        baseUrl: getBaseUrl(data.environment, data.liveEndpointUrlPrefix ?? ""),
        merchantAccount: data.merchantAccount,
        xApiKey: data.apiKey,
        environment: data.environment
      }
    }).then((response) => {
      if (!response.data.Configuration.validate.adyen.valid) {
        setValidationError(true)
        return
      }
      updateAdyenConfig({ variables: { ...data, id: adyenConfig.id } })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <EditSidebar cancelEvent={() => closeSidebar()}>
      <EditSidebarHeader
        icon={<AdyenLogo />}
        title={adyenConfig?.merchantAccount}
        id={adyenConfig?.id}
        cancelEvent={() => closeSidebar()}
      >
        <DeleteButton
          id={adyenConfig?.id}
          name={adyenConfig?.merchantAccount}
          entity={"Adyen configuration"}
          redirectUrl="/settings/providers/adyen"
          mutation={DELETE_ADYEN_CONFIG}
          refetch={refetch}
          postDeleteFunction={() => setAdyenConfigId("")}
          secureDelete={true}
        />
        <PrimaryButton
          type="submit"
          loading={validateLoading || createLoading}
        >
          Save
        </PrimaryButton>
      </EditSidebarHeader>
      {validationError ? (
        <Popup
          title="Validation error"
          subtitle="Couldn't validate provided credentials with Adyen."
          buttonText="Ok"
          handleOkClick={() => setValidationError(false)}
          handleCloseClick={() => setValidationError(false)}
        />
      ) : (
        <></>
      )}
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Connected store markets</Tab>
        </TabList>
        <TabPanel>
        <AdyenConfigInputs control={control} errors={errors} setValue={setValue} defaultValues={adyenConfig}/>
          <Webhooks webhooks={adyenConfig.webhooks ?? []} />
        </TabPanel>
        <TabPanel>
          <ConnectedStoreMarket
            providerId={adyenConfig.id}
            providerType={PROVIDER_TYPE.ADYEN}
            providerCategory={PROVIDER_CATEGORY.PAYMENT}
          />
        </TabPanel>
      </Tabs>
    </EditSidebar>
    </form>
  )
}

function Webhooks({ webhooks = [] }: { webhooks: AdyenWebhook[] }) {
  if (!webhooks.length) {
    return null
  }

  return (
    <>
      <Title>Webhooks</Title>
      <Container>
        {webhooks.map((w) => (
          <StyledTableRow key={w.id}>
            <WebhookIcon>
              <Code />
            </WebhookIcon>
            <WebhookName>
              <strong>{w.target}</strong>
              <WebhookId>{w.externalId}</WebhookId>
            </WebhookName>
            <WebhookIcon>{WebhookStatusIcon(w.status)}</WebhookIcon>
          </StyledTableRow>
        ))}
      </Container>
    </>
  )
}

function WebhookStatusIcon(status: Maybe<AdyenWebhookStatus> | undefined) {
  switch (status) {
    case AdyenWebhookStatus.Success:
      return <CircleCheckIcon />
    case AdyenWebhookStatus.Failed:
      return <CircleExclamationIcon />
    default:
      return <Loader color={theme.colors.greyDarker} />
  }
}

export default EditAdyenConfig
