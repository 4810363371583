import React from "react"
import CopyButton from "../../Ui/CopyButton"

import { Wrapper, StyledTableHeader, StyledTableRow, Email } from "./ApiKey.styled"

import { ReactComponent as EmailIcon } from "images/icons/paper-plane.svg"

type Props = {
  sendAs: string
  name: string
  value: string
  icon: React.ReactNode
}

export const ApiKey = ({ sendAs, name, value, icon }: Props) => (
  <Wrapper>
    <StyledTableHeader>
      <div>Send as</div>
      <div>Name</div>
      <div>Value</div>
    </StyledTableHeader>
    <StyledTableRow>
      {icon}

      <div>
        <b>{sendAs}</b>
      </div>
      <div>
        <b>{name}</b>
        <CopyButton string={name} />
      </div>
      <div>
        {value === "Contact Brink support" ? (
          <Email href="mailto:merchantsupport@brinkcommerce.com">
            <b>{value}</b>
            <EmailIcon />
          </Email>
        ) : (
          <>
            <b>{value}</b>
            <CopyButton string={value} />
          </>
        )}
      </div>
    </StyledTableRow>
  </Wrapper>
)
