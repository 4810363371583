import gql from "graphql-tag"

const VALIDATE_RETAIN24 = gql`
  query validateRetain24($baseUrl: String!, $certificate: String!, $certificatePassword: String!) {
    Configuration {
      validate {
        retain24(
          input: {
            baseUrl: $baseUrl
            certificate: $certificate
            certificatePassword: $certificatePassword
          }
        ) {
          errorMessage
          valid
        }
      }
    }
  }
`
export default VALIDATE_RETAIN24
