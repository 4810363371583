import React from "react"

import DineroFactory from "dinero.js"

import {
  ActionWrapper,
  CartItemContainer,
  InfoWrapper,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice
} from "./CartItem.styled"
import Money from "../../../../Money"

import placeholder from "../../../../../images/placeholder.svg"
import { OrderLine, ReleaseOrderLine } from "lib/types/generated/graphql-types"
import { ButtonWrapper } from "../Shared.styled"

type Props = {
  item: ReleaseOrderLine
  currencyCode?: string
  orderLine: OrderLine | undefined
}

const CartItem = ({ item, currencyCode, orderLine, ...props }: Props) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <CartItemContainer {...props}>
      <InfoWrapper>
        <ItemImage>
          <img src={orderLine?.imageUrl ?? ""} onError={handleImageError} />
        </ItemImage>
        <ItemDetails>
          <ItemName>{orderLine?.name}</ItemName>
          <ItemPrice>
            <Money
              amount={Number(item?.totalAmount)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />{" "}
            {item?.totalDiscountAmount && (
              <span>
                <Money
                  amount={Number(item?.totalAmount + item?.totalDiscountAmount)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </span>
            )}
          </ItemPrice>
        </ItemDetails>
      </InfoWrapper>

      <ActionWrapper>
        <span>
          <b>Items to release:</b>
          <span>{item.quantity}</span>
        </span>
        <span>
          <b>Amount to release:</b>{" "}
          <Money amount={item.totalAmount} currencyUnit={currencyCode as DineroFactory.Currency} />
        </span>
        <hr />
        <ButtonWrapper></ButtonWrapper>
      </ActionWrapper>
    </CartItemContainer>
  )
}

export default CartItem
