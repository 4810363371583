import styled from "styled-components/macro"

export const TagConditionContainer = styled.span`
  border: 0.1rem solid ${p => p.theme.colors.borderLight};
  border-radius: 0.6rem;
  width: 100%;
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  b {
    margin: 0.5rem;
  }
`

export const KeyBox = styled.span`
  background: #D6EBF5;
  border-radius: 0.2rem;
  margin: 0.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  font-weight: 700;


  svg {
    height: 1.4rem;
    margin-right: 0.5rem;
  }
`

export const ValueBox = styled.span`
  background: ${p => p.theme.colors.greyLight};
  border-radius: 0.2rem;
  margin: 0.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
`
