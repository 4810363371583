import React, { Dispatch, SetStateAction, useState } from "react"
import {
  BasePrice,
  CloseIcon,
  DiscountInputWrapper,
  Error,
  ErrorText,
  Flex,
  Info,
  Price,
  ProductVariantRowContainer,
  Qty,
  QtyButton,
  Quantity,
  TotalPrice,
  Variant,
  Variants
} from "./ProductVariant.styled"
import {
  Order,
  OrderLine,
  ProductParent,
  ProductVariant,
  TaxMarket
} from "@lib/types/generated/graphql-types"
import placeholder from "../../../../../images/placeholder.svg"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { ReactComponent as MinusIcon } from "images/icons/minus.svg"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { useQuery } from "@apollo/client"
import GET_TAX_MARKETS from "../../../../../graphql/queries/tax/GetTaxMarkets"
import Input from "../../../../Ui/Form/Input"
import GET_PRODUCT from "../../../../../graphql/queries/product/GetProduct"
import { Id } from "../Shared.styled"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  padding?: string
  productVariant: ProductVariant
  updateProductVariant?: (variant: ProductVariant | undefined) => void
  storeGroupId: string
  countryCode?: string
  currencyCode: string
  quantity: number
  setQuantity: Dispatch<SetStateAction<number>>
  discount: number
  setDiscount: Dispatch<SetStateAction<number>>
  setTaxMarket: Dispatch<SetStateAction<TaxMarket | undefined>>
  editOrderLine: OrderLine | undefined
  order: Order
  amountToHigh: boolean
}

const AddedProductVariantRow = ({
  padding,
  updateProductVariant,
  productVariant,
  storeGroupId,
  countryCode,
  currencyCode,
  quantity,
  setQuantity,
  discount,
  setDiscount,
  setTaxMarket,
  editOrderLine,
  amountToHigh
}: Props) => {
  const [productParent, setProductParent] = useState<ProductParent>()
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  if (!productVariant) return null
  const price = productVariant.StoreGroupPrices.find(
    (storeGroupPrice) => storeGroupPrice.storeGroupId === storeGroupId
  )?.marketPrices?.find((marketPrice) => marketPrice.countryCode === countryCode)?.price

  useQuery(GET_PRODUCT, {
    variables: { id: productVariant.productParentId },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (data) {
        setProductParent(data.Product.parent)
      }
    },
    onError: (error) => handleOrderManagementError(error)
  })

  useQuery(GET_TAX_MARKETS, {
    variables: { taxGroupId: productVariant.TaxGroup.id },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      if (data) {
        setTaxMarket(
          data.getTaxMarkets.find((taxMarket: TaxMarket) => taxMarket.countryCode === countryCode)
        )
      }
    },
    onError: (error) => handleOrderManagementError(error)
  })

  const hasDiscount = () =>
    editOrderLine
      ? editOrderLine.salePriceAmount !== editOrderLine.basePriceAmount
      : price?.salePriceAmount !== price?.basePriceAmount

  const getVariantPrice = (productVariant: ProductVariant) =>
    productVariant.StoreGroupPrices.find(
      (storeGroupPrice) => storeGroupPrice.storeGroupId === storeGroupId
    )?.marketPrices?.find((marketPrice) => marketPrice.countryCode === countryCode)?.price

  return (
    <>
      <ProductVariantRowContainer $padding={padding} key={productVariant?.id}>
        <Flex>
          <img
            src={productVariant?.imageUrl ?? ""}
            alt={productVariant?.name}
            onError={handleImageError}
          />
          <Info>
            <div>{productVariant?.name}</div>
            {price && (
              <Price>
                {hasDiscount() && (
                  <BasePrice>
                    <Money
                      amount={editOrderLine ? editOrderLine.basePriceAmount : price.basePriceAmount}
                      currencyUnit={price.currencyCode as DineroFactory.Currency}
                    />
                  </BasePrice>
                )}
                <div>
                  <Money
                    amount={editOrderLine ? editOrderLine.salePriceAmount : price.salePriceAmount}
                    currencyUnit={price.currencyCode as DineroFactory.Currency}
                  />
                </div>
              </Price>
            )}
            <Id>
              <b>Variant ID: </b>
              {productVariant.id}
            </Id>
            {editOrderLine && editOrderLine?.productVariantId !== productVariant.id && (
              <Id>
                <b>Changed from: </b>
                {editOrderLine?.productVariantId}
              </Id>
            )}
            <Quantity>
              <QtyButton
                onClick={() => {
                  if (quantity > 1) setQuantity(quantity - 1)
                }}
              >
                <MinusIcon />
              </QtyButton>{" "}
              <Qty>{quantity}</Qty>
              <QtyButton onClick={() => setQuantity(quantity + 1)}>
                <PlusIcon />
              </QtyButton>
            </Quantity>
          </Info>
        </Flex>

        {updateProductVariant && !editOrderLine && (
          <CloseIcon onClick={() => updateProductVariant(undefined)}>
            <TrashIcon />
          </CloseIcon>
        )}
      </ProductVariantRowContainer>
      <DiscountInputWrapper>
        <Input
          name={"discount"}
          type="number"
          label={"Discount in minor units (optional)"}
          value={discount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDiscount(parseInt(e.currentTarget.value))
          }}
        />
        {discount > 0 && (
          <div>
            Formatted:{" "}
            <b>
              <Money currencyUnit={currencyCode as DineroFactory.Currency} amount={discount || 0} />
              {quantity > 1 && ` (x ${quantity})`}
            </b>
          </div>
        )}
        {isNaN(discount) && <ErrorText>-Missing discount value</ErrorText>}
      </DiscountInputWrapper>
      {price && (
        <>
          <b>Total order line amount: </b>
          <TotalPrice>
            <div>
              <span>Total discount</span> -
              <Money
                currencyUnit={currencyCode as DineroFactory.Currency}
                amount={
                  editOrderLine
                    ? (editOrderLine?.distributedTotalDiscountAmount / editOrderLine.quantity) *
                        quantity +
                      (discount || 0) * quantity
                    : (discount || 0) * quantity
                }
              />
            </div>
            <div>
              <span>Total amount</span>
              <b>
                <Money
                  currencyUnit={currencyCode as DineroFactory.Currency}
                  amount={
                    editOrderLine
                      ? price.basePriceAmount * quantity -
                        (editOrderLine?.distributedTotalDiscountAmount / editOrderLine.quantity) *
                          quantity -
                        (discount || 0) * quantity
                      : price.salePriceAmount * quantity - (discount || 0) * quantity
                  }
                />
              </b>
            </div>
            {amountToHigh && (
              <div>
                <span />
                <Error>Amount to high</Error>
              </div>
            )}
          </TotalPrice>
        </>
      )}
      {productParent?.Variants && productParent?.Variants?.length > 1 && (
        <>
          <b>Change variant within the same parent</b>
          <Variants>
            {productParent?.Variants?.filter((variant) => variant.id !== productVariant.id)
              .filter((variant) => variant?.Stock?.validateStock)
              .map((variant) => (
                <Variant
                  key={variant.id}
                  onClick={() => updateProductVariant && updateProductVariant(variant)}
                >
                  <img
                    src={productVariant?.imageUrl ?? ""}
                    alt={productVariant?.name}
                    onError={handleImageError}
                  />
                  <div>{variant.name}</div>
                  {getVariantPrice(productVariant) && (
                    <Money
                      amount={getVariantPrice(productVariant)?.salePriceAmount ?? 0}
                      currencyUnit={
                        getVariantPrice(productVariant)?.currencyCode as DineroFactory.Currency
                      }
                    />
                  )}
                  <Id>
                    <b>Variant ID:</b> {variant.id}
                  </Id>
                </Variant>
              ))}
          </Variants>
        </>
      )}
    </>
  )
}

export default AddedProductVariantRow
