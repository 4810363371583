import React from "react"
import styled from "styled-components/macro"
import { OrderPaymentSveaCheckout } from "@lib/types/generated/graphql-types"
import { getPaymentMethodDisplayName, getPaymentProviderDisplayName } from "helpers/getPaymentDisplayName"

import Status from "components/Ui/Status"
import { List, Item, Value, Label } from "components/Ui/List/List"

import { ReactComponent as AdyenLogo } from "images/providers/adyen-vector-logo.svg"

const Logo = styled(AdyenLogo)`
  position: absolute;
  height: 2.5rem;
  right: 3rem;
`

type Props = {
  orderPayment: OrderPaymentSveaCheckout
}

const Svea = ({ orderPayment }: Props) => {
  if (!orderPayment) return null

  return (
    <div>
      <List>
        <Logo />
        <Item>
          <Label>Status:</Label>
          <Value>
            <Status status={orderPayment.status.toUpperCase()} />
          </Value>
        </Item>
        <Item>
          <Label>Payment provider:</Label>
          <Value>{getPaymentProviderDisplayName(orderPayment.providerName)}</Value>
        </Item>
        <Item>
          <Label>Payment method:</Label>
          <Value>{getPaymentMethodDisplayName(orderPayment.method)}</Value>
        </Item>
        <Item>
          <Label>Payment reference:</Label>
          <Value>{orderPayment.reference}</Value>
        </Item>
        <Item>
          <Label>Merchant ID:</Label>
          <Value>{orderPayment.sveaMerchantId}</Value>
        </Item>
        <Item>
          <Label>Svea order ID:</Label>
          <Value>{orderPayment.sveaOrderId}</Value>
        </Item>
      </List>
    </div>
  )
}

export default Svea
