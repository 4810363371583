import styled from "styled-components/macro"
import PageHeader from "components/Ui/Page/PageHeader"

export const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 1rem;
`

export const Select = styled.div`
  display: flex;
  gap: 4rem;
  margin: 3rem 0 4rem;
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
`

export const Container = styled.div`
  display: grid;
  width: 100%;

  > div {
    word-break: break-all;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Flex = styled.div`
  display: flex;
  gap: 1rem;

  button {
    flex-shrink: 0;
    height: 4.4rem;
  }
`
