import styled from "styled-components/macro"

import { ReactComponent as ChevronRight } from "images/icons/chevron-right.svg"
import { ReactComponent as ChevronLeft } from "images/icons/chevron-left.svg"

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;
`

export const IconRight = styled(ChevronRight)`
  margin-left: 1rem;
`

export const IconLeft = styled(ChevronLeft)`
  margin-right: 1rem;
`
