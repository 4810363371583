import React from "react"
import PageHeader from "../components/Ui/Page/PageHeader"
import { useAuthenticator } from "@aws-amplify/ui-react"

export const UserError = () => {
  const { user } = useAuthenticator()
  return (
    <>
      <PageHeader group="No access" title="User error" />
      <h3>{`${user.username} don't have access to Merchant portal.`}</h3>
      <p>
        Contact your user administrator to grant access, you must log out and in again for changes
        to take effect.
      </p>
    </>
  )
}
