import React from "react"
import styled from "styled-components/macro"
import { Common } from "@lib/types"

import MultiSelect from "../../Form/MultiSelect"

const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const Icon = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
  setValue?: (value: Common.Option[]) => void | ((e: string) => void)
  options?: Common.Option[] | undefined
  icon?: React.ReactElement
  placeHolder: string
  defaultValue?: Common.Option[]
}
const FilterSection = ({ defaultValue, setValue, options, icon, placeHolder }: Props) => {
  return (
    <Container>
      <Wrapper>
        {icon && <Icon>{icon}</Icon>}
        <MultiSelect
          setValue={setValue}
          options={options ?? []}
          placeholder={placeHolder}
          value={defaultValue}
          overrideDisable={true}
        />
      </Wrapper>
    </Container>
  )
}

export default FilterSection
