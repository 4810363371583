import React from "react"
import { Control, UseFormRegister } from "react-hook-form"
import { InputCreateOrUpdateBundleGroup, Maybe, ValidDateRange } from "@lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import Input from "../../../Ui/Form/Input"
import TimePeriodSelectionBundles from "../TimePeriodSelectionBundles"

type Props = {
  register: UseFormRegister<InputCreateOrUpdateBundleGroup>
  errors: FieldErrors<InputCreateOrUpdateBundleGroup>
  control: Control<InputCreateOrUpdateBundleGroup>
  defaultName?: string
  defaultTimeRange?: Maybe<ValidDateRange> | undefined
}

const GeneralSettings = ({ register, errors, control, defaultName, defaultTimeRange }: Props) => {
  return (
    <div>
      <h2>General settings</h2>
      <Input register={register} name={"name"} errors={errors} label={"Bundle group name"} defaultValue={defaultName ?? ""} />
      <TimePeriodSelectionBundles
        control={control}
        errors={errors}
        description={"Limit bundle group to only be valid between selected dates"}
        defaultValues={defaultTimeRange}
      />
    </div>
  )
}

export default GeneralSettings
