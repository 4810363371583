import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"
import {
  BundleGroupSearchHit,
} from "@lib/types/generated/graphql-types"
import { useNavigate } from "react-router-dom"

import { BundleGroupInfo, Actions } from "./BundleGroupTable.styled"

import { IconTableHeader, EmptyList, Name, Id } from "components/Ui/Table/Shared.styled"

import TableRow from "components/Ui/Table/TableRow"
import ActionButton from "components/Ui/Table/ActionButton"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import Status from "components/Ui/Status"
import { ReactComponent as BundleGroupIcon } from "images/icons/boxes-stacked.svg"
import { ApolloError } from "@apollo/client"
import ErrorMessage from "../../Ui/Messages/ErrorMessage"
import DeleteBundleGroup from "./DeleteBundleGroup"

type Props = {
  loading: boolean
  setBundleGroups: Dispatch<SetStateAction<BundleGroupSearchHit[]>>
  bundleGroups: BundleGroupSearchHit[]
  error: ApolloError | undefined
}

export const BundleGroupTable = ({ bundleGroups, setBundleGroups, loading, error }: Props) => {
  const navigate = useNavigate()

  const tableHeader = (
    <IconTableHeader>
      <BundleGroupInfo>Name</BundleGroupInfo>
      <div>Status</div>
      <div>Valid from</div>
      <div>Valid to</div>
      <Actions></Actions>
    </IconTableHeader>
  )

  const getTableRow = (
    bundleGroup?: BundleGroupSearchHit
  ) => {
    if (bundleGroup)
      return (
        <>
          <TableRow>
            <BundleGroupIcon />
            <BundleGroupInfo>
              <div>
                <Name>{bundleGroup.name} </Name>
                <Id>{bundleGroup.id} </Id>
              </div>
            </BundleGroupInfo>
            <div>
              {bundleGroup.isActive ? <Status status="Active" /> : <Status status="Inactive" />}
            </div>
            <div>
              {bundleGroup.validFrom ? dayjs(bundleGroup.validFrom).format("YYYY-MM-DD") : "-"}
            </div>
            <div>
              {bundleGroup.validTo ? dayjs(bundleGroup.validTo).format("YYYY-MM-DD") : "-"}
            </div>
            <Actions>
              <DeleteBundleGroup
                bundleGroup={bundleGroup}
                bundleGroups={bundleGroups}
                setBundleGroups={setBundleGroups}
              />
            </Actions>
          </TableRow>
        </>
      )
    return (
      <>
        <BundleGroupIcon />
        <BundleGroupInfo>
          <div>
            <Name>Bundle group name</Name>
            <Id>b40c453e-bc09-4d07-b031-825cb4d89b3d</Id>
          </div>
        </BundleGroupInfo>
        <div>
          <Status status="Default" />
        </div>
        <div>2000-00-00</div>
        <div>2000-00-00</div>
        <Actions>
          <ActionButton mode="delete" />
        </Actions>
      </>
    )
  }

  if (loading)
    return <TableBlurLoading numberOfRows={10} tableHeader={tableHeader} rows={getTableRow()} />
  if (error)
    return (
      <ErrorMessage
        showRefreshButton
        message={
          <>
            {`Error loading bundle groups. Try refreshing the page, or contact `}
            <a href="mailto:support@brinkcommerce.com">support</a>.
          </>
        }
      />
    )

  return bundleGroups?.length > 0 ? (
    <>
      {tableHeader}
      {bundleGroups.map(
        (
          bundleGroup: BundleGroupSearchHit
        ) => (
          <div key={bundleGroup.id} onClick={() => navigate(`/discounts/bundle-groups/${bundleGroup.id}`)}>
            {getTableRow(bundleGroup)}
          </div>
        )
      )}
    </>
  ) : (
    <>
      {!loading && (
        <EmptyList>
          <BundleGroupIcon />
          <p> No bundle groups found</p>
        </EmptyList>
      )}
    </>
  )
}
