import React from "react"
import CountUp from "react-countup"

import { Wrapper, IconWrapper, Info, Number } from "./Shared.styled"

type Props = {
  count: number
  text: string
  onClick?: () => void
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const CountCard = ({ count, text, onClick, icon: Icon }: Props) => (
  <Wrapper onClick={onClick}>
    {Icon && (
      <IconWrapper>
        <Icon />
      </IconWrapper>
    )}
    <Info>
      {text}
      <Number>
        <CountUp end={count} duration={1} />
      </Number>
    </Info>
  </Wrapper>
)
