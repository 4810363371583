import React, { Dispatch, SetStateAction } from "react"
import styled from "styled-components/macro"
import Select, { MultiValue } from "react-select"
import { Common } from "@lib/types"
import { isCampaignUser, isSuperUser } from "../../../helpers/user"

interface StyledProps {
  show?: boolean
  invalid?: boolean
}

const Container = styled.div<StyledProps>`
  width: 100%;

  svg {
    margin-right: 0;
  }

  div[class*="-control"] {
    min-height: 4.5rem;
    padding: 0.1rem 0 0 0.8rem;
    border: 0.1rem solid
      ${(p) => (p.invalid ? p.theme.colors.error.background : p.theme.colors.greyLight)};
    border-radius: 0.5rem;
    transition: all 0.2s;
    cursor: pointer;
    box-shadow: 0 0 0.5rem 0
      ${(p) => (!p.invalid ? "transparent" : p.theme.colors.error.background)};

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      &:hover,
      &:focus {
        border: 0.1rem solid ${(p) => p.theme.colors.turquoiseDark};
      }
    }

    div[class*="-multiValue"] {
      padding: 0 0.5rem 0 0.5rem;
      background: ${(p) => p.theme.colors.greyLighter};
      display: flex;
      align-items: center;
      margin: 0 0.7rem 0 0;
      height: 2.8rem;
    }

    div[class*="-ValueContainer"] {
      padding-left: 0;
    }

    div[class*="-MultiValueRemove"] {
      svg {
        fill: ${(p) => p.theme.colors.black};
      }

      &:hover {
        background: none;

        svg {
          fill: ${(p) => p.theme.colors.turquoiseDark};
        }
      }
    }

    div[class*="-placeholder"] {
      margin-left: 1.2rem;
      color: ${(p) => p.theme.colors.greyDarker};
    }
  }
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

const ErrorMessage = styled.p<StyledProps>`
  position: relative;
  padding: 0 1.2rem 0 0.5rem;
  font-size: 1.2rem;
  color: ${(p) => p.theme.colors.error.background} !important;
  letter-spacing: 0.02rem;
  transition: opacity 0.2s;
  opacity: ${(p) => (p.show ? "1" : "0")};
  margin: 0;
`

const allValue = "all"

type Props = {
  setValue?: Dispatch<SetStateAction<any>> // eslint-disable-line
  options: Common.Option[] | []
  defaultValue?: Common.Option[] | undefined
  placeholder?: string
  onInputChange?: (event: string) => void
  loading?: boolean
  returnValueOnly?: boolean
  invalid?: boolean
  label?: string | JSX.Element
  value?: Common.Option[]
  disabled?: boolean
  overrideDisable?: boolean
  selectAllEnabled?: boolean
}
// eslint-disable-next-line no-use-before-define
const MultiSelect: React.FC<Props> = React.forwardRef<any, Props>( // eslint-disable-line
  (
    {
      options,
      setValue,
      defaultValue,
      placeholder,
      onInputChange,
      loading,
      returnValueOnly,
      invalid = false,
      label,
      disabled,
      value,
      overrideDisable,
      selectAllEnabled = false
    }: Props,
    ref
  ) => {
    const isDisabled = () => {
      if (disabled) return disabled
      if (overrideDisable || isCampaignUser() || isSuperUser()) return false
      return !isSuperUser()
    }

    const selectOptions = selectAllEnabled
      ? [{ value: allValue, label: "Select all" }, ...options]
      : options

    const handleOnChange = (selected: MultiValue<Common.Option>) => {
      if (selected[selected.length - 1]?.value === allValue) {
        return setValue && setValue(options)
      }
      if (returnValueOnly) {
        return setValue && setValue(selected.map((item) => item.value))
      }
      return setValue && setValue(selected)
    }

    return (
      <Container invalid={invalid}>
        {label && (
          <Label>
            {label}
            <ErrorMessage show={invalid}> - Please select at least one option</ErrorMessage>
          </Label>
        )}
        <Select
          defaultValue={defaultValue}
          value={value}
          isMulti
          ref={ref}
          onChange={(e) => handleOnChange(e)}
          onInputChange={onInputChange}
          options={selectOptions}
          placeholder={placeholder}
          isDisabled={isDisabled()}
          isLoading={loading || false}
        />
      </Container>
    )
  }
)

export default MultiSelect
