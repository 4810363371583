import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputSveaCheckoutConfig } from "lib/types/generated/graphql-types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_OR_UPDATE_SVEA_CHECKOUT_CONFIG from "graphql/mutations/config/CreateOrUpdateSveaCheckoutConfig"
import VALIDATE_SVEA from "graphql/queries/config/ValidateSveaConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"
import { SveaConfigInputs } from "../../../../components/Config/Svea/SveaConfigInputs"


type Props = {
  refetch: () => void
}

interface ExtendedInputSveaCheckoutConfig extends InputSveaCheckoutConfig {
  id: string
}

function CreateSveaConfig({ refetch }: Props) {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputSveaCheckoutConfig>({ mode: "onBlur" })

  const [addSveaConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_SVEA_CHECKOUT_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          merchantId: "",
          apiKey: ""
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Svea Checkout config successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateSvea, { loading }] = useLazyQuery(VALIDATE_SVEA)

  const onSubmit: SubmitHandler<ExtendedInputSveaCheckoutConfig> = (data) => {
    validateSvea({
      variables: {
        baseUrl: data.checkoutBaseUrl,
        merchantId: data.merchantId,
        checkoutSecret: data.apiKey
      }
    }).then((response) => {
      if (response.data.Configuration.validate.sveaCheckout.valid) {
        addSveaConfig({ variables: { ...data } })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputSveaCheckoutConfig> = (data) => {
    addSveaConfig({ variables: { ...data } })
    setValidationError(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Svea Checkout configuration">
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Svea. Do you want to save the Svea checkout config anyway? "
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <SveaConfigInputs control={control} errors={errors} setValue={setValue} showId={true} />
      </EditSidebar>
    </form>
  )
}

export default CreateSveaConfig
