import React, { Dispatch, SetStateAction } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Input from "../../../components/Ui/Form/Input"
import PrimaryButton from "../../../components/Ui/Buttons/PrimaryButton"
import CREATE_OR_UPDATE_TAX_GROUP from "../../../graphql/mutations/tax/CreateOrUpdateTaxGroup"
import { useMutation } from "@apollo/client"
import Box from "../../../components/Ui/Box"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"
import EditSidebarHeader from "../../../components/Ui/EditSidebar/EditSidebarHeader"
import EditSidebar from "../../../components/Ui/EditSidebar/EditSidebar"
import { InputTaxGroup } from "@lib/types/generated/graphql-types"


type Props = {
  setEditId: SetStateAction<Dispatch<string>>
  refetch: () => void
}

interface FullInputTaxGroup extends InputTaxGroup {
  id: string;
}

export function CreateTaxGroup({ setEditId, refetch }: Props) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FullInputTaxGroup>()
  const dispatch = useAppDispatch()

  const [addTaxGroup, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_TAX_GROUP, {
    onCompleted: (response) => {
      setEditId(response.createOrUpdateTaxGroup.id)
      dispatch(
        alertActions.actions.createAlert({
          message: "Tax Group successfully created",
          type: "success"
        })
      )
      reset({
        id: "",
        name: "",
        description: ""
      })
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<FullInputTaxGroup> = (data) =>
    addTaxGroup({
      variables: { ...data, id: data.id.replaceAll(" ", "") }
    })

  return (
    <EditSidebar>
      <Box padding="0" margin="0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditSidebarHeader title="New tax group">
            <PrimaryButton type="submit" loading={createLoading}>
              Save
            </PrimaryButton>
          </EditSidebarHeader>
          <Controller
            name="id"
            render={({ field }) => <Input {...field} label="Identifier *" type="text" placeholder="Identifier" errors={errors} />}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="name"
            render={({ field }) => <Input {...field} label="Name *" type="text" placeholder="Name" errors={errors} />}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
          <Controller
            name="description"
            render={({ field }) => <Input {...field} label="Description *" type="text" placeholder="Description" errors={errors} />}
            defaultValue=""
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
        </form>
      </Box>
    </EditSidebar>
  )
}
