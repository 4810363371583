import React, { MutableRefObject, useRef, useState } from "react"
import { v4 as uuid } from "uuid"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Common } from "@lib/types"
import { useQuery } from "@apollo/client"
import {
  StoreGroup as StoreGroupType,
  StoreMarket as StoreMarketType
} from "@lib/types/generated/graphql-types"
import { getCurrency } from "helpers/currency"
import { Conditions, FrontendCondition, LogicState } from "@lib/types/discount"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import { ConditionSelect } from "../Ui/ConditionSelect"
import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import Threshold from "./Conditions/Threshold"
import MinItems from "./Conditions/MinItems"
import StoreGroup from "./Conditions/StoreGroup"
import CountryCode from "./Conditions/CountryCode"
import MinItemsValue from "./Conditions/MinItemsValue"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

type Props = {
  listId: string
  condition?: FrontendCondition
  setState: any // eslint-disable-line
}

const EditCondition = ({ listId, condition, setState }: Props) => {
  const dispatch = useAppDispatch()
  const [conditionType, setConditionType] = useState<string>()
  const submitRef = useRef() as MutableRefObject<HTMLButtonElement>
  const [currencyOptions, setCurrencyOptions] = useState<Common.Option[]>([])

  const updateConditionType = (conditionType: Common.Option) =>
    setConditionType(conditionType?.value)

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      const storeMarkets = data.getStoreGroups
        .map((storeGroup: StoreGroupType) => storeGroup.storeMarkets)
        .flat()
      const currencyCodes = storeMarkets.map(
        (storeMarket: StoreMarketType) => storeMarket.currencyCode
      )
      const uniqueCurrencyCodes = [...new Set(currencyCodes)] as string[]
      setCurrencyOptions(
        uniqueCurrencyCodes.map((code: string) => ({
          value: code,
          label: getCurrency(code).currency
        }))
      )
    }
  })

  const addCondition = (data: Conditions) => {
    setState((prev: LogicState) => ({
      ...prev,
      [listId]: {
        ...prev[listId],
        conditions: condition?.id
          ? prev[listId].conditions.map((c: FrontendCondition) =>
              c.id === condition.id
                ? {
                    ...c,
                    data: {
                      type: conditionType,
                      ...data
                    }
                  }
                : c
            )
          : prev[listId].conditions.length !== 0
          ? [
              ...prev[listId].conditions,
              {
                id: uuid(),
                title: "AND",
                type: "operator"
              },
              {
                id: uuid(),
                title: "Condition",
                type: "condition",
                data: {
                  type: conditionType,
                  ...data
                }
              }
            ]
          : [
              {
                id: uuid(),
                title: "Condition",
                type: "condition",
                data: {
                  type: conditionType,
                  ...data
                }
              }
            ]
      }
    }))
    dispatch(hideEditSidebar())
  }

  const renderForm = () => {
    switch (conditionType) {
      case "CARTOVERTHRESHOLD":
      case "CARTUNDERTHRESHOLD":
        return (
          <Threshold
            condition={condition}
            addCondition={addCondition}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case "MINITEMSVALUE":
        return (
          <MinItemsValue
            condition={condition}
            addCondition={addCondition}
            currencyOptions={currencyOptions}
            submitRef={submitRef}
          />
        )
      case "MINITEMS":
        return <MinItems condition={condition} addCondition={addCondition} submitRef={submitRef} />
      case "STOREGROUP":
        return (
          <StoreGroup condition={condition} addCondition={addCondition} submitRef={submitRef} />
        )
      case "COUNTRYCODE":
        return (
          <CountryCode condition={condition} addCondition={addCondition} submitRef={submitRef} />
        )
      default:
        return null
    }
  }

  return (
    <EditSidebar>
      <EditSidebarHeader
        title={condition?.data ? "Edit condition" : "Add condition"}
        id={condition?.id}
      >
        <PrimaryButton type="button" handleClick={() => submitRef.current.click()}>
          Save
        </PrimaryButton>
      </EditSidebarHeader>
      <ConditionSelect
        disabled={condition?.data ? true : false}
        setValue={updateConditionType}
        defaultValue={condition?.data?.type}
      />
      <>{conditionType && renderForm()}</>
    </EditSidebar>
  )
}

export default EditCondition
