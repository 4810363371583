import { AwsRum, AwsRumConfig } from "aws-rum-web"

export const AwsRumClient = (rumAppMonitorId: string, rumIdentityPool: string, awsRegion: string): AwsRum => {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: rumIdentityPool,
    endpoint: `https://dataplane.rum.${awsRegion}.amazonaws.com`,
    telemetries: ["errors"],
    allowCookies: true,
    enableXRay: false
  }

  const APPLICATION_ID = rumAppMonitorId
  const APPLICATION_VERSION = "1.0.0"
  const APPLICATION_REGION = awsRegion

  return new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
}
