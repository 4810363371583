import React from "react"
import { KeyBox, TagConditionContainer, ValueBox } from "./TagConditionRow.styled"
import { InputTagCondition, InputTagConditionMode } from "lib/types/generated/graphql-types"
import { ReactComponent as TagsIcon } from "images/icons/tags.svg"

type Props = {
  tagConditions: InputTagCondition[]
}

const TagConditions = ({ tagConditions }: Props) => {
  const getModeLabel = (mode: InputTagConditionMode) => {
    if (mode === InputTagConditionMode.Exclude) return "Exclude"
    return "Include"
  }

  return (
    <>
      {tagConditions.map((tagCondition) => (
        <TagConditionContainer key={tagCondition.key}>
          <b>{getModeLabel(tagCondition.mode)}</b>
          <KeyBox><TagsIcon/>{tagCondition.key}</KeyBox>
          {tagCondition.values.map((value) => (<ValueBox key={value}>{value}</ValueBox>))}
        </TagConditionContainer>
      ))}
    </>
  )
}
export default TagConditions
