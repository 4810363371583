import React, { Dispatch, SetStateAction } from "react"
import {Container, Wrapper } from "./InputOrderReason.styled"
import Input from "../../../../Ui/Form/Input"
import { InputReason } from "@lib/types/generated/graphql-types"


type Props = {
  reason: InputReason
  setReason: Dispatch<SetStateAction<InputReason>>
}

const InputOrderReason = ({ reason, setReason }: Props) => {
  const updateCause = (cause: string) => setReason({ ...reason, cause: cause })
  const updateCode = (code: string) => setReason({ ...reason, code: code })

  return (
    <Wrapper>
        <Container>
            <>
              <Input
                name={"cause"}
                placeholder={"Cause"}
                removePaddingBottom={true}
                value={reason.cause}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateCause(e.currentTarget.value)
                }
              />
              <Input
                name={"code"}
                value={reason.code}
                placeholder={"Code"}
                removePaddingBottom={true}
                onChange={(e: React.FormEvent<HTMLInputElement>) => updateCode(e.currentTarget.value)}
              />
            </>
        </Container>
    </Wrapper>
  )
}

export default InputOrderReason
