import styled from "styled-components/macro"

type StyledProps = {
  $active: boolean
}


export const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
  button {
    margin: 0 0.5rem;
    width: 25rem;
  }
`

export const PercentageWrapper = styled.div`
margin-top: 3rem
`

export const Container = styled.div<StyledProps>`
  border-left: 0.3rem solid ${(p) => p.$active ? p.theme.colors.turquoiseDark : p.theme.colors.greyDark};
  padding: 0 0 1rem 1rem;

div {
  gap: 1rem;
}

`

