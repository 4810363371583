import gql from "graphql-tag"

const GET_REFUND = gql`
  query getRefund($refundId: ID!) {
    Order {
      refund(refundId: $refundId) {
        id
        started
        completed
        failed
        created
        updated
        restarts
        restarted
        giftCardProvider {
          providerName
          providerId
          status {
            current
          }
        }
        paymentProvider {
          providerName
          providerId
          status {
            current
          }
        }
        fee {
          amount
          name
          taxPercentage
          taxPercentageDecimals
        }
        bonusProvider {
          providerName
          providerId
          status {
            current
          }
        }
        giftCards {
          amount
          currencyCode
          giftCardId
          status
        }
        shippingFees {
          currencyCode
          shippingFeeId
          taxPercentage
          taxPercentageDecimals
          totalAmount
          totalDiscountAmount
          totalTaxAmount
        }
        bonus {
          reservationId
          currencyCode
          amount
        }
        reason {
          cause
          code
        }
        refundedPayment {
          reference
        }
        voucherProvider {
          providerName
          providerId
        }
        vouchers {
          voucherId
          amount
        }
        gifts {
          giftId
          quantity
        }
        totals {
          subTotal
          shippingTotal
          giftCardTotal
          bonusTotal
          grandTotal
        }
        orderLines {
          currencyCode
          orderLineId
          quantity
          taxPercentage
          taxPercentageDecimals
          totalAmount
          totalDiscountAmount
          totalTaxAmount
        }
      }
    }
  }
`

export default GET_REFUND
