import React from "react"
import styled from "styled-components/macro"
import { v4 as uuidv4 } from "uuid"
import DineroFactory from "dinero.js"

import {
  OrderGiftCardItemBsgGiftCard,
  OrderGiftCardItemRetain24,
  OrderGiftCardItemKbsGiftCard,
  OrderGiftCardBsgGiftCard,
  OrderGiftCardKbsGiftCard,
  OrderGiftCardRetain24
} from "@lib/types/generated/graphql-types"

import Status from "components/Ui/Status"
import OrderBlock from "components/Order/OrderBlock"
import { List, Item, Value, Label } from "components/Ui/List/List"
import Money from "components/Money"

import { ReactComponent as CardIcon } from "images/icons/gift-card.svg"
import { ReactComponent as Icon } from "images/icons/gift.svg"

const Columns = styled.div`

`

type Props = {
  orderGiftcard: OrderGiftCardBsgGiftCard | OrderGiftCardKbsGiftCard | OrderGiftCardRetain24
}

const Giftcard = ({ orderGiftcard }: Props) => {
  if (!orderGiftcard) return null

  return (
    <Columns>
      <OrderBlock>
        <h2>
          <Icon />
          Gift card provider
        </h2>
        <List>
          {orderGiftcard.providerName ? (
            <>
              <Item>
                <Label>Provider name:</Label>
                <Value>{orderGiftcard.providerName}</Value>
              </Item>
              <Item>
                <Label>Provider ID:</Label>
                <Value>{orderGiftcard.providerId}</Value>
              </Item>
              <Item>
                <Label>Status:</Label>
                <Value>
                  <Status status={orderGiftcard.status} />
                </Value>
              </Item>
            </>
          ) : (
            <p>No gift card(s) used for this order.</p>
          )}
        </List>
      </OrderBlock>
      <OrderBlock>
        <h2>
          <CardIcon />
          Gift card(s) used
        </h2>
        {orderGiftcard.giftCards ? (
          <>
            {orderGiftcard.giftCards.map(
              (
                giftcard:
                  | OrderGiftCardItemBsgGiftCard
                  | OrderGiftCardItemKbsGiftCard
                  | OrderGiftCardItemRetain24
              ) => (
                <List key={uuidv4()}>
                  <>
                    <Item>
                      <Label>ID:</Label>
                      <Value>{giftcard.id}</Value>
                    </Item>
                    <Item>
                      <Label>Status:</Label>
                      <Value>
                        <Status status={giftcard.status} />
                      </Value>
                    </Item>
                    <Item>
                      <Label>Reservation ID:</Label>
                      <Value>{giftcard.reservationId}</Value>
                    </Item>
                    <Item>
                      <Label>Amount:</Label>
                      <Value>
                        <Money
                          amount={parseInt(`${giftcard.amount}`)}
                          currencyUnit={giftcard.currencyCode as DineroFactory.Currency}
                        />
                      </Value>
                    </Item>

                    {giftcard.failedReason ? (
                      <Item>
                        <Label>Failed reason:</Label>
                        <Value>
                          <Status status={giftcard.failedReason} />
                        </Value>
                      </Item>
                    ) : (
                      <></>
                    )}
                  </>
                </List>
              )
            )}
          </>
        ) : (
          <List>
            <p>No gift card(s) used for this order.</p>
          </List>
        )}
      </OrderBlock>
    </Columns>
  )
}

export default Giftcard
