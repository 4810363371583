import React, { ReactNode } from "react"

import {
  Container,
  Header,
  Content,
  Actions,
  EditButton,
  DeleteButton,
  Footer
} from "./TableRow.styled"

import PrimaryButton from "../Buttons/PrimaryButton"

import { ReactComponent as EditIcon } from "images/icons/gear.svg"
import { ReactComponent as DeleteIcon } from "images/icons/trash-can.svg"

type Props = {
  children: ReactNode | ReactNode[]
  title?: string
  subtitle?: ReactNode
  isInactive?: boolean
  wrapContent?: boolean
  editEnabled?: boolean
  deleteEnabled?: boolean
  deleteInHeader?: boolean
  smallButtons?: boolean
  disableHover?: boolean
  handleOnClick?: React.MouseEventHandler
  handleDelete?: React.MouseEventHandler
}

const TableRow = ({
  children,
  title,
  subtitle,
  isInactive = false,
  wrapContent = false,
  editEnabled,
  deleteEnabled,
  deleteInHeader = false,
  smallButtons = false,
  disableHover = false,
  handleOnClick,
  handleDelete,
  ...props
}: Props) => {
  return (
    <Container
      {...props}
      isInactive={isInactive}
      disableHover={disableHover}
      onClick={handleOnClick}
    >
      <>
        {(title || deleteInHeader) && (
          <Header>
            <h4>{title}</h4>
            {deleteInHeader && (
              <PrimaryButton handleClick={handleDelete} type="button" mode="delete">
                Remove
              </PrimaryButton>
            )}
          </Header>
        )}
        <Content wrapContent={wrapContent}>
          {children}
          {(editEnabled || deleteEnabled) && (
            <Actions>
              {editEnabled && (
                <EditButton type="button" title="Èdit" smallButtons={smallButtons}>
                  <EditIcon />
                </EditButton>
              )}
              {deleteEnabled && !deleteInHeader && (
                <DeleteButton
                  type="button"
                  title="Delete"
                  smallButtons={smallButtons}
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </DeleteButton>
              )}
            </Actions>
          )}
        </Content>
        {subtitle && (
          <>
            <Footer>{subtitle}</Footer>
          </>
        )}
      </>
    </Container>
  )
}

export default TableRow
