import React from "react"
import { Blur, Container, Dates, ExpandIcon, Footer, TotalTitle } from "./Shared.styled"
import dayjs from "dayjs"
import Money from "../../Money"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"

const LoadingRow = () => (
  <Blur>
  <Container>
    <h3>Delivery #id-12342356789</h3>
    <Dates>
      <div>
        Started: <b>{dayjs().format("YYYY-MM-DD : HH:mm:ss")}</b>
      </div>
      <div>
        Last updated: <b>{dayjs().format("YYYY-MM-DD : HH:mm:ss")}</b>
      </div>
        <div>
          Completed: <b>{dayjs().format("YYYY-MM-DD : HH:mm:ss")}</b>
        </div>
      <div>
        Order lines: <b>{1}</b>
      </div>
    </Dates>
    <Footer>
      <div>
        <TotalTitle>Total:</TotalTitle>
        <TotalTitle>
          <Money
            amount={10000}
            currencyUnit={"SEK"}
          />
        </TotalTitle>
      </div>
    </Footer>
    <ExpandIcon $expanded={false}>
      <DownIcon />
    </ExpandIcon>
  </Container>
  </Blur>
)

export default LoadingRow
