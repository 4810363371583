import React from "react"

import { Default, Error, Success, Waiting } from "./Status.styled"

type Props = {
  status: string
}

const Status = ({ status }: Props) => {
  switch (status) {
    case "COMPLETED":
    case "Completed":
    case "completed":
    case "SUCCESS":
    case "ACCEPTED":
    case "AUTHORIZED":
    case "RESERVED":
    case "ACTIVE":
    case "Active":
    case "ENABLED":
    case "Enabled":
    case "NOT ARCHIVED":
    case "No":
    case "REDEEMED":
    case "CONFIRMED":
    case "Confirmed":
    case "Verified":
    case "Provided":
    case "Required":
    case "PURCHASECOMPLETED":
    case "Include":
    case "In stock":
    case "REFUND_GIFT_CARD_SUCCESS":
    case "REFUND_BONUS_SUCCESS":
    case "REFUND_PAYMENT_SUCCESS":
    case "CANCEL_GIFT_CARD_SUCCESS":
    case "CANCEL_BONUS_SUCCESS":
    case "CANCEL_PAYMENT_SUCCESS":
    case "CAPTURE_PAYMENT_SUCCESS":
    case "COMPENSATION_PAYMENT_SUCCESS":
    case "COMPENSATION_BONUS_SUCCESS":
    case "COMPENSATION_GIFT_CARD_SUCCESS":
    case "REDEEM_BONUS_SUCCESS":
    case "REDEEM_GIFT_CARD_SUCCESS":
    case "TRACK_SHIPMENT_SUCCESS":
    case "RELEASE_PAYMENT_SUCCESS":
    case "RELEASE_BONUS_SUCCESS":
    case "RELEASE_GIFT_CARD_SUCCESS":
    case "Delivered":
    case "Refunded":
    case "Compensated":
    case "Released":
    case "SKIPPED":
    case "MANUAL":
    case "Placed":
    case "Included":
    case "CREATED":
    case "Updated":
    case "Created":
      return <Success>{status}</Success>
    case "RUNNING":
    case "FORCE_CHANGE_PASSWORD":
    case "Refunded in part":
    case "Delivered in part":
    case "Compensated in part":
    case "NOT CONFIRMED":
      return <Waiting>{status}</Waiting>
    case "ERROR":
    case "REDEEM_FAILED":
    case "Yes":
    case "NOT ACTIVE":
    case "Inactive":
    case "RESERVATION_FAILED":
    case "ARCHIVED":
    case "ABORTED":
    case "DISABLED":
    case "Disabled":
    case "Not verified":
    case "Not provided":
    case "Not required":
    case "refused":
    case "Refused":
    case "Exclude":
    case "Out of stock":
    case "Missing stock data":
    case "REFUND_BONUS_FAILURE":
    case "REFUND_GIFT_CARD_FAILURE":
    case "REFUND_PAYMENT_FAILURE":
    case "CANCEL_BONUS_FAILURE":
    case "CANCEL_GIFT_CARD_FAILURE":
    case "CANCEL_PAYMENT_FAILURE":
    case "CAPTURE_PAYMENT_FAILURE":
    case "COMPENSATION_PAYMENT_FAILURE":
    case "COMPENSATION_GIFT_CARD_FAILURE":
    case "COMPENSATION_BONUS_FAILURE":
    case "TRACK_SHIPMENT_FAILURE":
    case "REDEEM_GIFT_CARD_FAILURE":
    case "REDEEM_BONUS_FAILURE":
    case "RELEASE_PAYMENT_FAILURE":
    case "RELEASE_BONUS_FAILURE":
    case "RELEASE_GIFT_CARD_FAILURE":
    case "Cancelled":
    case "Failed":
    case "Deleted":
      return <Error>{status}</Error>

    default:
      return <Default>{status}</Default>
  }
}

export default Status
