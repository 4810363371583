import React from "react"
import styled from "styled-components/macro"
import { getCountryName } from "helpers/countries"
import dayjs from "dayjs"
import DineroFactory from "dinero.js"
import * as graphql from "@lib/types/generated/graphql-types"
import { useQuery } from "@apollo/client"

import GET_STORE_GROUP from "graphql/queries/store/GetStoreGroup"

import Money from "components/Money"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import FlagIcon from "components/Ui/FlagIcon"
import DotLoader from "components/Ui/DotLoader"
import CopyButton from "components/Ui/CopyButton"

import { ReactComponent as Icon } from "images/icons/magnifying-glass.svg"
import { ReactComponent as TotalsIcon } from "images/icons/circle-dollar.svg"
import { ReactComponent as HistoryIcon } from "images/icons/rectangle-vertical-history.svg"
import OrderHistory from "../../../../components/Order/OrderHistory"
import Status from "../../../../components/Ui/Status"

const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }
`

const OrderValue = styled(Item)`
  margin-top: 0;
`

const Total = styled(Item)`
  font-size: 1.6rem;
`

const StateStatus = styled.div`
  margin-right: 1rem;
`

type Props = {
  order: graphql.Order
  orderHistory: graphql.OrderHistory
}

const OrderOverview = ({ order, orderHistory }: Props) => {
  const { data, loading } = useQuery(GET_STORE_GROUP, {
    fetchPolicy: "cache-first",
    variables: { id: order?.storeGroupId }
  })

  const getFormattedState = (state: string) => {
    switch (state) {
      case "FULLY_DELIVERED":
        return "Delivered"
      case "PARTIALLY_DELIVERED":
        return "Delivered in part"
      case "PARTIALLY_REFUNDED":
        return "Refunded in part"
      case "FULLY_REFUNDED":
        return "Refunded"
      case "FULLY_COMPENSATED":
        return "Compensated"
      case "PARTIALLY_COMPENSATED":
        return "Compensated in part"
      case "CANCELLED":
        return "Cancelled"
      case "PLACED":
        return "Placed"
      case "RELEASED":
        return "Released"
      default:
        return state
    }
  }
  if (!order) return null
  return (
    <>
      <Columns>
        <OrderBlock>
          <h2>
            <Icon />
            Overview
          </h2>
          <List>
            {order.status && (
              <Item>
                <Label>Status:</Label>
                <Value>
                  {order.status.orderStates.map((state) => (
                    <StateStatus key={state}>
                      <Status status={getFormattedState(state)} />
                    </StateStatus>
                  ))}
                </Value>
              </Item>
            )}
            <Item>
              <Label>Reference:</Label>
              <Value>
                <>
                  {order.reference}
                  <CopyButton string={order.reference} />
                </>
              </Value>
            </Item>
            {order?.attributes?.merchantReference1 ? (
              <Item>
                <Label>Merchant reference 1:</Label>
                <Value>
                  <>
                    {order?.attributes?.merchantReference1} <CopyButton string={order?.attributes?.merchantReference1} />
                  </>
                </Value>
              </Item>
            ) : (
              <></>
            )}
            {order?.attributes?.merchantReference2 ? (
              <Item>
                <Label>Merchant reference 2:</Label>
                <Value>
                  <>
                    {order?.attributes?.merchantReference2} <CopyButton string={order?.attributes?.merchantReference2} />
                  </>
                </Value>
              </Item>
            ) : (
              <></>
            )}
            <Item>
              <Label>Order date:</Label>
              <Value>{dayjs(order.date).format("YYYY-MM-DD HH:mm")}</Value>
            </Item>
            <Item flexibleHeight>
              <Label>E-mail:</Label>
              <Value>
                <>
                  {order.billingAddress.email}
                  <CopyButton string={order.billingAddress.email} />
                </>
              </Value>
            </Item>
            <Item>
              <Label>Store group:</Label>
              <Value>{loading ? <DotLoader /> : data?.getStoreGroup?.name}</Value>
            </Item>
            <Item>
              <Label>Store market:</Label>
              <Value flex>
                <>
                  <FlagIcon countryCode={order.countryCode} />
                  {getCountryName(order.countryCode)}
                </>
              </Value>
            </Item>
          </List>
        </OrderBlock>
        <OrderBlock>
          <h2>
            <TotalsIcon />
            Order totals
          </h2>
          <List>
            <OrderValue>
              <Label>Order value:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${order.totals?.subTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </OrderValue>
            <Item>
              <Label>Discount amount:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${-order.totals?.discountTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Item>
            {order.totals?.giftCardTotal > 0 ? (
              <Item>
                <Label>Gift card amount:</Label>
                <Value alignRight>
                  <Money
                    amount={parseInt(`${-order.totals?.giftCardTotal}`)}
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
            ) : (
              <></>
            )}
            {order.totals?.bonusTotal > 0 ? (
              <Item>
                <Label>Bonus amount:</Label>
                <Value alignRight>
                  <Money
                    amount={parseInt(`${-order.totals?.bonusTotal}`)}
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
            ) : (
              <></>
            )}

            {order.totals?.shippingTotal > 0 ? (
              <Item>
                <Label>Shipping cost:</Label>
                <Value alignRight>
                  <Money
                    amount={order.totals?.shippingTotal}
                    currencyUnit={order.currencyCode as DineroFactory.Currency}
                  />
                </Value>
              </Item>
            ) : (
              <></>
            )}
            <Item>
              <Label>
                <>
                  Tax:
                  {order.isTaxIncludedInPrice && " (Included in price)"}
                </>
              </Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${order.totals?.taxTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Item>
            <Total>
              <Label>Total order value:</Label>
              <Value alignRight>
                <Money
                  amount={parseInt(`${order.totals?.grandTotal}`)}
                  currencyUnit={order.currencyCode as DineroFactory.Currency}
                />
              </Value>
            </Total>
          </List>
        </OrderBlock>
      </Columns>
      {orderHistory.history.length > 0 && (
        <>
          <br />
          <OrderBlock>
            <h2>
              <HistoryIcon />
              History
            </h2>
            <OrderHistory
              orderHistory={orderHistory}
              currencyCode={order.currencyCode}
              order={order}
            />
          </OrderBlock>
        </>
      )}
    </>
  )
}

export default OrderOverview
