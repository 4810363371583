import React, { Dispatch, SetStateAction, useState } from "react"
import { useMutation } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { BundleGroupSearchHit } from "@lib/types/generated/graphql-types"

import alertActions from "lib/store/services/Alert/AlertSlice"
import Popup from "components/Ui/Popup"
import ActionButton from "components/Ui/Table/ActionButton"
import DELETE_BUNDLE_GROUP from "../../../graphql/mutations/bundles/DeleteBundleGroup"

type Props = {
  bundleGroup?: BundleGroupSearchHit
  bundleGroups: BundleGroupSearchHit[]
  setBundleGroups: Dispatch<SetStateAction<BundleGroupSearchHit[]>>
}

const DeleteBundleGroup = ({ bundleGroup, bundleGroups, setBundleGroups }: Props) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [selectedDeleteBundleGroup, setSelectedDeleteBundleGroup] = useState<BundleGroupSearchHit | undefined>()
  const dispatch = useAppDispatch()

  const [deleteBundleGroup, { loading: deleteLoading }] = useMutation(DELETE_BUNDLE_GROUP, {
    onCompleted: () => {
      setOpenPopup(false)
      setBundleGroups(
        bundleGroups.filter(
          (bundleGroup: BundleGroupSearchHit) => bundleGroup.id !== selectedDeleteBundleGroup?.id
        )
      )
      dispatch(
        alertActions.actions.createAlert({
          message: `Bundle group successfully deleted`,
          type: "success"
        })
      )
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })
  const onDelete = () => {
    deleteBundleGroup({
      variables: {
        id: selectedDeleteBundleGroup?.id
      }
    })
  }

  return (
    <>
      <ActionButton
        mode="delete"
        handleOnClick={(e) => {
          e.stopPropagation()
          setSelectedDeleteBundleGroup(bundleGroup)
          setOpenPopup(true)
        }}
      />
      {openPopup && (
        <Popup
          title="Delete bundle group"
          subtitle={`Are you sure you want to delete the following bundle group?`}
          item={{
            itemId: bundleGroup?.id,
            itemName: bundleGroup?.name ?? ""
          }}
          handleCloseClick={(e) => {
            e.stopPropagation()
            setOpenPopup(false)
          }}
          handleOkClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
          loading={deleteLoading}
          type="delete"
        />
      )}
    </>
  )
}

export default DeleteBundleGroup
