import React from "react"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import { OrderModification, OrderModificationOrderLines } from "@lib/types/generated/graphql-types"
import { ReactComponent as RefreshIcon } from "images/icons/arrow-rotate-left-thick.svg"
import { useQuery } from "@apollo/client"
import Status from "../../../Ui/Status"
import { Label } from "../../Updates/Shared.styled"
import { Container, ProviderStatus } from "./Shared.styled"
import GET_MODIFICATION from "../../../../graphql/queries/order/GetModification"

type Props = {
  pendingModification: OrderModification
  orderRefetch: () => void
  orderLoading: boolean
}

const PendingModification = ({ pendingModification, orderRefetch, orderLoading }: Props) => {
  const { data, refetch, loading } = useQuery(GET_MODIFICATION, {
    variables: { modificationId: pendingModification?.id },
    fetchPolicy: "network-only"
  })

  const modification = data?.Order?.orderModificationOrderLines as OrderModificationOrderLines
  if (!modification) return null

  return (
    <Container>
      <h4>Pending modification #{pendingModification.id}</h4>
      <ProviderStatus>
        <div>
          <Label>Payment status:</Label>{" "}
          <Status status={modification.paymentProvider.status?.current ?? ""} />
        </div>
      </ProviderStatus>
      <PrimaryButton
        handleClick={() => {
          refetch()
          orderRefetch()
        }}
        loading={loading || orderLoading}
      >
        <RefreshIcon />
        Refresh status
      </PrimaryButton>
    </Container>
  )
}

export default PendingModification
