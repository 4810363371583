import { createSlice } from "@reduxjs/toolkit"


interface Alert {
  message: string
  type: string
}

interface PopupError {
  messages: string[]
  title: string
}

interface InitialState {
  alert: Alert
  popupError: PopupError
}


const initialStateValue: InitialState = {
  alert: {
    message: "",
    type: ""
  },
  popupError: {
    title: "",
    messages: [],
  }
}

export const AlertSlice = createSlice({
  name: "alert",
  initialState: initialStateValue,
  reducers: {
    createAlert: (state, action) => {
      state.alert = {
        message: action.payload.message,
        type: action.payload.type
      }
    },
    resetAlert: (state) => {
      state.alert = {
        message: "",
        type: ""
      }
    },
    createPopupError: (state, action) => {
      state.popupError = {
        title: action.payload.title,
        messages: action.payload.messages,
      }
    },
    resetPopupError: (state) => {
      state.popupError = {
        title: "",
        messages: []
      }
    }
  }
})

export const actions = AlertSlice.actions

export default AlertSlice
