import React, { Dispatch, SetStateAction } from "react"
import {
  DateWrapper,
  InputWrapper,
  Name,
  SelectWrapper,
  StyledButton
} from "../CampaignInput.styled"
import { Controller, UseFormGetValues } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import SingleSelect from "../../Ui/Form/SingleSelect"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { CampaignGroup, InputCampaign } from "@lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { Option } from "@lib/types/common"
import { Loader } from "../../Ui/Loader"
import dayjs from "dayjs"
import { CreateCampaignGroup } from "../../../views/Campaigns/CreateCampaignGroup"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useDispatch } from "react-redux"
import { isCampaignUser, isSuperUser } from "helpers/user"

type DefaultValues = {
  name?: string
  startDate?: string
  endDate?: string
  campaignGroupId: string
}

type Props = {
  control: Control<InputCampaign>
  errors: FieldErrors<InputCampaign>
  setValue: UseFormSetValue<InputCampaign>
  getValues: UseFormGetValues<InputCampaign>
  campaignGroups: CampaignGroup[]
  defaultValues?: DefaultValues
  setCampaignGroups: Dispatch<SetStateAction<CampaignGroup[]>>
  isCampaignOngoing?: boolean,
  startDateValidation: boolean
}

const General = ({
  control,
  errors,
  setValue,
  getValues,
  campaignGroups,
  defaultValues,
  setCampaignGroups,
  isCampaignOngoing,
  startDateValidation
}: Props) => {
  const dispatch = useDispatch()

  const campaignGroupOptions = campaignGroups?.map((campaignGroup: CampaignGroup) => ({
    value: campaignGroup.id,
    label: campaignGroup.name
  })) as Option[]
  return (
    <>
      <h2>General</h2>
      <InputWrapper>
        <Name>
          <Controller
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="Name *"
                placeholder="Aa - Zz, 0 - 9"
                errors={errors}
                disabled={isCampaignOngoing}
              />
            )}
            defaultValue={defaultValues?.name ?? ""}
            control={control}
            rules={{
              required: "This is a required field"
            }}
          />
        </Name>

        <SelectWrapper>
          {!campaignGroups ? (
            <Loader color={"black"} />
          ) : (
            <Controller
              name="campaignGroupId"
              render={({ field }) => (
                <SingleSelect
                  {...field}
                  label="Campaign group *"
                  options={campaignGroupOptions}
                  disabled={isCampaignOngoing}
                  defaultValue={
                    defaultValues?.campaignGroupId
                      ? {
                          label:
                            campaignGroups?.find(
                              (group) => group.id === defaultValues?.campaignGroupId
                            )?.name ?? defaultValues?.campaignGroupId,
                          value: defaultValues?.campaignGroupId
                        }
                      : {
                          label:
                            campaignGroups?.find((group) => group.id === field.value)?.name ??
                            field.value,
                          value: field.value
                        }
                  }
                  setValue={(e) => setValue("campaignGroupId", e?.value)}
                />
              )}
              control={control}
              rules={{
                required: "Campaign group is required."
              }}
            />
          )}
        </SelectWrapper>
        <StyledButton
          overrideDisabled={!isSuperUser() && isCampaignUser()}
          handleClick={() => dispatch(showEditSidebar())}
        >
          + Group
        </StyledButton>
        <CreateCampaignGroup setCampaignGroups={setCampaignGroups} />
      </InputWrapper>
      <DateWrapper>
        <Controller
          name="startDate"
          render={({ field }) => (
            <Input
              {...field}
              type="datetime-local"
              label="Start date*"
              placeholder="Date"
              disabled={isCampaignOngoing}
              min={dayjs().format("YYYY-MM-DD HH:mm")}
              errors={errors}
            />
          )}
          defaultValue={
            defaultValues?.startDate
              ? dayjs(defaultValues?.startDate).format("YYYY-MM-DD HH:mm")
              : dayjs().add(1, "hour").format("YYYY-MM-DD HH:mm")
          }
          control={control}
          rules={{
            required: "Start date is required",
            ...(startDateValidation && {min: {value: dayjs().format("YYYY-MM-DD HH:mm"), message: `Can't be a date before ${dayjs().format("YYYY-MM-DD HH:mm")}`,}})
          }}
        />
        <Controller
          name="endDate"
          render={({ field }) => (
            <Input
              {...field}
              type="datetime-local"
              label="End date*"
              placeholder="Date"
              min={getValues("startDate")}
              errors={errors}
            />
          )}
          defaultValue={
            defaultValues?.endDate ? dayjs(defaultValues?.endDate).format("YYYY-MM-DD HH:mm") : ""
          }
          control={control}
          rules={{
            required: "End date is required",
            min: {value: dayjs(getValues("startDate")).format("YYYY-MM-DD HH:mm"), message: `Can't be a date before start date`,}
          }}
        />
      </DateWrapper>
    </>
  )
}

export default General
