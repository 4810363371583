import gql from "graphql-tag"

const VALIDATE_ADYEN = gql`
    query validateAdyen($baseUrl: String!, $merchantAccount: String!, $xApiKey: String!, $environment: String) {
        Configuration {
            validate {
              adyen(baseUrl: $baseUrl, merchantAccount: $merchantAccount, xApiKey: $xApiKey, environment: $environment) {
                    errorMessage
                    valid
                }
            }
        }
    }
`

export default VALIDATE_ADYEN
