import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { v4 as uuidv4 } from "uuid"
import { useNavigate } from "react-router-dom"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { LogicState, DiscountRuleInputs } from "@lib/types/discount"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { transformLogicData } from "helpers/transformLogicData"
import { DiscountExternalRule } from "@lib/types/generated/graphql-types"
import { duplicateLogicRules } from "helpers/duplicateDiscount"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"

import VALIDATE_EXTERNAL_RULE_ID from "graphql/queries/discount/ValidateDiscountExternalRuleId"
import CREATE_OR_UPDATE_DISCOUNT_EXTERNAL_RULE from "graphql/mutations/discount/CreateOrUpdateDiscountExternalRule"

import { Flex } from "./DuplicateExternalRule.styled"

import { EditPopup } from "components/Ui/EditPopup/EditPopup"
import Input from "components/Ui/Form/Input"
import SecondaryButton from "components/Ui/Buttons/SecondaryButton"

type Props = {
  externalRuleData: DiscountExternalRule
  logicState: LogicState
  setDuplicateExternalRule: Dispatch<SetStateAction<boolean>>
}

interface ExtendedDiscountRuleInputs extends DiscountRuleInputs {
  id: string
}

const DuplicateExternalRule = ({
  externalRuleData,
  logicState,
  setDuplicateExternalRule
}: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const closePopup = () => {
    dispatch(hideEditSidebar())
    setDuplicateExternalRule(false)
  }

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isValid },
    watch,
    getValues
  } = useForm<ExtendedDiscountRuleInputs>()

  const [validationError, setValidationError] = useState<boolean>(false)
  const [validateRuleId, { loading: validateLoading }] = useLazyQuery(VALIDATE_EXTERNAL_RULE_ID)

  const idField = watch("id")

  useEffect(() => {
    setValidationError(false)
  }, [idField])

  const [addExternalRule, { loading }] = useMutation(CREATE_OR_UPDATE_DISCOUNT_EXTERNAL_RULE, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: `New external rule created. ${externalRuleData.name} successfully duplicated`,
          type: "success"
        })
      )
      navigate(`/discounts/external-rules/${data.createOrUpdateDiscountExternalRule.id}`)
      closePopup()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const validateId = async () => {
    const id = getValues("id")
    const referenceSearchQuery = esb
      .requestBodySearch()
      .query(esb.boolQuery().must(esb.termsQuery("id", id)))
    const query = referenceSearchQuery.toJSON() as Query
    const response = await validateRuleId({
      variables: {
        from: 0,
        size: 1000,
        query: JSON.stringify(query.query)
      }
    })
    if (response.data.searchDiscountExternalRules.total !== 0) {
      setValidationError(true)
      return false
    } else {
      setValidationError(false)
      return true
    }
  }

  const onSubmit: SubmitHandler<ExtendedDiscountRuleInputs> = async (data) => {
    const valid = await validateId()

    if (valid) {
      const { id, name } = data
      const transformedData = transformLogicData(duplicateLogicRules(logicState))
      addExternalRule({
        variables: {
          id,
          name,
          isActive: externalRuleData.isActive ?? false,
          isStackable: externalRuleData.isStackable ?? false,
          applyLast: externalRuleData.applyLast ?? false,
          isExclusive: externalRuleData.isExclusive ?? false,
          sortOrder: externalRuleData.sortOrder,
          ...transformedData,
          ...(externalRuleData.validDateRange &&
            externalRuleData.validDateRange.from &&
            externalRuleData.validDateRange.to && {
              validDateRange: {
                from: new Date(externalRuleData.validDateRange.from),
                to: new Date(externalRuleData.validDateRange.to)
              }
            })
        }
      })
    }
  }

  return (
    <EditPopup
      title="Duplicate external discount rule"
      buttonText="Duplicate"
      handleOkClick={handleSubmit(onSubmit)}
      handleCloseClick={() => closePopup()}
      loading={loading || validateLoading}
      disableButton={!isValid}
    >
      <p>A new ID and name is required.</p>
      <form>
        <Flex>
          <Controller
            name="id"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                label="ID *"
                placeholder="Aa - Zz, 0 - 9, -_."
                errors={validationError ? { id: { message: "ID already exist" } } : errors}
                description="Cannot be modified after save"
              />
            )}
            control={control}
            rules={{
              required: "This is a required field",
              pattern: {
                value: /^[A-Za-z0-9_.-]+$/,
                message: "Only Aa-Zz, 0-9 and _-. are allowed"
              }
            }}
          />
          <SecondaryButton
            type="button"
            handleClick={() => {
              setValue("id", uuidv4())
              trigger("id")
            }}
          >
            Generate
          </SecondaryButton>
        </Flex>
        <Controller
          name="name"
          render={({ field }) => <Input label="Name *" errors={errors} {...field} />}
          defaultValue={`${externalRuleData.name} Copy`}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />
      </form>
    </EditPopup>
  )
}

export default DuplicateExternalRule
