import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components/macro"
import { selectAlert } from "lib/store/services/Alert/AlertSelector"
import alertActions from "lib/store/services/Alert/AlertSlice"

import { ReactComponent as InfoIcon } from "images/icons/circle-info.svg"
import { ReactComponent as SuccessIcon } from "images/icons/circle-check.svg"
import { ReactComponent as ErrorIcon } from "images/icons/circle-exclamation.svg"

type StyledProps = {
  showMessage: boolean
}

const Container = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  margin: 0;
  border: none;
  border-radius: 0.5rem;
  padding: 1.7rem 2rem;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 10003;
  font-weight: 700;
  letter-spacing: 0.05rem;
  transition: transform 0.4s;
  transform: translateX(${(p) => (p.showMessage ? "0" : "calc(100% + 3rem)")});

  p {
    margin: 0;
  }

  p::first-letter {
    text-transform: capitalize;
  }

  a {
    color: ${(p) => p.theme.colors.error.text};
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.error.text};
    line-height: 2rem;
    margin-left: 0.4rem;
    transition: border-bottom 0.2s;

    &:hover {
      border-bottom: 0.1rem solid transparent;
    }
  }

  svg {
    height: 2.2rem;
    margin-right: 1rem;
    fill: ${(p) => p.theme.colors.white};
  }
`

const Success = styled(Container)`
  background: ${(p) => p.theme.colors.success.background};
  color: ${(p) => p.theme.colors.success.text};
`

const Error = styled(Container)`
  background: ${(p) => p.theme.colors.error.background};
  color: ${(p) => p.theme.colors.error.text};
`

const Default = styled(Container)`
  background: ${(p) => p.theme.colors.default.background};
  color: ${(p) => p.theme.colors.default.text};
`

const defaultErrorMessage = (
  <>
    An error occured. Please contact
    <a href="mailto:support@brinkcommerce.com">support</a>.
  </>
)

const Alert = () => {
  const { alert } = useSelector(selectAlert)
  const [notification, setNotification] = useState({ type: "", message: "" })
  const [show, setShow] = useState(false)
  const { type, message } = notification || {}

  const dispatch = useDispatch()

  useEffect(() => {
    if (alert.type) {
      setNotification(alert)
      setShow(true)
      setTimeout(() => {
        setShow(false)
        dispatch(alertActions.actions.resetAlert())
      }, 4000)
    }
  }, [alert])

  return (
    <>
      <Success showMessage={show && type === "success"}>
        <SuccessIcon />
        <p> {message}</p>
      </Success>
      <Error showMessage={show && type === "error"}>
        <ErrorIcon />
        <p>{message || defaultErrorMessage}</p>
      </Error>
      <Default showMessage={show && type === "default"}>
        <InfoIcon />
        <p>{message}</p>
      </Default>
    </>
  )
}

export default Alert
