import gql from "graphql-tag"

const ALL_SHIPPING_PROVIDERS = gql`
  query getShippingProviders {
    getShippingProviders {
      id
      type
    }
  }
`

export default ALL_SHIPPING_PROVIDERS
