import gql from "graphql-tag"

const DELETE_DELIVERY = gql`
  mutation deleteDelivery($deliveryId: ID!) {
    Order {
      deleteDelivery(deliveryId: $deliveryId) {
        status
      }
    }
  }
`

export default DELETE_DELIVERY
