import gql from "graphql-tag"

const ON_ADYEN_WEBHOOK_UPDATED = gql`
  subscription OnAdyenWebhookChanged($configId: String!) {
    OnAdyenWebhookChanged(configId: $configId) {
      id
      description
      username
      configId
      externalId
      target
      status
      created
      updated
      tested
      sequenceNumber
    }
  }
`

export default ON_ADYEN_WEBHOOK_UPDATED
