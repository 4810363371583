import React, { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { DiscountCodeRuleSearchHit } from "lib/types/generated/graphql-types"
import { Query, RULE_TYPE } from "lib/types/common"
import { Common } from "lib/types"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import SEARCH_DISCOUNT_CODE_RULES from "graphql/queries/discount/SearchDiscountCodeRules"
import Box from "components/Ui/Box"
import DiscountRuleFilters from "../../../components/Discount/DiscountRule/DiscountRuleFilters"
import { DiscountRuleTable } from "../../../components/Discount/DiscountRule/DiscountRuleTable"

export const ShowDiscountCodeRules = () => {
  const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

  const DEFAULT_CODE_RULE_FILTER = {
    fromDate: "",
    toDate: "",
    isActive: [] as Common.Option[],
    numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
    searchQuery: ""
  }

  const [esbQuery, setEsbQuery] = useState<Query>()
  const [discountCodeRules, setDiscountCodeRules] = useState<DiscountCodeRuleSearchHit[]>([])
  const [totalDiscountCodeRules, setTotalDiscountCodeRules] = useState<number>()
  const [codeRuleFilter, setCodeRuleFilter] = useLocalStorage<Common.DiscountRuleFilter>(
    "codeRuleFilter",
    DEFAULT_CODE_RULE_FILTER
  )
  const setSearchQuery = (value: string) =>
    setCodeRuleFilter({ ...codeRuleFilter, ...{ searchQuery: value } })

  const query = esbQuery?.toJSON() as Query

  const [searchDiscountCodeRules, { loading, error }] = useLazyQuery(SEARCH_DISCOUNT_CODE_RULES, {
    onCompleted: (data) => {
      setDiscountCodeRules(data?.searchDiscountCodeRules)
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (query) {
          searchDiscountCodeRules({
            variables: {
              from: 0,
              size: codeRuleFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query)
            }
          }).then((response) => {
            setDiscountCodeRules(response?.data?.searchDiscountCodeRules?.hits)
            setTotalDiscountCodeRules(response?.data?.searchDiscountCodeRules?.total)
          })
        }
      },
      codeRuleFilter?.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [codeRuleFilter?.searchQuery, codeRuleFilter?.numberOfHits, esbQuery])

  return (
    <Box padding="0" margin="0">
      <DiscountRuleFilters
        setSearchQuery={setSearchQuery}
        loading={loading}
        searchQuery={codeRuleFilter?.searchQuery ?? ""}
        setEsbQuery={setEsbQuery}
        ruleFilter={codeRuleFilter}
        setRuleFilter={setCodeRuleFilter}
        numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
        defaultRuleFilter={DEFAULT_CODE_RULE_FILTER}
        totalHits={totalDiscountCodeRules}
      />
      <DiscountRuleTable
        type={RULE_TYPE.CODE_RULE}
        discountRules={discountCodeRules}
        setDiscountRules={setDiscountCodeRules}
        loading={loading}
        error={error}
      />
    </Box>
  )
}
