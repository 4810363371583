import React, { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { Query, RULE_TYPE } from "lib/types/common"
import { DiscountRuleSearchHit } from "lib/types/generated/graphql-types"
import { Common } from "lib/types"
import { useLocalStorage } from "../../../hooks/useLocalStorage"

import SEARCH_DISCOUNT_RULES from "graphql/queries/discount/SearchDiscountRules"

import Box from "components/Ui/Box"
import DiscountRuleFilters from "../../../components/Discount/DiscountRule/DiscountRuleFilters"
import { DiscountRuleTable } from "../../../components/Discount/DiscountRule/DiscountRuleTable"

export const ShowCartRules = () => {
  const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

  const DEFAULT_CART_RULE_FILTER = {
    fromDate: "",
    toDate: "",
    isActive: [] as Common.Option[],
    numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
    searchQuery: ""
  }

  const [cartRules, setCartRules] = useState<DiscountRuleSearchHit[]>([])
  const [esbQuery, setEsbQuery] = useState<Query>()
  const [totalDiscountRules, setTotalDiscountRules] = useState<number>()
  const [cartRuleFilter, setCartRuleFilter] = useLocalStorage<Common.DiscountRuleFilter>(
    "cartRuleFilter",
    DEFAULT_CART_RULE_FILTER
  )

  const setSearchQuery = (value: string) =>
    setCartRuleFilter({ ...cartRuleFilter, ...{ searchQuery: value } })
  const query = esbQuery?.toJSON() as Query

  const [searchDiscountRules, { loading, error }] = useLazyQuery(SEARCH_DISCOUNT_RULES, {
    onCompleted: (data) => {
      setCartRules(data?.searchDiscountRules)
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if(query) {
          searchDiscountRules({
            variables: {
              from: 0,
              size: cartRuleFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query)
            }
          }).then((response) => {
            setCartRules(response?.data?.searchDiscountRules?.hits)
            setTotalDiscountRules(response?.data?.searchDiscountRules?.total)
          })
        }
      },
      cartRuleFilter?.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [cartRuleFilter?.searchQuery, cartRuleFilter?.numberOfHits, esbQuery])

  return (
    <Box padding="0" margin="0">
      <DiscountRuleFilters
        setSearchQuery={setSearchQuery}
        loading={loading}
        searchQuery={cartRuleFilter?.searchQuery ?? ""}
        setEsbQuery={setEsbQuery}
        ruleFilter={cartRuleFilter}
        setRuleFilter={setCartRuleFilter}
        numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
        defaultRuleFilter={DEFAULT_CART_RULE_FILTER}
        totalHits={totalDiscountRules}
      />
      <DiscountRuleTable
        type={RULE_TYPE.CART_RULE}
        discountRules={cartRules}
        setDiscountRules={setCartRules}
        loading={loading}
        error={error}
      />
    </Box>
  )
}
