import gql from "graphql-tag"

const SET_SHIPPING_TAX_GROUP = gql`
    mutation setShippingTaxGroup($taxGroupId: String!) {
        setShippingTaxGroup(taxGroupId: $taxGroupId) {
            created
            taxGroupId
            updated
        }
    }
`

export default SET_SHIPPING_TAX_GROUP
