import gql from "graphql-tag";

const DELETE_KLARNA_CHECKOUT_CONFIG = gql`
    mutation deleteKlarnaCheckoutConfig($id: ID!) {
        deleteKlarnaCheckoutConfig(id: $id) {
            status
        }
    }
`;

export default DELETE_KLARNA_CHECKOUT_CONFIG;
