import gql from "graphql-tag"

const CREATE_OR_UPDATE_VOYADO_CONFIG = gql`
  mutation createOrUpdateVoyadoConfig(
    $id: ID!
    $baseUrl: String!
    $apiKey: String!
  ) {
    createOrUpdateVoyadoConfig(
      id: $id
      voyadoConfig: {
        baseUrl: $baseUrl
        apiKey: $apiKey
      }
    ) {
      id
    }
  }
`

export default CREATE_OR_UPDATE_VOYADO_CONFIG
