import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputQliroOneConfig } from "lib/types/generated/graphql-types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import { CREATE_OR_UPDATE_QLIRO_ONE_CONFIG } from "graphql/mutations/config/Providers/Qliro/CreateOrUpdateQliroOneConfig"
import { VALIDATE_QLIRO_ONE } from "graphql/queries/config/Providers/Qliro/ValidateQliroOneConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"
import { QliroOneConfigInputs } from "components/Config/Qliro/QliroOneConfigInputs"

type Props = {
  refetch: () => void
}

interface ExtendedInputQliroOneConfig extends InputQliroOneConfig {
  id: string
}
export const CreateQliroOneConfig = ({ refetch }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputQliroOneConfig>({ mode: "onBlur" })

  const [addQliroOneConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_QLIRO_ONE_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          baseUrl: "",
          merchantOrderManagementStatusPushUrl: "",
          merchantApiKey: "",
          merchantApiSecret: "",
          integratedShipping: undefined
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Qliro One configuration successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateQliroOne, { loading }] = useLazyQuery(VALIDATE_QLIRO_ONE)

  const onSubmit: SubmitHandler<ExtendedInputQliroOneConfig> = (data) => {
    validateQliroOne({
      variables: {
        input: {
          baseUrl: data.baseUrl,
          merchantOrderManagementStatusPushUrl: data.merchantOrderManagementStatusPushUrl,
          merchantApiKey: data.merchantApiKey,
          merchantApiSecret: data.merchantApiSecret,
          ...(data.integratedShipping && { integratedShipping: data.integratedShipping })
        }
      }
    }).then((response) => {
      if (response.data.Configuration.validate.qliroOne.valid) {
        addQliroOneConfig({
          variables: {
            ...data,
            ...(data.integratedShipping && { integratedShipping: data.integratedShipping })
          }
        })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputQliroOneConfig> = (data) => {
    addQliroOneConfig({
      variables: {
        ...data,
        ...(data.integratedShipping && { integratedShipping: data.integratedShipping })
      }
    })
    setValidationError(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Qliro One configuration">
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Qliro One. Do you want to save the Qliro One config anyway? "
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <QliroOneConfigInputs control={control} errors={errors} setValue={setValue} />
      </EditSidebar>
    </form>
  )
}
