import gql from "graphql-tag"

const RESTART_RELEASE = gql`
  mutation restartRelease($releaseId: ID!, $input: InputOrderStartRelease!) {
    Order {
      restartRelease(releaseId: $releaseId, input: $input) {
        status
      }
    }
  }
`

export default RESTART_RELEASE
