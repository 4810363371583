import styled from "styled-components/macro"

import PrimaryButton from "../Buttons/PrimaryButton"

export const Container = styled.div`
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  background: #ffe6e6;
  color: #e64d4d;
  border-radius: 0.5rem;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  line-height: 2rem;

  span {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  a {
    color: #e64d4d;
    border-bottom: 0.1rem solid #e64d4d;
    line-height: 2rem;
    margin-left: 0.4rem;
    transition: border-bottom 0.2s;

    &:hover {
      border-bottom: 0.1rem solid transparent;
    }
  }

  svg {
    flex-shrink: 0;
    height: 2.2em;
    width: 2.2rem;
    margin-right: 1.5rem;
    fill: #e64d4d;
  }
`

export const RefresButtonn = styled(PrimaryButton)`
  background: #e64d4d;
  border-color: #e64d4d;

  svg {
    height: 1.8rem;
    fill: ${(p) => p.theme.colors.white};
  }
`
