import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import DELETE_INGRID_CONFIG from "graphql/mutations/config/DeleteIngridCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"

import { ReactComponent as IngridLogo } from "images/providers/ingrid-logo.svg"
import { useMutation, useQuery } from "@apollo/client"
import GET_INGRID_CONFIG from "../../../../graphql/queries/config/GetIngridConfig"
import IngridConfigInputs from "../../../../components/Config/Ingrid/IngridConfigInputs"
import { SubmitHandler, useForm } from "react-hook-form"
import CREATE_OR_UPDATE_INGRID_CONFIG from "../../../../graphql/mutations/config/CreateOrUpdateIngridCheckoutConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { InputIngridConfig } from "@lib/types/generated/graphql-types"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"


type Props = {
  refetch: () => void
  ingridConfigId: string
  setIngridConfigId: Dispatch<SetStateAction<string>>
}

interface ExtendedInputIngridConfig extends InputIngridConfig {
  id: string
}

export const EditIngridConfig = ({ refetch, ingridConfigId, setIngridConfigId }: Props) => {
  const dispatch = useAppDispatch()
  const closeSidebar = () => {
    setIngridConfigId("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputIngridConfig>({ mode: "onBlur" })

  const [updateIngridConfig, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_INGRID_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Ingrid configuration successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputIngridConfig> = (data) => {
    updateIngridConfig({ variables: { id: ingridConfigId, ...data } })
  }

  const { data } = useQuery(GET_INGRID_CONFIG, { variables: { id: ingridConfigId } })

  const ingridConfig = data?.getIngridConfig

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader
          icon={<IngridLogo />}
          title={ingridConfig?.siteId}
          id={ingridConfig?.id}
          cancelEvent={() => closeSidebar()}
        >
          <DeleteButton
            id={ingridConfig?.id}
            name={ingridConfig?.id}
            entity={"Ingrid configuration"}
            redirectUrl="/settings/providers/ingrid"
            mutation={DELETE_INGRID_CONFIG}
            refetch={refetch}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={updateLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <IngridConfigInputs control={control} errors={errors} setValue={setValue} defaultValues={ingridConfig} />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={ingridConfig.id}
              providerType={PROVIDER_TYPE.INGRID}
              providerCategory={PROVIDER_CATEGORY.SHIPPING}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}
