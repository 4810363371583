import gql from "graphql-tag"

const SEARCH_CAMPAIGN_GROUPS = gql`
  query searchCampaignGroups($from: Int!, $size: Int!, $sort: [SearchSorting!], $query: AWSJSON) {
    searchCampaignGroups(from: $from, size: $size, sort: $sort, query: $query) {
      total
      hits {
        id
        name
        description
        created
        updated
      }
    }
  }
`

export default SEARCH_CAMPAIGN_GROUPS
