import gql from "graphql-tag"

const START_CANCELLATION = gql`
  mutation startRefund($cancellationId: ID!, $input: InputOrderStartCancellation!) {
    Order {
      startCancellation(cancellationId: $cancellationId, input: $input) {
        status
      }
    }
  }
`

export default START_CANCELLATION
