import gql from "graphql-tag"

const UPDATE_STORE_GROUP = gql`
  mutation updateStoreGroup($id: ID!, $name: String!, $description: String!, $countryOfSale: String) {
    updateStoreGroup(
      id: $id
      storeGroup: { name: $name, description: $description, countryOfSale: $countryOfSale }
    ) {
      id
      name
      channelType
      description
    }
  }
`

export default UPDATE_STORE_GROUP
