import React from "react"
import { Controller } from "react-hook-form"
import { Control } from "react-hook-form/dist/types/form"
import { DiscountCodeRule, DiscountExternalRule } from "lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { RULE_TYPE } from "lib/types/common"

import { Container, Name, SortOrder, CheckboxWrapper } from "./DiscountRuleGeneralInput.styled"

import Input from "components/Ui/Form/Input"
import Checkbox from "components/Ui/Form/Checkbox"
import TimePeriodSelection from "../TimePeriodSelection"
import { DiscountRuleInputs } from "@lib/types/discount"

type Props = {
  discountRule: DiscountExternalRule | DiscountCodeRule
  control: Control<DiscountRuleInputs>
  isStackable: boolean
  errors: FieldErrors<DiscountRuleInputs>
  type: RULE_TYPE | undefined
}

const DiscountRuleGeneralUpdateInput = ({
  discountRule,
  control,
  isStackable,
  errors,
  type
}: Props) => {
  const getTypeText = () => {
    switch (type) {
      case RULE_TYPE.CODE_RULE:
        return "discount rule with codes generated from Brink"
      case RULE_TYPE.EXTERNAL_RULE:
        return "discount rule with codes generated from external provider"
    }
  }

  if (!discountRule) return null

  return (
    <div>
      <Container>
        <div>
          <h2>General</h2>
          <p>General inputs for {getTypeText()}</p>
          <Name>
            <Controller
              name="name"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  label="Name *"
                  placeholder="Aa - Zz, 0 - 9"
                  errors={errors}
                />
              )}
              control={control}
              defaultValue={discountRule.name}
              rules={{
                required: "This is a required field"
              }}
            />
          </Name>
          <SortOrder>
            <Controller
              name="sortOrder"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  label="Sort order *"
                  placeholder="Minimum value is 1"
                  errors={errors}
                />
              )}
              control={control}
              defaultValue={discountRule.sortOrder}
              rules={{
                required: "This is a required field",
                min: { value: 1, message: "Minimum value is 1" }
              }}
            />
          </SortOrder>
        </div>
        <CheckboxWrapper>
          <Checkbox
            control={control}
            name="isActive"
            label="Active"
            defaultValue={discountRule.isActive}
          />
          <Checkbox
            control={control}
            name="isStackable"
            label="Is stackable"
            description="Can this rule stack with other rules?"
            defaultValue={discountRule.isStackable}
          />
          <Checkbox
            disabled={!isStackable}
            control={control}
            name="isExclusive"
            label="Is exclusive"
            description="Only one exclusive rule can be applied per cart."
            defaultValue={discountRule.isExclusive}
          />
          <Checkbox
            disabled={!isStackable}
            control={control}
            name="applyLast"
            label="Apply last"
            description="Apply this discount rule last if multiple rules are applied."
            defaultValue={discountRule.applyLast}
          />
        </CheckboxWrapper>
      </Container>
      <hr />
      <TimePeriodSelection
        control={control}
        defaultValues={discountRule.validDateRange ?? undefined}
        errors={errors}
      />
    </div>
  )
}

export default DiscountRuleGeneralUpdateInput
