import React, { Dispatch, SetStateAction, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { Common } from "lib/types"
import { getCountryName, countryOptions } from "helpers/countries"
import { TaxMarket } from "lib/types/generated/graphql-types"

import { Container, StyledTableRow, Name, Actions, NotFound } from "./TaxMarketTable.styled"

import FlagIcon from "components/Ui/FlagIcon"
import CreateTaxMarket from "../../views/Taxes/TaxMarket/CreateTaxMarket"
import ActionButton from "components/Ui/Table/ActionButton"
import QuickAdd from "components/Ui/Table/QuickAdd"
import SingleSelect from "components/Ui/Form/SingleSelect"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import DeleteTaxMarket from "./DeleteTaxMarket"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

type Props = {
  id: string
  taxMarkets: TaxMarket[]
  refetch: () => void
  setEditTaxMarket: Dispatch<SetStateAction<TaxMarket | undefined>>
}

type Inputs = {
  countryCode: string
  taxPercentage: number
  taxPercentageDecimals: number[]
}

function TaxMarketTable({ id, taxMarkets, refetch, setEditTaxMarket }: Props) {
  const [editMode, setEditMode] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const { control } = useForm<Inputs>()
  const updateCountryCode = (countryCode: Common.Option) => {
    setCountryCode(countryCode.value)
  }
  return (
    <div>
      <QuickAdd inSidebar>
        <Controller
          name="countryCode"
          render={({ field }) => (
            <SingleSelect
              {...field}
              options={countryOptions}
              setValue={updateCountryCode}
              defaultValue={{ label: "Select country", value: "" }}
            />
          )}
          control={control}
          rules={{
            required: "This is a required field"
          }}
        />

        <PrimaryButton type="button" handleClick={() => setEditMode(true)} disabled={!countryCode}>
          <PlusIcon /> Add
        </PrimaryButton>
      </QuickAdd>
      {editMode && (
        <CreateTaxMarket
          taxGroupId={id}
          countryCode={countryCode}
          refetch={refetch}
          setEditMode={setEditMode}
          setEditTaxMarket={setEditTaxMarket}
        />
      )}
      {taxMarkets.length > 0 ? (
        <>
          <Container>
            {taxMarkets.map((taxMarket) => {
              const name = getCountryName(taxMarket.countryCode) ?? ""
              return (
                <StyledTableRow key={taxMarket.countryCode}>
                  <Name>
                    <FlagIcon countryCode={taxMarket.countryCode} />
                    {name} ({taxMarket.countryCode})
                  </Name>
                  <Actions>
                    <ActionButton
                      mode="edit"
                      handleOnClick={() => {
                        setEditTaxMarket(taxMarket)
                      }}
                    />
                    <DeleteTaxMarket
                      name={name}
                      taxGroupId={id}
                      countryCode={taxMarket.countryCode}
                      refetch={refetch}
                      icon={true}
                    />
                  </Actions>
                </StyledTableRow>
              )
            })}
          </Container>
        </>
      ) : (
        <NotFound>No tax markets added</NotFound>
      )}
    </div>
  )
}

export default TaxMarketTable
