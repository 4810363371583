import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useLazyQuery } from "@apollo/client"
import {
  ProductParentSearchHits,
  ProductVariantSearchHits
} from "@lib/types/generated/graphql-types"
import { Query } from "@lib/types/common"
import { useLocalStorage } from "hooks/useLocalStorage"
import { Common } from "@lib/types"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import SEARCH_PRODUCT_VARIANTS from "graphql/queries/product/SearchProductVariants"
import SEARCH_PRODUCT_PARENTS from "graphql/queries/product/SearchProductParents"

import PageHeader from "components/Ui/Page/PageHeader"
import ProductFilters from "components/Products/ProductFilters"
import { ProductTable } from "components/Products/ProductTable"
import { ProductVariantSearchTable } from "components/Products/ProductVariantSearchTable"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  position: relative;
`

const TotalHits = styled.span`
  ${(p) => p.theme.bold};
  font-size: 1.3rem;
`

const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

const DEFAULT_PRODUCT_FILTER = {
  isActive: true,
  isArchived: false,
  numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
  searchQuery: ""
}

export const ShowProducts = () => {
  const [products, setProducts] = useState<ProductParentSearchHits>()
  const [productVariants, setProductVariants] = useState<ProductVariantSearchHits>()
  const [esbQuery, setEsbQuery] = useState<Query>()
  const [productFilter, setProductFilter] = useLocalStorage<Common.ProductFilter>(
    "productFilter",
    DEFAULT_PRODUCT_FILTER
  )
  const setSearchQuery = (value: string) =>
    setProductFilter({ ...productFilter, ...{ searchQuery: value } })

  const query = esbQuery?.toJSON() as Query

  const [searchProducts, { loading, error }] = useLazyQuery(SEARCH_PRODUCT_PARENTS, {
    onCompleted: (data) => {
      setProducts(data.searchProductParents)
    }
  })

  const [searchProductVariants, { loading: variantLoading, error: variantError }] = useLazyQuery(
    SEARCH_PRODUCT_VARIANTS,
    {
      onCompleted: (data) => {
        setProductVariants(data.searchProductVariants)
      }
    }
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (query) {
          searchProductVariants({
            variables: {
              from: 0,
              size: productFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query),
              trackTotalHits: true
            }
          }).then((response) => {
            setProductVariants(response?.data?.searchProductVariants)
          })

          searchProducts({
            variables: {
              from: 0,
              size: productFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query),
              trackTotalHits: true
            }
          }).then((response) => {
            setProducts(response?.data?.searchProductParents)
          })
        }
      },
      productFilter?.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [productFilter?.searchQuery, esbQuery, productFilter.numberOfHits])

  return (
    <Container>
      <PageHeader title="Products"></PageHeader>
      <ProductFilters
        setSearchQuery={setSearchQuery}
        loading={loading}
        searchQuery={productFilter?.searchQuery ?? ""}
        setEsbQuery={setEsbQuery}
        productFilter={productFilter}
        setProductFilter={setProductFilter}
        numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
        defaultProductFilter={DEFAULT_PRODUCT_FILTER}
        totalHits={(products?.total ?? 0) + (productVariants?.total ?? 0)}
      />
      <Tabs>
        <TabList>
          <Tab>
            Product parents (<TotalHits>{products?.total.toLocaleString() ?? "0"}</TotalHits>)
          </Tab>
          <Tab>
            {" "}
            Product variants (
            <TotalHits>{productVariants?.total.toLocaleString() ?? "0"}</TotalHits>)
          </Tab>
        </TabList>

        <TabPanel>
          <ProductTable
            productData={products}
            loading={loading}
            searchQuery={productFilter?.searchQuery}
            error={error}
          />
        </TabPanel>
        <TabPanel>
          <ProductVariantSearchTable
            productData={productVariants}
            loading={variantLoading}
            searchQuery={productFilter?.searchQuery}
            error={variantError}
          />
        </TabPanel>
      </Tabs>
    </Container>
  )
}
