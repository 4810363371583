import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { AlertSlice } from "./services/Alert/AlertSlice"
import editSidebar from "./services/editSidebar/slice"
import auth from "./services/auth/slice"
import brink from "./services/brink/slice"
import { api as brinkApi } from "./services/brink/api"

export const store = configureStore({
  reducer: {
    notification: AlertSlice.reducer,
    editSidebar,
    auth,
    [brinkApi.reducerPath]: brinkApi.reducer,
    brink
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(brinkApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch
