import React from "react"
import styled from "styled-components/macro"
import OrderBlock from "components/Order/OrderBlock"
import { Item, Label, List, Value } from "components/Ui/List/List"
import { ReactComponent as VoucherIcon } from "images/icons/ticket.svg"
import {
  OrderVoucher,
  OrderVoucherVoyado
} from "@lib/types/generated/graphql-types"
import Status from "../../../../../components/Ui/Status"
import Money from "../../../../../components/Money"
import DineroFactory from "dinero.js"

const Columns = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    display: flex;
    gap: 6rem;
  }

  > div {
    flex: 1;
  }
`

type Props = {
  voucherDetails: OrderVoucher
}

const Vouchers = ({ voucherDetails }: Props) => {
  function isOrderVoucherVoyado(object: OrderVoucher): object is OrderVoucherVoyado {
    return object.providerName === "Voyado"
  }

  return (
    <Columns>
      <OrderBlock>
        <h2>
          <VoucherIcon />
          External vouchers
        </h2>
        <>
          {isOrderVoucherVoyado(voucherDetails) &&
          voucherDetails?.vouchers &&
          voucherDetails?.vouchers?.length > 0 ? (
            <>
              {voucherDetails?.vouchers.map((voucher) => (
                <List key={voucher.id}>
                  <Item>
                    <Label>Provider id:</Label>
                    <Value>{voucherDetails.providerId}</Value>
                  </Item>
                  <Item>
                    <Label>Provider name:</Label>
                    <Value>{voucherDetails.providerName}</Value>
                  </Item>
                  <Item>
                    <Label>Contact id:</Label>
                    <Value>{voucherDetails.contactId}</Value>
                  </Item>
                  <Item>
                    <Label>Check number:</Label>
                    <Value>{voucher.checkNumber}</Value>
                  </Item>
                  <Item>
                    <Label>Status:</Label>
                    <Status status={voucher.status} />
                  </Item>
                  <Item>
                    <Label>Amount:</Label>
                    <Value>
                      <Money
                        amount={voucher.amount}
                        currencyUnit={voucher.currencyCode as DineroFactory.Currency}
                      />
                    </Value>
                  </Item>
                  {voucher?.failedReason ? (
                    <Item>
                      <Label>Failed reason:</Label>
                      <Value>{voucher.failedReason}</Value>
                    </Item>
                  ) : (
                    <></>
                  )}
                </List>
              ))}
            </>
          ) : (
            <p>No external voucher used for this order.</p>
          )}
        </>
      </OrderBlock>
    </Columns>
  )
}

export default Vouchers
