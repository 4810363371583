import React from "react"
import { Container, Divider, Provider, Row } from "./Totals.styled"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { Loader } from "../../../../Ui/Loader"
import { OrderTotals } from "@lib/types/generated/graphql-types"

type Props = {
  totals: OrderTotals
  currencyCode: string
}

const Totals = ({ totals, currencyCode }: Props) => (
  <Container>
    {totals ? (
      <>
        <Row>
          <h4>Subtotal</h4> <Money amount={Math.round(totals.subTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>
        <Row>
          <h4>Shipping total</h4> <Money amount={Math.round(totals.shippingTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>
        {totals.discountTotal > 0 && <Row>
          <h4>Discount total</h4> - <Money amount={Math.round(totals.discountTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>}
        {totals.bonusTotal > 0 && <Row>
          <Provider>Bonus total</Provider> - <Money amount={Math.round(totals.bonusTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>}
        {totals.giftCardTotal > 0 && <Row>
          <Provider>Gift card total</Provider> - <Money amount={Math.round(totals.giftCardTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/>
        </Row>}
        <Divider />
        <Row>
          <h3>Total order value</h3> <h3><Money amount={Math.round(totals.grandTotal)} currencyUnit={currencyCode as DineroFactory.Currency}/></h3>
        </Row>
      </>
    ) : (
      <Loader />
    )}
  </Container>
)

export default Totals
