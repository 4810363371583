import React, { useEffect, useState } from "react"
import { ProductPreviewTable } from "./ProductPreviewTable"
import { useLazyQuery } from "@apollo/client"
import SEARCH_PRODUCT_VARIANTS from "../../../../graphql/queries/product/SearchProductVariants"
import { ProductVariantSearchHits } from "@lib/types/generated/graphql-types"
import { Query } from "@lib/types/common"
import { Common } from "@lib/types"
import { CampaignTagRule, TagCondition } from "@lib/types/campaign"
import esb from "elastic-builder"
import { Header, HeaderContainer, Products, ResultWrapper, StyledProductIcon, Wrapper } from "./ProductPreveiw.styled"
import ProductPreviewFilter from "./ProductPreviewFilter"
import { Hits, Result } from "./ProductPreviewFilter.styled"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "images/icons/chevron-up.svg"

const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

const DEFAULT_PRODUCT_FILTER = {
  numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
  searchQuery: ""
}

type Props = {
  tagRule: CampaignTagRule
  index: number
}

const ProductPreview = ({ tagRule, index }: Props) => {
  const [productVariants, setProductVariants] = useState<ProductVariantSearchHits>()
  const [productFilter, setProductFilter] =
    useState<Common.ProductPreviewFilter>(DEFAULT_PRODUCT_FILTER)
  const [expanded, setExpanded] = useState<boolean>(false)

  const setNumberOfHits = (value: string) =>
    setProductFilter({ ...productFilter, ...{ numberOfHits: value } })

  const setSearchQuery = (value: string) =>
    setProductFilter({ ...productFilter, ...{ searchQuery: value } })

  const makeNestedEsQuery = (tagCondition: TagCondition) =>
    esb
      .nestedQuery()
      .path("tags")
      .query(
        esb
          .boolQuery()
          .filter([
            esb.termQuery("tags.key", tagCondition.key),
            esb.termsQuery("tags.value", tagCondition.values)
          ])
      )

  const getQuery = () => {
    const filterArrayMust = tagRule.tagConditions
      .filter(
        (tagCondition) => tagCondition.include && tagCondition.key && tagCondition.values.length > 0
      )
      .map(makeNestedEsQuery)

    const filterArrayMustNot = tagRule.tagConditions
      .filter(
        (tagCondition) =>
          !tagCondition.include && tagCondition.key && tagCondition.values.length > 0
      )
      .map(makeNestedEsQuery)

    const nestedQuery = esb
      .boolQuery()
      .must(esb.matchQuery(`isActive`, "true"))
      .filter([...filterArrayMust])
      .mustNot([...filterArrayMustNot])
      .must([esb.queryStringQuery(productFilter.searchQuery.trim() + "*").analyzeWildcard(true)])
    return esb.requestBodySearch()?.query(nestedQuery).toJSON()
  }

  const query = getQuery() as Query

  const [searchProductVariants, { loading: variantLoading, error: variantError }] = useLazyQuery(
    SEARCH_PRODUCT_VARIANTS,
    {
      onCompleted: (data) => {
        setProductVariants(data.searchProductVariants)
      }
    }
  )

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (query) {
          searchProductVariants({
            variables: {
              from: 0,
              size: productFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query),
              trackTotalHits: true
            }
          }).then((response) => {
            setProductVariants(response?.data?.searchProductVariants)
          })
        }
      },
      productFilter.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [productFilter?.searchQuery, productFilter.numberOfHits, tagRule])

  if (tagRule.tagConditions.length === 0) return null

  return (
    <Wrapper>
      <Header onClick={() => setExpanded(!expanded)}>
        <HeaderContainer>
          <ResultWrapper>
            <StyledProductIcon className="expandIcon" />
            <b>Rule {index + 1}</b>
            <Result>
              Results: <Hits>{productVariants?.total}</Hits>
            </Result>
          </ResultWrapper>
          {expanded ? <UpIcon /> : <DownIcon />}
        </HeaderContainer>
      </Header>
      <Products>
        <ProductPreviewFilter
          setSearchQuery={setSearchQuery}
          loading={variantLoading}
          searchQuery={productFilter?.searchQuery ?? ""}
          setNumberOfHits={setNumberOfHits}
          productFilter={productFilter}
          numberOfHitsOptions={NUMBER_OF_HITS_OPTIONS}
          expanded={expanded}
          selectedNumberOfHits={productFilter?.numberOfHits}
        />
        {expanded && (
          <ProductPreviewTable
            productData={productVariants}
            loading={variantLoading}
            error={variantError}
            searchQuery={productFilter.searchQuery}
          />
        )}
      </Products>
    </Wrapper>
  )
}

export default ProductPreview
