import React from "react"
import styled from "styled-components/macro"
import { useAuthenticator } from "@aws-amplify/ui-react"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { ReactComponent as LogoutIcon } from "images/icons/arrow-right-from-bracket.svg"
import { useSelector } from "react-redux"
import { selectUserAttributes, selectUserGroups } from "lib/store/services/auth/selectors"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Break = styled.hr`
  margin: 1rem 0 2rem;
`

const Info = styled.div`
  width: 100%;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    width: 48%;
  }
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
`

const SignOutButton = styled(PrimaryButton)`
  svg {
    margin-right: 0;
  }

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    svg {
      margin-right: 1rem;
    }
  }
`
const CampaignOnly = styled.p`
  color: red;
`

export const User = () => {
  const { signOut, user } = useAuthenticator()
  const userGroups = useSelector(selectUserGroups)
  const userAttributes = useSelector(selectUserAttributes)
  const username = user.username

  const logout = () => {
    signOut()
    window.location.reload()
  }

  const campaignOnly =
    userGroups?.includes("brinkcommerce-campaignuser") &&
    !userGroups?.includes("brinkcommerce-superuser") &&
    !userGroups?.includes("brinkcommerce-useradmin") &&
    !userGroups?.includes("brinkcommerce-readonly")

  const getRolesDescription = () => {
    if (!userGroups) return "No access"
    const superUserDescription =
      "As a superuser in Merchant Portal, you can create and edit configurations, manage discount rules, and perform other related tasks."
    if (
      userGroups &&
      userGroups.includes("brinkcommerce-superuser") &&
      userGroups.includes("brinkcommerce-useradmin")
    ) {
      return (
        <>
          <p>{superUserDescription}</p>{" "}
          <p>You also have access to administrate users and API clients in Merchant Portal</p>
        </>
      )
    }

    if (
      userGroups &&
      userGroups.includes("brinkcommerce-campaignuser") &&
      userGroups.includes("brinkcommerce-superuser")
    ) {
      return (
        <>
          <p>{superUserDescription}</p>
          <p>You can also view, create and edit campaign groups and campaigns.</p>
        </>
      )
    }

    if (
      userGroups &&
      userGroups.includes("brinkcommerce-campaignuser") &&
      userGroups.includes("brinkcommerce-readonly")
    ) {
      return (
        <>
          <p>
            You have read-only access to Merchant Portal, which means you can view discounts,
            orders, configurations, etc.
          </p>
          <p>You can also view, create and edit campaign groups and campaigns.</p>
        </>
      )
    }
    if (userGroups && userGroups.includes("brinkcommerce-superuser")) {
      return <p>{superUserDescription}</p>
    }

    if (userGroups && userGroups.includes("brinkcommerce-readonly")) {
      return (
        <p>
          You have read-only access to Merchant Portal, which means you can view discounts, orders,
          configurations, etc.
        </p>
      )
    }

    if (userGroups && campaignOnly) {
      return (
        <CampaignOnly>
          You can view campaigns and campaign groups. However, to be able to create or edit, you
          require read-only access too. Only the user role user-admin can manage user access.
        </CampaignOnly>
      )
    }
  }

  return (
    <>
      <PageHeader title="Merchant Portal user">
        <SignOutButton handleClick={logout} overrideDisabled={true}>
          <LogoutIcon />
          <span className="hideOnSmallScreens">Sign out</span>
        </SignOutButton>
      </PageHeader>
      <Container>
        <Info>
          <Label>Given name</Label>
          <div>{userAttributes?.given_name}</div>
          <Break />
          <Label>Family name</Label>
          <div>{userAttributes?.family_name}</div>
          <Break />
          <Label>Username</Label>
          <div>{username}</div>
          <Break />
        </Info>
        <Info>
          <Label>Groups</Label>
          <div>
            {userGroups ? (
              userGroups?.map((role: string) => <div key={role}>{role}</div>)
            ) : (
              <div>No group connected to user</div>
            )}
          </div>
          <Break />
          <Label>Access</Label>
          {getRolesDescription()}
        </Info>
      </Container>
    </>
  )
}
