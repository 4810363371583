import { createSlice } from "@reduxjs/toolkit"

const initialStateValue = {
  show: false
}

const slice = createSlice({
  name: "editSideBar",
  initialState: initialStateValue,
  reducers: {
    toggleEditSidebar: (state = initialStateValue) => {
      state.show = !state.show
    },
    hideEditSidebar: (state = initialStateValue) => {
      state.show = false
      document.documentElement.classList.remove("sidebar-open")
    },
    showEditSidebar: (state = initialStateValue) => {
      state.show = true
      document.documentElement.classList.add("sidebar-open")
    }
  }
})

const { actions, reducer } = slice
export const { toggleEditSidebar, hideEditSidebar, showEditSidebar } = actions

export default reducer
