import gql from "graphql-tag"

const PLATFORM_CONFIG = gql`
  fragment ExternalApi on PlatformConfig {
    __typename
    externalApi {
      usagePlans {
        name
        rateLimit
        burstLimit
        quotaLimit
        quotaPeriod
      }
      discountManagementQuotas {
        maxGenerationJobsPerDay
        maxNumberOfCodes
      }
    }
  }

  query PlatformConfig {
    PlatformConfig {
      product {
        ...ExternalApi
      }
      discount {
        ...ExternalApi
      }
      stock {
        ...ExternalApi
      }
      store {
        ...ExternalApi
      }
      tax {
        ...ExternalApi
      }
      order {
        ...ExternalApi
      }
      price {
        ...ExternalApi
      }
    }
  }
`

export default PLATFORM_CONFIG
