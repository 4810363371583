import gql from "graphql-tag";

const DELETE_STORE_GROUP = gql`
  mutation deleteStoreGroup($id: ID!) {
    deleteStoreGroup(id: $id) {
      status
    }
  }
`;

export default DELETE_STORE_GROUP;
