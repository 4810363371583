import React, { Dispatch, SetStateAction } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { CapabilityProvider, InputVoyadoConfig } from "@lib/types/generated/graphql-types"
import { useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import CREATE_OR_UPDATE_VOYADO_CONFIG from "graphql/mutations/config/CreateOrUpdateVoyadoConfig"


import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import VoyadoConfigInputs from "../../../../components/Config/Voyado/VoyadoConfigInputs"

interface ExtendedInputVoyadoConfig extends InputVoyadoConfig {
  id: string
}

type Props = {
  setVoyadoConfigurations: Dispatch<SetStateAction<CapabilityProvider[]>>
}

export const CreateVoyadoConfig = ({ setVoyadoConfigurations }: Props) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ExtendedInputVoyadoConfig>({ mode: "onBlur" })

  const [addVoyadoConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_VOYADO_CONFIG,
    {
      onCompleted: (data) => {
        reset({
          id: "",
          apiKey: "",
          baseUrl: ""
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Voyado configuration successfully created",
            type: "success"
          })
        )
        if (data)
          setVoyadoConfigurations((prevState) => [
            ...prevState,
            { id: data.createOrUpdateVoyadoConfig.id, type: "Voyado" }
          ])
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputVoyadoConfig> = (data) => {
    addVoyadoConfig({ variables: { ...data } })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Voyado configuration">
          <PrimaryButton type="submit" loading={createLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <VoyadoConfigInputs control={control} errors={errors} showId={true} />
      </EditSidebar>
    </form>
  )
}
