import React, { Dispatch, SetStateAction } from "react"
import { Container, Section } from "./SectionChoice.styled"
import { BundleSection, BundleSectionValue } from "@lib/types/discount"


type Props = {
  sections: BundleSection[]
  selectedSection: BundleSectionValue
  setSelectedSection: Dispatch<SetStateAction<BundleSectionValue>>
}

const SectionChoice = ({ sections, setSelectedSection, selectedSection }: Props) => {
  return (
    <Container>
      {sections.map((section, index) => (
        <Section key={section.value} $selected={selectedSection === section.value} $valid={section.valid} onClick={() => setSelectedSection(section.value)}>
          {index + 1}.{section.label}
        </Section>
      ))}
    </Container>
  )
}

export default SectionChoice
