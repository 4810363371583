import gql from "graphql-tag"

const CREATE_MODIFICATION = gql`
  mutation createOrderModificationOrderLines($orderId: ID!, $input: InputOrderModificationOrderLines!) {
    Order {
      createOrderModificationOrderLines(orderId: $orderId, input: $input) {
        ... on OrderModificationOrderLines {
          id
          orderReference
          oldTotals {
            subTotal
            discountTotal
            grandTotal
            taxTotal
          }
          newTotals {
            subTotal
            discountTotal
            grandTotal
            taxTotal
          }
          orderLines {
            modificationType
          }
        }
      }
    }
  }
`

export default CREATE_MODIFICATION
