import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { PROVIDER_TYPE } from "lib/types/common"
import { Provider } from "lib/types/generated/graphql-types"

import { Container, Wrapper, IconTableHeader, EmptyList } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import { getLoadingRow } from "./Retain24ConfigTableRow"
import { Retain24ConfigTableRow } from "./Retain24ConfigTableRow"

type Props = {
  providers: Provider[]
  error: object | undefined
  setEditId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const Retain24ConfigTable = ({ providers, setEditId, error, loading }: Props) => {
  const [retain24Providers, setRetain24Providers] = useState<Provider[]>([])

  const tableHeader = (
    <IconTableHeader>
      <div>Simulation key</div>
      <div className="hideOnSmallScreens">Base URL</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Created</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Updated</div>
    </IconTableHeader>
  )

  useEffect(() => {
    if (providers) {
      setRetain24Providers(
        providers?.filter((provider) => provider.type === PROVIDER_TYPE.RETAIN24)
      )
    }
  }, [providers])

  if (loading)
    return <TableBlurLoading numberOfRows={1} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (retain24Providers?.length < 1)
    return (
      <EmptyList>
        <p> No configurations added</p>
      </EmptyList>
    )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Retain24 settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...retain24Providers].map((retain24Provider) => (
          <Retain24ConfigTableRow
            key={retain24Provider.id}
            id={retain24Provider.id}
            setEditId={setEditId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
