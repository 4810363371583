import styled from "styled-components/macro"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 20rem;
`

export const NameCell = styled.div`
  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    min-width: 50rem;
  }
`
