import styled from "styled-components/macro"
import { List } from "../../../../Ui/List/List"

export const Container = styled.div`
min-width: 35rem;
`

export const Summary = styled(List)`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  margin: 0 0 4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 3rem;
  }
`

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`


