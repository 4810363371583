import styled from "styled-components/macro"
import SingleSelect from "components/Ui/Form/SingleSelect"
import TableHeader from "components/Ui/Table/TableHeader"
import { TresholdCell } from "./PriceRule/PriceRule.styled"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import TableRow from "components/Ui/Table/TableRow"

export const Wrapper = styled.div`
  margin: 2rem 0;

  > div {
    margin-bottom: 2rem;
  }
`

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
  }
`

export const StyledButton = styled(PrimaryButton)`
  height: 4.5rem;
  margin-top: 0.2rem;
  width: 18rem;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    flex-direction: row;
    gap: 1rem;
  }
`

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
`

export const Name = styled.div`
  margin-right: 1rem;
  width: 100%;
`

export const DateWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`

export const Percentage = styled.div`
  width: 100%;
  flex: auto !important;
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
`

export const CountrySelect = styled(SingleSelect)`
  margin-bottom: 1rem;
`

export const StoreMarketsHeader = styled(TableHeader)`
  padding: 0 6rem 0 2rem;
`

export const StoreMarketRow = styled(TableRow)`
  background: rgba(255, 255, 255, 0.5);
  padding: 1.2rem 2rem 1rem;
  margin-bottom: rem;
  width: 100%;
`

export const Country = styled(TresholdCell)`
  width: 50%;
  flex: auto;
`

export const Value = styled(TresholdCell)`
  flex: auto;
  width: calc(50% - 8rem);
`

export const AddButton = styled(PrimaryButton)`
  margin: 0 0 0 auto;
`
export const StoreMarketsTable = styled.div`
  display: flex;
  flex-direction: column;
`
