import React, { Dispatch, SetStateAction } from "react"
import { AddedReason, Container, ErrorMessage, Label, MissingReason, Wrapper } from "./Reason.styled"
import Input from "../../../Ui/Form/Input"
import { InputReason, Order } from "lib/types/generated/graphql-types"
import { Loader } from "../../../Ui/Loader"
import { theme } from "../../../../theme"
import { ReactComponent as TrashIcon } from "images/icons/trash-can.svg"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"

type Props = {
  order: Order
  createLoading: boolean
  deleteLoading: boolean
  deleteCancellation: () => void
  setReason: Dispatch<SetStateAction<InputReason>>
  reason: InputReason
  reasonError: boolean
}

const Reason = ({
  order,
  deleteLoading,
  deleteCancellation,
  setReason,
  reason,
  reasonError
}: Props) => {
  const updateCause = (cause: string) => setReason({ ...reason, cause: cause })
  const updateCode = (code: string) => setReason({ ...reason, code: code })

  return (
    <Wrapper>
      {order?.Cancellations[0]?.reason ? (
        <>
          <AddedReason>
            <div>
              <span>
                <b>Cause:</b> {order?.Cancellations[0]?.reason.cause}
              </span>
              <span>
                <b>Code:</b> {order?.Cancellations[0]?.reason.code}
              </span>
            </div>
            {deleteLoading ? (
              <Loader color={theme.colors.grey} />
            ) : (
              <TrashIcon onClick={deleteCancellation}></TrashIcon>
            )}
          </AddedReason>
        </>
      ) : order?.Cancellations.length === 0 ? (
        <>
          <Label>Reason:</Label>
          <Container>
            <>
              <Input
                name={"cause"}
                placeholder={"Cause"}
                removePaddingBottom={true}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateCause(e.currentTarget.value)
                }
              />
              <Input
                name={"code"}
                placeholder={"Code"}
                removePaddingBottom={true}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  updateCode(e.currentTarget.value)
                }
              />
            </>
          </Container>
        </>
      ) : (
        <MissingReason>
          {" "}
          - No reason added{" "}
          <SecondaryButton handleClick={deleteCancellation} loading={deleteLoading}>
            Add reason
          </SecondaryButton>
        </MissingReason>
      )}
      {reasonError && <ErrorMessage>{"Can't use only cause or code*"}</ErrorMessage>}
    </Wrapper>
  )
}

export default Reason
