import gql from "graphql-tag"

const CREATE_OR_UPDATE_SVEA_CHECKOUT_CONFIG = gql`
  mutation createOrUpdateSveaCheckoutConfig(
    $id: ID!
    $merchantId: String!
    $checkoutBaseUrl: String!
    $paymentAdminBaseUrl: String!
    $apiKey: String!
  ) {
    createOrUpdateSveaCheckoutConfig(
      id: $id
      sveaCheckoutConfig: {
        merchantId: $merchantId
        checkoutBaseUrl: $checkoutBaseUrl
        paymentAdminBaseUrl: $paymentAdminBaseUrl
        apiKey: $apiKey
      }
    ) {
      id
    }
  }
`

export default CREATE_OR_UPDATE_SVEA_CHECKOUT_CONFIG
