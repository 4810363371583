import React, { Dispatch, SetStateAction, useState } from "react"
import dayjs from "dayjs"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import styled from "styled-components/macro"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import GET_RETAIN24_CONFIG from "graphql/queries/config/GetRetain24Config"
import CREATE_OR_UPDATE_RETAIN24_CONFIG from "graphql/mutations/config/CreateOrUpdateRetain24Config"
import DELETE_RETAIN24_CONFIG from "graphql/mutations/config/DeleteRetain24Config"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import {
  Retain24ConfigInputs,
  FormInputRetain24Config
} from "components/Config/Retain24/Retain24ConfigInputs"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"

import { ReactComponent as Retain24Logo } from "images/providers/retain24-logo.svg"
import VALIDATE_RETAIN24 from "graphql/queries/config/ValidateRetain24Config"
import Popup from "components/Ui/Popup"

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

const Info = styled.div`
  padding-bottom: 2rem;
`

type Props = {
  refetch: () => void
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

export const EditRetain24Config = ({ refetch, editId, setEditId }: Props) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormInputRetain24Config>({ mode: "onBlur" })

  const [validationError, setValidationError] = useState<boolean>(false)

  const { data, loading } = useQuery(GET_RETAIN24_CONFIG, {
    variables: { id: editId }
  })

  const retain24Config = data?.getRetain24Config

  const dispatch = useAppDispatch()
  const [updateRetain24Config, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_RETAIN24_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Retain24 checkout config successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )
  const [validateRetain24, { loading: validateLoading }] = useLazyQuery(VALIDATE_RETAIN24)

  const onSubmit: SubmitHandler<FormInputRetain24Config> = async (data) => {
    const certificate = await toBase64(data.certificate as File)

    validateRetain24({
      variables: {
        baseUrl: data.baseUrl,
        certificate: certificate,
        certificatePassword: data.certificatePassword
      }
    }).then((response) => {
      if (!response.data?.Configuration.validate.retain24.valid) {
        setValidationError(true)
        return
      } else {
        return updateRetain24Config({
          variables: { ...data, id: editId, certificate: certificate }
        })
      }
    })
  }

  const closeSidebar = () => {
    setEditId("")
    dispatch(hideEditSidebar())
  }

  if (loading) return <EditSidebar loading={loading} />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar setEditId={setEditId}>
        <EditSidebarHeader
          icon={<Retain24Logo />}
          title={retain24Config?.simulationKey}
          id={retain24Config?.id}
          cancelEvent={() => closeSidebar()}
        >
          {validationError ? (
            <Popup
              title="Validation error"
              subtitle="Couldn't validate the provided credentials with Retain24."
              buttonText="Ok"
              handleOkClick={() => {
                setValidationError(false)
              }}
              handleCloseClick={() => setValidationError(false)}
            />
          ) : (
            <></>
          )}
          <DeleteButton
            id={retain24Config?.id}
            name={retain24Config?.simulationKey}
            entity={"Retain24 configuration"}
            redirectUrl="/settings/providers/retain24"
            mutation={DELETE_RETAIN24_CONFIG}
            refetch={refetch}
            postDeleteFunction={() => setEditId("")}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={updateLoading || validateLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <Label>Created:</Label>
            <Info>{dayjs(retain24Config.created).format("YYYY-MM-DD")}</Info>
            <Label>Updated:</Label>
            <Info>{dayjs(retain24Config.updated).format("YYYY-MM-DD")}</Info>
            <Retain24ConfigInputs
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValues={retain24Config}
            />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={retain24Config.id}
              providerType={PROVIDER_TYPE.RETAIN24}
              providerCategory={PROVIDER_CATEGORY.GIFTCARD}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}

const toBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      const result = reader.result as ArrayBuffer
      let encoded = result.toString().replace(/^data:(.*,)?/, "")

      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4))
      }

      resolve(encoded)
    }
    reader.onerror = reject
  })
}
