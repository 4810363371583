import React, { useState } from "react"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"

import PageHeader from "components/Ui/Page/PageHeader"
import { TaxGroups } from "./TaxGroup/TaxGroups"
import { ShippingTax } from "./ShippingTax/ShippingTax"
import styled from "styled-components/macro"
import PrimaryButton from "../../components/Ui/Buttons/PrimaryButton"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import { useAppDispatch } from "lib/store"

const Container = styled.div`
  width: 100%;
`
export const Taxes = () => {
  const dispatch = useAppDispatch()
  const [showAddTaxGroupButton, setShowAddTaxGroupButton] = useState<boolean>(true)
  return (
    <Container>
      <PageHeader title="Taxes" hasTabs={true}>
        {showAddTaxGroupButton ? (
          <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
            <PlusIcon /> Add new tax group
          </PrimaryButton>
        ) : (
          <></>
        )}
      </PageHeader>
      <Tabs>
        <TabList>
          <Tab onClick={() => setShowAddTaxGroupButton(true)}>Tax Groups</Tab>
          <Tab onClick={() => setShowAddTaxGroupButton(false)}>Shipping tax</Tab>
        </TabList>
        <TabPanel>
          <TaxGroups />
        </TabPanel>
        <TabPanel>
          <ShippingTax />
        </TabPanel>
      </Tabs>
    </Container>
  )
}
