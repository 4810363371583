import gql from "graphql-tag"

const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(id: $id) {
      result
    }
  }
`

export default DELETE_CAMPAIGN
