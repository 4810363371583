import React, { Dispatch, SetStateAction, useState } from "react"
import { TooltipIcon } from "components/Ui/TooltipIcon"
import {
  InputStoreMarket,
  InputMarketRoundingRule,
  InputRoundingRules
} from "lib/types/generated/graphql-types"
import { MarketRoundingRules } from "./MarketRoundingRules"
import {
  CheckboxDescription,
  CheckboxWrapper,
  CustomValue,
  DefaultRuleWrapper,
  Description,
  ExampleValue,
  OutputExample,
  RoundingRulesWrapper,
  RuleExampleWrapper,
  TestRule
} from "./RoundingRules.styled"

import MultiSelect from "components/Ui/Form/MultiSelect"
import CheckboxSimple from "components/Ui/Form/CheckboxSimple"
import { Common } from "lib/types"
import { getRoundingRuleValue } from "helpers/getRoundingRuleValue"
import Input from "../../Ui/Form/Input"
import { ReactComponent as ArrowRight } from "images/icons/arrow-right-long.svg"
import { formatFromMinorUnits } from "helpers/money"

type Props = {
  marketRules: InputMarketRoundingRule[]
  setMarketRules: Dispatch<SetStateAction<InputMarketRoundingRule[]>>
  roundingRules: InputRoundingRules | undefined
  setRoundingRules: Dispatch<SetStateAction<InputRoundingRules | undefined>>
  addedStoreMarkets: InputStoreMarket[]
  isCampaignOngoing?: boolean
}

export const RoundingRules = ({
  marketRules,
  setMarketRules,
  roundingRules,
  setRoundingRules,
  addedStoreMarkets,
  isCampaignOngoing
}: Props) => {
  const [exampleValue, setExampleValue] = useState("")
  const commonTargets = ["00", "000", "99", "95", "995", "50", "69", "79"].sort((a, b) => {
    return Number(a) - Number(b)
  })
  const exampleValues = [39928, 45001, 40066, 59899]

  const commonTargetsOptions = commonTargets?.map((target: string) => ({
    value: target,
    label: target
  }))

  const updateDefaultRoundUp = (value: boolean) => {
    const defaultRule = {
      ...roundingRules?.defaultRule,
      roundUp: value,
      roundDown: roundingRules?.defaultRule?.roundDown ?? false,
      targets: roundingRules?.defaultRule?.targets ?? []
    }
    setRoundingRules({ defaultRule: defaultRule, marketRules: roundingRules?.marketRules })
  }

  const updateDefaultRoundDown = (value: boolean) => {
    const defaultRule = {
      ...roundingRules?.defaultRule,
      roundUp: roundingRules?.defaultRule?.roundUp ?? false,
      roundDown: value,
      targets: roundingRules?.defaultRule?.targets ?? []
    }
    setRoundingRules({ defaultRule: defaultRule, marketRules: roundingRules?.marketRules })
  }

  const updateDefaultTargets = (targetOptions: Common.Option[]) => {
    const targets = targetOptions.map((to) => to.value)
    const defaultRule = {
      ...roundingRules?.defaultRule,
      roundUp: roundingRules?.defaultRule?.roundUp ?? false,
      roundDown: roundingRules?.defaultRule?.roundDown ?? false,
      targets: targets
    }
    setRoundingRules({ defaultRule: defaultRule, marketRules: roundingRules?.marketRules })
  }

  const getRoundingExample = (value: number) => {
    return getRoundingRuleValue(
      roundingRules?.defaultRule.roundUp ?? false,
      roundingRules?.defaultRule.roundDown ?? false,
      roundingRules?.defaultRule.targets ?? [],
      value
    )
  }

  return (
    <div>
      <h2>Rounding rules (Optional)</h2>
      <Description>
        In the previous step, a percentage discount was set. In the following step, you will
        determine if and how the sale prices will be rounded. <br /> This is optional, meaning you
        can set a percentage discount without specifying rounding rules.
        <br /> However, if you choose to do so, a default rounding rule is required. Additionally,
        you have the option to set up rounding rules for specific markets.
      </Description>

      <RoundingRulesWrapper>
        <br />
        <h3>Default rounding rule</h3>
        <DefaultRuleWrapper>
          <MultiSelect
            label={
              <>
                {"Targets"}
                <TooltipIcon content="The targets specify the numbers that the price (in minor units) will end with. The target closest to the sale price will be chosen." />
              </>
            }
            defaultValue={
              roundingRules?.defaultRule?.targets.map((target) => ({
                value: target,
                label: target
              })) ?? []
            }
            disabled={isCampaignOngoing}
            options={commonTargetsOptions}
            setValue={updateDefaultTargets}
          />
          <CheckboxWrapper>
            <CheckboxDescription>
              If both are true, the target that is closest to the sale price will automatically be
              chosen. If neither Round down or Round up is chosen, no rounding will be made.
            </CheckboxDescription>
            <div>
              <CheckboxSimple
                name={"roundDownDefault"}
                label="Round down"
                value={roundingRules?.defaultRule?.roundDown ?? false}
                setValue={updateDefaultRoundDown}
                disabled={isCampaignOngoing}
              />
              <CheckboxSimple
                name={"roundUpDefault"}
                value={roundingRules?.defaultRule?.roundUp ?? false}
                label="Round up"
                setValue={updateDefaultRoundUp}
                disabled={isCampaignOngoing}
              />
            </div>
          </CheckboxWrapper>
        </DefaultRuleWrapper>
        {roundingRules?.defaultRule?.targets && roundingRules.defaultRule.targets.length > 0 && (
          <TestRule>
            <h3>Rounding rule output examples:</h3>
            <RuleExampleWrapper>
              {exampleValues.map((value) => (
                <OutputExample key={value}>
                  {formatFromMinorUnits(value, "SEK")} <ArrowRight />
                  <b>{formatFromMinorUnits(getRoundingExample(value) ?? value, "SEK")}</b>
                </OutputExample>
              ))}
            </RuleExampleWrapper>

            <RuleExampleWrapper>
              <ExampleValue>
                <Input
                  name={"value"}
                  label={"Custom decimal value"}
                  type="number"
                  disabled={isCampaignOngoing}
                  defaultValue={exampleValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setExampleValue(e.target.value)
                  }
                />
              </ExampleValue>
              {exampleValue && exampleValue !== "" && (
                <CustomValue>
                  {" "}
                  <ArrowRight /> {getRoundingExample(parseInt(exampleValue)) ?? exampleValue}
                </CustomValue>
              )}
            </RuleExampleWrapper>
          </TestRule>
        )}
        <hr />
        <MarketRoundingRules
          isCampaignOngoing={isCampaignOngoing}
          commonTargetsOptions={commonTargetsOptions}
          marketRules={marketRules}
          setMarketRules={setMarketRules}
          roundingRules={roundingRules}
          setRoundingRules={setRoundingRules}
          addedStoreMarkets={addedStoreMarkets}
        />
      </RoundingRulesWrapper>
    </div>
  )
}
