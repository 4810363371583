import styled from "styled-components/macro"

export const Flex = styled.div`
  display: flex;
  gap: 1rem;

  button {
    margin-top: 3rem;
    height: 4.5rem;

    ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
      margin-top: 3rem;
    }
  }
`
