import React, { Dispatch, SetStateAction } from "react"
import { CapabilityProvider, InputVoyadoConfig } from "lib/types/generated/graphql-types"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"

import DELETE_VOYADO_CONFIG from "graphql/mutations/config/DeleteVoyadoConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"

import { ReactComponent as VoaydoLogo } from "images/providers/voyado-logo.svg"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"
import { useMutation, useQuery } from "@apollo/client"
import GET_VOYADO_CONFIG from "../../../../graphql/queries/config/Providers/Voyado/GetVoyadoConfig"
import VoyadoConfigInputs from "../../../../components/Config/Voyado/VoyadoConfigInputs"
import CREATE_OR_UPDATE_VOYADO_CONFIG from "../../../../graphql/mutations/config/CreateOrUpdateVoyadoConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { SubmitHandler, useForm } from "react-hook-form"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"

interface ExtendedInputVoyadoConfig extends InputVoyadoConfig {
  id: string
}

type Props = {
  voyadoConfigId: string
  setVoyadoConfigId: Dispatch<SetStateAction<string>>
  setVoyadoConfigurations: Dispatch<SetStateAction<CapabilityProvider[]>>
}

export const EditVoyadoConfig = ({
  setVoyadoConfigurations,
  voyadoConfigId,
  setVoyadoConfigId
}: Props) => {
  const dispatch = useAppDispatch()
  const closeSidebar = () => {
    setVoyadoConfigId("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ExtendedInputVoyadoConfig>({ mode: "onBlur" })

  const [updateVoyadoConfig, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_VOYADO_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Voyado configuration successfully updated",
            type: "success"
          })
        )
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<InputVoyadoConfig> = (data) => {
    updateVoyadoConfig({ variables: { ...data, id: voyadoConfigId } })
  }

  const { data } = useQuery(GET_VOYADO_CONFIG, { variables: { id: voyadoConfigId } })

  const voyadoConfig = data?.getVoyadoConfig

  const removeVoyadoConfig = () => {
    setVoyadoConfigurations((prevState) =>
      prevState.filter((config: CapabilityProvider) => config.id !== voyadoConfigId)
    )
    setVoyadoConfigId("")
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar cancelEvent={() => closeSidebar()}>
        <EditSidebarHeader
          icon={<VoaydoLogo />}
          title={voyadoConfig?.id}
          id={voyadoConfig?.id}
          cancelEvent={() => {
            closeSidebar()
          }}
        >
          <DeleteButton
            id={voyadoConfig?.id}
            name={voyadoConfig?.baseUrl}
            entity={"Voyado configuration"}
            redirectUrl="/settings/providers/voyado"
            mutation={DELETE_VOYADO_CONFIG}
            postDeleteFunction={removeVoyadoConfig}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={updateLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <VoyadoConfigInputs control={control} errors={errors} defaultValues={voyadoConfig} />
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={voyadoConfig?.id}
              providerType={PROVIDER_TYPE.VOYADO}
              providerCategory={PROVIDER_CATEGORY.CART_CAPABILITY}
            />
          </TabPanel>
        </Tabs>
      </EditSidebar>
    </form>
  )
}
