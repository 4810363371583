import gql from "graphql-tag"

export const CREATE_OR_UPDATE_BONUS_CONFIG = gql`
  mutation createOrUpdateBonusConfig(
    $id: ID!
    $baseUrl: String!
    $authUrl: String!
    $clientId: String!
    $clientSecret: String!
  ) {
    createOrUpdateBonusConfig(
      id: $id
      bonusConfig: {
        baseUrl: $baseUrl
        authUrl: $authUrl
        clientId: $clientId
        clientSecret: $clientSecret
      }
    ) {
      id
    }
  }
`
