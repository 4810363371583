import React, { Dispatch, SetStateAction, useState } from "react"
import { ApiScope } from "@lib/types/generated/graphql-types"
import { ReactComponent as ArrowDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ArrowUp } from "images/icons/chevron-up.svg"
import { AccessButton, ErrorMessage, Label, LabelWrapper, Scope } from "./ScopeSelect.styled"

type Scopes = {
  read: ApiScope[]
  write: ApiScope[]
}

type Props = {
  error: boolean
  selectedScopes: Scopes
  setSelectedScopes: Dispatch<SetStateAction<Scopes>>
  availableScopes: ApiScope[]
}

enum SCOPE_TYPE {
  READ,
  WRITE
}

const ScopeSelect = ({ error, selectedScopes, setSelectedScopes, availableScopes }: Props) => {
  const [showReadScopes, setShowReadScopes] = useState(false)
  const [showWriteScopes, setShowWriteScopes] = useState(false)

  const readScope = availableScopes?.filter((scope: ApiScope) => scope?.name?.endsWith("read"))
  const writeScope = availableScopes?.filter((scope: ApiScope) => scope.name.endsWith("write"))

  const isAllReadSelected = () => readScope.length === selectedScopes.read.length
  const isAllWriteSelected = () => readScope.length === selectedScopes.write.length

  const handleSelectAllRead = () =>
    isAllReadSelected()
      ? setSelectedScopes({ ...selectedScopes, read: [] })
      : setSelectedScopes({ ...selectedScopes, read: readScope })

  const handleSelectAllWrite = () =>
    isAllWriteSelected()
      ? setSelectedScopes({ ...selectedScopes, write: [] })
      : setSelectedScopes({ ...selectedScopes, write: writeScope })

  const isSelected = (scope: ApiScope) =>
    selectedScopes.read.includes(scope) || selectedScopes.write.includes(scope)

  const handleSelect = (scope: ApiScope, scopeType: SCOPE_TYPE) =>
    isSelected(scope)
      ? setSelectedScopes({
          ...selectedScopes,
          ...(scopeType === SCOPE_TYPE.READ
            ? { read: selectedScopes.read.filter((s) => s !== scope) }
            : { write: selectedScopes.write.filter((s) => s !== scope) })
        })
      : setSelectedScopes({
          ...selectedScopes,
          ...(scopeType === SCOPE_TYPE.READ
            ? { read: [...selectedScopes.read, scope] }
            : { write: [...selectedScopes.write, scope] })
        })

  return (
    <>
      <LabelWrapper>
        <Label>Scope *</Label>
        {error && <ErrorMessage>- Required</ErrorMessage>}
      </LabelWrapper>
      <AccessButton onClick={() => setShowReadScopes(!showReadScopes)}>
        <Label>{`Read access (${selectedScopes.read?.length}/${readScope?.length})`}</Label>
        {showReadScopes ? <ArrowUp /> : <ArrowDown />}
      </AccessButton>
      {showReadScopes && (
        <Scope $selected={isAllReadSelected()} onClick={handleSelectAllRead}>
          Select all
        </Scope>
      )}
      {showReadScopes &&
        readScope?.map((scope: ApiScope) => (
          <Scope
            $selected={isSelected(scope)}
            onClick={() => handleSelect(scope, SCOPE_TYPE.READ)}
            key={scope.name}
          >
            {scope.description}
          </Scope>
        ))}
      <AccessButton onClick={() => setShowWriteScopes(!showWriteScopes)}>
        <Label>{`Write access (${selectedScopes.write?.length}/${writeScope?.length})`}</Label>
        {showWriteScopes ? <ArrowUp /> : <ArrowDown />}
      </AccessButton>
      {showWriteScopes && (
        <Scope $selected={isAllWriteSelected()} onClick={handleSelectAllWrite}>
          Select all
        </Scope>
      )}
      {showWriteScopes &&
        writeScope?.map((scope: ApiScope) => (
          <Scope
            $selected={isSelected(scope)}
            onClick={() => handleSelect(scope, SCOPE_TYPE.WRITE)}
            key={scope.name}
          >
            {scope.description}
          </Scope>
        ))}
    </>
  )
}

export default ScopeSelect
