import React from "react"
import { Controller } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import { SidebarDivider } from "../../Dashboard/Shared.styled"
import { validate } from "uuid"
import { Control } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { InputNShiftConfig, NShiftConfig } from "@lib/types/generated/graphql-types"

interface ExtendedInputNShiftConfig extends InputNShiftConfig {
  id: string
}


type Props = {
  control: Control<ExtendedInputNShiftConfig>
  errors: FieldErrors
  showId?: boolean
  defaultValues?: NShiftConfig
}

const NShiftConfigInputs = ({control, errors, showId, defaultValues}: Props) => {
return(
  <>
    {showId && <Controller
      name="id"
      render={({ field }) => (
        <Input
          {...field}
          type="text"
          label="Identifier *"
          description="For internal use in Brink"
          placeholder="Aa - Zz, 0 - 9, -_."
          errors={errors}
        />
      )}
      control={control}
      rules={{
        required: "This is a required field",
        pattern: {
          value: /^[A-Za-z0-9_.-]+$/,
          message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
        }
      }}
      defaultValue=""
    />}
    <SidebarDivider />
    <Controller
      name="deliveryCheckoutId"
      render={({ field }) => (
        <Input {...field} type="text" label="nShift Delivery checkout ID *" errors={errors} />
      )}
      control={control}
      rules={{
        validate: {
          uuid: (value) => {
            if (!validate(value)) return "Must be uuid format"
          }
        },
        required: "This is a required field"
      }}
      defaultValue={defaultValues?.deliveryCheckoutId ?? ""}
    />
    <Controller
      name="clientId"
      render={({ field }) => (
        <Input {...field} type="text" label="nShift client ID *" errors={errors} />
      )}
      control={control}
      rules={{
        required: "This is a required field"
      }}
      defaultValue=""
    />
    <Controller
      name="clientSecret"
      render={({ field }) => (
        <Input {...field} type="text" label="nShift client secret *" errors={errors} />
      )}
      control={control}
      rules={{
        required: "This is a required field"
      }}
      defaultValue=""
    />
  </>
)
}

export default NShiftConfigInputs
