import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useLazyQuery } from "@apollo/client"
import { CampaignFilter, GroupedCampaign, Query } from "@lib/types/common"
import { Campaign, CampaignGroup, CampaignGroupSearchHit } from "@lib/types/generated/graphql-types"

import SEARCH_CAMPAIGN_GROUPS from "graphql/queries/campaign/SearchCampaignGroups"
import SEARCH_CAMPAIGNS from "graphql/queries/campaign/SearchCampaigns"
import { isCampaignUser, isSuperUser } from "helpers/user"

import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import CampaignGroupTable from "../../components/Campaign/CampaignGroup/CampaignGroupTable"
import { ReactComponent as PlusIcon } from "images/icons/plus.svg"
import CampaignFilters from "../../components/Campaign/CampaignFilters"
import { useNavigate } from "react-router-dom"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  position: relative;
`

const StyledButton = styled(PrimaryButton)`
  width: 20rem;
`

const DEFAULT_CAMPAIGN_FILTER = {
  status: "",
  searchQuery: "",
  createdFromDate: "",
  createdToDate: ""
}

const SIZE = 1000

export const ShowCampaigns = () => {
  const [allCampaignGroups, setAllCampaignGroups] = useState<CampaignGroup[]>([])
  const [campaignGroupsSearchHits, setCampaignGroupsSearchHits] = useState<
    CampaignGroupSearchHit[]
  >([])
  const [groupedCampaigns, setGroupedCampaigns] = useState<GroupedCampaign[] | undefined>()
  const [campaignFilter, setCampaignFilter] = useState<CampaignFilter>(DEFAULT_CAMPAIGN_FILTER)
  const [esbCampaignQuery, setEsbCampaignQuery] = useState<Query>()
  const navigate = useNavigate()

  const setSearchQuery = (value: string) =>
    setCampaignFilter({ ...campaignFilter, ...{ searchQuery: value } })


  const [getAllCampaignGroups, { loading: campaignGroupsSearchLoading }] = useLazyQuery(
    SEARCH_CAMPAIGN_GROUPS,
    {
      variables: {
        from: 0,
        size: 1000,
        sort: [{ field: "updated", order: "DESC" }]
      },
      onCompleted: (data) => {
        setAllCampaignGroups(data.searchCampaignGroups.hits)
      }
    }
  )

  const [searchCampaignGroups, { loading: campaignGroupsLoading }] = useLazyQuery(
    SEARCH_CAMPAIGN_GROUPS,
    {
      variables: {
        from: 0,
        size: 1000,
        sort: [{ field: "updated", order: "DESC" }]
      },
      onCompleted: (data) => {
        setCampaignGroupsSearchHits(data.searchCampaignGroups.hits)
      }
    }
  )

  const [searchCampaigns, { loading: loadingCampaigns, error: campaignError }] = useLazyQuery(
    SEARCH_CAMPAIGNS,
    {
      onCompleted: (data) => {
        setGroupedCampaigns(getGroupedCampaignsFromCampaigns(data?.searchCampaigns?.hits))
      }
    }
  )

  const campaignQuery = esbCampaignQuery?.toJSON() as Query
  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if (campaignQuery) {
          if (allCampaignGroups.length === 0) {
            getAllCampaignGroups().then(() => {
              searchCampaigns({
                variables: {
                  from: 0,
                  size: SIZE,
                  query: JSON.stringify(campaignQuery?.query),
                  sort: [{ field: "updated", order: "DESC" }]
                }
              })
            })
          } else {
            searchCampaigns({
              variables: {
                from: 0,
                size: SIZE,
                query: JSON.stringify(campaignQuery?.query),
                sort: [{ field: "updated", order: "DESC" }]
              }
            })
          }
          if (campaignFilter.searchQuery) {
            searchCampaignGroups({
              variables: {
                from: 0,
                size: SIZE,
                query: JSON.stringify(campaignQuery?.query),
                sort: [{ field: "updated", order: "DESC" }]
              }
            })
          } else {
            setCampaignGroupsSearchHits([])
          }
        }
      },
      campaignFilter.searchQuery === "" ? 10 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [campaignFilter, esbCampaignQuery, SIZE])

  const getGroupedCampaignsFromCampaigns = (campaigns: Campaign[]) => {
    const groupedCampaigns: GroupedCampaign[] = []
    campaigns?.forEach((campaign) => {
      const exists = groupedCampaigns?.find(
        (gc) => gc?.campaignGroup?.id === campaign?.campaignGroupId
      )
      if (exists) {
        groupedCampaigns.map((gc) =>
          gc.campaignGroup?.id === exists?.campaignGroup?.id
            ? {
                campaignGroup: allCampaignGroups.find(
                  (cg: CampaignGroup) => cg.id === gc?.campaignGroup?.id
                ),
                campaigns: gc.campaigns.push(campaign)
              }
            : gc
        )
      } else {
        groupedCampaigns.push({
          campaignGroup: allCampaignGroups?.find(
            (cg: CampaignGroup) => cg.id === campaign.campaignGroupId
          ),
          campaigns: [campaign]
        })
      }
    })

    allCampaignGroups?.forEach((cg: CampaignGroup) =>
      groupedCampaigns?.find((gc) => gc?.campaignGroup?.id === cg.id)
        ? null
        : groupedCampaigns.push({
            campaignGroup: cg,
            campaigns: []
          })
    )
    return groupedCampaigns
  }

  return (
    <Container>
      <PageHeader
        title="Campaigns"
        description="Campaign groups and campaigns are listed below. Campaign management is aimed to promote a set of products. A campaign group can hold multiple campaigns."
      >
        <StyledButton
          overrideDisabled={!isSuperUser() && isCampaignUser()}
          type="button"
          handleClick={() => navigate("/campaigns/create-campaign")}
        >
          <PlusIcon /> Create campaign
        </StyledButton>
      </PageHeader>
      <CampaignFilters
        setSearchQuery={setSearchQuery}
        loading={loadingCampaigns || campaignGroupsLoading || campaignGroupsSearchLoading}
        searchQuery={campaignFilter.searchQuery}
        setEsbQuery={setEsbCampaignQuery}
        campaignFilter={campaignFilter}
        setCampaignFilter={setCampaignFilter}
        defaultCampaignFilter={DEFAULT_CAMPAIGN_FILTER}
      />
      <CampaignGroupTable
        campaignFilter={campaignFilter}
        groupedCampaigns={groupedCampaigns || []}
        setCampaignGroups={setAllCampaignGroups}
        setGroupedCampaigns={setGroupedCampaigns}
        campaignGroupsSearchHits={campaignGroupsSearchHits}
        loading={loadingCampaigns || campaignGroupsLoading || campaignGroupsSearchLoading}
        error={campaignError}
      />
    </Container>
  )
}
