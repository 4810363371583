import gql from "graphql-tag"

const SEARCH_PRODUCT_PARENTS = gql`
  query searchProductParents(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
    $trackTotalHits: Boolean
  ) {
    searchProductParents(
      from: $from
      size: $size
      sort: $sort
      query: $query
      trackTotalHits: $trackTotalHits
    ) {
      total
      hits {
        id
        created
        description
        imageUrl
        isActive
        isArchived
        name
        tags {
          key
          value
        }
        updated
      }
    }
  }
`

export default SEARCH_PRODUCT_PARENTS
