import styled from "styled-components/macro"

type StyledProps = {
  $selected: boolean
  $valid?: boolean
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-top: 1rem;
  border-bottom: 0.1rem solid ${p => p.theme.colors.borderLight};
  margin-bottom: 1rem;
  ${(p) => p.theme.mQ.MEDIA_MIN_MEDIUM} {
    display: flex;
  }
`

export const Section = styled.div<StyledProps>`
  padding: 1rem;
  text-transform: capitalize;
  border-bottom: ${p =>  p.$selected ? `0.2rem solid ${p.theme.colors.turquoiseDarker}`: p.$valid ? "none": `0.2rem solid ${p.theme.colors.error.background}` } ;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`
