import React, { Dispatch, SetStateAction, useState } from "react"
import DeleteCampaignGroup from "./DeleteCampaignGroup"
import { Campaign } from "@lib/types/generated/graphql-types"
import { ReactComponent as DownIcon } from "../../../images/icons/chevron-down.svg"
import { ReactComponent as UpIcon } from "../../../images/icons/chevron-up.svg"
import { Actions, IconWrapper, InfoWrapper, Name, StyledTableRow } from "./CampaignGroupRow.styled"
import { Id } from "../../Ui/Table/Shared.styled"
import InlineCampaignRow from "./InlineCampaignRow"
import { CampaignFilter, GroupedCampaign } from "@lib/types/common"
import { useNavigate } from "react-router-dom"

type Props = {
  groupedCampaign: GroupedCampaign
  setGroupedCampaigns: Dispatch<SetStateAction<GroupedCampaign[] | undefined>>
  campaignFilter: CampaignFilter
}

const CampaignGroupRow = ({ groupedCampaign, setGroupedCampaigns, campaignFilter }: Props) => {
  const [expanded, setExpanded] = useState(
    campaignFilter.searchQuery !== "" ||
      campaignFilter.status !== "" ||
      campaignFilter.createdFromDate !== "" ||
      campaignFilter.createdToDate !== ""
  )
  const navigate = useNavigate()

  if (
    (campaignFilter.searchQuery ||
      (campaignFilter.createdFromDate && campaignFilter.createdToDate)) &&
    groupedCampaign.campaigns.length === 0
  )
    return null
  return (
    <>
      <StyledTableRow $expanded={expanded}>
        <IconWrapper onClick={() => setExpanded(!expanded)}>
          {groupedCampaign.campaigns.length > 0 ? expanded ? <UpIcon /> : <DownIcon /> : <></>}
        </IconWrapper>
        <InfoWrapper
          onClick={() =>
            navigate(`/campaigns/campaign-group/${groupedCampaign?.campaignGroup?.id}`)
          }
        >
          <Name>
            <div>{groupedCampaign?.campaignGroup?.name}</div>
            <span>{groupedCampaign?.campaignGroup?.id}</span>
          </Name>
          <Id>{groupedCampaign?.campaignGroup?.description}</Id>
        </InfoWrapper>
        <Actions>
          <DeleteCampaignGroup
            campaignGroup={groupedCampaign?.campaignGroup}
            setGroupedCampaigns={setGroupedCampaigns}
          />
        </Actions>
      </StyledTableRow>
      {expanded &&
        groupedCampaign?.campaigns?.map((campaign: Campaign) => (
          <InlineCampaignRow
            key={campaign.id}
            campaign={campaign}
            setGroupedCampaigns={setGroupedCampaigns}
          />
        ))}
    </>
  )
}

export default CampaignGroupRow
