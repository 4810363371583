import gql from "graphql-tag"

const GET_RETAIN24_CONFIG = gql`
  query getRetain24Config($id: ID!) {
    getRetain24Config(id: $id) {
      id
      baseUrl
      created
      updated
      simulationKey
      credentials {
        certificate
        privateKey
      }
      redeem
    }
  }
`

export default GET_RETAIN24_CONFIG
