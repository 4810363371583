import styled from "styled-components/macro"

import TableRow from "../../Ui/Table/TableRow"
import TableHeader from "../../Ui/Table/TableHeader"

export const Wrapper = styled.div`
  margin-bottom: 4rem;
`

export const Email = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${(p) => p.theme.colors.turquoiseDark};

  svg {
    fill: ${(p) => p.theme.colors.turquoiseDark};

    height: 1.6rem;
    cursor: pointer;
    transition: all 0.2s;
    margin: 0 0.5rem 0 1rem;
  }

  &:hover {
    color: ${(p) => p.theme.colors.turquoiseDarker};

    svg {
      fill: ${(p) => p.theme.colors.turquoiseDarker};
    }
  }
`

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
`

export const StyledTableRow = styled(TableRow)`
  &:hover {
    cursor: initial;
    background: rgba(255, 255, 255, 1);
    opacity: ${(p) => (p.isInactive ? "0.4" : "1")};
  }

  > div > div {
    flex: 1;
  }
`

export const Flex = styled.div`
  display: flex;
`
