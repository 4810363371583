import gql from "graphql-tag"

const CREATE_OR_UPDATE_TAX_MARKET = gql`
  mutation createOrUpdateTaxMarket(
    $taxGroupId: String!
    $countryCode: String!
    $taxPercentage: Int!
    $taxPercentageDecimals: Int!
  ) {
    createOrUpdateTaxMarket(
      taxGroupId: $taxGroupId
      countryCode: $countryCode
      taxMarket: {
        taxPercentage: $taxPercentage
        taxPercentageDecimals: $taxPercentageDecimals
      }
    ) {
      taxGroupId
      countryCode
      taxPercentage
      taxPercentageDecimals
      created
      updated
    }
  }
`

export default CREATE_OR_UPDATE_TAX_MARKET
