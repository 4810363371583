import styled from "styled-components/macro"
import TableHeader from "../../Ui/Table/TableHeader"

export const ImageAndDetails = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex: initial !important;
  align-self: center;
  margin-right: 1rem;
  height: 6rem;
  width: 6rem;

`

export const ProductImage = styled.img`
  width: 100%;
  display: block;
  flex-shrink: 0;
  max-height: 100%;
  object-fit: contain;
`

export const OrderlineName = styled.h4`
  margin: 0;
  display: block;
  padding-right: 2rem;
  line-height: 2rem;
`

export const Id = styled.span`
  color: ${(p) => p.theme.colors.greyDark};
  margin: 0.5rem 0 0.5rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
`
export const ParentId = styled.span`
  a {
    text-decoration: underline;
    color: ${(p) => p.theme.colors.greyDark};
  }
`

export const Quantity = styled.span`
  color: ${(p) => p.theme.colors.greyDark};
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
`

export const RuleLabel = styled.div`
  cursor: pointer;
  width: auto;
  flex: 1;
  margin-top: 1rem;

  h4 {
    margin: 0;
    display: flex;
    align-items: center;
  }

  svg {
    transition: all 0.3s;
    height: 1.5rem;
    margin-left: 0.5rem;
    transform: rotate(0deg);
  }

  &:hover {
    svg {
      transform: rotate(-45deg);
    }
  }

`

export const BundleId = styled.div`
  font-size: 1rem;
  font-weight: 400 !important;
  margin-bottom: 1rem;
`

export const StyledTableHeader = styled(TableHeader)`
  padding: 0;
  border: none;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLighter};
  width: 100%;
  div {
    border: none;
    padding: 0;
  }
`

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);

`
