import styled from "styled-components/macro"

export const Container = styled.div`

`
export const Info = styled.div`
display: flex;
  margin-bottom: 1rem;
  align-items: center;
  h2 {
    margin: 0;
  }
  svg {
    height: 2rem;
    margin-right: 1rem;
  }
`
