import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import { defaultConfig, DynamicConfig } from "./config"
import { dynamicConfigUrl } from "./config"

interface DynamicConfigContext {
  config: DynamicConfig
  setConfig?: (newConfig: DynamicConfig) => void
  isTestEnvironment: boolean
}

const configContextObject = React.createContext<DynamicConfigContext>({
  config: defaultConfig,
  isTestEnvironment: false
});

export const useConfig = () => useContext(configContextObject);

const configLoadingErrorMessage =
  "Error while fetching global config, the App will not be rendered. (This is NOT a React error.)"

type Props = {
  children: React.ReactNode;
}

const ConfigContextProvider = ({ children }: Props) => {
  const [configState, setConfigState] = useState(defaultConfig);
  const [isTestEnvironmentState, setIsTestEnvironment] = useState(false)
  const [configLoadingState, setConfigLoadingState] = useState<
    "loading" | "ready" | "error"
  >("loading")

  useEffect(() => {
    setIsTestEnvironment(configState?.accountType === "test")
  }, [configState])

  useEffect(() => {
    axios
      .get(dynamicConfigUrl)
      .then((response) => {
        if (setConfigState) {
          setConfigState(response.data)
        }
        setConfigLoadingState("ready")
      })
      .catch((e) => {
        console.log( // eslint-disable-line
          configLoadingErrorMessage,
          `Have you provided the config file '${dynamicConfigUrl}'?`,
          e
        )
        setConfigLoadingState("error")
      })
  }, [setConfigState])

  if (configLoadingState === "loading") {
    return null
  }
  if (configLoadingState === "error") {
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        {configLoadingErrorMessage}
      </p>
    )
  }

  return (
    <configContextObject.Provider
      value={{
        config: configState,
        setConfig: setConfigState,
        isTestEnvironment: isTestEnvironmentState
      }}
    >
      {children}
    </configContextObject.Provider>
  );
};

export default ConfigContextProvider;
