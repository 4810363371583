import gql from "graphql-tag"

const DELETE_COMPENSATION = gql`
  mutation deleteCompensation($compensationId: ID!) {
    Order {
      deleteCompensation(compensationId: $compensationId) {
        status
      }
    }
  }
`

export default DELETE_COMPENSATION
