import React, { Dispatch, SetStateAction } from "react"
import { InputKlarnaCheckoutConfig } from "@lib/types/generated/graphql-types"
import dayjs from "dayjs"
import styled from "styled-components/macro"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"

import DELETE_KLARNA_CHECKOUT_CONFIG from "graphql/mutations/config/DeleteKlarnaCheckoutConfig"
import CREATE_OR_UPDATE_KLARNA_CHECKOUT_CONFIG from "graphql/mutations/config/CreateOrUpdateKlarnaCheckoutConfig"
import GET_KLARNA_CHECKOUT_CONFIG from "graphql/queries/config/GetKlarnaCheckoutConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import { KlarnaCheckoutConfigInputs } from "components/Config/KlarnaCheckout/KlarnaCheckoutConfigInputs"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"

import { ReactComponent as KlarnaLogo } from "images/providers/klarna-logo.svg"

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

const Info = styled.div`
  padding-bottom: 2rem;
`

interface ExtendedInputKlarnaConfig extends InputKlarnaCheckoutConfig {
  id: string
}

type Props = {
  refetch: () => void
  editId: string
  setEditId: Dispatch<SetStateAction<string>>
}

function EditKlarnaConfig({ refetch, editId, setEditId }: Props) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputKlarnaConfig>({ mode: "onBlur" })

  const { data, loading } = useQuery(GET_KLARNA_CHECKOUT_CONFIG, {
    variables: { id: editId }
  })

  const klarnaCheckoutConfig = data?.getKlarnaCheckoutConfig

  const dispatch = useAppDispatch()
  const [updateKlarnaConfig, { loading: updateLoading }] = useMutation(
    CREATE_OR_UPDATE_KLARNA_CHECKOUT_CONFIG,
    {
      onCompleted: () => {
        dispatch(
          alertActions.actions.createAlert({
            message: "Klarna checkout configuration successfully updated",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const onSubmit: SubmitHandler<ExtendedInputKlarnaConfig> = (data) => {
    updateKlarnaConfig({ variables: { ...data, id: klarnaCheckoutConfig.id } })
  }

  const closeSidebar = () => {
    setEditId("")
    dispatch(hideEditSidebar())
  }
  if (loading) return <EditSidebar loading={loading} />
  return (
    <EditSidebar setEditId={setEditId}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <EditSidebarHeader
          icon={<KlarnaLogo />}
          title={klarnaCheckoutConfig?.merchantId}
          id={klarnaCheckoutConfig?.id}
          cancelEvent={() => closeSidebar()}
        >
          <DeleteButton
            id={klarnaCheckoutConfig?.id}
            name={klarnaCheckoutConfig?.merchantId}
            entity={"Klarna checkout configuration"}
            redirectUrl="/settings/providers/klarna"
            mutation={DELETE_KLARNA_CHECKOUT_CONFIG}
            refetch={refetch}
            postDeleteFunction={() => setEditId("")}
            secureDelete={true}
          />
          <PrimaryButton type="submit" loading={updateLoading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        <Tabs>
          <TabList>
            <Tab>General</Tab>
            <Tab>Connected store markets</Tab>
          </TabList>
          <TabPanel>
            <KlarnaCheckoutConfigInputs
              control={control}
              errors={errors}
              setValue={setValue}
              defaultValues={klarnaCheckoutConfig}
            />
            <Label>Created:</Label>
            <Info>{dayjs(klarnaCheckoutConfig.created).format("YYYY-MM-DD")}</Info>
            <Label>Updated:</Label>
            <Info>{dayjs(klarnaCheckoutConfig.updated).format("YYYY-MM-DD")}</Info>
          </TabPanel>
          <TabPanel>
            <ConnectedStoreMarket
              providerId={klarnaCheckoutConfig.id}
              providerType={PROVIDER_TYPE.KLARNA_CHECKOUT}
              providerCategory={PROVIDER_CATEGORY.PAYMENT}
            />
          </TabPanel>
        </Tabs>
      </form>
    </EditSidebar>
  )
}

export default EditKlarnaConfig
