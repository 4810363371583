import gql from "graphql-tag"

const SEARCH_PRODUCT_VARIANTS = gql`
  query searchProductVariants(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
    $trackTotalHits: Boolean
  ) {
    searchProductVariants(
      from: $from
      size: $size
      sort: $sort
      query: $query
      trackTotalHits: $trackTotalHits
    ) {
      total
      hits {
        created
        description
        id
        imageUrl
        isActive
        isArchived
        name
        productParentId
        tags {
          value
          key
        }
        taxGroupId
        updated
      }
    }
  }
`

export default SEARCH_PRODUCT_VARIANTS
