import React from "react"
import { Order, OrderCompensation } from "lib/types/generated/graphql-types"
import { Container, Label, ShippingFeeWrapper } from "./ShippingFees.styled"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import { getCompensationInput } from "../Helpers/helpers"
import { handleOrderManagementError } from "../../Helpers/errors"

type Props = {
  compensation: OrderCompensation
  currencyCode: string
  refetch: () => void
  order: Order
}

const ShippingFees = ({ compensation, currencyCode, refetch, order }: Props) => {
  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => refetch(),
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const shippingFeeExistsInCompensation = (id: string) =>
    !!compensation?.shippingFees?.find((fee) => fee.shippingFeeId === id)

  const addShippingFee = (id: string) => {
    const shippingFee = order?.shippingFees?.find((fee) => fee.id === id)
    updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          shippingFees: [
            ...(compensation.shippingFees ?? []),
            {
              shippingFeeId: shippingFee?.id,
              taxPercentage: shippingFee?.taxPercentage,
              taxPercentageDecimals: shippingFee?.taxPercentageDecimals,
              totalAmount: shippingFee?.salePriceAmount,
              totalDiscountAmount: shippingFee?.discountAmount,
              totalTaxAmount: shippingFee?.taxAmount
            }
          ]
        }
      }
    })
  }

  const removeShippingFee = (id: string) => {
    updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          shippingFees: compensation?.shippingFees?.filter((fee) => fee.shippingFeeId !== id)
        }
      }
    })
  }

  return (
    <Container>
      <Label>Shipping:</Label>
      {order.shippingFees &&
        order?.shippingFees?.map((fee) => {
          return (
            <ShippingFeeWrapper key={fee.id}>
              <div>
                <b>{fee?.name}</b>
                {" - "}
                <Money
                  amount={fee?.salePriceAmount ?? 0}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </div>
              {shippingFeeExistsInCompensation(fee.id ?? "") ? (
                <PrimaryButton
                  loading={loading}
                  mode="delete"
                  handleClick={() => removeShippingFee(fee?.id ?? "")}
                >
                  Exclude
                </PrimaryButton>
              ) : (
                <PrimaryButton loading={loading} handleClick={() => addShippingFee(fee?.id ?? "")}>
                  Include
                </PrimaryButton>
              )}
            </ShippingFeeWrapper>
          )
        })}
    </Container>
  )
}

export default ShippingFees
