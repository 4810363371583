import gql from "graphql-tag"

const GET_EXTERNAL_EVENTS_DESTINATION_APIS = gql`
  query getExternalEventsDestinationAPIs {
    getExternalEventsDestinationAPIs {
      apiDestinations
      apiDestinationsDetails {
        active
        created
        endpoint
        name
        updated
      }
    }
  }
`

export default GET_EXTERNAL_EVENTS_DESTINATION_APIS
