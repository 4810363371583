import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { InputAvardaConfig } from "lib/types/generated/graphql-types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"
import { useAppDispatch } from "lib/store"
import alertActions from "lib/store/services/Alert/AlertSlice"

import { CREATE_OR_UPDATE_AVARDA_CONFIG } from "graphql/mutations/config/Providers/Avarda/CreateOrUpdateAvardaConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import Popup from "components/Ui/Popup"
import { AvardaConfigInputs } from "../../../../components/Config/Avarda/AvardaConfigInputs"
import { VALIDATE_AVARDA } from "../../../../graphql/queries/config/Providers/Avarda/ValidateQliroOneConfig"

type Props = {
  refetch: () => void
}

interface ExtendedInputAvardaConfig {
  id: string
  avardaConfig: InputAvardaConfig
}

export const CreateAvardaConfig = ({ refetch }: Props) => {
  const [validationError, setValidationError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExtendedInputAvardaConfig>({ mode: "onBlur" })

  const [addAvardaOneConfig, { loading: createLoading }] = useMutation(
    CREATE_OR_UPDATE_AVARDA_CONFIG,
    {
      onCompleted: () => {
        reset({
          id: "",
          avardaConfig: {
            authUrl: "",
            clientId: "",
            clientSecret: "",
            shippingBroker: undefined
          },
        })
        dispatch(hideEditSidebar())
        dispatch(
          alertActions.actions.createAlert({
            message: "Avarda configuration successfully created",
            type: "success"
          })
        )
        refetch()
      },
      onError: () => {
        dispatch(
          alertActions.actions.createAlert({
            type: "error"
          })
        )
      }
    }
  )

  const [validateAvarda, { loading }] = useLazyQuery(VALIDATE_AVARDA)

  const onSubmit: SubmitHandler<ExtendedInputAvardaConfig> = (data) => {
    const {baseUrl, authUrl, clientId, clientSecret, shippingBroker} = data.avardaConfig
    validateAvarda({
      variables: {
        input: {
          baseUrl,
          authUrl,
          clientId,
          clientSecret,
        }
      }
    }).then((response) => {
      if (response.data.Configuration.validate.avarda.valid) {
        addAvardaOneConfig({
          variables: {
            id: data.id,
            avardaConfig: {
              baseUrl,
              authUrl,
              clientId,
              clientSecret,
              ...(shippingBroker?.name && { shippingBroker }),
            }
          }
        })
      } else {
        setValidationError(true)
      }
    })
  }

  const directSubmit: SubmitHandler<ExtendedInputAvardaConfig> = (data) => {
    const {baseUrl, authUrl, clientId, clientSecret, shippingBroker} = data.avardaConfig
    addAvardaOneConfig({
      variables: {
        id: data.id,
        avardaConfig: {
          baseUrl,
          authUrl,
          clientId,
          clientSecret,
          ...(shippingBroker?.name && { shippingBroker }),
        }
      }
    })
    setValidationError(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <EditSidebar>
        <EditSidebarHeader title="Create Avarda configuration">
          <PrimaryButton type="submit" loading={createLoading || loading}>
            Save
          </PrimaryButton>
        </EditSidebarHeader>
        {validationError ? (
          <Popup
            title="Validation error"
            subtitle="Couldn't validate provided credentials with Avarda. Do you want to save the Avarda config anyway? "
            buttonText="Yes, save"
            handleOkClick={handleSubmit(directSubmit)}
            handleCloseClick={() => setValidationError(false)}
          />
        ) : (
          <></>
        )}
        <AvardaConfigInputs control={control} errors={errors} setValue={setValue} />
      </EditSidebar>
    </form>
  )
}
