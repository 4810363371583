import styled from "styled-components/macro"
import { List } from "../../../../Ui/List/List"

type StyledProps = {
  $expanded: boolean
}

export const Summary = styled(List)`
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  padding: 1rem 2rem;
  margin: 0 0 4rem;

  ${(p) => p.theme.mQ.MEDIA_MIN_LARGE} {
    padding: 2rem 3rem;
  }
`

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`

export const InputWrapper = styled.div`
  padding: 2rem;
  border: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0.6rem;
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 1rem;
div{
  padding-bottom: 0;
}
  span{
    display: flex;
    align-items: center;
    svg {
      width: 2rem;
    }
  }
`

export const LabelWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${p => p.$expanded ? "2rem": "0"};

  svg {
    width: 1.6rem;

  }
  b {
    margin-left: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Error = styled.div`
  font-size: 1.2rem;
  color: ${(p) => p.theme.colors.errorText};
`

export const GiftCardWrapper = styled.div`
margin-bottom: 2rem;
`

export const ButtonWrapper = styled.div`
margin-top: 2rem;
`
