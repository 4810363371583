import React from "react"
import Input from "../../../Ui/Form/Input"
import {
  Actions,
  Container,
  ResetSearch,
  SearchContainer,
  SearchIcon,
  SearchLoader,
  SearchWrapper
} from "./Search.styled"

type SearchOptions = {
  handleChange: (input: string) => void
  loading: boolean
  defaultValue: string
}

type Props = {
  searchOptions?: SearchOptions
}

const Search = ({ searchOptions }: Props) => {
  return (
    <Container>
      <SearchContainer>
        <SearchWrapper>
          <SearchIcon />
          <Input
            name={"search"}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              searchOptions?.handleChange(e.currentTarget.value)
            }
            placeholder={"Search..."}
            overrideDisabled={true}
          />
          <Actions>
            {searchOptions?.loading ? (
              <SearchLoader color="black" />
            ) : (
              <ResetSearch
                $show={searchOptions?.defaultValue !== ""}
                onClick={() => searchOptions?.handleChange("")}
              />
            )}
          </Actions>
        </SearchWrapper>
      </SearchContainer>
    </Container>
  )
}

export default Search
