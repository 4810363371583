import React, { useState } from "react"
import styled from "styled-components/macro"

import { ReactComponent as DownIcon } from "../../../images/icons/chevron-down.svg"
import { ReactComponent as StockIcon } from "../../../images/icons/warehouse.svg"
import { Maybe, Stock } from "@lib/types/generated/graphql-types"
import InventoryRow from "./InventoryRow"
import Status from "../../Ui/Status"
import { TooltipIcon } from "../../Ui/TooltipIcon"

type StyledProps = {
  $expanded: boolean
}

const Arrow = styled(DownIcon)<StyledProps>`
  width: 1.2rem !important;
  margin-left: 1rem;
  transition: transform 0.2s;
  transform: rotate(${(p) => (p.$expanded ? "180deg" : "0deg")});
`

const ValidateStock = styled.div`
  margin-bottom: 1rem;
  font-weight: 700;
  display: flex;

  i {
    width: 2rem;
  }

  span {
    margin-left: 1rem;
  }
`

const Tab = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => (p.$expanded ? "none" : `0.1rem solid ${p.theme.colors.greyLight}`)};

  svg {
    width: 1.6rem;
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.turquoiseDark};

    svg {
      fill: ${(p) => p.theme.colors.turquoiseDark};
    }
  }
`

type Props = {
  stock: Maybe<Stock> | undefined
}

export const ProductVariantStock = ({ stock }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const toValidateStock = () => {
    if (stock !== null) {
      return (
        <>
          <ValidateStock>
            Validate stock:
            {stock?.validateStock ? <Status status={"Enabled"} /> : <Status status={"Disabled"} />}
            <TooltipIcon content="Validation of stock quantity in cart to prevent overselling" />
          </ValidateStock>
          {stock?.inventories.map((inventory) => (
            <InventoryRow
              key={inventory.inventoryId}
              inventoryId={inventory.inventoryId}
              stock={inventory.quantity}
              created={inventory.created}
              updated={inventory.updated}
            />
          ))}
        </>
      )
    }
    return <Status status={"Missing stock data"} />
  }

  return (
    <>
      <Tab onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        <StockIcon />
        <h4>Stock: {stock?.stockQuantity}</h4>
        <Arrow $expanded={expanded} />
      </Tab>
      {expanded && <>{toValidateStock()}</>}
    </>
  )
}
