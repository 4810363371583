import gql from "graphql-tag"

const SEARCH_DISCOUNT_CODES = gql`
  query searchDiscountCodes(
    $from: Int!
    $size: Int!
    $sort: [SearchSorting!]
    $query: AWSJSON
    $trackTotalHits: Boolean
  ) {
    searchDiscountCodes(from: $from, size: $size, sort: $sort, query: $query, trackTotalHits: $trackTotalHits) {
      total
      hits {
        code
        discountCodeRuleId
        isUnlimited
        updated
        usageLimit
        created
        updated
        validFrom
        validTo
      }
    }
  }
`

export default SEARCH_DISCOUNT_CODES
