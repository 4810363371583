import React, { useEffect, useState } from "react"
import esb, { RequestBodySearch } from "elastic-builder"
import dayjs from "dayjs"
import { Common } from "@lib/types"
import { ReactComponent as CheckIcon } from "images/icons/circle-check.svg"

import FilterDate from "components/Ui/Table/Filter/FilterDate"
import Filter from "components/Ui/Table/Filter/Filter"
import FilterSection from "components/Ui/Table/Filter/FilterSection"
import { Option } from "@lib/types/common"
import SingleSelect from "../../Ui/Form/SingleSelect"
import { ReactComponent as EarthIcon } from "images/icons/earth.svg"
import { BoldText, BundleWrapper, Icon } from "./BundleGroupFilters.styled"

type Props = {
  setSearchQuery: (input: string) => void
  loading: boolean
  searchQuery: string
  setEsbQuery: (query: RequestBodySearch) => void
  bundleGroupFilter: Common.BundleGroupFilter
  setBundleGroupFilter: (filter: Common.BundleGroupFilter) => void
  numberOfHitsOption: string[]
  defaultBundleGroupFilter: Common.BundleGroupFilter
  totalHits: number | undefined
}

const BundleGroupFilters = ({
  setSearchQuery,
  loading,
  searchQuery,
  setEsbQuery,
  bundleGroupFilter,
  setBundleGroupFilter,
  numberOfHitsOption,
  defaultBundleGroupFilter,
  totalHits
}: Props) => {
  const isFilterActive = () => {
    const { isActive, fromDate, toDate } = bundleGroupFilter
    return fromDate !== "" || toDate !== "" || isActive.length > 0
  }
  const [timeZone, setTimeZone] = useState<Option>({
    value: Intl.DateTimeFormat().resolvedOptions().timeZone,
    label: Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const timeZones = Intl.supportedValuesOf("timeZone")

  const getValues = (options: Common.Option[]) => {
    return options.map((option) => option.value)
  }

  const setActiveRuleFilter = (value: Common.Option[]) =>
    setBundleGroupFilter({ ...bundleGroupFilter, ...{ isActive: value } })
  const setFromDate = (value: string) => setBundleGroupFilter({ ...bundleGroupFilter, ...{ fromDate: value } })
  const setToDate = (value: string) => setBundleGroupFilter({ ...bundleGroupFilter, ...{ toDate: value } })
  const setNumberOfHits = (value: string) =>
    setBundleGroupFilter({ ...bundleGroupFilter, ...{ numberOfHits: value } })

  const isActiveOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" }
  ]
  const fromDate = bundleGroupFilter.fromDate === "" ? 0 : 1
  const toDate = bundleGroupFilter.toDate === "" ? 0 : 1
  const totalFilters = bundleGroupFilter.isActive.length + fromDate + toDate

  useEffect(() => {
    const boolQuery = esb.boolQuery()

    if (bundleGroupFilter.isActive.length > 0) {
      boolQuery.must(esb.termsQuery("isActive", getValues(bundleGroupFilter.isActive)))
    }
    if (bundleGroupFilter.fromDate && !bundleGroupFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("validFrom")
          .gt(dayjs(bundleGroupFilter.fromDate).subtract(1, "day").format("YYYY-MM-DD"))
          .format("yyyy-MM-dd")
          .timeZone(timeZone.value)
      )
    } else if (!bundleGroupFilter.fromDate && bundleGroupFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("validTo")
          .lt(dayjs(bundleGroupFilter.toDate).add(1, "day").format("YYYY-MM-DD"))
          .format("yyyy-MM-dd")
          .timeZone(timeZone.value)
      )
    } else if (bundleGroupFilter.fromDate && bundleGroupFilter.toDate) {
      boolQuery
        .must(
          esb
            .rangeQuery("validFrom")
            .gt(dayjs(bundleGroupFilter.fromDate).subtract(1, "day").format("YYYY-MM-DD"))
            .format("yyyy-MM-dd")
            .timeZone(timeZone.value)
        )
        .must(
          esb
            .rangeQuery("validTo")
            .lt(dayjs(bundleGroupFilter.toDate).add(1, "day").format("YYYY-MM-DD"))
            .format("yyyy-MM-dd")
            .timeZone(timeZone.value)
        )
    }

    boolQuery.must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)])

    setEsbQuery(esb.requestBodySearch().query(boolQuery))
  }, [bundleGroupFilter, searchQuery, timeZone])

  const resetFilter = () => {
    setBundleGroupFilter(defaultBundleGroupFilter)
  }

  return (
    <Filter
      isFilterActive={isFilterActive}
      setNumberOfHits={setNumberOfHits}
      numberOfHitsOptions={numberOfHitsOption}
      selectedNumberOfHits={bundleGroupFilter?.numberOfHits}
      resetFilter={resetFilter}
      totalHits={totalHits}
      searchOptions={{
        handleChange: (input: string) => {
          setSearchQuery(input)
        },
        loading: loading,
        defaultValue: searchQuery
      }}
      totalFilters={totalFilters}
    >
      <FilterSection
        placeHolder="Active/inactive bundle groups"
        setValue={setActiveRuleFilter}
        options={isActiveOptions}
        defaultValue={bundleGroupFilter.isActive}
        icon={<CheckIcon />}
      />
      <FilterDate
        defaultFromDate={bundleGroupFilter?.fromDate}
        defaultToDate={bundleGroupFilter?.toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        type={"date"}
      />
      <BoldText>Time zone</BoldText>
      <BundleWrapper>
        <Icon>
          <EarthIcon />
        </Icon>
        <SingleSelect
          options={timeZones.map((timeZone) => ({ value: timeZone, label: timeZone }))}
          setValue={setTimeZone}
          defaultValue={{
            value: Intl.DateTimeFormat().resolvedOptions().timeZone,
            label: Intl.DateTimeFormat().resolvedOptions().timeZone
          }}
        />
      </BundleWrapper>

    </Filter>
  )
}
export default BundleGroupFilters
