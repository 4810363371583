import gql from "graphql-tag"

export const VALIDATE_QLIRO_ONE = gql`
  query validateQliroOne($input: InputQliroOneConfig!) {
    Configuration {
      validate {
        qliroOne(input: $input) {
          errorMessage
          valid
        }
      }
    }
  }
`
