import React, { useEffect } from "react"
import esb, { RequestBodySearch } from "elastic-builder"
import dayjs from "dayjs"
import { Common } from "@lib/types"
import { ReactComponent as CheckIcon } from "images/icons/circle-check.svg"

import FilterDate from "components/Ui/Table/Filter/FilterDate"
import Filter from "components/Ui/Table/Filter/Filter"
import FilterSection from "components/Ui/Table/Filter/FilterSection"

type Props = {
  setSearchQuery: (input: string) => void
  loading: boolean
  searchQuery: string
  setEsbQuery: (query: RequestBodySearch) => void
  ruleFilter: Common.DiscountRuleFilter
  setRuleFilter: (filter: Common.DiscountRuleFilter) => void
  numberOfHitsOption: string[]
  defaultRuleFilter: Common.DiscountRuleFilter
  totalHits: number | undefined
}

const DiscountCodeRuleFilters = ({
  setSearchQuery,
  loading,
  searchQuery,
  setEsbQuery,
  ruleFilter,
  setRuleFilter,
  numberOfHitsOption,
  defaultRuleFilter,
  totalHits
}: Props) => {
  const isFilterActive = () => {
    const { isActive, fromDate, toDate } = ruleFilter
    return fromDate !== "" || toDate !== "" || isActive.length > 0
  }

  const getValues = (options: Common.Option[]) => {
    return options.map((option) => option.value)
  }

  const setActiveRuleFilter = (value: Common.Option[]) =>
    setRuleFilter({ ...ruleFilter, ...{ isActive: value } })
  const setFromDate = (value: string) =>
    setRuleFilter({ ...ruleFilter, ...{ fromDate: value } })
  const setToDate = (value: string) => setRuleFilter({ ...ruleFilter, ...{ toDate: value } })
  const setNumberOfHits = (value: string) =>
    setRuleFilter({ ...ruleFilter, ...{ numberOfHits: value } })

  const isActiveOptions = [
    { value: "true", label: "Active" },
    { value: "false", label: "Inactive" }
  ]
  const fromDate = ruleFilter.fromDate === "" ? 0 : 1
  const toDate = ruleFilter.toDate === "" ? 0 : 1
  const totalFilters = ruleFilter.isActive.length + fromDate + toDate

  useEffect(() => {
    const boolQuery = esb.boolQuery()

    if (ruleFilter.isActive.length > 0) {
      boolQuery.must(esb.termsQuery("isActive", getValues(ruleFilter.isActive)))
    }
    if (ruleFilter.fromDate && !ruleFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("validFrom")
          .gt(dayjs(ruleFilter.fromDate).subtract(1, "day").format("YYYY-MM-DD"))
          .format("yyyy-MM-dd")
          .timeZone("Europe/Stockholm")
      )
    } else if (!ruleFilter.fromDate && ruleFilter.toDate) {
      boolQuery.must(
        esb
          .rangeQuery("validTo")
          .lt(dayjs(ruleFilter.toDate).add(1, "day").format("YYYY-MM-DD"))
          .format("yyyy-MM-dd")
          .timeZone("Europe/Stockholm")
      )
    } else if (ruleFilter.fromDate && ruleFilter.toDate) {
      boolQuery
        .must(
          esb
            .rangeQuery("validFrom")
            .gt(dayjs(ruleFilter.fromDate).subtract(1, "day").format("YYYY-MM-DD"))
            .format("yyyy-MM-dd")
            .timeZone("Europe/Stockholm")
        )
        .must(
          esb
            .rangeQuery("validTo")
            .lt(dayjs(ruleFilter.toDate).add(1, "day").format("YYYY-MM-DD"))
            .format("yyyy-MM-dd")
            .timeZone("Europe/Stockholm")
        )
    }

    boolQuery.must([esb.queryStringQuery(searchQuery.trim() + "*").analyzeWildcard(true)])

    setEsbQuery(esb.requestBodySearch().query(boolQuery))
  }, [ruleFilter, searchQuery])

  const resetFilter = () => {
    setRuleFilter(defaultRuleFilter)
  }

  return (
    <Filter
      isFilterActive={isFilterActive}
      setNumberOfHits={setNumberOfHits}
      numberOfHitsOptions={numberOfHitsOption}
      selectedNumberOfHits={ruleFilter?.numberOfHits}
      resetFilter={resetFilter}
      totalHits={totalHits}
      searchOptions={{
        handleChange: (input: string) => {
          setSearchQuery(input)
        },
        loading: loading,
        defaultValue: searchQuery
      }}
      totalFilters={totalFilters}
    >
      <FilterSection
        placeHolder="Active/inactive discount rules"
        setValue={setActiveRuleFilter}
        options={isActiveOptions}
        defaultValue={ruleFilter.isActive}
        icon={<CheckIcon />}
      />
      <FilterDate
        defaultFromDate={ruleFilter?.fromDate}
        defaultToDate={ruleFilter?.toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        type={"date"}
      />
    </Filter>
  )
}
export default DiscountCodeRuleFilters
