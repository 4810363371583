import React, { useState } from "react"
import {
  Maybe,
  OrderDelivery,
  OrderGift,
  OrderGiftCardProductItemRetain24,
  OrderLine,
  ShippingFee
} from "lib/types/generated/graphql-types"

import dayjs from "dayjs"
import Money from "../../Money"
import DineroFactory from "dinero.js"
import {
  Action,
  AdditionalData,
  BaseData,
  Bold,
  Container,
  Dates, Discount,
  ExpandIcon,
  Footer,
  Id,
  ImageContainer,
  Info, Label,
  NotCompleted,
  OrderLineWrapper,
  PaymentInfo, PriceWrapper,
  ProductImage,
  ProviderStatus, Row,
  TotalTitle
} from "./Shared.styled"
import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"
import placeholder from "../../../images/placeholder.svg"
import Totals from "./Totals"
import { useQuery } from "@apollo/client"
import GET_DELIVERY from "../../../graphql/queries/order/GetDelivery"
import Status from "../../Ui/Status"
import LoadingRow from "./LoadingRow"
import { ReactComponent as ShippingIcon } from "images/icons/cart-flatbed-boxes.svg"
import { ReactComponent as GiftCardIcon } from "images/icons/gift-card.svg"

type Props = {
  deliveryId: string
  currencyCode: string
  orderLines: OrderLine[]
  gifts: OrderGift[]
  shippingFees: ShippingFee[]
  giftCardProducts: Maybe<OrderGiftCardProductItemRetain24[]> | undefined
}
const Delivery = ({ currencyCode, orderLines, gifts, deliveryId, shippingFees, giftCardProducts }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  const getOrderLine = (id: string) => {
    return orderLines?.find((ol) => ol.id === id)
  }
  const getGiftLine = (id: string) => {
    return gifts?.find((og) => og.id === id)
  }
  const getShippingFee = (id: string) => {
    return shippingFees?.find((fee) => fee.id === id)
  }

  const getGiftCardProduct = (id: string) => {
    return giftCardProducts?.find((giftCardProduct) => giftCardProduct.id === id)
  }

  const { data, loading } = useQuery(GET_DELIVERY, { variables: { deliveryId: deliveryId } })

  const delivery = data?.Order?.delivery as OrderDelivery
  if (loading) return <LoadingRow />
  return (
    <Container>
      <BaseData onClick={() => setExpanded(!expanded)}>
        {!delivery.completed && <NotCompleted>Not completed</NotCompleted>}
        <h3>Delivery #{delivery.id}</h3>
        <Dates>
          <div>
            Started: <b>{dayjs(delivery.created).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          <div>
            Last updated: <b>{dayjs(delivery.updated).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          {delivery.completed && (
            <div>
              Completed: <b>{dayjs(delivery?.completed).format("YYYY-MM-DD : HH:mm:ss")}</b>
            </div>
          )}
          <div>
            Order lines: <b>{delivery.orderLines.length}</b>
          </div>
          {delivery.gifts.length > 0 && (
            <div>
              Gifts: <b>{delivery.gifts.length}</b>
            </div>
          )}
          {delivery?.restarted &&
            <>
            <div>
            Restarted: <b>{dayjs(delivery.restarted).format("YYYY-MM-DD : HH:mm:ss")}</b>
          </div>
          <div>
            Restarts: <b>{delivery.restarts}</b>
          </div>
            </>
          }
        </Dates>
      </BaseData>
      {expanded && (
        <AdditionalData>
          {delivery.orderLines.map((ol) => (
            <OrderLineWrapper key={ol.orderLineId}>
              <Info>
                <ImageContainer>
                  <ProductImage
                    src={getOrderLine(ol.orderLineId)?.imageUrl as string}
                    onError={handleImageError}
                  />
                </ImageContainer>
                <div>
                  <Bold>{getOrderLine(ol.orderLineId)?.name}</Bold>
                  <PriceWrapper>
                      {" "}
                      <Money
                        amount={parseInt(`${ol.totalAmount}`)}
                        currencyUnit={currencyCode as DineroFactory.Currency}
                      />
                    {ol?.totalDiscountAmount > 0 &&
                      <Discount>
                        <Money
                          amount={parseInt(`${ol.totalDiscountAmount + ol.totalAmount}`)}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </Discount>
                    }
                  </PriceWrapper>
                  <div>Order quantity: {getOrderLine(ol.orderLineId)?.quantity}</div>
                  <Id>{getOrderLine(ol.orderLineId)?.productVariantId}</Id>
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>
                  {getOrderLine(ol.orderLineId)?.quantity === ol.quantity
                    ? "Was delivered"
                    : "Was delivered in part"}
                </span>
                <span>
                  <b>Items delivered:</b> {ol.quantity}/{getOrderLine(ol.orderLineId)?.quantity}
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {delivery.gifts.map((gift) => (
            <OrderLineWrapper key={gift.giftId}>
              <Info>
                <ImageContainer>
                  <ProductImage
                    src={getGiftLine(gift.giftId)?.imageUrl as string}
                    onError={handleImageError}
                  />
                </ImageContainer>
                <div>
                  <Bold>Gift: {getGiftLine(gift.giftId)?.displayName}</Bold>
                  <div>Order quantity: {getGiftLine(gift.giftId)?.quantity}</div>
                  <Id>{getGiftLine(gift.giftId)?.productVariantId}</Id>
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>
                  {getGiftLine(gift.giftId)?.quantity === gift.quantity
                    ? "Was delivered"
                    : "Was delivered in part"}
                </span>
                <span>
                  <b>Items delivered:</b> {gift.quantity}/{getGiftLine(gift.giftId)?.quantity}
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {delivery?.giftCardProducts?.map((giftCardProduct) => (
            <OrderLineWrapper key={giftCardProduct.giftCardProductId}>
              <Info>
                <span>
                  <GiftCardIcon />
                </span>
                <div>
                  <Bold>Gift card: {getGiftCardProduct(giftCardProduct?.giftCardProductId)?.name}</Bold>
                  <Money
                    amount={parseInt(`${giftCardProduct.priceAmount}`)}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                  <Status status={giftCardProduct?.status ?? ""} />
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>Was delivered
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          {delivery?.shippingFees?.map((fee) => (
            <OrderLineWrapper key={fee.shippingFeeId}>
              <Info>
                <span>
                  <ShippingIcon />
                </span>
                <div>
                  <Bold>Shipping: {getShippingFee(fee.shippingFeeId)?.displayName}</Bold>
                  <Money
                    amount={parseInt(`${fee.totalAmount}`)}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </div>
              </Info>
              <Action>
                <span>
                  <b>Action:</b>Was delivered
                </span>
              </Action>
            </OrderLineWrapper>
          ))}
          <ProviderStatus>
            <div>
              <Label>Payment status:</Label>{" "}
              <Status status={delivery.paymentProvider.status?.current ?? ""} />
            </div>
            {delivery?.shippingProvider && (
              <div>
                <Label>Shipping status:</Label>
                <Status status={delivery?.shippingProvider?.status?.current ?? ""} />
              </div>
            )}
            {delivery?.bonusProvider && (
              <div>
                <Label>Bonus status:</Label>
                <Status status={delivery?.bonusProvider?.status?.current ?? ""} />
              </div>
            )}
            {delivery?.giftCardProvider && (
              <div>
                <Label>Gift card status:</Label>
                <Status status={delivery?.giftCardProvider?.status?.current ?? ""} />
              </div>
            )}
          </ProviderStatus>
          <PaymentInfo>
            {delivery?.capturedPayment && (
              <div>
                Payment reference: <b>{delivery.capturedPayment?.reference}</b>
              </div>
            )}
            {delivery?.bonus && (
              <div>
                Bonus reservation id: <b>{delivery.bonus?.reservationId}</b>
              </div>
            )}
            {delivery?.tracking && (
              <>
                <div>
                  Tracking reference: <b>{delivery.tracking?.reference}</b>
                </div>
                <div>
                  Tracking url: <b>{delivery.tracking?.url}</b>
                </div>
                {delivery.tracking?.shippingCompany && (
                  <div>
                    Shipping company: <b>{delivery.tracking?.shippingCompany}</b>
                  </div>
                )}
                <div>
                  Shipping method: <b>{delivery.tracking?.shippingMethod}</b>
                </div>
              </>
            )}
            {delivery?.giftCards.length > 0 && (
              <>
                {`Gift card${delivery?.giftCards.length > 1 ? "s" : ""}:`}
                {delivery.giftCards.map((giftCard) => (
                  <Row key={giftCard.giftCardId}>
                    <span>
                      Id: <b>{giftCard.giftCardId}</b>
                    </span>
                    <span>
                      Amount:{" "}
                      <b>
                        <Money
                          amount={giftCard.amount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </b>
                    </span>
                    <span>
                      Status: <Status status={giftCard.status} />
                    </span>
                  </Row>
                ))}
              </>
            )}
            {delivery?.vouchers.length > 0 && (
              <>
                {`Voucher${delivery?.vouchers.length > 1 ? "s" : ""}:`}
                {delivery.vouchers.map((voucher) => (
                  <Row key={voucher.voucherId}>
                    <span>
                      provider: <b>{delivery?.voucherProvider?.providerName}</b>
                    </span>
                    <span>
                      Id: <b>{voucher.voucherId}</b>
                    </span>
                    <span>
                      Amount:{" "}
                      <b>
                        <Money
                          amount={voucher.amount}
                          currencyUnit={currencyCode as DineroFactory.Currency}
                        />
                      </b>
                    </span>
                  </Row>
                ))}
              </>
            )}
          </PaymentInfo>
          <Totals totals={delivery.totals} currencyCode={currencyCode} label="Captured amount" />
        </AdditionalData>
      )}
      <Footer>
        <div>
          <TotalTitle>Total:</TotalTitle>
          <TotalTitle>
            <Money
              amount={parseInt(`${delivery.totals.grandTotal}`)}
              currencyUnit={currencyCode as DineroFactory.Currency}
            />
          </TotalTitle>
        </div>
      </Footer>
      <ExpandIcon $expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <DownIcon />
      </ExpandIcon>
    </Container>
  )
}

export default Delivery
