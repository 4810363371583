import React from "react"
import { Authenticator } from "@aws-amplify/ui-react"
import { Authentication } from "./Authentication"

function App() {
  return (
    <Authenticator.Provider>
      <Authentication />
    </Authenticator.Provider>
  )
}

export default App
