import gql from "graphql-tag"

const GET_EXTERNAL_EVENT_RULES = gql`
  query getExternalEventsRules {
    getExternalEventsRules {
      rules {
        ruleName
        state
      }
    }
  }
`

export default GET_EXTERNAL_EVENT_RULES
