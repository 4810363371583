import React, { ChangeEvent, MutableRefObject, useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useLazyQuery } from "@apollo/client"
import esb from "elastic-builder"
import { Query } from "@lib/types/common"
import { Outcomes, FrontendOutcome } from "@lib/types/discount"
import { Gift, ProductVariantSearchHit } from "@lib/types/generated/graphql-types"
import { v4 as uuidv4 } from "uuid"
import { Controller, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"

import SEARCH_PRODUCT_VARIANTS from "graphql/queries/product/SearchProductVariants"

import QuickAdd from "components/Ui/Table/QuickAdd"
import Input from "components/Ui/Form/Input"
import TableHeader from "components/Ui/Table/TableHeader"
import TableRow from "components/Ui/Table/TableRow"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

const ConditionsAdd = styled(QuickAdd)`
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 2rem 0;
  position: relative;
`

const Hits = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1rem 1.2rem 0.6rem;
  padding: 2rem;
  background: ${(p) => p.theme.colors.white};
  position: absolute;
  top: calc(100% - 1rem);
  left: 0;
  right: 0;
  z-index: 1;

  ul {
    margin-top: 0;
  }
`

const Hit = styled.li`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding: 1.2rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Name = styled.span`
  display: block;
  ${(p) => p.theme.bold}
`

const Id = styled.span`
  color: ${(p) => p.theme.colors.greyDark};
`

const Qty = styled(Input)`
  width: 8rem;
  padding-bottom: 0;
  margin-right: 1rem;
`

const HitCount = styled.div`
  text-align: right;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  padding-bottom: 1rem;
`

const StyledTableHeader = styled(TableHeader)`
  padding-right: 8.5rem;

  div {
    &:first-child {
      flex: 60%;
    }
  }
`

const SelectedGift = styled(TableRow)`
  div {
    &:first-child {
      ${(p) => p.theme.bold}
      flex: 60%;
    }
  }
`

const Flex = styled.div`
  flex: 1;
`

const NotFound = styled.p`
  text-align: center;
`

type SearchHit = {
  id: string
  name: string
}

type Inputs = {
  search: string
  qty: number
}

type Props = {
  outcome?: FrontendOutcome
  addOutcome: (data: Outcomes) => void
  submitRef: MutableRefObject<HTMLButtonElement>
}

export const GiftWithPurchase = ({ outcome, addOutcome, submitRef }: Props) => {
  const dispatch = useAppDispatch()
  const [productVariants, setProductVariants] = useState<ProductVariantSearchHit[]>([])
  const [gifts, setGifts] = useState<Gift[]>(outcome?.data?.gifts ?? [])
  const [searchInput, setSearchInput] = useState("")
  const { control, setValue } = useForm<Inputs>()

  const referenceSearchQuery = esb.requestBodySearch().query(
    esb
      .boolQuery()
      .must(esb.termQuery("isActive", true))
      .must([esb.queryStringQuery(`*${searchInput}*`).analyzeWildcard(true)])
  )

  const query = referenceSearchQuery.toJSON() as Query
  const variables = {
    from: 0,
    size: 20,
    query: JSON.stringify(query.query)
  }

  const [searchProductVariants] = useLazyQuery(SEARCH_PRODUCT_VARIANTS, {
    variables
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        searchProductVariants({
          variables
        }).then((response) => {
          setProductVariants(response?.data?.searchProductVariants.hits)
        })
      },
      searchInput === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [searchInput])

  const onSubmit = () => {
    if (gifts.length > 0) {
      addOutcome({
        gifts: gifts
      })
    } else {
      dispatch(
        alertActions.actions.createAlert({
          message: "At least one product is required",
          type: "error"
        })
      )
    }
  }

  const updateGiftQty = (productVariantId: string, qty: string) => {
    const updatedQtyGifts = gifts.map((gift: Gift) =>
      gift.productVariantId === productVariantId ? { productVariantId, quantity: parseInt(qty) } : gift
    )
    setGifts(updatedQtyGifts)
  }

  const removeProductVariant = (index: number) => {
    const clone = [...gifts]
    clone.splice(index, 1)
    setGifts(clone)
  }

  return (
    <>
      <ConditionsAdd>
        <Controller
          name="search"
          render={({ field }) => (
            <Input
              {...field}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(e.target.value)
              }}
              placeholder="Search product variants to select as gift"
              label="Select gift:"
            />
          )}
          control={control}
        />

        <>
          {searchInput !== "" && (
            <Hits>
              <HitCount>{productVariants.length} results</HitCount>
              <ul>
                {productVariants.length > 0 ? (
                  productVariants.map((hit: SearchHit) => (
                    <Hit key={uuidv4()}>
                      <Flex>
                        <Name>{hit.name}</Name>
                        <Id>{hit.id}</Id>
                      </Flex>
                      <PrimaryButton
                        handleClick={() => {
                          setValue("search", "")
                          setSearchInput("")
                          setGifts((prev) => [
                            ...prev,
                            {
                              productVariantId: hit.id,
                              quantity: 1
                            }
                          ])
                        }}
                      >
                        Add
                      </PrimaryButton>
                    </Hit>
                  ))
                ) : (
                  <NotFound>No search results found...</NotFound>
                )}
              </ul>
            </Hits>
          )}
        </>
      </ConditionsAdd>
      <h3>Selected gifts</h3>
      {gifts.length > 0 ? (
        <>
          <StyledTableHeader>
            <div>Variant ID</div>
            <div>Quantity</div>
          </StyledTableHeader>
          <div>
            {gifts.map((gift, index) => (
              <SelectedGift
                key={gift.productVariantId}
                deleteEnabled
                smallButtons
                disableHover
                handleDelete={() => {
                  removeProductVariant(index)
                }}
              >
                <Flex>{gift.productVariantId}</Flex>
                <Flex>
                  <Controller
                    name="qty"
                    render={({ field }) => (
                      <Qty
                        {...field}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          updateGiftQty(gift.productVariantId, e.target.value)
                        }
                        placeholder="Qty"
                        type="number"
                        defaultValue={gift.quantity}
                        removePaddingBottom
                      />
                    )}
                    control={control}
                  />
                </Flex>
              </SelectedGift>
            ))}
          </div>
        </>
      ) : (
        <NotFound>No gifts selected</NotFound>
      )}
      <button onClick={() => onSubmit()} ref={submitRef} type="button" style={{ display: "none" }} />
    </>
  )
}
