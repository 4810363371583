import React, { useState } from "react"
import OutsideClickHandler from "react-outside-click-handler"

import {
  Container,
  DropDownButton,
  Dropdown,
  Flex,
  SaveButton,
  ArrowDownButton
} from "./ActionDropdownButton.styled"

import { ReactComponent as DownIcon } from "images/icons/chevron-down.svg"

type Props = {
  title: string
  children: JSX.Element | JSX.Element[]
  submitEvent?: () => void
  submitLoading?: boolean
  overrideDisabled?: boolean
  disabled?: boolean
}

export const ActionDropdownButton = ({ title, children, submitEvent, submitLoading, overrideDisabled, disabled }: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  return (
    <Container>
      <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
        {submitEvent ? (
          <Flex>
            <SaveButton type="button" disabled={disabled} loading={submitLoading} handleClick={submitEvent} overrideDisabled={overrideDisabled}>
              {title}
            </SaveButton>
            <ArrowDownButton type="button" handleClick={() => setShowDropdown(!showDropdown)} overrideDisabled={overrideDisabled}>
              <DownIcon />
            </ArrowDownButton>
          </Flex>
        ) : (
          <DropDownButton type="button" handleClick={() => setShowDropdown(!showDropdown)}>
            {title} <DownIcon />
          </DropDownButton>
        )}
        {showDropdown && <Dropdown>{children}</Dropdown>}
      </OutsideClickHandler>
    </Container>
  )
}
