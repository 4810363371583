import React from "react"
import { Control, Controller } from "react-hook-form"
import Input from "../../Ui/Form/Input"
import { SidebarDivider } from "../../Dashboard/Shared.styled"
import { InputVoyadoConfig, VoyadoConfig } from "@lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"

interface ExtendedInputVoyadoConfig extends InputVoyadoConfig {
  id: string
}

type Props = {
  control: Control<ExtendedInputVoyadoConfig>
  errors: FieldErrors<ExtendedInputVoyadoConfig>
  defaultValues?: VoyadoConfig
  showId?: boolean
}

const isValidHttpUrl = (string: string) => {
  try {
    const newUrl = new URL(string)
    return newUrl.protocol === "https:"
  } catch (err) {
    return false
  }
}

const VoyadoConfigInputs = ({ control, errors, showId, defaultValues }: Props) => {
  return (
    <>
      { showId && <Controller
        name="id"
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            label="Identifier *"
            description="For internal use in Brink"
            placeholder="Aa - Zz, 0 - 9, -_."
            errors={errors}
          />
        )}
        control={control}
        rules={{
          required: "This is a required field",
          pattern: {
            value: /^[A-Za-z0-9_.-]+$/,
            message: "Only characters Aa-Zz and 0-9 are allowed. No spaces"
          }
        }}
        defaultValue=""
      />}
      <SidebarDivider />
      <Controller
        name="apiKey"
        render={({ field }) => (
          <Input {...field} type="text" label="Voyado API-key *" errors={errors} />
        )}
        control={control}
        rules={{
          required: "This is a required field"
        }}
        defaultValue=""
      />
      <Controller
        name="baseUrl"
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            label="Voyado base URL *"
            description="Must be https:// format"
            errors={errors}
          />
        )}
        control={control}
        rules={{
          validate: {
            url: (value) => {
              if (!isValidHttpUrl(value)) return "Must be https:// format"
            }
          },
          required: "This is a required field"
        }}
        defaultValue={defaultValues?.baseUrl ?? ""}
      />
    </>
  )
}

export default VoyadoConfigInputs
