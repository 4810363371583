import gql from "graphql-tag"

const CREATE_CANCELLATION = gql`
  mutation createCancellation($orderId: ID!, $input: InputOrderCancellation!) {
    Order {
      createCancellation(orderId: $orderId, input: $input) {
        id
      }
    }
  }
`

export default CREATE_CANCELLATION
