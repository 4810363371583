import React, { Dispatch, SetStateAction } from "react"
import { Percentage } from "../CampaignInput.styled"
import Input from "../../Ui/Form/Input"
import CampaignThreshold from "./CampaignThreshold"
import {
  PriceRuleContainer,
  TypeSelector,
  TypeSelectorContainer,
  Description
} from "./PriceRule.styled"
import {
  InputStoreMarket,
  InputPriceRule,
  InputPriceRuleType,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { ReactComponent as PercentageIcon } from "images/icons/badge-percent.svg"
import { ReactComponent as FixedPriceIcon } from "images/icons/badge-dollar.svg"
import { ReactComponent as SetPriceIcon } from "images/icons/hand-holding-dollar.svg"
import { isCampaignUser, isSuperUser } from "helpers/user"

type Props = {
  priceRule: InputPriceRule
  setPriceRule: Dispatch<SetStateAction<InputPriceRule>>
  addedStoreMarkets: InputStoreMarket[]
  storeGroups: StoreGroup[] | undefined
  isCampaignOngoing?: boolean
}
const PriceRule = ({ priceRule, setPriceRule, addedStoreMarkets, storeGroups , isCampaignOngoing}: Props) => {
  const updateType = (type: InputPriceRuleType) => {
    setPriceRule({ type: type })
  }

  const addMoney = (currency: string, amount: number) => {
    setPriceRule({
      ...priceRule,
      monies: [...(priceRule?.monies ?? []), { key: currency, value: amount }]
    })
  }

  return (
    <>
      <h2>Price rule</h2>
      <Description>
        A price rule determines how the sale prices of the products you&apos;ve selected will be
        calculated.
      </Description>
      <PriceRuleContainer>
        <TypeSelectorContainer>
          <TypeSelector
            $active={priceRule.type === InputPriceRuleType.RelativeDiscount}
            onClick={(isSuperUser() || isCampaignUser()) && !isCampaignOngoing ? () => updateType(InputPriceRuleType.RelativeDiscount): undefined}
          >
            <PercentageIcon />
            <b>Percentage</b>
            Discount in percentage
          </TypeSelector>
          <TypeSelector
            $active={priceRule.type === InputPriceRuleType.FixedDiscount}
            onClick={(isSuperUser() || isCampaignUser()) && !isCampaignOngoing ? () => updateType(InputPriceRuleType.FixedDiscount): undefined}
          >
            <FixedPriceIcon />
            <b>Fixed amount</b>
            Define a fixed amount of discount to be subtracted from base price
          </TypeSelector>
          <TypeSelector
            $active={priceRule.type === InputPriceRuleType.SetPrice}
            onClick={(isSuperUser() || isCampaignUser()) && !isCampaignOngoing ? () => updateType(InputPriceRuleType.SetPrice): undefined}
          >
            <SetPriceIcon />
            <b>Set price</b>
            Set a fixed sale price for products
          </TypeSelector>
        </TypeSelectorContainer>
        <>
          {priceRule?.type === InputPriceRuleType.RelativeDiscount && (
            <>
              <Percentage>
                <Input
                  name="percentage"
                  type="number"
                  placeholder="Percentage, 1 - 100"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPriceRule({ ...priceRule, percentage: parseInt(event.target.value) })
                  }
                  disabled={isCampaignOngoing}
                  label="Percentage % *"
                  defaultValue={priceRule?.percentage ?? ""}
                />
                <Input
                  name="percentageDecimals"
                  type="number"
                  disabled={isCampaignOngoing}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setPriceRule({ ...priceRule, percentageDecimals: parseInt(event.target.value) })
                  }
                  placeholder="Percentage decimals"
                  label="Percentage decimals *"
                  defaultValue={priceRule?.percentageDecimals ?? ""}
                />
              </Percentage>
              <div>
                <strong>Formatted: </strong>
                {(priceRule?.percentageDecimals === 0 && priceRule?.percentage) ? priceRule?.percentage + "%" : priceRule?.percentage && priceRule?.percentageDecimals
                  ? priceRule?.percentage / 10 ** priceRule?.percentageDecimals + "%"
                  : "0"}
              </div>
            </>
          )}

          {(priceRule?.type === InputPriceRuleType.FixedDiscount ||
            priceRule?.type === InputPriceRuleType.SetPrice) && (
            <CampaignThreshold
              isCampaignOngoing={isCampaignOngoing ?? false}
              thresholds={priceRule?.monies ?? []}
              addMoney={addMoney}
              addedStoreMarkets={addedStoreMarkets}
              storeGroups={storeGroups}
              priceRule={priceRule}
              setPriceRule={setPriceRule}
            />
          )}
        </>
      </PriceRuleContainer>
    </>
  )
}

export default PriceRule
