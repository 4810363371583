import React from "react"
import { Flex, Hit, HitBlur, Id, Name, Stock } from "./AddOrUpdateProductVariant.styled"
import { v4 as uuidv4 } from "uuid"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import {  useQuery } from "@apollo/client"
import GET_PRODUCT_VARIANT from "../../../../../graphql/queries/product/GetProductVariant"
import { ProductVariant} from "@lib/types/generated/graphql-types"

type Props = {
  variantId: string
  addProductVariant: (productVariant: ProductVariant) => void
}

const ProductVariantSearchHitRow = ({
  variantId,
  addProductVariant,
}: Props) => {


  const { data, loading } = useQuery(GET_PRODUCT_VARIANT, {
    variables: { id: variantId },
    fetchPolicy: "cache-first"
  })

  const productVariant = data?.Product?.variant
  if (loading)
    return (
      <HitBlur>
        <Flex>
          <Name>Product name</Name>
          <Id>product id</Id>
          <Stock>Stock: 1</Stock>
        </Flex>
        <PrimaryButton disabled={true}>Add</PrimaryButton>
      </HitBlur>
    )
  if (!productVariant) return null
  return (
    <Hit key={uuidv4()}>
      <Flex>
        <Name>{productVariant.name}</Name>
        <Id>{productVariant.id}</Id>
        <Stock>Stock: {productVariant?.Stock?.stockQuantity}</Stock>
      </Flex>
      <PrimaryButton
        handleClick={() => {
          addProductVariant(productVariant)
        }}
        disabled={!productVariant?.Stock?.stockQuantity || productVariant?.Stock?.stockQuantity === 0}
      >
        Add
      </PrimaryButton>
    </Hit>
  )
}

export default ProductVariantSearchHitRow
