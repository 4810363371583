import React from "react"
import { Controller } from "react-hook-form"
import { Control } from "react-hook-form/dist/types/form"
import { DiscountRule } from "lib/types/generated/graphql-types"
import { FieldErrors } from "react-hook-form/dist/types/errors"

import { Container, Name, SortOrder, CheckboxWrapper } from "./DiscountRuleGeneralInput.styled"

import Input from "components/Ui/Form/Input"
import Checkbox from "components/Ui/Form/Checkbox"
import TimePeriodSelection from "../TimePeriodSelection"
import { DiscountRuleInputs } from "@lib/types/discount"

type Props = {
  cartRule: DiscountRule
  control: Control<DiscountRuleInputs>
  errors: FieldErrors<DiscountRuleInputs>
}

const CartRuleGeneralUpdateInput = ({ cartRule, control, errors }: Props) => {
  if (!cartRule) return null

  return (
    <div>
      <Container>
        <div>
          <h2>General</h2>
          <p>General inputs for discount rule applicable in cart without code</p>
          <Name>
            <Controller
              name="name"
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  label="Name *"
                  placeholder="Aa - Zz, 0 - 9"
                  errors={errors}
                />
              )}
              control={control}
              defaultValue={cartRule.name}
              rules={{
                required: "This is a required field"
              }}
            />
          </Name>
          <SortOrder>
            <Controller
              name="sortOrder"
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  label="Sort order *"
                  placeholder="Minimum value is 1"
                  errors={errors}
                />
              )}
              control={control}
              defaultValue={cartRule.sortOrder}
              rules={{
                required: "This is a required field",
                min: { value: 1, message: "Minimum value is 1" }
              }}
            />
          </SortOrder>
        </div>
        <CheckboxWrapper>
          <Checkbox
            control={control}
            name="isActive"
            label="Active"
            defaultValue={cartRule.isActive}
          />
          <Checkbox
            control={control}
            name="isStackable"
            label="Is stackable"
            description="Can this rule stack with other rules?"
            defaultValue={cartRule.isStackable}
          />
        </CheckboxWrapper>
      </Container>
      <hr />
      <TimePeriodSelection control={control} errors={errors} defaultValues={cartRule.validDateRange ?? undefined} />
    </div>
  )
}

export default CartRuleGeneralUpdateInput
