import React from "react"
import * as graphql from "@lib/types/generated/graphql-types"
import FailedRefund from "./FailedRefund"
import { Container } from "../../../../views/Orders/ManageOrder/ManageOrder.styled"
import { OrderSummary } from "./OrderSummary/OrderSummary"
import { Cart } from "./Cart/Cart"

type Props = {
  order: graphql.Order
  refetch: () => void
  loading: boolean
  failedRefunds: graphql.OrderRefund[]
  orderHistory: graphql.OrderHistory
}

const RefundOrder = ({ order, refetch, loading, failedRefunds, orderHistory }: Props) => {
  return (
    <>
      {failedRefunds?.length > 0 ? (
        failedRefunds.map((refund: graphql.OrderRefund) => (
          <FailedRefund
            key={refund.id}
            failedRefund={refund}
            currencyCode={order.currencyCode}
            orderId={order.id ?? ""}
            orderHistory={orderHistory.history}
          />
        ))
      ) : (
        <Container>
          <div>
            <OrderSummary
              order={order}
              refetch={refetch}
              orderLoading={loading}
            />
          </div>
          <div>
            <Cart order={order} refetch={refetch} />
          </div>
        </Container>
      )}
    </>
  )
}

export default RefundOrder
