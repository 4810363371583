import gql from "graphql-tag"

const GET_AVARDA_CONFIG = gql`
  query getAvardaConfig($id: ID!) {
    getAvardaConfig(id: $id) {
      id
      created
      updated
      baseUrl
      authUrl
      shippingBroker {
        name
      }
    }
  }
`

export default GET_AVARDA_CONFIG
