import React, { Dispatch, SetStateAction } from "react"
import Popup from "../../../../Ui/Popup"
import { useQuery } from "@apollo/client"
import { ButtonWrapper, ProviderContainer } from "./StatusPopup.styled"
import Status from "../../../../Ui/Status"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as RefreshIcon } from "../../../../../images/icons/arrow-rotate-left-thick.svg"
import GET_MODIFICATION from "../../../../../graphql/queries/order/GetModification"

type Props = {
  setShowStatusPopup: Dispatch<SetStateAction<boolean>>
  modificationId: string
  refetchOrder: () => void
}

const StatusPopup = ({ setShowStatusPopup, modificationId, refetchOrder }: Props) => {
  const { data, refetch, loading } = useQuery(GET_MODIFICATION, {
    variables: { modificationId },
    fetchPolicy: "network-only"
  })

  const modification = data?.Order?.orderModificationOrderLines

  return (
    <Popup
      title={"Provider status"}
      handleCloseClick={() => {
        refetchOrder()
        setShowStatusPopup(false)
      }}
      handleOkClick={() => {
        refetchOrder()
        setShowStatusPopup(false)
      }}
      buttonText={"Ok"}
    >
      <div>
        <ButtonWrapper>
          <PrimaryButton handleClick={() => refetch()} loading={loading}>
            <RefreshIcon />
            Refresh status
          </PrimaryButton>
        </ButtonWrapper>
          <ProviderContainer>
            <b>Payment</b>
            <Status status={modification?.paymentProvider?.status?.current} />
          </ProviderContainer>
      </div>
    </Popup>
  )
}

export default StatusPopup
