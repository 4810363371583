import { createGlobalStyle } from "styled-components"
import { theme } from "./theme"
import MuseoSansRegular from "./fonts/museosans-300-webfont.woff2"
import MuseoSansBold from "./fonts/museosans-700-webfont.woff2"

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Museo Sans";
    src: url(${MuseoSansRegular}) format("woff2");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Museo Sans Bold";
    src: url(${MuseoSansBold}) format("woff2");
    font-weight: 700;
    font-style: normal;
  }

  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-size-adjust: 100%;
  }

  .disableScroll {
    overflow: hidden;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;

  }

  body {
    color: ${theme.colors.black};
    font-size: 1.3rem;
    line-height: 2.4rem;
    font-family: "Museo Sans", sans-serif;
    font-weight: 300;
    letter-spacing: 0.05rem;

    ${theme.mQ.MEDIA_MIN_MEDIUM} {
      font-size: 1.4rem;
      line-height: 2.6rem;
    }
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    color: ${theme.colors.black};

    &:hover {
      &:after {
        background-color: transparent;
      }
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  h1, h2, h3, h4 {
    font-weight: 700;
    font-family: "Museo Sans Bold", serif;
    letter-spacing: 0.05rem;


  }

  h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;

    ${theme.mQ.MEDIA_MIN_LARGE} {
      font-size: 2.8rem;
      line-height: 3.2rem;

    }
  }

  h2 {
    font-size: 1.8rem;
    line-height: 2rem;
    margin: 2rem 0;
  }

  b, strong {
    font-weight: 700;
    font-family: "Museo Sans Bold", serif;
    letter-spacing: 0.05rem;
  }


  hr {
    border: 0;
    border-bottom: 0.1rem solid ${theme.colors.greyLight}; 
    margin: 3rem 0 4rem;

    ${theme.mQ.MEDIA_MIN_LARGE} {
      margin: 4rem 0 6rem;

    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  button, input, textarea, select {
    -webkit-appearance: none;
    font-family: "Museo Sans", sans-serif;
    font-weight: 300;
    letter-spacing: 0.05rem;
    position: relative;
    
    &:focus {
      outline: none;
    }
  }
  

  label {
    font-weight: 700;
    font-family: "Museo Sans Bold", serif;
    font-size: 1.3rem;
    letter-spacing: 0.05rem;
    color: ${theme.colors.black};
  }

  ::placeholder {
    color: ${theme.colors.greyDarker};
    font-family: "Museo Sans", sans-serif;
    font-weight: 300;
    letter-spacing: 0.05rem;
  }

  ::selection {
    background: ${theme.colors.yellowLight};
 }

 input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  bottom: 0;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  background-position: 98% center;
  background-size: 1.8rem;
}

  button {
    font-family: "Museo Sans", sans-serif;
    font-weight: 700;
  }

  .sidebar-open {
    overflow: hidden;
  }

  .hideOnSmallScreens {
    display: none !important;
    
    ${theme.mQ.MEDIA_MIN_LARGE} {
      display: flex !important;
    }
  }

  .hideOnMeduimScreens {
    display: none !important;
    
    ${theme.mQ.MEDIA_MIN_X_LARGE} {
      display: flex !important;
    }
  }

  /* React tabs */

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-tabs__tab-list {
    margin: 0 0 2rem;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;

    ${theme.mQ.MEDIA_MIN_LARGE} {
      display: flex;
      margin: 0 0 3rem;
      border-bottom: 0.1rem solid ${theme.colors.greyLight};
    }
  }
  
  .react-tabs__tab {
    display: inline-block;
    border: none;
    border-bottom: 0.3rem solid transparent;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem 1.5rem;
    cursor: pointer;
    font-size: 1.4rem;
    border-bottom: 0.1rem solid ${theme.colors.greyLight};


    ${theme.mQ.MEDIA_MIN_LARGE} {
      font-size: 1.5rem;
      border-bottom: none;
    }
  }
  
  .react-tabs__tab--selected {
    border: none;
    border-bottom: 0.3rem solid  ${theme.colors.turquoiseDark};
    background: none;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    display: none;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
`
export default GlobalStyle
