import React from "react"
import styled from "styled-components/macro"

const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.7;

  p {
    font-size: 1.2rem;
    color: ${(p) => p.theme.colors.white};
  }
`

const Footer = () => (
  <Container>
    <p>
      &copy; 2024 <strong>Brink Commerce</strong>. All rights reserved
    </p>
  </Container>
)

export default Footer
