import gql from "graphql-tag"

const RESTART_DELIVERY = gql`
  mutation restartDelivery($deliveryId: ID!, $input: InputOrderStartDelivery!) {
    Order {
      restartDelivery(deliveryId: $deliveryId, input: $input) {
        status
      }
    }
  }
`

export default RESTART_DELIVERY
