import gql from "graphql-tag"

export const VALIDATE_AVARDA = gql`
  query validateAvarda($input: InputAvardaConfig!) {
    Configuration {
      validate {
        avarda(input: $input) {
          errorMessage
          valid
        }
      }
    }
  }
`
