import React from "react"
import PageHeader from "components/Ui/Page/PageHeader"
import { ApiKey } from "components/Settings/Credentials/ApiKey"
import { ReactComponent as WindowIcon } from "images/icons/window.svg"

const Credentials = () => {
  return (
    <>
      <PageHeader
        title="Credentials"
        description={<>Get your API keys for Shopper API and Management API </>}
      />
      <h2>Shopper API</h2>
      <ApiKey
        sendAs="header"
        name="x-shopper-api-key"
        value="Contact Brink support"
        icon={<WindowIcon />}
      />
      <h2>Management API</h2>
      <ApiKey
        sendAs="header"
        name="x-api-key"
        value="BrinkCommerceDefaultApiKey"
        icon={<WindowIcon />}
      />
    </>
  )
}

export default Credentials
