import React, { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { Query, RULE_TYPE } from "lib/types/common"
import { DiscountExternalRuleSearchHit } from "lib/types/generated/graphql-types"
import { Common } from "lib/types"
import { useLocalStorage } from "hooks/useLocalStorage"
import SEARCH_EXTERNAL_RULES from "graphql/queries/discount/SearchDiscountExternalRules"
import Box from "components/Ui/Box"
import DiscountRuleFilters from "../../../components/Discount/DiscountRule/DiscountRuleFilters"
import { DiscountRuleTable } from "../../../components/Discount/DiscountRule/DiscountRuleTable"

export const ShowExternalRules = () => {
  const NUMBER_OF_HITS_OPTIONS = ["25", "50", "100", "150", "200", "250"]

  const DEFAULT_EXTERNAL_RULE_FILTER = {
    fromDate: "",
    toDate: "",
    isActive: [] as Common.Option[],
    numberOfHits: NUMBER_OF_HITS_OPTIONS[0],
    searchQuery: ""
  }

  const [externalRules, setExternalRules] = useState<DiscountExternalRuleSearchHit[]>([])
  const [esbQuery, setEsbQuery] = useState<Query>()
  const [totalDiscountExternalRules, setTotalDiscountExternalRules] = useState<number>()
  const [externalRuleFilter, setExternalRuleFilter] = useLocalStorage<Common.DiscountRuleFilter>(
    "externalRuleFilter",
    DEFAULT_EXTERNAL_RULE_FILTER
  )

  const setSearchQuery = (value: string) =>
    setExternalRuleFilter({ ...externalRuleFilter, ...{ searchQuery: value } })
  const query = esbQuery?.toJSON() as Query

  const [searchDiscountRules, { loading, error }] = useLazyQuery(SEARCH_EXTERNAL_RULES, {
    onCompleted: (data) => {
      setExternalRules(data?.searchDiscountExternalRules)
    }
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        if(query) {
          searchDiscountRules({
            variables: {
              from: 0,
              size: externalRuleFilter?.numberOfHits,
              sort: [{ field: "updated", order: "DESC" }],
              query: JSON.stringify(query?.query)
            }
          }).then((response) => {
            setExternalRules(response?.data?.searchDiscountExternalRules?.hits)
            setTotalDiscountExternalRules(response?.data?.searchDiscountExternalRules?.total)
          })
        }
      },
      externalRuleFilter?.searchQuery === "" ? 0 : 300
    )
    return () => clearTimeout(delayDebounceFn)
  }, [externalRuleFilter?.searchQuery, externalRuleFilter?.numberOfHits, esbQuery])

  return (
    <Box padding="0" margin="0">
      <DiscountRuleFilters
        setSearchQuery={setSearchQuery}
        loading={loading}
        searchQuery={externalRuleFilter?.searchQuery ?? ""}
        setEsbQuery={setEsbQuery}
        ruleFilter={externalRuleFilter}
        setRuleFilter={setExternalRuleFilter}
        numberOfHitsOption={NUMBER_OF_HITS_OPTIONS}
        defaultRuleFilter={DEFAULT_EXTERNAL_RULE_FILTER}
        totalHits={totalDiscountExternalRules}
      />
      <DiscountRuleTable
        type={RULE_TYPE.EXTERNAL_RULE}
        discountRules={externalRules}
        setDiscountRules={setExternalRules}
        loading={loading}
        error={error}
      />
    </Box>
  )
}
