import gql from "graphql-tag"

const UPDATE_STORE_MARKET = gql`
  mutation updateStoreMarket(
    $storeGroupId: String!
    $countryCode: String!
    $currencyCode: String!
    $isTaxIncludedInPrice: Boolean!
    $isTaxExemptionEligible: Boolean
    $isArchived: Boolean
    $capabilities: InputCapabilities
    $cartCapabilities: InputCartCapabilities
  ) {
    updateStoreMarket(
      storeGroupId: $storeGroupId
      countryCode: $countryCode
      storeMarket: {
        currencyCode: $currencyCode
        isTaxIncludedInPrice: $isTaxIncludedInPrice
        isTaxExemptionEligible: $isTaxExemptionEligible
        isArchived: $isArchived
        capabilities: $capabilities
        cartCapabilities: $cartCapabilities
      }
    ) {
      countryCode
      currencyCode
      created
      updated
      description
      name
      storeGroupId
      isTaxIncludedInPrice
      isTaxExemptionEligible
      isArchived
      cartCapabilities {
        voyado {
          id
        }
        bonus {
          id
        }
        retain24 {
          id
        }
      }
      capabilities {
        shippingProviders {
          id
          type
        }
        paymentProviders {
          id
          type
        }
        giftCardProviders {
          id
          type
        }
      }
    }
  }
`

export default UPDATE_STORE_MARKET
