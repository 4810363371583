import styled from "styled-components/macro"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import TableHeader from "components/Ui/Table/TableHeader"

export const StyledTableHeader = styled(TableHeader)`
  padding-left: 6.4rem;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const AddShippingTaxButton = styled(PrimaryButton)`
  width: 50%;
`
