import React from "react"
import { useQuery } from "@apollo/client"
import ALL_GIFT_CARD_PROVIDERS from "../../../graphql/queries/config/AllGiftCardProviders"
import { Common } from "@lib/types"
import { Control, UseFormSetValue } from "react-hook-form/dist/types/form"
import { FieldErrors } from "react-hook-form/dist/types/errors"
import { InputUpdateStoreMarket, Provider } from "@lib/types/generated/graphql-types"
import { Controller } from "react-hook-form"
import MultiSelect from "../../Ui/Form/MultiSelect"
import { ErrorMessage } from "@hookform/error-message"
import styled from "styled-components/macro"

const InputSection = styled.div`
  flex: 1;
  margin-bottom: 2rem;
`

const Label = styled.label`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
`

type Props = {
  defaultValue: Common.Option[] | undefined
  control: Control
  errors: FieldErrors
  setValue: UseFormSetValue<InputUpdateStoreMarket>
}

export const GiftCardPaymentProviders = ({ defaultValue, control, errors, setValue }: Props) => {
  const { data } = useQuery(ALL_GIFT_CARD_PROVIDERS)

  const giftCardProviderOptions = data?.getGiftCardProviders?.map((provider: Provider) => ({
    value: provider.id,
    label: provider.id
  }))

  const updateGiftCardProviders = (providers: Common.Option[]) =>
    setValue(
      "capabilities.giftCardProviders",
      providers.map((provider) => ({
        id: provider.value,
        type: data?.getGiftCardProviders?.find((p: Provider) => p.id === provider.value)?.type
      }))
    )

  if (!giftCardProviderOptions) return null

  return (
    <InputSection>
      <Label>Gift card payment</Label>
      <Controller
        name="capabilities.giftCardProviders"
        render={() => (
          <MultiSelect
            defaultValue={defaultValue}
            options={giftCardProviderOptions}
            setValue={updateGiftCardProviders}
          />
        )}
        control={control}
      />
      <ErrorMessage errors={errors} name="capabilities.giftCardProviders" />
    </InputSection>
  )
}
