import React, { useState } from "react"
import PageHeader from "components/Ui/Page/PageHeader"
import { useMutation, useQuery } from "@apollo/client"
import { SubmitHandler, useForm } from "react-hook-form"
import { useAppDispatch } from "lib/store"

import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import {
  CampaignGroup,
  InputCampaign,
  InputStoreMarket,
  InputMarketRoundingRule,
  InputPriceRule,
  InputPriceRuleType,
  InputRoundingRules,
  StoreGroup
} from "lib/types/generated/graphql-types"
import { useNavigate, useParams } from "react-router-dom"
import GET_CAMPAIGN from "../../graphql/queries/campaign/getCampaign"
import { CampaignTagRule } from "lib/types/campaign"
import General from "../../components/Campaign/General/General"
import SEARCH_CAMPAIGN_GROUPS from "../../graphql/queries/campaign/SearchCampaignGroups"
import { CampaignStoreMarket } from "../../components/Campaign/StoreMarket/CampaignStoreMarket"
import PriceRule from "../../components/Campaign/PriceRule/PriceRule"
import { RoundingRules } from "../../components/Campaign/RoundingRules/RoundingRules"
import ProductVariantRules from "../../components/Campaign/ProductVariantRules/ProductVariantRules"
import CREATE_OR_UPDATE_CAMPAIGN from "../../graphql/mutations/campaign/CreateOrUpdateCampaign"
import alertActions from "lib/store/services/Alert/AlertSlice"
import ALL_STORE_GROUPS from "../../graphql/queries/store/AllStoreGroups"
import ErrorMessage from "../../components/Ui/Messages/ErrorMessage"
import dayjs from "dayjs"
import { NoticeBanner } from "../../components/Ui/Messages/NoticeBanner"
import UPDATE_CAMPAIGN_PERIOD from "../../graphql/mutations/campaign/UpdateCampaignPeriod"
import { ActionDropdownButton } from "../../components/Ui/Buttons/ActionDropdownButton"
import { getJson } from "helpers/getJson"

import { ReactComponent as CopyIcon } from "images/icons/copy.svg"
import { ReactComponent as CodeIcon } from "images/icons/brackets-curly.svg"
import { isCampaignUser, isSuperUser } from "helpers/user"
import ProductPreview from "../../components/Campaign/ProductVariantRules/ProductPreview/ProductPreview"
import { v4 as uuidv4 } from "uuid"

export const UpdateCampaign = () => {
  const dispatch = useAppDispatch()
  const [storeGroups, setStoreGroups] = useState<StoreGroup[]>()
  const [tagRules, setTagRules] = useState<CampaignTagRule[]>([])
  const [addedStoreMarkets, setAddedStoreMarkets] = useState<InputStoreMarket[]>([])
  const [marketRules, setMarketRules] = useState<InputMarketRoundingRule[]>([])
  const [priceRule, setPriceRule] = useState<InputPriceRule>({
    type: InputPriceRuleType.RelativeDiscount
  })
  const [roundingRules, setRoundingRules] = useState<InputRoundingRules>()
  const [campaignGroups, setCampaignGroups] = useState<CampaignGroup[]>([])
  const { id } = useParams()
  const navigate = useNavigate()

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      setStoreGroups(data.getStoreGroups)
    }
  })

  const transformJSONLogicToTagRules = (logic: string) => {
    const tagLogic = JSON.parse(logic)
    // eslint-disable-next-line
    const transformed = tagLogic.or.map((rule: any) => ({
      // eslint-disable-next-line
      tagConditions: rule.and.map((condition: any) =>
        Object.keys(condition)[0] === "contains"
          ? {
              key: condition.contains.key,
              values: condition.contains.values,
              include: true
            }
          : {
              key: condition["!"].contains.key,
              values: condition["!"].contains.values,
              include: false
            }
      )
    }))
    setTagRules(transformed)
  }

  const { data, error, refetch } = useQuery(GET_CAMPAIGN, {
    variables: { id: id },
    onCompleted: (data) => {
      const { storeMarkets, priceRule, roundingRules, productVariantRules } = data.getCampaign
      transformJSONLogicToTagRules(productVariantRules.tagLogic)
      setAddedStoreMarkets(storeMarkets)
      setPriceRule(priceRule)
      setRoundingRules(roundingRules)
    }
  })

  const { data: campaignGroupsData } = useQuery(SEARCH_CAMPAIGN_GROUPS, {
    variables: {
      from: 0,
      size: 40,
      sort: [{ field: "updated", order: "DESC" }]
    },
    onCompleted: () => {
      setCampaignGroups(campaignGroupsData?.searchCampaignGroups?.hits)
    }
  })

  const campaign = data?.getCampaign

  const isCampaignOngoing = () => {
    return (
      dayjs(campaign?.startDate).format("YYYY-MM-DD HH:mm:ss") <
        dayjs().format("YYYY-MM-DD HH:mm:ss") &&
      dayjs(campaign?.endDate).format("YYYY-MM-DD HH:mm:ss") > dayjs().format("YYYY-MM-DD HH:mm:ss")
    )
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues
  } = useForm<InputCampaign>()

  const [updateCampaignPeriod, { loading: periodLoading }] = useMutation(UPDATE_CAMPAIGN_PERIOD, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Campaign end date successfully updated",
          type: "success"
        })
      )
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          message: error.message,
          type: "error"
        })
      )
    }
  })

  const [updateCampaign, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_CAMPAIGN, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Campaign successfully updated",
          type: "success"
        })
      )
      refetch()
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          message: error.message,
          type: "error"
        })
      )
    }
  })

  const getTagLogicJSON = () => {
    const transformed = {
      or: tagRules.map((tagRule) => ({
        and: tagRule.tagConditions.map((tagCondition) => ({
          ...(tagCondition.include
            ? {
                contains: {
                  key: tagCondition.key,
                  values: tagCondition.values
                }
              }
            : {
                ["!"]: {
                  contains: {
                    key: tagCondition.key,
                    values: tagCondition.values
                  }
                }
              })
        }))
      }))
    }
    return JSON.stringify(transformed)
  }

  const onSubmit: SubmitHandler<InputCampaign> = (data) => {
    if (isCampaignOngoing()) {
      return updateCampaignPeriod({
        variables: { id: campaign.id, endDate: new Date(data.endDate) }
      })
    }
    updateCampaign({
      variables: {
        campaignGroupId: data.campaignGroupId,
        name: data.name,
        id: campaign.id,
        priceRule: priceRule,
        storeMarkets: addedStoreMarkets,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
        ...(roundingRules?.defaultRule.targets
          ? {
              roundingRules: {
                defaultRule: roundingRules?.defaultRule,
                marketRules: roundingRules?.marketRules ?? []
              }
            }
          : { roundingRules: null }),
        productVariantRules: {
          tagLogic: getTagLogicJSON()
        }
      }
    })
  }

  if (error) return <ErrorMessage message={"Error fetching campaing"} />
  if (!data) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        title={campaign.name}
        group="Campaign"
        id={campaign.id}
        hasTabs
        goBackLinkUrl="/campaigns"
        goBackLinkText="Back to campaigns"
      >
        <ActionDropdownButton
          title="Save"
          overrideDisabled={!isSuperUser() && isCampaignUser()}
          submitEvent={handleSubmit(onSubmit)}
          submitLoading={createLoading || periodLoading}
        >
          <li
            onClick={() => {
              navigate(`/campaigns/duplicate-campaign/${campaign.id}`)
            }}
          >
            <CopyIcon /> Duplicate
          </li>
          <li
            onClick={() => {
              getJson(campaign)
            }}
          >
            <CodeIcon /> Get rule JSON
          </li>
        </ActionDropdownButton>
      </PageHeader>

      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Product rules</Tab>
          <Tab>Store markets</Tab>
          <Tab>Price Rules</Tab>
          {priceRule.type === InputPriceRuleType.RelativeDiscount && <Tab>Rounding Rules</Tab>}
        </TabList>
        {isCampaignOngoing() && (
          <NoticeBanner type={"reminder"}>
            <span>
              Important note: Once the campaign is active, no other fields but the end date can be
              modified.
            </span>
          </NoticeBanner>
        )}
        <TabPanel>
          <General
            isCampaignOngoing={isCampaignOngoing()}
            control={control}
            errors={errors}
            setValue={setValue}
            campaignGroups={campaignGroups}
            setCampaignGroups={setCampaignGroups}
            getValues={getValues}
            startDateValidation={!isCampaignOngoing()}
            defaultValues={{
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              campaignGroupId: campaign.campaignGroupId
            }}
          />
        </TabPanel>
        <TabPanel>
          <ProductVariantRules
            tagRules={tagRules}
            setTagRules={setTagRules}
            isCampaignOngoing={isCampaignOngoing()}
          />
          {tagRules[0]?.tagConditions?.length > 0 && <h2>Product variant preview</h2>}
          {tagRules.map((tagRule, index) => (
            <ProductPreview key={uuidv4()} tagRule={tagRule} index={index} />
          ))}
        </TabPanel>
        <TabPanel>
          <CampaignStoreMarket
            addedStoreMarkets={addedStoreMarkets}
            setAddedStoreMarkets={setAddedStoreMarkets}
            storeGroups={storeGroups}
            isCampaignOngoing={isCampaignOngoing()}
          />
        </TabPanel>
        <TabPanel>
          <PriceRule
            priceRule={priceRule}
            setPriceRule={setPriceRule}
            addedStoreMarkets={addedStoreMarkets}
            storeGroups={storeGroups}
            isCampaignOngoing={isCampaignOngoing()}
          />
        </TabPanel>
        {priceRule.type === InputPriceRuleType.RelativeDiscount && (
          <TabPanel>
            <RoundingRules
              marketRules={marketRules}
              setMarketRules={setMarketRules}
              roundingRules={roundingRules}
              setRoundingRules={setRoundingRules}
              addedStoreMarkets={addedStoreMarkets}
              isCampaignOngoing={isCampaignOngoing()}
            />
          </TabPanel>
        )}
      </Tabs>
    </form>
  )
}
