import styled from "styled-components/macro"

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.turquoiseDarker};

  svg {
    height: 1.4rem;
    margin-right: 0.5rem;
    fill: ${(p) => p.theme.colors.turquoiseDarker};
  }

  &:hover {
    cursor: pointer;
  }
`
