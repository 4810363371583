import React, { ReactNode, useState } from "react"
import { InputWrapper, LabelWrapper, Error } from "./OrderSummary.styled"
import Input from "../../../../Ui/Form/Input"
import Money from "../../../../Money"
import DineroFactory from "dinero.js"
import { ReactComponent as ArrowDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ArrowUp } from "images/icons/chevron-up.svg"


type Props = {
  name: string
  label: string
  currencyCode: string
  updateFunction: (amount: number) => void
  amount: number
  icon: ReactNode
  maxAmount: number
}

const AmountInput = ({ currencyCode, updateFunction, amount, name, label, icon, maxAmount }: Props) => {
  const [expanded, setExpanded] = useState(name === "paymentAmount")
  return (
    <InputWrapper>
      <LabelWrapper onClick={() => setExpanded(!expanded)} $expanded={expanded}>
        <span>
          {icon} <b>{label}</b>
        </span>
        {expanded ? <ArrowUp /> : <ArrowDown />}
      </LabelWrapper>
      {expanded && (
        <>
          Amount in minor units:
          <Input
            name={name}
            type="number"
            placeholder="0"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              updateFunction(parseInt(e.currentTarget.value))
            }
          />
          <b>Formatted:</b>{" "}
          <Money amount={amount || 0} currencyUnit={currencyCode as DineroFactory.Currency} />
        </>
      )}
      {amount > maxAmount && <Error>{`- Amount can't be higher than total`}</Error>}
      {amount < 0 && <Error>{`- Amount can´t be lower than 0`}</Error>}
    </InputWrapper>
  )
}

export default AmountInput
