import React, { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "lib/store"
import { hideEditSidebar } from "lib/store/services/editSidebar/slice"

import { DELETE_BONUS_CONFIG } from "graphql/mutations/config/DeleteBonusConfig"

import EditSidebar from "components/Ui/EditSidebar/EditSidebar"
import EditSidebarHeader from "components/Ui/EditSidebar/EditSidebarHeader"
import DeleteButton from "components/Ui/EditSidebar/DeleteButton"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import ConnectedStoreMarket from "components/Config/ConnectedStoreMarkets/ConnectedStoreMarket"
import { ReactComponent as BonusIcon } from "images/icons/hand-holding-dollar.svg"
import { ReactComponent as TibberLogo } from "images/providers/tibber-logo.svg"
import { PROVIDER_CATEGORY, PROVIDER_TYPE } from "lib/types/common"
import { useMutation, useQuery } from "@apollo/client"
import { GET_BONUS_CONFIG } from "../../../../graphql/queries/config/GetBonusProvider"
import BonusConfigInput from "../../../../components/Config/Bonus/BonusConfigInput"
import { SubmitHandler, useForm } from "react-hook-form"
import { CREATE_OR_UPDATE_BONUS_CONFIG } from "../../../../graphql/mutations/config/CreateOrUpdateBonusConfig"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { InputBonusConfig } from "lib/types/generated/graphql-types"
import PrimaryButton from "../../../../components/Ui/Buttons/PrimaryButton"

interface ExtendedInputBonusConfig extends InputBonusConfig {
  id: string
}

type Props = {
  refetch: () => void
  bonusConfigId: string
  setBonusConfigId: Dispatch<SetStateAction<string>>
}

export const EditBonusConfig = ({ refetch, bonusConfigId, setBonusConfigId }: Props) => {
  const dispatch = useAppDispatch()
  const closeSidebar = () => {
    setBonusConfigId("")
    dispatch(hideEditSidebar())
  }

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ExtendedInputBonusConfig>({ mode: "onBlur" })

  const [addBonusConfig, { loading }] = useMutation(CREATE_OR_UPDATE_BONUS_CONFIG, {
    onCompleted: () => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Custom bonus provider configuration successfully created",
          type: "success"
        })
      )
      refetch()
    },
    onError: () => {
      dispatch(
        alertActions.actions.createAlert({
          type: "error"
        })
      )
    }
  })

  const onSubmit: SubmitHandler<ExtendedInputBonusConfig> = (data) => {
    addBonusConfig({ variables: { ...data, id: bonusConfigId } })
  }


  const { data } = useQuery(GET_BONUS_CONFIG, { variables: { id: bonusConfigId } })

  const bonusConfig = data?.getBonusConfig

  if (!bonusConfig) return null

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <EditSidebar cancelEvent={() => closeSidebar()}>
      <EditSidebarHeader
        icon={bonusConfig.baseUrl.includes("tibber") ? <TibberLogo /> : <BonusIcon />}
        title={bonusConfig.id}
        id={bonusConfig.id}
        cancelEvent={() => closeSidebar()}
      >
        <DeleteButton
          id={bonusConfigId}
          name={bonusConfigId}
          entity={"Custom bonus provider configuration"}
          redirectUrl="/settings/providers/bonus"
          mutation={DELETE_BONUS_CONFIG}
          refetch={refetch}
          postDeleteFunction={() => setBonusConfigId("")}
          secureDelete={true}
        />
        <PrimaryButton type="submit" loading={loading}>
          Save
        </PrimaryButton>
      </EditSidebarHeader>

      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Connected store markets</Tab>
        </TabList>
        <TabPanel>
          <BonusConfigInput control={control} errors={errors} defaultValues={bonusConfig} />
        </TabPanel>
        <TabPanel>
          <ConnectedStoreMarket
            providerId={bonusConfigId}
            providerType={PROVIDER_TYPE.BONUS}
            providerCategory={PROVIDER_CATEGORY.CART_CAPABILITY}
          />
        </TabPanel>
      </Tabs>
    </EditSidebar>
    </form>
  )
}
