import styled from "styled-components/macro"

type StyledProps = {
  blurBackgroundColor?: boolean
}

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: calc(100% - 2rem);
  max-width: 55rem;
  min-height: 20rem;
  background: ${(p) => p.theme.colors.background};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.6rem;
  padding: 2rem 3rem 0;
  color: ${(p) => p.theme.colors.black};
  z-index: 10001;

  h2 {
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.greyLight};
    margin-bottom: 0;
    padding-bottom: 2rem;
    width: 100%;
  }

  p {
    margin: 0 0 0.5rem;
    color: ${(p) => p.theme.colors.black};
  }
`

export const Blur = styled.div<StyledProps>`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10001;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  background: ${(p) => p.theme.colors.greyLighter};
  padding: 2rem;
  margin: 0 -3rem;
  border-top: 0.1rem solid ${(p) => p.theme.colors.greyLight};
  border-radius: 0 0 0.6rem 0.6rem;

  button {
    margin-left: 0 !important;
  }
`
