import gql from "graphql-tag"

const GET_DELIVERY = gql`
  query getDelivery($deliveryId: ID!) {
    Order {
      delivery(deliveryId: $deliveryId) {
        id
        orderId
        started
        completed
        failed
        created
        updated
        restarts
        restarted
        capturedPayment {
          reference
        }
        giftCardProvider {
          providerName
          providerId
          status {
            current
          }
        }
        paymentProvider {
          providerName
          providerId
          status {
            current
          }
        }
        bonusProvider {
          providerName
          providerId
          status {
            current
          }
        }
        giftCards {
          amount
          currencyCode
          giftCardId
          status
        }
        giftCardProducts {
          giftCardProductId
          priceAmount
          currencyCode
          createId
          status
          createFailedReason
          createTransactionId
        }
        promotionProvider {
          providerName
          providerId
        }
        shippingProvider {
          providerName
          providerId
          status {
            current
          }
        }
        shippingFees {
          currencyCode
          shippingFeeId
          totalAmount
        }
        tracking {
          reference
          url
          shippingCompany
          shippingMethod
        }
        voucherProvider {
          providerId
          providerName
        }
        vouchers {
          voucherId
          amount
        }
        bonus {
          reservationId
          currencyCode
          amount
        }
        gifts {
          giftId
          quantity
        }
        totals {
          subTotal
          shippingTotal
          giftCardTotal
          bonusTotal
          grandTotal
        }
        orderLines {
          currencyCode
          orderLineId
          quantity
          taxPercentage
          taxPercentageDecimals
          totalAmount
          totalDiscountAmount
          totalTaxAmount
        }
      }
    }
  }
`

export default GET_DELIVERY
