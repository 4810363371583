import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { useQuery } from "@apollo/client"
import { useAppDispatch } from "lib/store"
import { showEditSidebar } from "lib/store/services/editSidebar/slice"
import { useSelector } from "react-redux"
import { editSidebarOpen } from "lib/store/services/editSidebar/selectors"

import ALL_STORE_GROUPS from "graphql/queries/store/AllStoreGroups"

import UpdateStoreGroup from "./UpdateStoreGroup"
import StoreGroupTable from "components/StoreGroup/StoreGroupTable"
import PageHeader from "components/Ui/Page/PageHeader"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"
import { CreateStoreGroup } from "./CreateStoreGroup"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

const Container = styled.div`
  width: 100%;
`

export function StoreGroups() {
  const dispatch = useAppDispatch()
  const showSidebar = useSelector(editSidebarOpen)
  const [editId, setEditId] = useState("")

  useEffect(() => {
    if (!showSidebar) setEditId("")
  }, [showSidebar])

  const { loading, error, data, refetch } = useQuery(ALL_STORE_GROUPS)

  return (
    <Container>
      {editId ? (
        <UpdateStoreGroup id={editId} setEditId={setEditId} refetch={refetch} />
      ) : (
        <CreateStoreGroup setEditId={setEditId} refetch={refetch} />
      )}
      <PageHeader
        title="Store groups"
        description={
          <>
            Add store groups to group store markets. <br />
          </>
        }
      >
        <PrimaryButton type="button" handleClick={() => dispatch(showEditSidebar())}>
          <PlusIcon /> Add new store group
        </PrimaryButton>
      </PageHeader>
      <StoreGroupTable
        storeGroups={data?.getStoreGroups || []}
        loading={loading}
        error={error}
        editId={editId}
        setEditId={setEditId}
      />
    </Container>
  )
}
