import gql from "graphql-tag"

const GET_VOYADO_CONFIGS = gql`
  query getVoyadoConfigs {
    getVoyadoConfigs {
      id
    }
  }
`

export default GET_VOYADO_CONFIGS
