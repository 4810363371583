import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import {
  ButtonWrapper,
  Container,
  CountryInfo,
  Description,
  SelectLabel,
  SelectWrapper,
  StoreGroupLabel,
  StoreMarketRow,
  StoreMarketsTable,
  StyledMultiSelect,
  Value,
  Values,
  Wrapper
} from "./SetChannels.styled"
import PrimaryButton from "../../../Ui/Buttons/PrimaryButton"
import SecondaryButton from "../../../Ui/Buttons/SecondaryButton"
import { InputStoreMarket, StoreGroup } from "@lib/types/generated/graphql-types"
import { Option } from "@lib/types/common"
import { getCountryName } from "helpers/countries"
import SingleSelect from "../../../Ui/Form/SingleSelect"
import FlagIcon from "../../../Ui/FlagIcon"
import Popup from "../../../Ui/Popup"
import { isSuperUser } from "helpers/user"
import { v4 as uuidv4 } from "uuid"
import { ReactComponent as StoreGroupIcon } from "images/icons/circle-nodes.svg"
import { ReactComponent as StoreMarketIcon } from "images/icons/store.svg"
import { BundleSectionValue } from "lib/types/discount"

type Props = {
  addedStoreMarkets: InputStoreMarket[]
  setAddedStoreMarkets: Dispatch<SetStateAction<InputStoreMarket[]>>
  storeGroups: StoreGroup[] | undefined
  setSelectedSection?: Dispatch<SetStateAction<BundleSectionValue>>
  expanded?: boolean
}

const SetChannels = ({
  addedStoreMarkets,
  setAddedStoreMarkets,
  storeGroups,
  setSelectedSection,
  expanded
}: Props) => {
  const [selectedStoreMarkets, setSelectedStoreMarkets] = useState<Option[]>([])
  const [selectedStoreGroup, setSelectedStoreGroup] = useState<StoreGroup | undefined>()
  const [storeMarketToDelete, setStoreMarketToDelete] = useState<string>("")
  const [openPopup, setOpenPopup] = useState(false)
  const [showStoreSelector, setShowStoreSelector] = useState((addedStoreMarkets.length > 0 || expanded))

  const storeGroupOptions = storeGroups?.map((storeGroup: StoreGroup) => ({
    value: storeGroup.id,
    label: storeGroup.name
  })) as Option[]

  const storeMarketOptions = selectedStoreGroup?.storeMarkets?.map((storeMarket) => ({
    label: getCountryName(storeMarket?.countryCode ?? "") ?? "",
    value: storeMarket?.countryCode ?? ""
  }))

  const onDeletStoreMarket = () => {
    setAddedStoreMarkets((prev: InputStoreMarket[]) =>
      prev.filter((campaignStoreMarket) => campaignStoreMarket.key !== storeMarketToDelete)
    )
    setOpenPopup(false)
  }

  useEffect(() => {
    const addedMarkets = addedStoreMarkets.find((as) => as.key === selectedStoreGroup?.id)
    if (addedMarkets) {
      const addedCountries = addedMarkets?.value?.map((countryCode: string) => ({
        label: getCountryName(countryCode) ?? "",
        value: countryCode
      }))
      setSelectedStoreMarkets(addedCountries)
    } else {
      setSelectedStoreMarkets([])
    }
  }, [selectedStoreGroup])

  useEffect(() => {
    const storeMarkets = selectedStoreMarkets.map((option) => option.value)
    if (selectedStoreMarkets.length !== 0) {
      if (
        addedStoreMarkets.length === 0 ||
        !addedStoreMarkets.find((storeGroup) => storeGroup?.key === selectedStoreGroup?.id)
      ) {
        setAddedStoreMarkets((prev) => [
          ...prev,
          {
            key: selectedStoreGroup?.id ?? "",
            value: storeMarkets
          }
        ])
      } else {
        setAddedStoreMarkets(
          addedStoreMarkets.map((storeGroup) =>
            storeGroup?.key === selectedStoreGroup?.id
              ? {
                  key: storeGroup.key,
                  value: storeMarkets
                }
              : storeGroup
          )
        )
      }
    } else {
      setAddedStoreMarkets((prev: InputStoreMarket[]) =>
        prev.filter((campaignStoreMarket) => campaignStoreMarket.key !== selectedStoreGroup?.id)
      )
    }
  }, [selectedStoreMarkets])

  const handleSelectAll = () => {
    if (storeGroups) {
      setAddedStoreMarkets(
        storeGroups?.map((storeGroup) => ({
          key: storeGroup.id,
          value: storeGroup?.storeMarkets?.map((storeMarket) => storeMarket.countryCode) ?? []
        }))
      )
    }
    setShowStoreSelector(true)
    if(setSelectedSection) setSelectedSection(BundleSectionValue.BUNDLES)
  }

  return (
    <div>
      <h2>Set channels</h2>
      <p>
        You can set a selection of store groups and markets where the bundle group will be activated
        or you can activate it for all channels.
      </p>
      {!expanded && <ButtonWrapper>
        <PrimaryButton handleClick={handleSelectAll}>Activate for all channels</PrimaryButton>{" "}
        <span>OR</span>{" "}
        <SecondaryButton type={"button"} handleClick={() => setShowStoreSelector(true)}>
          Select a subset of channels
        </SecondaryButton>
      </ButtonWrapper>}
      {showStoreSelector && (
        <>
          <Container>
            <h2>Countries</h2>
            <Description>Make your selection on store market(s).</Description>
            <SelectWrapper>
              <SingleSelect
                label={
                  <SelectLabel>
                    <StoreGroupIcon />
                    Store groups *
                  </SelectLabel>
                }
                options={storeGroupOptions}
                setValue={(storeGroup) =>
                  setSelectedStoreGroup(storeGroups?.find((sg) => sg.id === storeGroup?.value))
                }
                defaultValue={
                  selectedStoreGroup
                    ? { label: selectedStoreGroup.name, value: selectedStoreGroup.id }
                    : undefined
                }
                placeholder="Select store group"
              />
              <StyledMultiSelect
                label={
                  <SelectLabel>
                    <StoreMarketIcon />
                    Store markets *
                  </SelectLabel>
                }
                value={selectedStoreMarkets}
                options={selectedStoreGroup ? storeMarketOptions ?? [] : []}
                setValue={(e) => {
                  setSelectedStoreMarkets(e)
                }}
                selectAllEnabled={true}
                placeholder="Select store market"
              />
            </SelectWrapper>
          </Container>
          <StoreMarketsTable>
            {addedStoreMarkets.length > 0 ? (
              <>
                {addedStoreMarkets.map((storeMarket) => {
                  return (
                    <Wrapper key={uuidv4()}>
                      <StoreMarketRow
                        deleteEnabled={isSuperUser()}
                        smallButtons
                        disableHover
                        handleDelete={() => {
                          setStoreMarketToDelete(storeMarket.key)
                          setOpenPopup(true)
                        }}
                      >
                        <StoreGroupLabel>
                          <StoreGroupIcon />
                          <span>{storeGroups?.find((sg) => sg.id === storeMarket?.key)?.name}</span>
                        </StoreGroupLabel>
                        <Values>
                          {storeMarket.value.map((countryCode) => (
                            <Value key={countryCode}>
                              <CountryInfo>
                                <FlagIcon countryCode={countryCode} />
                                {getCountryName(countryCode)}
                              </CountryInfo>
                            </Value>
                          ))}
                        </Values>
                      </StoreMarketRow>
                      {openPopup && storeMarketToDelete === storeMarket.key && (
                        <Popup
                          title="Delete market rule"
                          subtitle={`Are you sure you want to delete all store market(s) from?`}
                          item={{
                            itemId: "",
                            itemName:
                              storeGroups?.find((sg) => sg.id === storeMarket?.key)?.name ?? ""
                          }}
                          handleCloseClick={() => setOpenPopup(false)}
                          handleOkClick={() => onDeletStoreMarket()}
                          type="delete"
                          overrideOkButton={!isSuperUser()}
                        />
                      )}
                    </Wrapper>
                  )
                })}
              </>
            ) : (
              <p>No store markets added</p>
            )}
          </StoreMarketsTable>
        </>
      )}
    </div>
  )
}

export default SetChannels
