import { RootState } from '@lib/store'
import { State } from '@lib/types'
import { createSelector } from '@reduxjs/toolkit'


const selectBrink = (state: RootState): State.BrinkState => state.brink
export const selectToken = createSelector(selectBrink, (state) => state.token)
export const selectCapabilities = createSelector(selectBrink, (state) => state.capabilities)

export const selectCheckoutToken = createSelector(selectBrink, (state) => state.checkoutToken)

export const selectTermsUrl = createSelector(selectBrink, (state) => state.termsUrl)

const useCart = createSelector(selectBrink, (state) => state.cart)


export const selectCart = createSelector(useCart, (shopperCart) => {
  if (!shopperCart) return

  const cart: State.Cart = {
    ...shopperCart
  }
  return cart
})

export const selectCartItemsLength = createSelector(selectCart, (cart) => {
  return cart?.items.length ?? 0
})
