import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { PROVIDER_TYPE } from "lib/types/common"
import { Provider } from "lib/types/generated/graphql-types"

import { Container, Wrapper, IconTableHeader, EmptyList } from "components/Ui/Table/Shared.styled"

import ErrorMessage from "components/Ui/Messages/ErrorMessage"
import TableBlurLoading from "components/Ui/Table/TableBlurLoading"
import { getLoadingRow, NShiftTableRow } from "./NShiftConfigTableRow"

import { ReactComponent as NshiftLogo } from "images/providers/icons/nshift.svg"

type Props = {
  providers: Provider[]
  error: object | undefined
  setNShiftConfigId?: Dispatch<SetStateAction<string>>
  loading: boolean
}

export const NShiftConfigTable = ({ providers, setNShiftConfigId, error, loading }: Props) => {
  const [nShiftProviders, setNShiftProviders] = useState<Provider[]>([])

  useEffect(() => {
    if (providers) {
      setNShiftProviders(providers?.filter((provider) => provider.type === PROVIDER_TYPE.NSHIFT))
    }
  }, [providers])

  const tableHeader = (
    <IconTableHeader>
      <div>Identifier</div>
      <div className="hideOnSmallScreens">Base URL</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Created</div>
      <div className="hideOnSmallScreens hideOnMeduimScreens">Updated</div>
    </IconTableHeader>
  )

  if (loading)
    return <TableBlurLoading numberOfRows={2} tableHeader={tableHeader} rows={getLoadingRow()} />

  if (nShiftProviders?.length < 1)
    return (
      <EmptyList>
        <NshiftLogo /> <p> No configurations added</p>
      </EmptyList>
    )

  return (
    <Wrapper>
      <Container>
        {error && (
          <ErrorMessage
            showRefreshButton
            message={
              <>
                Error loading Ingrid settings. Try refreshing the page, or contact{" "}
                <a href="mailto:support@brinkcommerce.com">support</a>.
              </>
            }
          />
        )}
        {tableHeader}
        {[...nShiftProviders].map((nShiftProvider) => (
          <NShiftTableRow
            key={nShiftProvider.id}
            id={nShiftProvider.id}
            setNShiftConfigId={setNShiftConfigId}
          />
        ))}
      </Container>
    </Wrapper>
  )
}
